import { useCallback, useRef } from 'react';
import { assertExhaustive } from 'venn-utils';
import type { Subject } from '../studio/types';

export type StateSignals =
  | { type: 'StudioReset' }
  | { type: 'SubjectUpdate'; subject: Subject }
  | { type: 'WorkspaceConfigurationUpdate' };

export const serializeSignal = (signal: StateSignals): string => {
  const { type } = signal;
  switch (type) {
    case 'StudioReset':
    case 'WorkspaceConfigurationUpdate':
      return type;
    case 'SubjectUpdate':
      return `${type}|${JSON.stringify(signal.subject, Object.keys(signal.subject).sort())}`;
    default:
      throw assertExhaustive(type);
  }
};

export const useSendSignal = (signal: StateSignals) => {
  const signalRef = useRef(signal);
  signalRef.current = signal;
  return useCallback(() => {
    sendSignal(signalRef.current);
  }, []);
};

export const sendSignal = (signal: StateSignals) => {
  document.dispatchEvent(new CustomEvent(serializeSignal(signal)));
};
