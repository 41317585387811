import React, { useEffect, useState } from 'react';
import { compact } from 'lodash';
import type { DateRange, Granularity } from 'venn-ui-kit';
import { Col, Container, DateRangePicker, GetColor, Label, Row } from 'venn-ui-kit';
import styled from 'styled-components';
import { type AnalysisSubject } from 'venn-utils';
import BenchmarkSelector from './BenchmarkSelector';
import SliderToggle from '../../slider-toggle/SliderToggle';
import type { SubjectControlProps } from './SubjectControl';
import SubjectControl from './SubjectControl';
import { ADD_DROPDOWN_WIDTH, BENCHMARK_MAX_WIDTH } from './compareConstants';
import type { FrequencyEnum } from 'venn-api';
import { primaryFactorLens, useCachedLoadableValue } from 'venn-state';

export interface CompareControlsProps extends SubjectControlProps {
  toggleLabel: string;
  toggleDisabled: boolean;
  toggleDisabledMessage?: string;
  onDateRangeChange: (dateRange: DateRange) => void;
  dateRange: DateRange;
  granularity: Granularity;
  maxDateRange?: DateRange;
  benchmark?: AnalysisSubject;
  onBenchmarkChange: (benchmark?: AnalysisSubject) => void;
  toggleRelative: () => void;
  controlsDisabled: boolean;
  maxSelections: number;
  maxFrequency: FrequencyEnum;
}

const CompareControls = ({
  locationOnPage,
  toggleLabel,
  toggleDisabledMessage,
  subjects,
  onHoverSubject,
  dateRange: dateRangeProp,
  onDateRangeChange,
  maxDateRange,
  onAddSubject,
  onClearSubjects,
  onRemoveSubject,
  benchmark,
  onBenchmarkChange,
  relative,
  toggleRelative,
  toggleDisabled,
  isAddDisabled,
  controlsDisabled,
  maxSelections,
  isPivoted,
  togglePivoted,
  isOptionDisabled,
  optionDisabledTooltipContent,
  maxFrequency,
}: CompareControlsProps) => {
  const factorLens = useCachedLoadableValue(primaryFactorLens);

  const [dateRange, setDateRange] = useState<DateRange>(dateRangeProp);

  useEffect(() => {
    setDateRange(dateRangeProp);
  }, [dateRangeProp]);

  return (
    <Wrapper data-testid="qa-compare-controls-wrapper" fluid>
      <PaddedRow>
        <BenchmarkCol xs={7} className="qa-benchmark">
          <FormControl>
            <BenchmarkSelector
              value={benchmark}
              onChange={onBenchmarkChange}
              disabled={controlsDisabled}
              resultsFilter={isOptionDisabled}
              resultDisabledMessage={optionDisabledTooltipContent}
            />
          </FormControl>
        </BenchmarkCol>
        <Col xs={2}>
          <FormControl>
            <Label>{toggleLabel}</Label>
            <SliderToggle
              disabled={controlsDisabled || toggleDisabled}
              toggled={relative}
              onToggle={toggleRelative}
              tooltipMessage={toggleDisabledMessage}
            />
          </FormControl>
        </Col>
        <RightColumn xs={3}>
          <FormControl>
            <Label>Analysis Period:</Label>
            <DateRangePicker
              value={dateRange.from || dateRange.to || dateRange.period ? dateRange : { period: 'full' }}
              period={dateRange.period}
              onUpdateDate={(newDateRange) => {
                setDateRange({
                  from: newDateRange.from,
                  to: newDateRange.to,
                });
              }}
              factorLens={factorLens}
              onChange={(range) => {
                setDateRange(range);
                onDateRangeChange(range);
              }}
              range={maxDateRange}
              maxFrequency={maxFrequency}
              disabled={subjects.length === 0 || controlsDisabled}
              options={compact(['ytd', '1yr', '3yr', '5yr', 'full', 'full_no_factor_constraint'])}
            />
          </FormControl>
        </RightColumn>
      </PaddedRow>
      <Row>
        <Col>
          <BlackBar />
        </Col>
      </Row>
      <HeaderRow>
        <Col>
          <SubjectControl
            locationOnPage={locationOnPage}
            subjects={subjects}
            isAddDisabled={isAddDisabled || controlsDisabled}
            relative={relative}
            isPivoted={isPivoted}
            maxSelections={maxSelections}
            isOptionDisabled={isOptionDisabled}
            optionDisabledTooltipContent={optionDisabledTooltipContent}
            togglePivoted={togglePivoted}
            onAddSubject={onAddSubject}
            onClearSubjects={onClearSubjects}
            onHoverSubject={onHoverSubject}
            onRemoveSubject={onRemoveSubject}
          />
        </Col>
      </HeaderRow>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background-color: ${GetColor.White};
  padding-left: 0;
  padding-right: 0;
`;

const BlackBar = styled.div`
  background-color: ${GetColor.Black};
  height: 1px;
`;

const HeaderRow = styled(Row)`
  margin-top: 10px;
`;

const PaddedRow = styled(Row)`
  padding-bottom: 5px;
`;

const BenchmarkCol = styled(Col)`
  min-width: ${ADD_DROPDOWN_WIDTH}px;
  max-width: ${BENCHMARK_MAX_WIDTH}px;
`;

const RightColumn = styled(Col)`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`;

const FormControl = styled.div`
  padding: 10px 0;
  > label {
    margin-bottom: 5px;
    display: inline-block;
  }
`;

export default CompareControls;
