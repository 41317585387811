import type { ColDef, IRowNode } from 'ag-grid-community';
import { type BulkManageRow, type BulkManageUpdateFn } from '../types';
import { bulkManageSortFunction } from './bulkManageSortFunction';
import { ProxyCellRenderer, type ProxyCellRendererParams } from './ProxyCellRenderer';
import type { DetailedProxyMetadata } from 'venn-api';
import { isBulkManageFundRow } from '../utils';

const PROXY_COL_MIN_WIDTH = 160;

export const getProxyColDef = (
  canEditProxies: boolean,
  onUpdate: BulkManageUpdateFn,
  openBulkProxyModal: (row: IRowNode<BulkManageRow>) => void,
): ColDef<BulkManageRow, DetailedProxyMetadata | undefined> => {
  return {
    flex: 2,
    maxWidth: 350,
    minWidth: PROXY_COL_MIN_WIDTH,
    cellRendererSelector: ({ node }) => {
      return node.group || !isBulkManageFundRow(node.data)
        ? undefined
        : {
            component: ProxyCellRenderer,
            params: {
              canEditProxies,
              onUpdate,
              openBulkProxyModal,
            } satisfies ProxyCellRendererParams,
          };
    },
    headerName: 'Proxy',
    sortable: true,
    field: 'fundProxyInfo',
    comparator: bulkManageSortFunction({
      valueGetter: (value) => value?.proxyName,
    }),
  };
};
