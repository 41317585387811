import React, { useCallback, useContext, useMemo } from 'react';
import { type ReorderableItem, ReorderableList, UserContext } from 'venn-components';
import { BorderBottom, FlexHeader } from '../../shared';
import styled from 'styled-components';
import BlockItem from './components/BlockItem';
import { useRecoilState } from 'recoil';
import { allBlockIdsState } from 'venn-state';

const OutlinePanel = () => {
  const { hasPermission } = useContext(UserContext);
  const [blockIds, setBlockIds] = useRecoilState(allBlockIdsState);

  const views = useMemo(
    () =>
      blockIds.map((id) => ({
        key: id,
      })) ?? [],
    [blockIds],
  );

  const onReorderBlocks = useCallback(
    (newItems: typeof views) => {
      const reordered = newItems.map(({ key }) => key);
      setBlockIds(reordered);
    },
    [setBlockIds],
  );

  const itemRenderer = useCallback(({ key }: ReorderableItem) => <BlockItem key={key} id={key} />, []);

  return (
    <>
      <BorderBottom>
        <FlexHeader>Blocks</FlexHeader>
      </BorderBottom>
      <BlocksWrapper>
        <ReorderableList
          hideDelete
          reorderableItems={views}
          onReorderItems={onReorderBlocks}
          itemRenderer={itemRenderer}
          disabled={!hasPermission('STUDIO_REORDER_BLOCKS')}
          id="blockList"
        />
      </BlocksWrapper>
    </>
  );
};

export default OutlinePanel;

const BlocksWrapper = styled.div`
  margin: 10px 30px;

  ul > div {
    display: flex;
    align-items: center;
    > i {
      top: unset;
      left: 1px;
    }
  }
`;
