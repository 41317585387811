import { DefaultValue, selector, selectorFamily } from 'recoil';
import { studioBlockCustomWidth } from '../grid';
import { type BlockId, type CustomViewOptions, type GlobalCustomViewOptions, UnitFormat } from '../types';
import {
  allocatorAnalysisSubject,
  openAllocatorSubject,
  openAllocatorSubjectConfig,
  openPrivateAllocatorConfig,
} from './allocator';
import { isReportState } from './view';
import { blockAllFactorsSelected } from './blockConfig';
import { blockCustomMetricSettingsState } from './blockSettings';
import { colorIndexSubjectTupleState } from './colors';
import {
  studioCustomFooterText,
  studioHideFooterDisclaimer,
  studioHideFooterPagination,
  studioHideHeaderCompanyName,
  studioHideHeaderLogo,
  studioHideHeaderViewName,
  viewPages,
} from './print';
import {
  blockPrivateAssetAllocation,
  blockPrivateAssetIsCumulative,
  blockPrivateDefaultAsOfDateBehavior,
  blockStartingNAV,
  DEFAULT_CAPITAL_COMMITMENT_ALLOCATION,
} from './privateAssets';
import { viewSectionName, viewSubtitle, viewTitle } from './viewOptions';
import { customField_gridData } from './customMetrics';
import { blockSelectedPeerGroupId } from './peerGroups';
import { allBlockFontsState, blockTableSpacingRatioState } from '../fonts/blockFonts';
import {
  blockHoldingsCategoryIds,
  blockScenarios,
  blockYAxisUnitFormat,
  canToggleHideBlockBorders,
  geoLevelFilterType,
  hideBlockBorders,
  portfolioStrategyFilterType,
  sectorLevelFilterType,
  viewCoverPageImage,
  viewCustomNotablePeriods,
  viewDataGridSizeType,
  viewImageId,
  viewImages,
  viewPrimaryColor,
  viewRichText,
  viewSelectedInvestmentGroupInformation,
  viewSelectedNotablePeriods,
  viewShowVenncast,
} from './customViewOptions';
import { DEFAULT_PRIVATE_ASSET_ALLOCATION } from 'venn-utils';
import { isNil } from 'lodash';
import { blockAsOfDateSetting } from './historicalAssets';

export const customViewOptions = selectorFamily<CustomViewOptions, BlockId>({
  key: 'customViewOptions',
  get:
    (id) =>
    ({ get }) => {
      const selectedNotablePeriods = get(viewSelectedNotablePeriods(id));
      const selectedInvestmentGroupInformation = get(viewSelectedInvestmentGroupInformation(id));
      const customNotablePeriods = get(viewCustomNotablePeriods(id));
      const dataGridSizeType = get(viewDataGridSizeType(id));
      const portfolioStrategyFilter = get(portfolioStrategyFilterType(id));
      const geoLevelFilter = get(geoLevelFilterType(id));
      const sectorLevelFilter = get(sectorLevelFilterType(id));
      const BLOCK_WIDTH = get(studioBlockCustomWidth(id));
      const title = get(viewTitle(id));
      const sectionName = get(viewSectionName(id));
      const subtitle = get(viewSubtitle(id));
      const richText = get(viewRichText(id));
      const showVenncast = get(viewShowVenncast(id));
      const imageId = get(viewImageId(id));
      const scenarios = get(blockScenarios(id));
      const allFactorsSelected = get(blockAllFactorsSelected(id));
      const holdingsCategoryIds = get(blockHoldingsCategoryIds(id));
      const privateAssetIsCumulative = get(blockPrivateAssetIsCumulative(id));
      const privateDefaultAsOfDateBehavior = get(blockPrivateDefaultAsOfDateBehavior(id));
      const startingNAV = get(blockStartingNAV(id));
      const privateAssetAllocation = get(blockPrivateAssetAllocation(id));
      const customMetricsData = get(customField_gridData(id));
      const selectedPeerGroupId = get(blockSelectedPeerGroupId(id));
      const customMetricsSettings = get(blockCustomMetricSettingsState(id));
      const customFonts = get(allBlockFontsState(id));
      const tableSpacingRatio = get(blockTableSpacingRatioState(id));
      const yAxisUnitFormat = get(blockYAxisUnitFormat(id));
      // Don't store the hide borders setting if the block doesn't support block borders. This way we aren't locking blocks into some future behavior.
      // Also this prevents issues from settings being stored before the FF is enabled.
      const hideBorders = get(canToggleHideBlockBorders(id)) ? get(hideBlockBorders(id)) : undefined;
      const historicalPortfolioAsOfSetting = get(blockAsOfDateSetting(id));

      const baseOptions = {
        selectedNotablePeriods,
        selectedInvestmentGroupInformation,
        customNotablePeriods,
        dataGridSizeType,
        portfolioStrategyFilter,
        geoLevelFilter,
        sectorLevelFilter,
        BLOCK_WIDTH,
        title,
        sectionName,
        subtitle,
        richText,
        showVenncast,
        imageId,
        scenarios,
        allFactorsSelected,
        holdingsCategoryIds,
        privateAssetIsCumulative,
        privateDefaultAsOfDateBehavior,
        startingNAV,
        privateAssetAllocation,
        yAxisUnitFormat,
        customMetricGrid: customMetricsData,
        selectedPeerGroupId,
        customMetricsSettings,
        historicalPortfolioAsOfDate: historicalPortfolioAsOfSetting,
      };

      return get(isReportState)
        ? {
            ...baseOptions,
            customFonts,
            tableSpacingRatio,
            hideBorders,
          }
        : baseOptions;
    },
  set:
    (id) =>
    ({ set }, value) => {
      if (value instanceof DefaultValue) {
        return;
      }
      set(viewSelectedNotablePeriods(id), value.selectedNotablePeriods);
      set(viewSelectedInvestmentGroupInformation(id), value.selectedInvestmentGroupInformation);
      set(viewCustomNotablePeriods(id), value.customNotablePeriods);
      set(viewDataGridSizeType(id), value.dataGridSizeType);
      set(portfolioStrategyFilterType(id), value.portfolioStrategyFilter);
      set(geoLevelFilterType(id), value.geoLevelFilter);
      set(sectorLevelFilterType(id), value.sectorLevelFilter);
      value.BLOCK_WIDTH && set(studioBlockCustomWidth(id), value.BLOCK_WIDTH);
      set(viewTitle(id), value.title);
      set(viewSectionName(id), value.sectionName);
      set(viewSubtitle(id), value.subtitle);
      set(viewRichText(id), value.richText);
      set(viewShowVenncast(id), value.showVenncast);
      set(viewImageId(id), value.imageId);
      value.scenarios && set(blockScenarios(id), value.scenarios);
      value.allFactorsSelected && set(blockAllFactorsSelected(id), value.allFactorsSelected);
      set(blockHoldingsCategoryIds(id), value.holdingsCategoryIds);
      set(blockPrivateAssetIsCumulative(id), value.privateAssetIsCumulative);
      value.privateDefaultAsOfDateBehavior != null &&
        set(blockPrivateDefaultAsOfDateBehavior(id), value.privateDefaultAsOfDateBehavior);
      set(blockStartingNAV(id), value.startingNAV ?? DEFAULT_CAPITAL_COMMITMENT_ALLOCATION);
      set(blockPrivateAssetAllocation(id), value.privateAssetAllocation ?? DEFAULT_PRIVATE_ASSET_ALLOCATION);
      value.customMetricGrid && set(customField_gridData(id), value.customMetricGrid);
      set(blockSelectedPeerGroupId(id), value.selectedPeerGroupId);
      value.customMetricsSettings && set(blockCustomMetricSettingsState(id), value.customMetricsSettings);
      value.customFonts && set(allBlockFontsState(id), value.customFonts);
      value.tableSpacingRatio && set(blockTableSpacingRatioState(id), value.tableSpacingRatio);
      set(blockYAxisUnitFormat(id), value.yAxisUnitFormat ?? UnitFormat.PERCENT);
      if (!isNil(value.hideBorders)) {
        set(hideBlockBorders(id), value.hideBorders);
      }
      if (value.historicalPortfolioAsOfDate !== undefined) {
        set(blockAsOfDateSetting(id), value.historicalPortfolioAsOfDate);
      }
    },
});

export const globalCustomViewOptions = selector<GlobalCustomViewOptions>({
  key: 'customViewOptions',
  get: ({ get }) => {
    const pages = get(viewPages);
    const coverPageImage = get(viewCoverPageImage);
    const images = get(viewImages);
    const hide_header_company_name = get(studioHideHeaderViewName);
    const hide_header_logo = get(studioHideHeaderCompanyName);
    const hide_header_view_name = get(studioHideHeaderLogo);
    const hide_footer_pagination = get(studioHideFooterPagination);
    const hide_footer_disclaimer = get(studioHideFooterDisclaimer);
    const custom_footer_text = get(studioCustomFooterText);
    const primaryColor = get(viewPrimaryColor);
    const allocatorConfig = get(openAllocatorSubjectConfig);
    const colorIndexSubjectMapping = get(colorIndexSubjectTupleState);
    const privateAllocatorConfig = get(openPrivateAllocatorConfig);
    return {
      pages,
      coverPageImage,
      images,
      hide_header_company_name,
      hide_header_logo,
      hide_header_view_name,
      hide_footer_pagination,
      hide_footer_disclaimer,
      custom_footer_text,
      primaryColor,
      allocatorConfig,
      colorIndexSubjectMapping,
      privateAllocatorConfig,
    };
  },
  set: ({ get, set }, value) => {
    if (value instanceof DefaultValue) {
      set(viewPages, value);
      set(viewCoverPageImage, value);
      set(viewImages, value);
      set(studioHideHeaderViewName, value);
      set(studioHideHeaderCompanyName, value);
      set(studioHideHeaderLogo, value);
      set(studioHideFooterPagination, value);
      set(studioHideFooterDisclaimer, value);
      set(studioCustomFooterText, value);
      set(viewPrimaryColor, value);
      set(allocatorAnalysisSubject(get(openAllocatorSubject)), value);
      set(openAllocatorSubject, value);
      set(colorIndexSubjectTupleState, value);
      return;
    }
    value.pages && set(viewPages, value.pages);
    value.coverPageImage && set(viewCoverPageImage, value.coverPageImage);
    set(viewImages, value.images);
    value.hide_header_company_name && set(studioHideHeaderViewName, value.hide_header_company_name);
    value.hide_header_logo && set(studioHideHeaderCompanyName, value.hide_header_logo);
    value.hide_header_view_name && set(studioHideHeaderLogo, value.hide_header_view_name);
    value.hide_footer_pagination && set(studioHideFooterPagination, value.hide_footer_pagination);
    value.hide_footer_disclaimer && set(studioHideFooterDisclaimer, value.hide_footer_disclaimer);
    value.custom_footer_text && set(studioCustomFooterText, value.custom_footer_text);
    value.primaryColor && set(viewPrimaryColor, value.primaryColor);
    value.allocatorConfig && set(openAllocatorSubjectConfig, value.allocatorConfig);
    value.colorIndexSubjectMapping && set(colorIndexSubjectTupleState, value.colorIndexSubjectMapping);
    value.privateAllocatorConfig && set(openPrivateAllocatorConfig, value.privateAllocatorConfig);
  },
});
