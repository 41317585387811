import type {
  Portfolio,
  AnalysisTypeEnum,
  AnalysisParams,
  AnalysisRequest,
  Scenario,
  TimePeriodEnum,
  AnalysisPeriod,
} from 'venn-api';
import type AnalysisSubject from './AnalysisSubject';
import type { TimeFrame } from './types';
import { flatten, chunk, omit, compact, isNil } from 'lodash';
import getAnalysisSubjects from './getAnalysisSubjects';
import type { RangeType, DateRange } from 'venn-ui-kit';
import { logExceptionIntoSentry } from '../error-logging';
import { assertExhaustive } from '../type';

export interface TrendRollingPeriods {
  rollingFactorExposuresPeriod?: number;
  rollingFactorRiskPeriod?: number;
  rollingFactorReturnPeriod?: number;
}

const getAnalysisRequest = (
  analysisTypeList: AnalysisTypeEnum[] | undefined,
  subject: AnalysisSubject | undefined,
  selectedTimeFrame: TimeFrame,
  relative: boolean,
  categoryActive: boolean,
  selectedPeriod?: RangeType,
  trackingId?: number,
  drawdownThreshold?: number,
  useResidual?: boolean,
  scenarios?: Scenario[],
  analysisDate?: number,
  excludeBenchmark?: boolean,
  customComparisonPortfolio?: Portfolio,
  rollingYears?: number,
  /** Factor trend's rolling periods for risk, return and exposure */
  trendRollingPeriods?: TrendRollingPeriods,
  selectedNotablePeriods?: number[],
): Partial<AnalysisRequest> | undefined => {
  if (!subject || !selectedTimeFrame || !analysisTypeList) {
    return undefined;
  }

  const analyses = compact(
    flatten(
      analysisTypeList.map((analysisDataType) =>
        getAnalyses(
          analysisDataType,
          drawdownThreshold,
          relative,
          useResidual,
          scenarios,
          analysisDate,
          subject,
          rollingYears,
          trendRollingPeriods,
          selectedNotablePeriods,
        ),
      ),
    ),
  );
  const subjects = getAnalysisSubjects(subject, categoryActive, excludeBenchmark, customComparisonPortfolio);

  // Keep the time frame when there is any user selected analysis period
  if (selectedTimeFrame.startTime || selectedTimeFrame.endTime || selectedPeriod) {
    return {
      start: selectedTimeFrame.startTime,
      end: selectedTimeFrame.endTime,
      period: convertPeriodRequest(selectedPeriod),
      analyses,
      subjects,
      trackingId,
    };
  }

  return {
    analyses,
    subjects,
    trackingId,
  };
};

export default getAnalysisRequest;

export const getChunkedAnalysisRequests = (
  analysisTypeList: AnalysisTypeEnum[] | undefined,
  subject: AnalysisSubject | undefined,
  selectedTimeFrame: TimeFrame,
  relative: boolean,
  categoryActive: boolean,
  trendRollingPeriods: TrendRollingPeriods,
  selectedPeriod?: RangeType,
  drawdownThreshold?: number,
  trackingId?: number,
  selectedNotablePeriods?: number[],
): Partial<AnalysisRequest>[] | undefined => {
  const request = getAnalysisRequest(
    analysisTypeList,
    subject,
    selectedTimeFrame,
    relative,
    categoryActive,
    selectedPeriod,
    trackingId,
    drawdownThreshold,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    trendRollingPeriods,
    selectedNotablePeriods,
  );

  if (!request?.analyses) {
    return undefined;
  }

  if (request.analyses.length === 0) {
    return [request];
  }

  const chunkSize = Math.floor(Math.sqrt(request.analyses.length));
  return chunk(request.analyses, chunkSize).map((analyses: AnalysisParams[]) => ({
    ...omit(request, ['analyses']),
    analyses,
  }));
};

export const flattenChunkedRequestOrResponse = <T, K extends { analyses: T[] }>(data: Partial<K>[]): Partial<K> =>
  data && data.length > 0
    ? {
        ...data[0],
        analyses: data.flatMap((d) => d.analyses),
      }
    : {};

export const generateAnalysisParams = (
  analysisType: AnalysisTypeEnum,
  relative: boolean,
  rollingYears?: number,
  drawdownThreshold?: number,
  residual?: boolean,
  scenarios?: Scenario[],
  analysisDate?: number,
  portfolio?: Portfolio,
  selectedNotablePeriods?: number[],
  includeAllPredefinedPeriods = false,
): AnalysisParams => ({
  analysisType,
  analysisDate,
  drawdownThreshold,
  relative,
  rollingYears,
  residual,
  scenarios: scenarios || [],
  contextPortfolio: portfolio,
  selectedNotablePeriods,
  includeAllPredefinedPeriods,
  peerGroupIdentifier: undefined,
});

const getAnalyses = (
  analysisDataType: AnalysisTypeEnum,
  drawdownThreshold?: number,
  relative = false,
  residual?: boolean,
  scenarios?: Scenario[],
  analysisDate?: number,
  subject?: AnalysisSubject,
  rollingYears?: number,
  trendRollingPeriods?: TrendRollingPeriods,
  selectedNotablePeriods?: number[],
): AnalysisParams[] => {
  switch (analysisDataType) {
    case 'ROLLING_VOLATILITY':
    case 'ROLLING_SHARPE':
    case 'ROLLING_RETURN':
    case 'ROLLING_BETA':
    case 'ROLLING_BENCHMARK_CORRELATION':
      if (rollingYears !== undefined) {
        return [generateAnalysisParams(analysisDataType, relative, rollingYears)];
      }
      return [
        generateAnalysisParams(analysisDataType, relative, 1),
        generateAnalysisParams(analysisDataType, relative, 3),
        generateAnalysisParams(analysisDataType, relative, 5),
      ];
    case 'FUND_RESIDUAL_CORRELATION':
    case 'CORRELATION':
      return [generateAnalysisParams(analysisDataType, relative, undefined, undefined, !relative && residual)];
    case 'CUMULATIVE_RETURN':
    case 'VENNCAST':
    case 'RETURNS_HISTOGRAM':
    case 'PROFORMA_DRAWDOWN':
    case 'PERFORMANCE_SUMMARY_FORECAST':
    case 'PERFORMANCE_SUMMARY_HISTORICAL':
    case 'FACTOR_CONTRIBUTION_TO_RISK':
    case 'FACTOR_CONTRIBUTION_TO_RETURN':
    case 'FACTOR_EXPOSURES':
    case 'FACTOR_DRAWDOWN_PERIOD':
    case 'FACTOR_ENVIRONMENT':
    case 'RANGE_DEBUG':
    case 'RETURNS_GRID':
    case 'RESIDUAL_RETURNS_ANALYSIS':
    case 'PERCENTAGE_DRAWDOWN_ANALYSIS':
    case 'PORTFOLIO_BREAKDOWN':
    case 'RANGE_ANALYSIS':
      return [generateAnalysisParams(analysisDataType, relative, undefined)];
    case 'FACTOR_CONTRIBUTION_TO_RISK_TREND':
      return [generateAnalysisParams(analysisDataType, relative, trendRollingPeriods?.rollingFactorRiskPeriod)];
    case 'FACTOR_CONTRIBUTION_TO_RETURN_TREND':
      return [generateAnalysisParams(analysisDataType, relative, trendRollingPeriods?.rollingFactorReturnPeriod)];
    case 'FACTOR_EXPOSURES_TREND':
      return [generateAnalysisParams(analysisDataType, relative, trendRollingPeriods?.rollingFactorExposuresPeriod)];
    case 'FACTOR_DRAWDOWN':
      return [generateAnalysisParams(analysisDataType, relative, undefined, drawdownThreshold)];
    case 'NOTABLE_PERIODS':
      return [
        generateAnalysisParams(
          analysisDataType,
          relative,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          selectedNotablePeriods,
        ),
      ];
    case 'PERFORMANCE_SUMMARY_FORECAST_CONTRIBUTION':
    case 'PERFORMANCE_SUMMARY_HISTORICAL_CONTRIBUTION':
      return [generateAnalysisParams(analysisDataType, relative, undefined, drawdownThreshold)];
    case 'SCENARIO':
      return [generateAnalysisParams(analysisDataType, relative, undefined, undefined, undefined, scenarios)];
    case 'FUND_FACTOR_CONTRIBUTION':
    case 'FUND_FACTOR_TREND_CONTRIBUTION':
      return [
        generateAnalysisParams(analysisDataType, relative, rollingYears, undefined, undefined, undefined, analysisDate),
      ];
    case 'INVESTMENT_ATTRIBUTION':
      return [
        generateAnalysisParams(
          analysisDataType,
          relative,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          subject?.portfolio,
        ),
      ];
    case 'HISTORICAL_RESIDUAL_PORTFOLIO_RETURNS_ANALYSIS':
      // known new block type, don't throw an error
      // TODO: handle this new analysis block type
      return [];

    default:
      logExceptionIntoSentry(`Unknown Analysis type: ${analysisDataType}`);
      return [];
  }
};

export const convertPeriodRequest = (period?: RangeType): TimePeriodEnum | undefined => {
  if (isNil(period)) {
    return undefined;
  }
  switch (period) {
    case '1yr':
      return 'YEAR_1';
    case '3yr':
      return 'YEAR_3';
    case '5yr':
      return 'YEAR_5';
    case '7yr':
      return 'YEAR_7';
    case '10yr':
      return 'YEAR_10';
    case 'ytd':
      return 'YTD';
    case 'full':
      return 'FULL';
    case 'full_no_factor_constraint':
      return 'FULL_NO_FACTOR_CONSTRAINT';
    default:
      return assertExhaustive(period);
  }
};

export const rangeTypeToApiType = (period: RangeType): TimePeriodEnum => {
  const result = convertPeriodRequest(period);
  return result!;
};

export const convertPeriodToDataRange = (analysisPeriod?: AnalysisPeriod): DateRange => ({
  from: analysisPeriod?.periodToDate ? undefined : analysisPeriod?.startDate,
  to: analysisPeriod?.periodToDate ? undefined : analysisPeriod?.endDate,
  period: analysisPeriod?.periodToDate as RangeType,
});
