import { selector, selectorFamily, waitForAll } from 'recoil';
import { analysis, type DetailedProxyMetadata } from 'venn-api';
import { createProxyMetadataKey, hasFeatureSelector, VennQueryClient } from 'venn-utils';
import { convertStudioSubjectToApiSubject } from './utils';
import { compact, isEmpty, mapValues, keyBy, omit, uniqBy, zipObject } from 'lodash';
import { allUniqViewSubjectsAndBenchmarks } from './configuration/allViewSubjects';
import { allBlockIdsState } from './grid';
import { blockBenchmarkSubjects } from './configuration/benchmark';
import { blockLimitedRequestSubjects } from './configuration/blockConfig';
import { requestSubjects } from './configuration/subjects';
import type { BlockId, StudioRequestSubject } from './types';
import { isReportState } from './configuration/view';
import { isReturnsBasedBlockState, blocksOnPage } from './configuration/print';

/** Returns ALL proxy infos for the entire view, regardless of if the block it comes from is returns-based. */
export const allProxyInfoState = selector<{ [subjectId: string]: DetailedProxyMetadata[] | null | undefined }>({
  key: 'allProxyInfo',
  get: async ({ get }) => {
    if (!get(hasFeatureSelector('proxy_disclosure_ff')) || !get(isReportState)) {
      return {};
    }

    const subjects = get(allUniqViewSubjectsAndBenchmarks);

    if (subjects.length === 0) {
      return {};
    }

    const reqSubjects = get(requestSubjects(subjects));
    const apiSubjects = reqSubjects.map((subject) => convertStudioSubjectToApiSubject(subject, 'PRIMARY', true));
    const queryKey = createProxyMetadataKey(apiSubjects);

    const queryClient = VennQueryClient.getInstance();
    await queryClient.invalidateQueries(queryKey);
    const result = await queryClient.fetchQuery(queryKey, () =>
      analysis({
        analyses: [
          {
            analysisType: 'DETAILED_PROXY_METADATA',
            relative: false,
            scenarios: [],
          },
        ],
        subjects: apiSubjects,
      }),
    );

    const subjectIds = apiSubjects.map((subject) => subject.id);
    return zipObject(subjectIds, result.content.analyses[0].detailedProxyMetadata);
  },
});

export const flattenedProxyMapState = selector<{ [fundId: string]: DetailedProxyMetadata | null | undefined }>({
  key: 'flattenedProxyMapState',
  get: ({ get }) => {
    const allProxiesInfo = get(allProxyInfoState);
    const flattenedProxyInfo = Object.values(allProxiesInfo)
      .flat()
      .filter((meta) => meta);
    return omit(
      {
        ...keyBy(flattenedProxyInfo, 'fundId'),
        ...keyBy(flattenedProxyInfo, 'parentId'),
      },
      'undefined',
      'null',
    );
  },
});

export type UsedProxyWithParents = DetailedProxyMetadata & {
  parentSubjectNames: string[];
};

export const hasProxyState = selectorFamily<boolean, string>({
  key: 'hasProxyInfo',
  get:
    (subjectId) =>
    ({ get }) =>
      !isEmpty(get(allProxyInfoState)[subjectId]),
});

/**
 * Returns info about all actively in-use proxies in the view.
 *
 * Excludes non-returns based blocks, because proxies don't affect non-returns based analyses.
 */
export const investmentProxiesState = selector<UsedProxyWithParents[]>({
  key: 'investmentProxiesState',
  get: ({ get }) => {
    if (!get(hasFeatureSelector('proxy_disclosure_ff')) || !get(isReportState)) {
      return [];
    }
    const blockIds = get(allBlockIdsState);
    return get(investmentUsedProxiesState(blockIds));
  },
});

/**
 * Returns true if there are any proxies actively in-use on the provided page index.
 *
 * Excludes non-returns based blocks, because proxies don't affect non-returns based analyses.
 */
export const hasUsedProxiesOnPageState = selectorFamily<boolean, number>({
  key: 'hasUsedProxiesOnPageState',
  get:
    (pageNumber) =>
    ({ get }) => {
      const blockIds = get(blocksOnPage(pageNumber)).filter((id) => get(isReturnsBasedBlockState(id)));
      const usedProxies = get(blockSubjectProxiesState(blockIds));
      return Object.values(usedProxies).some(({ proxies }) => !isEmpty(proxies));
    },
});

/**
 * Excludes non-returns based blocks, because proxies don't affect non-returns based analyses.
 */
const investmentUsedProxiesState = selectorFamily<UsedProxyWithParents[], BlockId[]>({
  key: 'investmentUsedProxiesState',
  get:
    (blockIds) =>
    ({ get }) => {
      const subjectIdToUsedProxies = get(blockSubjectProxiesState(blockIds));
      const usedProxyInfos = Object.values(subjectIdToUsedProxies);
      const fundIdToProxyInfo = usedProxyInfos.reduce<{ [fundId: string]: UsedProxyWithParents }>(
        (currentValue, { subject, proxies }) => {
          proxies.forEach((meta) => {
            const newMeta = currentValue[meta.fundId] ?? { ...meta, parentSubjectNames: [] };
            if (subject.portfolio || subject.fund?.assetType === 'BENCHMARK') {
              newMeta.parentSubjectNames.push(subject.name);
            }
            currentValue[meta.fundId] = newMeta;
          });
          return currentValue;
        },
        {},
      );
      return Object.values(fundIdToProxyInfo);
    },
});

/**
 * Returns a map of subject IDs to whether or not they have any proxies.
 *
 * If a block subject has no proxies, then the subject ID may be omitted entirely.This is a side effect of
 * the way the lookup is implemented.
 *
 * Excludes non-returns based blocks, because proxies don't affect non-returns based analyses.
 */
export const blockSubjectHasProxyState = selectorFamily<{ [subjectId: string]: boolean | undefined }, BlockId>({
  key: 'blockSubjectHasProxyState',
  get:
    (blockId) =>
    ({ get }) => {
      const blockSubjectProxies = get(blockSubjectProxiesState([blockId]));
      return mapValues(blockSubjectProxies, ({ proxies }) => !isEmpty(proxies));
    },
});

/**
 * Excludes non-returns based blocks, because proxies don't affect non-returns based analyses.
 */
export const blockSubjectProxiesState = selectorFamily<
  {
    [subjectId: string]: { subject: StudioRequestSubject; proxies: DetailedProxyMetadata[] };
  },
  BlockId[]
>({
  key: 'blockSubjectProxiesState',
  get:
    (rawBlockIds) =>
    ({ get }) => {
      if (!get(hasFeatureSelector('proxy_disclosure_ff')) || !get(isReportState)) {
        return {};
      }

      const filteredBlockIds = rawBlockIds.filter((id) => get(isReturnsBasedBlockState(id)));
      if (filteredBlockIds.length === 0) {
        return {};
      }

      const proxyInfoBySubjectId = get(allProxyInfoState);
      const blockSubjects = get(subjectsForBlockState(filteredBlockIds));

      return Object.fromEntries(
        compact(
          blockSubjects.map((subject) => {
            const proxies = proxyInfoBySubjectId[subject.id];
            return proxies ? [subject.id, { subject, proxies }] : null;
          }),
        ),
      );
    },
});

const subjectsForBlockState = selectorFamily<StudioRequestSubject[], BlockId[]>({
  key: 'subjectsForBlockState',
  get:
    (blockIds) =>
    ({ get }) => {
      const subjects = get(waitForAll(blockIds.map((id) => blockLimitedRequestSubjects(id)))).flat();
      const benchmarkSubjects = get(waitForAll(blockIds.map((id) => blockBenchmarkSubjects(id)))).flat();
      const benchmarkRequestSubjects = get(requestSubjects(benchmarkSubjects));
      return uniqBy([...subjects, ...benchmarkRequestSubjects], (subject) => subject.id);
    },
});
