import type React from 'react';
import type { SelectTypes } from 'venn-ui-kit';
import type { AnalysisSubject, AnalysisSubjectType, LibraryItemType, QuickFilter } from 'venn-utils';
import type {
  LibrarySearchEntity,
  SearchFund,
  ItemId,
  OperationResult,
  SearchQuery,
  LibrarySearchResult,
  AnalysisViewSearchResult,
  HelpArticleDoc,
  PrivateAssetSearchModeEnum,
} from 'venn-api';
import type { SearchMenuColumn } from 'venn-components';

export const DEFAULT_MAX_RESULTS = 10;

/**
 * General-purpose search item to be displayed in a VennSelect
 */
export interface GenericSearchMenuItem<T> {
  category: SearchMenuItemCategory;
  searchResult?: LibrarySearchEntity;
  isTagged?: boolean;
  taggedWith?: string;
  value?: T;
  label: string;
  viewInfo?: AnalysisViewSearchResult;
  helpArticle?: HelpArticleDoc;
}

export type SearchMenuItemCategory = AnalysisSubjectType | 'recent' | 'saved' | 'tag' | 'view' | 'article';

/**
 * Search item which is internally represented as a {@link AnalysisSubject}
 */
export type SearchMenuItem = GenericSearchMenuItem<AnalysisSubject>;
/**
 * Search item which is internally represented as a {@link SearchFund}
 */
export type SearchMenuFundItem = GenericSearchMenuItem<SearchFund>;

/**
 * Aggregation of all possible search menu item types
 */
export type GenericSearchType = SearchMenuItem | SearchMenuFundItem;

export interface SearchFilter {
  /**
   * The name to be displayed for the filter
   */
  name: string;
  /**
   * The library quick filter (if any) this filter corresponds to
   */
  quickFilter?: QuickFilter;
  /**
   * The library item type (if any) this filter corresponds to
   */
  itemType?: LibraryItemType;
  /**
   * Defines if search should be performed only among privates only/publics only or both
   */
  privateAssetSearchMode?: PrivateAssetSearchModeEnum;
}

export interface Placeholders {
  ids: ItemId[];
  type: SearchMenuItemCategory;
}

export interface GenericSearchMenuProps<IsMulti extends boolean = false> {
  /** The value passed into the innermost select component.
   * Leave this undefined to make it an uncontrolled component. */
  value?: SearchMenuItem | null;
  /**
   * If specified, getOptionValue(value) === getOptionValue(item) is used to find the selected value among the options.
   * Additionally, whenever the result of getOptionValue(value) changes, the options list is refreshed.
   */
  getOptionValue?: (item: SearchMenuItem) => string;
  onBlur?: () => void;
  /**
   * When true the search input field is automatically focused once the component is mounted.
   */
  autofocus?: boolean;
  investmentsOnly?: boolean;
  portfoliosOnly?: boolean;
  defaultMenuIsOpen?: boolean;
  excludedItems?: ItemId[];
  defaultValue?: SearchMenuItem;
  isClearable?: boolean;
  onClear?: () => void;
  disabled?: boolean;
  closeOnSelect?: boolean;
  /**
   * Called on all items displayed in the dropdown. Items are disabled if true is returned.
   */
  isOptionDisabled?: (option: SearchMenuItem) => boolean;
  /**
   * Called on all DISABLED items in the dropdown (see {@link isOptionDisabled}). Overrides the default message.
   */
  optionDisabledTooltipContent?: (option: SearchMenuItem) => string;
  /**
   * Function invoked when the query input changes
   * Useful if a parent component needs to have access to the current query in the search bar
   */
  onQueryChange?: (query: string) => void;
  /**
   * If false the dropdown will not show recently analyzed results when no query is present
   */
  showRecentlyAnalyzed?: boolean;
  /**
   * Classname for the component.
   */
  className?: string;
  /**
   * Classname prefix for inner components.
   */
  classNamePrefix?: string;
  /**
   * Initial text to search, defaults to the empty string
   */
  initialQuery?: string;
  /**
   * If true, display the compact layout removing the date range and identifier columns
   */
  shortPlaceholder?: boolean;
  /**
   * If true, the menu width is set to a fixed 700px instead of the width of the trigger
   */
  fixedMenuWidth?: boolean;
  /**
   * If true, the menu is opened in a portal, rather than a child DOM element
   */
  usePortal?: boolean;
  /**
   * If present overrides the default select input placeholder
   */
  customPlaceholder?: React.ReactNode;
  /**
   * If the search is for proxyable investments only
   */
  proxyable?: boolean;
  /**
   * If the search is for public assets only (default), private assets only, or all.
   */
  privateAssetSearchMode?: PrivateAssetSearchModeEnum;
  /**
   * If true, the will be rendered as a block below the search, rather than floating
   */
  displayResultsAsBlock?: boolean;
  /**
   * The columns to display for the search results. Defaults to DEFAULT_COLUMNS
   */
  columns?: SearchMenuColumn[];
  /**
   * The location of the search menu in the application
   */
  location: string;
  /**
   * If true, display quick filters above the results
   */
  showQuickFilters?: boolean;
  /**
   * Provide a footer to display below search results
   */
  footer?: React.FC<SelectTypes.MenuListProps<SearchMenuItem, IsMulti>>;
  /**
   * If true, clears search query on blur
   */
  clearQueryOnBlur?: boolean;
  /**
   * If true, when the search is unfocused it is dark and has small placeholder as in the universal search
   */
  darkPlaceholder?: boolean;
  /**
   * If provided, these items will be shown when there is no search instead of recently analyzed
   */
  placeholders?: Placeholders;
  /** Show a smaller search bar with limited columns */
  smallScreen?: boolean;
  /** Styles provided to the menu component for portal use */
  menuStyles?: React.CSSProperties;
  /**
   * If true, enable search for views, help articles
   */
  canSearchEverything?: boolean;
  /**
   * If true, always have the menu open
   */
  forceMenuIsOpen?: boolean;
  /**
   * If true include tags, defaults to true
   */
  includeTags?: boolean;
  /**
   * If true include benchmarks search pill (doesn't affect search yet), defaults to true
   */
  includeBenchmarks?: boolean;
  /**
   * Whether new styling that will be part of the new design system should be used.
   * Historically we've had buttons take shades of teal, refreshed styling takes shades of blue.
   */
  refreshedStyling?: boolean;

  /**
   * Whether or not to pin the already selected search item to the top of the search results, even if the selected
   * item is not returned in the results.
   */
  pinSelected?: boolean;
  /**
   * The max number of results possible for the search menu to show
   * Defaults to {@link DEFAULT_MAX_RESULTS} if unset
   */
  maxResults?: number;
  /**
   * Forces the search icon to appear in the search bar
   */
  forceShowSearchIcon?: boolean;
}

export type SearchType = (
  body?: Partial<SearchQuery>,
  signal?: AbortSignal,
) => Promise<OperationResult<LibrarySearchResult>>;

export interface CustomSelectProps<IsMulti extends boolean = false> {
  columns: SearchMenuColumn[];
  onClearSearch: () => void;
  optionDisabledTooltipContent?: (option: SearchMenuItem) => string;
  fixedMenuWidth?: boolean;
  showQuickFilters?: boolean;
  investmentsOnly: boolean;
  portfoliosOnly: boolean;
  privateAssetSearchMode: PrivateAssetSearchModeEnum;
  footer?: React.FC<SelectTypes.MenuListProps<SearchMenuItem, IsMulti>>;
  closeSearchMenu: () => void;
  onClear?: () => void;
  onTagsDropdown: (item: SearchMenuItem) => void;
  onTagsClose: (item: SearchMenuItem) => void;
  selectedTagDropdowns: SearchMenuItem[] | [];
  openedTags: SearchMenuItem[] | [];
  excludedItems?: ItemId[];
  smallScreen?: boolean;
  menuStyles?: React.CSSProperties;
  canSearchEverything?: boolean;
  /**
   * include the pill for filtering on tags only
   */
  includeTags?: boolean;
  /**
   * include the pill for filtering on benchmarks only
   */
  includeBenchmarks?: boolean;
  /**
   * The max number of results possible for the search menu to show
   */
  maxResults: number;
}

export interface CustomMultiSelectProps extends CustomSelectProps<true> {
  loading: boolean;
  selections: SearchMenuItem[] | [];
  onSelectAllWithTags: (items: SearchMenuItem[]) => void;
  onUnselectSelectAllWithTags: (items: SearchMenuItem[]) => void;
  onBulkTagSelect: (items: SearchMenuItem) => void;
  onBulkTagUnselect: (items: SearchMenuItem) => void;
  handleOnSelected: () => void;
  maxSelections?: number;
  menuTrailingContent?: (selectionsLeft: number) => JSX.Element | undefined;
  scrollAddIntoView?: boolean;
  shouldLimitSize: boolean;
}
