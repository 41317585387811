import React, { useCallback, useEffect, useRef } from 'react';
import type { ReactText } from 'react';
import { useHistory } from 'react-router-dom';
import { Notifications, NotificationType } from 'venn-ui-kit';
import { RedistributionCorrectMessage, RedistributionWarnMessage } from '../components/shared';
import { Routes, useHasFF } from 'venn-utils';
import { analysisViewCombinedIdState, redistributableState } from 'venn-state';
import { constSelector, useRecoilValue, useRecoilValueLoadable } from 'recoil';

const successToastProps = [<RedistributionCorrectMessage />, NotificationType.SUCCESS] as const;
const warnToastProps = [<RedistributionWarnMessage showLink />, NotificationType.WARNING] as const;

export const useRedistributableNotification = () => {
  const notificationEnabled = useHasFF('report_lab_export_watermark_ff');

  const history = useHistory();
  const notificationIdRef = useRef<ReactText>();
  const isReport = history.location.pathname.startsWith(Routes.REPORT_LAB_PATH);
  const viewId = useRecoilValue(analysisViewCombinedIdState);
  /** Defaults to undefined while the view is being initialized and the view ID is undefined. */
  const redistributableValueMaybe = useRecoilValueLoadable(
    viewId ? redistributableState(viewId) : constSelector(undefined),
  ).valueMaybe();

  /**
   * We use a record from viewId to state because this hook is used at a high enough level that it doesn't necessarily dismount between different views,
   * so it needs to handle being rendered for one view ID and then rerendered for a different view ID, and potentially back again.
   *
   * We store this as React state not Recoil state so that leaving Studio/RL for other pages and then coming back to Studio/RL causes notifications
   * to appear once more.
   *
   * We use a ref because we don't need to rerender when it changes.
   */
  const viewToLastStateRef = useRef<Readonly<{ [lastViewId: string]: boolean | undefined }>>({});
  /** We use this to detect when we've switched from one view to a different view. We use a ref because we don't need to rerender when it changes. */
  const lastViewIdRef = useRef<string | undefined>(undefined);

  /** Clear the notification even if it hasn't even appeared on the screen yet. */
  const clearNotification = useCallback(() => {
    if (notificationIdRef.current) {
      // Only dismiss active notifications, because inactive notifications might still be appearing.
      Notifications.dismiss(notificationIdRef.current);
    }
  }, []);

  /** Clear only notifications that have already become visible.  */
  const clearVisibleNotification = useCallback(() => {
    const notificationId = notificationIdRef.current;
    if (notificationId && Notifications.isActive(notificationId)) {
      // Only dismiss active notifications, because inactive notifications might still be appearing.
      Notifications.dismiss(notificationId);
    }
  }, []);

  // This effect clears the notification when leaving Studio/RL entirely or potentially when switching between RL and Studio.
  useEffect(() => {
    // Only clear as an effect cleanup, not as the initial cleanup
    return clearNotification;
  }, [clearNotification]);

  useEffect(() => {
    if (!isReport || !notificationEnabled) {
      clearNotification();
      return;
    }

    // Ignore loading states entirely. Don't show messages, don't hide messages. Instead, just wait to see what to do when it finishes loading.
    if (redistributableValueMaybe === undefined || viewId === null) {
      return;
    }

    const lastViewState = viewId ? viewToLastStateRef.current[viewId] : undefined;
    const lastViewId = lastViewIdRef.current;
    if (viewId) {
      viewToLastStateRef.current = { ...viewToLastStateRef.current, [viewId]: redistributableValueMaybe };
      lastViewIdRef.current = viewId;
    }

    if (lastViewId !== viewId) {
      // Clear the notification when switching between views within Studio/RL.
      // We do NOT want to clear any new not-yet-visible notifications however, because otherwise we would clear the notification on freshly loaded views.
      clearVisibleNotification();
    }

    // If it is a newly loaded view then we don't need to tell them that there is nothing redistributable
    // This is also helpful for new views, because new views go through a phase of having no ID, having a temporary ID, then having a permanent ID.
    if (lastViewState === undefined && redistributableValueMaybe) {
      return;
    }

    // Don't show the same message repeatedly.
    if (lastViewState === redistributableValueMaybe) {
      return;
    }

    const notificationId = notificationIdRef.current;
    if (notificationId && Notifications.isActive(notificationId)) {
      // Update the existing warning rather than creating duplicates
      if (redistributableValueMaybe) {
        Notifications.notifyUpdatePersistent(notificationId, ...successToastProps);
      } else {
        Notifications.notifyUpdatePersistent(notificationId, ...warnToastProps);
      }
    } else {
      // When views are loading they go through 3 different IDs before the first toastify even appears.
      // So we need to dismiss old IDs even if they're not even visible yet.
      clearNotification();
      const newNotificationId = redistributableValueMaybe
        ? Notifications.notifyPersistent(...successToastProps)
        : Notifications.notifyPersistent(...warnToastProps);
      notificationIdRef.current = newNotificationId;
    }
  }, [clearVisibleNotification, clearNotification, isReport, notificationEnabled, redistributableValueMaybe, viewId]);
};
