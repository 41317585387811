import React, { useCallback } from 'react';
import { Button } from 'venn-ui-kit';
import ManageInvestmentDataTrigger from './ManageInvestmentDataTrigger';
import { MANAGE_DATA_SECTION, type AnalysisSubject } from 'venn-utils';

export interface ManageDataLinkProps {
  fundId?: string;
  subject?: AnalysisSubject;
  label?: string;
  customRenderer?: (onClick: () => void, disabled?: boolean) => React.ReactNode;
  hideButton?: boolean;
  disabled?: boolean;
  defaultEditData?: boolean;
  forceSection?: MANAGE_DATA_SECTION | null;
  errorState?: boolean;
}

const ManageDataLink = ({
  fundId,
  subject,
  label,
  hideButton,
  disabled,
  customRenderer,
  defaultEditData,
  forceSection,
  errorState = false,
}: ManageDataLinkProps) => {
  const text = label || 'Manage Data';

  const renderer = useCallback(
    // @ts-expect-error: fixme
    (onClick) =>
      customRenderer ? (
        customRenderer(onClick)
      ) : (
        <Button disabled={disabled} onClick={onClick} icon="cog">
          {text}
        </Button>
      ),
    [text, disabled, customRenderer],
  );

  if ((!fundId && !subject) || subject?.fund?.assetType === 'BENCHMARK' || hideButton) {
    // We won't show manage data button for composite benchmarks or if no fundId or subject is provided
    return null;
  }

  return (
    <ManageInvestmentDataTrigger
      fundId={fundId ?? subject?.fund?.id}
      portfolioId={subject?.portfolio?.id}
      subjectType={subject?.type}
      forceSection={forceSection ?? (!subject?.userUploaded ? MANAGE_DATA_SECTION.ANALYSIS_RANGE : null)}
      defaultEditData={defaultEditData}
      customRenderer={renderer}
      errorState={errorState}
      location={errorState ? 'Analysis.BlockError' : 'Analysis'}
    />
  );
};

export default ManageDataLink;
