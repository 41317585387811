import React from 'react';
import type { FrequencyEnum } from 'venn-api';
import { getAppTitle, Icon, Tooltip } from 'venn-ui-kit';
import { Numbers } from 'venn-utils';
import StatefulNumericInput from '../../../stateful-numeric-input/StatefulNumericInput';
import { capitalize } from 'lodash';

interface LagInputProps {
  frequency: FrequencyEnum;
  numLags: number;
  suggestedNumLags: number | undefined;
  onLagUpdate: (value: number) => void;
}

const formatter = (value: string | number) => {
  return Numbers.formatNumber(value, 0);
};

const LagInput = ({ frequency, numLags, suggestedNumLags, onLagUpdate }: LagInputProps) => {
  const onValueChange = (val: number) => {
    onLagUpdate(Math.round(val));
  };

  // Limit to 1.5 years for quarterly, 1 year for monthly
  const maxNumLags = frequency === 'QUARTERLY' ? 6 : 12;

  const lagTooltip =
    suggestedNumLags && suggestedNumLags !== numLags
      ? `Default lag recommendation (${suggestedNumLags}) is based on ${getAppTitle()} analysis.`
      : `Default lag recommendation is based on ${getAppTitle()} analysis.`;

  return (
    <div className="font-bold text-[14px] flex flex-col gap-2">
      <div data-testid="lag-header" className="flex items-center">
        {capitalize(frequency)} Lags
        <Tooltip usePortal content={lagTooltip}>
          <Icon className="ml-1 text-venn-grey-80" type="circle-info" prefix="far" />
        </Tooltip>
      </div>
      <StatefulNumericInput
        min={1}
        max={maxNumLags}
        precision={0}
        value={`${numLags === 0 ? '' : numLags}`}
        formatter={formatter}
        onValueChange={onValueChange}
        className="w-[50px] !h-[32px]"
      />
    </div>
  );
};

export default LagInput;
