import React, { useState, useCallback } from 'react';
import { trim } from 'lodash';
import styled from 'styled-components';
import { Tooltip, GetColor, KeyCodes, SelectedItemPill, TooltipPosition } from 'venn-ui-kit';
import { Input } from './Input';

interface MultiEmailInputProps {
  /**
   * Emails with their validation statuses
   */
  values: EmailValidation[];
  /**
   * Text appearing on the input if there's no email being typed
   */
  placeholder: string;
  /**
   * Whether the whole input is in an error state
   */
  error: boolean;
  /**
   * Validation function called every time a new email is added
   */
  onValidate: (email: string) => Promise<EmailValidation>;
  /**
   * Called every time a new email is added or removed, after validation
   */
  onChange: (values: EmailValidation[]) => void;
}

export interface EmailValidation {
  address: string;
  valid: boolean;
  message: string;
}

const MultiEmailInput = ({ values, placeholder, error, onValidate, onChange }: MultiEmailInputProps) => {
  const [currentInput, setCurrentInput] = useState('');

  const updateEmails = useCallback(
    async (newEmail: string) => {
      const trimValue = trim(newEmail);
      if (trimValue.length === 0) {
        return;
      }
      const validatedEmail = await onValidate(trimValue);
      const newEmails: EmailValidation[] = [...values, validatedEmail];
      onChange(newEmails);
      setCurrentInput('');
    },
    [values, onChange, onValidate],
  );

  const handleKeyUp = useCallback(
    // @ts-expect-error: fixme
    (event) => {
      if (event.keyCode === KeyCodes.Enter || event.keyCode === KeyCodes.Space) {
        updateEmails(event.target.value);
      }
    },
    [updateEmails],
  );

  const handleKeyDown = useCallback(
    // @ts-expect-error: fixme
    (event) => {
      if (event.keyCode === KeyCodes.Delete || event.keyCode === KeyCodes.Backspace) {
        if (currentInput.length === 0 && values.length > 0) {
          onChange(values.slice(0, values.length - 1));
        }
      }
    },
    [onChange, values, currentInput],
  );

  const handleBlur = useCallback(
    // @ts-expect-error: fixme
    (event) => {
      event?.target.value && updateEmails(event?.target.value);
    },
    [updateEmails],
  );

  const handleChange = useCallback((value: string) => {
    setCurrentInput(value);
  }, []);

  const handleDelete = useCallback(
    (item: EmailValidation) => {
      const newList = values.filter((v) => v !== item);
      onChange(newList);
    },
    [onChange, values],
  );

  return (
    <EmailsBox error={error}>
      {values.map((email: EmailValidation) => (
        <Item key={email.address} email={email} onDelete={handleDelete} />
      ))}
      <EmailInput
        placeholder={placeholder}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        value={currentInput}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className="qa-email-input"
      />
    </EmailsBox>
  );
};

interface ItemProps {
  email: EmailValidation;
  onDelete: (item: EmailValidation) => void;
}

const Item = ({ email, onDelete }: ItemProps) => {
  const { message } = email;

  return (
    <Tooltip
      content={<ErrorMessageWrapper>{message}</ErrorMessageWrapper>}
      position={TooltipPosition.Bottom}
      isHidden={!message?.length}
    >
      <EmailItem
        label={email.address.toLocaleLowerCase()}
        value={email}
        // @ts-expect-error: TODO fix strictFunctionTypes
        onRemoveItem={onDelete}
        error={!email.valid}
      />
    </Tooltip>
  );
};

const EmailsBox = styled.div<{ error?: boolean }>`
  display: flex;
  background-color: ${GetColor.White};
  border-radius: 4px;
  border: 1px solid ${(props) => (props.error ? GetColor.DEPRECATED_DivergingColor.A3 : GetColor.Grey)};
  min-height: 37px;
  padding: 0 4px 6px 8px;
  text-align: left;
  flex-wrap: wrap;
`;

const EmailItem = styled(SelectedItemPill)`
  margin: 6px 5px 0;
  max-width: 320px;
  word-break: break-all;
`;

const EmailInput = styled(Input)`
  min-width: 130px;
  border: none;
  height: auto;
  margin: 6px 0 0;
  :focus {
    outline: none;
  }
  input:focus {
    outline: none;
  }
  flex-grow: 1;
`;

const ErrorMessageWrapper = styled.div`
  padding: 10px 15px;
  text-align: left;
`;

export default MultiEmailInput;
