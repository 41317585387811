import { compact, partial } from 'lodash';
import React, { useCallback, useContext } from 'react';
import { SimpleMenu } from 'venn-ui-kit';
import { MANAGE_DATA_SECTION, navigateToManageDataPage, type ObjectId, useHasFF, useModal } from 'venn-utils';
import { ActionsButton, GreyIcon, StyledBaseDropMenu } from '../AllocationStrategyActions';
import { CashflowSettingsModal } from '../../modals/cashflow-settings-modal';
import { useHistory } from 'react-router-dom';
import type { PrivatePortfolioNode } from 'venn-api';
import { useSendSignal } from 'venn-state';
import { UserContext } from '../../contexts';
import type { DropMenuProps } from 'venn-ui-kit/src/components/drop-menu/implementations/BaseDropMenu';

interface PrivatesFundActionsProps {
  onDelete: () => void;
  subject: ObjectId;
  modifiedPortfolio: PrivatePortfolioNode;
}

export const PrivatesFundActions = ({ onDelete, subject, modifiedPortfolio }: PrivatesFundActionsProps) => {
  const { hasPermission } = useContext(UserContext);
  const hasPrivatesCashFlowSettingsFF = useHasFF('privates_hyperparameters_ff');
  const hasPrivatesCashFlowSettingsEditingFF = useHasFF('privates_hyperparameters_editing_ff');
  const hasEditHyperparametersPermission = hasPermission('EDIT_HYPERPARAMETERS');
  const [isCashflowSettingsModalOpen, openCashflowSettingsModal, closeCashflowSettingsModal] = useModal();
  const history = useHistory();

  const refreshStudioView = useSendSignal({ type: 'WorkspaceConfigurationUpdate' });
  const options = compact([
    hasPrivatesCashFlowSettingsFF
      ? {
          label: 'CF Pacing Model Parameters',
          value: () =>
            hasPrivatesCashFlowSettingsEditingFF && hasEditHyperparametersPermission
              ? openCashflowSettingsModal()
              : navigateToManageDataPage(
                  history,
                  subject,
                  'Studio',
                  true,
                  MANAGE_DATA_SECTION.CASH_FLOW_PACING_SETTINGS,
                ),
        }
      : null,
    {
      label: 'Delete',
      value: onDelete,
    },
  ]);

  const triggerComponent: DropMenuProps<unknown>['triggerComponent'] = useCallback(
    (_, _highlighted, onToggle) => (
      <ActionsButton
        data-testid="private-allocator-fund-row-actions-button"
        onClick={partial(onToggle, undefined)}
        className="qa-strategy-actions"
      >
        <GreyIcon type="ellipsis-h" />
      </ActionsButton>
    ),
    [],
  );

  const menuComponent: DropMenuProps<unknown>['menuComponent'] = useCallback(
    (_, onCollapse, menuClassName) => (
      <SimpleMenu
        className={menuClassName}
        items={options}
        selected={null}
        onChange={(item) => {
          onCollapse();
          item?.value?.();
        }}
      />
    ),
    [options],
  );

  return (
    <>
      <StyledBaseDropMenu
        style={{ position: 'absolute' }}
        className="drop-menu"
        usePortal
        filteredItems={options}
        triggerComponent={triggerComponent}
        menuComponent={menuComponent}
      />
      {isCashflowSettingsModalOpen && (
        <CashflowSettingsModal
          onChangesApplied={refreshStudioView}
          onClose={closeCashflowSettingsModal}
          privatePortfolio={modifiedPortfolio}
          triggeringFundId={subject.privateFundId}
          source="STUDIO_PRIVATE_ALLOCATOR_PANEL"
        />
      )}
    </>
  );
};
