import type { FrequencyEnum, LibrarySearchEntity, ProxyOption, ProxyTypeEnum, SimpleFund } from 'venn-api';
import { AssetTypeEnum, SupportedErrorCodes } from 'venn-api';
import {
  type AnyDuringEslintMigration,
  FrequencyTypes,
  getFrequencyType,
  getNumberOfDatapoints,
  itemHasNoReturns,
  MOMENT_DURATIONS,
} from 'venn-utils';
import moment from 'moment';
import { DisabledProxyReason, type FundToProxy } from '../types';
import { DESMOOTHING_FAQ_HREF, INTERPOLATION_EXTRAPOLATION_FAQ_HREF } from 'venn-ui-kit';

export interface ReturnsRangeInfo {
  start?: number;
  end?: number;
  frequency?: FrequencyEnum;
}

export interface CategoryProxyTypeMetadata {
  helpLink: string;
  classPrefix: string;
}

export interface SelectedProxy {
  id: string;
  name: string;
}

export const typeSupportsDesmooth = (proxyType: ProxyTypeEnum) =>
  proxyType === 'DESMOOTH' || proxyType === 'DESMOOTH_INTERPOLATE';

export const typeSupportsInterpolate = (proxyType: ProxyTypeEnum) =>
  proxyType === 'INTERPOLATE' || proxyType === 'DESMOOTH_INTERPOLATE';

export const typeSupportsCategoryPicker = (proxyType: ProxyTypeEnum) =>
  typeSupportsDesmooth(proxyType) || typeSupportsInterpolate(proxyType);

export const getReturnsRangeFromError = (error: unknown): ReturnsRangeInfo | null =>
  (error as AnyDuringEslintMigration)?.content?.code === SupportedErrorCodes.NoFundReturns ? {} : null;

export const sortCategoryOptions = (a: ProxyOption, b: ProxyOption) => {
  if (a.category.displayName && b.category.displayName) {
    return a.category.displayName.localeCompare(b.category.displayName);
  }
  if (a.valid && !b.valid) {
    return -1;
  }
  if (b.valid && !a.valid) {
    return 1;
  }
  return 0;
};

const INTERPOLATION_DATA_THRESHOLD = 12;
const EXRAPOLATION_DATA_THRESHOLDS: { [K in FrequencyEnum]: number } = {
  DAILY: 90,
  WEEKLY: 26,
  MONTHLY: 24,
  QUARTERLY: 12,
  YEARLY: 0,
  UNKNOWN: 0,
};

const getDesmoothInterpolateFrequencyError = (
  proxyType: 'DESMOOTH' | 'DESMOOTH_INTERPOLATE' | 'INTERPOLATE',
  rawProxyReturnsRange?: ReturnsRangeInfo | null,
  rawInvestmentReturnsRange?: ReturnsRangeInfo | null,
): DisabledProxyReason | undefined => {
  const message =
    proxyType === 'DESMOOTH' ? DisabledProxyReason.DESMOOTH_LOWER_FREQ : DisabledProxyReason.INTERPOLATE_LOWER_FREQ;

  if (!rawProxyReturnsRange?.frequency || !rawInvestmentReturnsRange?.frequency) {
    return undefined;
  }

  return rawInvestmentReturnsRange?.frequency <= rawProxyReturnsRange?.frequency ? message : undefined;
};

/**
 * Determine whether a proxy selection should be disabled and the reason to disable it.
 * @param proxyType proxy method to use
 * @param investment the investment to proxy
 * @param rawInvestmentReturnsRange unproxied returns info of the investment being proxied, null if unavailable
 * @param rawProxyReturnsRange unproxied returns info of the investment to be used as a proxy, null if unavailable
 * @return the reason to disable the proxy type, null if it shouldn't be disabled
 */
export const getDisabledProxyTypeMessage = (
  proxyType: ProxyTypeEnum,
  investment: SimpleFund | FundToProxy,
  rawInvestmentReturnsRange: ReturnsRangeInfo | null,
  rawProxyReturnsRange: ReturnsRangeInfo | undefined | null,
): DisabledProxyReason | undefined => {
  if (investment.assetType === AssetTypeEnum.BENCHMARK) {
    return DisabledProxyReason.COMPOSITE_BENCHMARK_NOT_PROXYABLE;
  }

  const { start: proxyStart, end: proxyEnd, frequency: proxyFrequency } = rawProxyReturnsRange ?? {};
  if (rawProxyReturnsRange && itemHasNoReturns(proxyStart, proxyEnd)) {
    return DisabledProxyReason.PROXY_NO_RETURNS;
  }

  const isInterpolatable =
    ('interpolatable' in investment && investment.interpolatable) ||
    rawInvestmentReturnsRange?.frequency === 'QUARTERLY';
  // TODO(VER-816, VENN-28438): get this from the backend proxy metadata endpoint instead if it is cheap for the backend, so we can
  // dedupe this error-prone logic.
  const hasMinDataForInterpolation = () =>
    ('hasMinDataForInterpolation' in investment && investment.hasMinDataForInterpolation) ||
    (rawInvestmentReturnsRange?.start && rawInvestmentReturnsRange?.end && rawInvestmentReturnsRange?.frequency
      ? getNumberOfDatapoints(
          rawInvestmentReturnsRange.start,
          rawInvestmentReturnsRange.end,
          rawInvestmentReturnsRange.frequency,
        ) >= INTERPOLATION_DATA_THRESHOLD
      : false);

  switch (proxyType) {
    case 'EXTRAPOLATE':
      if (rawInvestmentReturnsRange?.frequency === 'QUARTERLY') {
        return DisabledProxyReason.EXTRAPOLATE_QUARTERLY;
      }
      if (
        rawProxyReturnsRange?.frequency &&
        rawInvestmentReturnsRange?.frequency &&
        rawInvestmentReturnsRange.frequency < rawProxyReturnsRange.frequency
      ) {
        return DisabledProxyReason.EXTRAPOLATE_LOWER_FREQ;
      }
      if (
        rawProxyReturnsRange?.end &&
        rawInvestmentReturnsRange?.end &&
        rawProxyReturnsRange.end <= rawInvestmentReturnsRange.end
      ) {
        return DisabledProxyReason.EXTRAPOLATE_DOES_NOT_EXTEND;
      }
      if (
        rawInvestmentReturnsRange?.frequency &&
        rawInvestmentReturnsRange.start &&
        rawInvestmentReturnsRange.end &&
        getNumberOfDatapoints(
          rawInvestmentReturnsRange.start,
          rawInvestmentReturnsRange.end,
          rawInvestmentReturnsRange.frequency,
        ) < EXRAPOLATION_DATA_THRESHOLDS[rawInvestmentReturnsRange.frequency]
      ) {
        return DisabledProxyReason.EXTRAPOLATE_NOT_ENOUGH_DATA;
      }
      return undefined;
    case 'BACKFILL':
      // disable if returns info isn't available (yet)
      if (!rawInvestmentReturnsRange) {
        return DisabledProxyReason.INVESTMENT_RETURNS_UNAVAILABLE;
      }
      if (isInterpolatable) {
        return DisabledProxyReason.BACKFILL_QUARTERLY;
      }

      const {
        start: investmentStart,
        end: investmentEnd,
        frequency: investmentFrequency = FrequencyTypes.DAILY, // backend default for investments with no returns
      } = rawInvestmentReturnsRange;
      const investmentFrequencyType = getFrequencyType(investmentFrequency);

      // if there's no selected proxy, allow backfilling
      if (!rawProxyReturnsRange) {
        return undefined;
      }

      const isProxyFrequentEnough = getFrequencyType(proxyFrequency) <= investmentFrequencyType; // See FrequencyTypes: Daily = 1, Weekly = 2, ...
      if (!isProxyFrequentEnough) {
        return DisabledProxyReason.BACKFILL_TOO_INFREQUENT;
      }

      if (itemHasNoReturns(investmentStart, investmentEnd)) {
        return undefined;
      }

      return (
        // proxy should have earlier data than the investment, have data at least until
        // right before the investment (i.e. no gaps after backfilling)
        proxyStart! < investmentStart! && proxyEnd! >= getPreviousDate(investmentStart!, investmentFrequencyType)
          ? undefined
          : DisabledProxyReason.BACKFILL_INSUFFICIENT_HISTORY
      );
    case 'SUBSTITUTE':
      return investment.investmentSource === 'VENN' ? DisabledProxyReason.SUBSTITUTE_SYSTEM_FUND : undefined;
    case 'INTERPOLATE':
      return !isInterpolatable
        ? DisabledProxyReason.INTERPOLATE_NONQUARTERLY
        : !hasMinDataForInterpolation()
          ? DisabledProxyReason.INTERPOLATE_NOT_ENOUGH_DATA
          : getDesmoothInterpolateFrequencyError(proxyType, rawProxyReturnsRange, rawInvestmentReturnsRange);
    case 'DESMOOTH':
      return rawInvestmentReturnsRange?.frequency === 'DAILY'
        ? DisabledProxyReason.DESMOOTH_DAILY
        : !hasMinDataForInterpolation()
          ? DisabledProxyReason.DESMOOTH_NOT_ENOUGH_DATA
          : getDesmoothInterpolateFrequencyError(proxyType, rawProxyReturnsRange, rawInvestmentReturnsRange);
    case 'DESMOOTH_INTERPOLATE':
      return !isInterpolatable
        ? DisabledProxyReason.INTERPOLATE_NONQUARTERLY
        : !hasMinDataForInterpolation()
          ? DisabledProxyReason.INTERPOLATE_NOT_ENOUGH_DATA
          : getDesmoothInterpolateFrequencyError(proxyType, rawProxyReturnsRange, rawInvestmentReturnsRange);
    default:
      return undefined;
  }
};

export const getCategoryProxyTypeMetadata = (proxyType: ProxyTypeEnum): CategoryProxyTypeMetadata => {
  switch (proxyType) {
    case 'INTERPOLATE':
      return {
        helpLink: INTERPOLATION_EXTRAPOLATION_FAQ_HREF,
        classPrefix: 'interpolation',
      };
    case 'DESMOOTH':
      return {
        helpLink: DESMOOTHING_FAQ_HREF,
        classPrefix: 'desmooth',
      };
    case 'DESMOOTH_INTERPOLATE':
      return {
        helpLink: DESMOOTHING_FAQ_HREF,
        classPrefix: 'desmooth-interpolation',
      };
    default:
      return { helpLink: '/', classPrefix: 'unknown' };
  }
};

export const getDisabledProxyMessage = (
  potentialProxy: LibrarySearchEntity,
  proxyType: ProxyTypeEnum,
  investment: SimpleFund | FundToProxy,
  rawInvestmentReturnsRange: ReturnsRangeInfo | null,
): DisabledProxyReason | undefined => {
  return getDisabledProxyTypeMessage(proxyType, investment, rawInvestmentReturnsRange, {
    start: potentialProxy.unproxiedStartRange,
    end: potentialProxy.unproxiedEndRange,
    frequency: potentialProxy.unproxiedFrequency,
  });
};

const getPreviousDate = (date: number, frequency: FrequencyTypes): number => {
  const dateMoment = moment.utc(date);
  return dateMoment.subtract(1, MOMENT_DURATIONS[frequency]).valueOf();
};
