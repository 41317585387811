import React, { PureComponent } from 'react';
import type { TrackAnalysisProps } from 'venn-components';
import { DrawdownHistoricalAnalysisChart, FailedAnalysisInfo } from 'venn-components';
import type { SharedProps } from '../types';
import Loading from './Loading';

interface DrawdownProps extends SharedProps {
  trackingProps: TrackAnalysisProps;
  getHeaderOffset?: () => number;
}

class Drawdown extends PureComponent<DrawdownProps> {
  render() {
    const { analyses, analysisConfig, onResetTimeFrame, getHeaderOffset, trackingProps, downloadMetaData } = this.props;
    const { trackingId, updateAnalysisStatusForTracking, blockNames, blockTitles } = trackingProps;
    const { relative, category } = analysisConfig;
    const drawdownErrorMessage = analyses?.results?.factorDrawdown?.message;
    if (drawdownErrorMessage) {
      return (
        <FailedAnalysisInfo
          subject={analyses?.subject}
          analysesPeriod={analyses?.analysesPeriod}
          onResetAnalysisPeriod={onResetTimeFrame}
          error={{
            message: drawdownErrorMessage?.text,
            code: drawdownErrorMessage?.code,
          }}
          regressionName="Drawdown Analysis"
          isAdvancedAnalysis
          relativeToBenchmark={relative}
          categoryActive={analysisConfig.category === 'ON'}
          blockNames={blockNames}
          blockTitles={blockTitles}
          updateAnalysisStatusForTracking={updateAnalysisStatusForTracking}
          trackingId={trackingId}
        />
      );
    }
    // Converter function will make an empty factorDrawdown object,
    // When the user switch analysis type, to prevent loading empty drawdown state
    // we need to check if there is any factor drawdown data loaded yet.
    const hasFactorDrawdownLoaded = analyses?.results?.factorDrawdown?.subject;
    if (!analyses || !analyses.results || !hasFactorDrawdownLoaded) {
      return <Loading />;
    }
    const factorDrawdown = analyses?.results?.factorDrawdown;
    const factorDrawdownSubject = analyses?.subject;
    return (
      <DrawdownHistoricalAnalysisChart
        analysisGroup={factorDrawdown}
        analysesPeriod={analyses?.analysesPeriod}
        onResetTimeFrame={onResetTimeFrame}
        subject={factorDrawdownSubject}
        benchmark={analysisConfig.benchmark}
        relative={relative}
        categoryActive={category === 'ON'}
        getHeaderOffset={getHeaderOffset}
        trackingProps={trackingProps}
        downloadMetaData={downloadMetaData}
      />
    );
  }
}

export default Drawdown;
