import React, { useCallback, useEffect, useRef, useState } from 'react';
import { EditableText } from 'venn-ui-kit';
import type { UseMetaDataReturn } from 'venn-components';

interface EditabeInvestmentNameProps {
  useMetaDataReturn: UseMetaDataReturn;
  disabled?: boolean;
  onChange?: (name: string) => void;
}

const EditableInvestmentName: React.FC<React.PropsWithChildren<EditabeInvestmentNameProps>> = ({
  useMetaDataReturn,
  disabled,
  onChange,
}: EditabeInvestmentNameProps) => {
  const [error, setError] = useState<boolean>();
  const { metaData, metaDataError, updateMetaData } = useMetaDataReturn;
  const originalName = useRef(metaData?.name);
  const [editedName, setEditedName] = useState(metaData?.name);

  useEffect(() => {
    if (metaData?.name && metaData.name !== originalName.current) {
      originalName.current = metaData.name;
      setEditedName(metaData.name);
    }
  }, [metaData]);

  useEffect(() => {
    if (metaDataError) {
      setEditedName(metaData?.name);
    }
  }, [metaData, metaDataError]);

  const onEditName = useCallback(
    async (name: string) => {
      if (!metaData) {
        return;
      }
      setError(false);
      if (name === metaData.name || !name) {
        setEditedName(metaData.name);
        return;
      }
      await updateMetaData({ ...metaData, name: editedName });

      if (metaDataError) {
        setEditedName(metaData?.name);
      } else {
        onChange?.(name);
      }
    },
    [metaData, updateMetaData, editedName, metaDataError, onChange],
  );

  const handleInputChange = useCallback(
    // @ts-expect-error: fixme
    (e) => {
      setError(!e.target.value);
      setEditedName(e.target.value);
    },
    [],
  );

  return (
    <EditableText
      onChange={handleInputChange}
      onSubmit={onEditName}
      value={editedName}
      error={error}
      disabled={disabled}
      iconSize={16}
      inputClassName="field-sizing-content min-w-52 max-w-[70vw]"
    />
  );
};

export default EditableInvestmentName;
