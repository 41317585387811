import type { GridApi, IRowNode } from 'ag-grid-community';
import type { BulkManageRow } from '../types';

import { isNil } from 'lodash';
import type { Portfolio } from 'venn-api';
import { isBulkManageTotalRow, isHistoricalBulkManageInvestmentRow } from '../utils';

type Props = {
  portfolio: Portfolio | undefined;
  selectedAllocationDate: string;
  allocationDates: number[];
  gridApi: GridApi | undefined;
  showAllocations: boolean;
  showLiquidatedInvestments: boolean;
  showLiquidatedInvestmentsToggle: boolean;
};
export const useInvestmentFilter = ({
  portfolio,
  selectedAllocationDate,
  allocationDates,
  gridApi,
  showAllocations,
  showLiquidatedInvestments,
  showLiquidatedInvestmentsToggle,
}: Props) => {
  const historical = !!portfolio?.historical;

  const isExternalFilterPresent = () => historical;
  const doesExternalFilterPass = (node: IRowNode<BulkManageRow>) => {
    if (showLiquidatedInvestmentsToggle) {
      if (allocationDates.length === 0 || showLiquidatedInvestments) {
        resetAllRowVisibility(node);
        return true;
      }
    }

    const date = showLiquidatedInvestmentsToggle
      ? allocationDates[allocationDates.length - 1]
      : Number(selectedAllocationDate);
    const result = filterEmptyFundsAndStrategies(node, date, historical);
    gridApi?.onRowHeightChanged();
    return result;
  };

  return {
    isExternalFilterPresent:
      historical && (showAllocations || showLiquidatedInvestmentsToggle) ? isExternalFilterPresent : undefined,
    doesExternalFilterPass:
      historical && (showAllocations || showLiquidatedInvestmentsToggle) ? doesExternalFilterPass : undefined,
  };
};

/**
 * Resets the row visibility for an AG-Grid table that may have been filtered.
 *
 * @param node the node to recursively reset
 */
const resetAllRowVisibility = (node: IRowNode<BulkManageRow>) => {
  node.setRowHeight(undefined);
  node.childrenAfterFilter?.forEach((child) => resetAllRowVisibility(child));
};

/**
 * This method actually filters the visibility of rows and their parents if they have 0 allocation or
 * their children all have 0 allocations (or there are no children).  It also returns true if a row
 * should be visible for use by a filter for AG-Grid.
 *
 * @param node the node to recursively check
 * @param selectedAllocationDate the date to check allocations for
 * @param historical whether the portfolio is historical or not
 * @returns true if the row should be visible, false if it should be hidden (height set to 0 as well)
 */
const filterEmptyFundsAndStrategies = (
  node: IRowNode<BulkManageRow>,
  selectedAllocationDate: number,
  historical: boolean,
) => {
  node.setRowHeight(undefined);
  if (!historical || isBulkManageTotalRow(node.data)) {
    return true;
  }

  if (!node.group) {
    if (
      isHistoricalBulkManageInvestmentRow(node.data) &&
      node.data?.allocation[selectedAllocationDate] !== 0 &&
      !isNil(node.data?.allocation[selectedAllocationDate])
    ) {
      node.setRowHeight(undefined);
      return true;
    }

    node.setRowHeight(0);
    return false;
  }

  if (isNil(node.childrenAfterFilter) || node.childrenAfterFilter.length === 0) {
    node.setRowHeight(0);
    return false;
  }

  if (
    node.childrenAfterFilter.reduce(
      (acc, child) => !filterEmptyFundsAndStrategies(child, selectedAllocationDate, historical) && acc,
      true,
    )
  ) {
    node.setRowHeight(0);
    return false;
  }

  node.setRowHeight(undefined);
  return true;
};
