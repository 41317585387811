import { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export const RangeCellClass = 'range-cell';

export const BoldRowGroupCellClass = 'ag-bold-group-row';
export const AllocationCellClass = 'allocation-cell';
export const AllocationHeaderCellClass = 'allocation-header-cell';
export const AllocationHeaderGroupCellClass = 'allocation-header-group-cell';

export const AxisRowClass = 'axis-cell';
export const DisabledCellClass = 'disabled-cell';

export const columnStyles = css`
  .${AllocationHeaderCellClass} {
    text-align: right;
    border-right: 1px solid ${GetColor.LightGrey2} !important;

    :hover {
      background-color: inherit;
      border-left: inherit;
      border-right: inherit;
    }

    .ag-header-cell-label {
      justify-content: flex-end;
    }
  }

  .${BoldRowGroupCellClass} {
    font-weight: bold;
  }

  .${AllocationCellClass} {
    text-align: right;
  }

  .${AllocationHeaderGroupCellClass} {
    width: 100%;
    height: 100%;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .ag-header-group-cell {
      background-color: ${GetColor.GreyScale.Grey10};
      padding: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  .${RangeCellClass} {
    padding: 0;
    border-width: 0;
    border-right-width: 1px;
  }

  .${DisabledCellClass} {
    color: ${GetColor.HintGrey};
  }

  .${AxisRowClass} {
    border-bottom: 1px solid ${GetColor.Black};
    .ag-cell {
      border: none;
      overflow: visible;
    }
  }
`;
