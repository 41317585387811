import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import FactorLensesContext from '../../contexts/factor-lenses-context';
import { useBulkFundData } from './useBulkFundData';
import type { AgGridReact } from 'ag-grid-react';
import { type AnalysisSubject } from 'venn-utils';
import { useModal } from 'venn-utils';
import { getRangeLogic } from '../analysisPeriodUtils';
import { type BulkManageRow } from '../types';
import { isBulkManageAxisRow, isBulkManageFundRow, rowHasHistoricalError } from '../utils';
import type { RowClassRules, SelectionChangedEvent } from 'ag-grid-community';
import { compact, uniq } from 'lodash';
import { AxisRowClass } from '../columns';
import type { ProxyMetaWithName } from '../../modals/pickers/proxy-picker/BulkProxyPicker';
import type { FundToProxy } from 'venn-components';
import type { FundToBulkProxy } from '../../modals/pickers/types';
import type { Portfolio } from 'venn-api';

const getPortfolioAllocDates = (portfolio?: Portfolio): number[] =>
  compact(
    uniq(
      [
        portfolio?.closingAllocationsTs?.flatMap((ts) => ts[0]) ?? [],
        portfolio?.children.flatMap((child) => getPortfolioAllocDates(child)),
      ].flat(),
    ),
  ).sort((a, b) => a - b);

type Props = {
  subject: AnalysisSubject;
  shouldHighlightRowsWithHistoricalErrors: boolean;
};
export const useBulkManagementTable = ({ subject, shouldHighlightRowsWithHistoricalErrors }: Props) => {
  const [showLiquidatedInvestments, setShowLiquidatedInvestments] = useState(true);

  const { primaryFactorLens } = useContext(FactorLensesContext);
  const { uniqFunds, rangeDataByFund, proxyDataByFund, primaryRangeAnalysis, rangeAnalysis, benchmarkInfo } =
    useBulkFundData(subject);

  const gridRef = useRef<AgGridReact | null>(null);
  const [selectedFundIds, setSelectedFundIds] = useState<string[]>([]);
  const [openedSecondaryId, setOpenedSecondaryId] = useState<string | undefined>();
  const [isPickerOpen, onPickerOpen, onPickerClose] = useModal();

  useEffect(() => {
    if (!isPickerOpen) {
      setOpenedSecondaryId(undefined);
    }
  }, [isPickerOpen]);

  const rangeData = useMemo(() => {
    if (!rangeAnalysis) {
      return undefined;
    }
    return getRangeLogic(true, rangeAnalysis, primaryRangeAnalysis, { short: true });
  }, [primaryRangeAnalysis, rangeAnalysis]);

  const onClearSelected = useCallback(() => {
    setSelectedFundIds([]);
    gridRef.current?.api.deselectAll();
  }, []);

  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const onSetSetSelectedDate = useCallback(
    (date: string | undefined) => {
      onClearSelected();
      setSelectedDate(date);
    },
    [onClearSelected],
  );

  const allocationDates = useMemo(() => getPortfolioAllocDates(subject?.portfolio), [subject?.portfolio]);
  const selectedAllocationDate = selectedDate ?? String(allocationDates?.[allocationDates.length - 1]);

  const onSelectionChanged = useCallback((event: SelectionChangedEvent<BulkManageRow, unknown>) => {
    const rows = event.api.getSelectedRows();
    setSelectedFundIds(compact(rows.map((row) => isBulkManageFundRow(row) && row.investment?.id)));
  }, []);
  const onProxyChange = useCallback(
    (_: ProxyMetaWithName[], failedToProxyFunds: (FundToProxy | FundToBulkProxy)[]) => {
      if (openedSecondaryId) {
        // If we were open due to a secondary ID, don't change the selection.
        return;
      }

      const gridApi = gridRef.current?.api;
      if (gridApi) {
        const fundIds = failedToProxyFunds.map((f) => f.id);
        const fundIdSet = new Set(fundIds);
        gridApi.deselectAll();
        gridApi.forEachNode((rowNode) => {
          if (fundIdSet.has(rowNode.data.investment?.id)) {
            rowNode.setSelected(true);
          }
        });
      }
    },
    [openedSecondaryId],
  );

  const selectedFunds = useMemo(
    () => uniqFunds.filter((fund) => selectedFundIds.includes(fund.id)),
    [selectedFundIds, uniqFunds],
  );

  const rowClassRules = useMemo(
    (): RowClassRules<BulkManageRow> => ({
      [AxisRowClass]: (params) => isBulkManageAxisRow(params.data),
      '[&&]:bg-venn-error-highlight': ({ data }) =>
        rowHasHistoricalError(data) && shouldHighlightRowsWithHistoricalErrors,
    }),
    [shouldHighlightRowsWithHistoricalErrors],
  );

  return {
    isPickerOpen,
    onPickerClose,
    openedSecondaryId,
    uniqFunds,
    selectedFunds,
    onProxyChange,
    rangeAnalysis,
    proxyDataByFund,
    rangeDataByFund,
    onClearSelected,
    onPickerOpen,
    gridRef,
    onSelectionChanged,
    groupHeaderHeight: 30,
    rowClassRules,
    selectedAllocationDate,
    allocationDates,
    selectedDate,
    onSetSetSelectedDate,
    setOpenedSecondaryId,
    setSelectedFundIds,
    showLiquidatedInvestments,
    benchmarkInfo,
    rangeData,
    primaryFactorLens,
    setShowLiquidatedInvestments,
  };
};
