import React from 'react';
import { TooltipLink, FORECASTS_FAQ_HREF } from 'venn-ui-kit';

const baseText =
  'Forecast metrics represent a forward looking view on performance. They are calculated using up to 3 years (minimum of 1 year) of daily or monthly data to estimate factor betas and the residual, which are then lined up with your factor forecasts.';

export const LongTermForecastTooltipForHistoricals = () => {
  const historicalsText =
    'Portfolios with historical allocations use the latest reported allocations, adjusted for market drift, for calculations.';
  const tooltipText = `${baseText} ${historicalsText}`;
  return <LongTermForecastTooltipHelper tooltipText={tooltipText} />;
};

export const LongTermForecastTooltip = () => {
  return <LongTermForecastTooltipHelper tooltipText={baseText} />;
};

const LongTermForecastTooltipHelper = ({ tooltipText }: { tooltipText: string }) => {
  return (
    <TooltipLink
      positions={{
        top: -205,
        left: -260,
      }}
      text={tooltipText}
      href={FORECASTS_FAQ_HREF}
      top
    />
  );
};
