import { CELL_BORDER_WIDTH, CELL_HORIZONTAL_PADDING, DataGrid, UserContext } from 'venn-components';
import { analyticsService, Dates, findSelectedContext, isRequestSuccessful, plural, useHasFF } from 'venn-utils';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { DateRange } from 'venn-ui-kit';
import { Button, DateRangePicker, Notifications, NotificationType, Pagination, Tooltip, GetColor } from 'venn-ui-kit';
import type { DocumentSortTypeEnum } from 'venn-api';
import { getDocumentContent, getDocumentsZip, searchUserDocuments } from 'venn-api';
import type {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  SelectionChangedEvent,
  RowSelectionOptions,
  GetRowIdParams,
} from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';

import { compact, noop } from 'lodash';
import type { DocumentWithUIState } from './types';
import moment from 'moment';
import styled from 'styled-components';
import { CheckboxMenuHeaderRenderer } from './CheckboxMenuHeaderRenderer';
import ZeroSavedViewsEmptyState from './ZeroSavedViewsEmptyState';
import saveAs from 'file-saver';
import { primaryFactorLens, useCachedLoadableValue } from 'venn-state';

const DEFAULT_RANGE: DateRange = {
  from: moment.utc('2022/01/01').valueOf(),
  to: moment.utc().endOf('day').valueOf(),
};
const MAX_RANGE: DateRange = {
  from: moment.utc('2000/01/01').valueOf(),
  to: moment.utc().endOf('day').valueOf(),
};

const RESULTS_PER_PAGE = 25; // TODO: it would be nice to let user change this

export const ARCHIVE_TABLE_BASE_WIDTH = 840;
export const ARCHIVE_TABLE_PADDING = 40;
export const OWNERSHIP_COLUMN_WIDTH = 200;

const defaultColumnStyling = {
  border: 'none',
  alignItems: 'center',
  display: 'flex',
};

interface ArchiveState {
  dateRange: DateRange;
  page: number;
}

type ArchiveTableRow = DocumentWithUIState & {
  ownershipName: string;
};

export const ArchiveTable = () => {
  const hasContextSwitching = useHasFF('context_switching');
  const userContext = useContext(UserContext);
  const gridRef = useRef<AgGridReact>(null);
  const factorLens = useCachedLoadableValue(primaryFactorLens);

  const [archiveState, setArchiveState] = useState<ArchiveState>({
    page: 1,
    dateRange: DEFAULT_RANGE,
  });
  // We surprisingly need both of the date ranges to keep everything in sync.
  // Without ref, setDateRange doesn't affect the server side datasource.
  // When it was registered, it captured some initial value of dateRange in a closure,
  // which would NOT be updated with setDateRange(). See the blog below:
  // https://dmitripavlutin.com/react-hooks-stale-closures/
  // Additionally, using only ref is not enough. We need to pass the date range
  // to the date picker, and just updating ref in-place is not enough to trigger the re-render and causes bugs.
  // See also: https://stackoverflow.com/questions/29361703/react-refs-do-not-update-between-render
  const dateRangeRef = useRef<DateRange>(DEFAULT_RANGE);
  // useEffect makes sure the two are continuously in sync
  useEffect(() => {
    dateRangeRef.current = archiveState.dateRange;
  }, [archiveState.dateRange]);

  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [totalResults, setTotalResult] = useState<number>();

  const isGridEmpty = totalResults === 0;
  const addOwnershipInfo = useCallback(
    (results: DocumentWithUIState[]) => {
      return results.map(
        (view): ArchiveTableRow => ({
          ...view,
          ownershipName:
            findSelectedContext(view.ownerContextId, userContext.profileSettings?.availableContexts)?.value.context
              .name ?? 'Global',
        }),
      );
    },
    [userContext.profileSettings?.availableContexts],
  );

  const areAllVisibleRowsChecked = useCallback(() => {
    const api = gridRef.current?.api;
    if (!api) {
      return false;
    }
    let checkedRows = 0;
    const firstDisplayedRow = api.getFirstDisplayedRowIndex();
    const lastDisplayedRow = api.getLastDisplayedRowIndex();
    for (let currentRow = firstDisplayedRow; currentRow <= lastDisplayedRow; ++currentRow) {
      checkedRows += api.getDisplayedRowAtIndex(currentRow)?.isSelected() ? 1 : 0;
    }
    return checkedRows > 0 && checkedRows === lastDisplayedRow - firstDisplayedRow + 1;
  }, []);

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setGridOption('serverSideDatasource', {
      getRows: (getRowParams: IServerSideGetRowsParams) => {
        const { request } = getRowParams;
        const { startRow, sortModel } = request;
        const { colId, sort } = sortModel[0] ?? {
          colId: 'updated_time',
          sort: 'desc',
        };
        const pageNumber = (startRow ?? 0) / RESULTS_PER_PAGE;
        searchUserDocuments({
          pageNumber,
          resultsPerPage: RESULTS_PER_PAGE,
          query: {
            ownerContextId: userContext.currentContext,
            fileTypes: ['PDF'],
            startTime: dateRangeRef.current.from,
            endTime: moment.utc(dateRangeRef.current.to).add(1, 'day').valueOf(),
            sortBy: sortColumnsEnumMapping[colId] as DocumentSortTypeEnum,
            order: sort,
          },
        })
          .then((result) => {
            if (isRequestSuccessful(result)) {
              getRowParams.success({
                rowData: addOwnershipInfo(result.content.results),
                rowCount: result.content.totalResults,
              });
              setTotalResult(result.content.totalResults);
              analyticsService.searchQueryEntered({
                query: '',
                location: 'archive',
                visibleResults: result.content.results.length,
                totalResults: result.content.totalResults,
                filterValues: {
                  page: pageNumber,
                  to: new Date(dateRangeRef.current.to!).toLocaleDateString(),
                  from: new Date(dateRangeRef.current.from!).toLocaleDateString(),
                  sortBy: colId,
                  order: sort,
                },
              });
            } else {
              getRowParams.fail();
            }
          })
          .catch(getRowParams.fail);
      },
    });

    params.api.sizeColumnsToFit(ARCHIVE_TABLE_BASE_WIDTH + (hasContextSwitching ? OWNERSHIP_COLUMN_WIDTH : 0));
  };

  const headerCheckboxSelected = areAllVisibleRowsChecked();
  const archiveTableColumns: ColDef<ArchiveTableRow>[] = useMemo(
    () =>
      compact([
        {
          // Dummy column to show checkboxes and our custom header checkbox
          maxWidth: 30,
          minWidth: 30,
          headerComponent: CheckboxMenuHeaderRenderer,
          headerComponentParams: {
            selected: headerCheckboxSelected,
            setSelectedRecoilState: noop,
          },
          checkboxSelection: true,
        },
        hasContextSwitching
          ? {
              headerName: 'Ownership',
              field: 'ownershipName',
              sortable: false, // TODO: our backend does not support sorting this column
              flex: 1,
              wrapText: true,
              autoHeight: true,
            }
          : null,
        {
          headerName: 'Name',
          field: 'name',
          sortable: true,
          flex: 3.5,
        },
        {
          headerName: 'Saved By',
          field: 'createdByUser.name',
          sortable: false, // Database holds user IDs and there's no good way to sort by name
          flex: 1,
          wrapText: true,
          autoHeight: true,
        },
        {
          headerName: 'Export Time',
          field: 'updatedTime',
          sortable: true,
          type: 'rightAligned',
          valueFormatter: (item) => Dates.toDayMonthYearTime(item.value),
          cellStyle: {
            ...defaultColumnStyling,
            justifyContent: 'flex-end',
          },
          flex: 1,
          minWidth: 120,
        },
      ]),
    [hasContextSwitching, headerCheckboxSelected],
  );

  const trackDocumentsDownload = useCallback((items: ArchiveTableRow[]) => {
    analyticsService.documentsDownloaded({
      location: 'archive',
      downloadNums: items.length,
      downloadIds: items.map((item) => item.uuid),
    });
  }, []);

  const onDownload = useCallback(async () => {
    const items = gridRef.current?.api.getSelectedRows();
    if (items === undefined || items?.length === 0) {
      return;
    }
    const downloadMsg = plural(items.length, {
      1: 'Downloading {{count}} document...',
      other: 'Downloading {{count}} documents...',
    });
    const toastId = Notifications.notify(downloadMsg, NotificationType.LOADING);
    if (items.length === 1) {
      const { uuid, version } = items[0];
      await getDocumentContent(uuid, version)
        .then((result) => {
          saveAs(result.content, items[0].name);
          Notifications.notifyUpdate(toastId, `${items[0].name} successfully downloaded`, NotificationType.SUCCESS);
          trackDocumentsDownload(items);
        })
        .catch(() => {
          Notifications.notifyUpdate(toastId, `Downloading '${items[0].name}' failed`, NotificationType.ERROR);
        });
    } else {
      const timestamp = moment().format('YYYY-MM-DD hh:mm A');
      await getDocumentsZip(items)
        .then((result) => {
          saveAs(result.content, `venn_files_export_${timestamp}.zip`);
          Notifications.notifyUpdate(
            toastId,
            `venn_files_export_${timestamp}.zip successfully downloaded`,
            NotificationType.SUCCESS,
          );
          trackDocumentsDownload(items);
        })
        .catch(() => {
          Notifications.notifyUpdate(
            toastId,
            `Downloading venn_files_export_${timestamp}.zip failed`,
            NotificationType.ERROR,
          );
        });
    }
  }, [trackDocumentsDownload]);

  const getRowId = useCallback((params: GetRowIdParams) => params.data.uuid, []);

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    setSelectedCount(event.api.getSelectedRows()?.length);
  }, []);

  const changeDateRangeResettingPage = useCallback((dateRange: DateRange, refreshGrid: boolean) => {
    setArchiveState(() => {
      return {
        dateRange,
        page: 1,
      };
    });
    gridRef.current!.api.paginationGoToPage(0);
    // The line below may cause one unnecessary refreshes in some cases, but it is needed for correctness.
    // Ag-grid will skip a refresh if it visited the front page previously.
    // However, with date filters applied that would lead to wrong data being fetched
    // There is no convenient way to track which pages were visited; better safe than sorry.
    if (refreshGrid) {
      gridRef.current!.api.refreshServerSide();
    }
  }, []);

  const changePage = useCallback((index: number, refreshGrid: boolean) => {
    setArchiveState((prevState) => {
      return {
        dateRange: prevState.dateRange,
        page: index,
      };
    });
    gridRef.current!.api.paginationGoToPage(index - 1);
    if (refreshGrid) {
      gridRef.current!.api.refreshServerSide();
    }
  }, []);

  const changePageAndRefresh = useCallback(
    (index: number) => {
      changePage(index, true);
    },
    [changePage],
  );
  const changePageNoRefresh = useCallback(
    (index: number) => {
      changePage(index, false);
    },
    [changePage],
  );
  const changeDateRangeAndRefresh = useCallback(
    (dateRange: DateRange) => {
      changeDateRangeResettingPage(dateRange, true);
    },
    [changeDateRangeResettingPage],
  );
  const rowSelection: RowSelectionOptions = useMemo(
    () => ({
      mode: 'multiRow' as const,
      selectAll: 'currentPage' as const,
      headerCheckbox: false,
      checkboxes: false,
      enableClickSelection: true,
      enableSelectionWithoutKeys: true,
    }),
    [],
  );
  const defaultColDef = useMemo(
    () => ({
      lockPinned: true,
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      editable: false,
      cellStyle: defaultColumnStyling,
      resizable: false,
    }),
    [],
  );
  const rowStyle = useMemo(() => ({ cursor: 'pointer' }), []);
  const onSortChanged = useCallback(() => changePageAndRefresh(1), [changePageAndRefresh]);

  return (
    <>
      <ActionBar>
        <DateRangePicker
          right
          value={archiveState.dateRange}
          range={MAX_RANGE}
          onChange={changeDateRangeAndRefresh}
          maxFrequency="DAILY"
          factorLens={factorLens}
          options={['ytd', '1yr', '3yr', '5yr', 'full_no_factor_constraint']}
        />
        <Tooltip usePortal content="Select files to download" isHidden={!!selectedCount}>
          <DownloadButton disabled={!selectedCount} onClick={onDownload}>
            Download {!selectedCount ? '' : `(${selectedCount})`}
          </DownloadButton>
        </Tooltip>
      </ActionBar>
      <PaginationBar>
        <div>
          <Pagination
            pagesCount={Math.ceil((totalResults ?? 0) / RESULTS_PER_PAGE)}
            selectedPage={Number(archiveState.page) ?? 1}
            onPageChange={changePageNoRefresh}
          />
        </div>
      </PaginationBar>
      <ArchiveGridWrapper isGridEmpty={isGridEmpty}>
        <DataGrid
          columnDefs={archiveTableColumns}
          gridRef={gridRef}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          cacheBlockSize={RESULTS_PER_PAGE}
          onGridReady={onGridReady}
          rowStyle={rowStyle}
          onSelectionChanged={onSelectionChanged}
          onSortChanged={onSortChanged}
          getRowId={getRowId}
          pagination
          paginationPageSize={RESULTS_PER_PAGE}
          suppressPaginationPanel
          rowBuffer={RESULTS_PER_PAGE}
          rowSelection={rowSelection}
          rowHeight={50}
          rowModelType="serverSide"
          suppressContextMenu
          autoSizeStrategy={{
            type: 'fitGridWidth',
          }}
        />
      </ArchiveGridWrapper>
      {isGridEmpty ? <ZeroSavedViewsEmptyState onClick={() => changeDateRangeAndRefresh(DEFAULT_RANGE)} /> : null}
    </>
  );
};

const sortColumnsEnumMapping = {
  name: 'NAME',
  'createdByUser.name': 'SAVED_BY',
  updatedTime: 'UPDATED_TIME',
};

const ArchiveGridWrapper = styled.div<{ isGridEmpty: boolean }>`
  .ag-header {
    border-top: 1px solid ${GetColor.Grey};
    border-bottom: 1px solid ${GetColor.Grey};
    background-color: ${GetColor.White};
  }

  .ag-center-cols-container {
    width: 100% !important;
  }

  width: 100%;
  font-family: ${(props) => props.theme.Typography.fontFamily};

  .ag-root-wrapper {
    border: none;
  }

  .ag-header-cell {
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;

    @media print {
      text-overflow: unset;
      padding-left: 5px;
      padding-right: 5px;

      .ag-header-group-cell-label,
      .ag-header-cell-label {
        justify-content: center;
      }

      .ag-header-icon {
        display: none !important;
      }
    }
  }

  .ag-header-group-cell .ag-react-container,
  .ag-header-cell .ag-react-container {
    width: 100%;
  }

  .ag-row-hover {
    background-color: ${GetColor.DEPRECATED_DivergingColor.B1};
  }

  .ag-cell {
    padding: 2px ${CELL_HORIZONTAL_PADDING}px;
    border-bottom: ${CELL_BORDER_WIDTH}px solid ${GetColor.PaleGrey};
    font-size: 14px;
    line-height: 24px;
  }

  .ag-row {
    border: none;
  }

  .ag-column-hover {
    background-color: transparent;
  }

  @media print {
    .ag-header-cell-text {
      white-space: normal;
    }

    .ag-row {
      page-break-inside: avoid;
    }
  }

  // Prevents mid-word wrapping
  .ag-cell-wrap-text {
    word-break: break-word;
  }

  .ag-center-cols-viewport,
  .ag-center-cols-clipper,
  .ag-center-cols-container {
    min-height: 35px !important;
  }
`;

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  height: 50px;
`;
const PaginationBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  padding-left: 10px;
`;
const DownloadButton = styled(Button)`
  display: flex;
  margin-right: 0px;
  min-height: 35px;
`;
