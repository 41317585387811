import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from 'recoil';
import type { Portfolio } from 'venn-api';
import { AnalysisSubject, calculateComparison, normalizePortfolio, selectStrategy } from 'venn-utils';
import {
  allocatorAnalysisSubject,
  compareColumnOpen,
  hasAllocationPercentError,
  isReportState,
  openAllocatorSubject,
  originalAnalysisSubjectQuery,
  useCachedLoadableState,
  useCachedLoadableValue,
} from 'venn-state';
import { checkHasQuantDiff, TreeItemUpdateType } from '../AllocationUtils';
import type { CompareType } from '../../contexts';
import { ComparisonContext, PortfoliosContext } from '../../contexts';
import { isNil } from 'lodash';
import type { PortfolioComparisonType } from './AllocationPanelV2';
import AllocationPanelV2 from './AllocationPanelV2';
import { useReplacePortfolioInView } from './useReplacePortfolioInView';
import { useSyncFees } from './useSyncFees';

interface AllocationPanelV2RecoilWrapper {
  onReplacePortfolioInAllocator: (portfolioSubject: AnalysisSubject) => void;
}

const AllocationPanelV2RecoilWrapper = React.memo(
  ({ onReplacePortfolioInAllocator }: AllocationPanelV2RecoilWrapper) => {
    // TODO(annap): apply URL overrides (landing page from Portfolio Lab)

    const isReportLab = useRecoilValue(isReportState);

    const allocatorSubject = useCachedLoadableValue(openAllocatorSubject);
    const [currentSubject, setCurrentSubject] = useCachedLoadableState(allocatorAnalysisSubject(allocatorSubject));
    const originalSubject = useCachedLoadableValue(originalAnalysisSubjectQuery(allocatorSubject));
    const requeryOriginalPortfolio = useRecoilRefresher_UNSTABLE(originalAnalysisSubjectQuery(allocatorSubject));
    const showComparisonColumn = useRecoilValue(compareColumnOpen);
    const [hasAllocationError, setHasAllocationError] = useRecoilState(hasAllocationPercentError);

    const original = useMemo(
      () => (isNil(originalSubject?.portfolio) ? undefined : normalizePortfolio(originalSubject.portfolio)),
      [originalSubject],
    );
    const rawCurrentPortfolio = currentSubject?.portfolio ?? originalSubject?.portfolio;
    const normalCurrentPortfolio = useMemo(
      () => (isNil(rawCurrentPortfolio) ? undefined : normalizePortfolio(rawCurrentPortfolio)),
      [rawCurrentPortfolio],
    );

    const { comparePortfolio, compareType, updateCompareType } = useContext(ComparisonContext);
    const comparisonType = compareType === 'Master' ? 'MASTER' : compareType === 'Last Saved' ? 'SAVED' : undefined;

    useEffect(() => {
      if (!currentSubject || !currentSubject.portfolio || !currentSubject.secondaryPortfolio || !comparePortfolio) {
        return;
      }
      if (
        currentSubject.secondaryPortfolio?.id === comparePortfolio.id &&
        currentSubject.secondaryPortfolio?.version === comparePortfolio.version &&
        currentSubject.secondaryPortfolio?.draft &&
        !comparePortfolio.draft
      ) {
        setCurrentSubject(
          new AnalysisSubject(currentSubject.portfolio, 'portfolio', {
            ...currentSubject.getOptionsCopy(),
            secondaryPortfolio: comparePortfolio,
          }),
        );
      }
    }, [comparePortfolio, currentSubject, setCurrentSubject]);

    const { masterPortfolio } = useContext(PortfoliosContext);
    const onUpdateComparison = useCallback(
      (comparisonType: PortfolioComparisonType, portfolioId?: number, portfolioVersion?: number) => {
        if (isNil(currentSubject) || isNil(currentSubject.portfolio)) {
          return;
        }
        const [type, portfolio]: [CompareType, Portfolio | undefined] =
          comparisonType === 'MASTER'
            ? ['Master', masterPortfolio]
            : comparisonType !== 'SAVED' || isNil(original)
              ? [undefined, undefined]
              : [
                  'Last Saved',
                  portfolioId === original.id && portfolioVersion === original.version
                    ? original
                    : ({
                        id: portfolioId ?? original.id,
                        version: portfolioVersion ?? original.version,
                        draft: true,
                      } as Portfolio),
                ];

        updateCompareType(type, portfolio);
        setCurrentSubject(
          new AnalysisSubject(currentSubject.portfolio, 'portfolio', {
            ...currentSubject.getOptionsCopy(),
            secondaryLabel: type,
            secondaryPortfolio: portfolio,
            secondaryStrategy: undefined!,
          }),
        );
      },
      [original, updateCompareType, masterPortfolio, currentSubject, setCurrentSubject],
    );

    const [isPercentageMode, setIsPercentageMode] = useState<boolean>(false);
    const togglePercentageMode = useCallback(() => setIsPercentageMode((isPercentageMode) => !isPercentageMode), []);
    /**
     * When the allocations don't add up to 100% in percentage mode, we enter the state with `hasAllocationError = true`.
     * We then don't update the global `AnalysisSubject` with the "broken" portfolio, because we don't want to re-run
     * analysis for it. Instead, we keep the value in `localCurrentPortfolio` until the error is resolved. It's also
     * possible that in the `localCurrentPortfolio` a new strategy is created and selected; in this case, we need to store
     * the selected strategy locally as well, as it won't exist in the global `AnalysisSubject`.
     */
    const [localCurrentPortfolio, setLocalCurrentPortfolio] = useState<Portfolio | undefined>();
    const [localSelectedStrategy, setLocalSelectedStrategy] = useState<Portfolio | undefined>();

    const currentSubjectAllocation = currentSubject?.portfolio?.allocation;
    const localAllocation = localCurrentPortfolio?.allocation;
    const updatedHasAllocationError =
      isPercentageMode &&
      localAllocation &&
      currentSubjectAllocation &&
      checkHasQuantDiff(localAllocation / currentSubjectAllocation, 1, 1000);
    useEffect(() => {
      if (hasAllocationError !== updatedHasAllocationError) {
        setHasAllocationError(!!updatedHasAllocationError);
      }
    }, [hasAllocationError, setHasAllocationError, updatedHasAllocationError]);

    const updateSelectedStrategy = useMemo(
      () =>
        isReportLab
          ? undefined
          : (strategy: Portfolio, secondaryStrategy: Portfolio | undefined) => {
              const selectedStrategyOnAnalysisSubject = isNil(normalCurrentPortfolio)
                ? null
                : selectStrategy(strategy.id, normalCurrentPortfolio);
              if (!isNil(selectedStrategyOnAnalysisSubject)) {
                setCurrentSubject(
                  new AnalysisSubject(normalCurrentPortfolio!, 'portfolio', {
                    ...currentSubject!.getOptionsCopy(),
                    strategyId: strategy.id,
                    secondaryStrategy,
                  }),
                );
                setLocalSelectedStrategy(undefined);
                return;
              }

              // If the selected strategy doesn't exist on the portfolio in `AnalysisSubject`, and `hasAllocationError = true`,
              // there is a chance it's a new strategy created when we already entered the "broken" state. In this case, it
              // should exist on `localCurrentPortfolio`.
              if (hasAllocationError && !isNil(localCurrentPortfolio)) {
                const selectedStrategyOnLocalPortfolio = selectStrategy(strategy.id, localCurrentPortfolio);
                if (!isNil(selectedStrategyOnLocalPortfolio)) {
                  setLocalSelectedStrategy(selectedStrategyOnLocalPortfolio);
                }
              }
            },
      [
        isReportLab,
        normalCurrentPortfolio,
        hasAllocationError,
        localCurrentPortfolio,
        setCurrentSubject,
        currentSubject,
      ],
    );

    const onUpdateSecondaryPortfolio = useCallback(
      (comparisonType: PortfolioComparisonType, portfolio: Portfolio | undefined, strategy: Portfolio | undefined) => {
        if (isNil(currentSubject) || isNil(currentSubject.portfolio)) {
          return;
        }
        if (isNil(currentSubject.secondaryPortfolio) && isNil(portfolio)) {
          return; // No change to secondary: was null, is null
        }
        if (
          currentSubject.secondaryPortfolioComparisonType === comparisonType &&
          currentSubject.secondaryPortfolio?.id === portfolio?.id &&
          currentSubject.secondaryStrategy?.id === strategy?.id
        ) {
          // No change to comparison type, secondary portfolio id and selected strategy
          if (comparisonType !== 'SAVED') {
            return; // Not change to secondary: only 'SAVED'-type portfolios can have historical version selected
          }
          if (
            currentSubject.secondaryPortfolio?.version === portfolio?.version &&
            currentSubject.secondaryStrategy?.version === strategy?.version
          ) {
            return; // No change to secondary: no version change either
          }
        }

        setCurrentSubject(
          new AnalysisSubject(currentSubject.portfolio, 'portfolio', {
            ...currentSubject.getOptionsCopy(),
            secondaryLabel:
              comparisonType === 'SAVED' ? 'Last Saved' : comparisonType === 'MASTER' ? 'Master' : undefined,
            secondaryPortfolio: portfolio,
            secondaryStrategy: strategy,
          }),
        );
      },
      [currentSubject, setCurrentSubject],
    );

    const onReplaceSubject = useReplacePortfolioInView();
    const { syncFees } = useSyncFees();

    const onSavePortfolioAction = useCallback(
      (newPortfolio: Portfolio) => {
        onReplaceSubject(newPortfolio);

        if (!currentSubject || !currentSubject.portfolio) {
          return;
        }

        const [secondaryLabel, secondaryPortfolio, secondaryStrategy] =
          currentSubject.secondaryLabel === 'Master'
            ? [currentSubject.secondaryLabel, currentSubject.secondaryPortfolio, currentSubject.secondaryStrategy]
            : [undefined, undefined, undefined];
        const strategyId =
          currentSubject.strategyId && currentSubject.strategyId !== currentSubject.portfolio.id
            ? calculateComparison(currentSubject.portfolio, newPortfolio)[0].get(currentSubject.strategyId)?.id
            : undefined;

        onReplacePortfolioInAllocator(
          new AnalysisSubject(newPortfolio, 'portfolio', {
            strategyId,
            secondaryLabel,
            secondaryPortfolio,
            secondaryStrategy,
          }),
        );
        requeryOriginalPortfolio();
      },
      [currentSubject, onReplaceSubject, onReplacePortfolioInAllocator, requeryOriginalPortfolio],
    );

    const onSavedAsNewPortfolio = useCallback(
      (newPortfolio: Portfolio) => {
        onSavePortfolioAction(newPortfolio);
        syncFees(currentSubject, newPortfolio, TreeItemUpdateType.SAVE);
      },
      [onSavePortfolioAction, syncFees, currentSubject],
    );

    const onUpdatePortfolio = useCallback(
      (portfolio: Portfolio, updateType: TreeItemUpdateType) => {
        if (
          isNil(portfolio.allocation) ||
          isNil(currentSubject) ||
          isNil(currentSubject.portfolio) ||
          isNil(currentSubject?.portfolio.allocation)
        ) {
          return;
        }
        // We would ignore difference smaller than 0.1%
        const updatedHasAllocationError =
          isPercentageMode && checkHasQuantDiff(portfolio.allocation / currentSubject.portfolio.allocation, 1, 1000);

        if (updateType === TreeItemUpdateType.RESET && portfolio.allocation === 0 && isPercentageMode) {
          // Handle the edge case when the user is in percentage mode and reset to base 0
          setIsPercentageMode(false);
        }

        syncFees(currentSubject, portfolio, updateType);
        if (updateType === TreeItemUpdateType.SAVE) {
          // Update instances of the portfolio with the new portfolio version
          onSavePortfolioAction(portfolio);
        }

        if (updatedHasAllocationError) {
          setLocalCurrentPortfolio(portfolio);
          setLocalSelectedStrategy(
            // Keep `localSelectedStrategy`, if set; otherwise, keep the strategy set on the `currentSubject`, if set;
            // otherwise default to the root of the portfolio.
            selectStrategy(localSelectedStrategy?.id ?? currentSubject.strategyId ?? portfolio.id, portfolio) ??
              portfolio,
          );
        } else {
          setCurrentSubject(
            new AnalysisSubject({ ...portfolio }, 'portfolio', {
              ...currentSubject.getOptionsCopy(),
              strategyId: localSelectedStrategy?.id ?? currentSubject.strategyId,
            }),
          );
          setLocalCurrentPortfolio(undefined);
          setLocalSelectedStrategy(undefined);
        }
      },
      [
        isPercentageMode,
        currentSubject,
        setCurrentSubject,
        localSelectedStrategy,
        setLocalSelectedStrategy,
        onSavePortfolioAction,
        syncFees,
      ],
    );

    useEffect(() => {
      if (!showComparisonColumn && !!comparisonType) {
        onUpdateComparison(undefined);
      }
    }, [showComparisonColumn, comparisonType, onUpdateComparison]);

    if (isNil(normalCurrentPortfolio) || isNil(original)) {
      return null;
    }

    return (
      <AllocationPanelV2
        current={localCurrentPortfolio ?? normalCurrentPortfolio}
        original={original}
        compare={comparePortfolio}
        comparisonType={comparisonType}
        onUpdateComparison={onUpdateComparison}
        onUpdateSecondaryPortfolio={onUpdateSecondaryPortfolio}
        onUpdatePortfolio={onUpdatePortfolio}
        selectedStrategyId={localSelectedStrategy?.id ?? currentSubject?.strategyId ?? normalCurrentPortfolio.id}
        updateSelectedStrategy={updateSelectedStrategy}
        isPercentageMode={isPercentageMode}
        togglePercentageMode={togglePercentageMode}
        hasAllocationError={hasAllocationError}
        baseAllocation={currentSubject?.portfolio?.allocation ?? 0}
        hideComparisonColumn={!showComparisonColumn}
        onSavedAsNewPortfolio={onSavedAsNewPortfolio}
      />
    );
  },
);
AllocationPanelV2RecoilWrapper.displayName = 'AllocationPanelV2RecoilWrapper';

export default AllocationPanelV2RecoilWrapper;
