import React, { useContext, useEffect, useState } from 'react';
import { compact, findIndex } from 'lodash';
import type { SharedProps, AnalysisTrackingProps } from '../types';
import { TrackSuccessfulAnalysis, DownloadableContentBlock, exportToExcel, AnalysisViewContext } from 'venn-components';
import type { FrequencyEnum } from 'venn-api';
import { analysis } from 'venn-api';

import { ThemeContext } from 'styled-components';
import NotablePeriodChart from './NotablePeriodsChart';
import { getAnalysisRequest, analyticsService } from 'venn-utils';
import type { NotablePeriodZipped } from '../logic/notablePeriodsColumns';
import { getColumnsForNotableHistoricalPeriods, formatDate } from '../logic/notablePeriodsColumns';
import { useNotablePeriodChart } from '../logic/useNotablePeriodChart';
import { getAppTitle } from 'venn-ui-kit';

export interface NotablePeriodAnalysisProps extends SharedProps, AnalysisTrackingProps {
  analysisFrequency?: FrequencyEnum;
}

const NotableHistoricalPeriod: React.FunctionComponent<React.PropsWithChildren<NotablePeriodAnalysisProps>> = ({
  analysisConfig,
  analyses,
  trackingProps,
  downloadMetaData,
  analysisFrequency,
}) => {
  const title = analyses?.relative ? 'Notable Historical Periods Relative to Benchmark' : 'Notable Historical Periods';
  const subtitle = analyses?.relative
    ? `Notable historical periods identified by ${getAppTitle()}, relative to benchmark.`
    : `Notable historical periods identified by ${getAppTitle()}.`;

  const { Colors } = useContext(ThemeContext);
  const { selectedNotablePeriods } = useContext(AnalysisViewContext);
  const [loading, setLoading] = useState<number[]>([]);
  const [defaultSelectedPeriods, setDefaultSelectedPeriods] = useState<number[]>([]);

  const {
    setNotablePeriods,
    notablePeriods,
    subject,
    hasBenchmark,
    hasComparison,
    hasCategory,
    labels,
    onUpdateAnalysisViewParam,
    chartData,
    data,
  } = useNotablePeriodChart({
    analysisConfig,
    analyses,
    loading,
  });

  useEffect(() => {
    if (!selectedNotablePeriods) {
      const initialIds = data?.subject
        ?.filter((datum) => datum.type === 'PREDEFINED' && datum.extremumValue)
        .map((x) => x.id);
      setDefaultSelectedPeriods(initialIds ?? []);
    }
  }, [data?.subject, selectedNotablePeriods]);

  if (!notablePeriods || !analysisConfig.subject) {
    return null;
  }

  const isSelected = (datum: NotablePeriodZipped) =>
    datum.loading || (selectedNotablePeriods ?? defaultSelectedPeriods).includes(datum.id!);
  const vennPredefinedSelectedPeriods = chartData.filter(isSelected);
  const vennPredefinedPeriods =
    data && data.subject ? data.subject?.filter((datum) => datum.type === 'PREDEFINED') : [];

  const onDelete = (id: number) => {
    onUpdateAnalysisViewParam({
      selectedNotablePeriods: selectedNotablePeriods
        ? selectedNotablePeriods.filter((x) => x !== id)
        : defaultSelectedPeriods.filter((x) => x !== id),
    });
  };

  const max = Math.max(
    ...[
      ...compact(vennPredefinedSelectedPeriods.map((datum) => datum?.subject)),
      ...compact(vennPredefinedSelectedPeriods.map((datum) => datum?.benchmark)),
      ...compact(vennPredefinedSelectedPeriods.map((datum) => datum?.category)),
    ].map(Math.abs),
    0,
  );

  const shouldShowHistoricalTool =
    !!subject?.portfolio?.historical || !!analysisConfig.benchmark?.portfolio?.historical;
  const primaryHeadingTooltipContent = shouldShowHistoricalTool
    ? 'The current investment / portfolio does not have returns or allocation data for the entirety of the selected period'
    : 'The current investment / portfolio does not have returns data for the entirety of the selected period';

  const editableColumns = getColumnsForNotableHistoricalPeriods({
    primaryHeading: 'Notable Historical Periods',
    primaryHeadingTooltipContent,
    labels,
    max,
    hasBenchmark,
    hasComparison,
    hasCategory,
    Colors,
    analysisFrequency,
    onDelete,
  });

  return (
    <TrackSuccessfulAnalysis {...trackingProps}>
      <DownloadableContentBlock
        header={title}
        subHeader={subtitle}
        downloadable={{
          png: true,
          excel: exportToExcel(
            vennPredefinedSelectedPeriods.filter((x) => x.subject !== undefined),
            editableColumns.filter((x) => x.label!.length > 0),
          ),
          options: {
            fileName: `${subject?.name || ''} - ${title}`,
            metaData: downloadMetaData!,
          },
          tracking: {
            subjectType: subject?.type,
            subjectId: subject?.id,
            description: 'NOTABLE_PERIODS',
            relativeToBenchmark: false,
            userUploaded: subject?.fund?.userUploaded || false,
          },
        }}
      >
        <NotablePeriodChart
          chartData={vennPredefinedSelectedPeriods}
          columns={editableColumns}
          hasBenchmark={hasBenchmark}
          hasComparison={hasComparison}
          hasCategory={hasCategory}
          labels={labels}
          max={max}
          emptyHeader="No periods have been selected yet."
          emptyMessage="Select a period from the dropdown above."
          picker={{
            items: vennPredefinedPeriods
              .filter((datum) => !(selectedNotablePeriods ?? defaultSelectedPeriods).includes(datum.id))
              .map((datum) => ({
                label: `${datum.name}: ${formatDate('DAILY', datum.start, '--')} - ${formatDate(
                  'DAILY',
                  datum.extremumDate,
                  '--',
                )}`,
                value: datum.id,
              })),
            onAdd: async (item: number) => {
              const selectedPeriodData = vennPredefinedPeriods.filter((x) => x.id === item)[0];
              analyticsService.notablePeriodAdded({
                periodName: selectedPeriodData.name,
              });
              const selectedNotablePeriodIds = [...(selectedNotablePeriods ?? defaultSelectedPeriods), item].sort();
              const request = getAnalysisRequest(
                ['NOTABLE_PERIODS'],
                analysisConfig.subject,
                analysisConfig.selectedTimeFrame,
                analysisConfig.relative,
                Boolean(notablePeriods.category),
                analysisConfig.selectedPeriod,
                analysisConfig.trackingId,
                undefined,
                false,
                undefined,
                undefined,
                !notablePeriods.benchmark,
                undefined,
                undefined,
                undefined,
                selectedNotablePeriodIds,
              );
              setLoading([...loading, item]);
              onUpdateAnalysisViewParam({ selectedNotablePeriods: selectedNotablePeriodIds });
              const response = await analysis(request);
              const result = response.content.analyses[0].notablePeriods;
              const subjectIndex = 0;
              const benchmarkIndex = findIndex(request?.subjects, (s) => s.comparisonType === 'BENCHMARK');
              const comparisonIndex = findIndex(
                request?.subjects,
                (s) => s.comparisonType === 'COMPARISON' || s.comparisonType === 'CATEGORY',
              );
              setNotablePeriods({
                subject: result[subjectIndex],
                benchmark: result[benchmarkIndex],
                category: result[comparisonIndex],
              });
              setLoading(loading.filter((x) => x !== item));
            },
            onDelete,
          }}
        />
      </DownloadableContentBlock>
    </TrackSuccessfulAnalysis>
  );
};

export default NotableHistoricalPeriod;
