import type { FC } from 'react';
import React, { useContext } from 'react';
import { Content, Footer, LayoutWithMain, Main, TOP_NAV_HEIGHT } from 'venn-ui-kit';
import {
  Footer as ShellFooter,
  MAIN_CONTENT_ID,
  PrintCopy,
  StudioPrintSettingsContext,
  UserContext,
} from 'venn-components';
import { Loading } from 'authentication-page';
import type { ShellRouterProps } from '../ShellRouter';
import ShellNavigationBar from '../shell-navigation/components/ShellNavigationBar';
import ErrorBoundary from './ErrorBoundary';
import SwitchWorkspaceModal from './SwitchWorkspaceModal';
import InAppLoading from './Loading';
import useWorkspaceSwitch from './useWorkspaceSwitch';
import type { ModuleConfig } from '../../config/shellModuleConfigInitializer';

/**
 * We have 3 main layout style:
 * STANDARD: Standard layout with footer
 * FIXED_FULL_PAGE_CONTENT: Like the new Home page, the content takes the entire page except for the nav and it's only scrollable down to the footer.
 * NO_FOOTER: Like analysis page. Since we will have fixed allocation panel, so we don't add the footer to the main content.
 */
export type ShellLayoutStyle = 'STANDARD' | 'NO_FOOTER' | 'FIXED_FULL_PAGE_CONTENT';

type ShellLayoutContainerProps = ShellRouterProps & ModuleConfig['layoutOptions'];

const ShellLayoutContainer: FC<React.PropsWithChildren<ShellLayoutContainerProps>> = (props) => {
  const { children, layout, noMinWidth, minWidth, history, showNavBar = true } = props;
  const hasFooter = layout === 'STANDARD' || layout === 'FIXED_FULL_PAGE_CONTENT';
  const mainTag = hasFooter ? 'main' : 'div';
  const { isSwitchModalOpen, onCancelSwitchWorkspace, onSwitchWorkspace, isView, factorLoading } = useWorkspaceSwitch();
  const { profileSettings } = useContext(UserContext);
  const { organizationPrintSettings } = useContext(StudioPrintSettingsContext);
  // Because some components rely on factor lenses being pre-loaded into the application
  // we need to display a whole-screen-loader while the factor lenses are being loaded/reloaded
  if (factorLoading) {
    return <Loading />;
  }

  return (
    <LayoutWithMain className="main-layout" showNav={showNavBar}>
      {showNavBar && <ShellNavigationBar history={history} />}
      <Main
        id={MAIN_CONTENT_ID}
        hasFooter={hasFooter}
        fullPage={layout === 'FIXED_FULL_PAGE_CONTENT'}
        as={mainTag}
        headerHeight={TOP_NAV_HEIGHT}
      >
        {isSwitchModalOpen ? (
          <>
            <InAppLoading />
            <SwitchWorkspaceModal onCancel={onCancelSwitchWorkspace} onContinue={onSwitchWorkspace} isView={isView} />
          </>
        ) : (
          <ErrorBoundary>
            {hasFooter ? (
              <>
                <Content noMinWidth={noMinWidth} minWidth={minWidth}>
                  {children}
                </Content>
                <Footer>
                  <ShellFooter />
                </Footer>
              </>
            ) : (
              children
            )}
            <PrintCopy
              organizationName={organizationPrintSettings?.organizationName ?? profileSettings?.organization.name}
            />
          </ErrorBoundary>
        )}
      </Main>
    </LayoutWithMain>
  );
};

export default ShellLayoutContainer;
