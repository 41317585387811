import React, { useCallback, useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { range } from 'lodash';
import moment from 'moment';
import Tooltip from '../../../venn-ui-kit/src/components/tooltip/Tooltip';
import { ZIndex } from 'venn-ui-kit';
import { cn } from '@/lib/utils';
import styled from 'styled-components';
import type { MomentInput } from 'moment/moment';

const getMonths = (year: number, begin: moment.Moment, ending: moment.Moment) => {
  return range(0, 12).filter((month) => {
    const monthEnd = moment.utc().year(year).month(month).endOf('month');
    return !monthEnd.isBefore(begin) && !monthEnd.isAfter(ending);
  });
};

const TopLevelItem = ({
  label,
  date,
  tooltip,
}: {
  label: React.ReactNode;
  date: MomentInput;
  tooltip: React.ReactNode;
}) => (
  <Tooltip content={tooltip} zIndex={ZIndex.Watermark} usePortal className="w-[210px]">
    <div className="flex justify-between w-[180px] items-center">
      {label} <span className="text-[12px] italic text-venn-grey-80">({moment.utc(date).format('MM/DD/YYYY')})</span>
    </div>
  </Tooltip>
);

const getDefaultSelectTrigger = (date?: string) => (
  <StyledSelectTrigger>
    <StyledSelectValue placeholder="Select a date" defaultValue="Current">
      {date}
    </StyledSelectValue>
  </StyledSelectTrigger>
);

export const CalendarDropdown = ({
  value,
  lastUpdated,
  start,
  end,
  disabled,
  onChange,
  widthClass,
  selectTrigger = getDefaultSelectTrigger,
}: {
  widthClass?: string;
  value?: string;
  lastUpdated?: Date;
  start?: Date;
  end?: Date;
  disabled?: boolean;
  onChange?: (value: string) => void;
  selectTrigger?: (value?: string) => React.ReactNode;
}) => {
  const [selectedDate, setSelectedDate] = useState<string | undefined>(value ?? 'Current');
  const [selectedYear, setSelectedYear] = useState<string | undefined>(
    value
      ? String(
          moment
            .utc(value ?? end)
            .endOf('month')
            .year(),
        )
      : undefined,
  );

  const onValueChange = useCallback(
    (value: string) => {
      setSelectedDate(value);
      onChange && onChange(value);
    },
    [setSelectedDate, onChange],
  );

  const begin = moment.utc(start).startOf('month');
  const ending = moment.utc(end);
  const displayDate = moment.utc(selectedDate, 'MM/DD/YYYY');
  const formattedDisplayDate = displayDate.isValid() ? displayDate.format('D MMM YYYY') : selectedDate;
  const lastUpdatedEndOfMonth = moment.utc(lastUpdated).endOf('month');
  const lastUpdatedValue = !lastUpdatedEndOfMonth.isAfter(end) ? lastUpdatedEndOfMonth : moment.utc(lastUpdated);

  return (
    <Select value={selectedDate} onValueChange={onValueChange} disabled={!!disabled}>
      {selectTrigger(formattedDisplayDate)}
      <SelectContent className="overflow-hidden max-h[200px]">
        <SelectItem
          value="Current"
          className={cn(
            'hover:bg-venn-grey-20 text-[14px] border-0 hover:border-0 focus:border-0 focus:outline-none w-[210px]',
            widthClass,
          )}
        >
          <TopLevelItem
            label="Current"
            date={end}
            tooltip="Allocations reflect any drift due to investment performance since last uploaded"
          />
        </SelectItem>
        {lastUpdatedValue && (
          <SelectItem
            value="Last Uploaded"
            className={cn(
              'hover:bg-venn-grey-20 text-[14px] border-0 hover:border-0 focus:border-0 focus:outline-none w-[210px]',
              widthClass,
            )}
          >
            <TopLevelItem
              label="Last Uploaded"
              date={lastUpdatedValue}
              tooltip="Latest available uploaded allocations"
            />
          </SelectItem>
        )}
        <Accordion
          type="single"
          collapsible
          className="w-full"
          value={selectedYear}
          onValueChange={(year) => setSelectedYear(year)}
        >
          {range(begin.year(), ending.year() + 1)
            .reverse()
            .filter((year) => getMonths(year, begin, ending).length > 0)
            .map((year) => (
              <AccordionItem
                value={String(year)}
                key={year}
                className="border-none hover:text-black hover:no-underline"
              >
                <StyledAccordionTrigger>
                  <span className="mx-[12px]">{year}</span>
                </StyledAccordionTrigger>
                {getMonths(year, begin, ending)
                  .reverse()
                  .map((month) => (
                    <AccordionContent key={`${year}-${month}`} className="h-[24px] border-none hover:border-none">
                      <StyledSelectItem
                        value={moment.utc().year(year).month(month).endOf('month').format('MM/DD/YYYY')}
                      >
                        {moment.utc().year(year).month(month).endOf('month').format('D MMM')}
                      </StyledSelectItem>
                    </AccordionContent>
                  ))}
              </AccordionItem>
            ))}
        </Accordion>
      </SelectContent>
    </Select>
  );
};

const StyledSelectTrigger = styled(SelectTrigger).attrs({
  className:
    'hover:text-black focus:ring-0 h-[35px] focus:outline-none focus:border-[hsl(var(--ring))] border-[hsl(var(--border))] border-[1px] border-solid text-[12px] font-bold text-[--foreground] w-[210px]',
})``;

const StyledSelectValue = styled(SelectValue).attrs({
  className: 'text-[12px] font-bold text-[--foreground] hover:text-black w-[210px]',
})``;

const StyledAccordionTrigger = styled(AccordionTrigger).attrs({
  className: 'text-[14px] text-[--foreground] p-[12px] h-[16px] hover:text-black',
})``;

const StyledSelectItem = styled(SelectItem).attrs({
  className:
    'hover:bg-venn-grey-20 align-middle text-[14px] indent-[24px] border-0 hover:border-0 focus:border-0 focus:outline-none',
})``;
