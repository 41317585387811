import { useMemo } from 'react';
import type { AnalysisSubject } from 'venn-utils';
import { getSubjectFunds } from 'venn-utils';
import { prefetchBenchmarkQuery, useBenchmarkQuery } from '../../hooks/useBenchmarkQuery';
import { useProxyDataQuery, useRangeAnalysisQuery } from '../../hooks';
import { isNil } from 'lodash';
import type { SubjectRangeAnalysis } from 'venn-api';

export const useBulkFundData = (subject: AnalysisSubject) => {
  const uniqPortfolioFunds = useMemo(() => getSubjectFunds(subject, false), [subject]);
  const uniqFundIds = useMemo(() => {
    // IDs are sorted for cache key stability
    if (
      typeof subject.activeBenchmarkId === 'string' &&
      !uniqPortfolioFunds.find((f) => f.id === subject.activeBenchmarkId)
    ) {
      return [...uniqPortfolioFunds.map((f) => f.id), subject.activeBenchmarkId].sort();
    }
    return uniqPortfolioFunds.map((f) => f.id).sort();
  }, [uniqPortfolioFunds, subject.activeBenchmarkId]);

  // Pre-fetch to prevent suspense waterfall
  prefetchBenchmarkQuery(subject);
  // No need to suspend for rangeAnalysisQuery, because we have per-row loading states for it.
  const { rangeAnalysis: rangeAnalysisResult, analysisRequest, isFetching } = useRangeAnalysisQuery(subject);
  // Force a loading state when rangeAnalysis is fetching by setting rangeAnalysis back to undefined.
  const rangeAnalysis = isFetching ? undefined : rangeAnalysisResult;
  const proxyDataByFund = useProxyDataQuery(uniqFundIds);
  const benchmarkInfo = useBenchmarkQuery(subject, { suspense: true });
  const benchmarkFund = benchmarkInfo.type === 'fund' ? benchmarkInfo.benchmark : undefined;

  const uniqFunds = useMemo(() => {
    if (benchmarkFund && !uniqPortfolioFunds.find((f) => f.id === benchmarkFund.id)) {
      return [...uniqPortfolioFunds, benchmarkFund];
    }
    return uniqPortfolioFunds;
  }, [uniqPortfolioFunds, benchmarkFund]);

  const { rangeDataByFund, primaryRangeAnalysis } = useMemo(() => {
    const primaryIndex = analysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'PRIMARY');
    const primaryRangeAnalysis = !isNil(primaryIndex) ? rangeAnalysis?.rangeAnalyses[primaryIndex] : undefined;
    const rangeAnalyses = primaryRangeAnalysis
      ? primaryRangeAnalysis.investmentId
        ? [primaryRangeAnalysis]
        : primaryRangeAnalysis.rangeAnalyses
      : [];
    const rangeDataByFund: Record<SubjectRangeAnalysis['investmentId'], SubjectRangeAnalysis[]> = (() => {
      const result = {};
      rangeAnalyses.forEach((analysis) => {
        if (analysis.investmentId in result) {
          result[analysis.investmentId].push(analysis);
        } else {
          result[analysis.investmentId] = [analysis];
        }
      });
      return result;
    })();

    const benchmarkIndex = analysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'BENCHMARK');
    const benchmarkRange = !isNil(benchmarkIndex) ? rangeAnalysis?.rangeAnalyses[benchmarkIndex] : undefined;
    if (benchmarkRange && benchmarkInfo.benchmark?.id) {
      rangeDataByFund[benchmarkInfo.benchmark.id] = [benchmarkRange];
    }

    return { rangeDataByFund, primaryRangeAnalysis };
  }, [analysisRequest?.subjects, rangeAnalysis?.rangeAnalyses, benchmarkInfo.benchmark?.id]);

  return {
    uniqFunds,
    uniqFundIds,
    rangeAnalysis,
    proxyDataByFund,
    rangeDataByFund,
    primaryRangeAnalysis,
    benchmarkInfo,
  };
};
