import React, { useContext, useMemo } from 'react';
import type { ProxyTypeEnum, DetailedProxyMetadata, SubjectRangeAnalysis } from 'venn-api';
import { Button, GetColor, Icon, PROXY_FAQ_HREF } from 'venn-ui-kit';
import { useInvestmentsReturnsRanges } from '../modals/pickers/proxy-picker/useInvestmentsReturnsRanges';
import { useProxyTypeValidation } from '../modals/pickers/proxy-picker/useProxyTypeValidation';
import { getProxyTypeNoun, invalidate, plural, useModal } from 'venn-utils';
import styled, { css } from 'styled-components';
import { UserContext } from '../contexts';

import type { FundToBulkProxy } from '../modals/pickers/types';
import { DeleteProxiesConfirmationModal } from './DeleteProxiesConfirmationModal';

interface ProxyMessageProps {
  selectableProxyTypes: ProxyTypeEnum[];
  canManageProxy: boolean;
  maxFundsExceeded: boolean;
}
interface BulkProxySectionProps {
  selectedFunds: FundToBulkProxy[];
  proxyDataByFund: Record<string, DetailedProxyMetadata | undefined>;
  rangeDataByFund: Record<string, SubjectRangeAnalysis | undefined>;
  onClearSelected: () => void;

  onPickerOpen: () => void;
}

const MAX_NUM_SELECTED_FUNDS = 50;

const ProxyMessage = ({ selectableProxyTypes, canManageProxy, maxFundsExceeded }: ProxyMessageProps) => {
  if (maxFundsExceeded) {
    return (
      <>
        <WarningTextWrapper>
          <Icon type="warning" /> You have exceeded the maximum number ({MAX_NUM_SELECTED_FUNDS}) of investments that
          can be selected
        </WarningTextWrapper>
        <SeparatorIcon type="pipe" />
        Deselect investments to Select a Proxy.
      </>
    );
  }

  if (!canManageProxy) {
    return (
      <>
        <WarningTextWrapper>
          <Icon type="warning" /> Setting proxies is not allowed
        </WarningTextWrapper>
        <SeparatorIcon type="pipe" />
        Contact your workspace administrator for more details.
      </>
    );
  }

  if (selectableProxyTypes.length === 0) {
    return (
      <>
        <WarningTextWrapper>
          <Icon type="warning" /> No proxy types available
        </WarningTextWrapper>
        <SeparatorIcon type="pipe" />
        <Link href={PROXY_FAQ_HREF} target="_blank" rel="noopener noreferrer">
          Learn more
        </Link>
      </>
    );
  }

  return (
    <>
      {plural(selectableProxyTypes.length, {
        1: 'Available proxy type: ',
        other: 'Available proxy types: ',
      })}
      <Selected>{selectableProxyTypes.map(getProxyTypeNoun).join(', ')}</Selected>
      <SeparatorIcon type="pipe" />
      <Link href={PROXY_FAQ_HREF} target="_blank" rel="noopener noreferrer">
        Learn more
      </Link>
    </>
  );
};

export const BulkProxySection = ({
  selectedFunds,
  proxyDataByFund,
  onClearSelected,
  onPickerOpen,
  rangeDataByFund,
}: BulkProxySectionProps) => {
  const [isDeleteConfirmationOpen, openDeleteConfirmation, closeDeleteConfirmation] = useModal();

  const { hasPermission } = useContext(UserContext);

  const maxFundsExceeded = selectedFunds.length > MAX_NUM_SELECTED_FUNDS;

  const rawInvestmentReturnsRanges = useInvestmentsReturnsRanges(selectedFunds, proxyDataByFund, rangeDataByFund);
  const proxyTypeValidationResults = useProxyTypeValidation(selectedFunds, rawInvestmentReturnsRanges, undefined);
  const selectableProxyTypes = Object.keys(proxyTypeValidationResults).filter(
    (x) => !proxyTypeValidationResults[x].hasOnlyErrors,
  ) as (keyof typeof proxyTypeValidationResults)[];

  const selectedFundsWithProxy = useMemo(
    () => selectedFunds.filter((investment) => proxyDataByFund[investment.id]?.proxyId),
    [proxyDataByFund, selectedFunds],
  );

  const proxySelectDisabled =
    selectedFunds.length === 0 ||
    selectableProxyTypes.length === 0 ||
    !hasPermission('MANAGE_PROXY') ||
    maxFundsExceeded;

  return (
    <>
      <StickyFooter showErrorBorder={maxFundsExceeded}>
        <DescriptionContainer>
          <TopDescription>
            {plural(selectedFunds.length, {
              1: '{{count}} investment selected',
              other: '{{count}} investments selected',
            })}
          </TopDescription>
          <BottomDescription>
            <ProxyMessage
              selectableProxyTypes={selectableProxyTypes}
              canManageProxy={hasPermission('MANAGE_PROXY')}
              maxFundsExceeded={maxFundsExceeded}
            />
          </BottomDescription>
        </DescriptionContainer>
        <RightContainer>
          {selectedFundsWithProxy.length > 0 && (
            <Button
              onClick={openDeleteConfirmation}
              refreshedStyling
              destructive
              disabled={!hasPermission('MANAGE_PROXY')}
            >
              {plural(selectedFundsWithProxy.length, { 1: 'Remove proxy', other: 'Remove {{count}} proxies' })}
            </Button>
          )}

          <Button onClick={onClearSelected} refreshedStyling>
            Clear Selection
          </Button>
          <Button dominant onClick={onPickerOpen} refreshedStyling disabled={proxySelectDisabled}>
            Select a Proxy
          </Button>
        </RightContainer>
      </StickyFooter>

      {isDeleteConfirmationOpen && (
        <DeleteProxiesConfirmationModal
          fundsWithProxy={selectedFundsWithProxy}
          proxyDataByFund={proxyDataByFund}
          onCancel={closeDeleteConfirmation}
          onFinishedUpdate={(updatedFundIds) => {
            closeDeleteConfirmation();
            invalidate('investments', updatedFundIds);
          }}
        />
      )}
    </>
  );
};

const StickyFooter = styled.div<{ showErrorBorder: boolean }>`
  position: sticky;
  bottom: 0;
  background-color: ${GetColor.White};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ showErrorBorder }) => css`
    border: 3px solid ${showErrorBorder ? GetColor.Error : 'transparent'};
  `}
`;

const DescriptionContainer = styled.div`
  position: relative;
  flex-grow: 1;

  .bulk-management-table-wrapper,
  & > div {
    width: 100%;
  }
`;

const TopDescription = styled.h4`
  font-weight: 700;
  margin: 0 0 2px;
`;

const BottomDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const SeparatorIcon = styled(Icon)`
  color: ${GetColor.MidGrey1};
`;

const Selected = styled.span`
  text-transform: capitalize;
`;

const Link = styled.a`
  font-weight: 700;
`;

const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const WarningTextWrapper = styled.span`
  color: ${GetColor.Error};
`;
