import React from 'react';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';

import { hasDisabledBenchmark, hasDisabledIndividualBenchmark, isHistoricalSubject } from 'venn-utils';
import {
  blockBenchmarkConfig,
  blockCustomizableMetricsMap,
  blockInfoGraphicType,
  blockLimitedRequestSubjects,
  blockSettings,
  customizedBlock,
  isReportState,
} from 'venn-state';
import { HistoricalPortfolioStudioHeaderTooltips } from '../historical-portfolio/HistoricalPortfolioStudioHeaderTooltips';

import { Block } from '../../core';
import { CommonBenchmark } from '../common-benchmark';
import { BenchmarkSubject } from './benchmark-subject';
import { InvestmentSubject } from './investment-subject';

import { useAppPrintMode } from '../../../../print';
import { getUniqueKeyFromStudioRequestSubject } from '../../../../studio-blocks';

interface DefaultSubjectsProps {
  id: string;
  metric?: string;
  relativeToBenchmark: boolean;
  forceShowSubjects?: boolean;
}

export const DefaultSubjects = ({ id, metric, relativeToBenchmark, forceShowSubjects }: DefaultSubjectsProps) => {
  const { inPrintModeOrReportLab } = useAppPrintMode();

  const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(id));
  const blockSetting = useRecoilValue(blockSettings(id));
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(id));

  const subjects = useRecoilValue(blockLimitedRequestSubjects(id));
  const isStudio = !useRecoilValue(isReportState);
  const hasIndividualBenchmarks =
    benchmarkConfig.type === 'INDIVIDUAL' && !hasDisabledIndividualBenchmark(blockSetting?.customBlockType);

  const metricDefinitionsMap = useRecoilValue(blockCustomizableMetricsMap(id));
  const { selectedMetrics } = useRecoilValue(customizedBlock(id));
  const requiresBenchmark = relativeToBenchmark || (metric && metricDefinitionsMap[metric]?.needsBenchmark);

  const showSubjects =
    forceShowSubjects ||
    ((!inPrintModeOrReportLab || (inPrintModeOrReportLab && requiresBenchmark)) && !isEmpty(subjects));

  if (!showSubjects) {
    return null;
  }

  const shouldHideBenchmark =
    hasDisabledBenchmark(blockSetting.customBlockType, infoGraphicType) ||
    ((blockSetting.customBlockType === 'PRIVATE_PERFORMANCE_SUMMARY' ||
      blockSetting.customBlockType === 'PRIVATE_PERFORMANCE_TIME_SERIES') &&
      selectedMetrics
        .map((metric) => metricDefinitionsMap[metric])
        .every((metricDefinition) => metricDefinition.needsBenchmark !== true));

  return (
    <Block.Subjects id={id}>
      {!shouldHideBenchmark && benchmarkConfig?.subject && (
        <CommonBenchmark id={id} benchmarkSubject={benchmarkConfig?.subject} />
      )}
      {!shouldHideBenchmark && benchmarkConfig?.subject && subjects.length > 0 && <Block.SubjectsSeparator />}
      {subjects.map((subject) => (
        <Block.SubjectsRow key={getUniqueKeyFromStudioRequestSubject(subject)}>
          <InvestmentSubject id={id} subject={subject} />
          {isHistoricalSubject(subject) && isStudio && (
            <HistoricalPortfolioStudioHeaderTooltips blockId={id} subject={subject} />
          )}
          {hasIndividualBenchmarks && (
            <>
              <Block.SubjectsSeparator />
              <BenchmarkSubject subject={subject} />
            </>
          )}
        </Block.SubjectsRow>
      ))}
    </Block.Subjects>
  );
};
