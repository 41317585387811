import { atom } from 'recoil';
import { localStorageEffect } from '../effects';

export enum PageLayout {
  /** Vertical scrolling pages */
  VERTICAL = 'vertical',
  /** Horizontal scrolling pages */
  HORIZONTAL = 'horizontal',
  /** Vertical scrolling pages with two pages side by side */
  COLUMNS = 'columns',
}

/** Zoom in report lab. Be careful if you use this in Studio, as Studio doesn't have zoom applied, but this will still return the report lab zoom value. */
export const reportZoom = atom<number>({
  key: 'reportZoom',
  default: 1,
  effects: [localStorageEffect('report_zoom')],
});

export const reportPageLayout = atom<PageLayout>({
  key: 'reportPageLayout',
  default: PageLayout.VERTICAL,
  effects: [localStorageEffect('report_page_layout')],
});
