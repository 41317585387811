import { GetColor, Icon, portalMenuIgnoreActivityClassName, RelativePortal, ZIndex } from 'venn-ui-kit';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

type MappedPortfolioNodeCellRendererRootProps = {
  openSearch: () => void;
  isSearchOpen: boolean;
  closeSearch: () => void;
  isFund: boolean;
  isExcluded: boolean;
  isRemapped: boolean;
  needsMapping: boolean;
  shouldCloseSearchOnScroll: boolean;
  children: React.ReactNode;
};

const useCloseSearchOnScroll = ({
  isSearchOpen,
  closeFn,
  shouldCloseSearchOnScroll,
}: {
  isSearchOpen: boolean;
  closeFn: () => void;
  shouldCloseSearchOnScroll: boolean;
}) => {
  useEffect(() => {
    const handleScroll = () => {
      if (!shouldCloseSearchOnScroll) {
        return;
      }
      closeFn();
    };

    if (!isSearchOpen) {
      return () => {};
    }

    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [isSearchOpen, closeFn, shouldCloseSearchOnScroll]);
};

export const MappedPortfolioNodeCellRendererRoot = ({
  openSearch,
  isSearchOpen,
  isFund,
  isRemapped,
  closeSearch,
  needsMapping,
  shouldCloseSearchOnScroll,
  children,
  isExcluded,
}: MappedPortfolioNodeCellRendererRootProps) => {
  useCloseSearchOnScroll({ isSearchOpen, closeFn: closeSearch, shouldCloseSearchOnScroll });
  if (!isFund || isExcluded) {
    return null;
  }

  const button = isRemapped ? (
    <>
      <Icon type="retweet" />
      <Icon type="magnifying-glass" />
    </>
  ) : (
    <>
      {needsMapping ? 'Map' : <Icon data-testid="qa-check-mapping-icon" type="check" />}
      <Icon type="magnifying-glass" />
    </>
  );

  return (
    <>
      <ApplyMappingButton
        aria-label="remap investment"
        needsMapping={needsMapping}
        onClick={openSearch}
        data-testid="remap-investment-button"
      >
        {button}
      </ApplyMappingButton>
      {isSearchOpen && (
        <RelativePortal
          expectedHeight={480}
          style={{ zIndex: ZIndex.InterComFront }}
          onOutClick={closeSearch}
          className={portalMenuIgnoreActivityClassName}
        >
          <SearchWrapper data-testid="remap-investment-search-wrapper">{children}</SearchWrapper>
        </RelativePortal>
      )}
    </>
  );
};

const ApplyMappingButton = styled.button<{ needsMapping: boolean }>`
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  gap: 6px;
  font-size: 12px;
  border-radius: 4px;
  min-width: 60px;
  ${({ needsMapping }) =>
    needsMapping &&
    css`
      background-color: ${GetColor.Error};
    `};
  border: 1px solid ${({ needsMapping }) => (needsMapping ? GetColor.Error : GetColor.GreyScale.Grey80)};
  color: ${({ needsMapping }) => (needsMapping ? GetColor.White : GetColor.GreyScale.Grey80)};

  :hover {
    color: ${({ needsMapping }) => (needsMapping ? GetColor.White : GetColor.GreyScale.Grey80)};
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  top: 40px;
  left: -556px;
  z-index: ${ZIndex.InterComFront2};
  width: 778px;
  font-weight: normal;
  font-size: 14px;
`;
