import { BulkManagementTablePrimitives } from './components/BulkManagementTablePrimitives';
import React, { useMemo } from 'react';
import { type AnalysisSubject, withSuspense } from 'venn-utils';
import { useBulkManagementTable } from './hooks/useBulkManagementTable';
import { usePortfolioManagementColumns } from './hooks/usePortfolioManagementColumns';
import { BulkManagementLoadingState } from './BulkManagementLoadingState';
import { useInvestmentFilter } from './hooks/useInvestmentFilter';
import { getBulkManagementData } from './data';

type Props = {
  subject: AnalysisSubject;
  onFundUpdated?: (fundId: string) => void;
  canEditProxies: boolean;
  showLiquidatedInvestmentsToggle: boolean;
};

export const BulkPortfolioManagementTable = withSuspense(
  BulkManagementLoadingState,
  function BulkPortfolioManagementTable({
    subject,
    onFundUpdated,
    canEditProxies,
    showLiquidatedInvestmentsToggle,
  }: Props) {
    const {
      isPickerOpen,
      onPickerClose,
      openedSecondaryId,
      uniqFunds,
      selectedFunds,
      onProxyChange,
      rangeAnalysis,
      proxyDataByFund,
      rangeDataByFund,
      onClearSelected,
      onPickerOpen,
      gridRef,
      onSelectionChanged,
      groupHeaderHeight,
      rowClassRules,
      selectedAllocationDate,
      allocationDates,
      setOpenedSecondaryId,
      setSelectedFundIds,
      showLiquidatedInvestments,
      benchmarkInfo,
      rangeData,
      primaryFactorLens,
      setShowLiquidatedInvestments,
    } = useBulkManagementTable({
      subject,
      shouldHighlightRowsWithHistoricalErrors: true,
    });
    const { rowData, pinnedRowData } = useMemo(
      () =>
        getBulkManagementData(
          subject,
          uniqFunds,
          proxyDataByFund,
          rangeDataByFund,
          benchmarkInfo.benchmark,
          rangeData,
          primaryFactorLens,
          allocationDates,
          false,
        ),
      [
        subject,
        uniqFunds,
        proxyDataByFund,
        rangeDataByFund,
        benchmarkInfo.benchmark,
        rangeData,
        primaryFactorLens,
        allocationDates,
      ],
    );
    const { columnDefs, autoGroupColumnDef } = usePortfolioManagementColumns({
      subject,
      onFundUpdated,
      setSelectedFundIds,
      setOpenedSecondaryId,
      canEditProxies,
      onPickerOpen,
      rowData,
      showLiquidatedInvestmentsToggle,
      showLiquidatedInvestments,
      setShowLiquidatedInvestments,
    });
    const { isExternalFilterPresent, doesExternalFilterPass } = useInvestmentFilter({
      portfolio: subject?.portfolio,
      selectedAllocationDate,
      allocationDates,
      gridApi: gridRef.current?.api,
      showAllocations: false,
      showLiquidatedInvestments,
      showLiquidatedInvestmentsToggle,
    });
    return (
      <BulkManagementTablePrimitives.Root
        isPickerOpen={isPickerOpen}
        onPickerClose={onPickerClose}
        openedSecondaryId={openedSecondaryId}
        uniqFunds={uniqFunds}
        selectedFunds={selectedFunds}
        onProxyChange={onProxyChange}
        disableSelection={false}
        rangeAnalysis={rangeAnalysis}
        proxyDataByFund={proxyDataByFund}
        rangeDataByFund={rangeDataByFund}
        onClearSelected={onClearSelected}
        onPickerOpen={onPickerOpen}
      >
        <BulkManagementTablePrimitives.Grid
          gridRef={gridRef}
          rowData={rowData}
          pinnedRowData={pinnedRowData}
          columnDefs={columnDefs}
          onSelectionChanged={onSelectionChanged}
          groupHeaderHeight={groupHeaderHeight}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          disableSelection={false}
          autoGroupColumnDef={autoGroupColumnDef}
          rowClassRules={rowClassRules}
        />
      </BulkManagementTablePrimitives.Root>
    );
  },
);
