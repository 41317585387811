import React from 'react';
import styled from 'styled-components';
import { DropMenu, type DropMenuItem, GetColor, Icon, Label, Radio, Tooltip } from 'venn-ui-kit';
import {
  assertExhaustive,
  FIGURE_ICONS,
  FIGURE_TEXT,
  getSelectableMetricKeys,
  isPortfolioBlock,
  isTextOrFormatBlock,
  supportsCompactMode,
} from 'venn-utils';
import {
  DataGridSizeType,
  GeoLevelFilterType,
  PortfolioStrategyFilterType,
  SectorLevelFilterType,
  StudioTile,
} from 'venn-components';
import {
  type BlockId,
  blockInfoGraphicType,
  blockLogarithmicScale,
  blockMetrics,
  blockSettings,
  blockYAxisUnitFormat,
  geoLevelFilterType,
  isReportState,
  portfolioStrategyFilterType,
  sectorLevelFilterType,
  UnitFormat,
  viewDataGridSizeType,
} from 'venn-state';
import { useRecoilState, useRecoilValue } from 'recoil';
import type { InfoGraphicTypeEnum } from 'venn-api';
import { PanelSection } from '../../../PanelSection';
import { StyledLabel } from '../../../shared';
import { DEFAULT_SCALE } from '../../../../logic/studioUtils';

interface FormatSectionProps {
  selected: BlockId;
  isReadOnly?: boolean;
}

const gridSizeOptions: DropMenuItem<DataGridSizeType>[] = [
  { label: 'Default', value: DataGridSizeType.DEFAULT },
  { label: 'Compact', value: DataGridSizeType.COMPACT },
];

const portfolioFilterOptions: DropMenuItem<PortfolioStrategyFilterType>[] = [
  { label: 'All Portfolio Constituents', value: PortfolioStrategyFilterType.DEFAULT },
  { label: 'Strategies Only', value: PortfolioStrategyFilterType.ALL_STRATEGIES },
  { label: 'Top Level Strategies', value: PortfolioStrategyFilterType.TOP_LEVEL_STRATEGIES },
];

const geoLevelFilterOptions: DropMenuItem<GeoLevelFilterType>[] = [
  { label: 'Region Level', value: GeoLevelFilterType.REGION },
  { label: 'Sub-Region Level', value: GeoLevelFilterType.SUB_REGION },
  { label: 'Country Level', value: GeoLevelFilterType.DEFAULT },
];

const sectorLevelFilterOptions: DropMenuItem<SectorLevelFilterType>[] = [
  { label: 'Top-Level Sectors', value: SectorLevelFilterType.TOP_LEVEL },
  { label: 'Sub-Sectors', value: SectorLevelFilterType.SUB_SECTOR },
  { label: 'Individual Sectors', value: SectorLevelFilterType.DEFAULT },
];

const getTooltipStr = (
  infoGraphicType: InfoGraphicTypeEnum,
  selectedType: InfoGraphicTypeEnum | undefined,
  selectedMetricsCount: number,
  allowedMetrics: string[] | undefined,
): string | undefined => {
  if (infoGraphicType === selectedType) {
    return undefined;
  }

  switch (infoGraphicType) {
    case 'SCATTER':
      if (selectedMetricsCount > 2) {
        return 'Only two metrics will be displayed at a time';
      }
      return undefined;
    case 'LINE':
    case 'BAR':
    case 'DIVERGENT_BAR':
    case 'DISTRIBUTE_BAR':
    case 'FACTOR_TREND':
    case 'DRAWDOWN':
    case 'CORRELATION':
    case 'GROWTH_SIMULATION':
    case 'HEATMAP':
    case 'BOX':
    case 'PIE':
    case 'MAP':
    case 'AREA':
      if (allowedMetrics?.length !== 0 && selectedMetricsCount > 1) {
        return 'Charting is only available with 1 metric selected';
      }
      return undefined;
    case 'PIVOTED_GRID':
    case 'CALENDAR_GRID':
    case 'GRID':
    case 'GRID_TREE':
    case 'DEFAULT':
    case 'IMAGE':
    case 'PRIVATE_CASH_FLOW':
    case 'PEER_GROUP':
    case 'BOX_CHART':
      return undefined;
    default:
      throw assertExhaustive(infoGraphicType);
  }
};

const scaleOptions: DropMenuItem<boolean>[] = [
  { label: 'Logarithmic', value: true },
  { label: 'Linear', value: false },
];

const FormatSelection = ({ selected, isReadOnly }: FormatSectionProps) => {
  const settings = useRecoilValue(blockSettings(selected));
  const [infoGraphicType, setInfoGraphicType] = useRecoilState(blockInfoGraphicType(selected));
  const selectedMetrics = useRecoilValue(blockMetrics(selected));
  const [dataGridSize, setDataGridSize] = useRecoilState(viewDataGridSizeType(selected));
  const [portfolioStrategyFilter, setPortfolioStrategyFilter] = useRecoilState(portfolioStrategyFilterType(selected));
  const [geoLevelFilter, setGeoLevelFilter] = useRecoilState(geoLevelFilterType(selected));
  const [sectorLevelFilter, setSectorLevelFilter] = useRecoilState(sectorLevelFilterType(selected));
  const [yAxisFormat, setYAxisFormat] = useRecoilState(blockYAxisUnitFormat(selected));
  const [logarithmicScale, setLogarithmicScale] = useRecoilState(blockLogarithmicScale(selected));
  const availableTypes = settings.supportedGraphicTypes;
  const blockType = settings.customBlockType;

  // Only show the selected graphic if it's in read only
  const graphicTypesToDisplay = availableTypes.filter(
    (type) => !!FIGURE_TEXT[type] && (!isReadOnly || infoGraphicType === type),
  );

  const onChangeDataGridSizeType = (option: DropMenuItem<DataGridSizeType>) => setDataGridSize(option.value);

  const onChangePortfolioStrategyFilterType = (option: DropMenuItem<PortfolioStrategyFilterType>) =>
    setPortfolioStrategyFilter(option.value);

  const onChangeGeoLevelFilterType = (option: DropMenuItem<GeoLevelFilterType>) => setGeoLevelFilter(option.value);
  const onChangeSectorLevelFilterType = (option: DropMenuItem<SectorLevelFilterType>) =>
    setSectorLevelFilter(option.value);
  const isReport = useRecoilValue(isReportState);

  const blockAllowsGridStyleCustomization = supportsCompactMode(infoGraphicType, blockType);

  const allowsLogarithmicScale =
    ((blockType === 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN' && yAxisFormat === UnitFormat.ALLOCATION) ||
      blockType === 'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES' ||
      blockType === 'GROWTH_SIMULATION') &&
    !infoGraphicType?.includes('GRID');

  if (isTextOrFormatBlock(blockType)) {
    return null;
  }

  return (
    <PanelSection header="Format">
      <ButtonWrapper>
        {graphicTypesToDisplay.map((type) => {
          const hasMetricRestrictions = getSelectableMetricKeys(blockType, type);
          const tooltipStr = getTooltipStr(type, infoGraphicType, selectedMetrics.length, hasMetricRestrictions);

          return (
            <FormatWarningIconRoot key={type}>
              <StudioTile
                selected={infoGraphicType === type}
                onClick={isReadOnly ? undefined : () => setInfoGraphicType(type)}
                type={FIGURE_ICONS[type]}
                tooltip={FIGURE_TEXT[type]}
              />
              {tooltipStr && (
                <FormatWarningIcon>
                  <Tooltip content={tooltipStr} key={type} usePortal>
                    <Icon type="circle-exclamation" prefix="far" />
                  </Tooltip>
                </FormatWarningIcon>
              )}
            </FormatWarningIconRoot>
          );
        })}
      </ButtonWrapper>
      {!isReport && blockAllowsGridStyleCustomization && (
        <SectionPadding>
          <Label>Grid Size:</Label>
          <DropMenu<DataGridSizeType>
            items={gridSizeOptions}
            selected={dataGridSize ?? DataGridSizeType.DEFAULT}
            onChange={onChangeDataGridSizeType}
            disabled={isReadOnly}
          />
        </SectionPadding>
      )}
      {blockType === 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN' && !infoGraphicType?.includes('GRID') && (
        <YAxisFormatContainer>
          <Label>Y-axis:</Label>
          <Radio
            inputId="yAxisPercent"
            checked={yAxisFormat === UnitFormat.PERCENT}
            onChange={() => setYAxisFormat(UnitFormat.PERCENT)}
            value="yAxisPercent"
          >
            Percentage (%)
          </Radio>
          <Radio
            inputId="yAxisAllocation"
            checked={yAxisFormat === UnitFormat.ALLOCATION}
            onChange={() => setYAxisFormat(UnitFormat.ALLOCATION)}
            value="yAxisAllocation"
          >
            Value ($)
          </Radio>
        </YAxisFormatContainer>
      )}
      {allowsLogarithmicScale && (
        <SectionPadding>
          <StyledLabel>Scale:</StyledLabel>
          <DropMenu
            items={scaleOptions}
            selected={logarithmicScale ?? DEFAULT_SCALE}
            className="qa-logarithmic-scale"
            width={110}
            onChange={(item) => setLogarithmicScale(item.value)}
          />
        </SectionPadding>
      )}
      {infoGraphicType?.includes('GRID') && (blockType === 'PORTFOLIO_CONTRIBUTION' || isPortfolioBlock(blockType)) && (
        <SectionPadding>
          <Label>Portfolio Display Settings:</Label>
          <DropMenu<PortfolioStrategyFilterType>
            items={portfolioFilterOptions}
            selected={portfolioStrategyFilter ?? PortfolioStrategyFilterType.DEFAULT}
            onChange={onChangePortfolioStrategyFilterType}
            disabled={isReadOnly}
          />
        </SectionPadding>
      )}
      {infoGraphicType?.includes('GRID') && blockType === 'GEOGRAPHY_EXPOSURE' && (
        <SectionPadding>
          <Label>Display Settings:</Label>
          <DropMenu<GeoLevelFilterType>
            items={geoLevelFilterOptions}
            selected={geoLevelFilter ?? GeoLevelFilterType.DEFAULT}
            onChange={onChangeGeoLevelFilterType}
            disabled={isReadOnly}
          />
        </SectionPadding>
      )}
      {infoGraphicType?.includes('GRID') && blockType === 'SECTOR_EXPOSURE' && (
        <SectionPadding>
          <Label>Display Settings:</Label>
          <DropMenu<SectorLevelFilterType>
            items={sectorLevelFilterOptions}
            selected={sectorLevelFilter ?? SectorLevelFilterType.DEFAULT}
            onChange={onChangeSectorLevelFilterType}
            disabled={isReadOnly}
          />
        </SectionPadding>
      )}
    </PanelSection>
  );
};

export default FormatSelection;

const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  margin-top: 10px;
`;

const FormatWarningIconRoot = styled.div`
  position: relative;
`;

const FormatWarningIcon = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  height: 12px;
  border-radius: 50%;
  background-color: ${GetColor.White};
  color: ${GetColor.Primary.Dark};
`;

const SectionPadding = styled.div`
  padding-top: 10px;
`;

const YAxisFormatContainer = styled.div`
  padding-top: 10px;
  font-size: 14px;
  display: flex;
  font-weight: bold;
  flex-direction: column;
`;
