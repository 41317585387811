import React, { PureComponent } from 'react';
import { PERIODS } from './AnalysisChartLegend';
import type { ChartDataProps } from './VennChart';
import VennChart from './VennChart';
import { createVennLineChartConfig } from '../chart-config-logic';
import { compact, isNil } from 'lodash';
import type { AnalysisSubject } from 'venn-utils';
import { analyticsService, AnalyticsUtils } from 'venn-utils';
import type { FrequencyEnum } from 'venn-api';
import MediaQuery from 'react-responsive';

import type { ChartData, ChartDataWithRollingPeriods, RollingPeriod, SerieValue, UnitFormat } from '../types';
import { RollingPeriodEnum } from '../types';
import LineChartSerieProvider from './LineChartSerieProvider';
import { withTheme } from 'styled-components';
import type { Theme } from 'venn-ui-kit';
import type { RollingPeriodSettings } from '../../../contexts/analysis-view-context';
import { getMetaDataWithRollingPeriod } from './AnalysisLineChartUtils';

interface AnalysisLineChartProps extends ChartDataProps {
  analysisSubject?: AnalysisSubject;
  rollingPeriodSettings?: RollingPeriodSettings;
  frequency?: FrequencyEnum;
  relative: boolean;
  chartData?: ChartData;
  chartDataWithRollingPeriods?: ChartDataWithRollingPeriods;
  start?: number;
  end?: number;
  header: string;
  subHeader?: string | React.ReactNode;
  chartType?: 'line' | 'area';
  yAxisUnitFormat?: UnitFormat;
  defaultRollingPeriod?: RollingPeriod;
  onRollingPeriodChange?: (value?: RollingPeriodEnum, customPeriodMonths?: number) => void;
  allowCustomWindow?: boolean;
  theme: Theme;
}

interface AnalysisLineChartState {
  activePeriod?: RollingPeriod;
  customPeriod?: number;
}

class AnalysisLineChart extends PureComponent<AnalysisLineChartProps, AnalysisLineChartState> {
  constructor(props: AnalysisLineChartProps) {
    super(props);

    // The default period can be one of the predefined 1Y, 3Y, 5Y periods, or a custom period specified in months
    const { defaultRollingPeriod, rollingPeriodSettings, allowCustomWindow } = props;
    const [activePeriod, customPeriod] =
      allowCustomWindow && !isNil(rollingPeriodSettings?.customMonths)
        ? [undefined, rollingPeriodSettings?.customMonths]
        : [defaultRollingPeriod ?? PERIODS[0], undefined];

    this.state = {
      activePeriod,
      customPeriod,
    };
  }

  componentDidMount() {
    if (this.props.defaultRollingPeriod) {
      return;
    }

    const { activePeriod, customPeriod } = this.state;
    if (
      (!isNil(activePeriod) && this.props.defaultRollingPeriod === activePeriod) ||
      (!isNil(this.props.rollingPeriodSettings?.customMonths) &&
        this.props.rollingPeriodSettings?.customMonths === customPeriod)
    ) {
      return;
    }
    this.props.onRollingPeriodChange?.(activePeriod?.key, customPeriod);
  }

  render() {
    const {
      chartData,
      chartDataWithRollingPeriods,
      chartType = 'line',
      analysisSubject,
      relative,
      loadingSerieData,
    } = this.props;
    const { activePeriod, customPeriod } = this.state;

    if (!chartData && !chartDataWithRollingPeriods) {
      return this.renderChart();
    }

    if (chartData && chartDataWithRollingPeriods) {
      // TODO: can we prevent this from happening and remove the console.warn and replace it with a log to sentry?
      // eslint-disable-next-line no-console
      console.warn(
        'Too much data passed to render the chart.',
        'Either "chartData" or "chartDataWithRollingPeriods" should be provided, but not both.',
      );
      return this.renderChart();
    }

    return (
      <LineChartSerieProvider
        analysisSubject={analysisSubject}
        relative={relative}
        activePeriod={activePeriod}
        customPeriodMonths={customPeriod}
        loadingSerieData={loadingSerieData}
        chartData={chartData}
        chartDataWithRollingPeriods={chartDataWithRollingPeriods}
        chartType={chartType}
      >
        {({
          mainSerie,
          secondarySerie,
          benchmarkSerie,
          categorySerie,
        }: {
          mainSerie?: SerieValue;
          secondarySerie?: SerieValue;
          benchmarkSerie?: SerieValue;
          categorySerie?: SerieValue;
        }) => this.renderChart(mainSerie, secondarySerie, benchmarkSerie, categorySerie)}
      </LineChartSerieProvider>
    );
  }

  renderChart = (
    mainSerie?: SerieValue,
    secondarySerie?: SerieValue,
    benchmarkSerie?: SerieValue,
    categorySerie?: SerieValue,
  ) => {
    const {
      start,
      end,
      header,
      subHeader = '',
      chartDataWithRollingPeriods,
      yAxisUnitFormat,
      frequency,
      relative,
      allowCustomWindow,
      theme,
      downloadMetaData,
      ...chartDataProps
    } = this.props;
    const { activePeriod, customPeriod } = this.state;
    const isWithRollingPeriods = !!chartDataWithRollingPeriods;
    const disableRollingPeriodKeys = getDisableRollingPeriodKeys(chartDataWithRollingPeriods, relative);
    const numReturns = mainSerie?.data?.data?.length;
    const hasMainSerie = numReturns !== undefined && numReturns > 0;
    const mainSerieName = mainSerie?.name || '';
    const minStartDate = isWithRollingPeriods ? start : undefined;
    return (
      <MediaQuery print>
        {(print) => (
          <VennChart
            config={createVennLineChartConfig(
              compact([mainSerie, secondarySerie, benchmarkSerie, categorySerie]),
              theme,
              print ? 7.5 : 10.5,
              yAxisUnitFormat,
              frequency,
              minStartDate,
              print,
            )}
            fileName={`${header} - ${mainSerieName}`}
            disableDownload={!hasMainSerie}
            header={header}
            subHeader={subHeader}
            legendProps={{
              allowCustomWindow,
              isWithRollingPeriods,
              mainSerie,
              secondarySerie,
              benchmarkSerie,
              categorySerie,
              activePeriod,
              customPeriod,
              disableRollingPeriodKeys,
              updatePeriod: this.updatePeriod,
            }}
            hasMainSerie={hasMainSerie}
            exportPercentage={yAxisUnitFormat === 'percent'}
            downloadMetaData={
              isWithRollingPeriods
                ? getMetaDataWithRollingPeriod(
                    downloadMetaData,
                    activePeriod,
                    customPeriod,
                    chartDataProps.analysisSubject?.name,
                  )
                : downloadMetaData
            }
            {...chartDataProps}
          />
        )}
      </MediaQuery>
    );
  };

  updatePeriod = (activePeriod?: RollingPeriod, customPeriod?: number) => {
    if (isNil(activePeriod) && isNil(customPeriod)) {
      return;
    }
    if (!this.props.allowCustomWindow && isNil(activePeriod)) {
      return;
    }
    if (!isNil(activePeriod) && !isNil(customPeriod)) {
      // This should never happen; exactly one should be undefined.
      return;
    }

    this.setState({ activePeriod, customPeriod });

    this.props.onRollingPeriodChange?.(activePeriod?.key, customPeriod);

    const { analysisSubject, header, chartDataWithRollingPeriods, relative } = this.props;
    const objectName = chartDataWithRollingPeriods?.main?.name;
    const data = !isNil(activePeriod)
      ? relative
        ? chartDataWithRollingPeriods?.main?.data?.[activePeriod.key].relative
        : chartDataWithRollingPeriods?.main?.data?.[activePeriod.key].absolute
      : undefined;
    const dateRange = !data
      ? undefined
      : `${AnalyticsUtils.dateFormat(data[0][0])}-${AnalyticsUtils.dateFormat(data[data.length - 1][0])}`;
    analyticsService.chartModified({
      objectType: analysisSubject?.type,
      chart: header,
      rollingPeriod: isNil(activePeriod) ? `${customPeriod}M (custom)` : activePeriod.name,
      objectName,
      dateRange,
    });
  };
}

export default withTheme(AnalysisLineChart);

const getDisableRollingPeriodKeys = (
  chartDataWithRollingPeriods: ChartDataWithRollingPeriods | undefined,
  relative: boolean,
) => {
  if (!chartDataWithRollingPeriods) {
    return undefined;
  }
  const disableRollingPeriodKeys: RollingPeriodEnum[] = [];
  if (!checkRollingPeriodAvailable(chartDataWithRollingPeriods, relative, 0)) {
    disableRollingPeriodKeys.push(RollingPeriodEnum.ONE_YEAR);
  }
  if (!checkRollingPeriodAvailable(chartDataWithRollingPeriods, relative, 1)) {
    disableRollingPeriodKeys.push(RollingPeriodEnum.THREE_YEAR);
  }
  if (!checkRollingPeriodAvailable(chartDataWithRollingPeriods, relative, 2)) {
    disableRollingPeriodKeys.push(RollingPeriodEnum.FIVE_YEAR);
  }
  return disableRollingPeriodKeys;
};

const checkRollingPeriodAvailable = (
  chartDataWithRollingPeriods: ChartDataWithRollingPeriods,
  relative: boolean,
  index: number,
) =>
  relative
    ? chartDataWithRollingPeriods?.main?.data?.[index]?.relative?.length > 0
    : chartDataWithRollingPeriods?.main?.data?.[index]?.absolute?.length > 0;
