import {
  blockEmptyStateClass,
  BOLD_CLASS,
  DOWNLOAD_BLOCK_CLASS,
  JUSTIFY_HEADER_END_CLASS,
  LEFT_ALIGN_CLASS,
  RIGHT_ALIGN_CLASS,
  StudioBlockWatermark,
  useBlockId,
  useSetBlockSize,
  vennBlockWatermarkClass,
} from 'venn-components';
import React, { type ReactNode } from 'react';
import { recoilBlockMaxSize } from 'venn-state';
import Measure from 'react-measure';
import styled from 'styled-components';
import { ShimmerBlock } from 'venn-ui-kit';

export const BodyFoundations = {
  ContentWrapper: ({ children }: { children: React.ReactNode }) => {
    return (
      <BlockWatermarkContainer>
        <BlockContentWrapper>{children}</BlockContentWrapper>
        <StudioBlockWatermark />
      </BlockWatermarkContainer>
    );
  },
};

/** Contains the block content container and the block watermark, enabling the block watermark to overlay the block content container. */
const BlockWatermarkContainer = styled.div`
  flex: 1;
  min-height: 0;
  position: relative;
  height: 100%;

  :has(.${blockEmptyStateClass}) .${vennBlockWatermarkClass} {
    display: none;
  }
`;

const BlockContentWrapper = ({ children }: { children: ReactNode }) => {
  const setBlockContentMaxSizeState = useSetBlockSize(recoilBlockMaxSize.transformedState(useBlockId()));

  return (
    <Measure bounds onResize={setBlockContentMaxSizeState}>
      {({ measureRef }) => <BlockContentContainer ref={measureRef}>{children}</BlockContentContainer>}
    </Measure>
  );
};

const BlockContentContainer = styled.div`
  display: flex;
  /* needed to stop overflow */
  min-height: 0;
  height: 100%;

  /* Warning: Removing this CSS breaks many blocks in unexpected ways, because they stop growing and instead shrink down to their content's minimum size. */
  > div:last-child:not(${ShimmerBlock}) {
    width: 100%;
    height: 100%;
  }

  .${DOWNLOAD_BLOCK_CLASS} {
    width: 100%;
  }

  .${LEFT_ALIGN_CLASS} {
    text-align: left;
  }

  .${JUSTIFY_HEADER_END_CLASS} .ag-header-cell-label {
    justify-content: flex-end;
  }

  .${RIGHT_ALIGN_CLASS} {
    text-align: right;
  }
  .${BOLD_CLASS} {
    font-weight: bold;
  }
`;
