import React from 'react';
import { PortfolioWithLetterIcon } from './PortfolioWithLetterIcon';
import { useHasFF } from 'venn-utils';
import styled from 'styled-components';
import Icon from '../icon/Icon';

type HistoricalIconProps = {
  /** Fill the icon with solid color */
  color: string;
  className?: string;
  large?: boolean;
};

export const HistoricalPortfolioIcon = ({ color, className, large }: HistoricalIconProps) => {
  const hasHistorical = useHasFF('historical_portfolios_ff');
  return hasHistorical ? (
    <PortfolioWithLetterIcon
      dataTestId="qa-historical-portfolio-icon"
      letter="h"
      className={className}
      color={color}
      large={large}
    />
  ) : (
    <StyledIcon className={className} color={color} type="th" />
  );
};

const StyledIcon = styled(Icon)<{ color: string }>`
  color: ${(props) => props.color};
`;
