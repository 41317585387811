import React from 'react';
import styled, { withTheme } from 'styled-components';
import { pick } from 'lodash';
import type { Theme } from 'venn-ui-kit';
import { Tooltip } from 'venn-ui-kit';
import { Grid, Content } from './Layout';
import type { AllocationTreeProps } from './AllocationTree';
import AllocationTree from './AllocationTree';
import type { AllocationFooterProps } from './AllocationFooter';
import AllocationFooter from './AllocationFooter';
import type { AllocationHeaderProps } from './AllocationHeader';
import AllocationHeader from './AllocationHeader';
import { getDisabledOptimizationMessage } from 'venn-utils';
import { OptimalPortfolioContext } from '../contexts';
import type { InvestmentFactorForecast } from 'venn-api';
import { getColorsFromCompareType } from './AllocationUtils';

interface AllocationPanelContentProps
  extends Omit<
    AllocationHeaderProps & AllocationTreeProps & AllocationFooterProps & { theme: Theme },
    keyof AllocationPanelContentState
  > {
  investmentForecasts?: { [key: string]: InvestmentFactorForecast };
  isStudio: boolean;
}

interface AllocationPanelContentState {
  isDraggingInProgress: boolean;
  comparisonColor: string;
  lightComparisonColor: string;
}

class AllocationPanelContent extends React.Component<AllocationPanelContentProps, AllocationPanelContentState> {
  state = {
    isDraggingInProgress: false,
    comparisonColor: '',
    lightComparisonColor: '',
  };

  scrollElement = React.createRef<HTMLDivElement>();

  intervalHandler?: number = undefined;

  static getDerivedStateFromProps(nextProps: AllocationPanelContentProps) {
    const { selected: compareType, theme } = nextProps;
    const [primaryColor, lighterColor] = getColorsFromCompareType(compareType, theme);

    return {
      comparisonColor: primaryColor,
      lightComparisonColor: lighterColor,
    };
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandler);
  }

  onScrollUp = () => {
    clearInterval(this.intervalHandler);
    this.intervalHandler = window.setInterval(() => {
      this.scrollElement.current && this.scrollElement.current.scrollBy({ top: -10 });
    }, 60);
  };

  onScrollDown = () => {
    clearInterval(this.intervalHandler);
    this.intervalHandler = window.setInterval(() => {
      this.scrollElement.current && this.scrollElement.current.scrollBy({ top: 10 });
    }, 60);
  };

  onStopScroll = () => {
    clearInterval(this.intervalHandler);
  };

  setIsDragging = (isDragging: boolean) => {
    this.setState({ isDraggingInProgress: isDragging });
  };

  render() {
    const { portfolio, investmentForecasts } = this.props;
    const { isDraggingInProgress, comparisonColor, lightComparisonColor } = this.state;
    const headerProps = pick(this.props, [
      'hideMaster',
      'selected',
      'onChangeComparison',
      'masterName',
      'lastSavedPortfolio',
      'hasAllocationError',
      'togglePercentageMode',
      'isPercentageMode',
      'invalidTotal',
      'isModified',
      'isTradesView',
      'toggleTradesView',
      'tradesToggleDisabled',
      'hideComparisonColumn',
    ]);
    const treeProps = pick(this.props, [
      'portfolio',
      'selectedStrategyId',
      'allOriginalNodes',
      'allCompareNodes',
      'allGhostChildren',
      'hideCompareValue',
      'compareLoading',
      'onSelectStrategy',
      'onUpdatePortfolio',
      'isPercentageMode',
      'baseAllocation',
      'isTradesView',
      'orignalBaseAllocation',
      'allUpdatedFunds',
      'updateFund',
      'hasAccessToCompare',
      'hideComparisonColumn',
    ]);
    const footerProps = pick(this.props, [
      'canSave',
      'canReset',
      'onSave',
      'onSavedAsNewPortfolio',
      'getSaveAsSuccessCustomNotification',
      'onReset',
      'portfolio',
      'selectedStrategyId',
      'hasAllocationError',
      'isPercentageMode',
      'baseAllocation',
      'showOptimizerConstraintsButton',
      'onOptimizeSuccess',
      'optimizationError',
      'canRerunOptimization',
      'onRerunOptimization',
      'customAllocationConstraints',
      'hasComparison',
      'onApplyComparisonAllocationsToCurrent',
      'hideComparisonColumn',
      'isStudio',
    ]);

    const { canRerunOptimization, showOptimizerConstraintsButton, hideComparisonColumn } = this.props;

    return (
      <StyledGrid
        data-html2canvas-ignore
        isDraggingInProgress={isDraggingInProgress}
        hideComparisonColumn={hideComparisonColumn}
      >
        <AllocationHeader
          {...headerProps}
          comparisonColor={comparisonColor}
          lightComparisonColor={lightComparisonColor}
          onMouseEnter={isDraggingInProgress ? this.onScrollUp : undefined}
          onMouseLeave={isDraggingInProgress ? this.onStopScroll : undefined}
          disabledOptimizationMessage={getDisabledOptimizationMessage(portfolio, investmentForecasts)}
        />
        <Content ref={this.scrollElement} color={comparisonColor} hideComparisonColumn={hideComparisonColumn}>
          <AllocationTree {...treeProps} onDragFinished={this.onStopScroll} setIsDragging={this.setIsDragging} />

          {/* Comparison column overlay with tooltip */}
          {canRerunOptimization && showOptimizerConstraintsButton && (
            <TooltipWrapper>
              <StyledTooltip block hideArrow content="Rerun or Save to update optimization">
                <TooltipTrigger />
              </StyledTooltip>
            </TooltipWrapper>
          )}
        </Content>
        <AllocationFooter
          {...footerProps}
          comparisonColor={comparisonColor}
          showMouseEventsOverlay={isDraggingInProgress}
          onMouseEnter={isDraggingInProgress ? this.onScrollDown : undefined}
          onMouseLeave={isDraggingInProgress ? this.onStopScroll : undefined}
        />
      </StyledGrid>
    );
  }
}

const AllocationPanelContentWithContext = (props: Omit<AllocationPanelContentProps, 'investmentForecasts'>) => (
  <OptimalPortfolioContext.Consumer>
    {({ investmentForecasts }) => <AllocationPanelContent {...props} investmentForecasts={investmentForecasts} />}
  </OptimalPortfolioContext.Consumer>
);

export default withTheme(AllocationPanelContentWithContext);

const StyledGrid = styled(Grid)<{ isDraggingInProgress: boolean }>`
  ${({ isDraggingInProgress }) =>
    isDraggingInProgress &&
    `
    * {
      cursor: move !important;
      user-select: none;
      -ms-user-select: none;
    }
  `}
`;

const TooltipWrapper = styled.div`
  position: absolute;
  width: 105px;
  height: calc(100% - 62px);
  right: -1px;
  top: 62px;
`;

const StyledTooltip = styled(Tooltip)`
  > div:last-child {
    top: 33%;
    text-align: center;
  }
`;

const TooltipTrigger = styled.div`
  height: 100%;
  width: 100%;
`;
