import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import moment from 'moment';
import type { DrawdownFactorExposure, DrawdownRangeAnalysis, PortfolioCompare } from 'venn-api';
import type { Theme } from 'venn-ui-kit';
import { GetColor, TooltipBodyDirection, Tooltip, OverflowContainer, OverflowText, HighchartZIndex } from 'venn-ui-kit';
import Bar from '../../../../bar/Bar';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from '../../../../basictable/BasicTable';
import BasicTable, { SORTDIR, ColumnAlign } from '../../../../basictable/BasicTable';
import exportToExcel from '../../../../basictable/convertToExcel';
import DownloadableContentBlock from '../../../../content-block/DownloadableContentBlock';
import {
  formatColumnNumber,
  sortDrawdownFactorExposures,
  RESIDUAL_NAME,
  RISK_FREE_RATE_NAME,
  sortDrawdownFactorExposuresByContribution,
} from './utils';
import type { AnalysisSubject, AnyDuringEslintMigration } from 'venn-utils';
import { getSecondaryDisplayLabel, getFirstNegativeDay } from 'venn-utils';
import { compact, isNil } from 'lodash';
import { RESIDUAL_ID, RISK_FREE_RATE_ID } from '../../../../factor-chart/constants';
import { getLineColors } from '../../internal/utils';
import type { DownloadMetaData } from '../../../../downloadable';

const qaClass = 'qa-portfolio-factors';

function renderContributionTooltip({
  factorName,
  value,
  subject,
}: {
  factorName: string;
  value: string | null;
  subject: AnalysisSubject;
}) {
  return (
    <BarTooltip>
      Contribution to Drawdown:
      <BarTooltipHeader>{factorName}</BarTooltipHeader>
      <BarTooltipContent>
        <BarTooltipLeft>
          <span>{subject.name}:</span>
        </BarTooltipLeft>
        <BarTooltipRight>{`${value ? `${value}%` : '--'}`}</BarTooltipRight>
      </BarTooltipContent>
    </BarTooltip>
  );
}

function getColumnsForDataExport(
  subject: AnalysisSubject,
  compare: PortfolioCompare | undefined,
  canAnalyzeBenchmark: boolean,
  canAnalyzeSecondary: boolean,
): BasicTableColumn<DrawdownFactorExposure>[] {
  const canShowSecondaryPortfolio = subject.type === 'portfolio' && canAnalyzeSecondary;
  const canShowCategory = subject.type === 'investment' && !!subject?.categoryGroup && canAnalyzeSecondary;
  const canShowBenchmark = !!compare && canAnalyzeBenchmark;

  return compact([
    {
      label: 'Factor Name',
      accessor: 'factorName',
    },
    {
      label: `Percent of ${subject.name} Drawdown`,
      accessor: 'contribution',
      excelCellRenderer: ({ contribution }) => ({
        value: contribution || '--',
        percentage: !!contribution,
      }),
    },
    {
      label: `Starting Factor Exposure for ${subject.name}`,
      accessor: 'portfolioBeta',
      excelCellRenderer: ({ portfolioBeta }: DrawdownFactorExposure) => {
        return {
          value: portfolioBeta || '--',
          digits: 2,
        };
      },
    },
    canShowSecondaryPortfolio &&
      subject.secondaryLabel && {
        label: `Starting Factor Exposure for ${subject.name} (${getSecondaryDisplayLabel(subject, 'as of')})`,
        accessor: 'secondaryPortfolioBeta',
        excelCellRenderer: ({ secondaryPortfolioBeta }: DrawdownFactorExposure) => {
          return {
            value: secondaryPortfolioBeta || '--',
            digits: 2,
          };
        },
      },
    canShowBenchmark &&
      subject.activeBenchmarkName && {
        label: `Starting Factor Exposure for benchmark ${subject.activeBenchmarkName}`,
        accessor: 'benchmarkBeta',
        excelCellRenderer: ({ benchmarkBeta }: DrawdownFactorExposure) => {
          return {
            value: benchmarkBeta || '--',
            digits: 2,
          };
        },
      },
    canShowCategory &&
      subject.categoryGroup && {
        label: `Starting Factor Exposure for Category ${subject.categoryGroup.name}`,
        accessor: 'benchmarkBeta',
        excelCellRenderer: ({ secondaryPortfolioBeta }: DrawdownFactorExposure) => {
          return {
            value: secondaryPortfolioBeta || '--',
            digits: 2,
          };
        },
      },
  ]);
}

function getColumns(
  subject: AnalysisSubject,
  data: Partial<DrawdownFactorExposure>[],
  compare: PortfolioCompare | undefined,
  canAnalyzeBenchmark: boolean,
  canAnalyzeSecondary: boolean,
  theme: Theme,
): BasicTableColumn<DrawdownFactorExposure>[] {
  const colors = theme.Colors;
  return [
    {
      label: 'Factor Name',
      accessor: 'factorName',
      headerStyle: { paddingLeft: '20px' },
      cellStyle: { paddingLeft: '20px' },
      cellRenderer: ({ factorId, factorName }) => {
        const name = factorId === RESIDUAL_ID || factorId === RISK_FREE_RATE_ID ? <i>{factorName}</i> : factorName;
        return (
          <StyledNameOverflowContainer>
            <OverflowText.SingleLine>{name}</OverflowText.SingleLine>
          </StyledNameOverflowContainer>
        );
      },
    },
    {
      label: 'Percent of Portfolio Drawdown',
      accessor: 'contribution',
      align: ColumnAlign.RIGHT,
      sortable: true,
      sortTableFunc: sortDrawdownFactorExposuresByContribution,
      cellStyle: { color: colors.DataLineColor.Gold, width: 272, minWidth: 272, maxWidth: 272 },
      excelCellRenderer: ({ contribution }) => ({
        value: contribution || '--',
        percentage: !!contribution,
      }),
      cellRenderer: ({ factorName, contribution }) => {
        const maxDrawdown = data.reduce((max, { contribution: c }) => {
          if (Math.abs(c!) > max) {
            return Math.abs(c!);
          }
          return max;
        }, 1);
        const value = contribution ? (contribution * 100).toFixed(2) : null;
        const barValue = (Number(value) / (maxDrawdown * 100)) * 100;
        return (
          <PortfolioContribution>
            <StyledBar
              barColor={colors.DataBarColor.LightGold}
              barClassName={
                factorName === RESIDUAL_NAME ? 'residual' : factorName === RISK_FREE_RATE_NAME ? 'cash' : undefined
              }
              barWidth={200}
              barHeight={12}
              value={barValue}
              centerLine
            />
            <span style={{ display: 'inline-block', minWidth: 60 }}>{`${value ? `${value}%` : '--'}`}</span>
            {renderContributionTooltip({ factorName, subject, value })}
          </PortfolioContribution>
        );
      },
    },
    {
      headerStyle: { minWidth: 60, flexGrow: 0 },
    },
    {
      label: 'Starting Factor Exposure for Selected Period',
      accessor: 'portfolioBeta',
      align: ColumnAlign.RIGHT,
      sortable: true,
      sortTableFunc: sortDrawdownFactorExposures,
      sorted: SORTDIR.DESC,
      cellStyle: { color: colors.DataLineColor.Gold, minWidth: 280, paddingRight: 20 },
      headerStyle: { minWidth: 280, paddingRight: 33 },
      excelCellRenderer: ({ portfolioBeta }) => ({
        value: portfolioBeta || '--',
        percentage: !!portfolioBeta,
      }),
      cellRenderer: ({ factorId, portfolioBeta, benchmarkBeta, secondaryPortfolioBeta }: DrawdownFactorExposure) => {
        if (factorId === RESIDUAL_ID || factorId === RISK_FREE_RATE_ID) {
          return (
            <span>
              <ExposureValue color={colors.Grey}>--</ExposureValue>
              <ExposureValue>--</ExposureValue>
            </span>
          );
        }
        const maxBeta = data.reduce(
          (memo, dra) =>
            Math.max(
              memo,
              Math.abs(dra.portfolioBeta ?? NaN) || -Infinity,
              Math.abs(dra.benchmarkBeta ?? NaN) || -Infinity,
              Math.abs(dra.secondaryPortfolioBeta ?? NaN) || -Infinity,
            ),
          0,
        );

        const canShowSecondaryPortfolio = subject.type === 'portfolio' && !!secondaryPortfolioBeta;
        const canShowCategory = subject.type === 'investment' && !!subject?.categoryGroup && !!secondaryPortfolioBeta;

        const [mainColor, secondaryColor, benchmarkColor, categoryColor] = getColors(
          subject,
          canAnalyzeSecondary,
          compare,
          canAnalyzeBenchmark,
          theme,
        );

        return (
          <>
            <StyledBar
              barColor={colors.DataBarColor.LightGold}
              barWidth={200}
              barHeight={12}
              value={0}
              centerLine
              markers={compact([
                {
                  value: (portfolioBeta / maxBeta) * 100,
                  color: mainColor,
                  style: { zIndex: HighchartZIndex.Serie.Front },
                },
                canShowSecondaryPortfolio &&
                  secondaryColor && {
                    value: (secondaryPortfolioBeta / maxBeta) * 100,
                    color: secondaryColor,
                  },
                compare &&
                  !isNil(benchmarkBeta) &&
                  benchmarkColor && {
                    value: (benchmarkBeta / maxBeta) * 100,
                    color: benchmarkColor,
                  },
                canShowCategory &&
                  categoryColor && {
                    value: (secondaryPortfolioBeta / maxBeta) * 100,
                    color: categoryColor,
                  },
              ])}
            />
            <ExposureValue>
              {formatColumnNumber(portfolioBeta, { percent: false, precision: 2, multiplier: false })}
            </ExposureValue>
          </>
        );
      },
    },
  ];
}

interface FactorsReturnProps {
  subject: AnalysisSubject;
  benchmark: AnalysisSubject | undefined;
  data: DrawdownRangeAnalysis;
  canAnalyzeSecondary: boolean;
  downloadMetaData?: DownloadMetaData;
}

const FactorsReturn = ({ subject, benchmark, data, canAnalyzeSecondary, downloadMetaData }: FactorsReturnProps) => {
  const theme = useContext(ThemeContext);
  const compare = !benchmark?.portfolio?.historical ? subject.activeBenchmark : undefined;
  // The residual is not part of the factorExposureList array
  const factorExposuresWithResidualAndCash: Partial<DrawdownFactorExposure>[] = useMemo(
    () => [
      ...data.factorExposureList,
      {
        factorId: RESIDUAL_ID,
        factorName: RESIDUAL_NAME,
        contribution: data.residualContribution,
      },
      {
        factorId: RISK_FREE_RATE_ID,
        factorName: RISK_FREE_RATE_NAME,
        contribution: data.cashContribution,
      },
    ],
    [data.cashContribution, data.factorExposureList, data.residualContribution],
  );
  const secondaryLabel = subject?.secondaryLabel;
  const secondaryPortfolioTooltip =
    subject?.secondaryLabel === 'Last Saved'
      ? getSecondaryDisplayLabel(subject, `${subject.secondaryPortfolio?.name} as of`)
      : subject?.secondaryPortfolio?.name;
  const secondaryPortfolioLabel = secondaryLabel
    ? getSecondaryDisplayLabel(subject, undefined, 'Portfolio')
    : 'Master Portfolio';
  const showBenchmarkLegend = data?.canAnalyzeBenchmark && !!compare;
  const type = subject?.type;
  const [mainColor, secondaryColor, benchmarkColor, categoryColor] = getColors(
    subject,
    canAnalyzeSecondary,
    compare,
    showBenchmarkLegend,
    theme,
  );
  const columns = useMemo(
    () =>
      getColumns(subject, factorExposuresWithResidualAndCash, compare, showBenchmarkLegend, canAnalyzeSecondary, theme),
    [subject, factorExposuresWithResidualAndCash, compare, showBenchmarkLegend, canAnalyzeSecondary, theme],
  );
  const excelColumns = useMemo(
    () => getColumnsForDataExport(subject, compare, showBenchmarkLegend, canAnalyzeSecondary),
    [subject, compare, showBenchmarkLegend, canAnalyzeSecondary],
  );
  const excelData = useMemo(() => {
    // @ts-expect-error: TODO fix strictFunctionTypes
    const exportData = exportToExcel(factorExposuresWithResidualAndCash, excelColumns);
    const numberOfColumns = exportData[0].length;
    const start = data.portfolioDrawdown?.[0][0];
    const end = data.portfolioDrawdown?.[data.portfolioDrawdown.length - 1][0];
    const dates =
      start && end
        ? `${getFirstNegativeDay(start).format('DD MMM YYYY')} - ${moment.utc(end).format('DD MMM YYYY')}`
        : '(range unavailable)';
    const result = [
      new Array(numberOfColumns).fill({ value: '' }),
      new Array(numberOfColumns).fill({ value: '' }),
      ...exportData,
    ];
    result[0][0] = { value: dates, bold: true };
    return result;
  }, [factorExposuresWithResidualAndCash, excelColumns, data.portfolioDrawdown]);

  return (
    <DownloadableContentBlock
      header={`Factor Exposures for ${subject.name}`}
      downloadable={{
        png: true,
        excel: excelData,
        tracking: {
          description: 'DRAWDOWN_MARKET_ENV_FACTORS',
          relativeToBenchmark: false,
          subjectType: subject.type,
          subjectId: subject.id,
          userUploaded: subject.userUploaded,
        },
        options: {
          fileName: `Factor Exposures for ${subject.name}`,
          metaData: downloadMetaData,
        },
      }}
    >
      <StyledBasicTable
        columns={columns as AnyDuringEslintMigration}
        data={factorExposuresWithResidualAndCash}
        className={qaClass}
      />
      <PortfolioLegend>
        <LabelWithMarker color={mainColor}>{subject.name}</LabelWithMarker>
        {type === 'portfolio' && canAnalyzeSecondary && (
          <Tooltip
            content={secondaryPortfolioTooltip}
            isHidden={secondaryPortfolioLabel === 'Master Portfolio'}
            bodyDirection={TooltipBodyDirection.Left}
            hideArrow
          >
            <LabelWithMarker color={secondaryColor}>{secondaryPortfolioLabel}</LabelWithMarker>
          </Tooltip>
        )}
        {compare && showBenchmarkLegend && (
          <Tooltip content={compare.name} bodyDirection={TooltipBodyDirection.Left} hideArrow>
            <LabelWithMarker color={benchmarkColor}>Benchmark</LabelWithMarker>
          </Tooltip>
        )}
        {type === 'investment' && canAnalyzeSecondary && (
          <Tooltip
            content={`Category (${subject?.categoryGroup?.name})`}
            isHidden={false}
            bodyDirection={TooltipBodyDirection.Left}
            hideArrow
          >
            <LabelWithMarker color={categoryColor}>{`Category (${subject?.categoryGroup?.name})`}</LabelWithMarker>
          </Tooltip>
        )}
      </PortfolioLegend>
    </DownloadableContentBlock>
  );
};

export default FactorsReturn;

function getColors(
  subject: AnalysisSubject,
  canAnalyzeSecondary: boolean,
  compare: PortfolioCompare | undefined,
  canAnalyzeBenchmark: boolean,
  theme: Theme,
) {
  let colorIdx = 0;
  const lineColors = getLineColors(theme);
  const mainColor = lineColors[colorIdx++];
  const secondaryColor = subject.type === 'portfolio' && canAnalyzeSecondary ? lineColors[colorIdx++] : undefined;
  const benchmarkColor = compare && canAnalyzeBenchmark ? lineColors[colorIdx++] : undefined;
  const categoryColor = subject.type === 'investment' && canAnalyzeSecondary ? lineColors[colorIdx++] : undefined;
  return [mainColor, secondaryColor, benchmarkColor, categoryColor] as const;
}

const StyledNameOverflowContainer = styled(OverflowContainer)`
  width: 400px;
  min-width: 400px;
  @media (max-width: 1800px) {
    width: 200px;
    min-width: 200px;
  }
`;

const ExposureValue = styled.span<{ color?: string }>`
  display: inline-block;
  min-width: 50px;
  color: ${(props) => props.color || GetColor.DataLineColor.Gold};
`;

const StyledBar = styled(Bar)`
  border: 1px solid ${GetColor.Grey};
  .residual {
    background: repeating-linear-gradient(
      135deg,
      ${GetColor.DataLineColor.Gold},
      ${GetColor.DataLineColor.Gold} 3px,
      ${GetColor.White} 3px,
      ${GetColor.White} 4px
    );
  }
  .cash {
    background: repeating-linear-gradient(
      90deg,
      ${GetColor.DataLineColor.Gold},
      ${GetColor.DataLineColor.Gold} 1px,
      ${GetColor.White} 1px,
      ${GetColor.White} 2px
    );
  }
`;

const StyledBasicTable: StyledTableType<unknown> = styled(
  <T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />,
)`
  margin: 10px 0 20px;

  > tbody > tr {
    height: 26px;
  }
`;

const PortfolioLegend = styled.div`
  font-weight: bold;
  font-size: 12px;
  margin: 0 20px 20px 0;
  text-align: right;

  span {
    display: inline-block;
    position: relative;
    margin: 0 0 0 15px;

    &:before {
      content: '';
      position: absolute;
      left: -15px;
      top: 50%;
    }
  }
`;

const LabelWithMarker = styled.span<{ color?: string }>`
  &:before {
    height: 10px;
    width: 10px;
    transform: translateY(-50%) rotate(45deg);
    background: ${({ color }) => color ?? 'none'};
    border: 1px solid ${GetColor.White};
  }
`;

const PortfolioContribution = styled.div`
  position: relative;

  &:first-child {
    cursor: pointer;
  }

  &:hover {
    > div:last-child {
      display: flex;
    }
  }
`;

const BarTooltipHeader = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const BarTooltip = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  bottom: 26px;
  padding: 10px;
  text-align: left;
  font-size: 12px;
  background-color: rgba(16, 22, 27, 0.9);
  color: white;
  pointer-events: none;
`;

const BarTooltipContent = styled.div`
  display: flex;
  margin-top: 10px;
`;

const BarTooltipLeft = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 5px;

  span {
    white-space: nowrap;
  }
`;

const BarTooltipRight = styled.div`
  line-height: normal;
  text-align: right;
  white-space: nowrap;
`;
