import React, { useContext } from 'react';
import { analyticsService, withSuspense } from 'venn-utils';
import { compact } from 'lodash';
import {
  hasUnsavedChangesInPrivatesAllocator,
  hasUnsavedChangesState,
  hasUnsavedPortfolioChangesInAllocator,
  isReportState,
  portfolioInAllocator,
  useRecoilValueWithDefault,
} from 'venn-state';
import { useRecoilValue } from 'recoil';
import type { Portfolio } from 'venn-api';
import { ShimmerBlock } from 'venn-ui-kit';
import { ConfirmationModal } from '../modals';
import { Modal } from '../modal';
import { StudioContext, UserContext } from '../contexts';

interface ConfirmUnsavedChangesModalProps {
  onProceed: () => void;
  onReject: () => void;
}

export const ConfirmUnsavedChangesModal = withSuspense(
  <Modal>
    <ShimmerBlock height={300} />
  </Modal>,
  ({ onProceed, onReject }: ConfirmUnsavedChangesModalProps) => {
    const hasUnsavedReturnsAllocatorChanges = useRecoilValue(hasUnsavedPortfolioChangesInAllocator);
    const hasUnsavedPrivateAlloctorChanges = useRecoilValue(hasUnsavedChangesInPrivatesAllocator);
    const hasUnsavedAllocatorChanges = hasUnsavedReturnsAllocatorChanges || hasUnsavedPrivateAlloctorChanges;
    const { afterUnsavedChangesAction, isSaving, handleAfterUnsavedChangesAction } = useContext(StudioContext);
    const isReport = useRecoilValue(isReportState);
    const { canWriteToPortfolio } = useContext(UserContext);
    const allocatorPortfolio = useRecoilValue(portfolioInAllocator);
    const readOnlyPortfolioChanges = !!(allocatorPortfolio && !canWriteToPortfolio(allocatorPortfolio));
    const hideDiscard = !!afterUnsavedChangesAction?.hideDiscardBtn;
    const proceedBlocked = readOnlyPortfolioChanges;
    const hasUnsavedChanges = useRecoilValueWithDefault(hasUnsavedChangesState, false);

    return (
      <ConfirmationModal
        header="Would you like to save changes and continue?"
        subhead={getUnsavedChangesWarning(
          !!isReport,
          hasUnsavedChanges,
          hasUnsavedAllocatorChanges,
          hideDiscard,
          readOnlyPortfolioChanges ? allocatorPortfolio : undefined,
        )}
        proceedLabel={proceedBlocked ? 'Ok' : 'Save and Continue'}
        onCancel={async () => {
          analyticsService.ctaClicked({
            purpose: 'Cancel',
            locationOnPage: `${isReport ? 'Report' : 'Studio'} discard changes modal`,
          });
          await handleAfterUnsavedChangesAction('cancel');
          onReject();
        }}
        cancelLabel="Cancel"
        secondaryLabel={hideDiscard ? undefined : 'Discard Changes'}
        proceedDisabled={isSaving}
        onSecondaryClick={async () => {
          analyticsService.ctaClicked({
            purpose: 'Discard',
            locationOnPage: `${isReport ? 'Report' : 'Studio'} discard changes modal`,
          });
          await handleAfterUnsavedChangesAction('proceed');
          onProceed();
        }}
        onProceed={async () => {
          if (proceedBlocked) {
            analyticsService.ctaClicked({
              purpose: 'readonly-blocked-ok',
              locationOnPage: `${isReport ? 'Report' : 'Studio'} discard changes modal`,
            });
            await handleAfterUnsavedChangesAction('cancel');
            onReject();
            return;
          }

          analyticsService.ctaClicked({
            purpose: 'Apply',
            locationOnPage: `${isReport ? 'Report' : 'Studio'} discard changes modal`,
          });
          await handleAfterUnsavedChangesAction('saveAndProceed');
          onProceed();
        }}
      />
    );
  },
);

const getUnsavedChangesWarning = (
  isReport: boolean,
  hasUnsavedChange: boolean,
  hasUnsavedAllocatorChanges: boolean,
  hideDiscard: boolean,
  readOnlyAllocatorPortfolio?: Portfolio,
) =>
  compact([
    readOnlyAllocatorPortfolio &&
      `Read-only portfolio ("${readOnlyAllocatorPortfolio.name}") cannot be saved. Please "Save As" a new portfolio to save the changes.`,
    hideDiscard
      ? `Changes to the ${getChangedObjectsString(
          isReport,
          hasUnsavedChange,
          hasUnsavedAllocatorChanges,
        )} must be saved to continue.`
      : `Discarding changes will revert the ${getChangedObjectsString(
          isReport,
          hasUnsavedChange,
          hasUnsavedAllocatorChanges,
        )} to the last saved version.`,
  ]).join('\n\n');

const getChangedObjectsString = (isReport: boolean, hasUnsavedChange: boolean, hasUnsavedAllocatorChanges: boolean) =>
  `${compact([
    hasUnsavedChange && `${isReport ? 'report' : 'studio'} view`,
    hasUnsavedAllocatorChanges && 'portfolio',
  ]).join(' and ')}`;
