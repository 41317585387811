import type { StudioRequestSubject } from '../types';
import type { ComputedDateRange, CreateSerializableParam } from 'venn-utils';
import type { Portfolio, SimpleFund } from 'venn-api';
import { analysis } from 'venn-api';
import type { ApiSubject } from '../utils';
import { convertApiSubjectToStudioSubject, convertStudioSubjectToApiSubject } from '../utils';
import { cloneDeepWith, pick } from 'lodash';
import type { GetRecoilValue } from 'recoil';
import { atomFamily, selectorFamily } from 'recoil';
import {
  incrementOnStudioReset,
  incrementOnSubjectChange,
  incrementOnWorkspaceConfigurationChange,
} from '../../effects/signalEffects';
import { getDefaultRange } from '../consts';

/**
 * We include portfolio version as a field so that if a portfolio is saved during the session, we refetch
 */
type DateRangeSubject = ApiSubject & { portfolioVersion: number | undefined };

/**
 * Recoil dislikes interfaces, and Portfolio is defined as an interface, and it contains several fields with value-types that are interfaces.
 *
 * To fix that, we just wrap {@link DateRangeSubject} with {@link CreateSerializableParam}.
 */
type SerializableApiSubject = CreateSerializableParam<DateRangeSubject>;

/**
 * An id that is a dependency of subjectGroupRangeQuery
 * so that it's cache will be cleared when this atom changes
 * See https://recoiljs.org/docs/guides/asynchronous-data-queries/#use-a-request-id for more info
 */
const subjectGroupRangeQueryId = atomFamily<number, SerializableApiSubject[]>({
  key: 'subjectGroupRangeQueryId',
  default: 0,
  effects: (subjects) => [
    incrementOnStudioReset,
    ...subjects.map((subject) => incrementOnSubjectChange(convertApiSubjectToStudioSubject(subject))),
    incrementOnWorkspaceConfigurationChange,
  ],
});

/**
 * Performs a range debug fetch for the provided subjects, making use of the recoil selector cache to prevent unnecessary fetches.
 *
 * For optimal cache usage, all range debug fetching should happen through this selector, and subjects should be sorted by ID before using the selector.
 *
 * TODO(VENN-25070) explicitly handle errors
 */
const subjectGroupRangeQuery = selectorFamily<ComputedDateRange, SerializableApiSubject[]>({
  key: 'subjectGroupRangeQuery',
  get:
    (dateRangeSubjects) =>
    async ({ get }) => {
      get(subjectGroupRangeQueryId(dateRangeSubjects));

      // Remove portfolio version, as it is not used in the api
      const subjects = dateRangeSubjects.map(({ portfolioVersion, ...rest }) => rest);

      if (subjects.length === 0) {
        return getDefaultRange();
      }

      const { content } = await analysis({
        analyses: [
          {
            analysisType: 'RANGE_DEBUG',
            relative: false,
            scenarios: [],
            includeAllPredefinedPeriods: false,
          },
        ],
        subjects,
      });

      return {
        range: {
          to: content.endTime,
          from: content.startTime,
        },
        maxRange: {
          to: content.maxEndTime,
          from: content.maxStartTime,
        },
        frequency: content.frequency,
      };
    },
});

/**
 * Helper that gets the {@link subjectGroupRangeQuery} by converting the provided subjects to API subjects,
 * and optimizes the API subjects for use with the query (stripping out any unused fields).
 *
 * Implemented as a simple function rather than selector because caching at this level would be redundant with the cache from {@link subjectGroupRangeQuery}.
 */
export function recoilGetSubjectGroupRange(get: GetRecoilValue, subjects: StudioRequestSubject[]) {
  const rawApiSubjects = subjects.filter((s) => !s.private).map(getDateRangeSubject);

  const optimizedSubjects = optimizeSubjectsForCache(rawApiSubjects);
  const resultWithOpt = get(subjectGroupRangeQuery(optimizedSubjects));
  return resultWithOpt;
}

/** Optimizes the subjects array to improve caching hit-rate by trimming unused fields and sorting subject arrays and portfolio arrays by ID. */
function optimizeSubjectsForCache(oldSubjects: DateRangeSubject[]): DateRangeSubject[] {
  // Deep clone is somewhat expensive, but ~10000x faster than a cache-miss and network IO.
  // We must do a deep clone if we want to mutate the input because:
  //  1. we don't want to modify the portfolios used by e.g. allocator panel
  //  2. recoil gets very angry if you modify inputs, because usage of recil should be fairly pure
  const deepCloningFn = (value: unknown, key: string | number | undefined): unknown => {
    if (key === 'allocation' && typeof value === 'number') {
      // Backend only cares if a fund is included, not what the exact allocation is.
      // This makes sense because a date range doesn't care about the scaling being applied.
      return value === 0 ? 0 : 1;
    }

    const portfolioKeysToKeep: (keyof Portfolio)[] = ['id', 'name', 'children', 'fund', 'allocation'];
    const optimizePortfolio = (p?: Portfolio) => p && cloneDeepWith(pick(p, portfolioKeysToKeep), deepCloningFn);

    if (key === 'portfolio' && value) {
      return optimizePortfolio(value as Portfolio);
    }

    if (key === 'children' && Array.isArray(value)) {
      const childrenArr = value as Portfolio[];
      return childrenArr.map(optimizePortfolio);
    }

    if (key === 'fund' && value) {
      const fund = value as SimpleFund;
      const fundKeysToKeep: (keyof SimpleFund)[] = ['id', 'name'];
      return cloneDeepWith(pick(fund, fundKeysToKeep), deepCloningFn);
    }

    // Allow regular clone deep to be performed
    return undefined;
  };

  const newSubjects = oldSubjects.map((oldSubject) => {
    const subjectKeysToKeep: (keyof DateRangeSubject)[] = [
      'id',
      'subjectType',
      'comparisonType',
      'portfolio',
      'portfolioVersion',
    ];
    return cloneDeepWith(pick(oldSubject, subjectKeysToKeep), deepCloningFn);
  });

  // Date range debug doesn't care about order, and sorting enables greater caching since the
  // subjects (and their order) are used as the cache key.
  sortById(newSubjects);
  for (const subject of newSubjects) {
    if (subject.portfolio) {
      recursiveSortPortfolioIds(subject.portfolio);
    }
  }

  return newSubjects;
}

function recursiveSortPortfolioIds(portfolio: Portfolio) {
  if (!portfolio.children) {
    return;
  }

  sortById(portfolio.children);
  for (let i = 0; i < portfolio.children.length; ++i) {
    recursiveSortPortfolioIds(portfolio.children[i]);
  }
}

function sortById(idArr: { id: string | number; version?: number }[]) {
  idArr.sort((a, b) => {
    // Most of the time ID alone is enough
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;

    // But version is needed for historic portfolio comparison in which the same portfolio is compared against itself at a different revision
    if ((a.version || 0) < (b.version || 0)) return -1;
    if ((a.version || 0) > (b.version || 0)) return 1;

    return 0;
  });
}

function getDateRangeSubject(subject: StudioRequestSubject) {
  return {
    ...convertStudioSubjectToApiSubject(subject, 'PRIMARY', true),
    portfolioVersion: subject.portfolio?.version,
  };
}
