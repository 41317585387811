import React, { useCallback, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { GetColor, getItemLabel, Icon, Notifications, NotificationType, SwapArrowsIcon } from 'venn-ui-kit';
import { SaveAnalysisViewModal, ShareModal, StudioContext, UserContext } from 'venn-components';
import { analyticsService, copyToClipboard, Numbers, useModal } from 'venn-utils';
import { InlineInput } from '../pages/Shared';
import { getTopBarColor, type GetTopBarColorType, topBarButton } from './shared';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  analysisViewIdState,
  analysisViewNameState,
  analysisViewOwnerContextIdState,
  analysisViewTypeState,
  exportingToExcel,
  hasUnsavedChangesState,
  isReportState,
  isTemplateState,
  PageLayout,
  reportPageLayout,
  reportZoom,
  useRecoilValueWithDefault,
} from 'venn-state';
import { useRedistributableNotification } from '../../logic/useRedistributableNotification';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { Label } from '@/components/ui/label';
import { RedesignedPdfExport } from './RedesignedPdfExport';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { AllocationsButton } from './AllocationsButton';

interface TopBarProps {
  openReportConfigModal?: () => void;
}

export const RedesignedTopBar = React.memo(function RedesignedTopBar({ openReportConfigModal }: TopBarProps) {
  const [zoom, setZoom] = useRecoilState(reportZoom);
  const [layout, setLayout] = useRecoilState(reportPageLayout);
  const isExportingExcel = useRecoilValue(exportingToExcel);
  const { hasPermissionForResource, currentContext } = useContext(UserContext);
  const {
    onSave: onSaveInput,
    onExcelExport,
    reportNameValue,
    onChangeReportName,
    onSaveAs,
    isCheckingDuplicateReportName,
    isDuplicateReportName,
    isFetchingNewViewRef,
    setAfterUnsavedChangesAction,
  } = useContext(StudioContext);
  const viewId = useRecoilValue(analysisViewIdState);
  const viewName = useRecoilValue(analysisViewNameState);
  const analysisViewType = useRecoilValue(analysisViewTypeState);
  const ownerContextId = useRecoilValue(analysisViewOwnerContextIdState);
  const isReport = useRecoilValue(isReportState);
  const isTemplate = useRecoilValue(isTemplateState);
  const barColors = getTopBarColor({ isReport, isTemplate });
  const hasUnsavedChanges = useRecoilValueWithDefault(hasUnsavedChangesState, false);

  useRedistributableNotification();

  const [isSaving, setIsSaving] = useState(false);

  const onSave = useCallback(async () => {
    setIsSaving(true);
    await onSaveInput?.();
    setIsSaving(false);
  }, [onSaveInput]);

  const [isOpenShareEmailModal, openShareEmailModal, closeShareEmailModal] = useModal();
  const [isOpenSaveAsModal, openSaveAsModal, closeSaveAsModal] = useModal();

  const canSave = hasPermissionForResource(isTemplate ? 'STUDIO_CREATE_TEMPLATE' : 'EDIT_ANALYSIS_VIEW', {
    ownerContextId: ownerContextId ?? currentContext,
  });

  // Save as would use current context as default, check if has permission
  const canSaveAs = hasPermissionForResource('CREATE_STUDIO_VIEW', {
    ownerContextId: currentContext,
  });

  const handleCopyLink = async () => {
    const response = await copyToClipboard(window.location.href);
    if (response) {
      Notifications.notify('Link copied', NotificationType.SUCCESS);
      analyticsService.ctaClicked({
        purpose: isReport ? 'copy Report Lab Link' : 'copy studio link',
        locationOnPage: isReport ? 'Report Lab Dropdown' : 'studio share dropdown',
      });
    } else {
      Notifications.notify('Problem copying link', NotificationType.ERROR);
    }
  };

  const handleShareAction = (proceedCallback: () => void) => {
    if (hasUnsavedChanges) {
      setAfterUnsavedChangesAction({
        proceedCallback,
        cancelCallback: undefined,
        hideDiscardBtn: !viewId,
      });
    } else {
      proceedCallback();
    }
  };

  const onZoom = useCallback(
    (zoom: number, ratio: number) => {
      analyticsService.ctaClicked({
        purpose: `zoom ${ratio > 0 ? 'in' : 'out'}`,
        locationOnPage: 'studio topbar',
      });
      setZoom(zoom + ratio);
    },
    [setZoom],
  );

  const onLayoutChange = useCallback(
    (layout: PageLayout) => {
      analyticsService.ctaClicked({
        purpose: `change layout to ${layout}`,
        locationOnPage: 'studio topbar',
      });
      setLayout(layout);
    },
    [setLayout],
  );

  const onZoomOut = useCallback(() => onZoom(zoom, -0.1), [onZoom, zoom]);
  const onZoomIn = useCallback(() => onZoom(zoom, 0.1), [onZoom, zoom]);

  return (
    <>
      <div data-testid="qa-top-bar" className="flex items-center justify-between h-[65px] bg-venn-grey-90">
        <div className="flex justify-start items-center h-full">
          <AllocationsButton />
          <button
            type="button"
            className={topBarButton()}
            onClick={openReportConfigModal}
            disabled={isFetchingNewViewRef.current}
          >
            Layout
          </button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <button
                type="button"
                className="text-white h-full hover:bg-venn-grey-80 aria-expanded:bg-white aria-expanded:text-venn-dark-blue hover:text-white"
                disabled={isFetchingNewViewRef.current}
              >
                <div className="flex items-center gap-1 px-3  h-6 border-0 border-r border-solid border-venn-grey-60">
                  Save
                  <Icon type="caret-down" prefix="fas" />
                </div>
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 text-white bg-venn-grey-90">
              <DropdownMenuItem
                className="focus:bg-venn-grey-80 focus:text-white text-lg"
                disabled={
                  !hasUnsavedChanges ||
                  isSaving ||
                  isDuplicateReportName ||
                  isCheckingDuplicateReportName ||
                  isFetchingNewViewRef.current ||
                  !canSave
                }
                onSelect={onSave}
              >
                Save
              </DropdownMenuItem>
              <DropdownMenuItem
                className="focus:bg-venn-grey-80 focus:text-white text-lg"
                disabled={isFetchingNewViewRef.current || !viewId || !canSaveAs}
                onSelect={openSaveAsModal}
              >
                Save As
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <LabelDateStack>
            <CenteredDocumentLabelWrapper>
              <SavedViewNameWrapper>
                {isTemplate && (
                  <TemplateLabelWrapper barColors={barColors}>{getItemLabel(analysisViewType)}</TemplateLabelWrapper>
                )}
                <DocumentNameLabel
                  $inPrintMode={false}
                  placeholder="Untitled Analysis"
                  value={reportNameValue}
                  onChange={onChangeReportName}
                  $isDuplicateReportName={isDuplicateReportName}
                  disabled={isFetchingNewViewRef.current}
                  $barColors={barColors}
                />
              </SavedViewNameWrapper>
            </CenteredDocumentLabelWrapper>
          </LabelDateStack>
        </div>
        <div className="flex justify-end items-center h-full">
          {isReport && (
            <div className="flex gap-5 px-4">
              <div className="flex items-center gap-2">
                <Label id="view-label" className="text-white text-lg">
                  Page view
                </Label>
                <ToggleGroup
                  id="layouts"
                  type="single"
                  value={layout}
                  onValueChange={onLayoutChange}
                  disabled={isFetchingNewViewRef.current}
                  aria-labelledby="view-label"
                  className="gap-0 border border-solid border-venn-grey-30 rounded-lg overflow-hidden"
                >
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <ToggleGroupItem
                          aria-label="Toggle horizontal"
                          value={PageLayout.HORIZONTAL}
                          className="h-[40px] w-[40px] rounded-none text-xl text-white hover:bg-venn-grey-80 hover:text-white data-[state=on]:text-venn-dark-blue"
                        >
                          <Icon type="arrows-h" />
                        </ToggleGroupItem>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="bottom">Horizontal</TooltipContent>
                  </Tooltip>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <ToggleGroupItem
                          aria-label="Toggle side by side"
                          value={PageLayout.COLUMNS}
                          className="flex h-[40px] w-[40px] rounded-none text-xl text-white hover:bg-venn-grey-80 hover:text-white data-[state=on]:text-venn-dark-blue"
                        >
                          <SwapArrowsIcon className="!h-auto !w-[32px]" />
                        </ToggleGroupItem>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="bottom">Side by Side</TooltipContent>
                  </Tooltip>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <ToggleGroupItem
                          aria-label="Toggle vertical"
                          value={PageLayout.VERTICAL}
                          className="h-[40px] w-[40px] rounded-none text-xl text-white hover:bg-venn-grey-80 hover:text-white data-[state=on]:text-venn-dark-blue"
                        >
                          <Icon type="arrows-v" />
                        </ToggleGroupItem>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="bottom">Vertical</TooltipContent>
                  </Tooltip>
                </ToggleGroup>
              </div>
              <div className="flex gap-1 border border-solid border-venn-grey-30 rounded-lg overflow-hidden">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <button
                      type="button"
                      className="h-[40px] w-[40px] text-xl flex items-center px-3 hover:bg-venn-grey-80 text-white hover:text-white"
                      aria-label="Zoom out"
                      disabled={zoom < 0.15 || isFetchingNewViewRef.current}
                      onClick={onZoomOut}
                    >
                      <Icon type="search-minus" prefix="far" />
                    </button>
                  </TooltipTrigger>
                  <TooltipContent side="bottom">Zoom Out</TooltipContent>
                </Tooltip>
                <div
                  className="flex items-center justify-center w-[40px] text-white"
                  role="spinbutton"
                  aria-valuenow={zoom}
                  aria-valuemin={0.1}
                  aria-valuemax={2}
                  aria-label="Zoom"
                >
                  {Numbers.safeFormatPercentage(zoom, 0)}
                </div>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <button
                      type="button"
                      className="h-[40px] w-[40px] text-xl flex items-center px-3 hover:bg-venn-grey-80 text-white hover:text-white"
                      aria-label="Zoom in"
                      disabled={zoom > 1.95 || isFetchingNewViewRef.current}
                      onClick={onZoomIn}
                    >
                      <Icon type="search-plus" prefix="far" />
                    </button>
                  </TooltipTrigger>
                  <TooltipContent side="bottom">Zoom In</TooltipContent>
                </Tooltip>
              </div>
            </div>
          )}
          <DropdownMenu>
            <Tooltip>
              <TooltipTrigger asChild>
                <DropdownMenuTrigger asChild>
                  <button
                    type="button"
                    className="flex gap-2 items-center justify-center text-white h-[64px] w-[64px] hover:bg-venn-grey-80 aria-expanded:bg-white aria-expanded:text-venn-dark-blue hover:text-white"
                    disabled={isFetchingNewViewRef.current}
                    aria-label="Share options"
                  >
                    <Icon type="share-alt" prefix="far" className="text-2xl" />
                    <Icon type="caret-down" prefix="fas" />
                  </button>
                </DropdownMenuTrigger>
              </TooltipTrigger>
              <TooltipContent side="bottom">Share Report</TooltipContent>
            </Tooltip>
            <DropdownMenuContent className="w-56 text-white bg-venn-grey-90">
              <DropdownMenuItem
                className="focus:bg-venn-grey-80 focus:text-white text-lg"
                onSelect={() => handleShareAction(openShareEmailModal)}
              >
                <Icon type="envelope" prefix="far" />
                Email
              </DropdownMenuItem>
              <DropdownMenuItem
                className="focus:bg-venn-grey-80 focus:text-white text-lg"
                onSelect={() => handleShareAction(handleCopyLink)}
              >
                <Icon type="link" prefix="far" />
                Copy URL
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Tooltip>
            <TooltipTrigger asChild>
              <button
                type="button"
                className="flex gap-2 items-center justify-center text-white h-[64px] w-[64px] hover:bg-venn-grey-80 hover:text-white"
                onClick={onExcelExport}
                disabled={isFetchingNewViewRef.current || isExportingExcel}
                aria-label="Export to excel"
              >
                <Icon type="file-excel" className="text-2xl" />
              </button>
            </TooltipTrigger>
            <TooltipContent side="bottom">Excel Export</TooltipContent>
          </Tooltip>
          <RedesignedPdfExport />
        </div>
      </div>
      {isOpenShareEmailModal && (
        <ShareModal onClose={closeShareEmailModal} shareMetaData={{ savedViewName: viewName }} closeOnOutsideClick />
      )}
      {isOpenSaveAsModal && (
        <SaveAnalysisViewModal
          mode="SAVE_AS"
          defaultName={`${viewName} (Copy)`}
          onSubmit={onSaveAs}
          onClose={closeSaveAsModal}
        />
      )}
    </>
  );
});

const DocumentNameLabel = styled(InlineInput)<{ $isDuplicateReportName?: boolean; $barColors: GetTopBarColorType }>`
  padding-left: 5px;
  padding-right: 25px;
  overflow: hidden;
  font-weight: bold;
  font-size: 20px;
  color: ${GetColor.White};

  > input {
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 1px;
  }

  input:disabled {
    color: ${({ $barColors }) => $barColors.DarkGrey};
  }

  ${({ $isDuplicateReportName }) =>
    $isDuplicateReportName &&
    css`
      > input {
        color: ${GetColor.Error};
        outline: 1px solid ${GetColor.Error};
        :hover {
          outline: 1px solid ${GetColor.Alert};
        }
        :focus {
          outline: 1px solid ${GetColor.Alert};
        }
      }
    `}
`;

const CenteredDocumentLabelWrapper = styled.div`
  color: ${GetColor.White};
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
`;

const SavedViewNameWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
`;

const TemplateLabelWrapper = styled.div<{ barColors: GetTopBarColorType }>`
  /* gold-dark */
  background: ${({ barColors }) => barColors.ActiveBackground};
  border: 1px solid ${GetColor.White};
  color: ${GetColor.White};
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 8px;
  padding: 5px;
  height: 22px;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
`;

const LabelDateStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 1024px) {
    margin-left: 25px;
  }
`;
