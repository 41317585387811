import React, { memo } from 'react';

import { Block } from '../../components/core';
import { COMMON_ERROR_WRAPPERS } from '../../../studio-blocks/components/error-wrappers/common';
import { PrivateAssetGrowthSimulationHeader } from './private-asset-growth-simulation-header';
import { PrivateAssetGrowthSimulationContent } from './private-asset-growth-simulation-content';
import { SubjectsErrorWrapper } from '../../../studio-blocks/components/error-wrappers/SubjectsErrorWrapper';
import { PublicPrivateErrorWrapper } from '../../../studio-blocks/components/error-wrappers/PublicPrivateErrorWrapper';
import { MetricsErrorWrapper } from '../../../studio-blocks/components/error-wrappers/MetricsErrorWrapper';

interface PrivateAssetGrowthSimulationBlockProps {
  id: string;
}

export const PrivateAssetGrowthSimulationBlock = memo(function InternalPrivateAssetGrowthSimulationBlock({
  id,
}: PrivateAssetGrowthSimulationBlockProps) {
  return (
    <Block.Root id={id}>
      <Block.Header>
        <PrivateAssetGrowthSimulationHeader id={id} />
      </Block.Header>
      <Block.Content
        id={id}
        errorWrappers={[...COMMON_ERROR_WRAPPERS, MetricsErrorWrapper, SubjectsErrorWrapper, PublicPrivateErrorWrapper]}
      >
        <PrivateAssetGrowthSimulationContent id={id} />
      </Block.Content>
    </Block.Root>
  );
});
