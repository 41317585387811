import React, { type CSSProperties, useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import type { TriggerComponentProps } from '../../types';
import { Label, Hint, SecondaryLabel } from '../../../typography';
import { GetColor, ColorUtils } from '../../../../style';
import { Icon } from '../../../icon/Icon';
import { EllipsisAutoPositionTooltipSpan } from '../../../ellipsis-tooltip/EllipsisAutoPositionTooltipSpan';
import TriggerContainer from '../TriggerContainer';

export interface SimpleTriggerComponentProps extends TriggerComponentProps {
  /**
   * Text displayed when no item is selected. Defaults to "(None)"
   */
  placeholder?: string;
  /**
   * EXPERIMENTAL
   * If true, the dropdown will be searchable (like a typeahead)
   * This is pending amended Specs from UX
   */
  searchable?: boolean;
  /**
   * EXPERIMENTAL
   * This is pending amended Specs from UX
   */
  search?: string;
  /**
   * EXPERIMENTAL
   * This is pending amended Specs from UX
   */
  onSearch?: (text: string) => void;
  style?: CSSProperties;
  leftContent?: JSX.Element;
  disableTooltip?: boolean;
  /**
   * Defaults to the label of the selected item
   * @param selected the label of the selected item
   */
  getTooltipContent?: (selected: string) => string;
  /**
   * By default, tooltip is only shown when the content overflows.
   * If this is true, the tooltip is always shown.
   */
  alwaysShowTooltip?: boolean;
  /**
   * If defined, show a x icon at the right instead of the down arrow
   * This callback is called when it is pressed
   */
  onClear?: () => void;
  /** Hide the arrow icon */
  hideArrowIcon?: boolean;
  /**
   * Flag for showing the trigger as dark when not selected
   */
  darkPlaceholder?: boolean;
  // Tooltip positioning props
  tooltipUsePortal?: boolean;
  tooltipFlex?: boolean;
  width?: number;
  borderFocusColor?: string;
}

type InputProps = Pick<
  SimpleTriggerComponentProps,
  | 'selection'
  | 'selectionIcon'
  | 'expanded'
  | 'disabled'
  | 'placeholder'
  | 'searchable'
  | 'search'
  | 'onSearch'
  | 'onClick'
  | 'leftContent'
  | 'disableTooltip'
  | 'getTooltipContent'
  | 'alwaysShowTooltip'
  | 'onClear'
  | 'hideArrowIcon'
  | 'darkPlaceholder'
  | 'tooltipUsePortal'
  | 'tooltipFlex'
  | 'invalid'
  | 'highlight'
>;

type LabelProps = Pick<SimpleTriggerComponentProps, 'label' | 'rightLabel'>;

type ContainerProps = {
  shouldHighlightBorderOnFocus: boolean;
  borderFocusColor?: string;
  children: React.ReactNode;
} & Pick<
  SimpleTriggerComponentProps,
  | 'disabled'
  | 'searchable'
  | 'expanded'
  | 'onClick'
  | 'className'
  | 'invalid'
  | 'innerRef'
  | 'onKeyDown'
  | 'onKeyEnter'
  | 'onKeyUp'
  | 'onKeyEsc'
  | 'style'
  | 'width'
>;

export const SimpleTriggerComponents = {
  Container: ({
    shouldHighlightBorderOnFocus,
    borderFocusColor,
    disabled,
    searchable,
    expanded,
    onClick,
    className,
    invalid,
    innerRef,
    onKeyDown,
    onKeyEnter,
    onKeyUp,
    onKeyEsc,
    style,
    width,
    children,
  }: ContainerProps) => {
    const onInputClick = useCallback(() => {
      if (!disabled && (!searchable || !expanded)) {
        onClick?.();
      }
    }, [onClick, disabled, searchable, expanded]);
    return (
      <TriggerContainer
        className={className}
        disabled={disabled}
        invalid={invalid}
        expanded={expanded}
        innerRef={innerRef}
        onClick={onInputClick}
        onKeyDown={onKeyDown}
        onKeyEnter={onKeyEnter}
        onKeyUp={onKeyUp}
        onKeyEsc={onKeyEsc}
        style={style}
        width={width}
        shouldHighlightBorderOnFocus={shouldHighlightBorderOnFocus}
        borderFocusColor={borderFocusColor}
      >
        {children}
      </TriggerContainer>
    );
  },
  Label: ({ label, rightLabel }: LabelProps) => {
    return (
      label && (
        <LabelContainer>
          <Label className="qa-dropmenu-input-label">{label}</Label>
          {rightLabel && <SecondaryLabel>{rightLabel}</SecondaryLabel>}
        </LabelContainer>
      )
    );
  },
  Input: ({
    selection,
    selectionIcon,
    expanded,
    disabled,
    placeholder = '(None)',
    searchable,
    search,
    onSearch,
    onClick,
    leftContent,
    disableTooltip,
    getTooltipContent,
    alwaysShowTooltip,
    onClear,
    hideArrowIcon,
    darkPlaceholder,
    tooltipUsePortal,
    tooltipFlex,
    invalid,
    highlight,
  }: InputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const onSearchChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (search !== event.target.value) {
          onSearch?.(event.target.value);
        }
      },
      [onSearch, search],
    );

    useEffect(() => {
      if (searchable && expanded && !disabled && inputRef.current) {
        inputRef.current.focus();
      }
    }, [searchable, disabled, expanded]);

    const onArrowClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (!disabled) {
          onClick?.();
        }
      },
      [onClick, disabled],
    );

    const onClearClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (!disabled) {
          onClear?.();
        }
      },
      [disabled, onClear],
    );

    return (
      <Input
        dark={darkPlaceholder && !expanded}
        disabled={disabled}
        invalid={invalid}
        highlight={highlight}
        className="qa-dropmenu-trigger"
        data-testid="simpletrigger-input"
      >
        {(!expanded || !searchable) && leftContent}
        {searchable && expanded ? (
          <SearchInput value={search} onChange={onSearchChange} ref={inputRef} placeholder={selection} />
        ) : (
          <Selection>
            {selection ? (
              <EllipsisAutoPositionTooltipSpan
                usePortal={tooltipUsePortal}
                flex={tooltipFlex}
                alwaysShowTooltip={alwaysShowTooltip}
                content={getTooltipContent ? getTooltipContent(selection) : selection}
                disableTooltip={disableTooltip}
              >
                <Label className="qa-dropmenu-input-content">
                  {selectionIcon}
                  {selection}
                </Label>
              </EllipsisAutoPositionTooltipSpan>
            ) : (
              <Hint className="qa-dropmenu-input-content">{placeholder}</Hint>
            )}
          </Selection>
        )}

        {onClear ? (
          <ClearIcon type="times" prefix="far" onClick={onClearClick} className="qa-clear" />
        ) : hideArrowIcon ? null : (
          <ArrowIcon type={expanded ? 'angle-up' : 'angle-down'} onClick={onArrowClick} tabIndex={-1} />
        )}
      </Input>
    );
  },
};

interface StateProps {
  disabled?: boolean;
  invalid?: boolean;
  highlight?: boolean;
  dark?: boolean;
}

const Selection = styled.div`
  flex-grow: 1;
  min-width: 0;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;

  > * {
    display: inline-flex;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 12px;
    user-select: none;
  }

  ${Hint} {
    overflow: hidden;
  }

  .header-dropmenu-tooltip {
    margin-left: -20px;
    margin-bottom: -4px;
  }
`;

const Input = styled.div<StateProps>`
  height: 35px;
  border-radius: 4px;
  border: 1px solid ${({ dark, invalid }) => (invalid ? GetColor.Error : dark ? GetColor.DarkGrey : GetColor.Grey)};
  padding-right: 10px;
  display: flex;
  align-items: center;
  color: ${({ disabled, dark, invalid, highlight }) =>
    invalid
      ? GetColor.Error
      : disabled
        ? GetColor.HintGrey
        : highlight
          ? GetColor.HighlightDark
          : dark
            ? GetColor.White
            : GetColor.Black};
  background-color: ${({ disabled, dark }) =>
    disabled ? GetColor.PaleGrey : dark ? GetColor.DarkGrey2 : GetColor.White};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-style: ${(props) => (props.disabled ? 'italic' : 'normal')};

  ${({ dark }) =>
    dark &&
    css`
      :hover {
        border-color: ${GetColor.Primary.Main};
      }

      label {
        font-size: 11px;
        text-transform: uppercase;
      }

      .button-icon > i {
        color: ${GetColor.White};
      }
    `}
  label {
    color: inherit;
  }

  button {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

const SearchInput = styled.input`
  border: none;
  flex-grow: 1;
  min-width: 0;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: ${GetColor.Black};
  font-family: ${(props) => props.theme.Typography.fontFamily};
`;

const ClearIcon = styled(Icon)`
  color: ${GetColor.HintGrey};
  font-size: 16px;
  :hover {
    color: ${ColorUtils.opacifyDarkFrom(GetColor.HintGrey, 0.5)};
  }
`;

const ArrowIcon = styled(Icon)`
  color: ${GetColor.Black};
  font-size: 12px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
`;
