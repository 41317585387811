import React from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import { formatDateByFrequency } from '../../manage-data/utils';

export const AllocationHeaderGroup = ({
  historical,
  dates,
  disabled,
  selectedDate,
  setSelectedDate,
}: {
  historical: boolean;
  dates: number[];
  disabled?: boolean;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
}) => (
  <AllocationHeaderGroupStyled>
    <AllocationHeaderGroupContainer>
      {!historical ? (
        <span className="font-normal">Current Allocations</span>
      ) : (
        <>
          <span className="mr-[4px] font-normal">Allocations as of</span>
          <Select value={selectedDate} onValueChange={setSelectedDate} disabled={!!disabled}>
            <StyledSelectTrigger>
              <StyledSelectValue>{formatDateByFrequency(Number(selectedDate), 'DAILY')}</StyledSelectValue>
            </StyledSelectTrigger>
            <SelectContent className="overflow-hidden max-h[200px]">
              {dates.map((date) => (
                <SelectItem key={String(date)} value={String(date)}>
                  {formatDateByFrequency(date, 'DAILY')}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </>
      )}
    </AllocationHeaderGroupContainer>
  </AllocationHeaderGroupStyled>
);

const AllocationHeaderGroupStyled = styled.div`
  background-color: ${GetColor.GreyScale.Grey10};
  width: 100%;
  height: 100%;
  position: relative;
  border-top: 1px solid ${GetColor.GreyScale.Grey30} !important;
  border-right: 1px solid ${GetColor.GreyScale.Grey30} !important;

  .ag-header-group-cell {
    background-color: ${GetColor.GreyScale.Grey10};
    padding: 0;
  }

  .bulk-management-table-wrapper,
  & > div {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
`;

const AllocationHeaderGroupContainer = styled.div`
  padding: 0px 4px;
  width: 100%;
  height: 100%;
  border: 1px solid var(--ag-cell-horizontal-border) !important;
  border-left: none;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
`;

const StyledSelectTrigger = styled(SelectTrigger).attrs({
  className:
    'hover:text-black focus:ring-0 h-[35px] focus:outline-none focus:border-[hsl(var(--ring))] border-[hsl(var(--border))] border-[1px] border-solid text-[12px] font-bold text-[--foreground] max-h-[20px] w-full rounded-[4px]',
})`
  background-color: ${GetColor.GreyScale.Grey10};
  border-color: ${GetColor.GreyScale.Grey30};
  padding: 4px !important;
  padding-left: 6px !important;
  padding-top: 6px !important;
  max-width: 94px !important;
`;

const StyledSelectValue = styled(SelectValue).attrs({
  className: 'max-h-[20px] text-[12px] font-bold text-[--foreground] hover:text-black w-full',
})``;
