import { useState, useEffect, useCallback, useContext, useMemo, useRef } from 'react';
import { AnalysisViewContext, FactorLensesContext, UserContext } from 'venn-components';
import type { AnalysisSubjectType, TimeFrame, CategoryConfig, AnalysisConfig } from 'venn-utils';
import {
  AnalysisSubject,
  getTemplateById,
  useApi,
  setDefaultAnalysisType,
  Routes,
  emptyAnalysisTemplate,
  logExhaustive,
} from 'venn-utils';
import type { GeneralAnalysisTemplate, AnalysisPeriod, AnalysisViewTypeEnum } from 'venn-api';
import { getSpecificPortfolioV3, getFund, getRecentMasterPortfolioRange, viewEntity } from 'venn-api';
import type { MatchParams } from '../utils';
import { getParam, getBooleanQueryStringParam, shouldShowCategoryPicker } from '../utils';
import { updateNavigation } from './updateNavigation';
import useTrackFailedAnalysis from './useTrackFailedAnalysis';
import type { RangeType } from 'venn-ui-kit';
import type { RouteComponentProps } from 'react-router-dom';
import { isEqual, isNil } from 'lodash';

/**
 * This logic will handle everything related to the configuration of the analysis, and decides when the subject is to be re-fetched.
 * @param templates available analysis templates
 * @param routerProps URL data
 */
const useAnalysisConfig = (routerProps: RouteComponentProps<MatchParams>, templates: GeneralAnalysisTemplate[]) => {
  const { factorLenses } = useContext(FactorLensesContext);
  const userContext = useContext(UserContext);
  const userContextRef = useRef(userContext);
  const subjectIdRef = useRef<string | number | undefined>(undefined);
  const {
    relative,
    start: startParam,
    end: endParam,
    period: periodParam,
    onUpdateAnalysisViewParam,
    onStartNewAnalysis,
    hasUnsavedChanges,
    isCategoryOff,
    savedId,
  } = useContext(AnalysisViewContext);

  const [analysisConfig, setAnalysisConfig] = useState<AnalysisConfig>(
    // uses the  callback form of useState to avoid recomputing getTemplateById every render
    () => ({
      analysisTemplate:
        getTemplateById(templates, getParam(routerProps, 'analysisType', false)) ?? emptyAnalysisTemplate(),
      subject: undefined,
      selectedTimeFrame: { startTime: startParam, endTime: endParam },
      selectedPeriod: periodParam,
      relative,
      category: 'HIDDEN',
      trackingId: -1,
    }),
  );

  const { updateAnalysisStatusForTracking } = useTrackFailedAnalysis(analysisConfig);

  const [loading, setLoading] = useState(true);
  const abortableGetSpecificPortfolio = useApi(getSpecificPortfolioV3);
  const abortableGetFund = useApi(getFund);

  // There are three cases we will call fetchSubjectApi:
  // (1) when the page first load
  // (2) when we use navigation to change analysis portfolio to a new one
  // (3) when the user change benchmark or proxy or other metadata for investment.
  // ReloadingAllocationPanel is for the #2 case, we want to reload the allocation panel.
  // So the allocator panel won't be at the `changed` status.
  const fetchSubjectApi = useCallback(
    async (
      objectId: number | string | undefined,
      objectType: AnalysisSubjectType | undefined,
      redirectToAnalysis: () => void,
      strategyId?: number,
    ): Promise<
      | {
          subject: AnalysisSubject;
        }
      | undefined
    > => {
      if (!objectId || !objectType) {
        redirectToAnalysis();
        return undefined;
      }

      if (objectType === 'private-investment' || objectType === 'private-portfolio') {
        return undefined;
      }

      const savedInfo = savedId
        ? {
            viewId: savedId,
            viewType: 'ANALYSIS' as AnalysisViewTypeEnum,
          }
        : undefined;
      try {
        if (objectType === 'investment') {
          const fundResponse = await abortableGetFund(objectId as string);
          if (!fundResponse) {
            return undefined;
          }
          // Track when users analyse investments
          viewEntity(objectId as string, savedInfo);
          return {
            subject: new AnalysisSubject(fundResponse.content, 'investment', {}),
          };
        }
        if (objectType === 'portfolio') {
          const portfolioResponse = await abortableGetSpecificPortfolio(
            Number(objectId),
            undefined /* version - required for abortcontroller */,
          );
          if (!portfolioResponse) {
            return undefined;
          }

          let subject = new AnalysisSubject(portfolioResponse.content, 'portfolio', { strategyId });
          // Check if selected strategy is actually a fund, and if so, fetch the fund, too
          if (subject.isInvestmentInPortfolio) {
            try {
              const selectedFundResponse = await abortableGetFund(subject.strategy!.fund!.id);
              if (!selectedFundResponse) {
                return undefined;
              }
              subject = new AnalysisSubject(subject.superItem, subject.superType, {
                strategyId,
                strategyFund: selectedFundResponse.content,
              });
              // Track when users analyse investments in portfolio
              viewEntity(subject.strategy!.fund!.id, savedInfo);
            } catch (e) {
              if (e.name !== 'AbortError') {
                // Fetching the fund failed. Don't let this fund be selected. Instead, select the root.
                subject = new AnalysisSubject(subject.superItem, subject.superType);
              }
            }
          }
          // Track root portfolio Id
          viewEntity(subject.superId.toString(), savedInfo);
          return {
            subject,
          };
        }

        logExhaustive(objectType, 'objectType expected to be exhaustively handled');
        redirectToAnalysis();
        return undefined;
      } catch (e) {
        if (e.name !== 'AbortError') {
          redirectToAnalysis();
        }
        return undefined;
      }
    },
    [abortableGetFund, abortableGetSpecificPortfolio, savedId],
  );
  const fetchBenchmark = useCallback(
    async (subject: AnalysisSubject) => {
      const benchmarkId = subject.activeBenchmark?.fundId || subject.activeBenchmark?.portfolioId;
      const benchmarkType = subject.activeBenchmark?.type === 'PORTFOLIO' ? 'portfolio' : 'investment';
      const benchmarkData =
        !isNil(benchmarkId) && !isNil(benchmarkType)
          ? await fetchSubjectApi(benchmarkId, benchmarkType, redirectToAnalysis, undefined)
          : undefined;
      const error = !isNil(benchmarkId) && !isNil(benchmarkType) && !benchmarkData;
      return { benchmarkData, error };
    },
    [fetchSubjectApi],
  );

  const hasActiveBenchmark = analysisConfig.subject && analysisConfig.subject.activeBenchmark !== undefined;

  const canToggleRelative = useMemo(
    () => analysisConfig?.analysisTemplate?.id !== 'drawdown' && hasActiveBenchmark,
    [analysisConfig, hasActiveBenchmark],
  );

  const actualRelative = useMemo(() => canToggleRelative && relative, [relative, canToggleRelative]);

  // Store routerParams in non-observable ref, since we
  // do not want updates to trigger fetches or renders of data
  const routerPropsRef = useRef(routerProps);
  useEffect(() => {
    routerPropsRef.current = routerProps;
  }, [routerProps]);
  const redirectToAnalysis = () => {
    if (routerPropsRef.current) {
      const errorQueryParam = 'invalidSubject';
      routerPropsRef.current.history.replace(`${Routes.HOME_PATH}?${errorQueryParam}=true`);
    }
  };

  const getInitialCategoryConfig = useCallback(
    (subject: AnalysisSubject): CategoryConfig => {
      if (!shouldShowCategoryPicker(subject)) {
        return 'HIDDEN';
      }
      if (subject.categoryGroup === undefined || isCategoryOff) {
        return 'OFF';
      }
      return 'ON';
    },
    [isCategoryOff],
  );
  useEffect(() => {
    if (isCategoryOff !== (analysisConfig.category === 'OFF') && analysisConfig.subject?.type === 'investment') {
      setAnalysisConfig((prevConfig: AnalysisConfig) => ({
        ...prevConfig,
        category: isCategoryOff || !analysisConfig.subject?.categoryGroup ? 'OFF' : 'ON',
        trackingId: Date.now(),
      }));
    }
  }, [isCategoryOff, analysisConfig.category, analysisConfig.subject]);

  const fetchNewObject = useCallback(async () => {
    const analysisType = getParam(routerPropsRef.current, 'analysisType', false);
    const analysisTemplate = getTemplateById(templates, analysisType) ?? emptyAnalysisTemplate();
    const objectId = getParam(routerPropsRef.current, 'objectId', false);
    const objectType = getParam(routerPropsRef.current, 'objectType', false);
    const strategyId = getParam(routerPropsRef.current, 'strategyId', true);
    const isAnalysisTypeAvailable = templates.some((config) => config.id === analysisType);

    if (!isAnalysisTypeAvailable) {
      redirectToAnalysis();
      return;
    }

    // Deeplink support
    if (objectId === 'master') {
      try {
        const { content } = await getRecentMasterPortfolioRange();
        const masterId = content?.masterPortfolio?.id;
        const recentStart = content?.recentStart;
        const recentEnd = content?.recentEnd;
        if (!masterId) {
          redirectToAnalysis();
          return;
        }

        if (routerPropsRef.current) {
          if (getBooleanQueryStringParam(routerPropsRef.current.location, 'recent')) {
            routerPropsRef.current.history.replace(
              `/analysis/results/${analysisType}/portfolio/${masterId}?start=${recentStart}&end=${recentEnd}`,
            );
            return;
          }
          routerPropsRef.current.history.replace(`/analysis/results/${analysisType}/portfolio/${masterId}`);
        }
      } catch {
        redirectToAnalysis();
      }

      return;
    }

    // Object is not updated, so don't show loading state, but do re-fetch the subject
    // This can happen when we're viewing an investment within a portfolio, and update
    // proxy/benchmark, and need to re-fetch the investment only
    if (objectId !== subjectIdRef.current) {
      setLoading(true);

      setAnalysisConfig((prevConfig: AnalysisConfig) => ({
        analysisTemplate,
        subject: undefined,
        selectedTimeFrame: prevConfig.selectedTimeFrame,
        selectedPeriod: prevConfig.selectedPeriod,
        relative: prevConfig.relative,
        category: prevConfig.category,
        trackingId: Date.now(),
      }));
    }

    const data = await fetchSubjectApi(objectId, objectType, redirectToAnalysis, strategyId);
    if (!data) {
      return;
    }
    subjectIdRef.current = objectId;
    const { benchmarkData, error } = await fetchBenchmark(data.subject);
    if (error) {
      // we don't support private portfolios or investments as benchmarks, so if we can't find the benchmark we will fail analysis with an error and redirect
      return;
    }

    const category = getInitialCategoryConfig(data.subject);

    setAnalysisConfig({
      analysisTemplate,
      subject: data.subject,
      benchmark: benchmarkData?.subject,
      selectedTimeFrame: { startTime: startParam, endTime: endParam },
      selectedPeriod: periodParam,
      relative: false,
      category,
      trackingId: Date.now(),
    });

    setLoading(false);
  }, [templates, fetchSubjectApi, fetchBenchmark, getInitialCategoryConfig, startParam, endParam, periodParam]);

  // This should load when factor lenses and templates are loaded and when object ID changes in the URL
  useEffect(() => {
    if (
      factorLenses &&
      factorLenses.length &&
      templates.length > 0 &&
      // Prevent fetch object and trigger redirect behavior  when FS enable recent analysis and there is no objectId presented
      !isEmpty(routerProps.match.params.objectId) &&
      // Only refetch new object when it's a new analysis or subject has changed
      (subjectIdRef.current === undefined || subjectIdRef.current !== routerProps.match.params.objectId)
    ) {
      fetchNewObject();
    }
  }, [factorLenses, fetchNewObject, routerProps.match.params.objectId, templates]);

  useEffect(() => {
    setAnalysisConfig((prevState: AnalysisConfig) => {
      if (prevState?.analysisTemplate?.id === routerProps.match.params.analysisType) {
        return prevState;
      }
      return {
        ...prevState,
        analysisTemplate:
          templates.find((t) => t.id === routerProps.match.params.analysisType) ?? emptyAnalysisTemplate(),
        trackingId: Date.now(),
      };
    });
  }, [routerProps.match.params.analysisType, templates]);

  // Update default template when analysisTemplate or analysis subject type changes
  const noSubject = !analysisConfig.subject;
  useEffect(() => {
    if (noSubject || !analysisConfig.analysisTemplate) {
      return;
    }
    setDefaultAnalysisType(analysisConfig.subject!.type, userContextRef.current, analysisConfig.analysisTemplate);
  }, [analysisConfig.analysisTemplate, noSubject, analysisConfig.subject]);

  const fetchObjectForBenchmarksUpdateOnly = async () => {
    const { subject } = analysisConfig;
    if (!subject) {
      return;
    }
    const data = await fetchSubjectApi(subject.id, subject.type, redirectToAnalysis, subject.strategyId);
    if (!data) {
      return;
    }

    const updatedSubject = subject.getWithUpdatedBenchmarks(data.subject);
    const { benchmarkData, error } = await fetchBenchmark(updatedSubject);
    if (error) {
      // we don't support private portfolios or investments as benchmarks, so if we can't find the benchmark we will fail analysis with an error and redirect
      return;
    }

    setAnalysisConfig((prevConfig: AnalysisConfig) => ({
      ...prevConfig,
      subject: updatedSubject,
      benchmark: benchmarkData?.subject,
      trackingId: Date.now(),
    }));
  };

  // When user toggles relative, update our local reference to their preference and the URL param
  const toggleRelative = useCallback(
    () => onUpdateAnalysisViewParam({ relative: !relative }),
    [onUpdateAnalysisViewParam, relative],
  );

  // then update the analysis config with the actual relative state
  useEffect(() => {
    setAnalysisConfig((prevConfig: AnalysisConfig) => ({
      ...prevConfig,
      relative: !!actualRelative,
      trackingId: prevConfig.relative === actualRelative ? prevConfig.trackingId : Date.now(),
    }));
  }, [actualRelative]);

  // We need this for the useEffect below, so that we can correctly synchronize analysisConfig with the context
  const savedAnalysisPeriodRef = useRef<AnalysisPeriod>({
    periodToDate: periodParam,
    endDate: endParam,
    startDate: startParam,
  });
  useEffect(() => {
    const { periodToDate, endDate, startDate } = savedAnalysisPeriodRef.current;
    if (periodToDate !== periodParam || endDate !== endParam || startDate !== startParam) {
      savedAnalysisPeriodRef.current = periodParamsToObject(startParam, endParam, periodParam);

      // If after saving the start/end change, synchronize them back to the TimeFrame
      // This may happen because BE "normalizes" the timestamps coming from the Analysis Period selector
      if (!hasUnsavedChanges) {
        setAnalysisConfig((prevConfig: AnalysisConfig) => ({
          ...prevConfig,
          selectedTimeFrame: { startTime: startParam, endTime: endParam },
          selectedPeriod: periodParam,
        }));
      }
    }
  }, [periodParam, endParam, startParam, hasUnsavedChanges]);

  const prevConfigRef = useRef<AnalysisConfig>(analysisConfig);

  // synchronize query parameter state to reflect analysis config
  useEffect(() => {
    if (prevConfigRef.current === analysisConfig || !analysisConfig.subject) {
      // Only apply this effect when `analysisConfig` changes and subject is present
      return;
    }

    prevConfigRef.current = analysisConfig;
    if (analysisConfig.selectedPeriod) {
      // If the selected period from the date picker was one of the pre-set options ('1Y', '3Y', 'YTD'...),
      // don't set the start & end timestamps in the URL.
      onUpdateAnalysisViewParam({
        start: undefined,
        end: undefined,
        period: analysisConfig.selectedPeriod,
      });
      return;
    }
    const { startTime: start, endTime: end } = analysisConfig.selectedTimeFrame;
    const periodToDate = savedAnalysisPeriodRef.current.periodToDate as RangeType;
    // Do not override the analysis view params if `timeFrame` values are not set.
    // Using a ref to store them so that changes in the view's params don't trigger this useEffect
    const proposedParams = {
      period: start || end ? undefined : periodToDate,
      start: start ?? (periodToDate ? undefined : savedAnalysisPeriodRef.current.startDate),
      end: end ?? (periodToDate ? undefined : savedAnalysisPeriodRef.current.endDate),
    };
    if (
      !isEqual(
        savedAnalysisPeriodRef.current,
        periodParamsToObject(proposedParams.start, proposedParams.end, proposedParams.period),
      )
    ) {
      onUpdateAnalysisViewParam(proposedParams);
    }
  }, [analysisConfig, onUpdateAnalysisViewParam]);

  const onChangeAnalysisTemplate = useCallback(
    (analysisTemplate: GeneralAnalysisTemplate) => {
      setAnalysisConfig((prevConfig: AnalysisConfig) => {
        onUpdateAnalysisViewParam({ templateId: analysisTemplate.id });
        return {
          ...prevConfig,
          analysisTemplate,
          trackingId: Date.now(),
        };
      });
    },
    [onUpdateAnalysisViewParam],
  );

  const onChangeSubject = useCallback(
    (newSubject: AnalysisSubject) => {
      // When using the subject selector, if we select the subject already selected, don't make changes.
      if (analysisConfig.subject?.superId === newSubject.superId) {
        return;
      }
      const newConfig: AnalysisConfig = {
        ...analysisConfig,
        category: getInitialCategoryConfig(newSubject),
        subject: newSubject,
        trackingId: Date.now(),
      };
      updateNavigation(newConfig, routerProps.history);
      onStartNewAnalysis();
    },
    [analysisConfig, routerProps.history, onStartNewAnalysis, getInitialCategoryConfig],
  );

  const onUpdateSubject = useCallback(
    (newSubject: AnalysisSubject, preventRefresh?: boolean) => {
      const category = getInitialCategoryConfig(newSubject);
      setAnalysisConfig((prevConfig: AnalysisConfig) => {
        if (prevConfig.subject?.superId === newSubject.superId && prevConfig.subject?.id !== newSubject.id) {
          // Prompt the analysis view context to store the pathname with updated strategy id in local storage.
          onUpdateAnalysisViewParam({});
        }
        return {
          ...prevConfig,
          category,
          subject: newSubject,
          trackingId: preventRefresh ? prevConfig.trackingId : Date.now(), // Update trackingId to re-run analysis if not prevented
        };
      });
    },
    [getInitialCategoryConfig, onUpdateAnalysisViewParam],
  );

  const setTimeFrame = useCallback((selectedTimeFrame: TimeFrame, selectedPeriod?: RangeType) => {
    setAnalysisConfig((prevConfig: AnalysisConfig) => ({
      ...prevConfig,
      selectedTimeFrame,
      selectedPeriod,
      trackingId: Date.now(),
    }));
  }, []);

  const setCategoryConfig = useCallback(
    (categoryConfig: CategoryConfig) => {
      onUpdateAnalysisViewParam({ isCategoryOff: categoryConfig === 'OFF' });
    },
    [onUpdateAnalysisViewParam],
  );

  const subjectName = useMemo(() => analysisConfig.subject?.superItem.name, [analysisConfig.subject]);
  useEffect(() => {
    onUpdateAnalysisViewParam({ subjectName });
  }, [subjectName, onUpdateAnalysisViewParam]);

  return {
    analysisConfig,
    loadingSubject: loading,
    fetchObjectForBenchmarksUpdateOnly,
    /**
     * Call when the analysis template has changed
     */
    onChangeAnalysisTemplate,
    /**
     * Call when the subject identity has changed, i.e. it's now a different portfolio or fund
     * This will trigger a re-fetch of the subject itself
     */
    onChangeSubject,
    /**
     * Call when the subject has been modified (i.e. new allocation, rename, etc.)
     * This will NOT trigger a re-fetch of the subject itself
     */
    onUpdateSubject,
    setTimeFrame,
    setCategoryConfig,

    relative: actualRelative,
    canToggleRelative,
    toggleRelative,

    // Call when the data is fetched and we know if the given analyses succeeded or not
    updateAnalysisStatusForTracking,
  };
};

export default useAnalysisConfig;

const isEmpty = (params: string | undefined) => params === undefined || params === 'undefined';

const periodParamsToObject = (start?: number, end?: number, period?: RangeType): AnalysisPeriod => ({
  startDate: start,
  endDate: end,
  periodToDate: period,
});
