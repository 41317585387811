import React from 'react';
import { MultiHistoricalPortfolioContext } from './MultiHistoricalPortfolioContext';
import { assertExhaustive } from 'venn-utils';
import { useMultiHistoricalReviewRightPane } from './logic/useMultiHistoricalReviewRightPane';

type RootProps = {
  children: React.ReactNode;
};

export const Root = ({ children }: RootProps) => {
  const result = useMultiHistoricalReviewRightPane();
  switch (result.kind) {
    case 'success': {
      return (
        <MultiHistoricalPortfolioContext.Provider value={result.context}>
          {children}
        </MultiHistoricalPortfolioContext.Provider>
      );
    }
    case 'loading': {
      return null;
    }
    default:
      throw assertExhaustive(result);
  }
};
