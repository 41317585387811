import type { FrequencyEnum, VenncastReturn } from 'venn-api';
import type { Options, SeriesOptionsType } from 'highcharts/highstock';
import moment from 'moment';
import type { AnalysisGroup, AnalysisSubject, AnalysisSubjectType, AnyDuringEslintMigration } from 'venn-utils';
import { Dates, getSecondaryDisplayLabel } from 'venn-utils';
import last from 'lodash/last';
import { getDefaultChartConfig, getLineSerie, addVenncast } from '../chart-config-logic';
import type { ContentChartLegendItem } from '../../../content-chart-legend/index';
import { compact, round } from 'lodash';
import type { Theme } from 'venn-ui-kit';
import { getTextThemeProvider } from 'venn-ui-kit';

export const getLineColors = (theme: Theme) => {
  const lineChartColors = theme.Schemes.LineChartColors;
  return [lineChartColors.mainColor, lineChartColors.secondaryColor, lineChartColors.comparisonColor];
};

const getLegendLabels = (
  relative: boolean,
  mode: AnalysisSubjectType,
  hasSecondary: boolean,
  hasBenchmark: boolean,
  subject: AnalysisSubject,
) => {
  const benchmark = relative || !hasBenchmark ? undefined : 'Benchmark';
  if (mode === 'investment') {
    return compact(['Investment', benchmark, hasSecondary ? 'Category' : undefined]);
  }
  const comparisonLabel = !hasSecondary
    ? undefined
    : subject.secondaryLabel
      ? getSecondaryDisplayLabel(subject, undefined, 'Portfolio')
      : 'Master Portfolio';
  return compact(['Current Portfolio', comparisonLabel, benchmark]);
};

export const getVenncastLegendItems = (
  subject: AnalysisSubject,
  relative: boolean,
  theme: Theme,
  venncast?: AnalysisGroup<VenncastReturn>,
): ContentChartLegendItem[] => {
  if (!venncast || !venncast.subject || !subject) {
    return [];
  }

  const lineColors = getLineColors(theme);
  const labels = getLegendLabels(relative, subject.type, !!venncast.category, !!venncast.benchmark, subject);
  const legendItems: ContentChartLegendItem[] = labels.map((label, index) => ({
    name: label,
    color: lineColors[index],
  }));
  if (venncast.subject.estimated) {
    legendItems.push({
      name: 'Estimated',
      color: lineColors[0],
      estimated: true,
    });
  }
  return legendItems;
};

const getSeriesDataFromVenncast = (
  mainSerie: number[][] | undefined,
  secondarySerie: number[][] | undefined,
  benchmarkSerie: number[][] | undefined,
  lastReturn: number[] | undefined,
  subject: AnalysisSubject,
  lineColors: string[],
  venncast?: AnalysisGroup<VenncastReturn>,
): SeriesOptionsType[] => {
  if (!subject) {
    return [];
  }
  const isPortfolio = subject.type === 'portfolio';
  const secondaryLabel = !secondarySerie
    ? ''
    : isPortfolio
      ? getSecondaryDisplayLabel(
          subject,
          `${subject.secondaryPortfolio?.name} as of`,
          `Portfolio: ${subject.secondaryPortfolio?.name}`,
        )
      : compact([subject.categoryGroup ? 'Category' : undefined, subject.categoryGroup?.name]).join(': ');
  const secondarySerieLine = isPortfolio
    ? (getLineSerie(secondarySerie, secondaryLabel, lineColors[1]) as SeriesOptionsType)
    : undefined;
  const categorySerieLine = !isPortfolio
    ? (getLineSerie(secondarySerie, secondaryLabel, lineColors[benchmarkSerie ? 2 : 1]) as SeriesOptionsType)
    : undefined;

  const estimatedBoundSerie = venncast
    ? (getLineSerie(
        // TODO: (VENN-20577 / TYPES) Are the autogenerated types wrong?
        venncast?.subject?.estimateBounds as AnyDuringEslintMigration,
        'Error range',
        lineColors[0],
        'arearange',
        lastReturn,
      ) as SeriesOptionsType)
    : undefined;
  const estimatedReturnSerie = venncast
    ? (getLineSerie(
        venncast?.subject?.estimatedCumulativeReturns ?? [],
        'Estimated',
        lineColors[0],
        'dashline',
        lastReturn,
      ) as SeriesOptionsType)
    : undefined;

  const venncastSerieLines = compact([estimatedReturnSerie, estimatedBoundSerie]);

  return compact([
    getLineSerie(mainSerie, subject.name, lineColors[0]) as SeriesOptionsType,
    secondarySerieLine,
    getLineSerie(
      benchmarkSerie,
      compact(['Benchmark', subject.activeBenchmarkName]).join(': '),
      lineColors[secondarySerie && isPortfolio ? 2 : 1],
    ) as SeriesOptionsType,
    categorySerieLine,
    ...venncastSerieLines,
  ]);
};

const getBasicConfig = (
  series: SeriesOptionsType[],
  frequency: FrequencyEnum = 'MONTHLY',
  print: boolean,
  start: number,
  end: number,
  theme: Theme,
): Options => {
  const config = getDefaultChartConfig(
    'percent',
    frequency,
    print,
    theme.Typography,
    theme.Colors,
    theme.Schemes,
    print ? 7.5 : 10.5,
  );
  const range = moment.duration(end - start).asYears();
  const dateFormatValue = range <= 1 ? Dates.CHART_DATE_FORMATS.MONTH_DAY : Dates.CHART_DATE_FORMATS.YEAR_MONTH;
  const chartConfig = {
    ...config,
    chart: {
      ...config.chart,
      height: print ? 200 : undefined,
    },
    tooltip: {
      ...config.tooltip,
      style: {
        ...config.tooltip.style,
        fontSize: undefined,
      },
    },
    series,
    xAxis: {
      ...config.xAxis,
      id: 'xaxis',
      labels: {
        ...config.xAxis.labels,
        format: `{value:${dateFormatValue}}`,
      },
    },
    plotOptions: {
      ...config.plotOptions,
      area: { fillOpacity: 0.2 },
    },
  };
  return chartConfig;
};

export const getCumulativeReturnConfig = (
  returns: AnalysisGroup<number[][]>,
  subject: AnalysisSubject | undefined,
  frequency: FrequencyEnum = 'MONTHLY',
  print: boolean,
  theme: Theme,
): Options | undefined => {
  if (!subject) {
    return undefined;
  }
  const { Colors } = theme;
  const lineColors = getLineColors(theme);
  const start = returns?.subject?.[0]?.[0] || 0;
  const end = last(returns?.subject)?.[0] || 0;
  const mainSerie = returns?.subject;
  const secondarySerie = returns?.category;
  const benchmarkSerie = returns?.benchmark;
  const lastReturn = last(returns?.subject);
  const series = getSeriesDataFromVenncast(mainSerie, secondarySerie, benchmarkSerie, lastReturn, subject, lineColors);
  const chartConfig = getBasicConfig(series, frequency, print, start, end, theme);
  // Add bottom bar to keep consistent
  return addVenncast(
    chartConfig,
    {
      start,
      end,
      visibleVenncastStart: end,
      persistentBottomBar: true,
    },
    Colors,
    print,
  );
};

export const getVenncastConfig = (
  subject: AnalysisSubject | undefined,
  frequency: FrequencyEnum = 'MONTHLY',
  print: boolean,
  theme: Theme,
  venncast?: AnalysisGroup<VenncastReturn>,
): Options | undefined => {
  if (!subject || subject.portfolio?.historical) {
    return undefined;
  }
  const estimated = venncast?.subject?.estimated;
  if (!estimated) {
    return undefined;
  }
  const start = venncast?.subject?.cumulativeReturn?.[0]?.[0] || 0;
  const end = last(venncast?.subject?.cumulativeReturn)?.[0] || 0;
  const visibleVenncastEnd = last(venncast?.subject?.estimatedCumulativeReturns)?.[0];
  const mainSerie = venncast?.subject?.cumulativeReturn;
  const secondarySerie = venncast?.category?.cumulativeReturn;
  const benchmarkSerie = venncast?.benchmark?.cumulativeReturn;
  const lastReturn = last(venncast?.subject?.cumulativeReturn);
  const lineColors = getLineColors(theme);
  const series = getSeriesDataFromVenncast(
    mainSerie,
    secondarySerie,
    benchmarkSerie,
    lastReturn,
    subject,
    lineColors,
    venncast,
  );
  const chartConfig = getBasicConfig(series, frequency, print, start, end, theme);

  return addVenncast(
    chartConfig,
    { start, end, visibleVenncastStart: end, visibleVenncastEnd, estimated },
    theme.Colors,
    print,
  );
};

export const getVenncastDisableMessage = (
  returnFrequency: FrequencyEnum,
  isFund: boolean,
  isInterpolated: boolean,
  venncast?: AnalysisGroup<VenncastReturn>,
  isHistoricalPortfolio?: boolean,
) => {
  const venncastName = getTextThemeProvider().VenncastName;

  if (isHistoricalPortfolio) {
    return `${venncastName} is not available for historical portfolios`;
  }

  const defaultMessage =
    !venncast?.subject?.estimated && returnFrequency === 'QUARTERLY'
      ? isFund
        ? `Interpolate this investment to enable ${venncastName}`
        : `Interpolate your quarterly investments to enable ${venncastName}`
      : returnFrequency === 'YEARLY'
        ? `${venncastName} does not support yearly investments`
        : isInterpolated
          ? `${venncastName} can estimate returns for interpolated investments up to 9 months out`
          : `${venncastName} can estimate returns up to 6 months out`;
  if (!venncast || !venncast.subject) {
    return defaultMessage;
  }
  switch (venncast.subject.status) {
    case 'INSUFFICIENT_RETURNS':
      return `${venncastName} requires at least ${
        returnFrequency === 'DAILY' ? '6 months' : '1 year'
      } of return history`;
    case 'UP_TO_DATE':
      return 'Returns for your selection are already up-to-date';
    case 'INVALID_END_DATE':
      return `A comparator is constraining your analysis. ${venncastName} is only available when your last return date is visible`;
    case 'RELATIVE':
      return `${venncastName} is not available when "Relative to Benchmark" is selected`;
    default:
      return defaultMessage;
  }
};

export const formatSeriesData = (data: number[][]) => data.map((item) => [item[0], round(item[1], 12)]);
