import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import React from 'react';
import styled from 'styled-components';

const fillParentTooltipVariants = cva('h-full w-full', {
  variants: {
    variant: {
      default: 'block',
      overlap: 'absolute t-0 l-0',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

type FillParentTooltipProps = {
  children?: React.ReactNode;
  maxWidth: number;
  content: React.ReactNode;
} & VariantProps<typeof fillParentTooltipVariants>;
export const FillParentTooltip = ({ children, maxWidth, content, variant }: FillParentTooltipProps) => {
  return (
    <Tooltip>
      <TooltipTrigger className={cn(fillParentTooltipVariants({ variant }))}>
        <div className="absolute h-full w-full">{children}</div>
      </TooltipTrigger>

      <TooltipPortal>
        <TooltipContent>
          <div style={{ maxWidth }}>{content}</div>
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
};

/** Simple cell with tooltip indicating content is empty due to no returns */
function getNoReturnsTooltippedCell(cellContent: React.ReactNode) {
  return (
    <FillParentTooltip maxWidth={240} content={<div>This item does not have a return stream.</div>}>
      {cellContent}
    </FillParentTooltip>
  );
}

/* Internal cell padding to trigger tooltips to show on no text */
const RangeBarTooltipTrigger = styled.div`
  height: 16px;
  width: 100%;
`;

export const emptyRangeTooltipCell = getNoReturnsTooltippedCell(<RangeBarTooltipTrigger />);
export const notApplicableTooltipCell = getNoReturnsTooltippedCell('N/A');
