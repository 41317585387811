import React, { Fragment } from 'react';
import styled from 'styled-components';
import { CELL_SPACING, HeaderCell } from './shared';
import type { SearchMenuColumn } from 'venn-components';
import { ColumnRenderers } from './Columns';

const TableWrapper = styled.div`
  position: relative;
`;

const Table = styled.table`
  width: 100%;
  table-layout: auto;
  white-space: nowrap;
  text-align: left;
  font-size: 12px;
  tbody {
    tr {
      td {
        padding-right: ${CELL_SPACING}px;
      }
    }
  }
  thead {
    tr {
      th {
        padding-right: ${CELL_SPACING}px;
      }
    }
  }
`;
export const MenuListComponents = {
  TableWrapper,
  Table,
  // @ts-expect-error: fixme
  HeaderRow: ({ children }) => (
    <thead className="qa-menu-header">
      <tr>
        <th className="empty-cell" />
        {children}
      </tr>
    </thead>
  ),
  // @ts-expect-error: fixme
  FirstHeaderCell: ({ children }) => (
    <FirstHeaderCell>
      <Header>{children}</Header>
    </FirstHeaderCell>
  ),
  HeaderColumns: ({ columns }: { columns: SearchMenuColumn[] }) => {
    return columns.map((col) => <Fragment key={col}>{ColumnRenderers[col].headerRenderer()}</Fragment>);
  },
};

const FirstHeaderCell = styled(HeaderCell)`
  position: relative;
`;
const Header = styled.div`
  position: absolute;
  top: 0;
`;
