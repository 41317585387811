import React, { useCallback } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Body1, GetColor, Icon, Tooltip } from 'venn-ui-kit';
import { toClassName, useModuleLazily } from 'venn-utils';
import type { DropResult } from '@hello-pangea/dnd';
import type ReactDnd from '@hello-pangea/dnd';
import { isEmpty } from 'lodash';
import type { MetricSpecificError } from 'venn-state';

export interface ReorderableItem {
  key: string;
  /** Label is used if itemRenderer is not defined. */
  label?: string;
  tooltipContent?: string;
  errorMessage?: MetricSpecificError;
}

export interface ReorderableListProps<ITEM = ReorderableItem> {
  reorderableItems: ITEM[];
  onReorderItems: (newItems: ITEM[]) => void;
  /** Custom item component which will be used instead of label. */
  itemRenderer?: (item: ITEM, index: number) => JSX.Element;
  hideDelete?: boolean;
  disabled?: boolean;
  id?: string;
  /** The size of the margin around the list  */
  margin?: number;
  counterMargin?: number;
  /** If not provided, deletion will call onReorderItems with the deleted item removed. */
  onDeleteItem?: (deletedKey: string) => void;
}

export interface ReorderableListInnerProps<ITEM = ReorderableItem> extends ReorderableListProps<ITEM> {
  reactDnd: typeof ReactDnd;
}

export const REORDERABLE_LIST_HOVER_CLASS = 'reorderable-hover';

/**
 * Inner implementation of a reorderable list, not wrapped by a drag drop context
 * Not to be used outside of ReorderableList or ReorderableListGroup
 */
export const ReorderableListInner = <ITEM extends ReorderableItem>({
  reorderableItems,
  onReorderItems,
  itemRenderer,
  hideDelete,
  disabled,
  id,
  reactDnd,
  margin = 10,
  counterMargin = 30,
  onDeleteItem = (key: string) => {
    onReorderItems(reorderableItems.filter((b) => b.key !== key));
  },
}: ReorderableListInnerProps<ITEM>) => {
  if (reactDnd === null) {
    return null;
  }

  const { Draggable, Droppable } = reactDnd;
  return (
    <Droppable droppableId={id ?? 'droppable'}>
      {(provided) => (
        <AnalysisBlocks
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="qa-chosen-templates"
          counterMargin={counterMargin}
        >
          {reorderableItems.map((item, index) => (
            <Draggable
              key={item.key}
              draggableId={`${id}|${item.key}|${index}`}
              index={index}
              isDragDisabled={disabled}
            >
              {(draggableProvided, snapshot) => (
                <StyledDraggable
                  dragging={snapshot.isDragging}
                  disabled={disabled}
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                  className={item.key ? toClassName(item.key) : 'qa-undefined'}
                >
                  <MarginWrapper margin={margin}>
                    {!disabled && <ArrowIcon className={REORDERABLE_LIST_HOVER_CLASS} type="arrows-up-down" />}
                  </MarginWrapper>
                  {itemRenderer ? itemRenderer(item, index) : <ReorderableListDefaultItemRenderer item={item} />}
                  {!hideDelete && !disabled ? (
                    <DeleteButton onClick={() => onDeleteItem(item.key)}>
                      <Icon type="trash" />
                    </DeleteButton>
                  ) : null}
                  <MarginWrapper margin={margin} />
                </StyledDraggable>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </AnalysisBlocks>
      )}
    </Droppable>
  );
};

export const ReorderableListDefaultItemRenderer = <T extends ReorderableItem>({ item }: { item: T }) => {
  const { Colors } = useTheme();
  return (
    <span style={{ flex: 1 }}>
      <AnalysisBlockContent key={item.key} error={!isEmpty(item.errorMessage) && item.errorMessage?.type !== 'warning'}>
        <Body1>
          <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            {!isEmpty(item.errorMessage) && (item.errorMessage?.type === 'warning' ? <WarningIcon /> : <ErrorIcon />)}
            {item.label}
            {item.tooltipContent && (
              <Tooltip content={item.tooltipContent} usePortal>
                <Icon type="info-circle" prefix="far" style={{ color: Colors.GreyScale.Grey60 }} />
              </Tooltip>
            )}
          </div>
        </Body1>
      </AnalysisBlockContent>
    </span>
  );
};

/**
 * A single reorderable list, where items can be reordered within the list
 */
export const ReorderableList = <ITEM extends ReorderableItem>(props: ReorderableListProps<ITEM>) => {
  const reactDnd: typeof ReactDnd | null = useModuleLazily(useCallback(() => import('@hello-pangea/dnd'), []));

  if (reactDnd === null) {
    return null;
  }

  const { reorderableItems, onReorderItems } = props;

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newBlocksOrder = reorder(reorderableItems, result.source.index, result.destination.index);

    onReorderItems(newBlocksOrder);
  };

  const { DragDropContext } = reactDnd;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ReorderableListInner {...props} reactDnd={reactDnd} />
    </DragDropContext>
  );
};

const MarginWrapper = styled.div<{ margin: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ margin }) => margin}px;
`;

const ArrowIcon = styled(Icon)`
  font-size: 12px;
  color: ${GetColor.Primary.Dark};
`;

const AnalysisBlocks = styled.ul<{ counterMargin: number }>`
  list-style: none;
  padding: 0;
  margin: 0 -${({ counterMargin }) => counterMargin}px;
`;

const AnalysisBlockContent = styled.li<{ error: boolean }>`
  border-left: solid 3px ${GetColor.Grey};
  padding-left: 15px;
  flex: 1;

  ${({ error }) =>
    error &&
    css`
      color: ${GetColor.Error};
      > div {
        color: ${GetColor.Error};
      }
    `}
`;

const DeleteButton = styled.button`
  i {
    font-size: 12px;
    color: ${GetColor.Grey};
    :hover {
      color: ${GetColor.Primary.Dark};
      cursor: pointer;
    }
  }
`;

const StyledDraggable = styled.div<{ dragging: boolean; disabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    border-radius: 2px;
    background: ${(props) => (props.disabled ? 'transparent' : GetColor.GreyScale.Grey10)};
    outline: 1px solid ${GetColor.GreyScale.Grey30};
    cursor: ${(props) => (props.disabled ? 'inherit' : 'grab')};
  }
  &:not(:hover) {
    .${REORDERABLE_LIST_HOVER_CLASS} {
      visibility: hidden;
    }
  }

  ${({ dragging }) =>
    dragging &&
    `
    background-color: ${GetColor.Primary.Main};
    display: inline-block;
    border-radius: 4px;
    ${AnalysisBlockContent} {
      padding-right: 17.5px;
      border-left: none;
      margin: 0;
      ${Body1} {
        color: ${GetColor.White};
      }
    }
  `}
`;

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const ErrorIcon = styled(Icon).attrs({ prefix: 'far', type: 'exclamation-circle' })`
  color: ${GetColor.Error};
`;
const WarningIcon = styled(Icon).attrs({ type: 'diamond-exclamation' })`
  color: ${GetColor.Orange};
`;
