'use client';

import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from '@/lib/utils';

/**
 * Custom modification of the shadcn/switch component:
 * - to be smaller
 * - use Venn's custom colors
 * - display ON/OFF labels
 */
const SmallSwitch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'relative peer inline-flex h-6 w-14 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-venn-dark-blue data-[state=unchecked]:bg-venn-switch-light border-venn-dark-blue border-solid data-[state=checked]:border-none',
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'pointer-events-none block h-6 w-6 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-8 data-[state=unchecked]:-translate-x-2 border-venn-dark-blue border-2 border-solid z-20',
      )}
    />
    <span
      className={`absolute left-2 font-bold text-white text-[10px] z-10 top-1/2 -translate-y-1/2 transition-opacity duration-300 ${props.checked ? 'opacity-100' : 'opacity-0'}`}
    >
      ON
    </span>
    <span
      className={`absolute right-2 font-bold text-venn-dark-blue text-[10px] z-10 top-1/2 -translate-y-1/2 transition-opacity duration-300 ${!props.checked ? 'opacity-100' : 'opacity-0'}`}
    >
      OFF
    </span>
  </SwitchPrimitives.Root>
));
SmallSwitch.displayName = SwitchPrimitives.Root.displayName;

export { SmallSwitch };
