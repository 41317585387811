import { useContext, useEffect, useMemo } from 'react';
import { MultiHistoricalPortfolioContext } from '../MultiHistoricalPortfolioContext';
import * as React from 'react';
import { countErrors } from '../multiHistoricalPortfolioUtils';
import moment from 'moment';

export const useMultiHistoricalReviewDateDropMenu = () => {
  const { allDates, selectDate, selectedDate, errors } = useContext(MultiHistoricalPortfolioContext);
  const [isFilteredToErroredDates, setIsFilteredToErroredDates] = React.useState(false);
  const numberOfErrors = countErrors(errors);
  const hasErrors = numberOfErrors > 0;
  const selectedDateHasErrors = errors[selectedDate.valueOf()]?.length > 0;
  const filteredDates = useMemo(
    () => (hasErrors && isFilteredToErroredDates ? allDates.filter((date) => errors[date.valueOf()]) : allDates),
    [hasErrors, allDates, errors, isFilteredToErroredDates],
  );

  useEffect(() => {
    if (!hasErrors) {
      setIsFilteredToErroredDates(false);
    }
  }, [selectedDateHasErrors, hasErrors]);

  const dropdownItems = useMemo(
    () => filteredDates.map((date) => ({ value: date, label: moment.utc(date).format('DD MMM YYYY') })),
    [filteredDates],
  );
  const selectedItem = { value: selectedDate, label: moment.utc(selectedDate).format('DD MMM YYYY') };

  return {
    dropdownItems,
    isFilteredToErroredDates,
    selectDate,
    selectedItem,
    setIsFilteredToErroredDates,
    hasErrors,
    errors,
  };
};
