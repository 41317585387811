import { compact, isEmpty } from 'lodash';

import type {
  EntityFilterEnum,
  Fund,
  InvestmentSourceTypeEnum,
  ItemId,
  LibrarySearchEntity,
  LibrarySearchResult,
  Portfolio,
  PrivateAssetSearchModeEnum,
  PrivateFund,
  PrivatePortfolioNode,
} from 'venn-api';
import { getLibrarySearchEntityAnalysisSubjectType } from 'venn-state';
import { AnalysisSubject, getFrequencyType, LibraryItemType } from 'venn-utils';
import type { Placeholders, SearchMenuItem, SearchMenuItemCategory } from './types';

/**
 * Default implementation to map API response data from {@link LibrarySearchResult} to {@link SearchMenuItem[]}.
 *
 * @param results: Input data
 * @param recentlyViewed: If set the data category must be set to 'recent'.
 */
export const mapSearchResultToAnalysisSubject = ({
  result,
  type,
  isTagged,
  taggedWith,
}: {
  result: LibrarySearchResult;
  type?: SearchMenuItemCategory;
  isTagged?: boolean;
  taggedWith?: string;
}): SearchMenuItem[] => {
  return result.results.map((entity) => {
    const isTag = !isEmpty(entity.tagIds);
    const item = entity.privateFundId
      ? { ...entity, id: entity.privateFundId }
      : entity.privatePortfolioId
        ? { ...entity, id: entity.privatePortfolioId }
        : entity.fundId
          ? ({ ...entity, frequency: getFrequencyType(entity.frequency), id: entity.fundId } as Partial<Fund>)
          : ({
              ...entity,
              periodStart: entity.startRange,
              periodEnd: entity.endRange,
              children: [],
              id: entity.portfolioId,
              historical: entity.investmentSubtype === 'PORTFOLIO_HISTORICAL',
            } as Partial<Portfolio>);
    if (isTag) {
      return {
        category: 'tag',
        searchResult: entity,
        isTagged,
        taggedWith,
        value: undefined,
        label: entity.name,
      };
    }
    const subject = new AnalysisSubject(
      item as Fund | Portfolio | PrivateFund | PrivatePortfolioNode,
      getLibrarySearchEntityAnalysisSubjectType(entity),
    );
    return subjectToSearchMenuItem({ subject, entity, type, isTagged, taggedWith });
  });
};

export const fundToSearchMenuItem = (fund: Fund): SearchMenuItem => {
  return {
    value: new AnalysisSubject(fund, 'investment'),
    label: fund.name,
    category: 'investment',
  };
};

export const portfolioToSearchMenuItem = (portfolio: Portfolio | PrivatePortfolioNode): SearchMenuItem => {
  return {
    value: new AnalysisSubject(portfolio, 'portfolio'),
    label: portfolio.name,
    category: 'portfolio',
  };
};

export const subjectToSearchMenuItem = ({
  subject,
  entity,
  type,
  isTagged,
  taggedWith,
}: {
  subject: AnalysisSubject;
  entity?: LibrarySearchEntity;
  type?: SearchMenuItemCategory;
  isTagged?: boolean;
  taggedWith?: string;
}): SearchMenuItem => {
  return {
    value: subject,
    label: subject.name,
    category: type ?? subject.type,
    searchResult: entity,
    isTagged,
    taggedWith,
  };
};

export const getInvestmentSources = (itemTypes: LibraryItemType[]): InvestmentSourceTypeEnum[] | undefined => {
  const isBenchmarkSelected = itemTypes.includes(LibraryItemType.COMPOSITE_BENCHMARK);
  const isInvestmentSelected = itemTypes.includes(LibraryItemType.INVESTMENT);
  // If benchmark is selected but not investments, only allow custom investments
  if (!isInvestmentSelected && isBenchmarkSelected) {
    return ['CUSTOM'];
  }
  // If there are items selected, but not the benchmark, do not allow custom investments
  if (itemTypes.length && !isBenchmarkSelected) {
    return ['VENN', 'UPLOAD', 'CUSTODIAN', 'EXTERNAL_INTEGRATION'];
  }
  // Else, allow all sources
  return undefined;
};

export const getEntityFilters = (
  itemTypes: LibraryItemType[],
  investmentsOnly?: boolean,
  portfoliosOnly?: boolean,
): EntityFilterEnum[] => {
  if (!itemTypes.length) {
    return portfoliosOnly ? ['PORTFOLIO'] : investmentsOnly ? ['FUND'] : ['FUND', 'PORTFOLIO'];
  }

  const hasFunds =
    itemTypes.includes(LibraryItemType.COMPOSITE_BENCHMARK) || itemTypes.includes(LibraryItemType.INVESTMENT);
  const hasPortfolios = itemTypes.includes(LibraryItemType.PORTFOLIO);

  return compact([hasFunds ? 'FUND' : undefined, hasPortfolios ? 'PORTFOLIO' : undefined]);
};

export const getPrivateAssetSearchMode = (
  selectedPrivateAssetSearchModesFromFilters: PrivateAssetSearchModeEnum[],
  privateAssetSearchModeDefault: PrivateAssetSearchModeEnum,
) => {
  if (isEmpty(selectedPrivateAssetSearchModesFromFilters)) {
    return privateAssetSearchModeDefault;
  }

  const includePrivate = selectedPrivateAssetSearchModesFromFilters.includes('PRIVATES_ONLY');
  const includePublic = selectedPrivateAssetSearchModesFromFilters.includes('PUBLIC_ONLY');
  const includeAll = selectedPrivateAssetSearchModesFromFilters.includes('ALL');

  if ((includePrivate && includePublic) || includeAll) {
    return 'ALL';
  }

  return selectedPrivateAssetSearchModesFromFilters[0];
};

export const mapItemIds = (ids?: ItemId[]) => ({
  fundIds: ids?.filter((item) => item.type === 'FUND' || item.type === 'venn_fund').map((item) => item.id),
  portfolioIds: ids?.filter((item) => item.type === 'PORTFOLIO').map((item) => Number(item.id)),
});

export const sortDefaultResults = (results: LibrarySearchResult, placeholderIds?: ItemId[]) => {
  if (!placeholderIds) {
    return;
  }

  const ids = placeholderIds.map((id) => id.id);
  results.results.sort(
    (a, b) => ids.indexOf(a.fundId ?? a.portfolioId?.toString()) - ids.indexOf(b.fundId ?? b.portfolioId?.toString()),
  );
};

export const itemEqualityCheck = (item: SearchMenuItem, compared: SearchMenuItem) =>
  item.label === compared.label &&
  item.category === compared.category &&
  item.value?.fund?.id === compared.value?.fund?.id &&
  item.value?.portfolio?.id === compared.value?.portfolio?.id &&
  item.value?.privateFund?.id === compared.value?.privateFund?.id &&
  item.value?.privatePortfolio?.id === compared.value?.privatePortfolio?.id;

export const tagsInSearchCheck = (
  includeTagsSearch: boolean,
  defaultSearch: boolean,
  itemTypes: LibraryItemType[],
  placeholders?: Placeholders,
  privateAssetSearchMode?: PrivateAssetSearchModeEnum,
) => {
  // Include tags when there is no filter or filters contain tags
  const hasTagsFilter =
    itemTypes.length === 0 || itemTypes.includes(LibraryItemType.TAGS) || itemTypes.includes(LibraryItemType.ALL);
  // Not include tags when it's default search with placeholder items
  const noSavedItem = !(defaultSearch && placeholders && placeholders.ids.length > 0);
  // Don't include tags when doing privates only search
  const isPrivatesOnlySearch = privateAssetSearchMode === 'PRIVATES_ONLY';
  return includeTagsSearch && noSavedItem && hasTagsFilter && !isPrivatesOnlySearch;
};

export const getOptionDisabledFunc =
  (isOptionDisabled?: (option: SearchMenuItem) => boolean, investmentsOnly?: boolean, portfoliosOnly?: boolean) =>
  (option: SearchMenuItem) => {
    return !!(
      (portfoliosOnly && option.category === 'investment') ||
      (investmentsOnly && option.category === 'portfolio') ||
      isOptionDisabled?.(option)
    );
  };

export const getOptionDisabledMessageFunc =
  (
    optionDisabledTooltipContent?: (option: SearchMenuItem) => string,
    investmentsOnly?: boolean,
    portfoliosOnly?: boolean,
  ) =>
  (option: SearchMenuItem) => {
    if (portfoliosOnly && option.category === 'investment') {
      return 'Investments can not be added here';
    }

    if (investmentsOnly && option.category === 'portfolio') {
      return 'Portfolios can not be added here';
    }

    return optionDisabledTooltipContent?.(option);
  };
