import React, { useEffect } from 'react';
import Shell from './components/Shell';
import { GlobalStyles, ShellThemeProvider } from 'venn-ui-kit';
import { LicenseManager, AgGridLicenseKey, AppPrintModeProvider } from 'venn-components';
import { QueryClientProvider, VennQueryClient } from 'venn-utils';
import { RecoilRoot } from 'recoil';
import { Router } from 'react-router-dom';

import { history } from './components/history';
import { TooltipProvider } from '@/components/ui/tooltip';

LicenseManager.setLicenseKey(AgGridLicenseKey);

const App = () => {
  useEffect(() => {
    const resizeObserverError = (event: ErrorEvent) => {
      if (event.message === 'ResizeObserver loop completed with undelivered notifications.') {
        event.stopPropagation();
      }
    };
    window.addEventListener('error', resizeObserverError, true);
    return () => {
      window.removeEventListener('error', resizeObserverError, true);
    };
  }, []);
  return (
    <RecoilRoot>
      <QueryClientProvider client={VennQueryClient.getInstance()}>
        <Router history={history}>
          <ShellThemeProvider>
            <TooltipProvider delayDuration={150}>
              <AppPrintModeProvider>
                <Shell />
                <GlobalStyles />
              </AppPrintModeProvider>
            </TooltipProvider>
          </ShellThemeProvider>
        </Router>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default App;
