import React from 'react';

interface SwapArrowsIconProps {
  className?: string;
}

export const SwapArrowsIcon = ({ className }: SwapArrowsIconProps) => {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M24.5664 23.0625C23.7578 23.0625 23.125 23.7305 23.125 24.5039L23.125 26.1563L16.0937 26.1563C14.6875 26.1563 13.5625 25.0313 13.5625 23.625C13.5625 22.2539 14.6875 21.0938 16.0937 21.0938L23.4062 21.0938C25.7266 21.0938 27.625 19.2305 27.625 16.875C27.625 14.5547 25.7266 12.6563 23.4062 12.6563L16.375 12.6563L16.375 11.0039C16.375 10.2305 15.707 9.5625 14.9336 9.5625C14.5469 9.5625 14.1602 9.73828 13.9141 9.98438L10.9961 12.9023C10.8203 13.0781 10.75 13.2891 10.75 13.5C10.75 13.7461 10.8203 13.957 10.9961 14.0977L13.9141 17.0156C14.1602 17.2969 14.5469 17.4375 14.9336 17.4375C15.707 17.4375 16.375 16.8047 16.375 15.9961L16.375 14.3438L23.4062 14.3438C24.7773 14.3438 25.9375 15.5039 25.9375 16.875C25.9375 18.2813 24.7773 19.4063 23.4062 19.4063L16.0937 19.4063C13.7383 19.4063 11.875 21.3047 11.875 23.625C11.875 25.9805 13.7383 27.8438 16.0937 27.8438L23.125 27.8438L23.125 29.4961C23.125 30.3047 23.7578 30.9375 24.5664 30.9375C24.918 30.9375 25.3047 30.7969 25.5859 30.5156L28.5039 27.5977C28.6445 27.457 28.75 27.2461 28.75 27C28.75 26.7891 28.6445 26.5781 28.5039 26.4023L25.5859 23.4844C25.3047 23.2383 24.918 23.0625 24.5664 23.0625ZM24.8125 28.9336L24.8125 25.1016L26.7109 27L24.8125 28.9336ZM14.6875 15.4336L12.7539 13.5L14.6875 11.6016L14.6875 15.3984L14.6875 15.4336Z"
        fill="currentColor"
      />
    </svg>
  );
};
