import React, { Fragment, useCallback, useLayoutEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  GetColor,
  Headline3,
  Body1,
  ColorUtils,
  Icon,
  EllipsisTooltipSpan,
  CheckboxWrapper,
  Button,
} from 'venn-ui-kit';
import { isNil, kebabCase, compact } from 'lodash';

export interface SideMenuItem<T> {
  label: string;
  value: T;
  subItems?: SideMenuItem<T>[];
  icon?: JSX.Element | string;
  badge?: JSX.Element | string;
  /** Used for tracking. True if not all users have access to the section */
  restricted?: boolean;
  hidden?: boolean;
  /** By default, items with subitems are collapsible. Set this to true if the item shouldn't be */
  subItemsArrowHidden?: boolean;
  /** Whether the item should be disabled or not */
  disabled?: boolean;
  /** Tooltip to display on hover over the item name */
  tooltip?: string;
  /**
   * Whether the item name should be displayed in bold or not.
   * Selected items and items with subitems are always displayed in bold.
   */
  bold?: boolean;
  /** custom class */
  className?: string;
}

export interface SideMenuSection<T> {
  /** Unique section key, must not be 'title' if the side menu does not have a title */
  key: string;
  /** The title of the section */
  title?: string;
  /** The items in the section */
  items: SideMenuItem<T>[];
  /** A badge to display next to the section title */
  badge?: React.ReactNode;
  /** Whether this entire section can be collapsed or not */
  collapsible?: boolean;
  /** Whether items in this section are selected through checkboxes or not */
  checkbox?: boolean;
}

export type ItemClickHandler<T> = (value: T, label: string, restricted?: boolean, sectionKey?: string) => void;

interface SideMenuProps<T> {
  title?: string;
  items: SideMenuItem<T>[];
  sections?: SideMenuSection<T>[];
  selectedItems?: T | T[];
  onClick: ItemClickHandler<T>;
  onToggleItemCheckbox?: ItemClickHandler<T>;
  withoutContainer?: boolean;
  darkTheme?: boolean;
  onToggleItemOpen?: () => void;
  allowItemOverflow?: boolean;
  maxHeight?: number;
  resetSelected?: () => void;
  refreshedStyling?: boolean;
  containerBackgroundColor?: string;
}

interface ColorProps {
  darkTheme: boolean;
  selected: boolean;
  disabledStyling: boolean;
  checkbox: boolean;
  refreshedStyling: boolean;
}

function toSet<T>(items?: T | T[]): Set<T> {
  let itemArray: T[] = [];
  if (Array.isArray(items)) {
    itemArray = items;
  } else if (!isNil(items)) {
    itemArray = [items];
  }
  return new Set(itemArray);
}

const MENU_BOTTOM_PADDING_PX = 20;
const TITLE_CONTAINER_HEIGHT_PX = 66;

const SideMenu = <T,>({
  selectedItems,
  onClick,
  onToggleItemCheckbox,
  darkTheme = false,
  title,
  withoutContainer,
  items,
  onToggleItemOpen,
  allowItemOverflow,
  maxHeight,
  sections,
  resetSelected,
  containerBackgroundColor,
  refreshedStyling = false,
}: SideMenuProps<T>) => {
  const [closedSections, setClosedSections] = useState<Set<string>>(new Set());
  const [closedItems, setClosedItems] = useState<Set<T>>(new Set());
  const selectedItemsSet = toSet(selectedItems);

  const onToggleSection = useCallback(
    (sectionTitle: string) => (e: React.MouseEvent | React.KeyboardEvent) => {
      e?.stopPropagation();
      const newSet = new Set(closedSections);
      if (newSet.has(sectionTitle)) {
        newSet.delete(sectionTitle);
      } else {
        newSet.add(sectionTitle);
      }
      setClosedSections(newSet);
    },
    [closedSections],
  );

  const onToggleItem = useCallback(
    (itemValue: T) => (e: React.MouseEvent | React.KeyboardEvent) => {
      e?.stopPropagation();
      const newSet = new Set(closedItems);
      if (newSet.has(itemValue)) {
        newSet.delete(itemValue);
      } else {
        newSet.add(itemValue);
      }
      setClosedItems(newSet);
    },
    [closedItems],
  );

  const renderItems = (
    menuItems: SideMenuItem<T>[],
    parentItem?: SideMenuItem<T>,
    checkbox?: boolean,
    sectionKey?: string,
  ) => {
    return (
      <Items hideOverflow={!isNil(maxHeight)}>
        {menuItems
          .filter((item) => !item.hidden)
          .map((item) => {
            const {
              value,
              label,
              subItems,
              icon,
              badge,
              subItemsArrowHidden,
              disabled,
              restricted,
              tooltip,
              bold,
              className,
            } = item;
            const isItemSelected = selectedItemsSet.has(value);
            const clickHandler = checkbox && onToggleItemCheckbox ? onToggleItemCheckbox : onClick;
            const labelWithParent = parentItem ? `${parentItem.label}: ${label}` : label;
            const classes = compact([
              className,
              `qa-${parentItem ? `${kebabCase(parentItem.label)}-` : ''}${kebabCase(label)}-filter`,
              isItemSelected ? ' qa-selected-filter' : '',
              disabled ? ' qa-disabled-filter' : '',
            ]);

            return (
              <Fragment key={`${sectionKey ? `${sectionKey}-` : ''}${label}`}>
                <Item
                  refreshedStyling={refreshedStyling}
                  bold={bold ?? !!subItems?.length}
                  className={classes.join(' ')}
                  data-testid={`qa-${kebabCase(label)}-filter`}
                  selected={isItemSelected}
                  onClick={disabled ? undefined : () => clickHandler(value, labelWithParent, restricted, sectionKey)}
                  darkTheme={darkTheme}
                  allowItemOverflow={allowItemOverflow}
                  disabledStyling={!!disabled}
                  checkbox={!!checkbox}
                >
                  {checkbox ? (
                    <CheckboxWrapper
                      onChange={() => clickHandler(value, labelWithParent, restricted, sectionKey)}
                      checked={selectedItemsSet.has(value)}
                      disabled={disabled}
                    />
                  ) : null}
                  {icon}
                  <Label inverted={isItemSelected} checkbox={!!checkbox}>
                    {allowItemOverflow ? (
                      <>
                        {label}
                        {badge}
                      </>
                    ) : (
                      <EllipsisTooltipSpan block usePortal content={tooltip} alwaysShowTooltip={!!tooltip}>
                        {label}
                        {badge}
                      </EllipsisTooltipSpan>
                    )}
                  </Label>
                  {subItems && subItems.length > 0 && !subItemsArrowHidden && (
                    <SubitemsArrow
                      type={closedItems.has(value) ? 'caret-down' : 'caret-up'}
                      onClick={onToggleItem(value)}
                      tabIndex={0}
                    />
                  )}
                </Item>
                {subItems && !closedItems.has(value) && (
                  <Child>{renderItems(subItems, item, checkbox, sectionKey)}</Child>
                )}
              </Fragment>
            );
          })}
      </Items>
    );
  };

  useLayoutEffect(() => onToggleItemOpen?.(), [onToggleItemOpen, closedItems, closedSections]);

  const renderSection = (
    { title: sectionTitle, items: sectionItems, badge, checkbox, collapsible, key }: SideMenuSection<T>,
    isLast: boolean,
  ) => (
    <React.Fragment key={key}>
      {sectionTitle ? (
        <SectionTitle>
          <span>
            {sectionTitle}
            {badge}
          </span>
          {collapsible && sectionItems.length && (
            <SectionArrow
              type={closedSections.has(key) ? 'chevron-down' : 'chevron-up'}
              onClick={onToggleSection(key)}
              tabIndex={0}
            />
          )}
        </SectionTitle>
      ) : null}
      {!closedSections.has(key) && renderItems(sectionItems, undefined, checkbox, key)}
      {!isLast && <Separator />}
    </React.Fragment>
  );

  const titleContainerHeight = title ? TITLE_CONTAINER_HEIGHT_PX : 0;
  const itemsContainerMaxHeight = maxHeight ? maxHeight - titleContainerHeight - MENU_BOTTOM_PADDING_PX : undefined;

  const Content = (
    <>
      <MenuTitleContainer hasTitle={!!title}>
        {title && <Headline3>{title}</Headline3>}
        {resetSelected && (
          <ResetButton className="qa-reset-all" flat onClick={resetSelected}>
            Reset All
          </ResetButton>
        )}
      </MenuTitleContainer>
      <ItemsContainer maxHeight={itemsContainerMaxHeight}>
        {sections
          ? sections.map((section, index) => renderSection(section, index === sections.length - 1))
          : renderItems(items)}
      </ItemsContainer>
    </>
  );

  if (withoutContainer) {
    return Content;
  }

  return (
    <Container
      darkTheme={Boolean(darkTheme)}
      hasTitle={Boolean(title)}
      containerBackgroundColor={containerBackgroundColor}
    >
      {Content}
    </Container>
  );
};

const ItemsContainer = styled.div<{ maxHeight?: number }>`
  ${({ maxHeight }) => (!isNil(maxHeight) ? `max-height: ${maxHeight}px; overflow-y: auto;` : '')}
`;

const Container = styled.nav<{
  darkTheme: boolean;
  hasTitle: boolean;
  containerBackgroundColor: string | undefined;
}>`
  border-radius: 4px;
  background-color: ${({ darkTheme, containerBackgroundColor }) =>
    containerBackgroundColor ?? (darkTheme ? 'rgba(0, 129, 138, 0.15);' : GetColor.WhiteGrey)};
  padding-bottom: ${MENU_BOTTOM_PADDING_PX}px;

  ${Headline3} {
    margin: 20px 0 20px 20px;
  }
  ${({ hasTitle }) => !hasTitle && 'padding-top: 20px;'}
`;

const Items = styled.div<{ hideOverflow?: boolean }>`
  ${({ hideOverflow }) =>
    hideOverflow &&
    `
    overflow: hidden;`}
`;

const getColor = ({ darkTheme, selected, disabledStyling, refreshedStyling }: ColorProps) => {
  if (disabledStyling) {
    return GetColor.MidGrey2;
  }
  if (darkTheme) {
    return selected ? (refreshedStyling ? GetColor.DarkBlue : GetColor.Primary.Main) : GetColor.White;
  }
  return GetColor.Black;
};

const MenuTitleContainer = styled.div<{ hasTitle: boolean }>`
  display: flex;
  justify-content: space-between;
  button {
    font-size: 14px;
  }
  padding-right: 15px;
  ${({ hasTitle }) => hasTitle && `height: ${TITLE_CONTAINER_HEIGHT_PX}px;`}
`;

const SectionTitle = styled(Headline3)`
  font-size: 16px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
`;

const Separator = styled.div`
  margin: 0 17px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${GetColor.Grey};
`;

const Item = styled.button<
  {
    allowItemOverflow?: boolean;
    bold: boolean;
  } & ColorProps
>`
  width: calc(100% - 5px);
  padding-left: 20px;
  padding-right: 15px;
  ${(props) =>
    props.allowItemOverflow
      ? css`
          padding-top: 6px;
          padding-bottom: 6px;
        `
      : 'height: 32px;'}
  margin-right: 5px;
  background-color: ${({ darkTheme, refreshedStyling, selected, checkbox }) =>
    selected && !darkTheme && !checkbox
      ? ColorUtils.opacifyFrom(refreshedStyling ? GetColor.DarkBlue : GetColor.Primary.Dark, 0.1)
      : 'transparent'};
  color: ${getColor};
  border: 1px solid
    ${({ selected, refreshedStyling, checkbox }) =>
      selected && !checkbox ? (refreshedStyling ? GetColor.DarkBlue : GetColor.Primary.Dark) : 'transparent'};
  ${({ disabledStyling, darkTheme }) => css`
    :hover {
      ${darkTheme ? null : `background-color: ${GetColor.PaleGrey}`};
      border: 1px solid ${disabledStyling ? GetColor.MidGrey2 : GetColor.MidGrey1};
      border-left: none;
    }
  `}
  border-left: none;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  cursor: ${({ disabledStyling }) => (disabledStyling ? 'not-allowed' : 'pointer')};
  ${({ bold, selected }) => (bold || selected ? 'font-weight: bold;' : '')}

  ${Body1} {
    color: ${getColor};
  }

  // for the checkbox
  label {
    flex: none;
  }
`;

const Child = styled.div`
  margin-left: 15px;
`;

const SubitemsArrow = styled(Icon)`
  padding: 5px;
  color: ${GetColor.Black};
`;

const SectionArrow = styled(Icon)`
  padding-right: 25px;
  color: ${GetColor.Black};
`;

const Label = styled(Body1)<{ checkbox: boolean }>`
  line-height: normal;
  max-width: ${({ checkbox }) => (checkbox ? 'calc(100% - 30px)' : '100%')};
  // Needed for IE
  flex: none;
  position: relative;
  top: 1px;
`;

const ResetButton = styled(Button)`
  letter-spacing: 0.2px;
  // Needed for IE
  height: 100%;
`;

export default SideMenu;
