import styled, { css } from 'styled-components';
import { Select } from 'venn-ui-kit';

const searchBlockStyle = (prefix = 'select') => css`
  .${prefix}__menu {
    position: relative;
    box-shadow: none;
    background-color: transparent;

    > div {
      box-shadow: none;
    }

    .${prefix}__menu-list {
      padding: 20px 0 0 0;
      overflow: visible;
    }
  }
`;

export const StyledSelect = styled(Select)<{
  displayResultsAsBlock?: boolean;
  maxHeightCss?: string;
}>`
  .select__input-container {
    font-family: ${(props) => props.theme.Typography.fontFamily};
    font-weight: 700;
  }

  .select__menu-list {
    ${({ maxHeightCss }) => (maxHeightCss ? `max-height: ${maxHeightCss}` : '')};
  }

  ${(props) => props.displayResultsAsBlock && searchBlockStyle()}
`;
