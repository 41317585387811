import type { Portfolio } from 'venn-api';
import { isNil } from 'lodash';

export const getProFormaPortfolioTotalAllocations = (portfolio: Portfolio | undefined): number => {
  if (isNil(portfolio)) {
    return 0;
  }

  if (portfolio.fund) {
    return portfolio.allocation ?? 0;
  }
  return (
    portfolio.children?.map((child) => getProFormaPortfolioTotalAllocations(child)).reduce((a, b) => a + b, 0) ?? 0
  );
};

export const getHistoricalPortfolioDateTotalAllocations = (
  portfolio: Portfolio | undefined,
  allocationDates: number[],
): Record<number, number> => {
  const dateAllocations = {};

  if (!portfolio) {
    return dateAllocations;
  }

  allocationDates.forEach((date) =>
    getHistoricalPortfolioSingleDateTotalAllocations(portfolio, allocationDates, date, dateAllocations),
  );

  return dateAllocations;
};

const getHistoricalPortfolioSingleDateTotalAllocations = (
  portfolio: Portfolio,
  allocationDates: number[],
  date: number,
  dateAllocations: {
    [id: number]: number;
  },
) => {
  if (portfolio.fund) {
    dateAllocations[date] =
      (dateAllocations[date] ?? 0) + (portfolio.closingAllocationsTs?.find((x) => x[0] === date)?.[1] ?? 0);
  } else {
    portfolio.children?.forEach((child) =>
      getHistoricalPortfolioSingleDateTotalAllocations(child, allocationDates, date, dateAllocations),
    );
  }
};
