import { filter, isEqual, isNil, sum } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import type { ItemId, PrivatePortfolioNode } from 'venn-api';
import { savePrivatePortfolio } from 'venn-api';
import {
  allocatorAnalysisSubject,
  openPrivateAllocatorPortfolio,
  originalAnalysisSubjectQuery,
  subjectInputGroups,
  subjectInputGroupSubjects,
} from 'venn-state';
import {
  ButtonIcon,
  ColorUtils,
  EllipsisAutoPositionTooltipSpan,
  Flexbox,
  GetColor,
  LegacyRelativePortal,
  Notifications,
  NotificationType,
  TooltipBodyDirection,
  TooltipPosition,
} from 'venn-ui-kit';
import {
  AnalysisSubject,
  analyticsService,
  assertNotNil,
  formatAllocation,
  isRequestSuccessful,
  logExceptionIntoSentry,
  useModal,
} from 'venn-utils';
import type { SearchMenuItem } from '../../search-menu';
import { MultiSelectSearch } from '../../search-menu';
import AllocationDifferenceTooltip from '../AllocationDifferenceTooltip';
import ItemAllocation from '../ItemAllocation';
import { PrivatesAllocatorFooter } from './PrivatesAllocatorFooter';
import { PrivatesFundActions } from './PrivatesFundActions';
import { SavePrivatePortfolioAsModal } from './SavePrivatePortfolioAsModal';
import { UserContext } from '../../contexts';

const computeIdToAllocationMap = (portfolio?: PrivatePortfolioNode): Map<string, number> => {
  return new Map((portfolio?.children ?? []).map((node) => [node.fundId, node.capitalCommitment]));
};

export const PrivatesAllocatorPanel = () => {
  const originalPortfolio = useRecoilValue(openPrivateAllocatorPortfolio);
  const studioSubject = { privatePortfolioId: originalPortfolio?.id };
  const [analysisSubjectInPrivateAllocator, setAnalysisSubjectInPrivateAllocator] = useRecoilState(
    allocatorAnalysisSubject(studioSubject),
  );
  const { hasPermissionForResource, hasPermission } = useContext(UserContext);

  const idToOriginalAllocationMap = useMemo(() => {
    return computeIdToAllocationMap(originalPortfolio);
  }, [originalPortfolio]);
  const modifiedPortfolio = analysisSubjectInPrivateAllocator?.privatePortfolio;
  const idToCurrentAllocationMap = useMemo(() => {
    return computeIdToAllocationMap(modifiedPortfolio);
  }, [modifiedPortfolio]);
  const [isSavingInProgress, setSavingInProgress] = useState<boolean>(false);
  const [isOpenSaveAsModal, openSaveAsModal, closeSaveAsModal] = useModal();

  const handleSaveStateUpdates = useRecoilCallback(
    ({ set, refresh, snapshot }) =>
      async (oldPortfolioId: string | undefined, savedPortfolio: PrivatePortfolioNode) => {
        const newStudioSubject = { privatePortfolioId: savedPortfolio.id };

        set(openPrivateAllocatorPortfolio, savedPortfolio);
        set(allocatorAnalysisSubject(newStudioSubject), new AnalysisSubject(savedPortfolio, 'private-portfolio'));
        refresh(originalAnalysisSubjectQuery(newStudioSubject));

        if (oldPortfolioId !== savedPortfolio.id) {
          const allSubjectInputGroups = await snapshot.getPromise(subjectInputGroups);

          allSubjectInputGroups.forEach((group) =>
            set(subjectInputGroupSubjects(group), (current) => {
              return current.map((subject) =>
                subject.privatePortfolioId === oldPortfolioId ? newStudioSubject : subject,
              );
            }),
          );
        }
      },
    [],
  );

  const resetAllocatorAnalysisSubject = useRecoilCallback(
    ({ reset }) =>
      (currentPortfolioId: string | undefined) =>
        reset(allocatorAnalysisSubject({ privatePortfolioId: currentPortfolioId })),
    [],
  );

  useEffect(() => {
    const currentPortfolioId = originalPortfolio?.id;

    return () => resetAllocatorAnalysisSubject(currentPortfolioId);
  }, [originalPortfolio?.id, resetAllocatorAnalysisSubject]);

  /** Function for saving a portfolio.
   * @param newName desired portfolio name, or undefined for if you don't want to modify current name. */
  const onSave = useCallback(
    async (newName: string | undefined) => {
      setSavingInProgress(true);
      const savingPortfolioNotification = Notifications.notify('Saving portfolio...', NotificationType.LOADING);
      try {
        const createdPortfolio = newName
          ? {
              ...modifiedPortfolio,
              name: newName,
              id: undefined,
              fundId: undefined,
              parentNodeId: undefined,
              rootNodeId: undefined,
            }
          : modifiedPortfolio;
        const response = await savePrivatePortfolio(createdPortfolio);
        if (isRequestSuccessful(response)) {
          const savedPortfolio = response.content;
          handleSaveStateUpdates(modifiedPortfolio?.id, savedPortfolio);
          setSavingInProgress(false);
          Notifications.notifyUpdate(
            savingPortfolioNotification,
            'Portfolio saved successfully.',
            NotificationType.SUCCESS,
          );
        } else {
          Notifications.notifyUpdate(savingPortfolioNotification, 'Saving portfolio failed.', NotificationType.ERROR);
          setSavingInProgress(false);
        }
      } catch (error) {
        logExceptionIntoSentry(error);
        Notifications.notifyUpdate(savingPortfolioNotification, 'Saving portfolio failed.', NotificationType.ERROR);
        setSavingInProgress(false);
      }
    },
    [handleSaveStateUpdates, modifiedPortfolio],
  );

  const onSaveAsApply = useCallback(
    async (newName: string | undefined) => {
      closeSaveAsModal();
      await onSave(newName);
    },
    [onSave, closeSaveAsModal],
  );

  const canSaveAsPortfolio = useMemo(() => hasPermission('CREATE_PORTFOLIO'), [hasPermission]);

  const isModelPortfolio = useMemo(
    () =>
      originalPortfolio &&
      originalPortfolio.ownerContextId &&
      !hasPermissionForResource('EDIT_PORTFOLIO', originalPortfolio),
    [hasPermissionForResource, originalPortfolio],
  );

  if (isNil(originalPortfolio) || isNil(modifiedPortfolio)) {
    return null;
  }

  const isDemo = originalPortfolio?.permissionGroupId === 1;
  return (
    <MainContainer>
      <div>
        <TopContainer>
          <div>Name</div>
          <div>Capital Commitment</div>
        </TopContainer>
        <SingleLevelAllocator
          idToOriginalAllocationMap={idToOriginalAllocationMap}
          modifiedPortfolio={modifiedPortfolio}
          setModifiedPortfolio={(modifiedPortfolio) => {
            setAnalysisSubjectInPrivateAllocator(new AnalysisSubject(modifiedPortfolio, 'private-portfolio'));
          }}
        />
      </div>
      <div>
        <PrivatesAllocatorFooter
          onReset={() =>
            setAnalysisSubjectInPrivateAllocator(new AnalysisSubject(originalPortfolio, 'private-portfolio'))
          }
          noChanges={isEqual(idToOriginalAllocationMap, idToCurrentAllocationMap)}
          savingInProgress={isSavingInProgress}
          onSave={() => onSave(undefined)}
          onSaveAs={openSaveAsModal}
          isDemo={isDemo}
          isModelPortfolio={!!isModelPortfolio}
          canSaveAs={canSaveAsPortfolio}
        />
      </div>
      {isOpenSaveAsModal && <SavePrivatePortfolioAsModal onClose={closeSaveAsModal} onSave={onSaveAsApply} />}
    </MainContainer>
  );
};

interface SingleLevelAllocatorProps {
  modifiedPortfolio: PrivatePortfolioNode;
  idToOriginalAllocationMap: Map<string, number>;
  setModifiedPortfolio: React.Dispatch<PrivatePortfolioNode>;
}

const SingleLevelAllocator = ({
  idToOriginalAllocationMap,
  modifiedPortfolio,
  setModifiedPortfolio,
}: SingleLevelAllocatorProps) => {
  const [isAddingNewFund, setAddingNewFund] = useState<boolean>(false);
  const displayedInvestmentIds: ItemId[] = (modifiedPortfolio?.children ?? []).map((child) => {
    return {
      id: child.id,
      type: 'FUND',
    };
  });

  const originalTotalCapitalCommitment = sum(Array.from(idToOriginalAllocationMap.values()));
  const totalCapitalCommitment = sum((modifiedPortfolio.children ?? []).map((fund) => fund.capitalCommitment ?? 0));

  const handleInvestmentMultiAdd = (items: SearchMenuItem[]) => {
    const newPortfolioNodes: PrivatePortfolioNode[] = items.map((item) => {
      const itemAsSearchResult = assertNotNil(item.searchResult);
      return {
        name: itemAsSearchResult.name,
        fundId: itemAsSearchResult.privateFundId,
        id: itemAsSearchResult.privateFundId,
        children: [],
        parentNodeId: modifiedPortfolio?.rootNodeId,
        rootNodeId: modifiedPortfolio?.rootNodeId,
        capitalCommitment: itemAsSearchResult.capitalCommitment ?? 0,
        active: true,
        created: 0,
        dataSource: '',
        ownerId: 0,
        permissionGroupId: 0,
        updated: 0,
        updatedById: 0,
        ownerContextId: '',
      };
    });
    setAddingNewFund(false);
    setModifiedPortfolio({
      ...modifiedPortfolio,
      children: [...modifiedPortfolio.children, ...newPortfolioNodes],
    });
    analyticsService.allocatorInvestmentsChanged({
      isPrivateAllocatorPanel: true,
      portfolioId: modifiedPortfolio.id,
      addedInvestmentIds: items.map(({ searchResult }) => searchResult?.privateFundId ?? ''),
    });
  };

  const handleInvestmentDelete = (id: string) => {
    setModifiedPortfolio({
      ...modifiedPortfolio,
      children: filter(modifiedPortfolio.children, (node) => node.fundId !== id),
    });
    analyticsService.allocatorInvestmentsChanged({
      isPrivateAllocatorPanel: true,
      portfolioId: modifiedPortfolio.id,
      removedInvestmentIds: [id],
    });
  };

  const updateCapitalCommitment = (id: string, value: number) => {
    setModifiedPortfolio({
      ...modifiedPortfolio,
      children: modifiedPortfolio.children.map((fundNode) => {
        if (fundNode.fundId !== id) {
          // no update
          return fundNode;
        }
        return {
          ...fundNode,
          capitalCommitment: value,
        };
      }),
    });
  };

  return (
    <AllocatorContainer>
      <Flexbox
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        css={css`
          width: 100%;
        `}
      >
        <AllocatorPortfolioNameContainer>
          <EllipsisAutoPositionTooltipSpan
            usePortal
            content={modifiedPortfolio?.name}
            bodyDirection={TooltipBodyDirection.Right}
            maxWidth={160}
            alwaysShowTooltip={false}
            hideArrow
            tooltipMaxWidth={280}
          >
            {modifiedPortfolio?.name}
          </EllipsisAutoPositionTooltipSpan>
          <ButtonIcon iconType="plus" onClick={() => setAddingNewFund(true)} iconFontSize={12} size={12} />
          {isAddingNewFund && (
            <LegacyRelativePortal
              component="div"
              left={-156}
              top={26}
              style={{ width: 700 }}
              className="investment-search"
            >
              <MultiSelectSearch
                shouldLimitSize={false}
                autofocus
                investmentsOnly
                privateAssetSearchMode="PRIVATES_ONLY"
                includeTags={false}
                includeBenchmarks={false}
                onSelected={handleInvestmentMultiAdd}
                onBlur={() => setAddingNewFund(false)}
                excludedItems={displayedInvestmentIds}
                forceMenuIsOpen
                showRecentlyAnalyzed={false}
                location="privatesAllocationPanel"
              />
            </LegacyRelativePortal>
          )}
        </AllocatorPortfolioNameContainer>
        <Wrapper>
          <AllocationDifferenceTooltip
            hasDifference={totalCapitalCommitment !== originalTotalCapitalCommitment}
            value={totalCapitalCommitment}
            difference={totalCapitalCommitment - originalTotalCapitalCommitment}
            isPercentageMode={false}
            position={TooltipPosition.Bottom}
            usePortal
          >
            <TotalCapitalCommitment isModified={totalCapitalCommitment !== originalTotalCapitalCommitment}>
              {formatAllocation(totalCapitalCommitment, true)}
            </TotalCapitalCommitment>
          </AllocationDifferenceTooltip>
        </Wrapper>
      </Flexbox>
      <FundsContainer>
        {(modifiedPortfolio?.children ?? []).map((fundNode) => (
          <LeafNode
            modifiedPortfolio={modifiedPortfolio}
            onCapitalCommitmentChange={updateCapitalCommitment}
            onDelete={handleInvestmentDelete}
            id={fundNode.fundId}
            name={fundNode.name}
            capitalCommitment={fundNode.capitalCommitment ?? 0}
            originalCapitalCommitment={idToOriginalAllocationMap.get(fundNode.fundId)}
            key={fundNode.id}
          />
        ))}
      </FundsContainer>
    </AllocatorContainer>
  );
};

interface LeafNodeProps {
  id: string;
  name: string;
  modifiedPortfolio: PrivatePortfolioNode;
  originalCapitalCommitment: number | undefined;
  capitalCommitment: number;
  onCapitalCommitmentChange: (id: string, value: number) => void;
  onDelete: (id: string) => void;
}

const LeafNode = ({
  id,
  capitalCommitment,
  originalCapitalCommitment,
  modifiedPortfolio,
  name,
  onDelete,
  onCapitalCommitmentChange,
}: LeafNodeProps) => {
  if (!id) {
    return null;
  }
  return (
    <FundAllocationRow data-testid="private-allocator-fund-row">
      <EllipsisAutoPositionTooltipSpan
        usePortal
        content={name}
        bodyDirection={TooltipBodyDirection.Right}
        maxWidth={170}
        alwaysShowTooltip={false}
        hideArrow
        tooltipMaxWidth={260}
      >
        {name}
      </EllipsisAutoPositionTooltipSpan>
      <Wrapper>
        <ItemAllocation
          isGhost={false}
          isStrategy={false}
          isRoot={false}
          isModified={capitalCommitment !== originalCapitalCommitment}
          value={capitalCommitment}
          originalValue={originalCapitalCommitment}
          onUpdateAllocation={(value: number) => onCapitalCommitmentChange(id, value)}
          isOutsideOfSelectedSubtree={false}
          isPercentageMode={false}
          baseAllocation={undefined}
          orignalBaseAllocation={capitalCommitment}
          usePortalForTooltip
        />
        <PrivatesFundActions
          onDelete={() => onDelete(id)}
          subject={{ privateFundId: id }}
          modifiedPortfolio={modifiedPortfolio}
        />
      </Wrapper>
    </FundAllocationRow>
  );
};
const TopContainer = styled.div`
  background-color: ${GetColor.White};
  display: flex;
  padding: 8px;
  align-items: center;
  border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
  justify-content: space-between;
`;

const FundAllocationRow = styled.span`
  display: flex;
  flex-direction: row;
  height: 30px;
  border-left: 1px dotted ${GetColor.Gold};
  align-items: center;
  justify-content: space-between;
  line-height: 12px;
  font-weight: 500;
  padding-left: 16px;
`;
const Wrapper = styled.span`
  display: flex;
  flex-direction: row;
  width: 100px;
`;

const FundsContainer = styled.div`
  padding-left: 9px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const AllocatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;
`;

const AllocatorPortfolioNameContainer = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: row;
  padding: 10px 15px 10px 10px;
  height: 36px;
  justify-content: flex-start;
  align-items: stretch;
  line-height: normal;
  gap: 10px;
  width: 200px;
  max-width: 200px;

  border-radius: 0px 4px 4px 0px;
  border-top: 1px solid ${GetColor.Primary.Dark};
  border-right: 1px solid ${GetColor.Primary.Dark};
  border-bottom: 1px solid ${GetColor.Primary.Dark};
  background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};

  .button-icon-wrapper {
    background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)} !important;
  }

  .fa-plus {
    color: ${GetColor.HintGrey} !important;
  }
`;

const TotalCapitalCommitment = styled.span<{ isModified: boolean }>`
  display: inline-block;
  width: 80px;
  font-size: 16px;
  text-align: right;
  padding-right: 5px;

  color: ${({ isModified, theme: { Colors } }) => (isModified ? Colors.HighlightDark : Colors.Black)};
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  height: 100%;
  background-color: ${GetColor.GreyScale.Grey10};
`;
