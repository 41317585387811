import React from 'react';
import type { DateRange } from 'venn-ui-kit';
import { ButtonIcon } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import { Routes, countFunds } from 'venn-utils';

interface CompareButtonProps {
  subject: AnalysisSubject;
  relative?: boolean;
  dateRange?: DateRange;
  size?: number;
}

const CompareButton: React.FC<React.PropsWithChildren<CompareButtonProps>> = ({
  subject,
  relative,
  dateRange,
  size,
}) => {
  const isHistoricalPortfolio = !!subject.portfolio?.historical;
  const disabled = (countFunds(subject.portfolio) < 1 && subject.type === 'portfolio') || isHistoricalPortfolio;
  const tooltip = disabled
    ? isHistoricalPortfolio
      ? 'Comparison is not available for historical portfolios'
      : 'Add at least one investment to enable Comparison'
    : 'Compare';
  let linkTo = `${Routes.ANALYSIS_COMPARE_PATH}?subjects=${subject.id}`;

  if (subject.hasBenchmark) {
    linkTo += `&benchmark=${subject.activeBenchmarkId}`;
  }

  if (relative) {
    linkTo += '&relative=true';
  }

  if (dateRange && dateRange.from && dateRange.to) {
    linkTo += `&from=${dateRange.from}&to=${dateRange.to}`;
  }

  return (
    <ButtonIcon
      ctaTrackingOptions={{
        ctaDestination: 'Comparison',
        ctaText: 'Go to comparison screen',
        ctaPurpose: 'Compare subject',
      }}
      tooltip={tooltip}
      disabled={disabled}
      iconType="line-columns"
      iconPrefix="fad"
      to={linkTo}
      className="qa-compare"
      size={size}
    />
  );
};

export default CompareButton;
