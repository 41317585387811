import { FooterButton, FooterContainer, SaveAsButton, SaveButton } from '../AllocationFooter';
import React from 'react';
import { analyticsService } from 'venn-utils';
import {
  LoadingSize,
  Spinner,
  TooltipPosition,
  getAppTitle,
  DEMO_PRIVATE_PORTFOLIO_FAQ_HREF,
  Icon,
  GetColor,
} from 'venn-ui-kit';
import styled from 'styled-components';

interface PrivatesAllocatorFooterProps {
  onReset: () => void;
  onSave: () => void;
  onSaveAs: () => void;
  savingInProgress: boolean;
  noChanges: boolean;
  isDemo?: boolean;
  isModelPortfolio?: boolean;
  canSaveAs?: boolean;
}

export const PrivatesAllocatorFooter = ({
  onReset,
  onSave,
  onSaveAs,
  noChanges,
  savingInProgress,
  isDemo,
  isModelPortfolio,
  canSaveAs,
}: PrivatesAllocatorFooterProps) => {
  const saveTooltipMsg = noChanges ? 'No changes' : 'Save Private Portfolio';
  const modelPortfolioMsg = canSaveAs
    ? 'You do not have permission to edit Model portfolios. This portfolio will be saved separately.'
    : 'You do not have permission to edit Model portfolios';
  return (
    <StyledFooter>
      {isDemo && (
        <DemoLabel>
          <QuestionMark type="question-circle" /> {`You are viewing ${getAppTitle()}'s demo portfolio.`}&nbsp;
          <a href={DEMO_PRIVATE_PORTFOLIO_FAQ_HREF} target="_blank" rel="noopener noreferrer">
            Learn more
          </a>
        </DemoLabel>
      )}
      {isModelPortfolio && (
        <ModelPortfolioLabel data-testid="qa-private-model-portfolio">
          <Icon type="pencil-slash" className="text-venn-highlight-dark" />
          {modelPortfolioMsg}
        </ModelPortfolioLabel>
      )}
      <FooterContainer disabled={false}>
        <FooterButton
          showSaveButton={!isDemo}
          tooltipPosition={TooltipPosition.Top}
          tooltipMessage={noChanges ? 'No changes' : undefined}
          disabled={noChanges || savingInProgress}
          onClick={() => {
            onReset();
            analyticsService.ctaClicked({
              destination: undefined, // Stays on the page
              filled: false,
              locationOnPage: 'Privates Allocation Panel - footer',
              purpose: 'Reset allocation changes',
              text: 'Reset',
              type: 'button',
            });
          }}
          className="privates-allocator-reset-btn"
        >
          Reset
        </FooterButton>
        {!isDemo && !isModelPortfolio && (
          <SaveButton
            tooltipPosition={TooltipPosition.Top}
            tooltipMessage={saveTooltipMsg}
            showSaveButton
            disabled={noChanges || savingInProgress}
            onClick={() => {
              onSave();
              analyticsService.ctaClicked({
                destination: undefined, // Stays on the page
                filled: false,
                locationOnPage: 'Privates Allocation Panel - footer',
                purpose: 'Save allocation changes',
                text: 'Save',
                type: 'button',
              });
            }}
            className="privates-allocator-save-btn"
          >
            {savingInProgress ? <Spinner size={LoadingSize.small} /> : 'Save'}
          </SaveButton>
        )}
        <SaveAsButton
          tooltipPosition={TooltipPosition.Top}
          tooltipMessage="Save Portfolio As..."
          showSaveButton={!isDemo}
          disabled={savingInProgress || !canSaveAs}
          onClick={() => {
            onSaveAs();
            analyticsService.ctaClicked({
              destination: undefined, // Stays on the page
              filled: false,
              locationOnPage: 'Privates Allocation Panel - footer',
              purpose: 'Save allocations as new Private Portfolio',
              text: 'Save as...',
              type: 'button',
            });
          }}
          className="privates-allocator-save-as-btn"
        >
          Save as...
        </SaveAsButton>
      </FooterContainer>
    </StyledFooter>
  );
};

const QuestionMark = styled(Icon)`
  margin-right: 4px;
`;

const StyledFooter = styled.div`
  display: block;
`;

const DemoLabel = styled.div`
  height: 40px;
  background-color: ${GetColor.WhiteGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-top: 1px solid ${GetColor.GreyScale.Grey30};

  > a {
    font-weight: bold;
  }
`;

const ModelPortfolioLabel = styled.div`
  background-color: ${GetColor.HighlightPaleBackground};
  border-top: 1px solid ${GetColor.GreyScale.Grey30};
  display: flex;
  padding: 12px 10px;
  gap: 4px;
  align-items: center;
  justify-content: center;
  font-weight: normal;

  > a {
    font-weight: bold;
  }
`;
