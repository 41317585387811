import { assert, assertNotNil, capitalizeFirstLetter, getFormattedFrequency, getFormattedProxyType } from 'venn-utils';
import { formatDateByFrequency } from '../manage-data/utils';
import React from 'react';
import type { BulkManageRow, OldBulkManageRow } from './types';
import styled from 'styled-components';

import { BodyEmphasis, Col, Container, Row, Subtitle1 } from 'venn-ui-kit';
import { capitalize } from 'lodash';
import { isBulkManageInvestmentRow, isBulkManageRow, isBulkManageFundRow, isBulkManageFactorRow } from './utils';

interface ProxiedInvestmentTooltipContentProps {
  /** Data for a valid investment row
   *  It is expected to have returns and a frequency */
  data: BulkManageRow | OldBulkManageRow;
}
const isExtrapolating = (
  row: BulkManageRow | OldBulkManageRow,
): row is (BulkManageRow | OldBulkManageRow) & {
  extrapolateStartDate: number;
  extrapolateEndDate: number;
} =>
  isBulkManageRow(row)
    ? !!(isBulkManageFundRow(row) && row.extrapolateStartDate && row.extrapolateEndDate)
    : !!(row.extrapolateStartDate && row.extrapolateEndDate);
const hasProxyRange = (
  row: BulkManageRow | OldBulkManageRow,
): row is (BulkManageRow | OldBulkManageRow) & {
  startDate: number;
  proxyEndDate: number;
} =>
  isBulkManageRow(row)
    ? !!(isBulkManageFundRow(row) && row.startDate && row.proxyEndDate)
    : !!(row.startDate && row.proxyEndDate);
const ProxiedInvestmentTooltipContent = ({ data }: ProxiedInvestmentTooltipContentProps) => {
  if (isBulkManageRow(data)) {
    assert(
      isBulkManageInvestmentRow(data) || isBulkManageFactorRow(data),
      `Expected a fund or factor row. Got: ${data.rowType}`,
    );
  }
  const frequency = assertNotNil(data.frequency);

  const isNewRow = 'fundProxyInfo' in data;
  const proxyType = isNewRow ? data.fundProxyInfo?.proxyType : !isBulkManageRow(data) && data.proxyType;
  const numLags = isNewRow ? data.fundProxyInfo?.numLags : undefined;
  const unproxiedFrequency = isNewRow ? data.fundProxyInfo?.unproxiedFrequency : undefined;
  const shouldShowZeroAllocationWarning = isBulkManageRow(data)
    ? isBulkManageFundRow(data) && data.allocation === 0
    : !data.historicalStartDate && data.allocation === 0;

  return (
    <RangeTooltipContainer fluid>
      <Row>
        <Col>
          <Subtitle1 inverted>{data.name}</Subtitle1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Subtitle1 inverted>Availability Range:</Subtitle1>
        </Col>
        <Col>
          <Subtitle1 inverted>
            {formatDateByFrequency(assertNotNil(data.startDate), frequency)} -{' '}
            {formatDateByFrequency(assertNotNil(data.endDate), frequency)}
          </Subtitle1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Subtitle1 inverted>Return Frequency:</Subtitle1>
        </Col>
        <Col>
          <Subtitle1 inverted>{capitalizeFirstLetter(getFormattedFrequency(frequency))}</Subtitle1>
        </Col>
      </Row>
      {proxyType && (
        <Row>
          <Col>
            <Subtitle1 inverted>Proxy Type:</Subtitle1>
          </Col>
          <Col>
            <Subtitle1 inverted>{getFormattedProxyType(proxyType)}</Subtitle1>
          </Col>
        </Row>
      )}
      {!!numLags && (
        <Row>
          <Col>
            <Subtitle1 inverted>{unproxiedFrequency ? `${capitalize(unproxiedFrequency)} ` : ''}Lag:</Subtitle1>
          </Col>
          <Col>
            <Subtitle1 inverted>{numLags}</Subtitle1>
          </Col>
        </Row>
      )}
      {isExtrapolating(data) && (
        <Row>
          <Col>
            <Subtitle1 inverted>Extrapolation:</Subtitle1>
          </Col>
          <Col>
            <Subtitle1 inverted>On</Subtitle1>
          </Col>
        </Row>
      )}
      {hasProxyRange(data) && (
        <Row>
          <Col>
            <Subtitle1 inverted>Proxy Range:</Subtitle1>
          </Col>
          <Col>
            <Subtitle1 inverted>
              {formatDateByFrequency(data.startDate, frequency)} -{' ' /* prettier-ignore */}
              {formatDateByFrequency(data.proxyEndDate, frequency)}
            </Subtitle1>
            {isExtrapolating(data) && (
              <Subtitle1 inverted>
                {formatDateByFrequency(data.extrapolateStartDate, frequency)} -{' '}
                {formatDateByFrequency(data.extrapolateEndDate, frequency)}
              </Subtitle1>
            )}
          </Col>
        </Row>
      )}
      {shouldShowZeroAllocationWarning && (
        <StyledEmphasis inverted>
          Investments with zero allocation do not constrain your analysis period.
        </StyledEmphasis>
      )}
    </RangeTooltipContainer>
  );
};

const StyledEmphasis = styled(BodyEmphasis)`
  font-size: 12px;
  line-height: 2;
`;

const RangeTooltipContainer = styled(Container)`
  width: 400px;
`;

export default ProxiedInvestmentTooltipContent;
