import { assertExhaustive } from 'venn-utils';
import type { ComputableMetricEnum, MetricFilter, TimePeriodEnum } from 'venn-api';
import { getMetricLabel } from '../filters';
import { uniqWith, isEqual, isNil } from 'lodash';

export interface PeriodMetric {
  timePeriod: TimePeriodEnum;
  metric: ComputableMetricEnum;
}

export const getPeriodMetrics = ({ metricFilters }: { metricFilters: MetricFilter[] }): PeriodMetric[] =>
  uniqWith(
    metricFilters.map((m) => ({ timePeriod: m.timePeriod, metric: m.metric })),
    isEqual,
  ) // these 3 metrics already in the default columns
    .filter(
      (p) =>
        p.timePeriod !== 'FULL' || !['ANNUALIZED_RETURN', 'ANNUALIZED_VOLATILITY', 'SHARPE_RATIO'].includes(p.metric),
    );

export const toPath = ({ periodMetric }: { periodMetric: PeriodMetric }) => {
  const { timePeriod, metric } = periodMetric;
  return `${timePeriod}.${metric}`;
};

const shortenTerms = (str: string) => {
  return str
    .replace(/\bcontribution to\b/i, 'Cto.')
    .replace(/\bcumulative\b/i, 'Cum.')
    .replace(/\bannualized\b/i, 'Ann.');
};

export const toColumnName = ({ periodMetric }: { periodMetric: PeriodMetric }) => {
  const { timePeriod, metric } = periodMetric;
  return `${shortenTerms(getMetricLabel(metric))}${timePeriodToLabel(timePeriod)}`;
};

const timePeriodToLabel = (timePeriod: TimePeriodEnum | undefined | null) => {
  if (isNil(timePeriod)) {
    return '';
  }
  switch (timePeriod) {
    case 'YEAR_1':
      return ' (1yr)*';
    case 'YEAR_3':
      return ' (3yr)*';
    case 'YEAR_5':
      return ' (5yr)*';
    case 'YEAR_7':
      return ' (7yr)*';
    case 'YEAR_10':
      return ' (10yr)*';
    case 'YTD':
      return ' (ytd)*';
    case 'FULL':
      return '*';
    case 'FULL_NO_FACTOR_CONSTRAINT':
      return ' (full)';
    case 'YEAR_15':
      return ' (15yr)*';
    case 'YEAR_20':
      return ' (20yr)*';
    default:
      return assertExhaustive(timePeriod);
  }
};
