import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { MANAGE_DATA_SECTION, navigateToManageDataPage } from 'venn-utils';
import { useHistory } from 'react-router-dom';
import type { Portfolio } from 'venn-api';
import { isNil, noop } from 'lodash';
import {
  BaseDropMenu,
  Button,
  ButtonIcon,
  GetColor,
  Icon,
  Link as LinkStyle,
  OptionIconPosition,
  SimpleMenu,
  Tooltip,
} from 'venn-ui-kit';
import { ConfirmationModal } from 'venn-components';
import type { DropMenuProps } from 'venn-ui-kit/src/components/drop-menu/implementations/BaseDropMenu';

enum PortfolioPolicyActions {
  SAVE = 'SAVE',
  RESET = 'RESET',
}

interface ConstraintsPolicyStatusFooterProps {
  portfolio: Portfolio | undefined;
  matchesPolicy: boolean;
  isSideBarMaximized: boolean;
  onResetToPolicyConstraints: () => void;
  onApplyToPolicy: () => void;
  onConstraintsSave?: () => void;
  onConstraintsRevert?: () => void;
  isInEditState?: boolean;
  hasConstraintsRevamp?: boolean;
}

const OptionIcon = styled(Icon)`
  padding-left: 5px;
`;

const portfolioPolicyOptions = [
  {
    label: 'Save as Portfolio Policy',
    description: 'Save current constraints as Portfolio Policy. This will overwrite your existing policy.',
    value: PortfolioPolicyActions.SAVE,
    icon: <OptionIcon type="sign-out-alt" />,
    iconPosition: OptionIconPosition.RIGHT,
  },
  {
    label: 'Reset to Portfolio Policy',
    description:
      'Override current constraints with Portfolio Policy constraints. This will clear your current constraints.',
    value: PortfolioPolicyActions.RESET,
    icon: <OptionIcon type="sync" />,
    iconPosition: OptionIconPosition.RIGHT,
  },
];

const ConstraintsPolicyStatusFooter = ({
  portfolio,
  matchesPolicy,
  isSideBarMaximized,
  onResetToPolicyConstraints,
  onApplyToPolicy,
  onConstraintsSave = noop,
  onConstraintsRevert = noop,
  isInEditState = false,
  hasConstraintsRevamp = false,
}: ConstraintsPolicyStatusFooterProps) => {
  const history = useHistory();
  const [confirmPolicyOverride, setConfirmPolicyOverride] = useState(false);

  const actionsMapper = useMemo(() => {
    return {
      [PortfolioPolicyActions.SAVE]: () => setConfirmPolicyOverride(true),
      [PortfolioPolicyActions.RESET]: () => onResetToPolicyConstraints(),
    };
  }, [setConfirmPolicyOverride, onResetToPolicyConstraints]);

  const openManageDataPage = () => {
    if (isNil(portfolio)) {
      return;
    }
    navigateToManageDataPage(
      history,
      { portfolioId: portfolio.id },
      'Portfolio Lab',
      false,
      MANAGE_DATA_SECTION.PORTFOLIO_POLICY,
    );
  };

  const triggerComponent: DropMenuProps<PortfolioPolicyActions>['triggerComponent'] = useCallback(
    (expanded, _, onToggle) => (
      <>
        <PolicySync
          matchesPolicy={matchesPolicy}
          onClick={() => onToggle(!expanded)}
          iconType="sync"
          className="qa-policy-sync-menu"
          disabled={isInEditState || matchesPolicy}
          isInEditState={isInEditState}
        />
        {!isInEditState && !matchesPolicy ? (
          <StyledIcon matchesPolicy={matchesPolicy} type={expanded ? 'caret-up' : 'caret-down'} />
        ) : null}
      </>
    ),
    [matchesPolicy, isInEditState],
  );

  const menuComponent: DropMenuProps<PortfolioPolicyActions>['menuComponent'] = useCallback(
    (_, onCollapse) => (
      <StyledSimpleMenu<PortfolioPolicyActions>
        items={portfolioPolicyOptions}
        width={300}
        onChange={({ value }) => {
          actionsMapper[value]();
          onCollapse();
        }}
      />
    ),
    [actionsMapper],
  );

  if (hasConstraintsRevamp) {
    return (
      <>
        <Footer hasConstraintsRevamp={hasConstraintsRevamp}>
          <Container>
            <BaseDropMenu
              menuAbove
              className="drop-menu"
              filteredItems={portfolioPolicyOptions}
              triggerComponent={triggerComponent}
              menuComponent={menuComponent}
            />
          </Container>
          <FooterStatusRevamped isInEditState={isInEditState}>
            <StatusHeader>
              Portfolio Policy
              {isInEditState ? (
                <Icon type="minus-circle" />
              ) : (
                <MatchesPolicyIcon
                  matchesPolicy={matchesPolicy}
                  type={matchesPolicy ? 'check-circle' : 'times-circle'}
                />
              )}
            </StatusHeader>
            <span>
              Constraints {matchesPolicy ? 'match ' : 'do not match '}
              {isInEditState ? (
                <strong>portfolio policy</strong>
              ) : (
                <LinkStyle onClick={openManageDataPage} tabIndex={0}>
                  portfolio policy
                </LinkStyle>
              )}
            </span>
          </FooterStatusRevamped>

          <>
            <ResetButton className="qa-revert-constraints" disabled={!isInEditState} onClick={onConstraintsRevert}>
              Revert
            </ResetButton>

            <ActionButton
              className="qa-save-constraints"
              disabled={!isInEditState}
              dominant
              onClick={onConstraintsSave}
            >
              Save
            </ActionButton>
          </>
        </Footer>
        {confirmPolicyOverride && (
          <ConfirmationModal
            header="Replace existing policy?"
            subhead="Your current policy will be overridden. This action cannot be undone."
            className="update-constraints-modal"
            onCancel={() => {
              setConfirmPolicyOverride(false);
            }}
            onProceed={() => {
              onApplyToPolicy();
              setConfirmPolicyOverride(false);
            }}
            proceedLabel="Continue"
          />
        )}
      </>
    );
  }

  return (
    <>
      <Footer>
        <FooterStatus>
          Constraints {matchesPolicy ? 'match ' : 'do not match '}{' '}
          <LinkStyle onClick={openManageDataPage} tabIndex={0}>
            portfolio policy
          </LinkStyle>
        </FooterStatus>
        {!matchesPolicy ? (
          <>
            <Tooltip isHidden={isSideBarMaximized} content="Reset constraints">
              <FooterButton onClick={onResetToPolicyConstraints}>
                {isSideBarMaximized && 'Reset constraints'} <Icon type="sync" prefix="far" />
              </FooterButton>
            </Tooltip>
            <Tooltip isHidden={isSideBarMaximized} content="Save as portfolio policy">
              <FooterButton
                onClick={() => {
                  setConfirmPolicyOverride(true);
                }}
              >
                {isSideBarMaximized && 'Save as portfolio policy'} <Icon type="sign-out" prefix="far" />
              </FooterButton>
            </Tooltip>
          </>
        ) : (
          <FooterStatus>
            <HighlightIcon type="check-circle" prefix="far" />
          </FooterStatus>
        )}
      </Footer>
      {confirmPolicyOverride && (
        <ConfirmationModal
          header="Replace existing policy?"
          subhead="Your current policy will be overridden. This action cannot be undone."
          className="update-constraints-modal"
          onCancel={() => {
            setConfirmPolicyOverride(false);
          }}
          onProceed={() => {
            onApplyToPolicy();
            setConfirmPolicyOverride(false);
          }}
          proceedLabel="Continue"
        />
      )}
    </>
  );
};

export default ConstraintsPolicyStatusFooter;

const Footer = styled.div<{ hasConstraintsRevamp?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: ${({ hasConstraintsRevamp }) => (hasConstraintsRevamp ? '50' : '26')}px;
  width: 100%;
  border-top: 1px solid ${GetColor.Grey};
  background-color: ${GetColor.White};
  color: ${({ hasConstraintsRevamp }) => (hasConstraintsRevamp ? '' : GetColor.HintGrey)};
  display: flex;
`;

const FooterStatus = styled.div`
  height: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  background-color: ${GetColor.WhiteGrey};
  border-right: 1px solid ${GetColor.Grey};
  & > :last-child {
    margin-left: 3px;
  }
`;

const Container = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
`;

const PolicySync = styled(ButtonIcon)<{ matchesPolicy: boolean; isInEditState: boolean }>`
  width: 50px;
  height: 49px;
  background-color: ${GetColor.WhiteGrey};

  & i {
    color: ${({ matchesPolicy, isInEditState }) =>
      isInEditState ? GetColor.MidGrey1 : !matchesPolicy ? GetColor.Primary.Dark : GetColor.DarkGrey};
    font-size: 20px;
  }
`;

const StyledIcon = styled(Icon)<{ matchesPolicy: boolean }>`
  position: absolute;
  bottom: 18px;
  left: 38px;
  color: ${({ matchesPolicy }) => (!matchesPolicy ? GetColor.Primary.Dark : GetColor.DarkGrey)};
`;

const StyledSimpleMenu = styled(SimpleMenu)`
  & span:hover span {
    color: ${GetColor.Primary.Dark};
  }
` as unknown as typeof SimpleMenu;

const MatchesPolicyIcon = styled(Icon)<{ matchesPolicy: boolean }>`
  color: ${({ matchesPolicy }) => (!matchesPolicy ? GetColor.Error : GetColor.HighlightDark)};
`;

const FooterStatusRevamped = styled.div<{ isInEditState: boolean }>`
  max-width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ isInEditState }) => (isInEditState ? GetColor.PaleGrey : GetColor.WhiteGrey)};
  color: ${({ isInEditState }) => (isInEditState ? GetColor.MidGrey1 : 'initial')};
  border-left: 1px solid ${GetColor.Grey};
  border-right: 1px solid ${GetColor.Grey};
  font-size: 1.125rem;
  flex-grow: 1;
  padding: 0 10px;
  margin-left: 50px;
  cursor: ${({ isInEditState }) => (isInEditState ? 'not-allowed' : 'initial')};
`;

const StatusHeader = styled.span`
  font-weight: bold;

  & i {
    margin-left: 3px;
  }
`;

const ActionButton = styled(Button)`
  margin-right: 0;
  height: 50px;
  border-radius: 0;
  flex-grow: 1;

  &:disabled {
    background-color: transparent;
    color: ${GetColor.MidGrey2};
    border-top: none;
  }

  &:focus {
    border: 1px solid ${GetColor.HighlightDark};
  }
`;

const ResetButton = styled(ActionButton)`
  border: none;
  border-bottom: 1px solid ${GetColor.Grey};

  &:hover {
    border: none !important;
    border-bottom: 1px solid ${GetColor.Grey} !important;
  }
`;

const FooterButton = styled(Button)`
  margin-right: 0;
  border: none;
  border-radius: 0;
  border-right: 1px solid ${GetColor.Grey};
  min-height: 25px;
  height: 25px;
  min-width: 26px;
  padding: 0 10px;
  &:hover {
    border-right: 1px solid ${GetColor.Grey};
  }
  &:focus {
    border: 1px solid ${GetColor.HighlightDark};
  }
`;

const HighlightIcon = styled(Icon)`
  color: ${GetColor.HighlightDark};
`;
