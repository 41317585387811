import { isNil } from 'lodash';
import React from 'react';
import { constSelector, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import {
  hasProxyState,
  isReportState,
  isReturnsBasedBlockState,
  type StudioRequestSubject,
  subjectAsOfDate,
  useCachedLoadableValue,
} from 'venn-state';
import { GetColor } from 'venn-ui-kit';
import {
  FootnoteSymbols,
  getBaseFee,
  getComparisonLabelForBlockLegend,
  getSubjectFromRequestSubject,
  hasFeesExcluded,
  isHistoricalSubject,
  Numbers,
} from 'venn-utils';
import { formatDateByFrequency } from '../manage-data/utils';
import { useBlockId } from '../studio-blocks/contexts/BlockIdContext';

const falseState = constSelector(false);

export const NameWithOptionalFee = ({ subject, isHeader }: { subject: StudioRequestSubject; isHeader?: boolean }) => {
  const blockId = useBlockId();

  const name = getComparisonLabelForBlockLegend(subject);
  const isReturnsBasedBlock = useRecoilValue(isReturnsBasedBlockState(blockId));
  const isStudio = !useRecoilValue(isReportState);
  const historicalAsOfDate = useRecoilValue(subjectAsOfDate([blockId, getSubjectFromRequestSubject(subject)]));
  const hasProxy = useCachedLoadableValue(isReturnsBasedBlock ? hasProxyState(subject.id) : falseState, false);
  const fee = getBaseFee(subject);

  const feesExcluded = hasFeesExcluded(subject);
  const showHistoricalAsOfDate = isHistoricalSubject(subject) && isHeader && isStudio && !isNil(historicalAsOfDate);

  return (
    <PureNameWithOptionalFee
      name={name}
      historicalAsOfDate={showHistoricalAsOfDate ? historicalAsOfDate : undefined}
      fee={fee}
      hasProxy={hasProxy}
      supportsFees={isReturnsBasedBlock}
      feesExcluded={feesExcluded}
    />
  );
};

export const PureNameWithOptionalFee = ({
  name,
  historicalAsOfDate,
  fee,
  hasProxy,
  supportsFees,
  feesExcluded,
}: {
  name: string;
  historicalAsOfDate?: number;
  fee: number;
  hasProxy: boolean;
  supportsFees: boolean;
  feesExcluded: boolean;
}) => (
  <NameWithFeeContainer>
    <NameContainer>{[name, hasProxy && <sup>{FootnoteSymbols.proxyUsage}</sup>]}</NameContainer>
    {historicalAsOfDate && (
      <StyledHistoricalAsOfDate> (as of {formatDateByFrequency(historicalAsOfDate, 'DAILY')})</StyledHistoricalAsOfDate>
    )}
    {!supportsFees || fee === 0 ? null : (
      <StyledFee>
        {' '}
        ({Numbers.safeFormatPercentage(fee)} Fee){feesExcluded && FootnoteSymbols.excludedInvestments}
      </StyledFee>
    )}
  </NameWithFeeContainer>
);

export const formatSubjectWithOptionalFee = (subject: StudioRequestSubject, hasProxy: boolean) => {
  return formatNameWithOptionalFee(
    getComparisonLabelForBlockLegend(subject),
    getBaseFee(subject),
    hasFeesExcluded(subject),
    hasProxy,
  );
};

export const formatNameWithOptionalFee = (
  name: string,
  fee?: number,
  feesExcluded?: boolean,
  hasProxy?: boolean,
): string =>
  `${name}${hasProxy ? `<sup>${FootnoteSymbols.proxyUsage}</sup>` : ''}${fee ? ` <span style="font-weight:normal; font-style: italic">(${Numbers.safeFormatPercentage(fee)} Fee)${feesExcluded ? FootnoteSymbols.excludedInvestments : ''}</span>` : ''}`;

export const formatExportableSubjectWithOptionalFee = (subject: StudioRequestSubject, hasProxy?: boolean) => {
  return formatExportableNameWithOptionalFee(
    getComparisonLabelForBlockLegend(subject),
    getBaseFee(subject),
    hasFeesExcluded(subject),
    hasProxy,
  );
};

export const formatExportableNameWithOptionalFee = (
  name: string,
  fee?: number,
  feesExcluded?: boolean,
  hasProxy?: boolean,
): string => {
  const proxyInfo = hasProxy ? FootnoteSymbols.proxyUsage : '';
  const feeInfo = fee
    ? ` (${Numbers.safeFormatPercentage(fee)} Fee)${feesExcluded ? FootnoteSymbols.excludedInvestments : ''}`
    : '';
  return `${name}${proxyInfo}${feeInfo}`;
};

const NameWithFeeContainer = styled.span`
  align-content: center;
`;

const NameContainer = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledHistoricalAsOfDate = styled.span`
  font-weight: normal;
  color: ${GetColor.GreyScale.Grey80};
`;

const StyledFee = styled.span`
  font-style: italic;
  font-weight: normal;
  max-width: 80px;
`;

export default NameWithOptionalFee;
