import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import PrintContainerDimensions from '../../../print/PrintContainerDimensions';
import TestClassNames from '../TestClassNames';
import { GetColor, Icon, Loading, Body1 } from 'venn-ui-kit';
import { isNil } from 'lodash';
import KeyBlockingNumericInput from '../../../key-blocking-numeric-input/KeyBlockingNumericInput';

const Constants = {
  container: {
    padding: 0,
  },
  deleteButton: {
    paddingLeft: 16,
    width: 13,
  },
  indexSelector: {
    padding: 12,
  },
  valueInput: {
    width: 105,
  },
  picker: {
    marginTop: 32,
    marginBottom: 32,
  },
};

interface ForecastEditRowProps<T> {
  rowItem?: T;
  index?: number;
  baseValue?: number;
  placeholder?: string;
  onRowDelete: (idx: number) => void;
  onValueChange: (idx: number, newValue?: number) => void;
  leftViewProvider: (selectorMaxWidth: number, dropdownWidth: number) => React.ReactNode;
  inputRef: React.RefObject<HTMLInputElement>;
  height?: number;
  itemBorder?: boolean;
  placeholderOnSelected?: boolean;
  significantDecimalDigits?: number;
  inputLoading?: boolean;
  useFixedMargins?: boolean;
  showDelete?: boolean;
  alignRowItems: string;
  inputDisabled?: boolean;
}

export const ForecastEditRow = <T,>({
  rowItem,
  index = 0,
  baseValue,
  placeholder,
  onRowDelete,
  onValueChange,
  leftViewProvider,
  inputRef,
  height = 45,
  itemBorder = false,
  placeholderOnSelected = false,
  significantDecimalDigits = 2,
  inputLoading = false,
  useFixedMargins = false,
  showDelete = true,
  alignRowItems,
  inputDisabled = false,
}: ForecastEditRowProps<T>) => {
  /* Value held by the returns input field */
  const [inputValue, setInputValue] = useState<string>(
    !isNil(baseValue) ? parseFloat((baseValue * 100).toPrecision(15)).toString() : '',
  );

  /* Keeps track of whether the returns input field is currently focused */
  const [isInputFocused, setIsInputFocused] = useState(false);

  /* Determines whether the input (or lack of) in the return input field should be displayed in an error state */
  const [isValueFieldError, setIsValueFieldError] = useState(false);

  /* If a baseValue is provided, pre-populate the returns input field.
   * We set an arbitrary precision of 15 to ensure we do not encounter floating point precision hookups */
  useEffect(() => {
    if (rowItem) {
      setInputValue(!isNil(baseValue) ? parseFloat((baseValue * 100).toPrecision(15)).toString() : '');
    }
  }, [rowItem, baseValue, significantDecimalDigits]);

  /* Determine which placeholder to show on the returns input field. */
  const inputPlaceholder = useMemo(() => {
    if (placeholderOnSelected) {
      if (inputDisabled || isValueFieldError) {
        return '--';
      }
      if (isInputFocused) {
        return placeholder;
      }
      return '';
    }
    return placeholder;
  }, [placeholderOnSelected, placeholder, inputDisabled, isValueFieldError, isInputFocused]);

  /* On focus wrapper for the returns input field */
  const mouseDownAndFocusHandler = () => {
    setIsInputFocused(true);
    setIsValueFieldError(false);
  };

  /** If the input contains valid input upon being blurred, trigger an onValueChange event for the given item.
   *  Otherwise, enter error state. */
  const onCommitInput = useCallback(
    // @ts-expect-error: fixme
    ({ isValid }) => {
      setIsInputFocused(false);
      if (!isValid) {
        onValueChange(index, undefined);
        setIsValueFieldError(true);
        return;
      }
      if (rowItem) {
        const floatValue = Number.parseFloat(inputValue);
        const parsedValue = floatValue / 100;
        onValueChange(index, parsedValue);
      }
    },
    [index, inputValue, onValueChange, rowItem],
  );

  return (
    <PrintContainerDimensions>
      {({ width }) => {
        const selectorMaxWidth =
          width -
          Constants.container.padding * 2 -
          (Constants.deleteButton.width + Constants.deleteButton.paddingLeft) -
          Constants.indexSelector.padding -
          Constants.valueInput.width;
        const dropdownWidth = selectorMaxWidth + Constants.valueInput.width;
        const leftView = leftViewProvider(selectorMaxWidth, dropdownWidth);
        return (
          <Row className={TestClassNames.CMARow} height={height} itemBorder={itemBorder} alignItems={alignRowItems}>
            <SelectContainer useFixedMargins={useFixedMargins}>{leftView}</SelectContainer>

            <ReturnWrapper useFixedMargins={useFixedMargins}>
              {inputLoading ? (
                <Loading title="" />
              ) : (
                <>
                  <InputWrapper>
                    <KeyBlockingNumericInput
                      className={TestClassNames.RowPickerInput}
                      inputRef={inputRef}
                      value={inputValue}
                      placeholder={inputPlaceholder}
                      onFocus={mouseDownAndFocusHandler}
                      onMouseDown={mouseDownAndFocusHandler}
                      onCommitInput={onCommitInput}
                      onChange={(value: string) => setInputValue(value)}
                      height={35}
                      isLocked={inputDisabled}
                      allowNegative
                      isPercentage={false}
                    />
                  </InputWrapper>
                  <StyledPercentageMark>%</StyledPercentageMark>
                </>
              )}
            </ReturnWrapper>
            {showDelete && (
              <DeleteButton
                onMouseDown={() => {
                  onRowDelete(index);
                }}
              >
                <Icon type="trash" prefix="fa" />
              </DeleteButton>
            )}
          </Row>
        );
      }}
    </PrintContainerDimensions>
  );
};

export default ForecastEditRow;

const StyledPercentageMark = styled.div`
  align-self: center;
  color: ${GetColor.HintGrey};
  font-size: 14px;
`;

const InputWrapper = styled.div`
  margin-right: 4px;
`;

const Row = styled(Body1)<{ height: number; itemBorder: boolean; alignItems?: string }>`
  font-weight: bold;
  width: 100%;
  height: ${(props) => props.height}px;
  min-height: ${(props) => props.height}px;
  padding: 0 ${Constants.container.padding}px 0 ${Constants.container.padding}px;
  display: flex;
  align-items: ${(props) => props.alignItems};
  ${(props) =>
    props.itemBorder &&
    css`
      border-bottom: 1px solid ${GetColor.PaleGrey};
    `}
`;

const DeleteButton = styled.button`
  padding: 8px 0px 11px ${Constants.deleteButton.paddingLeft};
  width: ${Constants.deleteButton.width + Constants.deleteButton.paddingLeft}px;
  min-width: ${Constants.deleteButton.width + Constants.deleteButton.paddingLeft}px;
  i {
    font-size: 18px;
    color: ${GetColor.Primary.Dark};
  }
  &:hover,
  &:focus {
    i {
      color: ${GetColor.Primary.Main};
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

const SelectContainer = styled.div<{ useFixedMargins?: boolean }>`
  flex-grow: 1;
  ${({ useFixedMargins }) =>
    useFixedMargins &&
    css`
      margin: ${Constants.picker.marginTop}px 0 ${Constants.picker.marginBottom}px 0;
    `}
  padding-right: ${Constants.indexSelector.padding}px;
`;

const ReturnWrapper = styled.div<{ useFixedMargins?: boolean }>`
  width: 100px;
  ${({ useFixedMargins }) =>
    useFixedMargins &&
    css`
      margin: ${Constants.picker.marginTop}px 0 ${Constants.picker.marginBottom}px 0;
    `}
  display: flex;
`;
