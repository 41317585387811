import type { ErrorWrapper } from './errorWrapperTypes';
import { useRecoilValue } from 'recoil';
import { blockInfoGraphicType, blockMetrics, blockRequestSubjects, blockSettings } from 'venn-state';
import { useSelectedMetrics } from '../../logic/useSelectedMetrics';
import { getAnalysisMetricLevelErrorsAndWarnings } from '../../logic/privatesUtils';
import { isEmpty } from 'lodash';
import { PrivatesSubjectErrors } from '../PrivatesSubjectErrors';
import React from 'react';
import { isPrivatesPerformanceBlock } from 'venn-utils';
import type { PrivateAnalysisSubjectError } from '../PrivatesErrorMessages';
import { usePrivateAnalysis } from '../../logic/usePrivateAnalysis';
import { StyledErrorState } from '../StyledErrorState';

export const PrivateSubjectsErrorWrapper: ErrorWrapper = ({ blockId, children }) => {
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const customBlockType = blockSetting.customBlockType;
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const subjects = useRecoilValue(blockRequestSubjects(blockId));
  const selectedMetrics = useRecoilValue(blockMetrics(blockId));
  const selectedMetricsFull = useSelectedMetrics();

  const { data } = usePrivateAnalysis(blockId);

  // detect block-level critical subject errors in a performance block and report them
  if (isPrivatesPerformanceBlock(customBlockType)) {
    const tsResponse =
      customBlockType === 'PRIVATE_PERFORMANCE_TIME_SERIES'
        ? (data?.performanceTimeSeries ?? [])
        : (data?.performanceSummary ?? []);
    const blockLevelErrors = tsResponse.flatMap((response, index) => {
      const blockLevelError = response.errors?.blockLevel;
      return (blockLevelError ?? [])
        .filter((error) => error.severity === 'ERROR')
        .map((error) => {
          return {
            subject: subjects[index].name,
            error,
          } as PrivateAnalysisSubjectError;
        });
    });

    if (!isEmpty(blockLevelErrors)) {
      return <PrivatesSubjectErrors errors={blockLevelErrors} />;
    }
  }

  // errors for cash flow pacing OR time series block and chart formats supporting only one metric
  if (
    (customBlockType === 'PRIVATE_PERFORMANCE_TIME_SERIES' || customBlockType === 'PRIVATE_CASH_FLOW') &&
    infoGraphicType !== 'GRID'
  ) {
    const metricNames =
      customBlockType === 'PRIVATE_PERFORMANCE_TIME_SERIES'
        ? [selectedMetrics[0]]
        : selectedMetricsFull.map((metric) => metric.analysisResultKey);

    const selectedData =
      (customBlockType === 'PRIVATE_CASH_FLOW' ? data?.cashFlows : data?.performanceTimeSeries) ?? [];
    const metricLevelErrors = getAnalysisMetricLevelErrorsAndWarnings(
      selectedData,
      metricNames,
      subjects,
      customBlockType === 'PRIVATE_CASH_FLOW' ? 0 : undefined,
    );

    if (!isEmpty(metricLevelErrors)) {
      return <PrivatesSubjectErrors errors={metricLevelErrors} />;
    }
  }

  if (customBlockType === 'PRIVATE_CAPITAL_SUMMARY') {
    const hasPrivatePortfolioSubject = subjects.some((subject) => subject.privatePortfolio);
    if (!hasPrivatePortfolioSubject) {
      return <StyledErrorState selectedRefId={blockId} header="Please select a Private Portfolio to analyze" />;
    }
  }

  return children;
};
