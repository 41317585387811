import React, { useCallback } from 'react';
import type { FundProxy, FundToProxy, ProxyPickerWrapperProps } from 'venn-components';
import { Modal, ProxyCell, ProxyPicker, SidePanelOverlay, BulkProxyPicker } from 'venn-components';
import styled from 'styled-components';
import type { SearchResultWithUIState, Id, SearchState } from './types';
import { getFrequency } from './utils';
import { emptyRenderer } from './Shared';
import { useHasFF } from 'venn-utils';

export interface ProxyRenderProps {
  item: SearchResultWithUIState;
  showModal?: boolean;
  onModalClose: () => void;
  setIdWithMenuOpen: (id: Id) => void;
  updateData: React.Dispatch<React.SetStateAction<SearchState>>;
  refetchSearch: (hideLoading?: boolean) => void;
  readOnly?: boolean;
}

const ProxyRenderer = ({
  item,
  setIdWithMenuOpen,
  updateData,
  refetchSearch,
  showModal,
  onModalClose,
  readOnly,
}: ProxyRenderProps) => {
  const hasBulkProxy = useHasFF('portfolio_bulk_proxy_ff');

  const {
    proxyName,
    hasFactorAnalysis,
    proxyId,
    proxyType,
    fundId,
    name,
    proxyCategory,
    investmentSource,
    startRange,
    endRange,
    updated,
  } = item;
  const onProxyChange = useCallback(
    (proxyId: string | undefined, proxyName: string | undefined) => {
      // Show cell loading
      updateData((prev) => {
        const newData = [...prev.results];
        return {
          ...prev,
          results: newData.map((dataItem) => {
            if (dataItem.fundId === fundId) {
              return {
                ...dataItem,
                proxyId,
                proxyName,
                isUpdating: true,
              };
            }
            return dataItem;
          }),
        };
      });
      // Update table without loader
      refetchSearch(true);
      onModalClose?.();
    },
    [updateData, refetchSearch, onModalClose, fundId],
  );

  const saveAndCloseModal = useCallback(
    (saveFn: () => Promise<void>) => async () => {
      await saveFn();
      onModalClose?.();
    },
    [onModalClose],
  );

  const wrapperRenderer = useCallback(
    ({ saveUnsavedChanges, picker }: ProxyPickerWrapperProps) => (
      <StyledModal onClose={saveAndCloseModal(saveUnsavedChanges)} closeOnEsc noPadding>
        {picker}
      </StyledModal>
    ),
    [saveAndCloseModal],
  );

  if (investmentSource === 'CUSTOM') {
    return emptyRenderer();
  }

  const proxy =
    !proxyType || !proxyName
      ? null
      : {
          name: proxyName,
          id: proxyId,
          proxyId,
          proxyType,
          // the library search returns nulls so use a trick to convert it to undefined
          proxyCategory: proxyCategory || undefined,
          extrapolate: item.extrapolated,
          numLags: item.proxyNumLags,
        };

  const investment: FundToProxy = {
    id: fundId,
    name,
    startRange,
    endRange,
    frequency: getFrequency(item.frequency),
    returnFrequency: item.frequency,
    updated,
    userUploaded: item.userUploaded,
    investmentSource: item.investmentSource,
    interpolatable: item.interpolatable,
    hasMinDataForInterpolation: item.hasMinDataForInterpolation,
    unproxiedFrequency: item.unproxiedFrequency,
    unproxiedStartRange: item.unproxiedStartRange,
    unproxiedEndRange: item.unproxiedEndRange,
    proxyNumLags: item.proxyNumLags,
    proxyId: item.proxyId,
    extrapolate: item.extrapolated,
    assetType: item.assetType,
    live: item.live,
    dataSource: item.dataSource,
  };

  if (showModal) {
    return hasBulkProxy ? (
      <SidePanelOverlay.Root
        width={1062}
        margin={0}
        noPadding
        side="right"
        isOpen
        handleClose={onModalClose}
        content={
          <BulkProxyPicker
            investments={[investment]}
            onProxyChange={(appliedProxies) => onProxyChange(appliedProxies[0].proxyId, appliedProxies[0].proxyName)}
          />
        }
        hideTopBackButton
      />
    ) : (
      <ProxyPicker
        wrapperRenderer={wrapperRenderer}
        proxy={proxy}
        investment={investment}
        onProxyChange={(proxy?: FundProxy) => onProxyChange(proxy?.proxyId, proxy?.name)}
        closePicker={onModalClose}
        readOnly={readOnly}
      />
    );
  }

  return (
    // prevent library row clicks and cursor styling from going through
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <ProxyCell
        proxy={proxy}
        investment={investment}
        hoverAddProxy={hasFactorAnalysis}
        onProxyChange={(proxy?: FundProxy) => onProxyChange(proxy?.proxyId, proxy?.name)}
        onOpen={(id: string) => setIdWithMenuOpen({ fundId: id })}
        onClose={() => setIdWithMenuOpen({ fundId: undefined })}
        loading={item.isUpdating}
      />
    </Wrapper>
  );
};

export default ProxyRenderer;

const StyledModal = styled(Modal)`
  &.modal-wrapper {
    width: auto;
  }
`;

const Wrapper = styled.div`
  cursor: auto;
`;
