import { first, isNil, last } from 'lodash';
import moment from 'moment';
import React, { Suspense, useContext } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { CalendarDropdown, UserContext } from 'venn-components';
import { blockSettings, blockAsOfDateSetting, blockLimitedRequestSubjects } from 'venn-state';
import { GetColor, Icon, ShimmerBlock, Tooltip } from 'venn-ui-kit';
import { getSubjectFromRequestSubject, isHistoricalSubjectAsOfDateParameterized } from 'venn-utils';
import { rangeDebugQuery } from '../../../../../../../../venn-state/src/studio/async/rangeDebug';
import { FlexHeader } from '../../../../shared';

const AllocationAsOfDateInputControl = ({ selectedBlockId }: { selectedBlockId: string }) => {
  const { hasPermission } = useContext(UserContext);

  // As of date setting is only used in Portfolio Composition block that supports only 1 subject at a time
  // So we can just work with the first subject and ignore others
  const subject = useRecoilValue(blockLimitedRequestSubjects(selectedBlockId))[0];

  const rangeDebug = useRecoilValue(rangeDebugQuery(getSubjectFromRequestSubject(subject)));

  const [historicalPortfolioAsOfSetting, setHistoricalPortfolioAsOfDateSetting] = useRecoilState(
    blockAsOfDateSetting(selectedBlockId),
  );

  const lastHistoricalAllocationDt = last(rangeDebug?.historicalPortfolioMetadata?.allSortedAllocationDates);
  const firstHistoricalAllocationDt = first(rangeDebug?.historicalPortfolioMetadata?.allSortedAllocationDates);
  const readonly = !hasPermission('STUDIO_EDIT_DATES') || !hasPermission('STUDIO_EDIT');
  const end = !isNil(rangeDebug?.maxEndDate) ? moment.utc(rangeDebug.maxEndDate).toDate() : undefined;
  return (
    <CalendarDropdown
      // we use selectedBlockId as a key to force component reinstantiation when block changes
      key={selectedBlockId}
      start={moment.utc(firstHistoricalAllocationDt).toDate()}
      end={end}
      value={historicalPortfolioAsOfSetting}
      lastUpdated={moment.utc(lastHistoricalAllocationDt).toDate()}
      onChange={setHistoricalPortfolioAsOfDateSetting}
      disabled={readonly}
    />
  );
};
export const AllocationAsOfDateInput = ({ selectedBlockId }: { selectedBlockId: string }) => {
  const subjects = useRecoilValue(blockLimitedRequestSubjects(selectedBlockId));
  const blockSetting = useRecoilValue(blockSettings(selectedBlockId));
  const isHistoricProFormaOnlyBlock =
    subjects.length > 0 && isHistoricalSubjectAsOfDateParameterized(subjects, blockSetting.customBlockType);

  return (
    isHistoricProFormaOnlyBlock && (
      <div data-testid="qa-block-date-range">
        <FlexHeader style={{ marginBottom: '12px' }}>
          Allocation As of Date
          <Tooltip
            content={
              <TooltipContent>
                Add a historical portfolio with historical allocations in order to set an as of date.
              </TooltipContent>
            }
            usePortal
          >
            <InfoIcon type="info-circle" prefix="far" />
          </Tooltip>
        </FlexHeader>
        <Suspense fallback={<ShimmerBlock height={32} width="210px" />}>
          <AllocationAsOfDateInputControl selectedBlockId={selectedBlockId} />
        </Suspense>
      </div>
    )
  );
};

const InfoIcon = styled(Icon)`
  color: ${GetColor.HintGrey};
  margin-left: 4px;
  font-size: 14px;
`;

const TooltipContent = styled.div`
  width: 236px;
  padding: 5px;
`;
