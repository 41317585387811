import React, { useContext } from 'react';
import { debounce, compact } from 'lodash';
import type { SharedProps, AnalysisTrackingProps } from '../types';
import { TrackSuccessfulAnalysis, DownloadableContentBlock, exportToExcel, AnalysisViewContext } from 'venn-components';
import type { FrequencyEnum } from 'venn-api';

import { ThemeContext } from 'styled-components';
import NotablePeriodChart from './NotablePeriodsChart';
import { Numbers, getFirstNegativeDay } from 'venn-utils';
import { getColumnsForHistoricalDrawdownAndRallyScanner } from '../logic/notablePeriodsColumns';
import { useNotablePeriodChart } from '../logic/useNotablePeriodChart';
import { HISTORICAL_DRAWDOWN_FAQ_HREF } from 'venn-ui-kit';

export interface NotablePeriodAnalysisProps extends SharedProps, AnalysisTrackingProps {
  analysisFrequency?: FrequencyEnum;
}

const HistoricalDrawdownAndRallyScanner: React.FunctionComponent<
  React.PropsWithChildren<NotablePeriodAnalysisProps>
> = ({ analysisConfig, analyses, trackingProps, downloadMetaData, analysisFrequency }) => {
  const title = analyses?.relative
    ? 'Historical Drawdown and Rally Scanner Relative to Benchmark'
    : 'Historical Drawdown and Rally Scanner';
  const subtitle = analyses?.relative
    ? 'Historical periods where drawdowns and rallies in excess of a minimum threshold were experienced, relative to benchmark.'
    : 'Historical periods where drawdowns and rallies in excess of a minimum threshold were experienced.';

  const { notablePeriodsThreshold } = useContext(AnalysisViewContext);
  const { Colors } = useContext(ThemeContext);

  const { subject, hasBenchmark, hasComparison, hasCategory, labels, onUpdateAnalysisViewParam, chartData } =
    useNotablePeriodChart({
      analysisConfig,
      analyses,
    });

  const allNotablePeriods = chartData.filter((datum) => datum.type !== 'PREDEFINED');

  const max = Math.max(
    ...[
      ...compact(allNotablePeriods.map((datum) => datum?.subject)),
      ...compact(allNotablePeriods.map((datum) => datum?.benchmark)),
      ...compact(allNotablePeriods.map((datum) => datum?.category)),
    ].map(Math.abs),
    0,
  );

  const setThreshold = (newThreshold: number) => onUpdateAnalysisViewParam({ notablePeriodsThreshold: newThreshold });

  const vennIdentifiedPeriodData = allNotablePeriods
    .filter((datum) => Math.abs(datum.subject) >= notablePeriodsThreshold)
    .map(
      // Shift rally and drawdown dates by 1 because of the way we account for them in price land in the BE
      (datum) =>
        (datum.type === 'DRAWDOWN' || datum.type === 'RALLY') && datum.start
          ? { ...datum, start: getFirstNegativeDay(datum.start).valueOf() }
          : datum,
    );

  const nonEditableColumns = getColumnsForHistoricalDrawdownAndRallyScanner({
    labels,
    max,
    hasBenchmark,
    hasComparison,
    hasCategory,
    Colors,
    analysisFrequency,
  });

  return (
    <TrackSuccessfulAnalysis {...trackingProps}>
      <DownloadableContentBlock
        header={title}
        subHeader={
          <div>
            {subtitle} Visit the{' '}
            <a
              style={{ fontWeight: 'bold', cursor: 'pointer' }}
              target="_blank"
              rel="noopener noreferrer"
              href={HISTORICAL_DRAWDOWN_FAQ_HREF}
            >
              FAQ
            </a>{' '}
            to learn more.
          </div>
        }
        downloadable={{
          png: true,
          excel: exportToExcel(
            vennIdentifiedPeriodData,
            nonEditableColumns.filter((x) => x.label!.length > 0),
          ),
          options: {
            fileName: `${subject?.name || ''} - ${title}`,
            metaData: {
              ...downloadMetaData!,
              extra: [
                {
                  value: Numbers.safeFormatPercentage(notablePeriodsThreshold / 100, 1),
                  label: 'Notable Periods Threshold',
                },
              ],
            },
          },
          tracking: {
            subjectType: subject?.type,
            subjectId: subject?.id,
            description: 'NOTABLE_PERIODS',
            relativeToBenchmark: false,
            userUploaded: subject?.fund?.userUploaded || false,
          },
        }}
      >
        <NotablePeriodChart
          chartData={vennIdentifiedPeriodData}
          columns={nonEditableColumns}
          hasBenchmark={hasBenchmark}
          hasComparison={hasComparison}
          hasCategory={hasCategory}
          labels={labels}
          max={max}
          filter={{ threshold: notablePeriodsThreshold, setThreshold: debounce(setThreshold, 400) }}
          filterAbove
          emptyHeader={`${
            allNotablePeriods.length > 0
              ? 'No notable periods were found at the current threshold.'
              : 'There are no notable periods available.'
          }`}
          emptyMessage={`${
            allNotablePeriods.length > 0
              ? 'Try lowering the threshold to view additional periods.'
              : 'Try selecting a different investment or portfolio to view additional periods.'
          }`}
        />
      </DownloadableContentBlock>
    </TrackSuccessfulAnalysis>
  );
};

export default HistoricalDrawdownAndRallyScanner;
