import React from 'react';
import styled from 'styled-components';
import { assertExhaustive } from 'venn-utils';
import { type BarFillType, rangeBarBackground } from './columns/RangeCellRenderer';

type Shape = 'line' | 'capped line';
type LegendSwatchProps = {
  color: string;
  fillType: BarFillType;
  shape: Shape;
  size: 'small' | 'medium' | 'large';
};

export const LegendSwatch = ({ color, fillType, shape, size }: LegendSwatchProps) => {
  const heightPx = (() => {
    switch (size) {
      case 'small':
        return 2;
      case 'medium':
        return 4;
      case 'large':
        return 16;
      default:
        return assertExhaustive(size);
    }
  })();
  switch (shape) {
    case 'line':
      return <InternalLegendSwatch className="min-w-[20px]" color={color} fillType={fillType} height={heightPx} />;
    case 'capped line':
      return (
        <div className="flex items-center">
          <InternalLegendSwatch
            className="grow-[18] min-w-[18px]"
            color={color}
            fillType={fillType}
            height={heightPx}
          />
          <InternalLegendSwatch
            className="grow-[2] min-w-[2px]"
            color={color}
            fillType={fillType}
            height={heightPx * 5}
          />
        </div>
      );
    default:
      return assertExhaustive(shape);
  }
};

const InternalLegendSwatch = styled.div<{
  height: number;
  color: string;
  fillType: BarFillType;
}>`
  ${rangeBarBackground}

  height: ${({ height }) => height}px;
`;
