import React, { useState, useContext, useMemo, useCallback } from 'react';
import type { BasicTableColumn, SORTDIR } from 'venn-components';
import { PortfoliosContext } from 'venn-components';
import isEqual from 'lodash/isEqual';
import type { SearchResultWithUIState, Id, SearchState } from './types';
import { StyledTable } from './StyledComponents';
import { getRowStyle } from './ColumnRenderers';
import { toggleItemSelected } from './utils';
import { css, ThemeContext } from 'styled-components';
import { getColumns } from './Columns';
import { getPeriodMetrics } from './logic/librariesTable';
import type { MetricFilter } from 'venn-api';

interface LibrariesTableProps {
  data: SearchResultWithUIState[];
  sortDir: SORTDIR.ASC | SORTDIR.DESC;
  sortKey?: string;
  onSort: (key: string, dir: SORTDIR) => void;
  updateData: React.Dispatch<React.SetStateAction<SearchState>>;
  refetchSearch: (hideLoading?: boolean) => Promise<void>;
  disableSort?: boolean;
  trackSelectionMade: (item: SearchResultWithUIState) => void;
  findSimilar: (item: SearchResultWithUIState) => void;
  showAssetType?: boolean;
  showMorningstarCategory?: boolean;
  metricFilters?: MetricFilter[];
}

const LibrariesTable = ({
  updateData,
  sortKey,
  sortDir,
  disableSort,
  refetchSearch,
  data,
  onSort,
  trackSelectionMade,
  findSimilar,
  showAssetType,
  showMorningstarCategory,
  metricFilters = [],
}: LibrariesTableProps) => {
  const [idWithMenuOpen, setIdWithMenuOpen] = useState<Id>({ fundId: undefined, portfolioId: undefined });
  const portfoliosContext = useContext(PortfoliosContext);
  const theme = useContext(ThemeContext);
  const menuOpen = !!(idWithMenuOpen.fundId || idWithMenuOpen.portfolioId);

  const selectRowOnClick = useCallback(
    (
      e: React.MouseEvent<HTMLTableRowElement, MouseEvent> | React.KeyboardEvent<HTMLTableRowElement>,
      item: SearchResultWithUIState,
    ) => {
      // prevent clicks from modals or menus from going through
      if (!e.currentTarget.contains(e.target as Node) || menuOpen) {
        return;
      }

      toggleItemSelected(item, updateData);
    },
    [menuOpen, updateData],
  );

  const columns: BasicTableColumn<SearchResultWithUIState>[] = useMemo(() => {
    const max = Date.now();
    const min = Date.now() - 1000 * 60 * 60 * 24 * 365 * 10;

    const dynamicColumns = getPeriodMetrics({ metricFilters });

    return getColumns(
      updateData,
      sortDir,
      refetchSearch,
      portfoliosContext,
      max,
      min,
      setIdWithMenuOpen,
      trackSelectionMade,
      findSimilar,
      disableSort,
      sortKey,
      showAssetType,
      showMorningstarCategory,
      dynamicColumns,
    );
  }, [
    metricFilters,
    updateData,
    sortDir,
    refetchSearch,
    portfoliosContext,
    trackSelectionMade,
    findSimilar,
    disableSort,
    sortKey,
    showAssetType,
    showMorningstarCategory,
  ]);

  return (
    <StyledTable
      columns={columns}
      data={data}
      onSort={onSort}
      sortDir={sortDir}
      rowStyle={(item) => css`
        cursor: pointer;
        ${getRowStyle(item, idWithMenuOpen.fundId ?? idWithMenuOpen.portfolioId, theme)}
      `}
      selectedFundActive={menuOpen}
      sortKey={sortKey}
      sortingIsExternal
      onRowClick={selectRowOnClick}
    />
  );
};

const areEqual = (prevProps: LibrariesTableProps, nextProps: LibrariesTableProps) =>
  isEqual(prevProps.data, nextProps.data) &&
  prevProps.sortDir === nextProps.sortDir &&
  prevProps.sortKey === nextProps.sortKey &&
  prevProps.disableSort === nextProps.disableSort &&
  isEqual(prevProps.metricFilters, nextProps.metricFilters);

export default React.memo(LibrariesTable, areEqual);
