import { isNil, negate, zipObject } from 'lodash';
import type {
  AnalysisRequest,
  Message,
  RangeAnalysisResponse,
  RangeDebugResponse,
  FrequencyEnum,
  SubjectRangeAnalysis,
} from 'venn-api';
import { analysis } from 'venn-api';
import { type AnalysisSubject, createInvestmentRangeAnalysisKey, type UseQueryOptions } from 'venn-utils';
import { VennQueryClient, createRangeAnalysisKey, getAnalysisRequest, useQuery } from 'venn-utils';
import { create, windowScheduler, keyResolver } from '@yornaath/batshit';
import { useQueries } from '@tanstack/react-query';

type QueryOptions = {
  enabled?: boolean;
  suspense?: boolean;
};

export interface RangeDebugResult {
  response: RangeDebugResponse | null;
  subjectError: Message | null;
}

export interface RangeDebugGroup {
  primary: RangeDebugResult;
  secondary?: RangeDebugResult;
  benchmark?: RangeDebugResult;
  master?: RangeDebugResult;
  maxStartTime: number;
  maxEndTime: number;
  maxFrequency: FrequencyEnum;
}

export interface UseRangeAnalysisReturn {
  /** If the initial fetch is occurring. */
  loading: boolean;
  /** If any fetch is occuring. */
  isFetching: boolean;
  rangeAnalysis?: RangeAnalysisResponse;
  analysisRequest?: Partial<AnalysisRequest>;
}

export const prefetchRangeQuery = (subject: AnalysisSubject | undefined) => {
  return VennQueryClient.getInstance().prefetchQuery(createQuery(subject));
};

export const useRangeAnalysisQuery = (
  subject: AnalysisSubject | undefined,
  options: QueryOptions = {},
): UseRangeAnalysisReturn => {
  const { data, isLoading, isFetching } = useQuery(createQuery(subject, options));

  return {
    loading: isLoading,
    isFetching,
    rangeAnalysis: data?.analysisResponse,
    analysisRequest: data?.analysisRequest,
  };
};

function createQuery(
  subject: AnalysisSubject | undefined,
  { suspense = false, enabled = true }: QueryOptions = {},
): UseQueryOptions<{
  analysisRequest: Partial<AnalysisRequest> | undefined;
  analysisResponse: RangeAnalysisResponse | undefined;
} | null> {
  return {
    staleTime: 5 * 60 * 1000,
    queryKey: createRangeAnalysisKey(subject),
    suspense,
    enabled,
    queryFn: async ({ signal }) => {
      if (!subject || subject.private) {
        return null;
      }

      const analysisRequest = getAnalysisRequest(['RANGE_ANALYSIS'], subject, {}, false, true);
      const response = await analysis(analysisRequest, signal);
      const analysisResponse = response.content.analyses.map((a) => a.rangeAnalysis).find(negate(isNil));
      return { analysisRequest, analysisResponse };
    },
  };
}

export const useRangeAnalysisQueryBatched = (fundIds: string[], options: QueryOptions = {}) => {
  const results = useQueries({
    queries: fundIds.map((id) => createSingleRangeQuery(id, options)),
  });
  return zipObject(
    fundIds,
    results.map(({ data }) => data),
  );
};

const ranges = create({
  fetcher: async (ids: string[]) => {
    const response = await analysis({
      analyses: [{ analysisType: 'RANGE_ANALYSIS' }],
      subjects: ids.map((id) => ({
        id,
        subjectType: 'INVESTMENT',
        primary: true,
        comparisonType: 'PRIMARY',
        isPrivate: false,
      })),
    });
    const analysisResponse = response.content.analyses.map((a) => a.rangeAnalysis).find(negate(isNil));
    return analysisResponse?.rangeAnalyses ?? [];
  },
  resolver: keyResolver('investmentId'),
  scheduler: windowScheduler(10),
});

function createSingleRangeQuery(
  fundId: string,
  { suspense = true, enabled = true }: QueryOptions = {},
): UseQueryOptions<SubjectRangeAnalysis> {
  return {
    staleTime: 5 * 60 * 1000,
    queryKey: createInvestmentRangeAnalysisKey(fundId),
    queryFn: async () => ranges.fetch(fundId),
    suspense,
    enabled,
  };
}
