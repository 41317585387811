import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import Icon from '../icon/Icon';
import { GetColor } from '../../style/color';
import KeyCodes from '../../KeyCode';

interface EditableTextProps {
  value?: string; // Setting a value will make this a controlled component.
  initialValue?: string; // Use initial value to set an initial value and but leave the component uncontrolled.
  onSubmit?: (text: string) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  onBlur?: () => void;
  error?: boolean;
  iconSize?: number;
  inputClassName?: string;
}

const EditableText: React.FC<React.PropsWithChildren<EditableTextProps>> = ({
  value,
  initialValue = '',
  disabled = false,
  onChange,
  onSubmit,
  onBlur,
  error,
  iconSize,
  inputClassName,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [innerValue, setInnerValue] = useState(value ?? initialValue);

  useLayoutEffect(() => setInnerValue(value ?? initialValue), [initialValue, value]);

  useLayoutEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing, inputRef]);

  const handleSubmit = useCallback(() => {
    setIsEditing(false);
    onSubmit?.(value ?? innerValue);
  }, [innerValue, onSubmit, value]);

  if (!isEditing) {
    return (
      <Wrapper
        data-testid="qa-editable-text-wrapper"
        iconSuper={!iconSize}
        className={`${innerValue ? 'qa-subject-name' : ''}`}
      >
        {value ?? innerValue}{' '}
        {!disabled && <StyledIcon type="edit" onClick={() => setIsEditing(true)} iconSize={iconSize} />}
      </Wrapper>
    );
  }
  return (
    <Wrapper data-testid="qa-editable-text-wrapper">
      <StyledInput
        data-testid="qa-editable-text-input"
        className={inputClassName}
        ref={inputRef}
        value={value ?? innerValue}
        error={error}
        onChange={(e) => {
          onChange?.(e);
          setInnerValue(e.target.value);
        }}
        onBlur={onBlur ?? handleSubmit}
        onKeyDown={(e) => {
          if (e.keyCode === KeyCodes.Enter) {
            handleSubmit();
          }
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.span<{ iconSuper?: boolean }>`
  ${({ iconSuper }) =>
    iconSuper
      ? css`
          .button-icon {
            display: inline;
            vertical-align: super;
            line-height: 0;
          }
        `
      : css`
          display: inline;

          .button-icon {
            display: inline;
            margin-left: 4px;
          }
        `}
`;

const StyledInput = styled.input<{ error?: boolean }>`
  padding: 2px 10px;

  &:focus {
    ${({ error, theme }) => error && `border-color: ${theme.Colors.Error}`};
  }
`;

const StyledIcon = styled(Icon)<{ iconSize?: number }>`
  color: ${GetColor.Primary.Dark};
  font-size: ${({ iconSize }) => (iconSize ? `${iconSize}px` : '50%')};
  &:hover {
    color: ${GetColor.Primary.Main};
  }
`;

export default EditableText;
