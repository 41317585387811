import React from 'react';
import styled, { css } from 'styled-components';
import type { DropMenuItem, MenuCategory } from '../types';
import type { SimpleMenuProps } from './SimpleMenu';
import { Container, Items } from './BaseMenu';
import NoItem from './NoItem';
import { GetColor } from '../../../style/color';
import { Icon } from '../../icon/Icon';

export interface CategorizedMenuProps<TValue = string, TItem = DropMenuItem<TValue>>
  extends Omit<SimpleMenuProps<TValue>, 'items'> {
  categories: MenuCategory<TValue, TItem>[];
  headerComponent?: JSX.Element;
  footerComponent?: JSX.Element;
  hideSeparator?: boolean;
  refreshedStyling?: boolean;
}

interface CategorizedMenuPropsInner<TValue, TItem> extends CategorizedMenuProps<TValue, TItem> {
  renderItem: (item: TItem, category: MenuCategory<TValue, TItem>, index: number) => JSX.Element;
}

export const CategorizedMenu = <TValue, TItem = DropMenuItem<TValue>>({
  categories,
  width,
  height,
  renderItem,
  headerComponent,
  footerComponent,
  className,
  hideSeparator,
  refreshedStyling,
}: CategorizedMenuPropsInner<TValue, TItem>) => {
  return (
    <Container width={width} height={height} tabIndex={-1} className={className}>
      {headerComponent}
      {categories && categories.length > 0 ? (
        <Items>
          {categories.map((category) => (
            <Category hideSeparator={hideSeparator} key={category.name} refreshedStyling={refreshedStyling}>
              <CategoryName refreshedStyling={refreshedStyling}>
                {category.name}{' '}
                {category.disabled && (
                  <DisabledHint>
                    <Icon type="exclamation-circle" />
                    {category.disabledMessage}
                  </DisabledHint>
                )}
              </CategoryName>
              {(category.items || []).map((item, index) => renderItem(item, category, index))}
            </Category>
          ))}
        </Items>
      ) : (
        <NoItem>(No results found)</NoItem>
      )}
      {footerComponent}
    </Container>
  );
};

export default CategorizedMenu;

const Category = styled.div<{ hideSeparator?: boolean; refreshedStyling?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ hideSeparator, refreshedStyling }) =>
    !hideSeparator
      ? css`
          &:not(:last-child) {
            padding-bottom: ${refreshedStyling ? '16px' : '19px'};
            border-bottom: 1px solid ${GetColor.PaleGrey};
          }
        `
      : ''}
  ${({ refreshedStyling }) =>
    refreshedStyling
      ? css`
          &:not(:first-child) {
            padding-top: 16px;
          }
        `
      : ''}
`;

const CategoryName = styled.div<{ refreshedStyling?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  padding: 0 ${({ refreshedStyling }) => (refreshedStyling ? '12px' : '20px')};
  line-height: 2;
`;

const DisabledHint = styled.div`
  color: ${GetColor.HighlightDark};
  font-size: 10px;

  i {
    margin-right: 4px;
  }
`;
