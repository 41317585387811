import type { ColDef } from 'ag-grid-community';
import type { BulkManageRow } from '../types';
import { formatDate } from '../../manage-data/utils';
import { bulkManageSortFunction } from './bulkManageSortFunction';
import { isBulkManageInvestmentRow, isHistoricalBulkManageInvestmentRow, isBulkManageStrategyRow } from '../utils';

const DATE_COLUMN_WIDTH = 115;

export const startDateColDef = (isHistorical: boolean): ColDef<BulkManageRow, number | undefined> => ({
  headerName: isHistorical ? 'Analysis Start Date' : 'Start Date',
  valueGetter: ({ data }) => {
    if (isBulkManageInvestmentRow(data)) {
      return isHistoricalBulkManageInvestmentRow(data) ? data.historicalStartDate : data.startDate;
    }
    return undefined;
  },
  valueFormatter: ({ data, value }) => (isBulkManageStrategyRow(data) ? '' : (formatDate(value) ?? '')),
  width: DATE_COLUMN_WIDTH,
  comparator: bulkManageSortFunction(),
  aggFunc: 'max',
});

export const endDateColDef = (isHistorical: boolean): ColDef<BulkManageRow, number | undefined> => ({
  headerName: isHistorical ? 'Analysis End Date' : 'End Date',
  valueGetter: ({ data }) => {
    if (isBulkManageInvestmentRow(data)) {
      return isHistoricalBulkManageInvestmentRow(data) ? data.historicalEndDate : data.endDate;
    }
    return undefined;
  },
  valueFormatter: ({ data, value }) => (isBulkManageStrategyRow(data) ? '' : (formatDate(value) ?? '')),
  width: DATE_COLUMN_WIDTH,
  comparator: bulkManageSortFunction(),
  aggFunc: 'min',
});
