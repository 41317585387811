import React, { useContext, useMemo, useState } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import Select, {
  type StylesConfig,
  type ActionMeta,
  type FormatOptionLabelMeta,
  type OnChangeValue,
  type SingleValue,
  type CSSObjectWithLabel,
  type Props,
} from 'react-select';

import type { VennColors } from 'venn-ui-kit';
import { ColorUtils, ZIndex } from 'venn-ui-kit';
import type { StylingProps } from './styles';
import {
  FormFieldBackground,
  Container,
  getInputStyles,
  getLabelStyles,
  leftRightPadding,
  hoverColor,
  RequiredSymbol,
} from './styles';
import InfoIcon from './InfoIcon';
import type { BaseFormFieldProps } from './types';
import SubText from './SubText';

type FilterOptionOption<T> = Parameters<NonNullable<Props<T>['filterOption']>>[0];
export interface FormSelectProps<T = unknown> extends BaseFormFieldProps {
  /** Current option selected */
  value: T | null;
  /** Options from which to select */
  options: T[];
  /** Required if each option is not a string or if the value is not option.value */
  getOptionValue?: (option: T) => string;
  /** Function that returns true if option should show up as a search result given input */
  filterOption?: (option: FilterOptionOption<T>, input: string) => boolean;
  /** Function for custom rendering of an option in the options menu or a selected option */
  formatOptionLabel?: (option: T, meta: FormatOptionLabelMeta<T>) => React.ReactNode;
  /** Function to call every time an option is selected */
  onChange?: (option: SingleValue<T>) => void;
  /** Whether searching for an option should be disabled or not */
  disableSearch?: boolean;
}

const FormSelect = <T,>(props: FormSelectProps<T>) => {
  const {
    disabled,
    label,
    errorHidden,
    error,
    hint,
    infoIconText,
    onChange,
    onFocus,
    onBlur,
    disableSearch = false,
    value,
    required,
  } = props;
  const { Colors } = useContext(ThemeContext);
  const [focused, setFocused] = useState(false);
  const styles: StylesConfig<T, false> = useMemo(() => getReactSelectStyles(Colors), [Colors]);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    onFocus && onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    onBlur && onBlur(e);
  };

  const handleChange = (option: OnChangeValue<T, false>, meta: ActionMeta<T>) => {
    if (meta.action === 'select-option') {
      setFocused(false);
    }
    onChange && onChange(option);
  };

  const stylingProps = { hasValue: !!value, hasError: !!error, hasLabel: !!label, focused, disabled, disableSearch };

  return (
    <Container>
      <SelectContainer {...stylingProps}>
        <Select
          {...props}
          isMulti={false}
          placeholder=""
          isSearchable={!disableSearch}
          classNamePrefix="select"
          blurInputOnSelect
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          styles={styles}
          isDisabled={disabled}
        />
        <Label {...stylingProps} htmlFor={props.inputId} data-testid="form-select-label">
          {label}
          {required && <RequiredSymbol>*</RequiredSymbol>}
        </Label>
      </SelectContainer>
      <SubText errorHidden={errorHidden} error={error} hint={hint} />
      <InfoIcon text={infoIconText} />
    </Container>
  );
};

export default FormSelect;

const Label = styled.label<StylingProps & { disableSearch: boolean }>`
  ${({ hasValue, hasError, focused, disableSearch, disabled }) =>
    getLabelStyles({
      // treat an active/focused search as having a value when styling
      hasValue: hasValue || (!disableSearch && !!focused),
      hasError,
      focused,
      disabled,
    })}
`;

const SelectContainer = styled.div<StylingProps & { disableSearch: boolean }>`
  background-color: ${FormFieldBackground};
  width: 100%;

  ${({ focused, hasError }) =>
    !focused &&
    !hasError &&
    css`
      :hover label {
        color: ${hoverColor};
      }
    `}
  .select__control {
    cursor: pointer;
    ${(props) => getInputStyles(props)}
  }

  .select__input {
    & input,
    & div {
      font: inherit;
    }
  }
`;

const getReactSelectStyles = (colors: VennColors) => ({
  container: (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...base,
    pointerEvents: 'unset',
  }),
  control: (): CSSObjectWithLabel => ({}),
  input: (): CSSObjectWithLabel => ({
    position: 'absolute',
  }),
  // @ts-expect-error: fixme
  valueContainer: (base, props): CSSObjectWithLabel => ({
    display: 'flex',
    paddingBottom: props.hasValue ? 3 : 0,
  }),
  dropdownIndicator: (): CSSObjectWithLabel => ({
    position: 'absolute',
    right: '12px',
    bottom: '5px',
  }),
  indicatorSeparator: (): CSSObjectWithLabel => ({
    margin: 0,
  }),
  // @ts-expect-error: fixme
  option: (base: CSSObjectWithLabel, props): CSSObjectWithLabel => ({
    ...base,
    backgroundColor: props.isFocused ? colors.DarkGrey : 'inherit',
    cursor: 'pointer',
    padding: `10px ${leftRightPadding}px`,
  }),
  singleValue: () => ({}),
  menu: (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...base,
    backgroundColor: ColorUtils.hex2rgba(colors.Black, 0.65),
    border: `1px solid ${colors.Primary.Main}`,
    borderRadius: '0px',
    fontSize: 14,
    color: colors.White,
    marginTop: '-1px',
    paddingTop: '-1px',
    zIndex: ZIndex.Front,
  }),
  menuList: (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...base,
    backgroundColor: ColorUtils.hex2rgba(colors.Black, 0.65),
  }),
});
