import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  BlockOuterContainer,
  HoldingsBlock,
  NotableHistoricalBlock,
  PerformanceAndRiskBlock,
  PortfolioBreakdownBlock,
  PrivateAssetGrowthSimulationBlock,
  PrivateAssetSummaryBlock,
  PrivateCashFlowBlock,
  PrivatePerformanceSummaryBlock,
  PrivateTimeSeriesBlock,
} from 'venn-components';
import { blockDisplayHeader, type BlockId, blockSettings } from 'venn-state';
import { assertExhaustive, type CustomBlockTypeEnum, useHasFF } from 'venn-utils';
import { HistoricalAllocationsTimeSeriesBlock } from '../../../../../venn-components/src/blocks/custom-blocks/historical-allocations-timeseries-block';
import { type BlockWidths, useComputeBlockWidth } from './components/core/useComputeBlockWidth';
import { DeprecatedBlock } from './DeprecatedBlock';

type BlockSwitchProps = {
  id: string;
  index: number;
  pageFooterRef?: React.RefObject<HTMLElement>;
} & BlockWidths;

const NewBlockContainer = (
  props: BlockSwitchProps & {
    blockType: NewBlockType;
  },
) => {
  const { id, pageFooterRef } = props;
  const title = useRecoilValue(blockDisplayHeader(id));
  const { blockWidthPx, blockWidthStyle } = useComputeBlockWidth(props);
  const NewBlock = getNewBlockComponent(props.blockType);

  return (
    <BlockOuterContainer
      id={id}
      title={title}
      width={blockWidthPx}
      widthStyle={blockWidthStyle}
      pageFooterRef={pageFooterRef}
    >
      <NewBlock id={id} />
    </BlockOuterContainer>
  );
};

export const BlockSwitch = (props: BlockSwitchProps) => {
  const hasNewBlockArchitecture = useHasFF('new_block_architecture_ff');
  const blockSetting = useRecoilValue(blockSettings(props.id));
  const customBlockType = blockSetting.customBlockType;

  if (supportsNewBlock(customBlockType, hasNewBlockArchitecture)) {
    return <NewBlockContainer {...props} blockType={customBlockType} />;
  }

  return <DeprecatedBlock {...props} />;
};

const hasNewBlock = [
  'NOTABLE_PERIODS',
  'PERFORMANCE_SUMMARY',
  'PRIVATE_CASH_FLOW',
  'PRIVATE_CAPITAL_SUMMARY',
  'PORTFOLIO_BREAKDOWN',
  'PRIVATE_PERFORMANCE_TIME_SERIES',
  'PRIVATE_PERFORMANCE_SUMMARY',
  'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES',
  'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN',
  'ASSET_EXPOSURE',
  'SECTOR_EXPOSURE',
  'GEOGRAPHY_EXPOSURE',
  'HISTORICAL_ALLOCATIONS_TIMESERIES',
] as const satisfies CustomBlockTypeEnum[];
type NewBlockType = (typeof hasNewBlock)[number];

export function supportsNewBlock(
  customBlockType: CustomBlockTypeEnum,
  hasNewBlockArchitecture: boolean,
): customBlockType is NewBlockType {
  return hasNewBlockArchitecture && hasNewBlock.includes(customBlockType as NewBlockType);
}

/** You can use {@link supportsNewBlock} to check if a particular {@link CustomBlockTypeEnum} supports new block architecture, before calling this method. */
export function getNewBlockComponent(newBlockType: NewBlockType): React.FC<{ id: BlockId }> {
  switch (newBlockType) {
    case 'NOTABLE_PERIODS':
      return NotableHistoricalBlock;
    case 'PERFORMANCE_SUMMARY':
      return PerformanceAndRiskBlock;
    case 'PRIVATE_CASH_FLOW':
      return PrivateCashFlowBlock;
    case 'PORTFOLIO_BREAKDOWN':
      return PortfolioBreakdownBlock;
    case 'PRIVATE_CAPITAL_SUMMARY':
      return PrivateAssetSummaryBlock;
    case 'PRIVATE_PERFORMANCE_TIME_SERIES':
      return PrivateTimeSeriesBlock;
    case 'PRIVATE_PERFORMANCE_SUMMARY':
      return PrivatePerformanceSummaryBlock;
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES':
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN':
      return PrivateAssetGrowthSimulationBlock;
    case 'ASSET_EXPOSURE':
    case 'SECTOR_EXPOSURE':
    case 'GEOGRAPHY_EXPOSURE':
      return HoldingsBlock;
    case 'HISTORICAL_ALLOCATIONS_TIMESERIES':
      return HistoricalAllocationsTimeSeriesBlock;
    default:
      throw assertExhaustive(newBlockType);
  }
}
