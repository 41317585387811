import type { FC } from 'react';
import React from 'react';
import PrintContainerDimensions from '../print/PrintContainerDimensions';
import { Button, Icon } from 'venn-ui-kit';

import { cn } from '@/lib/utils';

export interface BulkEmptyStateProps {
  header: string;
  message?: string | JSX.Element[] | JSX.Element;
  className?: string;
  actionLabel?: string;
  onAction?: () => void;
  noBorder?: boolean;
  small?: boolean;
}

const isSmall = (width: number) => width <= 600;

export const blockEmptyStateClass = 'block-empty-state';

const BulkEmptyState: FC<React.PropsWithChildren<BulkEmptyStateProps>> = ({
  header,
  message,
  className,
  actionLabel,
  onAction,
  children,
  noBorder,
  small,
}) => {
  return (
    <PrintContainerDimensions>
      {({ width }) => (
        <div
          className={cn(
            className,
            blockEmptyStateClass,
            'relative flex flex-col items-center justify-center max-w-full flex-1',
            {
              'border-2 border-solid border-venn-pale-grey rounded-2xl p-24': !noBorder,
              'p-5': noBorder || isSmall(width) || small,
            },
          )}
        >
          <div className="flex flex-col items-center justify-center gap-6 max-w-5xl text-center">
            <div className="flex items-center gap-2 text-[20px]">
              <Icon type="circle-exclamation" className="size-7 text-venn-error" />
              <span className="text-venn-black font-medium">{header}</span>
            </div>
            {/* TODO(houssein): Arbitrary value to overwrite text color to match newer designs. We should refactor this when consolidating all empty states */}
            {message && <div className="text-[16px] [&>div]:text-venn-black">{message}</div>}
            {actionLabel ? (
              <Button className="print:hidden" refreshedStyling dominant onClick={onAction}>
                {actionLabel}
              </Button>
            ) : null}
            {children}
          </div>
        </div>
      )}
    </PrintContainerDimensions>
  );
};

export default BulkEmptyState;
