import { assertNotNil } from 'venn-utils';
import { SomethingWentWrongFallbackComponent } from '../../error-boundary/SomethingWentWrongFallbackComponent';
import React from 'react';
import SidePanelOverlay from '../../side-panel-overlay/SidePanelOverlay';
import { BulkProxySection } from '../BulkProxySection';
import styled from 'styled-components';
import EmptyState from '../../empty-state/EmptyState';
import type { FundToBulkProxy, FundToProxy } from '../../modals/pickers/types';
import { BulkProxyPicker, type ProxyMetaWithName } from '../../modals/pickers/proxy-picker/BulkProxyPicker';
import type { DetailedProxyMetadata, RangeAnalysisResponse, SimpleFund, SubjectRangeAnalysis } from 'venn-api';
import { withErrorBoundary } from '../../error-boundary/CustomizableErrorBoundary';

interface BulkManagementTableRootProps {
  isPickerOpen: boolean;
  onPickerClose: () => void;
  openedSecondaryId: string | undefined;
  uniqFunds: SimpleFund[];
  selectedFunds: SimpleFund[];
  onProxyChange:
    | ((appliedProxies: ProxyMetaWithName[], failedToProxyFunds: (FundToProxy | FundToBulkProxy)[]) => void)
    | undefined;
  disableSelection: boolean;
  rangeAnalysis: RangeAnalysisResponse | undefined;
  proxyDataByFund: Record<string, DetailedProxyMetadata | undefined>;
  rangeDataByFund: Record<string, SubjectRangeAnalysis[]>;
  onClearSelected: () => void;
  onPickerOpen: () => void;
  children: React.ReactNode;
}

export const BulkManagementTablePrimitivesRoot = withErrorBoundary(
  SomethingWentWrongFallbackComponent,
  InternalBulkManagementTablePrimitivesRoot,
);

function InternalBulkManagementTablePrimitivesRoot({
  isPickerOpen,
  onPickerClose,
  openedSecondaryId,
  uniqFunds,
  selectedFunds,
  onProxyChange,
  disableSelection,
  rangeAnalysis,
  proxyDataByFund,
  rangeDataByFund,
  onClearSelected,
  onPickerOpen,
  children,
}: BulkManagementTableRootProps) {
  if (uniqFunds.length === 0) {
    return <EmptyState header="There are no investments in this portfolio." />;
  }

  return (
    <>
      <SidePanelOverlay.Root
        width={1062}
        margin={0}
        noPadding
        side="right"
        isOpen={isPickerOpen}
        handleClose={onPickerClose}
        content={
          // If we don't have this check, BulkProxyPicker will throw errors when no investments are selected, because it is rendered even
          // when the overlay is closed.
          isPickerOpen ? (
            <BulkProxyPicker
              investments={
                openedSecondaryId ? [assertNotNil(uniqFunds.find((f) => f.id === openedSecondaryId))] : selectedFunds
              }
              onProxyChange={onProxyChange}
            />
          ) : undefined
        }
        hideTopBackButton
      />

      <Wrapper>
        {children}
        {!disableSelection && selectedFunds.length > 0 && rangeAnalysis && (
          <BulkProxySection
            selectedFunds={selectedFunds}
            proxyDataByFund={proxyDataByFund}
            rangeDataByFund={Object.fromEntries(
              Object.entries(rangeDataByFund).map(([k, arr]) => [k, arr[arr.length - 1]]),
            )}
            onClearSelected={onClearSelected}
            onPickerOpen={onPickerOpen}
          />
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  .bulk-management-table-wrapper,
  & > div {
    width: 100%;
  }
`;
