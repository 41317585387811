import React, { memo } from 'react';

import { usePrivateAnalysis } from '../../../studio-blocks';

import { GridWrapper } from '../../../studio-blocks/common';
import { PrivatePerformanceSummaryGrid } from '../../../studio-blocks/components/grid/PrivatePerformanceSummaryGrid';
import { useRecoilValue } from 'recoil';
import { isReportState } from 'venn-state';

interface PrivateAssetSummaryContentProps {
  id: string;
}

export const PrivatePerformanceSummaryContent = ({ id }: PrivateAssetSummaryContentProps) => {
  const isReport = useRecoilValue(isReportState);
  const { data, isExportable } = usePrivateAnalysis(id);

  return <Grid isExportable={isExportable} isReport={isReport} data={data} />;
};

type GridProps = {
  isExportable: boolean;
  // @ts-expect-error: fixme
  isReport;
  // @ts-expect-error: fixme
  data;
};

const Grid = memo(function InternalGrid({ isExportable, isReport, data }: GridProps) {
  return (
    <GridWrapper isReport={isReport}>
      <PrivatePerformanceSummaryGrid data={data} isExportable={isExportable} />
    </GridWrapper>
  );
});
