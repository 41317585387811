import { get, compact } from 'lodash';
import {
  checkboxMenuHeaderRenderer,
  checkboxRenderer,
  getActionsColumnRenderer,
  identifierColumnRenderer,
  menuHeaderRenderer,
  tagRenderer,
} from './ColumnRenderers';
import { Dates, FS, FundUtils } from 'venn-utils';
import type { Id, SearchResultWithUIState, SearchState } from './types';
import CumulativeTooltip from './CumulativeTooltip';
import React from 'react';
import type { PortfoliosContextValue } from 'venn-components';
import { ColumnAlign, NumericSpan, PercentageSpan, SORTDIR } from 'venn-components';
import { ItemIcon, ItemTypePill } from 'venn-ui-kit';
import { getItemType, SHARPE_KEY, ANN_RET_KEY, ANN_VOL_KEY, getAnnRet, getAnnVol, getSharpe } from './utils';
import NameCell from './NameCell';
import type { PeriodMetric } from './logic/librariesTable';
import { toColumnName, toPath } from './logic/librariesTable';
import { isPercentage } from './filters';

export const getColumns = (
  updateData: React.Dispatch<React.SetStateAction<SearchState>>,
  sortDir: SORTDIR.ASC | SORTDIR.DESC,
  refetchSearch: (hideLoading?: boolean) => Promise<void>,
  portfoliosContext: PortfoliosContextValue,
  max: number,
  min: number,
  setIdWithMenuOpen: (id: Id) => void,
  trackSelectionMade: (item: SearchResultWithUIState) => void,
  findSimilar: (item: SearchResultWithUIState) => void,
  disableSort?: boolean,
  sortKey?: string,
  showAssetType?: boolean,
  showMorningstarCategory?: boolean,
  dynamicColumns: PeriodMetric[] = [],
) => {
  const maxDynamicColumns = 5;

  return compact([
    {
      label: '',
      accessor: '',
      sortable: false,
      cellRenderer: checkboxRenderer(updateData),
      headerStyle: { width: 34 },
      headerRenderer: checkboxMenuHeaderRenderer(updateData),
      cellStyle: {
        paddingTop: 3,
        paddingBottom: 3,
        textAlign: 'center' as const,
      },
    },
    {
      label: '',
      accessor: '',
      sortable: false,
      cellRenderer: tagRenderer(setIdWithMenuOpen, updateData),
      headerRenderer: menuHeaderRenderer,
      headerStyle: { minWidth: 30 },
      cellStyle: {
        textAlign: 'center' as const,
      },
    },
    {
      label: 'Item Type',
      accessor: 'icons',
      sortable: false,
      cellRenderer: (item: SearchResultWithUIState) => <ItemTypePill item={getItemType(item)} />,
    },
    {
      label: '',
      accessor: 'icons',
      sortable: false,
      cellRenderer: (item: SearchResultWithUIState) => (
        <ItemIcon
          item={getItemType(item)}
          isUploaded={item.userUploaded}
          dataSource={item.dataSource}
          investmentSource={item.investmentSource}
        />
      ),
    },
    {
      label: 'Name',
      accessor: 'name',
      sortable: !disableSort,
      cellRenderer: (item: SearchResultWithUIState) => <NameCell trackSelectionMade={trackSelectionMade} item={item} />,
      className: 'library-name-cell',
      headerStyle: { width: '100%', minWidth: '300px' },
      sorted: sortKey === 'name' ? sortDir : undefined,
      sortingIsExternal: true,
    },
    {
      label: 'Identifier',
      accessor: 'symbol',
      headerStyle: { paddingRight: '10px' },
      cellRenderer: identifierColumnRenderer,
    },
    FS.has('context_switching')
      ? {
          label: 'Ownership',
          accessor: 'ownerContextFriendlyName',
          headerStyle: { padding: '5px 20px 5px 0' },
          cellStyle: { padding: '3px 20px 3px 0' },
        }
      : null,
    {
      label: 'CCY',
      accessor: 'currency',
      sortable: !disableSort,
      sorted: sortKey === 'currency' ? sortDir : undefined,
      sortingIsExternal: true,
      headerStyle: { padding: '5px 20px 5px 0' },
      cellStyle: { padding: '3px 20px 3px 0' },
    },
    {
      label: 'Class',
      accessor: 'shareClassName',
      sortable: !disableSort,
      sorted: sortKey === 'shareClassName' ? sortDir : undefined,
      sortingIsExternal: true,
      headerStyle: { padding: '5px 20px 5px 0' },
      cellStyle: { padding: '3px 20px 3px 0' },
      sortKey: 'shareClassName',
    },
    showAssetType
      ? {
          label: 'Type',
          accessor: 'assetType',
          sortable: false,
          cellRenderer: (item: SearchResultWithUIState) => FundUtils.getAssetTypeName(item.assetType),
          headerStyle: { padding: '5px 20px 5px 0' },
          cellStyle: { padding: '3px 20px 3px 0' },
        }
      : null,
    showMorningstarCategory
      ? {
          label: 'Morningstar Category',
          accessor: 'morningstarCategory',
          sortable: false,
          headerStyle: { padding: '5px 20px 5px 0' },
          cellStyle: { padding: '3px 20px 3px 0' },
        }
      : null,
    ...(dynamicColumns || []).slice(0, maxDynamicColumns).map((periodMetric) => {
      const metricPath = toPath({ periodMetric });
      const metricSortKey = `metricsOnTimePeriod.${metricPath}`;
      return {
        label: toColumnName({ periodMetric }),
        accessor: metricSortKey,
        sortable: !disableSort,
        headerStyle: { padding: '5px 20px 5px 0' },
        cellStyle: { padding: '3px 20px 3px 0' },
        cellRenderer: (item: SearchResultWithUIState) => {
          const value = get(item.metricsOnTimePeriod, metricPath) as unknown as number;
          return isPercentage(periodMetric.metric) ? (
            <PercentageSpan value={value} />
          ) : (
            <NumericSpan precision={2}>{value}</NumericSpan>
          );
        },
        sorted: sortKey === metricSortKey ? sortDir : undefined,
        sortingIsExternal: true,
        align: ColumnAlign.RIGHT,
        initialSortDirection: SORTDIR.DESC,
      };
    }),
    {
      label: 'Last 10yr Cum. Return*',
      accessor: 'cumulative',
      headerStyle: { padding: '5px 10px 5px 0' },
      cellRenderer: (item: SearchResultWithUIState) => <CumulativeTooltip item={item} min={min} max={max} />,
    },
    {
      label: 'Ann. Return*',
      accessor: ANN_RET_KEY,
      sortable: !disableSort,
      headerStyle: { padding: '5px 20px 5px 0' },
      cellStyle: { padding: '3px 20px 3px 0' },
      cellRenderer: (item: SearchResultWithUIState) => <PercentageSpan value={getAnnRet(item)} />,
      sorted: sortKey === ANN_RET_KEY ? sortDir : undefined,
      sortingIsExternal: true,
      align: ColumnAlign.RIGHT,
      initialSortDirection: SORTDIR.DESC,
    },
    {
      label: 'Ann. Volatility*',
      accessor: ANN_VOL_KEY,
      sortable: !disableSort,
      headerStyle: { padding: '5px 20px 5px 0' },
      cellStyle: { padding: '3px 20px 3px 0' },
      cellRenderer: (item: SearchResultWithUIState) => <PercentageSpan value={getAnnVol(item)} />,
      sorted: sortKey === ANN_VOL_KEY ? sortDir : undefined,
      sortingIsExternal: true,
      align: ColumnAlign.RIGHT,
      initialSortDirection: SORTDIR.DESC,
    },
    {
      label: 'Sharpe Ratio*',
      accessor: SHARPE_KEY,
      sortable: !disableSort,
      headerStyle: { padding: '5px 20px 5px 0' },
      cellStyle: { padding: '3px 20px 3px 0' },
      cellRenderer: (item: SearchResultWithUIState) => (
        <NumericSpan precision={2}>{getSharpe(item) ?? null}</NumericSpan>
      ),
      sorted: sortKey === SHARPE_KEY ? sortDir : undefined,
      sortingIsExternal: true,
      align: ColumnAlign.RIGHT,
      initialSortDirection: SORTDIR.DESC,
    },
    {
      label: 'First Available',
      accessor: 'startRange',
      sortable: !disableSort,
      headerStyle: { padding: '5px 20px 5px 0' },
      cellStyle: { padding: '3px 20px 3px 0' },
      cellRenderer: (item: SearchResultWithUIState) => Dates.toDayMonthYear(item.startRange),
      sorted: sortKey === 'startRange' ? sortDir : undefined,
      sortingIsExternal: true,
      align: ColumnAlign.RIGHT,
      initialSortDirection: SORTDIR.DESC,
    },
    {
      label: 'Last Updated',
      accessor: 'updated',
      sortable: !disableSort,
      headerStyle: { padding: '5px 20px 5px 0' },
      cellStyle: { padding: '3px 20px 3px 0' },
      cellRenderer: (item: SearchResultWithUIState) => Dates.toDayMonthYear(item.updated),
      sorted: sortKey === 'updated' ? sortDir : undefined,
      sortingIsExternal: true,
      align: ColumnAlign.RIGHT,
      initialSortDirection: SORTDIR.DESC,
    },
    {
      label: 'Actions',
      accessor: 'menu',
      sortable: false,
      cellRenderer: getActionsColumnRenderer(
        updateData,
        sortDir,
        refetchSearch,
        findSimilar,
        portfoliosContext,
        max,
        min,
        setIdWithMenuOpen,
      ),
      cellStyle: {
        textAlign: 'center' as const,
      },
    },
  ]);
};
