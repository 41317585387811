/**
 *  @fileoverview Functions to invalidate queries in the react-query cache across the application.
 *
 * To be used with invalidate.tsx; not to be exported for use in the rest of the application.
 */
import type { StudioRequestSubject } from 'venn-state';
import { VennQueryClient } from './vennQueryClient';
import {
  STUDIO_ANALYSIS_KEY_PREFIX,
  type StudioAnalysisKey,
  PROXY_METADATA_KEY_PREFIX,
  type ProxyMetadataKey,
  GET_FUND_KEY_PREFIX,
  type GetFundKey,
  FUND_RETURNS_RANGE_KEY_PREFIX,
  type FundReturnsRangeKey,
  PROXY_FEASIBILITY_KEY_PREFIX,
  type ProxyFeasibilityKey,
  RANGE_ANALYSIS_KEY_PREFIX,
  type RangeAnalysisKey,
  BULK_GET_FUND_KEY_PREFIX,
  type BulkGetFundKey,
  INVESTMENT_RANGE_ANALYSIS_KEY_PREFIX,
  INVESTMENT_PROXY_METADATA_KEY_PREFIX,
  PROXY_METRICS_KEY_PREFIX,
  type ProxyMetricsKey,
} from './query-keys';
import { findInPortfolioBy } from '../portfolio/portfolioUtils';
import type { AnalysisSubject } from '../analysis';

export function invalidateForecast() {
  const queryClient = VennQueryClient.getInstance();

  return queryClient.invalidateQueries({
    queryKey: [STUDIO_ANALYSIS_KEY_PREFIX],
  });
}

export function invalidateInvestment(investmentId: string | number) {
  return invalidateInvestments([investmentId]);
}

/**
 * Invalidate active queries that are affected by the given investment(s).
 */
export function invalidateInvestments(investmentIds: (string | number)[]) {
  const investmentIdsSet = new Set<string>(investmentIds.map(String));
  const shouldInvalidate = getSubjectMatchPredicate(investmentIdsSet);
  const queryClient = VennQueryClient.getInstance();

  // TODO: continue migrating useQuery usage to standardized keys and add to invalidation as needed:
  // - EXPORT_METADATA_KEY
  // - COMPARISON_CACHE_KEY

  const fundInvalidations = [
    queryClient.invalidateQueries({
      queryKey: [GET_FUND_KEY_PREFIX],
      predicate: (query) => {
        const queryKey = query.queryKey as GetFundKey;
        return investmentIdsSet.has(queryKey[1]);
      },
    }),
    queryClient.invalidateQueries({
      queryKey: [BULK_GET_FUND_KEY_PREFIX],
      predicate: (query) => {
        const queryKey = query.queryKey as BulkGetFundKey;
        return queryKey[1].some((fundId) => investmentIdsSet.has(fundId));
      },
    }),
    queryClient.invalidateQueries({
      queryKey: [FUND_RETURNS_RANGE_KEY_PREFIX],
      predicate: (query) => {
        const queryKey = query.queryKey as FundReturnsRangeKey;
        return investmentIdsSet.has(queryKey[1]);
      },
    }),
    queryClient.invalidateQueries({
      queryKey: [INVESTMENT_RANGE_ANALYSIS_KEY_PREFIX],
      predicate: (query) => {
        const queryKey = query.queryKey as FundReturnsRangeKey;
        return investmentIdsSet.has(queryKey[1]);
      },
    }),
    queryClient.invalidateQueries({
      queryKey: [INVESTMENT_PROXY_METADATA_KEY_PREFIX],
      predicate: (query) => {
        const queryKey = query.queryKey as FundReturnsRangeKey;
        return investmentIdsSet.has(queryKey[1]);
      },
    }),
    queryClient.invalidateQueries({
      queryKey: [PROXY_METRICS_KEY_PREFIX],
      predicate: (query) => {
        const queryKey = query.queryKey as ProxyMetricsKey;
        return investmentIdsSet.has(queryKey[1]);
      },
    }),
  ];

  const generalInvalidations = [
    queryClient.invalidateQueries({
      queryKey: [RANGE_ANALYSIS_KEY_PREFIX],
      predicate: (query) => {
        const queryKey = query.queryKey as RangeAnalysisKey;
        return shouldInvalidate(queryKey[1]);
      },
    }),
    queryClient.invalidateQueries({
      queryKey: [PROXY_METADATA_KEY_PREFIX],
      predicate: (query) => {
        const queryKey = query.queryKey as ProxyMetadataKey;
        return queryKey[1].some((subjectId) => investmentIdsSet.has(subjectId.id));
      },
    }),
    queryClient.invalidateQueries({
      queryKey: [STUDIO_ANALYSIS_KEY_PREFIX],
      predicate: (query) => {
        const queryKey = query.queryKey as StudioAnalysisKey;
        return queryKey[2].some((request) => {
          return shouldInvalidate(request.subject) || shouldInvalidate(request.benchmark);
        });
      },
    }),
    queryClient.invalidateQueries({
      queryKey: [PROXY_FEASIBILITY_KEY_PREFIX],
      predicate: (query) => {
        const queryKey = query.queryKey as ProxyFeasibilityKey;
        return investmentIdsSet.has(queryKey[1]);
      },
    }),
  ];

  return Promise.allSettled([...fundInvalidations, ...generalInvalidations]);
}

/**
 * @param investmentIdsSet Set of investment IDs to match against.
 * @returns Predicate function that returns true if a match is found against the subject, the subject's benchmark, or any of the subject's portfolio children.
 */
function getSubjectMatchPredicate(investmentIdsSet: Set<string>) {
  return (subject: StudioRequestSubject | AnalysisSubject | undefined) => {
    if (!subject) return false;

    const potentialKeys: (string | undefined | false)[] = [
      String(subject.id),
      subject.fund?.id,
      'individualBenchmark' in subject &&
        (subject.individualBenchmark?.fundId || String(subject.individualBenchmark?.portfolioId)),
      subject.fund?.proxyId,
      'activeBenchmarkId' in subject && typeof subject.activeBenchmarkId === 'string' && subject.activeBenchmarkId,
    ];
    if (potentialKeys.some((key) => key && investmentIdsSet.has(key))) {
      return true;
    }
    const portfolioNode =
      subject.strategy ?? ('modifiedPortfolio' in subject ? subject.modifiedPortfolio : undefined) ?? subject.portfolio;

    return !!(
      portfolioNode &&
      findInPortfolioBy(
        portfolioNode,
        (portfolio) => investmentIdsSet.has(String(portfolio.fund?.id)) || investmentIdsSet.has(String(portfolio.id)),
      )
    );
  };
}
