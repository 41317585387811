import { useCallback } from 'react';
import { assertExhaustive, LibraryFilterSectionKey } from 'venn-utils';
import type { BaseSearchParams, PrivatesSearchParams, SearchParams } from 'venn-state';
import { BASE_PARAMS, librarySearchParamsAtom, privateLibrarySearchParams } from 'venn-state';
import { AdvancedFilterType } from './useLibraryStateURLSynchronizer';
import type { RecoilState } from 'recoil';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { omit } from 'lodash';

type ChangedParam =
  | {
      key: 'lastTrackTime';
      value: number;
    }
  | {
      key: 'sortBy';
      column: string;
      order: 'asc' | 'desc';
    }
  | {
      key: 'page';
      value: number;
    }
  | {
      key: 'filter';
      filterName: string;
      value: string | string[];
    }
  | {
      key: 'resetSort';
    }
  | {
      key: 'selectedIds';
      value: string[];
    };

export const useReturnsLibraryState = () => {
  return useLibraryState<SearchParams>(librarySearchParamsAtom);
};
export const usePrivateLibraryState = () => {
  return useLibraryState<PrivatesSearchParams>(privateLibrarySearchParams);
};

export const useLibraryState = <StateType extends BaseSearchParams>(persistStateAtom: RecoilState<StateType>) => {
  const [librarySearchParams, setLibrarySearchParams] = useRecoilState(persistStateAtom);
  const resetLibrarySearchParams = useResetRecoilState(persistStateAtom);
  const updateLibrarySearchParam = useCallback(
    (param: ChangedParam) => {
      // @ts-expect-error: todo fix incorrect typing
      setLibrarySearchParams((prevQueryParams) => {
        let updateParams;
        switch (param.key) {
          case 'lastTrackTime':
            // reset to be last updated
            updateParams = {
              ...prevQueryParams,
              lastTrackTime: param.value,
              sortBy: undefined,
              page: 1,
            };
            break;
          case 'sortBy':
            updateParams = {
              ...prevQueryParams,
              sortBy: param.column,
              order: param.order,
            };
            break;
          case 'resetSort':
            updateParams = {
              ...prevQueryParams,
              sortBy: undefined,
              order: 'desc',
            };
            break;
          case 'page':
            updateParams = {
              ...prevQueryParams,
              page: param.value,
            };
            break;
          case 'selectedIds': {
            updateParams = {
              ...prevQueryParams,
              selectedIds: param.value,
            };
            break;
          }
          case 'filter': {
            updateParams = {
              ...prevQueryParams,
              [param.filterName]: param.value,
              ...BASE_PARAMS, // Reset page and encode search string
            };
            break;
          }
          default:
            throw assertExhaustive(param);
        }

        // Remove invalidSubject paramter
        return omit(updateParams, 'invalidSubject');
      });
    },
    [setLibrarySearchParams],
  );

  const toggleParamItem = useCallback(
    (key: string, item: string) => {
      setLibrarySearchParams((prevQueryParams) => {
        switch (key) {
          case LibraryFilterSectionKey.FILTERS:
          case LibraryFilterSectionKey.TAGS:
          case AdvancedFilterType.CURRENCY:
          case AdvancedFilterType.ASSET_TYPE:
          case AdvancedFilterType.DATA_SOURCE:
          case AdvancedFilterType.MORNINGSTAR_CATEGORY:
            const updated = new Set(prevQueryParams?.[key] ?? []);
            if (updated.has(item)) {
              updated.delete(item);
            } else {
              updated.add(item);
            }
            return {
              ...prevQueryParams,
              [key]: Array.from(updated),
              ...BASE_PARAMS,
            };
          default:
            return prevQueryParams;
        }
      });
    },
    [setLibrarySearchParams],
  );

  return {
    librarySearchParams,
    setLibrarySearchParams,
    updateLibrarySearchParam,
    resetLibrarySearchParams,
    toggleParamItem,
  };
};
