import React, { memo } from 'react';
import { COMMON_ERROR_WRAPPERS } from '../../../studio-blocks/components/error-wrappers/common';
import { MetricsErrorWrapper } from '../../../studio-blocks/components/error-wrappers/MetricsErrorWrapper';

import { Block } from '../../components/core';
import { HistoricalAllocationsTimeSeriesContent } from './historical-allocations-timeseries-content';
import { HistoricalAllocationsTimeSeriesHeader } from './historical-allocations-timeseries-header';

interface HistoricalAllocationsTimeSeriesBlockProps {
  id: string;
}

export const HistoricalAllocationsTimeSeriesBlock = memo(function InternalHistoricalAllocationsTimeSeriesBlock({
  id,
}: HistoricalAllocationsTimeSeriesBlockProps) {
  return (
    <Block.Root id={id}>
      <Block.Header>
        <HistoricalAllocationsTimeSeriesHeader id={id} />
      </Block.Header>

      <Block.Content id={id} errorWrappers={[...COMMON_ERROR_WRAPPERS, MetricsErrorWrapper]}>
        <HistoricalAllocationsTimeSeriesContent id={id} />
      </Block.Content>
    </Block.Root>
  );
});
