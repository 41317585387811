import { useCallback, useEffect, useState } from 'react';
import type { ClientIntegration } from 'venn-api';
import { getIntegrationSetupConfig, oAuth2 } from 'venn-api';
import { useUrlState, setValuesInUrl } from 'venn-components';
import { toLower, toUpper } from 'lodash';
import { Notifications, NotificationType } from 'venn-ui-kit';
import { logExceptionIntoSentry, logMessageToSentry } from 'venn-utils';
import { useHistory } from 'react-router-dom';
import { MAX_STEP, MIN_STEP, STEPS } from './steps';

export interface AddeparPanelState {
  /** Close the panel */
  onClose(): void;
  /** Open the panel */
  onOpen(): void;
  /** Whether the panel is open or not */
  isOpen: boolean;
  /** 0-indexed step number the panel is currently on */
  step: number;
  /** Go to the next step. Does nothing if already on the last step */
  goToNextStep(): void;
  /** Go to the previous step. Does nothing if already on the first step */
  goToPreviousStep(): void;
  /** The Addepar url to go to to trigger the OAuth flow */
  addeparAuthUrl: string | undefined;
  /** Whether Venn is currently authorizing with Addepar. This happens in between Step */
  isAuthorizing: boolean;
  /** The id of the Addepar firm being set up */
  addeparFirmId: string | undefined;
  /** The base url of the Addepar firm being set up, e.g. examplefirm.addepar.com */
  addeparFirmBaseUrl: string | undefined;
  /** Edit existing client integration */
  editClientIntegration: (integration: ClientIntegration) => void;
}

const ADDEPAR = 'ADDEPAR';
const OAUTH_URL_PARAM = 'oauth';
const OAUTH_AUTHORIZING_URL_PARAM = 'authorizing';
const OAUTH_STEP = STEPS[1];
const PARAM_STEP = STEPS[2];
export const CANCEL_OAUTH_MESSAGE = 'The user declined the authorization request.';
export const CANCEL_OAUTH_ERROR = 'access_denied';
const OAUTH_SUPPORTED_ERROR = [130002, 130003, 130004];

const notify = (text: string | JSX.Element, type: NotificationType = NotificationType.INFO) => {
  Notifications.notify(text, type, 'top-right');
};

const useAddeparSetupPanel = (): AddeparPanelState => {
  const history = useHistory();
  const [clientIntegration, setClientIntegration] = useState<ClientIntegration>();
  const [integration] = useUrlState<string | undefined>('integration', undefined, toLower, toUpper, true);
  const [urlStep] = useUrlState<string | undefined>('step', undefined, toLower, toLower, true);
  const [oauthCode] = useUrlState<string | undefined>('code', undefined, toLower, toLower, true);
  const [addeparError] = useUrlState<string | undefined>('error', undefined, toLower, toLower, true);
  const [addeparErrorDescription] = useUrlState<string | undefined>(
    'error_description',
    undefined,
    undefined,
    undefined,
    true,
  );
  const [step, setStep] = useState(MIN_STEP);

  const [addeparAuthUrl, setAddeparAuthUrl] = useState<string>();
  useEffect(() => {
    const fetchAuthUrl = async () => {
      try {
        const { content } = await getIntegrationSetupConfig(ADDEPAR);
        // if local testing desired, follow instructions here:
        // https://twosigma.atlassian.net/wiki/spaces/EN/pages/3102572561/Engineering+Notes+on+Addepar#Testing-Addepar-OAuth-Flow-Locally
        setAddeparAuthUrl(content.authorizationUrl);
      } catch (e) {
        if (e.name !== 'AbortError') {
          notify('Cannot authorize with Addepar right now. Try again later.', NotificationType.ERROR);
          logExceptionIntoSentry(e);
        }
      }
    };
    fetchAuthUrl();
  }, []);

  const [isAuthorizing, setIsAuthorizing] = useState(false);
  useEffect(() => {
    const authorize = async (code: string) => {
      let success = true;
      try {
        const response = (await oAuth2(ADDEPAR, code)).content;
        setClientIntegration(response);
        notify(`${response.clientName} successfully authorized!`, NotificationType.SUCCESS);
      } catch (e) {
        if (e.name !== 'AbortError') {
          let authErrorMessage = 'Authorization failed. Try again.';
          if (e.content?.code && OAUTH_SUPPORTED_ERROR.includes(e.content.code)) {
            authErrorMessage = e.content.message;
          } else {
            logExceptionIntoSentry(e);
          }
          notify(authErrorMessage, NotificationType.ERROR);
          success = false;
        }
      }
      setValuesInUrl({ step: undefined }, history);
      // state updates need to happen after url updates
      if (!success) {
        setStep(0);
      }
      setIsAuthorizing(false);
    };
    if (urlStep === OAUTH_URL_PARAM && oauthCode && !isAuthorizing) {
      setValuesInUrl({ step: OAUTH_AUTHORIZING_URL_PARAM, code: undefined }, history);
      authorize(oauthCode);
    }
    if (urlStep === OAUTH_AUTHORIZING_URL_PARAM) {
      setStep(OAUTH_STEP.key);
      setIsAuthorizing(true);
    }
  }, [history, isAuthorizing, oauthCode, step, urlStep]);

  const goToNextStep = useCallback(() => {
    setStep((prev) => Math.min(prev + 1, MAX_STEP));
  }, [setStep]);

  const goToPreviousStep = useCallback(() => {
    setStep((prev) => Math.max(prev - 1, MIN_STEP));
  }, [setStep]);

  const isOpen = integration === ADDEPAR;

  const onReset = useCallback(() => {
    setStep(MIN_STEP);
    setClientIntegration(undefined);
  }, []);

  const onOpen = useCallback(() => setValuesInUrl({ integration: ADDEPAR }, history), [history]);
  const onClose = useCallback(() => {
    setValuesInUrl({ integration: undefined }, history);
    onReset();
  }, [history, onReset]);

  const editClientIntegration = useCallback(
    // @ts-expect-error: fixme
    (integration) => {
      onOpen();
      setClientIntegration(integration);
      setStep(PARAM_STEP.key);
    },
    [onOpen],
  );

  useEffect(() => {
    if (addeparError && addeparErrorDescription) {
      // Just check the error code in case Addepar updates the error description
      if (addeparError === CANCEL_OAUTH_ERROR) {
        notify('Authorization cancelled', NotificationType.INFO);
      } else {
        notify('Unknown Addepar authorization error', NotificationType.INFO);
        logMessageToSentry(`Addepar unknown authorization error: (${addeparError}) ${addeparErrorDescription}`);
      }
      setValuesInUrl({ error: undefined, error_description: undefined, step: undefined }, history);
    }
  }, [addeparError, addeparErrorDescription, history]);

  return {
    isOpen,
    onOpen,
    onClose,
    step,
    goToNextStep,
    goToPreviousStep,
    addeparAuthUrl,
    isAuthorizing,
    addeparFirmId: clientIntegration?.clientId,
    addeparFirmBaseUrl: clientIntegration?.clientName,
    editClientIntegration,
  };
};

export default useAddeparSetupPanel;
