import { type ComputedDateRange, toEndOfPeriod } from 'venn-utils';
import type { ImageDetails } from 'venn-api';

const DEFAULT_MAX_RANGE = {
  from: toEndOfPeriod('1900/01/01', 'day'),
  to: toEndOfPeriod(new Date().valueOf(), 'day'),
};

export const getDefaultRange = (): ComputedDateRange => ({
  range: {},
  maxRange: DEFAULT_MAX_RANGE,
  frequency: 'DAILY',
});

export const STOCK_PHOTOS = [
  {
    id: 'f794fc5e-88ef-4754-ad2b-20318b2179b7',
  },
  {
    id: 'f0c47abd-4577-4e6f-a5e2-37226c96614b',
  },
  {
    id: '78cf60cf-870c-42b4-bed8-71ff8a2e4cc2',
  },
] as ImageDetails[];
export const DEFAULT_STOCK_PHOTO = STOCK_PHOTOS[0].id;
