import React, { Component } from 'react';
import type { History } from 'history';
import { Modal, ModalHeader, ModalFooter } from '../modal';
import { ModalSubhead } from '../modal/ModalHeader';
import { UNBLOCKABLE_ROUTES } from 'venn-utils';

const REFRESH_PROMPT = 'Unsaved changes will be lost.';

/**
 * TODO(houssein): Remove this component in favor of ./ConfirmUnsavedChangesModal.tsx in the future.
 * We shouldn't need to have two separate unssaved changes modals.
 */

export interface UnsavedChangesModalProps {
  /**
   * An optional classname to be applied to the root component.
   * Typically used to add `qaClasses`.
   */
  className?: string;
  /**
   * Optional callback when user discards the changes and navigates
   * to a different page.
   */
  onAccept?: () => void;
  /**
   * Determine whether user has unsaved changes to portfolio
   */
  isPortfolio?: boolean;
  /**
   * Required callback when user presses cancel and stays on the page.
   * You must hide the modal in this callback.
   */
  onCancel: () => void;
  primaryLabel?: string;
  customHeader?: string;
  customSubHeader?: string;
}

export interface UnsavedChangesModalState {
  callback?: UnsavedChangesCallback;
}

export type UnsavedChangesCallback = (accepted: boolean) => void;

export type UnsavedChangesPathPredicate = (path: string) => boolean;

/**
 * A "private static" variable that is populated by the callback function provided
 * by the router, when the user tries to navigate away in a blocked navigation state.
 * We willn need to call this function with either `true` or `false` when the user
 * presses "Discard changes" or "Cancel" in the modal.
 */
let historyCallback: UnsavedChangesCallback | undefined;

/**
 * Private static function to handle refresh button click
 */
const refreshCallback: (e: Event) => string = (e) => {
  e.preventDefault();
  e.returnValue = false;
  return REFRESH_PROMPT;
};

/**
 * A "private static" variable that is populated by a function provided by the router
 * when we block the history navigation. We will need to call this function to unblock
 * navigation when the user either saves or discards the changes.
 */
let unblockHistoryInternal: (() => void) | undefined;

/**
 * A modal dialog to be displayed when the user tries to navigate away from a page while
 * they have unsaved changes.
 *
 * Use static methods `blockHistory()` and `unblockHistory()` to set/unset the trigger
 * logic in your app.
 *
 * See this commit for a usage example:
 *
 * https://gitlab.pics-twosigma.com/venn/frontend/commit/2b1a02b34775456a86b6cbb208ef415099340759
 *
 * WARNING: The app should only have ONE instance of any componet that blocks/unblocks
 * history at any given time. TODO: remove this limitation.
 */
export default class UnsavedChangesModal extends Component<UnsavedChangesModalProps, UnsavedChangesModalState> {
  static history?: History;

  static userConfirmation?: (message: string, callback: UnsavedChangesCallback) => void;

  /**
   * Installs a hook on `history` to trigger the display of the Unsaved Changes Modal
   * when the user tries to navigate away from the page.
   *
   * When the user tries to navigate away, `showModal()` will be called, which must display
   * an instance of `UnsavedChangesModal` on the screen.
   *
   * You can pass an optional predicate if you want to block navigation to some paths, but
   * allow navigation to other paths. The target pathname will be passed to the predicate,
   * and if it returns true, navigation will be blocked by the confirmation modal; if it
   * return false, navigation will be allowed.
   *
   * If you don't provide a predicate, all navigation will be blocked by the confirmation modal.
   *
   * Multiple calls to `blockHistory()` have no effect. Make sure to call `unblockHistory()`
   * when you no longer need it.
   */
  static blockHistory(showModal: () => void, predicate?: UnsavedChangesPathPredicate) {
    if (!UnsavedChangesModal.history || unblockHistoryInternal) {
      return;
    }
    UnsavedChangesModal.userConfirmation = (msg: string, callback: UnsavedChangesCallback) => {
      historyCallback = callback;
      showModal();
    };

    /** Returns false and thus allows navigation if the next pathname matches an unblockable route. */
    const unblockableRoutesPredicate = (nextPathname: string) =>
      !UNBLOCKABLE_ROUTES.some((route) => nextPathname.startsWith(route));
    const combinedPredicate = (nextPathname: string) =>
      predicate?.(nextPathname) && unblockableRoutesPredicate(nextPathname);

    // This message is just a fallback, in case the custom modal is not supported
    const message = 'Do you want to leave this page?';
    unblockHistoryInternal = UnsavedChangesModal.history.block((location) =>
      combinedPredicate(location.pathname) ? message : undefined,
    );
    window.addEventListener('beforeunload', refreshCallback);
  }

  /**
   * Removes the hook from `history`, installed by `blockHistory()`. The user will be
   * able to navigate freely to any other path.
   */
  static unblockHistory() {
    if (!unblockHistoryInternal) {
      return;
    }
    unblockHistoryInternal();
    UnsavedChangesModal.userConfirmation = undefined;
    historyCallback = undefined;
    unblockHistoryInternal = undefined;
    window.removeEventListener('beforeunload', refreshCallback);
  }

  onAccept = () => {
    if (this.props.onAccept) {
      this.props.onAccept();
    }
    historyCallback?.(true);
  };

  onCancel = () => {
    this.props.onCancel();
    historyCallback?.(false);
  };

  render() {
    return (
      <Modal extraPadding className={this.props.className}>
        <ModalHeader extraPadding>
          {this.props.customHeader
            ? this.props.customHeader
            : `You have not saved your ${this.props.isPortfolio ? 'portfolio' : ''} changes.`}
        </ModalHeader>
        <ModalSubhead extraPadding>
          {this.props.customSubHeader ? this.props.customSubHeader : 'Do you want to leave this page?'}
        </ModalSubhead>
        <ModalFooter
          onCancel={this.onCancel}
          primaryLabel={this.props.primaryLabel ?? 'Yes, discard changes'}
          onPrimaryClick={this.onAccept}
        />
      </Modal>
    );
  }
}
