import React, { useContext } from 'react';
import styled from 'styled-components';
import { EllipsisTooltipSpan, getItemLabel, ItemIcon } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import { Dates, FundUtils } from 'venn-utils';
import UserContext from '../../contexts/user-context';
import type { SearchMenuItem } from '../types';
import { getItemType, getSubjectRange, getTicker, HeaderCell, isUploaded } from './shared';
import { capitalize } from 'lodash';

export enum SearchMenuColumn {
  CCY = 'CCY',
  IDENTIFIER = 'IDENTIFIER',
  TYPE = 'TYPE',
  AVAILABLE_DATES = 'AVAILABLE_DATES',
  LAST_UPDATED = 'LAST_UPDATED',
  VIEW_LAST_UPDATED = 'VIEW_LAST_UPDATED',
  VIEW_LAST_UPDATED_BY = 'VIEW_LAST_UPDATED_BY',
  CREATED_AT = 'CREATED_AT',
  UNPROXIED_AVAILABLE_DATES = 'UNPROXIED_AVAILABLE_DATES',
  UNPROXIED_FREQUENCY = 'UNPROXIED_FREQUENCY',
}

export const VIEW_COLUMNS: SearchMenuColumn[] = [
  SearchMenuColumn.VIEW_LAST_UPDATED_BY,
  SearchMenuColumn.VIEW_LAST_UPDATED,
];

export const ARTICLE_COLUMNS: SearchMenuColumn[] = [SearchMenuColumn.CREATED_AT];

export const DEFAULT_COLUMNS: SearchMenuColumn[] = [
  SearchMenuColumn.CCY,
  SearchMenuColumn.IDENTIFIER,
  SearchMenuColumn.AVAILABLE_DATES,
  SearchMenuColumn.LAST_UPDATED,
];

export const DEFAULT_PORTFOLIOS_COLUMNS: SearchMenuColumn[] = [
  SearchMenuColumn.CCY,
  SearchMenuColumn.AVAILABLE_DATES,
  SearchMenuColumn.LAST_UPDATED,
];

const DEFAULT_PORTFOLIO_SMALL_SCREEN_COLUMNS = [SearchMenuColumn.CCY];
const DEFAULT_SMALL_SCREEN_COLUMNS = [SearchMenuColumn.CCY, SearchMenuColumn.IDENTIFIER];
export const getDefaultColumns = (onlyPortfolio: boolean, smallScreen: boolean) => {
  if (onlyPortfolio && smallScreen) {
    return DEFAULT_PORTFOLIO_SMALL_SCREEN_COLUMNS;
  }
  if (smallScreen) {
    return DEFAULT_SMALL_SCREEN_COLUMNS;
  }

  if (onlyPortfolio) {
    return DEFAULT_PORTFOLIOS_COLUMNS;
  }
  return DEFAULT_COLUMNS;
};

interface Column {
  headerRenderer: () => React.ReactNode;
  cellRenderer: (item: SearchMenuItem) => React.ReactNode;
}

const SubjectCurrency = ({ item }: { item: AnalysisSubject }) => {
  const { profileSettings } = useContext(UserContext);
  if (item.fund) {
    return item.fund?.currency;
  }
  if (item.privateFund) {
    return item.privateFund.currency;
  }
  return profileSettings?.organization.currency;
};

export const ColumnRenderers: Record<SearchMenuColumn, Column> = {
  CCY: {
    headerRenderer: () => <HeaderCell>CCY</HeaderCell>,
    cellRenderer: ({ value }) => <td>{value ? <SubjectCurrency item={value} /> : '--'}</td>,
  },
  IDENTIFIER: {
    headerRenderer: () => <HeaderCell>Identifier</HeaderCell>,
    cellRenderer: ({ value }) => (
      <td>
        <EllipsisTooltipSpan maxWidth={100} flex>
          {value ? getTicker(value) : '--'}
        </EllipsisTooltipSpan>
      </td>
    ),
  },
  TYPE: {
    headerRenderer: () => <HeaderCell>Type</HeaderCell>,
    cellRenderer: ({ value }) => <td>{value?.fund && FundUtils.getAssetTypeName(value?.fund?.assetType)}</td>,
  },
  AVAILABLE_DATES: {
    headerRenderer: () => <HeaderCell>Available Dates</HeaderCell>,
    cellRenderer: ({ value }) => {
      return <SingleLineDateCell>{value && getSubjectRange(value)}</SingleLineDateCell>;
    },
  },
  LAST_UPDATED: {
    headerRenderer: () => <HeaderCell>Last Updated</HeaderCell>,
    cellRenderer: ({ value }) => (
      <SingleLineDateCell>
        {value?.superItem?.updated ? Dates.toDayMonthYear(value?.superItem?.updated) : '--'}
      </SingleLineDateCell>
    ),
  },
  VIEW_LAST_UPDATED_BY: {
    headerRenderer: () => <HeaderCell>Last Updated By</HeaderCell>,
    cellRenderer: ({ viewInfo }) => (
      <SingleLineDateCell>{viewInfo ? viewInfo.owner.displayName : '--'}</SingleLineDateCell>
    ),
  },
  VIEW_LAST_UPDATED: {
    headerRenderer: () => <HeaderCell>Last Updated</HeaderCell>,
    cellRenderer: ({ viewInfo }) => (
      <SingleLineDateCell>{viewInfo?.updated ? Dates.toDayMonthYear(viewInfo?.updated) : '--'}</SingleLineDateCell>
    ),
  },
  CREATED_AT: {
    headerRenderer: () => <HeaderCell>Created On</HeaderCell>,
    cellRenderer: ({ helpArticle }) => (
      <SingleLineDateCell>
        {helpArticle?.createdAt ? Dates.toDayMonthYear(Number(`${helpArticle.createdAt}000`)) : '--'}
      </SingleLineDateCell>
    ),
  },
  UNPROXIED_AVAILABLE_DATES: {
    headerRenderer: () => <HeaderCell>Available Dates</HeaderCell>,
    cellRenderer: ({ value, searchResult }) => {
      return (
        <SingleLineDateCell>{value && getSubjectRange(value, searchResult?.unproxiedFrequency)}</SingleLineDateCell>
      );
    },
  },
  UNPROXIED_FREQUENCY: {
    headerRenderer: () => <HeaderCell>Frequency</HeaderCell>,
    cellRenderer: ({ searchResult }) => {
      return (
        <SingleLineDateCell>
          {searchResult?.unproxiedFrequency ? capitalize(searchResult.unproxiedFrequency) : '--'}
        </SingleLineDateCell>
      );
    },
  },
};

const tooltipItemsRenderers = [
  {
    label: 'Available Dates',
    valueRenderer: (item?: AnalysisSubject) => (item?.superItem ? getSubjectRange(item) : '--'),
  },
  {
    label: 'Last Updated',
    valueRenderer: (item?: AnalysisSubject) =>
      item?.superItem.updated ? Dates.toDayMonthYear(item.superItem.updated) : '--',
  },
];

export const renderItemsTooltip = (searchItem: SearchMenuItem, isOptionDisabled?: boolean) => {
  const itemType = getItemType(searchItem);
  return (
    <TooltipContent>
      <Name>{searchItem.label}</Name>

      <SmallRow>
        <ItemIcon
          isDisabled={isOptionDisabled}
          item={itemType}
          dataSource={searchItem.value?.superItem.dataSource || searchItem.value?.portfolio?.dataSource}
          isUploaded={isUploaded(searchItem)}
          investmentSource={searchItem.value?.fund?.investmentSource}
        />
        {getItemLabel(itemType)}
      </SmallRow>

      {tooltipItemsRenderers.map((item) => (
        <Row key={item.label}>
          <span>{item.label}:</span>
          <Bold>{item.valueRenderer(searchItem.value)}</Bold>
        </Row>
      ))}
    </TooltipContent>
  );
};

const SingleLineCell = styled.td`
  white-space: nowrap;
`;

const SingleLineDateCell = styled(SingleLineCell)`
  text-align: right;
`;

const TooltipContent = styled.div`
  width: 200px;
  font-size: 11px;
`;

const Name = styled.div`
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SmallRow = styled.div`
  margin-bottom: 10px;
  font-size: 10px;
  display: flex;
  align-items: center;
  column-gap: 3px;
`;

const Bold = styled.span`
  font-weight: bold;
`;
