import { createInvestmentProxyMetadataKey } from 'venn-utils';
import { analysis, type AnalysisSubject } from 'venn-api';
import { zipObject, first, compact } from 'lodash';
import { create, keyResolver, windowScheduler } from '@yornaath/batshit';
import { useQueries } from '@tanstack/react-query';

/**
 * Fetches proxy metadata for a list of funds.
 */
export const useProxyDataQuery = (fundIds: string[]) => {
  const results = useQueries({ queries: fundIds.map((id) => createQuery(id)) });
  return zipObject(
    fundIds,
    results.map(({ data }) => data),
  );
};

const proxyData = create({
  fetcher: async (fundIds: string[]) => {
    const result = await analysis({
      analyses: [
        {
          analysisType: 'DETAILED_PROXY_METADATA',
          relative: false,
          scenarios: [],
        },
      ],
      subjects: fundIds.map(
        (id) =>
          ({
            id,
            comparisonType: 'PRIMARY',
            subjectType: 'INVESTMENT',
          }) as AnalysisSubject,
      ),
    });

    return compact(result.content.analyses?.[0]?.detailedProxyMetadata?.map((dpm) => first(dpm)));
  },
  resolver: keyResolver('fundId'),
  scheduler: windowScheduler(10),
});

function createQuery(fundId: string) {
  return {
    staleTime: 5 * 60 * 1000,
    queryKey: createInvestmentProxyMetadataKey(fundId),
    queryFn: async () => proxyData.fetch(fundId),
    suspense: true,
  };
}
