import React from 'react';
import type { SearchResultWithUIState, SearchState } from './types';
import type { SORTDIR } from 'venn-components';
import { ConditionalOverlay } from 'venn-components';
import EmptyStateV2 from './EmptyStateV2';
import LibrariesTable from './LibrariesTable';
import type { MetricFilter } from 'venn-api';
import { GetColor } from 'venn-ui-kit';
import styled from 'styled-components';

interface LibraryResultsProps {
  data: SearchResultWithUIState[];
  sortDir: SORTDIR.ASC | SORTDIR.DESC;
  sortKey?: string;
  onSort: (key: string, dir: SORTDIR) => void;
  updateData: React.Dispatch<React.SetStateAction<SearchState>>;
  refetchSearch: (hideLoading?: boolean) => Promise<void>;
  /** Clears the search query (and the filters in v2) */
  onClear: () => void;
  /** Whether there is a search query or not */
  canClearQuery: boolean;
  /** Whether the current filters are different from the default or not */
  canClearFilters: boolean;
  loading?: boolean;
  disableSort?: boolean;
  metricFilters?: MetricFilter[];
  showAssetType?: boolean;
  showMorningstarCategory?: boolean;
  findSimilar: (item: SearchResultWithUIState) => void;
  trackSelectionMade: (item: SearchResultWithUIState) => void;
}

const LibraryResults = ({
  data,
  onSort,
  sortDir,
  sortKey,
  updateData,
  refetchSearch,
  onClear,
  loading,
  disableSort,
  canClearQuery,
  canClearFilters,
  trackSelectionMade,
  showAssetType,
  showMorningstarCategory,
  findSimilar,
  metricFilters = [],
}: LibraryResultsProps) => {
  const showEmptyState = !data.length && !loading;
  return (
    <div>
      <ConditionalOverlay condition={!!loading} className="qa-library-table">
        <LibrariesTable
          onSort={onSort}
          sortDir={sortDir}
          sortKey={sortKey}
          updateData={updateData}
          refetchSearch={refetchSearch}
          disableSort={disableSort}
          data={data}
          trackSelectionMade={trackSelectionMade}
          findSimilar={findSimilar}
          showAssetType={showAssetType}
          showMorningstarCategory={showMorningstarCategory}
          metricFilters={metricFilters}
        />
        {!loading && (
          <FootnoteCopy>
            * These metrics are calculated using the overlapping date range of our Two Sigma Factor Lens and the
            available timeseries for the investment or portfolio.
          </FootnoteCopy>
        )}
      </ConditionalOverlay>

      {showEmptyState ? (
        <EmptyStateV2 onClear={onClear} canClearQuery={canClearQuery} canClearFilters={canClearFilters} />
      ) : null}
    </div>
  );
};

const FootnoteCopy = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${GetColor.GreyScale.Grey70};
  font-size: 1rem;
`;

export default LibraryResults;
