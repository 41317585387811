import React, { useCallback, useState } from 'react';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { librarySearchParamsAtom, savedFiltersSelector } from 'venn-state';
import { isRequestSuccessful, logExceptionIntoSentry, useApi } from 'venn-utils';
import { createSavedSearch } from 'venn-api';
import { Body1, Notifications, NotificationType, SkeletalDropMenu, TypeaheadMenu, MenuActions } from 'venn-ui-kit';
import SaveFiltersTriggerComponent from '../shared/SaveFiltersTriggerComponent';
import styled from 'styled-components';
import { getSearchQueryParams } from '../../search';

export const SaveFiltersTriggerMenu = () => {
  const [filterName, setFilterName] = useState<string>();
  const params = useRecoilValue(librarySearchParamsAtom);
  const apiQueryParams = getSearchQueryParams(params);
  const saveSearch = useApi(createSavedSearch);
  const refreshFilters = useRecoilRefresher_UNSTABLE(savedFiltersSelector);

  const onConfirmSave = useCallback(
    (onClose: () => void) => {
      saveSearch({ name: filterName, query: apiQueryParams })
        .then((result) => {
          if (isRequestSuccessful(result)) {
            Notifications.notify(`Filters Set: ${result.content.name} saved successfully`, NotificationType.SUCCESS);
            refreshFilters();
            onClose();
          } else {
            Notifications.notify('Error saving filters.', NotificationType.ERROR);
          }
        })
        .catch((e) => {
          if (e.name === 'AbortError') {
            return;
          }
          Notifications.notify('Error saving filters.', NotificationType.ERROR);
          logExceptionIntoSentry(e);
        });
    },
    [saveSearch, filterName, apiQueryParams, refreshFilters],
  );

  const triggerComponent: React.ComponentProps<typeof SkeletalDropMenu>['triggerComponent'] = useCallback(
    (expanded, toggleMenu) => (
      <SaveFiltersTriggerComponent label="Save filters" onClick={toggleMenu} aria-expanded={expanded} aria-haspopup />
    ),
    [],
  );

  const menuComponent: React.ComponentProps<typeof SkeletalDropMenu>['menuComponent'] = useCallback(
    (_, onClose) => (
      <PopupMenuWrapper>
        <Header> Save Filter Set</Header>
        <InputBox className="qa-filter-name-input" placeholder="Name Filter Set" onChange={setFilterName} />
        <StyledMenuActions
          onCancel={onClose}
          onApply={() => {
            onConfirmSave(onClose);
          }}
          applyLabel="SAVE"
          disabled={!filterName}
        />
      </PopupMenuWrapper>
    ),
    [filterName, onConfirmSave],
  );

  return (
    <SkeletalDropMenu
      menuPosition="center"
      className="qa-save-filters"
      triggerComponent={triggerComponent}
      menuComponent={menuComponent}
      disabled={false}
      onApply={() => {}}
    />
  );
};

const PopupMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 173px;
  width: 296px;
  justify-content: space-between;
  align-items: stretch;
`;

const StyledMenuActions = styled(MenuActions)`
  width: 100%;
  margin: 0 0;
  padding-left: 15px !important;
`;

const InputBox = styled(TypeaheadMenu)`
  width: 86%;
  margin-left: 20px;
  margin-top: -15px;
`;

const Header = styled(Body1)`
  padding-top: 14px;
  padding-left: 20px;
  font-weight: bold;
`;

export default SaveFiltersTriggerMenu;
