import React from 'react';
import type { CustomCellRendererProps } from 'ag-grid-react';
import type { BulkManageRow, HistoricalWarningOrError } from '../types';
import { assertExhaustive } from 'venn-utils';
import { Icon } from 'venn-ui-kit';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@/components/ui/tooltip';

const TooltipIcon = ({ historicalError }: { historicalError: HistoricalWarningOrError }) => {
  switch (historicalError._type) {
    case 'error':
      return <Icon type="triangle-exclamation" className="text-venn-error" iconLabel="Cell historical error icon" />;
    case 'warning':
      return <Icon type="diamond-exclamation" className="text-venn-orange" iconLabel="Cell historical warning icon" />;
    default:
      return assertExhaustive(historicalError);
  }
};

const getTooltipContent = (historicalError: HistoricalWarningOrError) => {
  switch (historicalError._type) {
    case 'error':
      switch (historicalError.value) {
        case 'ALLOCATION_DATE_OUTSIDE_SUBJECT_RANGE':
        case 'INVESTMENT_ALLOCATIONS_NOT_AVAILABLE':
        case 'EARLIEST_ALLOCATION_DATE_PREDATES_SUBJECT_START_DATE':
        case 'INVESTMENT_RETURNS_NOT_AVAILABLE':
          return 'Returns must be available for all portfolio allocations.';
        default:
          return assertExhaustive(historicalError);
      }
    case 'warning':
      switch (historicalError.value) {
        case 'LATEST_ALLOCATION_DATE_EXCEEDS_SUBJECT_RETURNS_END_DATE':
          return 'You can upload additional returns data or add a proxy to increase this investment’s analysis range.';
        default:
          return assertExhaustive(historicalError);
      }
    default:
      return assertExhaustive(historicalError);
  }
};
type NameCellRendererProps = CustomCellRendererProps<BulkManageRow, BulkManageRow['name']> & {
  setRowWithOpenTooltip?: (rowId: string | undefined) => void;
};
export const NameCellRenderer = ({ data, value, setRowWithOpenTooltip }: NameCellRendererProps) => {
  const [openTooltip, setOpenTooltip] = React.useState(false);
  if (!data) {
    return value;
  }
  switch (data.rowType) {
    case 'investment':
      switch (data.allocationType) {
        case 'historical':
          return data.historicalError ? (
            <div className="flex gap-1">
              <Tooltip
                onOpenChange={(open) => {
                  setRowWithOpenTooltip?.(open ? data?.rowId : undefined);
                  setOpenTooltip(open);
                }}
                open={openTooltip}
              >
                <TooltipTrigger>
                  <TooltipIcon historicalError={data.historicalError} />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>{getTooltipContent(data.historicalError)}</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              {value}
            </div>
          ) : (
            value
          );
        case 'proforma':
          return value;
        default:
          return assertExhaustive(data);
      }
    case 'factor':
    case 'total':
    case 'axis':
      return value;
    default:
      return assertExhaustive(data);
  }
};
