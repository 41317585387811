import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import { useHasFF, withSuspense } from 'venn-utils';
import { Constants } from '../Layout';
import AllocationPanelForStudio from './AllocationPanelV2RecoilWrapper';
import { isEqual, isNil } from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import type { AllocatorConfig } from 'venn-state';
import {
  openAllocatorSubject,
  allocatorAnalysisSubject,
  originalAnalysisSubjectQuery,
  compareColumnOpen,
  openAllocatorSubjectConfig,
  isAllocatorOpenState,
} from 'venn-state';
import StudioComparisonContextStore from './StudioComparisonContextStore';
import { StudioAllocationPanelShimmerBlock, StudioAllocatorSubject, StudioAllocatorWindow } from './components';
import { useUnsavedAllocatorChangesModal } from './useUnsavedAllocatorChangesModal';
import { ConfirmUnsavedChangesModal } from '../../unsaved-changes/ConfirmUnsavedChangesModal';

const StudioAllocationPanel = () => {
  const hasApInRl = useHasFF('ap_in_rl_ff');

  const setIsAllocatorOpen = useSetRecoilState(isAllocatorOpenState);
  const [openSubject, setOpenAllocatorSubject] = useRecoilState(openAllocatorSubject);
  const [analysisSubjectInAllocator, onSubjectUpdate] = useRecoilState(allocatorAnalysisSubject(openSubject));
  const originalSubjectInAllocator = useRecoilValue(originalAnalysisSubjectQuery(openSubject));
  const compareOpen = useRecoilValue(compareColumnOpen);
  const [allocatorSubjectConfig, setOpenAllocatorSubjectConfig] = useRecoilState(openAllocatorSubjectConfig);

  // Two settings that can be made within Allocator Panel, sleeve (strategy/investment) and comparison portfolio selection,
  // We sync them to `openAllocatorSubjectConfig` recoil atom, so the view can pick up and track the changes.
  useEffect(() => {
    if (!openSubject) {
      // Only clear the config (that gets saved to analysis view) if there is no `openSubject`.
      // There can be a case when `analysisSubjectInAllocator` is empty, but `openSubject` is set; that signifies that
      // the config came from the saved view, but we haven't loaded the full analysis subject for it yet.
      setOpenAllocatorSubjectConfig(undefined);
    } else if (analysisSubjectInAllocator) {
      const allocatorConfig: AllocatorConfig | undefined = analysisSubjectInAllocator.portfolio && {
        portfolioId: analysisSubjectInAllocator.portfolio.id,
        selectedStrategyId: analysisSubjectInAllocator.strategyId,
        ...(analysisSubjectInAllocator.secondaryPortfolio && analysisSubjectInAllocator.secondaryPortfolioComparisonType
          ? {
              comparisonConfig: {
                comparisonType: analysisSubjectInAllocator.secondaryPortfolioComparisonType,
                comparisonPortfolioId: analysisSubjectInAllocator.secondaryPortfolio.id,
                comparisonPortfolioVersion:
                  analysisSubjectInAllocator.secondaryPortfolioComparisonType === 'SAVED'
                    ? analysisSubjectInAllocator.secondaryPortfolio.version
                    : undefined,
                comparisonSelectedStrategyId: analysisSubjectInAllocator.secondaryStrategy?.id,
              },
            }
          : {}),
      };
      if (isEqual(allocatorConfig, allocatorSubjectConfig)) {
        // Don't update if there was no change to prevent determining difference between two different objects with the
        // same contents.
        return;
      }
      setOpenAllocatorSubjectConfig(allocatorConfig);
    }
  }, [openSubject, analysisSubjectInAllocator, allocatorSubjectConfig, setOpenAllocatorSubjectConfig]);

  const onClosePanel = useCallback(() => {
    if (isNil(originalSubjectInAllocator) || isNil(analysisSubjectInAllocator)) {
      return;
    }
    onSubjectUpdate(undefined);
    setIsAllocatorOpen(false);
    setOpenAllocatorSubject(undefined);
    setOpenAllocatorSubjectConfig(undefined);
  }, [
    analysisSubjectInAllocator,
    onSubjectUpdate,
    originalSubjectInAllocator,
    setIsAllocatorOpen,
    setOpenAllocatorSubject,
    setOpenAllocatorSubjectConfig,
  ]);

  const {
    unsavedAllocatorChangesModalOpen,
    onCloseAllocatorClick,
    onConfirmCloseAndLooseChanges: onConfirmClose,
    closeUnsavedAllocatorChangesModal,
  } = useUnsavedAllocatorChangesModal(onClosePanel);

  const allocatorWidth =
    1 + (compareOpen ? Constants.ALLOCATION_PANEL_TOTAL_WIDTH : Constants.ALLOCATION_PANEL_MAIN_WIDTH);

  const [newPortfolioAnalysisSubject, storeNewPortfolioAnalysisSubject] = useState<AnalysisSubject | undefined>();
  const onReplacePortfolioInAllocator = useCallback(
    (subject: AnalysisSubject) => {
      if (!subject.portfolio) {
        return;
      }
      // Reset current portfolio's allocator state
      onSubjectUpdate(undefined);
      // Change selected subject to newly saved one
      setOpenAllocatorSubject({ portfolioId: subject.portfolio.id });
      // Store analysis subject for newly saved portfolio to be updated once `allocatorSubject` recoil selector reloads
      storeNewPortfolioAnalysisSubject(subject);
    },
    [setOpenAllocatorSubject, onSubjectUpdate, storeNewPortfolioAnalysisSubject],
  );
  useEffect(() => {
    if (newPortfolioAnalysisSubject && openSubject?.portfolioId === newPortfolioAnalysisSubject.portfolio?.id) {
      onSubjectUpdate(newPortfolioAnalysisSubject);
      storeNewPortfolioAnalysisSubject(undefined);
    }
  }, [newPortfolioAnalysisSubject, openSubject, onSubjectUpdate]);

  return (
    <StudioAllocatorWindow hasApInRl={hasApInRl}>
      <StudioAllocatorSubject width={allocatorWidth} onClose={onCloseAllocatorClick} />
      {!isNil(analysisSubjectInAllocator?.portfolio) && (
        <StudioComparisonContextStore analysisSubject={analysisSubjectInAllocator}>
          <AllocationPanelWrapper data-testid="studio-allocator-wrapper">
            <AllocationPanelForStudio onReplacePortfolioInAllocator={onReplacePortfolioInAllocator} />
          </AllocationPanelWrapper>
        </StudioComparisonContextStore>
      )}
      {unsavedAllocatorChangesModalOpen && (
        <ConfirmUnsavedChangesModal onProceed={onConfirmClose} onReject={closeUnsavedAllocatorChangesModal} />
      )}
    </StudioAllocatorWindow>
  );
};

export default withSuspense(<StudioAllocationPanelShimmerBlock />, React.memo(StudioAllocationPanel));

const AllocationPanelWrapper = styled.div`
  grid-area: allocator-panel;
  background-color: ${GetColor.White};
  overflow: hidden;
`;
