import { isNil, last } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { type BlockId, rangeDebugQuery, type StudioRequestSubject, subjectAsOfDate } from 'venn-state';
import { Flexbox, GetColor, Icon, Tooltip } from 'venn-ui-kit';
import { getSubjectFromRequestSubject } from 'venn-utils';

type HistoricalPortfolioStudioHeaderTooltipsProps = {
  blockId: BlockId;
  subject: StudioRequestSubject;
};

const OUTDATED_WARNING_THRESHOLD_MONTHS = 6;

const shouldShowAllocationsOutdatedWarning = (
  selectedAsOfDate: number | undefined,
  allSortedAllocationDates: number[],
) => {
  if (!selectedAsOfDate) {
    return false;
  }

  const lastReportedAllocationDateOnOrBeforeSelectedAsOfDate = last(
    allSortedAllocationDates.filter((allocationDate) => allocationDate <= selectedAsOfDate),
  );

  if (!lastReportedAllocationDateOnOrBeforeSelectedAsOfDate) {
    return false;
  }

  // check if the gap between selected as of date and last reported allocation date is 6+ months
  return (
    moment(selectedAsOfDate).diff(moment(lastReportedAllocationDateOnOrBeforeSelectedAsOfDate), 'months') >=
    OUTDATED_WARNING_THRESHOLD_MONTHS
  );
};

export const HistoricalPortfolioStudioHeaderTooltips = ({
  blockId,
  subject,
}: HistoricalPortfolioStudioHeaderTooltipsProps) => {
  const rawSubject = getSubjectFromRequestSubject(subject);
  const rangeDebug = useRecoilValue(rangeDebugQuery(rawSubject));
  const historicalPortfolioMetadata = rangeDebug?.historicalPortfolioMetadata;
  const asOfDate = useRecoilValue(subjectAsOfDate([blockId, rawSubject]));

  if (isNil(historicalPortfolioMetadata)) {
    return null;
  }

  return (
    <Flexbox direction="row" gap={4}>
      {historicalPortfolioMetadata.isAllocationsForwardFilled && (
        <Tooltip
          usePortal
          content="End of period allocations were not found. Allocations closest to end of period were used."
        >
          <AllocationsSnappedIcon iconLabel="allocations snapped icon" />
        </Tooltip>
      )}
      {shouldShowAllocationsOutdatedWarning(asOfDate, historicalPortfolioMetadata.allSortedAllocationDates) && (
        <Tooltip
          usePortal
          content="Most recent updated portfolio allocations are more than 6 months out of date and may have drifted."
        >
          <AllocationsOutdatedIcon iconLabel="allocations outdated icon" />
        </Tooltip>
      )}
    </Flexbox>
  );
};

const AllocationsSnappedIcon = styled(Icon).attrs({ type: 'square-info' })`
  color: ${GetColor.GreyScale.Grey80};
`;

const AllocationsOutdatedIcon = styled(Icon).attrs({ type: 'diamond-exclamation' })`
  color: ${GetColor.Orange};
`;
