import React, { useMemo } from 'react';
import { getSelectableMetricKeys, requiresAnalysisRequest, requiresInfographic, useHasFF } from 'venn-utils';
import { useRecoilValue } from 'recoil';
import { blockInfoGraphicType, blockMaxSubjects, blockMetrics, blockSettings } from 'venn-state';
import StyledEmptyState from '../StyledEmptyState';
import { useAnalysis } from '../../logic/useAnalysis';
import { useSelectedMetrics } from '../../logic/useSelectedMetrics';
import { TRANSIENT_ERROR } from '../../logic/customBlockUtils';
import { getAppTitle } from 'venn-ui-kit';
import { StyledErrorState } from '../StyledErrorState';
import type { ErrorWrapper } from './errorWrapperTypes';
import { isEmpty } from 'lodash';
import { SupportedErrorCodes, type Message } from 'venn-api';

const ERROR_CODES_SOLVABLE_BY_PROXY = SupportedErrorCodes
  ? [
      // Until we have a better way for users to debug WHICH investments are the ones causing issues, we don't want to tell
      // them to proxy SupportedErrorCodes.NoCommonOverlap
      // SupportedErrorCodes.NoCommonOverlap,
      SupportedErrorCodes.NoOverlapWithFactorReturns,
      SupportedErrorCodes.InsufficientReturns,
      SupportedErrorCodes.InsufficientHistoryForDrawdown,
      SupportedErrorCodes.InsufficientDataForScenario,
      SupportedErrorCodes.PeriodTooNarrowForFactorTrend,
    ]
  : [];

export const MetricsErrorWrapper: ErrorWrapper = ({ blockId, children }) => {
  const hasBulkProxyFf = useHasFF('portfolio_bulk_proxy_ff');
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const customBlockType = blockSetting.customBlockType;
  const maxSubjects = useRecoilValue(blockMaxSubjects(blockId));
  const selectedMetricIds = useRecoilValue(blockMetrics(blockId));
  const selectedInfoGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const infoGraphicType = requiresInfographic(customBlockType) ? selectedInfoGraphicType : 'DEFAULT';

  const needsFetch = requiresAnalysisRequest(customBlockType);
  const { analyses } = useAnalysis(blockId);

  const selectedMetrics = useSelectedMetrics();
  // NOTE(collin.irwin): I am confused by the below functions behavior and names.  Why do they use only metrics[0] when the name says allMetrics?
  const allMetricsHaveErrors = useMemo(() => {
    return (
      needsFetch &&
      !isEmpty(analyses) &&
      selectedMetrics &&
      analyses.slice(0, maxSubjects).every((analysisResult) => {
        const analysis = analysisResult.find((analysis) => analysis?.analysisType === selectedMetrics[0]?.analysisType);
        return analysis?.message ?? analysis?.globalError;
      })
    );
  }, [needsFetch, analyses, selectedMetrics, maxSubjects]);

  const allMetricsHaveErrorsMessage = allMetricsHaveErrors
    ? analyses
        .slice(0, maxSubjects)
        .flatMap((analysisResult) => {
          const analysis = analysisResult.find(
            (analysis) => analysis?.analysisType === selectedMetrics[0]?.analysisType,
          );
          return analysis?.message ?? analysis?.globalError;
        })
        .find((m) => !!m)
    : undefined;

  const allMetricsHaveTransientErrors = useMemo(() => {
    return (
      needsFetch &&
      !isEmpty(analyses) &&
      selectedMetrics &&
      analyses.every((a) => {
        const message = a.find((analysis) => analysis?.analysisType === selectedMetrics[0]?.analysisType)?.message;
        return message && message.code === TRANSIENT_ERROR;
      })
    );
  }, [needsFetch, analyses, selectedMetrics]);

  if (allMetricsHaveTransientErrors) {
    return (
      <StyledEmptyState
        selectedRefId={blockId}
        header="Temporarily Unable to Run Analysis"
        message={`Please try again later or contact ${getAppTitle()} Support`}
      />
    );
  }

  if (allMetricsHaveErrors) {
    return (
      <StyledEmptyState
        selectedRefId={blockId}
        header="Unable to run analysis"
        message={getFinalText(hasBulkProxyFf, allMetricsHaveErrorsMessage)}
      />
    );
  }

  const hasMetricRestrictions = !!getSelectableMetricKeys(customBlockType, infoGraphicType);
  if (
    ![
      'GRID',
      'PIVOTED_GRID',
      'GRID_TREE',
      'SCATTER',
      'IMAGE',
      'DEFAULT',
      'PRIVATE_CASH_FLOW',
      'PEER_GROUP',
      'BOX_CHART',
    ].includes(infoGraphicType ?? 'DEFAULT') &&
    selectedMetricIds.length > 1 &&
    !hasMetricRestrictions &&
    customBlockType !== 'SCENARIO'
  ) {
    return (
      <StyledErrorState
        selectedRefId={blockId}
        header="Charting is only available with 1 metric selected"
        message="Please select only 1 metric"
      />
    );
  }

  return children;
};

function getFinalText(hasBulkProxyFf: boolean, msg: Message | undefined): string {
  const genericError = 'Please check the block inputs and configuration';
  if (!msg?.text) {
    return genericError;
  }
  if (hasBulkProxyFf && ERROR_CODES_SOLVABLE_BY_PROXY.includes(msg.code)) {
    const trimmedText = msg.text.trim();
    const trimmedTextWithPeriod = trimmedText.endsWith('.') ? trimmedText : `${trimmedText}.`;
    return `${trimmedTextWithPeriod} Go to Manage Data to add proxies.`;
  }

  if (Object.values(SupportedErrorCodes).includes(msg.code)) {
    return msg.text;
  }

  return genericError;
}
