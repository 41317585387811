import React from 'react';
import type { AnalysisLabelShape } from 'venn-utils';
import { Dates, getPeriodLengthWithFrequency } from 'venn-utils';
import type { VennColors } from 'venn-ui-kit';
import { Icon, GetColor, Tooltip } from 'venn-ui-kit';
import type { FrequencyEnum } from 'venn-api';
import type { BasicTableColumn } from 'venn-components';
import { compact } from 'lodash';
import styled from 'styled-components';
import BarChart from '../components/scenario-analysis/BarChart';

export interface NotablePeriodZipped {
  subject: number;
  benchmark?: number;
  category?: number;
  start: number;
  end?: number;
  recovery?: number;
  type: string;
  name?: string;
  id?: number;
  loading: boolean;
}

const generateName = (analysisFrequency: FrequencyEnum | undefined, data: NotablePeriodZipped) => {
  if (data.name) {
    return data.name;
  }

  const start = data.start;

  return analysisFrequency === 'DAILY'
    ? `${Dates.toDayMonthYear(start)} - ${data.end ? Dates.toDayMonthYear(data.end) : 'now'}`
    : `${Dates.toMonthYear(start)} - ${data.end ? Dates.toMonthYear(data.end) : 'now'}`;
};

export const formatDate = (
  analysisFrequency: FrequencyEnum | undefined,
  date: number | undefined,
  placeholder?: string,
) => (date ? (analysisFrequency === 'DAILY' ? Dates.toDayMonthYear(date) : Dates.toMonthYear(date)) : placeholder);

const isPredefinedDrawdown = (data: NotablePeriodZipped) => {
  return data.type === 'PREDEFINED' && data.subject < 0;
};

const formatTimeDifference = (
  analysisFrequency: FrequencyEnum | undefined,
  start: number,
  end: number | undefined,
  placeholder: string,
) => {
  if (!end) {
    return placeholder;
  }
  return getPeriodLengthWithFrequency(analysisFrequency, start, end);
};

const isEmptyRow = (data: NotablePeriodZipped) => {
  return !(typeof data.subject === 'number');
};

const numberCellCommonStyle: React.CSSProperties = {
  textAlign: 'right',
  paddingLeft: '15px',
  maxWidth: '100px',
};

export const getColumnsForNotableHistoricalPeriods = ({
  max,
  hasBenchmark,
  hasComparison,
  hasCategory,
  primaryHeading,
  primaryHeadingTooltipContent,
  labels,
  Colors,
  analysisFrequency,
  onDelete,
}: {
  max: number;
  hasBenchmark: boolean;
  hasComparison: boolean;
  hasCategory: boolean;
  primaryHeading: string;
  primaryHeadingTooltipContent: string;
  labels: AnalysisLabelShape;
  Colors: VennColors;
  analysisFrequency?: FrequencyEnum;
  onDelete: (id: number) => void;
}): BasicTableColumn<NotablePeriodZipped>[] => {
  const initialColumns = [
    {
      label: '',
      accessor: 'delete',
      cellStyle: {
        textAlign: 'center' as const,
        width: '20px',
        maxWidth: '20px',
        minWidth: '20px',
      },
      headerStyle: {
        textAlign: 'center' as const,
        width: '20px',
        maxWidth: '20px',
        minWidth: '20px',
      },
      cellRenderer: (data: NotablePeriodZipped) => <TrashIcon type="trash" onClick={() => onDelete(data.id!)} />,
    },
    {
      label: primaryHeading,
      accessor: 'start',
      cellStyle: {
        textAlign: 'left' as const,
        paddingLeft: '10px',
        minWidth: '120px',
      },
      headerStyle: {
        textAlign: 'left' as const,
        paddingLeft: '10px',
        width: '22%',
      },
      cellRenderer: (data: NotablePeriodZipped) => (
        <div>
          {generateName(analysisFrequency, data)}
          {isEmptyRow(data) && !data.loading && (
            <Tooltip content={primaryHeadingTooltipContent}>
              <InfoIcon type="info-circle" />
            </Tooltip>
          )}
        </div>
      ),
      excelCellRenderer: (data: NotablePeriodZipped) => ({ value: generateName(analysisFrequency, data) }),
    },
    {
      label: 'Start Date',
      accessor: 'start',
      sortable: true,
      cellStyle: primaryHeading
        ? {
            ...numberCellCommonStyle,
            minWidth: '80px',
          }
        : undefined,
      headerStyle: primaryHeading
        ? {
            ...numberCellCommonStyle,
            minWidth: '80px',
          }
        : undefined,
      cellRenderer: (data: NotablePeriodZipped) => <div>{formatDate(analysisFrequency, data.start)}</div>,
      excelCellRenderer: (data: NotablePeriodZipped) => ({ value: formatDate(analysisFrequency, data.start) }),
    },
    {
      label: 'End Date',
      accessor: 'end',
      sortable: true,
      cellStyle: primaryHeading
        ? {
            ...numberCellCommonStyle,
            minWidth: '80px',
          }
        : undefined,
      headerStyle: primaryHeading
        ? {
            ...numberCellCommonStyle,
            minWidth: '80px',
          }
        : undefined,
      cellRenderer: (data: NotablePeriodZipped) => <div>{formatDate(analysisFrequency, data.end, '--')}</div>,
      excelCellRenderer: (data: NotablePeriodZipped) => ({ value: formatDate(analysisFrequency, data.end, '--') }),
    },
  ];

  const commonColumns = getCommonColumns({
    max,
    hasBenchmark,
    hasComparison,
    hasCategory,
    labels,
    Colors,
    analysisFrequency,
  });
  return [...initialColumns, ...commonColumns];
};

export const getColumnsForHistoricalDrawdownAndRallyScanner = ({
  max,
  hasBenchmark,
  hasComparison,
  hasCategory,
  labels,
  Colors,
  analysisFrequency,
}: {
  max: number;
  hasBenchmark: boolean;
  hasComparison: boolean;
  hasCategory: boolean;
  labels: AnalysisLabelShape;
  Colors: VennColors;
  analysisFrequency?: FrequencyEnum;
}): BasicTableColumn<NotablePeriodZipped>[] => {
  const initialColumns = [
    {
      label: '',
      accessor: 'empty',
      cellStyle: {
        width: '20px',
        maxWidth: '20px',
        minWidth: '20px',
      },
      headerStyle: {
        width: '20px',
        maxWidth: '20px',
        minWidth: '20px',
      },
    },
    {
      label: 'Start Date',
      accessor: 'start',
      sortable: true,
      cellRenderer: (data: NotablePeriodZipped) => <div>{formatDate(analysisFrequency, data.start)}</div>,
      excelCellRenderer: (data: NotablePeriodZipped) => ({ value: formatDate(analysisFrequency, data.start) }),
    },
    {
      label: 'End Date',
      accessor: 'end',
      sortable: true,
      cellRenderer: (data: NotablePeriodZipped) => <div>{formatDate(analysisFrequency, data.end, '--')}</div>,
      excelCellRenderer: (data: NotablePeriodZipped) => ({ value: formatDate(analysisFrequency, data.end, '--') }),
    },
  ];
  const commonColumns = getCommonColumns({
    max,
    hasBenchmark,
    hasComparison,
    hasCategory,
    labels,
    Colors,
    analysisFrequency,
  });
  return [...initialColumns, ...commonColumns];
};

const getCommonColumns = ({
  max,
  hasBenchmark,
  hasComparison,
  hasCategory,
  labels,
  Colors,
  analysisFrequency,
}: {
  max: number;
  hasBenchmark: boolean;
  hasComparison: boolean;
  hasCategory: boolean;
  labels: AnalysisLabelShape;
  Colors: VennColors;
  analysisFrequency?: FrequencyEnum;
}): BasicTableColumn<NotablePeriodZipped>[] =>
  compact([
    {
      label: 'Period Length (Months)',
      accessor: 'length',
      sortable: true,
      sortValueFunc: (data) => (data.end ? data.end - data.start : 0),
      cellStyle: {
        ...numberCellCommonStyle,
        minWidth: '100px',
      },
      headerStyle: {
        ...numberCellCommonStyle,
        width: '10%',
      },
      cellRenderer: (data) => <div>{formatTimeDifference(analysisFrequency, data.start, data.end, '--')}</div>,
      excelCellRenderer: (data) => ({ value: formatTimeDifference(analysisFrequency, data.start, data.end, '--') }),
    },
    {
      label: 'Recovery Length (Months)',
      accessor: 'recovery',
      sortable: true,
      sortValueFunc: (data) => (data.type !== 'RALLY' && data.recovery ? data.recovery - data.end! : 0),
      cellStyle: {
        ...numberCellCommonStyle,
        minWidth: '100px',
      },
      headerStyle: {
        ...numberCellCommonStyle,
        width: '10%',
      },
      cellRenderer: (data) => (
        <div>
          {data.recovery && (data.type === 'DRAWDOWN' || isPredefinedDrawdown(data))
            ? getRecoveryMonths(data.end!, data.recovery, analysisFrequency)
            : '--'}
        </div>
      ),
      excelCellRenderer: (data) => ({
        value:
          data.recovery && (data.type === 'DRAWDOWN' || isPredefinedDrawdown(data))
            ? getRecoveryMonths(data.end!, data.recovery, analysisFrequency)
            : '--',
      }),
    },
    {
      label: labels.main,
      accessor: 'subject',
      sortable: true,
      cellStyle: {
        ...numberCellCommonStyle,
        minWidth: '60px',
      },
      headerStyle: {
        ...numberCellCommonStyle,
        minWidth: '60px',
      },
      cellRenderer: (data) => <div>{data.subject?.toFixed(1).concat(' %') ?? '--'}</div>,
      excelCellRenderer: (data) => ({
        value: data.subject / 100,
        digits: 1,
        percentage: true,
      }),
    },

    hasComparison
      ? {
          label: labels.comparison,
          accessor: 'comparison',
          sortable: true,
          cellStyle: {
            ...numberCellCommonStyle,
            minWidth: '60px',
          },
          headerStyle: {
            ...numberCellCommonStyle,
            minWidth: '60px',
            paddingLeft: '15px',
          },
          cellRenderer: (data) => <div>{data.category?.toFixed(1).concat(' %') ?? '--'}</div>,
          excelCellRenderer: (data) => ({
            value: (data.category || 0) / 100,
            digits: 1,
            percentage: true,
          }),
        }
      : null,
    hasBenchmark
      ? {
          label: labels.benchmark,
          accessor: 'benchmark',
          sortable: true,
          cellStyle: {
            ...numberCellCommonStyle,
            minWidth: '60px',
          },
          headerStyle: {
            ...numberCellCommonStyle,
            minWidth: '60px',
          },
          cellRenderer: (data) => <div>{data.benchmark?.toFixed(1).concat(' %') ?? '--'}</div>,
          excelCellRenderer: (data) => ({
            value: data.benchmark ? data.benchmark / 100 : undefined,
            digits: 1,
            percentage: true,
          }),
        }
      : null,
    hasCategory
      ? {
          label: labels.category,
          accessor: 'category',
          sortable: true,
          cellStyle: {
            ...numberCellCommonStyle,
            minWidth: '60px',
          },
          headerStyle: {
            ...numberCellCommonStyle,
            minWidth: '60px',
          },
          cellRenderer: (data) => <div>{data.category?.toFixed(1).concat(' %') ?? '--'}</div>,
          excelCellRenderer: (data) => ({
            value: (data.category || 0) / 100,
            digits: 1,
            percentage: true,
          }),
        }
      : null,
    {
      label: '',
      accessor: '',
      cellStyle: {
        borderLeftStyle: 'dashed',
        borderLeftWidth: 1,
        borderLeftColor: Colors.Grey,
        borderRightStyle: 'dashed',
        borderRightWidth: 1,
        borderRightColor: Colors.Grey,
        minWidth: '150px',
      },
      headerStyle: {
        width: '15%',
      },
      cellRenderer: (data) => (
        <BarChart
          loading={data.loading}
          max={max}
          mainData={data.subject}
          benchmarkData={hasBenchmark ? data.benchmark : undefined}
          comparisonData={hasComparison ? data.category : undefined}
          categoryData={hasCategory ? data.category : undefined}
        />
      ),
      excelCellRenderer: () => ({ value: null }),
      excelHeaderRenderer: () => ({ value: null }),
    },
  ]);

const getRecoveryMonths = (endDate: number, recoveryDate: number, analysisFrequency?: FrequencyEnum) => {
  if (endDate === recoveryDate) {
    return '--';
  }
  return getPeriodLengthWithFrequency(analysisFrequency, endDate, recoveryDate);
};

const TrashIcon = styled(Icon)`
  color: ${GetColor.Grey};
  min-width: 20px;
  max-width: 20px;
  width: 20px;
`;

const InfoIcon = styled(Icon)`
  padding-left: 5px;
  font-size: 12px;
`;
