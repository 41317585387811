import React from 'react';
import BaseButton from '../../button/Button';
import { getRangeFromType, getRangeTypeShortName } from '../granularities/logic';
import type { DateRange, Granularity } from '../types';
import styled from 'styled-components';
import { analyticsService } from 'venn-utils';
import { GetColor } from '../../../style/color';
import { kebabCase } from 'lodash';
import type { FactorLensWithReturns, FrequencyEnum } from 'venn-api';

export type RangeType = 'ytd' | '1yr' | '3yr' | '5yr' | '7yr' | '10yr' | 'full' | 'full_no_factor_constraint';
export type RangePickerDisposition = 'left' | 'right' | 'vertical';

export const DEFAULT_RANGE_OPTIONS: RangeType[] = ['ytd', '1yr', '3yr', '5yr', 'full', 'full_no_factor_constraint'];

interface RangePickerProps {
  value: RangeType | undefined;
  range: DateRange;
  granularity: Granularity;
  options?: RangeType[];
  disposition?: RangePickerDisposition;
  onChange?: (range: RangeType) => void;
  maxFrequency?: FrequencyEnum;
  factorLens: FactorLensWithReturns | undefined;
}

function canShow(
  value: RangeType,
  range: DateRange,
  granularity: Granularity,
  maxFrequency: FrequencyEnum,
  factorLens: FactorLensWithReturns | undefined,
) {
  if (value === 'full_no_factor_constraint' || value === 'full') {
    return true;
  }

  const presetRange = getRangeFromType(value, range, granularity, maxFrequency, factorLens);
  return !presetRange.from || !range.from || presetRange.from >= range.from;
}

const RangePicker = ({
  value,
  range,
  disposition,
  granularity,
  options = DEFAULT_RANGE_OPTIONS,
  onChange,
  maxFrequency,
  factorLens,
}: RangePickerProps) => {
  const filteredOptions = options.filter((option) =>
    canShow(option, range, granularity, maxFrequency ?? 'DAILY', factorLens),
  );

  const onClick = (clickedRange: RangeType) => {
    onChange?.(clickedRange);
    analyticsService.ctaClicked({
      filled: false,
      locationOnPage: 'date range picker',
      purpose: 'select date range',
      text: clickedRange,
      type: 'date range picker',
    });
  };

  return (
    <Container disposition={disposition}>
      {filteredOptions.map((option) => (
        <Button
          key={option}
          className={`qa-date-picker-preset-${kebabCase(option)}`}
          dominant={value === option}
          aria-pressed={value === option}
          onClick={() => onClick(option)}
        >
          {getRangeTypeShortName(option)}
        </Button>
      ))}
    </Container>
  );
};

const Button = styled(BaseButton)`
  margin: 0 2px;
  padding: 9px 7px;
  letter-spacing: 0.3px;
  min-width: fit-content;
  white-space: nowrap;
  flex: 1;
  &:first-child {
    margin-left: 0;
  }
`;

const Container = styled.div<{ disposition?: RangePickerDisposition }>`
  background-color: ${GetColor.DEPRECATED_DivergingColor.B1};
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: ${(props) => (props.disposition === 'left' ? 'flex-start' : 'flex-end')};

  ${(props) =>
    props.disposition === 'vertical'
      ? `
      flex-direction: column;
      height: unset;
      padding: 10px 20px;

      ${Button} {
          margin: 2px 0;
          width: 100%;
      }
  `
      : ''}
`;

export default RangePicker;
