import React, { useCallback } from 'react';
import styled from 'styled-components';
import every from 'lodash/every';
import FilterTrigger from '../triggers/FilterTrigger';
import type { CheckboxMenuProps } from '../menus/CheckboxMenu';
import { default as CheckboxMenu } from '../menus/CheckboxMenu';
import BaseDropMenu, { type DropMenuProps } from './BaseDropMenu';
import { buildLabel } from '../logic/checkbox-logic';
import type { BaseDropMenuProps, MenuContainerProps } from '../types';

export interface FilterDropMenuProps<T> extends CheckboxMenuProps<T>, BaseDropMenuProps<T>, MenuContainerProps {
  location?: string;
}

export const DropMenu = <T,>({
  label,
  items,
  className,
  openByDefault,
  usePortal,
  disabled,
  width,
  height,
  header,
  onChange,
  innerRef,
  selectedMetricText,
  tooltipPortal,
  location,
}: FilterDropMenuProps<T>) => {
  const onClear = useCallback(() => {
    onChange(
      items.map((i) => ({
        ...i,
        checked: true,
      })),
    );
  }, [items, onChange]);

  const allSelected = every(items, { checked: true });
  const selection = buildLabel(items, selectedMetricText);

  const triggerComponent: DropMenuProps<T>['triggerComponent'] = useCallback(
    (expanded, _, onExpand) => (
      <FilterTrigger
        className={className}
        disabled={disabled}
        label={label}
        expanded={expanded}
        selection={selection}
        all={allSelected}
        innerRef={innerRef}
        onClick={onExpand}
        onClear={onClear}
        width={width}
        square
      />
    ),
    [selection, allSelected, label, className, disabled, innerRef, onClear, width],
  );

  const menuComponent: DropMenuProps<T>['menuComponent'] = useCallback(
    (_, onCollapse, menuClassName) => (
      <MenuWrapper className={menuClassName}>
        <CheckboxMenu<T>
          width={width}
          height={height}
          header={header}
          items={items}
          onChange={onChange}
          onCollapse={onCollapse}
          hideSelectAll={disabled}
          tooltipPortal={tooltipPortal}
        />
      </MenuWrapper>
    ),
    [header, items, onChange, width, height, disabled, tooltipPortal],
  );

  return (
    <BaseDropMenu
      analyticsProps={{
        location,
        label,
        selection,
        selectType: 'multiselect',
        type: 'primary dropdown',
      }}
      openByDefault={openByDefault}
      usePortal={usePortal}
      filteredItems={items}
      triggerComponent={triggerComponent}
      menuComponent={menuComponent}
    />
  );
};

const MenuWrapper = styled.div`
  margin-top: 10px;
`;

export default DropMenu;
