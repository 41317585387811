import React, { useContext, useMemo } from 'react';
import type { AgGridReact } from 'ag-grid-react';
import type { BulkManageRow } from '../types';
import type {
  ColDef,
  ColGroupDef,
  GetDataPath,
  GetRowIdFunc,
  GridColumnsChangedEvent,
  IRowNode,
  RowClassParams,
  RowClassRules,
  RowSelectionOptions,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { isBulkManageFundRow, isSecondaryInvestment } from '../utils';
import { AssetTypeEnum } from 'venn-api';
import styled, { ThemeContext } from 'styled-components';
import { isNil, sumBy } from 'lodash';
import { columnStyles } from '../columns';
import { GetColor, ZIndex } from 'venn-ui-kit';
import { GroupCellClass, NameCellClass, paginationOptions, SecondaryCellClass } from '../constants';
import DataGrid from '../../data-grid/DataGrid';

const rowSelection: RowSelectionOptions<BulkManageRow> = {
  mode: 'multiRow',
  groupSelects: 'descendants',
  headerCheckbox: false,
  checkboxes: false,
  hideDisabledCheckboxes: true,
  isRowSelectable: ({ data }) =>
    !isBulkManageFundRow(data) || (data.investment?.assetType !== AssetTypeEnum.BENCHMARK && !data.secondary),
};

const defaultColDef: ColDef<BulkManageRow> = {
  suppressHeaderMenuButton: true,
  suppressMovable: true,
  editable: false,
  resizable: false,
};

const defaultIcons = {
  groupExpanded: '<i class="fa fa-caret-down"/>',
  groupContracted: '<i class="fa fa-caret-right"/>',
  check: '<i class="fa fa-caret-up"/>',
};

const getDataPath: GetDataPath<BulkManageRow> = (data) => data.fullPath;
const getRowId: GetRowIdFunc<BulkManageRow> = ({ data }) => data.rowId;

interface BulkDataGridProps {
  gridRef: React.MutableRefObject<AgGridReact | null>;
  rowData: BulkManageRow[];
  pinnedRowData: BulkManageRow[];
  columnDefs: (ColDef | ColGroupDef)[];
  onSelectionChanged: (event: SelectionChangedEvent) => void;
  groupHeaderHeight?: number;
  isExternalFilterPresent?: () => boolean;
  doesExternalFilterPass?: (node: IRowNode<BulkManageRow>) => boolean;
  disableSelection?: boolean;
  autoGroupColumnDef: ColDef<BulkManageRow, BulkManageRow['name']>;
  rowClassRules: RowClassRules<BulkManageRow>;
}
export const BulkManagementTablePrimitivesGrid = React.memo(
  ({
    gridRef,
    rowData,
    columnDefs,
    onSelectionChanged,
    pinnedRowData,
    groupHeaderHeight,
    isExternalFilterPresent,
    doesExternalFilterPass,
    disableSelection,
    rowClassRules,
    autoGroupColumnDef,
  }: BulkDataGridProps) => {
    const { Colors } = useContext(ThemeContext);

    const defaultRowStyle = useMemo(() => {
      const secondaryRowStyle = { background: Colors.GreyScale.Grey10 };
      return ({ node }: RowClassParams<BulkManageRow, unknown>) =>
        isSecondaryInvestment(node.data) ? secondaryRowStyle : undefined;
    }, [Colors.GreyScale.Grey10]);

    const [apiMinWidth, setApiMinWidth] = React.useState(0);
    const onGridColumnsChanged = (params: GridColumnsChangedEvent) => {
      setApiMinWidth(
        resolveColumnMinWidth(autoGroupColumnDef) +
          sumBy(params.api.getColumns(), (col) => resolveColumnMinWidth(col.getColDef())),
      );
    };

    return (
      <GridContainer>
        <StyledDataGrid
          minWidth={apiMinWidth}
          onGridColumnsChanged={onGridColumnsChanged}
          className="ag-theme-alpine"
          gridRef={gridRef}
          getRowId={getRowId}
          pagination={rowData.length > 500}
          paginationPageSize={500}
          paginationPageSizeSelector={paginationOptions}
          rowSelection={!disableSelection ? rowSelection : undefined}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowData}
          getRowStyle={defaultRowStyle}
          domLayout="autoHeight"
          icons={defaultIcons}
          rowHeight={37}
          headerHeight={25}
          onSelectionChanged={onSelectionChanged}
          suppressContextMenu
          treeData
          autoGroupColumnDef={autoGroupColumnDef}
          getDataPath={getDataPath}
          groupDefaultExpanded={-1} // -1 expands all groups by default
          rowClassRules={rowClassRules}
          pinnedBottomRowData={pinnedRowData}
          groupHeaderHeight={groupHeaderHeight}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
        />
      </GridContainer>
    );
  },
);
BulkManagementTablePrimitivesGrid.displayName = 'BulkManagementTablePrimitivesGrid';

function resolveColumnMinWidth({
  flex,
  width,
  minWidth,
  maxWidth,
}: {
  flex?: number;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
}): number {
  if (isNil(minWidth ?? width ?? maxWidth ?? flex)) {
    return 200;
  }
  minWidth ??= 0;
  maxWidth ??= Infinity;

  if (maxWidth < minWidth) {
    return maxWidth;
  }
  if (flex) {
    return minWidth;
  }
  return Math.max(width ?? 0, minWidth);
}

const StyledDataGrid = styled(DataGrid<BulkManageRow>)<{
  minWidth: number;
}>`
  width: max(${(props) => props.minWidth}px, 100%);

  .ag-header-group-cell,
  .ag-header-cell-comp-wrapper {
    height: 100%;
    padding: 0 !important;
  }
`;

const GridContainer = styled.div`
  .ag-header-cell {
    border-top: none !important;
  }

  .ag-root-wrapper {
    font-family: ${(props) => props.theme.Typography.fontFamily};

    --ag-cell-horizontal-border: solid ${GetColor.Grey};
    --ag-header-background-color: ${GetColor.White};
    --ag-background-color: ${GetColor.White};
    --ag-odd-row-background-color: ${GetColor.White};

    --ag-row-group-indent-size: 20px;
    --ag-cell-widget-spacing: 4px;
    --ag-cell-horizontal-padding: 4px;
  }

  ${columnStyles}
  .${NameCellClass} {
    border-right: 1px solid ${GetColor.LightGrey2};
  }

  .${GroupCellClass} {
    font-weight: bold;
    color: ${GetColor.Black};
  }

  .${SecondaryCellClass} {
    font-style: italic;
    color: ${GetColor.GreyScale.Grey80};
  }

  .ag-checkbox-input-wrapper.ag-checked,
  .ag-checkbox-input-wrapper.ag-indeterminate {
    // Sensibly, the background-color is the color of the checkmark, and color is the color of the background.
    --ag-checkbox-background-color: ${GetColor.White};
    --ag-checkbox-checked-color: ${GetColor.DarkBlue};
    --ag-checkbox-indeterminate-color: ${GetColor.DarkBlue};
  }

  .ag-header {
    border-bottom: none;
    font-size: 12px;
  }

  .ag-header-container {
    border-bottom: 1px solid ${GetColor.Black};
    font-size: 12px;
    position: sticky;
    top: 188px;
    z-index: ${ZIndex.Front};
  }

  .ag-ltr .ag-row-group-leaf-indent {
    margin-left: 0;
  }

  .ag-floating-bottom,
  .ag-floating-bottom-viewport {
    // Allow overflow for the axis
    overflow: visible !important;
    border: none;
  }

  .ag-root-wrapper,
  .ag-root {
    overflow: initial; // needed for sticky header
  }
`;
