import type { Fund, ProxyCategoryEnum, ProxyTypeEnum, SimpleFund } from 'venn-api';

export interface DataPoint {
  /**
   * Timestamp, in UTC
   */
  x: number;
  /**
   * Value
   */
  y: number;
}

export interface FundProxy {
  /** The name of proxy */
  name?: string;
  /** Required when proxyType is INTERPOLATE */
  proxyCategory?: ProxyCategoryEnum;
  /** The fund id of the proxy. Required when proxyType is not INTERPOLATE. */
  proxyId?: string;
  id?: string;
  proxyType?: ProxyTypeEnum;
  numLags?: number;
  extrapolate?: boolean;
}

type RequiredFundToProxyProps =
  | 'id'
  | 'name'
  | 'startRange'
  | 'endRange'
  | 'frequency'
  | 'unproxiedEndRange'
  | 'unproxiedFrequency'
  | 'unproxiedStartRange'
  | 'updated'
  | 'userUploaded'
  | 'investmentSource'
  | 'interpolatable'
  | 'hasMinDataForInterpolation'
  | 'proxyId'
  | 'proxyNumLags'
  | 'assetType'
  | 'extrapolate'
  | 'returnFrequency'
  | 'live'
  | 'dataSource';

/** Funds in bulk proxy picker can either be a simple fund from portfolio lookup or a full Fund from benchmark lookup. */
export type FundToBulkProxy = FundToProxy | SimpleFund;
export type FundToProxy = Pick<Fund, RequiredFundToProxyProps>;

export enum DisabledProxyReason {
  INVESTMENT_RETURNS_UNAVAILABLE = '',
  // returns information can be unavailable if the item hasn't been indexed yet
  PROXY_NO_RETURNS = 'This proxy has no returns or its returns information is currently unavailable.',
  BACKFILL_INSUFFICIENT_HISTORY = 'This proxy does not have enough history to backfill the returns of the investment.',
  BACKFILL_QUARTERLY = 'Unavailable for quarterly investments',
  BACKFILL_TOO_INFREQUENT = 'The returns of this proxy are not frequent enough to backfill the investment.',
  SUBSTITUTE_SYSTEM_FUND = 'Unavailable for Venn-provided investments',
  INTERPOLATE_NONQUARTERLY = 'Unavailable for monthly/daily investments',
  DESMOOTH_DAILY = 'Unavailable for daily investments',
  DESMOOTH_NOT_ENOUGH_DATA = 'The investment does not have enough data for desmoothing.',
  INTERPOLATE_NOT_ENOUGH_DATA = 'The investment does not have enough data for interpolation.',
  EXTRAPOLATE_NOT_ENOUGH_DATA = 'The investment does not have enough data for extrapolation.',
  EXTRAPOLATE_QUARTERLY = 'Apply Interpolate to enable Extrapolate',
  EXTRAPOLATE_LOWER_FREQ = 'Investment cannot be extrapolated with a lower frequency investment',
  EXTRAPOLATE_DOES_NOT_EXTEND = 'This proxy does not extend the returns of your investment',
  DESMOOTH_LOWER_FREQ = 'Desmoothing requires the proxy to be more frequent than the investment.',
  INTERPOLATE_LOWER_FREQ = 'Interpolation requires the proxy to be more frequent than the investment.',
  COMPOSITE_BENCHMARK_NOT_PROXYABLE = 'Composite benchmarks cannot be proxied',
}

export const proxyTypeErrorMessageWithType = {
  [DisabledProxyReason.BACKFILL_QUARTERLY]: 'Backfill is unavailable for quarterly investments',
  [DisabledProxyReason.INTERPOLATE_NONQUARTERLY]: 'Interpolation is unavailable for monthly/daily investments',
  [DisabledProxyReason.DESMOOTH_DAILY]: 'Desmoothing is unavailable for daily investments',
  [DisabledProxyReason.SUBSTITUTE_SYSTEM_FUND]: 'Substitution is unavailable for Venn-provided investments',
  [DisabledProxyReason.EXTRAPOLATE_QUARTERLY]: 'Extrapolation is unavailable for quarterly investments',
} as const;
