import { FilterMenuTrigger } from '../shared';
import React, { useCallback, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FactorLensesContext, Input } from 'venn-components';
import { AccordionMenu, GetColor, Icon, SimpleMenu, SkeletalDropMenu } from 'venn-ui-kit';
import type { AdvancedQueryRow } from '../types';
import {
  generateFactorLensAccordionItems,
  METRIC_OPTIONS,
  parseNumValue,
  QUERY_OPERATOR_OPTIONS,
  TIME_PERIOD_OPTIONS,
  timePeriodToDisplayName,
  toDisplayValue,
} from './advancedQueryUtils';
import ValidationError from './ValidationError';
import type { AdvancedQuery } from 'venn-state';

interface AdvancedQueryInputProps {
  onRemoveQuery: () => void;
  onUpdateQuery: (updated: AdvancedQueryRow) => void;
  query: AdvancedQueryRow;
  showErrors: boolean;
}

const AdvancedQueryInput = ({ onRemoveQuery, onUpdateQuery, query, showErrors }: AdvancedQueryInputProps) => {
  const { Colors } = useContext(ThemeContext);
  const { factorLenses } = useContext(FactorLensesContext);
  const items = generateFactorLensAccordionItems(factorLenses);

  const { timePeriod, metric, operator, value } = query;
  const { label: metricValue, isPercentage } = METRIC_OPTIONS.find(({ value }) => value === metric) ?? {};
  const selectedOperator = QUERY_OPERATOR_OPTIONS.find(({ value }) => value === operator);
  const displayValue = toDisplayValue(value, isPercentage);
  const showValueError = showErrors && displayValue === '';

  const onChangeField = useCallback(
    (fieldName: keyof AdvancedQuery, updatedValue: string, onClose: () => void) => {
      onUpdateQuery({ ...query, [fieldName]: updatedValue });
      onClose();
    },
    [onUpdateQuery, query],
  );

  const timePeriodTriggerComponent: React.ComponentProps<typeof SkeletalDropMenu>['triggerComponent'] = useCallback(
    (expanded, toggleMenu) => (
      <StyledTrigger
        className="qa-adv-query-time-period"
        width={135}
        label="Time Period"
        isOpen={expanded}
        onClick={toggleMenu}
        aria-expanded={expanded}
        aria-haspopup
        value={timePeriod && timePeriodToDisplayName(timePeriod)}
        error={showErrors && !timePeriod}
        errorMessage={<ValidationError />}
      />
    ),
    [timePeriod, showErrors],
  );

  const timePeriodMenuComponent: React.ComponentProps<typeof SkeletalDropMenu>['menuComponent'] = useCallback(
    (_, onClose) => (
      <SimpleMenu
        className="qa-adv-query-time-period-menu"
        items={TIME_PERIOD_OPTIONS}
        onChange={({ value }) => onChangeField('timePeriod', value, onClose)}
      />
    ),
    [onChangeField],
  );

  const metricTriggerComponent: React.ComponentProps<typeof SkeletalDropMenu>['triggerComponent'] = useCallback(
    (expanded, toggleMenu) => (
      <StyledTrigger
        className="qa-adv-query-metric"
        width={240}
        label="Metric"
        isOpen={expanded}
        onClick={toggleMenu}
        aria-expanded={expanded}
        aria-haspopup
        value={metricValue}
        error={showErrors && !metricValue}
        errorMessage={<ValidationError />}
      />
    ),
    [metricValue, showErrors],
  );

  const metricMenuComponent: React.ComponentProps<typeof SkeletalDropMenu>['menuComponent'] = useCallback(
    (_, onClose) => (
      <AccordionMenu expanded onSelect={(value) => onChangeField('metric', value, onClose)} items={items} />
    ),
    [items, onChangeField],
  );

  const operatorTriggerComponent: React.ComponentProps<typeof SkeletalDropMenu>['triggerComponent'] = useCallback(
    (expanded, toggleMenu) => (
      <StyledTrigger
        className="qa-adv-query-operator"
        width={206} // ensure all options, when selected, will be displayed on a single line
        label="Operator"
        isOpen={expanded}
        onClick={toggleMenu}
        aria-expanded={expanded}
        aria-haspopup
        value={selectedOperator?.label}
        error={showErrors && !selectedOperator}
        errorMessage={<ValidationError />}
      />
    ),
    [selectedOperator, showErrors],
  );

  const operatorMenuComponent: React.ComponentProps<typeof SkeletalDropMenu>['menuComponent'] = useCallback(
    (_, onClose) => (
      <SimpleMenu
        className="qa-adv-query-operator-menu"
        selected={operator}
        items={QUERY_OPERATOR_OPTIONS}
        onChange={({ value }) => onChangeField('operator', value, onClose)}
      />
    ),
    [operator, onChangeField],
  );

  return (
    <AdvancedQueryInputRow showErrors={showErrors}>
      <SkeletalDropMenu
        menuPosition="right"
        triggerComponent={timePeriodTriggerComponent}
        menuComponent={timePeriodMenuComponent}
      />
      <SkeletalDropMenu
        menuPosition="right"
        triggerComponent={metricTriggerComponent}
        menuComponent={metricMenuComponent}
      />
      <SkeletalDropMenu
        menuPosition="right"
        triggerComponent={operatorTriggerComponent}
        menuComponent={operatorMenuComponent}
      />
      <StyledInput
        className="qa-adv-query-value"
        type="number"
        percentage={isPercentage}
        value={displayValue}
        onChange={(updated: string) => {
          onUpdateQuery({ ...query, value: parseNumValue(updated, isPercentage) });
        }}
        error={showValueError}
        errorMessage={showValueError ? <ValidationError message="Enter a valid number" /> : undefined}
        errorColor={Colors.HighlightDark}
      />
      <TrashIcon onClick={onRemoveQuery} />
    </AdvancedQueryInputRow>
  );
};

export default AdvancedQueryInput;

const TrashIcon = styled(Icon).attrs(() => ({
  type: 'trash',
}))`
  color: ${GetColor.Primary.Dark};
  margin-left: 20px;
  font-size: 11px;
`;

const AdvancedQueryInputRow = styled.div<{ showErrors: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  > :not(:last-child) {
    margin-right: 10px;
  }
  margin-bottom: ${({ showErrors }) => (showErrors ? 25 : 10)}px;
`;

const StyledInput = styled(Input).attrs(() => ({
  textAlign: 'right',
}))<{ percentage?: boolean }>`
  padding-right: 10px;
  position: relative;
  width: 70px;

  ${({ percentage }) =>
    percentage
      ? `&:before {
    content: '%';
    right: 8px;
    position: absolute;
    color: ${GetColor.MidGrey2};
    line-height: 15px;
    font-size: 13px;
  }`
      : null}
`;

const StyledTrigger = styled(FilterMenuTrigger)<{ width?: number; error?: boolean }>`
  border-color: ${(props) => (props.error ? GetColor.HighlightDark : GetColor.GreyScale.Grey30)};
  ${({ width }) => width !== undefined && `width: ${width}px;`}
`;
