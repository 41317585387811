import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { Fund, Portfolio } from 'venn-api';
import { getFund } from 'venn-api';
import { selectStrategy, showReadOnlyWarning } from 'venn-utils';
import styled from 'styled-components';
import { BaseDropMenu, GetColor, Icon, SimpleMenu, Tooltip, TooltipPosition, ZIndex } from 'venn-ui-kit';
import { DataUploaderMode } from '../modals/data-uploader/types';
import { TreeItemUpdateType } from './AllocationUtils';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import partial from 'lodash/partial';
import { compact } from 'lodash';
import { UserContext } from '../contexts';
import DataUploaderModalWrapper from '../modals/data-uploader/DataUploaderModalWrapper';
import type { DropMenuProps } from 'venn-ui-kit/src/components/drop-menu/implementations/BaseDropMenu';

interface Option {
  label: string;
  value: DataUploaderMode | 'AddInvestments';
}

interface AllocationPlusButtonProps {
  portfolio: Portfolio;
  strategyId: number;
  updatePortfolio: (portfolio: Portfolio, updateType: TreeItemUpdateType) => void;
  updateAllFunds?: (funds: Fund[], preventSubjectUpdate?: boolean) => void;
  canUploadInvestments?: boolean;
}

const AllocationPlusButton: FC<React.PropsWithChildren<AllocationPlusButtonProps>> = ({
  updatePortfolio,
  portfolio,
  strategyId,
  updateAllFunds,
  canUploadInvestments,
}) => {
  const { hasPermissionForResource } = useContext(UserContext);
  const [dataUploadMode, setDataUploadMode] = useState<DataUploaderMode | undefined>(undefined);
  const options: Option[] = useMemo(
    () =>
      compact([
        canUploadInvestments
          ? {
              label: 'Upload Investments',
              value: DataUploaderMode.Returns,
            }
          : null,
        {
          label: 'Upload NAVs',
          value: DataUploaderMode.NewNavs,
        },
      ]),
    [canUploadInvestments],
  );

  const isModelPortfolio = useMemo(
    () => !hasPermissionForResource('EDIT_PORTFOLIO', portfolio),
    [hasPermissionForResource, portfolio],
  );

  const onFinish = useCallback(
    async (fundIds: string[]) => {
      try {
        const funds = await Promise.all(fundIds.map((fundId) => getFund(fundId)));
        updateAllFunds?.(
          funds.map(({ content }) => content),
          true,
        );
      } catch (e) {
        // Fail silently (this is an improvement for tooltip ranges accuracy in case of multiple concurrent investments
        // upload - it's not the end of the world if this fails.
      }
    },
    [updateAllFunds],
  );

  const completeUploadModal = (updatedPortfolio: Portfolio, newFunds: Portfolio[] = []) => {
    updatePortfolio(
      updatedPortfolio,
      dataUploadMode === DataUploaderMode.NewNavs
        ? TreeItemUpdateType.CHANGE_ALLOCATION
        : TreeItemUpdateType.UPLOAD_FUND,
    );
    setDataUploadMode(undefined);
    if (dataUploadMode === DataUploaderMode.Returns) {
      onFinish(newFunds.map(({ fund }) => fund!.id));
    }
  };

  const strategy = selectStrategy(strategyId || portfolio.id, portfolio) || portfolio;
  const isFundSelected = !!strategy.fund;

  const triggerComponent: DropMenuProps<unknown>['triggerComponent'] = useCallback(
    (expanded, _, onToggle) => (
      <Tooltip
        isHidden={!isFundSelected}
        content="Select a strategy to upload NAVs and investments."
        position={TooltipPosition.Right}
      >
        <StyledButton onClick={isFundSelected ? noop : partial(onToggle, undefined)} disabled={isFundSelected}>
          <Icon type="plus-circle" prefix="fal" />
        </StyledButton>
      </Tooltip>
    ),
    [isFundSelected],
  );

  const menuComponent: DropMenuProps<unknown>['menuComponent'] = useCallback(
    (_, onCollapse) => (
      <SimpleMenu
        items={!isFundSelected ? options : []}
        selected={null}
        width={220}
        onChange={(item) => {
          onCollapse();
          if (!isFundSelected) {
            setDataUploadMode(item.value as DataUploaderMode);
          }
        }}
      />
    ),
    [isFundSelected, options, setDataUploadMode],
  );

  return (
    <Plus
      disabled={isFundSelected}
      isDemo={portfolio.demo}
      readOnlyWarning={showReadOnlyWarning(portfolio, isModelPortfolio)}
    >
      <BaseDropMenu
        className="drop-menu"
        filteredItems={!isFundSelected ? options : []}
        triggerComponent={triggerComponent}
        menuComponent={menuComponent}
      />
      {!isNil(dataUploadMode) && (
        <DataUploaderModalWrapper
          mode={dataUploadMode}
          onCancel={() => setDataUploadMode(undefined)}
          onComplete={completeUploadModal}
          portfolio={portfolio}
          strategyId={strategyId}
        />
      )}
    </Plus>
  );
};

export default AllocationPlusButton;

const StyledButton = styled.button<{ disabled: boolean }>`
  ${({ disabled }) => `color: ${disabled ? GetColor.Grey : GetColor.Primary.Dark};`}
  font-size: 40px;
  line-height: 40px;
  cursor: pointer;
  &:hover,
  &:focus {
    ${({ disabled }) => `color: ${disabled ? GetColor.Grey : GetColor.Primary.Main};`}
  }
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    cursor: default;
  `}
`;

const Plus = styled.div<{ disabled: boolean; isDemo: boolean; readOnlyWarning?: boolean }>`
  position: absolute;
  z-index: ${ZIndex.Front};
  bottom: ${({ isDemo, readOnlyWarning }) => (isDemo ? 30 : 0) + (readOnlyWarning ? 50 : 0) + 50}px;
  left: 20px;
  background-color: ${GetColor.White};
  border-radius: 50%;
  .drop-menu {
    bottom: 22px;
    left: 50px;
    position: absolute;
  }
  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
  `}
  && {
    height: auto;
  }
`;
