import React, { useContext, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import { ThemeContext } from 'styled-components';
import { getSelectStyles, getSelectTheme } from './getSelectStyles';

/**
 * A prestyled version of react-select https://github.com/JedWatson/react-select
 * @param props
 * @constructor
 */
// @ts-expect-error: fixme
const VennCreatableSelect = <T, IsMulti extends boolean>(props) => {
  const { Colors } = useContext(ThemeContext);
  const ref = useRef();
  return (
    <CreatableSelect<T, IsMulti>
      ref={ref}
      theme={getSelectTheme(Colors)}
      styles={getSelectStyles<T, IsMulti>(Colors)}
      {...props}
    />
  );
};

export default VennCreatableSelect;
