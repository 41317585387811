import type { ColDef } from 'ag-grid-community';
import { assertNotNil } from 'venn-utils';
import type { BulkManageRow } from '../types';
import { bulkManageSortFunction } from './bulkManageSortFunction';
import { RangeCellClass } from './column-styles';
import { isBulkManageAxisRow, isBulkManageTotalRow } from '../utils';
import { RangeHeaderComponent } from './RangeHeaderComponent';
import { RangeCellRenderer, type RangeColDef } from './RangeCellRenderer';

const analysisRangeComparator: RangeColDef['comparator'] = bulkManageSortFunction({
  valueGetter: (v) =>
    v && !isBulkManageAxisRow(v) && !isBulkManageTotalRow(v) ? (v?.endDate ?? 0) - (v?.startDate ?? 0) : 0,
});
// @ts-expect-error: fixme
const analysisRangeValueGetter = ({ data }) => data;

// @ts-expect-error: fixme
const analysisRangeAggFunc = ({ values, rowNode }) => ({
  ...assertNotNil(rowNode.data),
  // @ts-expect-error: fixme
  rangeLoading: values.some((v) => v?.rangeLoading),
});

const ANALYSIS_RANGE_COL_MIN_WIDTH = 250;
type Props = {
  subjectHasHistoricalError: boolean;
  shouldShowHypotheticalReturnExtension: (row: BulkManageRow) => boolean;
};

export const rangeColDef = ({ subjectHasHistoricalError, shouldShowHypotheticalReturnExtension }: Props) =>
  ({
    flex: 1,
    headerComponent: RangeHeaderComponent,
    headerComponentParams: {
      hasHistoricalError: subjectHasHistoricalError,
    },
    sortable: true,
    minWidth: ANALYSIS_RANGE_COL_MIN_WIDTH,
    maxWidth: 500,
    cellClass: RangeCellClass,
    cellRenderer: RangeCellRenderer,
    cellRendererParams: {
      shouldShowHypotheticalReturnExtension,
    },
    comparator: analysisRangeComparator,
    valueGetter: analysisRangeValueGetter,
    aggFunc: analysisRangeAggFunc,
  }) satisfies ColDef<BulkManageRow>;
