/*
 * Venn Platform API 1.0.0 Type Definitions
 * Automatically generated file - do not modify!
 */

export interface AUMRange {
  approximateAUM: number;
  id: string;
  name: string;
}

export interface AccessFrequencyPair {
  prevStatus: AccessFrequencyEnum;
  status: AccessFrequencyEnum;
}

export interface AccessKey {
  active: boolean;
  created: number;
  createdByUserId: number;
  description: string;
  displayName: string;
  expiration: number;
  id: string;
  organizationId: number;
  secret: string;
  updated: number;
}

export interface Account {
  accountTier: AccountTierEnum;
  accountType: AccountTypeEnum;
  aum: AUMRange;
  countryCode: string;
  created: number;
  id: number;
  isSponsor: boolean;
  issuesPublicEquity: boolean;
  legalName: string;
  locality: string;
  name: string;
  organizationType: string;
  requiresMfa: boolean;
  sponsorAccountId: number;
  status: AccountStatusEnum;
}

export interface AccountCreationRequest {
  activationCode: string;
  aumRangeId: string;
  companyType: string;
  countryCode: string;
  email: string;
  legalAgreements: string[];
  locality: string;
  password: string;
  sponsorKey?: string;
}

export interface AccountQuery {
  countries: string[];
  ids: number[];
  isSponsor: boolean;
  limit: number;
  name: string;
  page: number;
  sponsoredById: number;
  status: AccountStatusEnum[];
  types: AccountTypeEnum[];
}

export interface AccountSAMLConfig {
  accountId: number;
  certificate: string;
  configuredById: number;
  emailKey: string;
  enabled: boolean;
  enforce: boolean;
  firstNameKey: string;
  issuerUrl: string;
  lastNameKey: string;
  samlEndpoint: string;
  signature: string;
  sloEndpoint: string;
  updated: number;
}

export interface AccountsCleanupRequest {
  accountIds: number[];
}

export interface ActionableContext {
  actionEnums: ActionEnum[];
  actions: string[];
  context: Context;
}

export interface ActiveUsageDetails {
  accessFrequencies: { [key: string]: AccessFrequencyPair };
  account: DetailedAccount;
  averageMonthlyDaysAccessed: number;
  averageMonthlyMinutesAccessed: number;
  numUsers: number;
  prevStatus: AccessFrequencyEnum;
  status: AccessFrequencyEnum;
  totalMonthlyDaysAccessed: { [key: string]: number };
  totalMonthlySecondsAccessed: { [key: string]: number };
  user: AppUser;
}

export interface ActiveUsageForDate {
  date: number;
  monthlyActiveTotal: number;
  quarterlyActiveTotal: number;
  weeklyActiveTotal: number;
  yearlyActiveTotal: number;
}

export interface AddeparAttributeValue {
  entityId: number;
  key: string;
  name: string;
}

export interface AddeparDataPreview {
  portfolioNames: string[];
  portfolioPreview: AddeparPortfolioPreview;
}

export interface AddeparFilterRange {
  from: number;
  to: number;
}

export interface AddeparOption {
  category: string;
  key: string;
  label: string;
}

export interface AddeparPortfolioPreview {
  children?: AddeparPortfolioPreview[];
  name: string;
  value: number;
}

export interface AddeparViewConfigV2Request {
  configUuid: string;
  currency?: string;
  filters?: AddeparViewFilter[];
  firmId: string;
  groupingIds: string[];
  lookThroughCompositeSecurities?: boolean;
  portfolioId?: number;
  portfolioType?: PortfolioTypeEnum;
  rootEntityIds?: string[];
  viewPrefix: string;
}

export interface AddeparViewConfigurationV2 {
  created: number;
  currency?: string;
  filters?: AddeparViewFilter[];
  firmId: string;
  groupingIds: string[];
  lookThroughCompositeSecurities: boolean;
  portfolioId: number;
  portfolioType: PortfolioTypeEnum;
  rootEntityIds?: string[];
  updated: number;
  viewConfigId: string;
  viewPrefix: string;
}

export interface AddeparViewFilter {
  attribute: string;
  groupingAttribute?: string;
  operator: AddeparViewFilterOperatorEnum;
  ranges?: AddeparFilterRange[];
  rankOrder?: AddeparViewFilterRankOrderEnum;
  rankValue?: number;
  type: AddeparViewFilterTypeEnum;
  unassignedAccountFees?: boolean;
  updated?: number;
  values?: string[];
}

export interface AggregateUserAgreement {
  count: number;
  firstAgreement: number;
  lastAgreement: number;
  outOfDate: boolean;
  user: AppUser;
  version: number;
}

export interface AggregationBucket {
  bucketEnd: string;
  bucketEndNum: number;
  bucketStart: string;
  bucketStartNum: number;
  buckets: AggregationBucket[];
  documentCount: number;
  field: AggregationFieldEnum;
  metricField: MetricFilter;
}

export interface AggregationParam {
  field: AggregationFieldEnum;
  interval: number;
  metricField: MetricFilter;
  percentiles: number[];
}

export interface AggregationQuery {
  aggregations: AggregationParam[];
  search: SearchQuery;
}

export interface Analysis {
  analysisRedistributable: boolean;
  analysisType: AnalysisTypeEnum;
  clientId: string;
  correlationMatrix: CorrelationMatrix;
  cumulativeReturns: number[][][];
  detailedProxyMetadata: DetailedProxyMetadata[][];
  downsideCorrelationMatrix: CorrelationMatrix;
  exportable: boolean[];
  factorContributionToReturn: ReturnsFactorAnalysis[];
  factorContributionToReturnForecast: FactorExposure[][];
  factorContributionToReturnTrend: ReturnsFactorAnalysis[][];
  factorContributionToRisk: ReturnsFactorAnalysis[];
  factorContributionToRiskForecast: FactorExposure[][];
  factorContributionToRiskTrend: ReturnsFactorAnalysis[][];
  factorDrawdown: DrawdownPeriodAnalysis[][];
  factorEnvironment: FactorDrawdownEnvironment;
  factorExposureComponents: FactorExposureComponents[];
  factorExposures: ReturnsFactorAnalysis[];
  factorExposuresForecast: FactorExposure[][];
  factorExposuresTrend: ReturnsFactorAnalysis[][];
  factorPeriodDrawdown: FactorDrawdownPeriod[];
  forecastedPerformanceAttributions: PortfolioPerformanceAttributions[];
  forecastedPerformancePortfolioContributions: PerformancePortfolioContributionResponse[];
  forecastedPerformanceSummary: ForecastedPerformanceSummary[];
  fundCorrelations: FundCorrelation[];
  growthSimulation?: GrowthSimulationResponse[];
  growthSimulationPublicPrivateResponse?: GrowthSimulationPublicPrivateResponse[];
  historicalPerformanceAttributions: PortfolioPerformanceAttributions[];
  historicalPerformancePortfolioContributions: PerformancePortfolioContributionResponse[];
  historicalPerformanceSummary: PerformanceSummaryResponse[];
  historicalPortfolioAllocationsTimeSeries?: HistoricalPortfolioAllocationsTimeSeriesResponse[];
  message: Message;
  messagesWithContext: { [key: string]: MessageWithContext[] };
  notablePeriods: NotablePeriod[][];
  pairwiseCorrelation: number[][];
  pairwiseDownsideCorrelation: number[][];
  peerGroupAnalysis?: PeerGroupAnalysisResponse;
  percentageDrawdown: PercentageDrawdownAnalysis[];
  portfolioBreakdown: PortfolioBreakdownResponse[];
  portfolioHistoricalResidualReturnsResponses: PortfolioHistoricalResidualReturnsResponse[];
  privateAssetSummary?: PrivateAssetSummaryResponse[];
  proformaDrawdown: DrawdownPeriodAnalysis[][];
  rangeAnalysis: RangeAnalysisResponse;
  rangeDebug: RangeDebugResponse[];
  recentEnd: number;
  recentStart: number;
  redistributable?: boolean[];
  residualReturnsAnalyses: ResidualReturnsResponse[];
  returns: number[][][];
  returnsGrids: ReturnsGridMapping[];
  returnsHistogram: ReturnsHistogram[];
  rollingAutocorrelation: RollingAnalysisResponse[];
  rollingBenchmarkCorrelation: RollingAnalysisResponse[];
  rollingBeta: RollingAnalysisResponse[];
  rollingCorrelation: RollingCorrelationResponse[];
  rollingFactorReturnContribution?: { [key: string]: RollingAnalysisResponse }[];
  rollingReturn: RollingAnalysisResponse[];
  rollingSharpe: RollingAnalysisResponse[];
  rollingVolatility: RollingAnalysisResponse[];
  scenarios: ScenarioAnalysis[][];
  venncast: VenncastReturn[];
  venncastComponents: InvestmentVenncast[][];
}

export interface AnalysisBlock {
  analysisBlockType: AnalysisBlockTypeEnum;
  analysisList: AnalysisTypeEnum[];
  content: string;
  created: number;
  description: string;
  pricingFeatures: FeatureEnum[];
  relativeToPortfolio: boolean;
  title: string;
}

export interface AnalysisExportRequest {
  analysisRequest: AnalysisRequest;
  excelName: string;
  pivoted: boolean;
  sheetName: string;
}

export interface AnalysisFactorLensRequest {
  asOfDate: number;
  currency: CurrencyEnum;
  end: number;
  fundId: string;
  lensVersion: string;
  start: number;
}

export interface AnalysisParams {
  analysisDate?: number;
  analysisType: AnalysisTypeEnum;
  bucketSize?: number;
  buckets?: number;
  clientId?: string;
  contextPortfolio?: Portfolio;
  customTimeFrames?: TimeFrame[];
  drawdownThreshold?: number;
  factorLensId?: number;
  fixedPeriods?: TimePeriodEnum[];
  forecastConfidenceLevels?: number[];
  forecastWindow?: number;
  historicalPortfolioAsOfDate?: number;
  id?: string;
  includeAllPredefinedPeriods?: boolean;
  initialNavAmount?: number;
  maximumPoints?: number;
  notablePeriodsThreshold?: number;
  peerGroupIdentifier?: PeerGroupIdentifier;
  relative?: boolean;
  residual?: boolean;
  returnsBasedSubjectStartingNav?: number;
  rollingYears?: number;
  scenarios?: Scenario[];
  selectedNotablePeriods?: number[];
  subRollingYears?: number;
}

export interface AnalysisPeriod {
  endDate?: number;
  periodToDate?: string;
  startDate?: number;
}

export interface AnalysisRequest {
  allPrivateSubjectsIndexes: number[];
  analyses: AnalysisParams[];
  benchmark: AnalysisSubject;
  benchmarkIndex: number;
  category: AnalysisSubject;
  compare: AnalysisSubject;
  end: number;
  frequency: FrequencyEnum;
  groupRootLevelInvestments: boolean;
  period: TimePeriodEnum;
  primary: AnalysisSubject;
  primaryIndex: number;
  privateSubjectIndex: number;
  start: number;
  subjects: AnalysisSubject[];
  trackingId: number;
}

export interface AnalysisResponse {
  analyses: Analysis[];
  endTime: number;
  frequency: FrequencyEnum;
  maxEndTime: number;
  maxFrequency: FrequencyEnum;
  maxStartTime: number;
  startTime: number;
  subjectErrors?: (Message | undefined)[];
  subjectNames: string[];
}

export interface AnalysisSearch {
  activeScheduledExport: boolean;
  descending: boolean;
  excludeVennCreated: boolean;
  excludedAnalysisViewTypes: AnalysisViewTypeEnum[];
  includeAllInWorkspace: boolean;
  name: string;
  onlyRecentlyViewed: boolean;
  page: number;
  pageSize: number;
  requiresAttention: boolean;
  sortBy: AnalysisViewSortTypeEnum;
  vennTemplate: boolean;
}

export interface AnalysisSnapshot {
  active: boolean;
  created: number;
  fileId: string;
  metadata: SnapshotMetadata;
  organizationId: number;
  owner: AppUser;
}

export interface AnalysisSubject {
  comparisonType: AnalysisComparisonTypeEnum;
  feesMapping?: { [key: string]: number };
  fund?: Fund;
  id: string;
  isPrivate: boolean;
  portfolio?: Portfolio;
  portfolioComparisonType?: AnalysisPortfolioComparisonTypeEnum;
  primary: boolean;
  privateFund?: PrivateFund;
  privatePortfolio?: PrivatePortfolioNode;
  subjectType: AnalysisSubjectTypeEnum;
  timeSeries?: number[][];
}

export interface AnalysisTemplate {
  analysisBlocks: string[];
  appUser: AppUser;
  created?: number;
  description: string;
  id: string;
  name: string;
  updated?: number;
}

export interface AnalysisView {
  analysisPeriod?: AnalysisPeriod;
  analysisViewType?: AnalysisViewTypeEnum;
  benchmarkSettings?: AnalysisViewBenchmarkSettings[];
  column?: number;
  created: number;
  customAnalysisParams: AnalysisParams[];
  customTemplateId: string;
  customViewOptions: { [key: string]: any };
  customizedBlock?: CustomizedBlock;
  customizedViews?: AnalysisView[];
  dateRanges?: AnalysisViewDateRange[];
  id?: string;
  name?: string;
  owner?: AppUser;
  ownerContextId?: string;
  page?: number;
  printColumnType?: PrintColumnTypeEnum;
  printOrientationType?: PrintOrientationTypeEnum;
  printRowType?: PrintRowTypeEnum;
  published: boolean;
  refId?: string;
  row?: number;
  studioDocumentType?: StudioDocumentTypeEnum;
  subjectGroups?: AnalysisViewSubjectGroup[];
  subjects: AnalysisViewSubject[];
  systemTemplate: string;
  template: boolean;
  templateDescription: string;
  updated: number;
  vennTemplate: boolean;
  version: number;
  width: number;
}

export interface AnalysisViewBenchmarkSettings {
  id: string;
  label: string;
  relative: boolean;
  subject?: AnalysisViewSubject;
  type: CustomBenchmarkTypeEnum;
}

export interface AnalysisViewDateRange {
  id: string;
  label: string;
  period: AnalysisPeriod;
  resolution: DateRangeResolutionEnum;
}

export interface AnalysisViewEntity {
  viewId: string;
  viewType: AnalysisViewTypeEnum;
}

export interface AnalysisViewSearchResult {
  analysisSubjectType: AnalysisSubjectTypeEnum;
  analysisViewType: AnalysisViewTypeEnum;
  created: number;
  customTemplateId: string;
  description: string;
  exportsErrorType: ExportsErrorTypeEnum;
  id: string;
  name: string;
  numScheduledExport: number;
  numSnapshots: number;
  owner: AppUser;
  ownerContextId?: string;
  privateAssetTemplate: boolean;
  published: boolean;
  scheduledFrequency: FrequencyEnum;
  scheduledId: string;
  studioDocumentType?: StudioDocumentTypeEnum;
  subjectId: string;
  subjectName: string;
  subjects: AnalysisSubject[];
  systemTemplate: string;
  template: boolean;
  updated: number;
  vennTemplate: boolean;
}

export interface AnalysisViewSubject {
  comparisonType: AnalysisComparisonTypeEnum;
  feesMapping?: { [key: string]: number };
  id: string;
  isPrivate: boolean;
  portfolioComparisonType?: AnalysisPortfolioComparisonTypeEnum;
  subjectInaccessible?: boolean;
  subjectType: AnalysisSubjectTypeEnum;
}

export interface AnalysisViewSubjectGroup {
  id: string;
  label: string;
  subjects: AnalysisViewSubject[];
}

export interface AppUser {
  accountId: number;
  active: boolean;
  actualUser: boolean;
  avatarId?: string;
  created?: number;
  displayName: string;
  email?: string;
  id: number;
  name: string;
  pwdLastUpdated?: number;
}

export interface ApprovedOfacEntry {
  approved: number;
  approvedById: number;
  entityId: string;
  entityType: OfacEntityTypeEnum;
  sdnId: string;
}

export interface AuditLog {
  action: AuditLogActionEnum;
  actor: AuditLogActor;
  auditSourceContext: AuditSourceContext;
  context: string;
  secondaryTarget: AuditLogTarget;
  target: AuditLogTarget;
  timestamp: number;
}

export interface AuditLogActionStructure {
  action: AuditLogActionEnum;
  actionGroup: AuditLogActionGroupEnum;
  crudType: CRUDTypeEnum;
  targetType: AuditLogTargetTypeEnum;
}

export interface AuditLogActor {
  name: string;
  orgId: number;
  systemAdmin: boolean;
  userId: number;
}

export interface AuditLogOptionsResponse {
  actionGroups: AuditLogActionGroupEnum[];
  crudtypes: CRUDTypeEnum[];
  groupedActionsWithTypes: AuditLogActionStructure[];
  sourceTypes: AuditSourceTypeEnum[];
  targetTypes: AuditLogTargetTypeEnum[];
}

export interface AuditLogRequestOptions {
  actionGroups: AuditLogActionGroupEnum[];
  actionTypes: AuditLogActionEnum[];
  auditSourceTypes: AuditSourceTypeEnum[];
  crudTypes: CRUDTypeEnum[];
  end: number;
  excludedOrgIds: number[];
  orgIds: number[];
  page: number;
  pageSize: number;
  start: number;
  targetIds: string[];
  targetTypes: AuditLogTargetTypeEnum[];
  userIds: number[];
}

export interface AuditLogRequestPaginationParams {
  page: number;
  pageSize: number;
}

export interface AuditLogResults {
  request: AuditLogRequestOptions;
  results: AuditLog[];
  total: number;
}

export interface AuditLogTarget {
  targetId: string;
  targetName: string;
  targetOrgId?: number;
  targetType: AuditLogTargetTypeEnum;
}

export interface AuditSourceContext {
  auditSourceType: AuditSourceTypeEnum;
  url: string;
}

export interface AuthenticatedResponse {
  authenticated: boolean;
}

export interface AutocompleteRequest {
  count: number;
  endsAtOrAfter: number;
  excludedItems: ItemId[];
  filterId: number;
  query: string;
  returnScopes: boolean;
  scope: ScopeTypeEnum;
  startsAtOrBefore: number;
}

export interface AutocompleteResponse {
  results: AutocompleteResult[];
}

export interface AutocompleteResult {
  fund?: Fund;
  investment?: SearchFund;
  name: string;
  portfolio?: Portfolio;
  type: string;
}

export interface AutocompleteSearchRequest {
  count: number;
  excludedItems: ItemId[];
  investmentParams: Partial<SearchQueryParams>;
  query: string;
  scopes: ScopeEnum[];
}

export interface AutoproxyResponse {
  results: ProxyMetrics[];
}

export interface Benchmark {
  fundId?: string;
  name: string;
  portfolioId?: number;
  primary: boolean;
  type: BenchmarkTypeEnum;
}

export interface BlockFontOverrides {
  chartAxisFontSize: number;
  chartLegendFontSize: number;
  footerFontSize: number;
  headerFontSize: number;
  subtitleFontSize: number;
  tableFontSize: number;
  titleFontSize: number;
}

export interface BlogPost {
  html_title: string;
  meta_description: string;
  publish_date: number;
  url: string;
}

export interface BodyPart {
  contentDisposition: ContentDisposition;
  entity: any;
  headers: { [key: string]: string[] };
  mediaType: MediaType;
  messageBodyWorkers: MessageBodyWorkers;
  parameterizedHeaders: { [key: string]: ParameterizedHeader[] };
  parent: MultiPart;
  providers: Providers;
}

export interface BucketEntry {
  count: number;
  name: string;
}

export interface CMAGrouping {
  cashFactor: CashFactorEnum;
  factor: FactorWithNonSerializedReturns;
  options: CMAOption[];
}

export interface CMAOption {
  accountNumber: string;
  active: boolean;
  apiId: string;
  assetType: AssetTypeEnum;
  benchmarkID: string;
  benchmarkName: string;
  category: CategoryEnum;
  categoryAssetType: CategoryAssetTypeOption;
  categoryGroup: CategoryGroupOption;
  created: number;
  createdById: number;
  currency: CurrencyEnum;
  dataSource: string;
  domicile: string;
  endRange: number;
  exchange: string;
  exchangeCountry: string;
  extrapolate: boolean;
  favorite: boolean;
  frequency: number;
  fundMaster: NamedEntityUUID;
  fundSourceType: FundSourceTypeEnum;
  hasMinDataForInterpolation: boolean;
  id: string;
  inApi: boolean;
  inApp: boolean;
  interpolatable: boolean;
  interpolationCategory: ProxyCategoryEnum;
  interpolationCategoryDisplayName: string;
  investmentBenchmarks: Benchmark[];
  investmentObjective: string;
  investmentRegion: InvestmentRegionEnum;
  investmentSource: InvestmentSourceTypeEnum;
  isin: string;
  live: boolean;
  morningstarCategory: string;
  morningstarCategoryCode: string;
  morningstarFundId: string;
  name: string;
  needsInterpolation: boolean;
  needsProxy: boolean;
  netExpenseRatio: number;
  outOfDate: boolean;
  owner: AppUser;
  preferredFrequency: number;
  primaryBenchmark: string;
  primaryShareClass: boolean;
  proxyCategory: ProxyCategoryEnum;
  proxyCategoryDisplayName: string;
  proxyId: string;
  proxyName: string;
  proxyNumLags: number;
  proxyType: ProxyTypeEnum;
  proxyable: boolean;
  remoteType: RemoteTypeEnum;
  returnFrequency: FrequencyEnum;
  shareClass: ShareClassEnum;
  startRange: number;
  symbol: string;
  unproxiedEndRange: number;
  unproxiedFrequency: FrequencyEnum;
  unproxiedNumReturns: number;
  unproxiedStartRange: number;
  updated: number;
  updatedBy: AppUser;
  updatedByDataSource: string;
  updatedById: number;
  uploadName: string;
  userEditable: boolean;
  userUploaded: boolean;
  viewed: boolean;
}

export interface Campaign {
  categories: string[];
  custom_unsubscribe_url: string;
  editor: string;
  html_content: string;
  id: number;
  ip_pool: string;
  list_ids: number[];
  plain_content: string;
  status: string;
  subject: string;
  suppression_group_id: number;
  title: string;
}

export interface CashFlowResponse {
  actual?: CashFlowSeries;
  actualCumulative?: CashFlowSeries;
  error: string;
  errorMessages: FundErrorMessages[];
  errors: { [key: string]: PrivateAnalysisError[] };
  initial?: CashFlowSeries;
  initialCumulative?: CashFlowSeries;
  projected?: CashFlowSeries;
  projectedCumulative?: CashFlowSeries;
  projectionAsOfDate: number;
}

export interface CashFlowSeries {
  contributions: number[][];
  distributions: number[][];
  nav: number[][];
  net: number[][];
}

export interface CashFlowSetting {
  assetClass?: PrivateFundAssetClassEnum;
  assetClassName?: string;
  bow: number;
  created: number;
  growth: number;
  lifeExpectancy: number;
  name: string;
  overrideType: CashFlowOverrideTypeEnum;
  owner?: AppUser;
  rateOfContribution0: number;
  rateOfContribution4: number;
  rateOfContribution8: number;
  settingId?: string;
  strategyName?: string;
  updated: number;
  userCreated: boolean;
  vintage?: number;
  yield: number;
}

export interface CashflowClassificationParameters {
  assetClass: string;
  strategy: string;
  vintage: number;
}

export interface CategoryAssetTypeOption {
  key: CategoryAssetTypeEnum;
  name: string;
}

export interface CategoryGroupOption {
  assetType: CategoryAssetTypeEnum;
  categoryId: string;
  key: CategoryGroupEnum;
  name: string;
}

export interface CellIndex {
  colIndex: number;
  rowIndex: number;
}

export interface ChangeUserTeamRequest {
  newTeamId: string;
  userId: number;
}

export interface CheckPermissionRequest {
  actionId: string;
  resourceEntityId: string;
  userEntityId: string;
}

export interface CheckPermissionResponse {
  actionsFound: string[];
  allowed: boolean;
}

export interface CheckRolesRequest {
  resourceEntityId: string;
  userEntityId: string;
}

export interface Claims {
  audience: string;
  empty: boolean;
  expiration: number;
  id: string;
  issuedAt: number;
  issuer: string;
  notBefore: number;
  subject: string;
}

export interface CleanupRequest {
  fundNames: string[];
  portfolioNames: string[];
  savedViewsNames: string[];
}

export interface ClientIntegration {
  active: boolean;
  addedByUserId: number;
  clientId: string;
  clientName: string;
  configVersion: ConfigVersionEnum;
  created: number;
  integrationType: IntegrationTypeEnum;
  integrationUuid: string;
  lastPortfolioReturnSyncError?: AddeparSyncErrorTypeEnum;
  lastPortfolioStructureSyncError?: AddeparSyncErrorTypeEnum;
  lastSync?: number;
  updated: number;
  updatedByUserId: number;
  workspaceId: number;
}

export interface ColumnErrors {
  columnSeriesErrors: ColumnSeriesErrorEnum[];
  errorCount: number;
  errors: SeriesErrorRange[];
  name: string;
}

export interface ColumnMapping {
  annualizedReturn: number;
  annualizedVolatility: number;
  appendId: number;
  cumulativeReturn: number[][];
  currency: CurrencyEnum;
  dateFormatId: number;
  frequencyId: number;
  fundId: string;
  fundName: string;
  isPercent: boolean;
  isUploadPercent: boolean;
  metricId: number;
  name: string;
  needsBackfill: boolean;
  newDataCount: number;
  newNav: number;
  oldNav: number;
  origin: CellIndex;
  periodAnnualized: boolean;
  periodReturn: number;
  portfolioNodeId: number;
  recentMonthlyReturns: number[][];
  seriesId: string;
  startOfNeededForFactorAnalysis: number;
  startOfNewData: number;
  strategyName: string;
  typeId: number;
}

export interface CompanyTypeOption {
  key: string;
  name: string;
  notes: string[];
}

export interface CompositeBenchmark {
  children: CompositeBenchmarkChild[];
  fundId: string;
  id: number;
  name: string;
}

export interface CompositeBenchmarkChild {
  annualizedOffset: number;
  fundId: string;
  weight: number;
}

export interface ComputedInvestmentResidual {
  computedResidual: number;
  computedReturn: number;
  created: number;
  defaultResidual: number;
  defaultReturn: number;
  forecastResidual: number;
  fundId: string;
  fundName: string;
  isManualOverride: boolean;
  overriddenResidual?: number;
  overriddenReturn?: number;
  permissionGroupId: number;
  userId: number;
  userName: string;
  workspaceId: number;
  workspaceName: string;
}

export interface ConstraintTarget {
  factorId?: number;
  factorName?: string;
  fundId?: string;
  fundName?: string;
  remoteId?: string;
  strategyId?: number;
  strategyName?: string;
}

export interface ContactsList {
  id: number;
  name: string;
  recipient_count: number;
}

export interface ContentDisposition {
  creationDate: number;
  fileName: string;
  modificationDate: number;
  parameters: { [key: string]: string };
  readDate: number;
  size: number;
  type: string;
}

export interface Context {
  global: boolean;
  id: string;
  name: string;
  workspaceId: number;
}

export interface Contribution {
  beta: ContributionExposure;
  returnContribution: ContributionExposure;
  riskContribution: ContributionExposure;
  sharpeContribution: ContributionExposure;
  stdError: ContributionExposure;
}

export interface ContributionExposure {
  exposure: number;
  exposurePct: number;
  unweightedExposure: number;
}

export interface CorrelationMatrix {
  correlations: FundCorrelationRowObject[];
  end: number;
  start: number;
}

export interface Country {
  code: string;
  defaultCurrency: CurrencyEnum;
  defaultLanguage: UserLanguageEnum;
  displayName: string;
  gdprApplied: boolean;
  iso2Code: string;
  iso3Code: string;
  restrictedAccess: boolean;
  supportedJurisdiction: boolean;
}

export interface CreateOrUpdateClientIntegrationRequest {
  active: boolean;
  clientId: string;
  clientName: string;
  configVersion: ConfigVersionEnum;
  integrationType: IntegrationTypeEnum;
  integrationUuid: string;
  workspaceId: number;
}

export interface CreateOrUpdateContextRequest {
  contextId: string;
  global: boolean;
  name: string;
  parentContextId: string;
  workspaceId: number;
}

export interface CreatePermissionTeamRequest {
  accountId: number;
  adminTeam: boolean;
  contextIdRoleIdMap: { [key: string]: string };
  name: string;
}

export interface CreateTeamRequest {
  accountId: number;
  adminTeam: boolean;
  teamName: string;
}

export interface Criterion {
  featureId: string;
  id: string;
  operator: CriterionOperatorEnum;
  propertyName: CriterionPropertyNameEnum;
  statusIfTrue: AvailabilityStatusEnum;
  usageThreshold: UsageThreshold;
  values: string[];
}

export interface CustodianClient {
  clientId: string;
  clientName: string;
  integrationType: IntegrationTypeEnum;
}

export interface CustomFactorForecast {
  cashComputedByCustomForecast: boolean;
  cashForecast: number;
  cashForecastContext: ForecastContextEnum;
  cashForecastOverride?: number;
  cashOverrideRange: FactorOverrideRange;
  created: number;
  default: boolean;
  endDate: number;
  factorForecasts: FactorForecast[];
  forecastContext: ForecastContextEnum;
  forecastId: string;
  investmentForecasts: InvestmentForecast[];
  name: string;
  regressionEnd: number;
  regressionPeriod: RegressionPeriodEnum;
  regressionStart: number;
  startDate: number;
  systemContext?: ForecastContextEnum;
  updatedBy: AppUser;
}

export interface CustomMetadata {
  yield: YieldMetric;
}

export interface CustomSetting {
  key: string;
  permissionGroupId: number;
  value: any;
}

export interface CustomTheme {
  id: string;
  theme: { [key: string]: any };
  updated: number;
  workspaceId: number;
}

export interface CustomizedBlock {
  confidenceLevels?: number[];
  confidenceLevelsVisibility?: boolean[];
  contributionToPercentage: boolean;
  forecastWindow?: number;
  header?: string;
  infoGraphicType?: InfoGraphicTypeEnum;
  linkedBenchmarkSettings?: string;
  linkedDateRange?: string;
  linkedSubjectGroups?: string[];
  logarithmicScale?: boolean;
  rollingYears?: number;
  selectedFactors: string[];
  selectedMetrics: string[];
  settingId?: string;
  subHeader?: string;
  tableLayoutType?: TableLayoutTypeEnum;
}

export interface DataSet {
  accessType: DataSetAccessTypeEnum;
  active: boolean;
  created: number;
  endDate: number;
  global: boolean;
  hasViewRawLicense: boolean;
  id: string;
  name: string;
  permissionGroupId: number;
  requiresExportLicense: boolean;
  requiresRedistributionLicense: boolean;
  startDate: number;
  validEnd: number;
  validStart: number;
}

export interface DataSetItem {
  dataSetId: string;
  endDate: number;
  id: string;
  startDate: number;
  type: DataSetItemTypeEnum;
  validEnd: number;
  validStart: number;
}

export interface DataSetQuery {
  end: number;
  global: boolean;
  ids: string[];
  itemIds: string[];
  page: number;
  pageSize: number;
  requiresExportLicense: boolean;
  requiresRedistributionLicense: boolean;
  start: number;
  subscriptionIds: string[];
}

export interface DataSetSubscriber {
  clientProvidedLicense: boolean;
  dataSetId: string;
  endDate: number;
  hasExportLicense: boolean;
  hasRedistributionLicense: boolean;
  startDate: number;
  subscriptionId: string;
  validEnd: number;
  validStart: number;
}

export interface DataSetWithMembers {
  accessType: DataSetAccessTypeEnum;
  active: boolean;
  created: number;
  dataSetItems: DataSetItem[];
  endDate: number;
  global: boolean;
  hasViewRawLicense: boolean;
  id: string;
  name: string;
  permissionGroupId: number;
  requiresExportLicense: boolean;
  requiresRedistributionLicense: boolean;
  startDate: number;
  subscribers: DataSetSubscriber[];
  validEnd: number;
  validStart: number;
}

export interface DbFeature {
  active: boolean;
  admin: boolean;
  created: number;
  criteria: Criterion[];
  defaultStatus: AvailabilityStatusEnum;
  defaultThreshold: UsageThreshold;
  description: string;
  id: string;
  name: string;
  updated: number;
}

export interface DescribeAccountRequest {
  accountId: number;
}

export interface DescribeAccountResponse {
  permissionTeams: PermissionTeam[];
}

export interface DetailedAccount {
  accountTier: AccountTierEnum;
  accountType: AccountTypeEnum;
  aum: AUMRange;
  countryCode: string;
  created: number;
  id: number;
  isSponsor: boolean;
  issuesPublicEquity: boolean;
  legalName: string;
  locality: string;
  name: string;
  organizationType: string;
  requiresMfa: boolean;
  sponsorAccountId: number;
  status: AccountStatusEnum;
}

export interface DetailedProxyMetadata {
  extrapolate: boolean;
  fundId: string;
  fundName: string;
  numLags: number;
  parentId?: string;
  parentName?: string;
  proxiedEndDate: number;
  proxiedFrequency: FrequencyEnum;
  proxiedStartDate: number;
  proxyId: string;
  proxyName: string;
  proxyType: ProxyTypeEnum;
  unproxiedEndDate: number;
  unproxiedFrequency: FrequencyEnum;
  unproxiedStartDate: number;
}

export interface Document {
  active: boolean;
  bytes: number;
  createdByUser: AppUser;
  createdTime: number;
  customizedMetaData: string;
  dataSource: string;
  draft: boolean;
  fileType: FileTypeEnum;
  name: string;
  ownerContextId: string;
  permissionGroup: number;
  updatedByUser: AppUser;
  updatedTime: number;
  uploadType: UploadTypeEnum;
  uuid: string;
  version: number;
  workspaceId: number;
}

export interface DocumentIdentifier {
  uuid: string;
  version?: number;
}

export interface DocumentSearch {
  documentIds?: string[];
  endTime?: number;
  fileTypes: FileTypeEnum[];
  includeDrafts?: boolean;
  includePreviousVersions?: boolean;
  name?: string;
  order: OrderEnum;
  ownerContextId?: string;
  sortBy: DocumentSortTypeEnum;
  startTime?: number;
  workspaceId?: number;
}

export interface DocumentSummary {
  identifier: string;
  languageCodeToFriendlyName: { [key: string]: string };
  rebrandEnv: boolean;
}

export interface DocumentVersion {
  key: string;
  version: string;
}

export interface DomainListing {
  active: boolean;
  created: number;
  exactMatch: boolean;
  id: string;
  matchType: MatchTypeEnum;
  name: string;
  status: DomainStatusEnum;
  updated: number;
}

export interface DomainQuery {
  active: boolean;
  end: number;
  exactMatch: boolean;
  limit: number;
  name: string[];
  page: number;
  start: number;
  status: DomainStatusEnum[];
}

export interface DrawdownContribution {
  allocation: number;
  cashContribution: number;
  contribution: number;
  factorContribution: FactorExposureContribution[];
  name: string;
  residualContribution: number;
  returnValue: number;
}

export interface DrawdownFactorInfo {
  annualizedReturn: number;
  factorDeltaCorrelations: FactorDeltaCorrelation[];
  factorId: number;
  factorName: string;
  historicalAnnualizedReturn: number;
}

export interface DrawdownPeriodAnalysis {
  benchmarkMaxDrawdown: number;
  benchmarkMaxDrawdownError: number;
  benchmarkResidualRisk: number;
  completed: boolean;
  end: number;
  masterPortfolioMaxDrawdown: number;
  masterPortfolioMaxDrawdownError: number;
  masterPortfolioResidualRisk: number;
  maxDrawdown: number;
  maxDrawdownError: number;
  residualRisk: number;
  start: number;
  trough: number;
  type: TypeEnum;
}

export interface DrawdownPeriodFactorExposure {
  contribution: number;
  factorId: number;
  factorName: string;
  portfolioBeta: number;
}

export interface Duration {
  nano: number;
  negative: boolean;
  positive: boolean;
  seconds: number;
  units: TemporalUnit[];
  zero: boolean;
}

export interface E2EUser {
  email: string;
  password: string;
  userId: number;
}

export interface E2EUserSignupRequest {
  activeFeatures: string[];
  inactiveFeatures: string[];
  organizationId: number;
}

export interface EditPermissionTeamRequest {
  adminTeam: boolean;
  newName: string;
  teamId: string;
}

export interface EfficientFrontierPortfolio {
  annualizedReturn: number;
  annualizedVolatility: number;
  portfolioData: PortfolioData;
  portfolioId: number;
  sharpeRatio: number;
  topTrades: TradeSummary[];
}

export interface EmailSubscriptionSetting {
  description: string;
  enabled: boolean;
  key: string;
  name: string;
  provider: EmailSubscriptionProviderEnum;
}

export interface EnableMFARequest {
  totpVerificationCode: number;
}

export interface EntityPerformance {
  annualizedReturn: number;
  annualizedSharpe: number;
  annualizedVolatility: number;
  category: string;
  correlation: { [key: string]: number };
  cumulativeReturns: number[][];
  id: string;
  name: string;
  periodAnnualized: boolean;
  periodReturn: number;
  type: FactorPerformanceTypeEnum;
}

export interface ErrorResponse {
  body: any;
  code: number;
  message: string;
}

export interface ExportMetadata {
  exportable: boolean;
  redistributable: boolean;
  subjectId: SubjectId;
}

export interface ExportMetadataRequest {
  subjects: ExportMetadataSubject[];
}

export interface ExportMetadataResponse {
  forecastExportMetadata: ExportMetadata[];
  subjectExportMetadata: ExportMetadata[];
}

export interface ExportMetadataSubject {
  type: string;
}

export interface FactorAlert {
  date: number;
  factorAlertLevel: FactorAlertLevelEnum;
  factorId: number;
  factorLensKey: string;
  factorMessage: string;
  factorName: string;
  lensId: number;
  mean: number;
  percentValue: string;
  positive: boolean;
  stdDev: number;
  value: number;
  windowSize: number;
}

export interface FactorAnalysis {
  annualizedAlpha: number;
  annualizedTotalRisk: number;
  factors: FactorAnalysisBreakdown[];
  periodAlpha: number;
  periodAnnualized: boolean;
  periodCashReturn: number;
  periodResidualReturn: number;
  regression: RegressionAnalysis;
  regressionEnd: number;
  regressionPeriods: number;
  regressionStart: number;
  residual: number[][];
  residualAnnualizedReturn: number;
  residualMarginalRisk: number;
  residualRisk: number;
  residualSharpe: number;
  residualStdError: number;
  residualTStat: number;
  residualVariance: number;
  residualVolatility: number;
  rsquared: number;
}

export interface FactorAnalysisBreakdown {
  annualizedReturnContribution: number;
  exposure: number;
  id: number;
  marginalRiskContribution: number;
  name: string;
  periodAnnualized: boolean;
  periodReturnContribution: number;
  riskContribution: number;
  significant: boolean;
  stdError: number;
  tstat: number;
}

export interface FactorContribution {
  beta: ContributionExposure;
  returnContribution: ContributionExposure;
  riskContribution: ContributionExposure;
  sharpeContribution: ContributionExposure;
  stdError: ContributionExposure;
  volatilityContribution: ContributionExposure;
}

export interface FactorDeltaCorrelation {
  delta: number;
  histCorr: number;
  id: number;
  name: string;
  periodCorr: number;
}

export interface FactorDrawdownEnvironment {
  factors: DrawdownFactorInfo[];
  start: number;
  trough: number;
}

export interface FactorDrawdownPeriod {
  cashContribution: number;
  errorDrawdown: number[][];
  factorExposureList: DrawdownPeriodFactorExposure[];
  fundDrawdownContribution: DrawdownContribution[];
  lowerErrorDrawdown: number[][];
  maxDrawdown: number;
  maxDrawdownDate: number;
  portfolioAnalysis: FactorAnalysis;
  portfolioDrawdown: number[][];
  residualContribution: number;
  strategyDrawdownContribution: DrawdownContribution[];
  upperErrorDrawdown: number[][];
}

export interface FactorExposure {
  exposure: number;
  id: number;
  name: string;
  returnContribution: number;
  riskContribution: number;
  significant: boolean;
  tstat: number;
}

export interface FactorExposureComponents {
  factors: NamedEntityLong[];
  funds: FactorExposureFund[];
  portfolio: PortfolioContributionFactorExposureNode;
  riskFreeCashReturnContribution?: number;
  strategies: FactorExposureStrategy[];
}

export interface FactorExposureContribution {
  contribution: number;
  factorId: number;
  factorName: string;
}

export interface FactorExposureFund {
  cashContribution: Contribution;
  factorContribution: { [key: string]: FactorContribution };
  fundId: string;
  fundName: string;
  totalReturnContribution: ContributionExposure;
  weight: number;
}

export interface FactorExposureNode {
  cashContribution: Contribution;
  factorContribution: { [key: string]: FactorContribution };
  totalReturnContribution: ContributionExposure;
}

export interface FactorExposureStrategy {
  cashContribution: Contribution;
  factorContribution: { [key: string]: FactorContribution };
  strategyName: string;
  strategyNodeId: number;
  totalReturnContribution: ContributionExposure;
  weight: number;
}

export interface FactorForecast {
  annualizedReturn: number;
  annualizedReturnOverride?: number;
  annualizedSharpe: number;
  annualizedVolatility: number;
  computedByCustomForecast: boolean;
  factorOverrideRange: FactorOverrideRange;
  forecastContext: ForecastContextEnum;
  id: number;
  name: string;
  returnEnd: number;
  returnStart: number;
}

export interface FactorGroupForecast {
  annualizedReturnContribution: number;
  name: string;
}

export interface FactorHistoryPerformance {
  end: number;
  entities: EntityPerformance[];
  frequency: FrequencyEnum;
  inputMappings: FactorInputMapping[];
  maxEnd: number;
  maxStart: number;
  start: number;
}

export interface FactorInputMapping {
  id: string;
  inputs: NestedFactorInputCollection[];
}

export interface FactorLensWithReturns {
  currency: CurrencyEnum;
  earliestEndDate: number;
  earliestStartDate: number;
  factorSeriesIds: number[];
  factors: FactorWithNonSerializedReturns[];
  id: number;
  latestStartDate: number;
  maximumFrequency: FrequencyEnum;
  name: string;
  primary: boolean;
  remoteFactorLensId: number;
  serialisedFactors: FactorWithSerializedReturns[];
  version: string;
}

export interface FactorMetric {
  id: number;
  name: string;
  value: number;
}

export interface FactorOverrideRange {
  maxOverride: number;
  maxOverrideZScore: number;
  mean: number;
  minOverride: number;
  minOverrideZScore: number;
  std: number;
}

export interface FactorWithNonSerializedReturns {
  category: string;
  description: string;
  excessCashAdjusted: boolean;
  factorPeriods: NotableFactorPeriod[];
  id: number;
  inputDescription: string;
  inputsMarketNeutral: boolean;
  name: string;
}

export interface FactorWithSerializedReturns {
  returns: number[][];
}

export interface FailedSyncResult {}

export interface FeatureInformationRequest {
  featureName: string;
  message: string;
}

export interface FeatureToggleRequest {
  activeFeatures: string[];
  caller: PermissionUser;
  inactiveFeatures: string[];
  userId: number;
}

export interface FileContents {
  data: string[];
  name: string;
}

export interface FileGridPreview {
  fileId: number;
  grids: TextGrid[];
}

export interface FileMapping {
  columns: ColumnMapping[];
  fileId: number;
  name: string;
}

export interface FileMetadata {
  bytes: number;
  created: number;
  documentUuid: string;
  documentVersion: number;
  fileId: number;
  name: string;
  ownerId: number;
}

export interface FileOptions {
  appendType: NamedEntityInteger[];
  currency: NamedEntityString[];
  dataType: NamedEntityInteger[];
  dateFormat: NamedEntityInteger[];
  errorTypes: NamedEntityString[];
  frequency: NamedEntityInteger[];
  metricType: NamedEntityInteger[];
}

export interface FilePreview {
  fileId: number;
  rawFile: TextGridFile;
  structure: UserGridSelection;
}

export interface FilterTypes {
  assetTypes: AssetTypeEnum[];
  equityRegions: NamedEntityInteger[];
  factorGroups: NamedEntityLong[];
  factors: NamedEntityLong[];
  fields: string[];
  investmentRegions: NamedEntityString[];
  investmentSources: NamedEntityString[];
  investmentTypes: NamedEntityString[];
  numUploadedInvestments: number;
  performanceFilters: SearchFilterField[];
}

export interface ForecastedPerformanceSummary {
  annualizedExcessReturn: number;
  annualizedReturn: number;
  informationRatio: number;
  sharpe: number;
  trackingError: number;
  volatility: number;
}

export interface FormDataBodyPart {
  contentDisposition: ContentDisposition;
  entity: any;
  formDataContentDisposition: FormDataContentDisposition;
  headers: { [key: string]: string[] };
  mediaType: MediaType;
  messageBodyWorkers: MessageBodyWorkers;
  name: string;
  parameterizedHeaders: { [key: string]: ParameterizedHeader[] };
  parent: MultiPart;
  providers: Providers;
  simple: boolean;
  value: string;
}

export interface FormDataContentDisposition {
  creationDate: number;
  fileName: string;
  modificationDate: number;
  name: string;
  parameters: { [key: string]: string };
  readDate: number;
  size: number;
  type: string;
}

export interface FormDataMultiPart {
  bodyParts: BodyPart[];
  contentDisposition: ContentDisposition;
  entity: any;
  fields: { [key: string]: FormDataBodyPart[] };
  headers: { [key: string]: string[] };
  mediaType: MediaType;
  messageBodyWorkers: MessageBodyWorkers;
  parameterizedHeaders: { [key: string]: ParameterizedHeader[] };
  parent: MultiPart;
  providers: Providers;
}

export interface Fund {
  accountNumber: string;
  active: boolean;
  assetType: AssetTypeEnum;
  benchmarkID: string;
  benchmarkName: string;
  category: CategoryEnum;
  categoryAssetType?: CategoryAssetTypeOption;
  categoryGroup?: CategoryGroupOption;
  created: number;
  createdById: number;
  currency: CurrencyEnum;
  dataSource: string;
  domicile: string;
  endRange: number;
  exchange: string;
  exchangeCountry: string;
  extrapolate?: boolean;
  favorite: boolean;
  frequency: number;
  fundMaster: NamedEntityUUID;
  fundSourceType: FundSourceTypeEnum;
  hasMinDataForInterpolation: boolean;
  id: string;
  interpolatable: boolean;
  interpolationCategory: ProxyCategoryEnum;
  interpolationCategoryDisplayName: string;
  investmentBenchmarks: Benchmark[];
  investmentObjective: string;
  investmentRegion: InvestmentRegionEnum;
  investmentSource: InvestmentSourceTypeEnum;
  isin: string;
  live: boolean;
  morningstarCategory: string;
  morningstarCategoryCode: string;
  morningstarFundId: string;
  name: string;
  needsInterpolation: boolean;
  needsProxy: boolean;
  netExpenseRatio: number;
  outOfDate: boolean;
  owner: AppUser;
  preferredFrequency: number;
  primaryBenchmark: string;
  primaryShareClass: boolean;
  proxyCategory?: ProxyCategoryEnum;
  proxyCategoryDisplayName?: string;
  proxyId?: string;
  proxyName?: string;
  proxyNumLags?: number;
  proxyType?: ProxyTypeEnum;
  proxyable: boolean;
  remoteType: RemoteTypeEnum;
  returnFrequency: FrequencyEnum;
  shareClass: ShareClassEnum;
  startRange: number;
  symbol: string;
  unproxiedEndRange: number;
  unproxiedFrequency: FrequencyEnum;
  unproxiedNumReturns: number;
  unproxiedStartRange: number;
  updated: number;
  updatedBy: AppUser;
  updatedByDataSource: string;
  updatedById: number;
  uploadName: string;
  userEditable: boolean;
  userUploaded: boolean;
  viewed: boolean;
}

export interface FundCorrelation {
  benchmarkId: string;
  benchmarkName: string;
  correlationEnd: number;
  correlationStart: number;
  fundCorrelationData: FundCorrelationData;
  fundId: string;
  name: string;
}

export interface FundCorrelationData {
  allocation: number;
  children: FundCorrelationData[];
  correlation: number;
  fundId: string;
  name: string;
  portfolioId: number;
  type: NodeTypeEnum;
}

export interface FundCorrelationRowObject {
  correlations: FundCorrelationValue[];
  entity: NamedEntityObject;
}

export interface FundCorrelationValue {
  correlation: number;
  entity: NamedEntityObject;
}

export interface FundEntities {
  benchmarks: Benchmark[];
  compositeBenchmarks: CompositeBenchmark[];
  fundId: string;
  portfolios: Portfolio[];
  proxyFunds: ProxyMeta[];
  tagged: boolean;
}

export interface FundErrorMessages {
  errorMessages: string[];
  fundId?: string;
  fundName?: string;
}

export interface FundId {
  type: 'fund';
  id: string;
}

export interface FundInfo {
  fundId: string;
  fundName: string;
}

export interface FundMetadata {
  accountNumber: string;
  assetType: AssetTypeEnum;
  category: CategoryEnum;
  categoryAssetType: CategoryAssetTypeOption;
  categoryGroup: CategoryGroupOption;
  currency: CurrencyEnum;
  dataSource: string;
  fundMaster: NamedEntityUUID;
  id: string;
  investmentObjective: string;
  live: boolean;
  morningstarCategory: string;
  name: string;
  netExpenseRatio: number;
  primaryBenchmark: string;
  symbol: string;
}

export interface FundMetadataOptions {
  categoryAssetTypes: CategoryAssetTypeOption[];
  categoryGroups: CategoryGroupOption[];
  categoryOptions: { [key: string]: string };
  currencyOptions: { [key: string]: string };
}

export interface FundMigrationStatus {
  fundId: string;
  metadataMigrated: boolean;
  metadataValidation: boolean;
  newDataSource: string;
  timeseriesMigrated: boolean;
  timeseriesValidation: boolean;
}

export interface FundMigrationStatusSearch {
  fundIds: string[];
  metadataMigrated: boolean;
  metadataValidation: boolean;
  newDataSource: string;
  timeseriesMigrated: boolean;
  timeseriesValidation: boolean;
}

export interface FundQuery {
  active: boolean;
  assetTypes: AssetTypeEnum[];
  live: boolean;
  maxUpdated: number;
  minUpdated: number;
  morningstarCategories: string[];
  permissionGroupId: number;
  remoteType: RemoteTypeEnum;
}

export interface FundRestrictionEntry {
  end: number;
  endedBy: AppUser;
  fundId: string;
  name: string;
  start: number;
  startedBy: AppUser;
}

export interface FundReturnsRange {
  end: number;
  frequency: FrequencyEnum;
  start: number;
}

export interface FundSeriesData {
  frequency: FrequencyEnum;
  id: string;
  name: string;
  series: number[][];
  type: number;
}

export interface FundingFailure {
  date: number;
  fundCapitalCalls: PrivateFundCapitalCall[];
  projectedTotalCapitalCall: number;
  projectedTotalDistributions: number;
  projectedTotalNav: number;
}

export interface GdprStatus {
  acceptedEula: number;
  acceptedLula: number;
  acceptedPrivacy: number;
  completed: boolean;
  preGdprUser: boolean;
  region: GdprRegionEnum;
}

export interface GeneralAnalysisTemplate {
  analysisBlocks: AnalysisBlock[];
  analysisTemplateType: AnalysisTemplateTypeEnum;
  appUser: AppUser;
  created?: number;
  description: string;
  id: string;
  name: string;
  pricingFeatures?: FeatureEnum[];
  updated?: number;
}

export interface GetAccountsRequest {
  accountIdMax: number;
  accountIdMin: number;
  cleanupFrequency: string;
  limit: number;
  status: AccountStatusEnum;
  type: AccountTypeEnum;
}

export interface GetPermissionedResourcesRequest {
  actionId: string;
  userId: string;
}

export interface GetPermissionedResourcesResponse {
  resources: TreeString[];
}

export interface GrowthSimulation {
  confidenceLevel: number;
  forecast: number[][];
}

export interface GrowthSimulationPublicPrivateResponse {
  forecastReturn: number;
  forecastVolatility: number;
  fundingFailures: { [key: string]: FundingFailure };
  privateNav: { [key: string]: number[][] };
  privateNavPercentage: { [key: string]: number[][] };
  probabilityOfSuccess: number;
  publicAndPrivateNav: { [key: string]: number[][] };
  publicNav: { [key: string]: number[][] };
  publicNavPercentage: { [key: string]: number[][] };
  simulationRange: TimeFrame;
}

export interface GrowthSimulationResponse {
  forecastReturn: number;
  forecastVolatility: number;
  growthSimulations: GrowthSimulation[];
}

export interface HelpArticle {
  body: string;
  created_at: number;
  description: string;
  state: string;
  title: string;
  updated_at: number;
  url: string;
}

export interface HelpArticleDoc {
  body: string;
  createdAt: number;
  description: string;
  state: string;
  title: string;
  updatedAt: number;
  url: string;
}

export interface HelpArticleQuery {
  from: number;
  name: string;
  size: number;
}

export interface HelpArticleSearchResult {
  results: HelpArticleDoc[];
  total: number;
}

export interface HistoricalPortfolioAllocationItem {
  allocations: number[][];
  label: string;
}

export interface HistoricalPortfolioAllocationsTimeSeriesResponse {
  fundAllocations: HistoricalPortfolioAllocationItem[];
  topLevelStrategyAllocations: HistoricalPortfolioAllocationItem[];
}

export interface HistoricalPortfolioMetadata {
  allSortedAllocationDates: number[];
  isAllocationsForwardFilled: boolean;
}

export interface HoldingsBreakdown {
  asOfDate: number;
  holdingsContributions: HoldingsContribution[];
}

export interface HoldingsCategory {
  categoryName: string;
  children: HoldingsCategory[];
  id: HoldingsCategoryId;
}

export interface HoldingsCategoryId {
  id: string;
}

export interface HoldingsContribution {
  allocation: number;
  id: HoldingsCategoryId;
}

export interface HoldingsDataRequest {
  breakdownType: HoldingsBreakdownTypeEnum;
  subjects: HoldingsSubject[];
}

export interface HoldingsDataResponse {
  asOfDate?: number;
  breakdowns?: HoldingsBreakdown[];
  error?: ErrorResponse;
}

export interface HoldingsSubject {
  investment?: string;

  portfolio?: Portfolio;
}

export interface HomeContent {
  whatsNewContent: WhatsNewContent;
}

export interface ImageDetails {
  basePath: string;
  contentStream: any;
  contentType: string;
  id: string;
}

export interface Integration {
  created: number;
  id: string;
  info: string;
  provider: string;
  providerDisplayName: string;
  providerType: IntegrationProviderTypeEnum;
  requestingUserId: number;
  status: IntegrationStatusEnum;
  updated: number;
  website: string;
  workspaceId: number;
}

export interface IntegrationAdminRequest {
  integrationId: string;
  status: IntegrationStatusEnum;
}

export interface IntegrationRequest {
  info: string;
  provider: string;
  providerType: IntegrationProviderTypeEnum;
  website: string;
}

export interface IntegrationSetupConfig {
  authorizationUrl: string;
}

export interface IntegrationSource {
  available: boolean;
  displayName: string;
  name: string;
  type: IntegrationProviderTypeEnum;
}

export interface InternalWorkspaceInvitation {
  created: number;
  createdUserId: number;
  email: string;
  expirationDate: number;
  invitedById: number;
  organizationId: number;
}

export interface InvestmentFactorForecast {
  annualizedResidualReturn: number;
  annualizedTotalReturn: number;
  cashForecast: number;
  factorBreakdown: FactorAnalysisBreakdown[];
  factorForecasts: FactorForecast[];
  factorGroups: FactorGroupForecast[];
}

export interface InvestmentForecast {
  cashForecast: boolean;
  fundId: string;
  returnVal: number;
}

export interface InvestmentRange {
  end: number;
  frequency: FrequencyEnum;
  id: string;
  name: string;
  numReturns?: number;
  start: number;
}

export interface InvestmentResidualOverride {
  created: number;
  forecastResidual?: number;
  forecastReturn?: number;
  fundId: string;
  isManualOverride: boolean;
  permissionGroupId: number;
  userId: number;
  workspaceId: number;
}

export interface InvestmentVenncast {
  fundId: string;
  fundName: string;
  lastReturnDate: number;
  ranges: InvestmentVenncastPeriod[];
  residualRisk: number;
}

export interface InvestmentVenncastPeriod {
  estimated: boolean;
  period: VenncastPeriodEnum;
  periodReturn: number;
  periodReturnError: number;
}

export interface InvitationDetails {
  activationCode: string;
  countryCode: string;
  email: string;
  expirationDate: number;
  organizationId: number;
  organizationLegalName: string;
}

export interface InvitationQuery {
  creationEnd: number;
  creationStart: number;
  emails: string[];
  expirationEnd: number;
  expirationStart: number;
  invitedByIds: number[];
  limit: number;
  organizationIds: number[];
  page: number;
  pending: boolean;
}

export interface InviteValidation {
  address: string;
  status: InviteValidationStatusEnum;
}

export interface InviteValidationResponse {
  emails: InviteValidation[];
  outsideDomain: boolean;
  success: boolean;
}

export interface ItemId {
  id: string;
  type: ItemTypeEnum;
}

export interface JsonNode {}

export interface LegalAgreementEntry {
  agreementDate: number;
  documentKey: string;
  documentVersion: string;
  email: string;
  ipAddress: string;
  organizationId: number;
  userId: number;
}

export interface LegalAgreementQuery {
  keys: string[];
  limit: number;
  page: number;
  userIds: number[];
}

export interface LegalDocument {
  text: string;
  active: boolean;
  activeDate: number;
  deactiveDate: number;
  key: string;
  language: UserLanguageEnum;
  legacyId: number;
  name: string;
  version: string;
}

export interface LegalDocumentContent {
  active: boolean;
  activeDate: number;
  deactiveDate: number;
  key: string;
  language: UserLanguageEnum;
  legacyId: number;
  markdownText: string;
  name: string;
  version: string;
}

export interface LegalDocumentJurisdiction {
  active: boolean;
  activeDate: number;
  billingType: string;
  countryCode: string;
  deactiveDate: number;
  document: LegalDocument;
}

export interface LegalDocumentQuery {
  activeDocument: boolean;
  activeJurisdiction: boolean;
  billingTypes: string[];
  countries: string[];
  keys: string[];
  limit: number;
  page: number;
}

export interface LegalFontOverrides {
  coverPageDisclosureFontSize?: number;
  disclaimerFontSize?: number;
  disclosureFontSize?: number;
}

export interface LensPerformanceRequest {
  end: number;
  factorIds: number[];
  fundId: string;
  maximumPoints: number;
  portfolioId: number;
  showInputs: boolean;
  start: number;
}

export interface LibraryIndexRequest {
  funds: string[];
  portfolios: number[];
  privateFunds: string[];
  privatePortfolios: string[];
  updateType: UpdateTypeEnum;
}

export interface LibrarySearchEntity {
  accountNumber: string;
  active: boolean;
  annualizedAlpha: number;
  annualizedAlphaTStat: number;
  annualizedResidualReturn: number;
  annualizedSharpeRatio: number;
  annualizedTotalReturn: number;
  annualizedVolatility: number;
  assetClass: AssetClassEnum;
  assetType: AssetTypeEnum;
  capitalCommitment: number;
  category: string;
  coreIndustries: string[];
  created: number;
  cumulativeReturn: number;
  currency: string;
  currencyName: string;
  currentlyProcessing: boolean;
  dataSource: string;
  endRange: number;
  externalId: string;
  extrapolated: boolean;
  factorLensId: number;
  factorLensName: string;
  fileSize?: number;
  finalCloseDate: number;
  finalCloseSize: number;
  frequency: FrequencyEnum;
  fundId: string;
  geographicFocus: string;
  hasFactorAnalysis: boolean;
  hasMinDataForInterpolation: boolean;
  industries: string[];
  interpolatable: boolean;
  interpolated: boolean;
  interpolatedFundId: string;
  interpolationCategory?: ProxyCategoryEnum;
  investmentSource: InvestmentSourceTypeEnum;
  investmentSourceName: string;
  investmentSubtype?: InvestmentSubtypeEnum;
  investmentType: InvestmentTypeEnum;
  live: boolean;
  manager: string;
  master: boolean;
  maxDrawdown: Period;
  metricsOnTimePeriod: { [key: string]: { [key: string]: number } };
  morningstarCategories: string[];
  morningstarCategory: string;
  morningstarCategoryCodes: string[];
  name: string;
  needsInterpolation: boolean;
  needsProxy: boolean;
  organizationId: number;
  outOfDate: boolean;
  owner: AppUser;
  ownerContextFriendlyName?: string;
  ownerContextId?: string;
  periodAlpha: number;
  periodAnnualized: boolean;
  periodReturn: number;
  permissionGroupId: number;
  portfolioId: number;
  primaryShareClass?: boolean;
  privateFundAssetClass: string;
  privateFundId: string;
  privateFundPerformance: PrivateFundPerformance;
  privateFundType: string;
  privatePortfolioId: string;
  proxyCategory?: ProxyCategoryEnum;
  proxyEndRange?: number;
  proxyId?: string;
  proxyName?: string;
  proxyNumLags?: number;
  proxyStartRange?: number;
  proxyType?: ProxyTypeEnum;
  proxyable: boolean;
  remoteType: RemoteTypeEnum;
  shareClass?: ShareClassEnum;
  shareClassName?: string;
  size: number;
  startRange: number;
  status: string;
  strategy: string;
  subStrategy: string;
  symbol: string;
  tagIds: string[];
  tags?: string[];
  tenYearCumulativeReturn: number;
  tenYearCumulativeReturnSeries: number[][];
  unproxiedEndRange: number;
  unproxiedFrequency: FrequencyEnum;
  unproxiedNumReturns: number;
  unproxiedStartRange: number;
  updated: number;
  userUploaded: boolean;
  version?: number;
  vintage: number;
}

export interface LibrarySearchResult {
  results: LibrarySearchEntity[];
  total: number;
}

export interface ListTeamsRequest {
  accountId: number;
}

export interface LocalDateParam {}

export interface Locality {
  name: string;
  restricted: boolean;
}

export interface LoginAuditEntry {
  created: number;
  ipAddress: string;
  organizationId: number;
  userId: number;
}

export interface LoginAuditQuery {
  endDate: number;
  limit: number;
  organizationIds: number[];
  page: number;
  startDate: number;
  userIds: number[];
}

export interface LoginResponse {
  enabledFactors: MFATypeEnum[];
  mfaRequired: boolean;
  mfaSignupRequired: boolean;
  orgId: number;
  phoneNumber: string;
  primaryMfaType: MFATypeEnum;
  samlLoginUrl: string;
  success: boolean;
  userId: number;
}

export interface LogoDetails {
  basePath: string;
  contentStream: any;
  contentType: string;
  default: boolean;
  id: string;
}

export interface MFAResponse {
  email: string;
  enabled: boolean;
  totpSecret: string;
  totpURI: string;
  totpURIQR: string;
}

export interface MFAStatus {
  enabled: boolean;
  phoneNumber?: string;
  primaryType: MFATypeEnum;
  verifiedPhone: boolean;
  verifiedTotp: boolean;
}

export interface ManualOnboardingEmailRequest {
  attachmentBase64: string;
  attachmentFileName: string;
  extraMessage: string;
  fromEmail: string;
  fromName: string;
  replyEmail: string;
  templateName: string;
  userId: number;
}

export interface MapContextToTeamRequest {
  contextId: string;
  roleId: string;
  teamId: string;
}

export interface MappedFund {
  allocation: number;
  fundId: string;
  fundName: string;
  isin: string;
  symbol: string;
}

export interface MappedPrivateFund {
  fundId: string;
  fundName: string;
  hasErrors: boolean;
  hasMetadata: boolean;
  rawFundName: string;
  skipPersist: boolean;
  writeType: WriteTypeEnum;
}

export interface MappingAndErrors {
  errors: ColumnErrors[];
  hasInvalidOrMissingDates: boolean;
  mapping: FileMapping;
}

export interface MappingMetrics {
  annualizedReturn: number;
  annualizedVolatility: number;
  cumulativeReturn: number[][];
  needsBackfill: boolean;
  newDataCount: number;
  newNav: number;
  oldNav: number;
  periodAnnualized: boolean;
  periodReturn: number;
  recentMonthlyReturns: number[][];
  startOfNeededForFactorAnalysis: number;
  startOfNewData: number;
}

export interface MediaType {
  parameters: { [key: string]: string };
  subtype: string;
  type: string;
  wildcardSubtype: boolean;
  wildcardType: boolean;
}

export interface Message {
  code: number;
  text: string;
  type: MessageTypeEnum;
}

export interface MessageBodyWorkers {}

export interface MessageWithContext {
  message: Message;
  subjectName: string;
}

export interface MetricFilter {
  maximum?: number;
  metric: ComputableMetricEnum;
  minimum?: number;
  timePeriod: TimePeriodEnum;
}

export interface Morningstar {
  type: 'morningstar-peer-group-id';
  categoryId: string;
  categoryName: string;
}

export interface MultiOptimizationConfiguration {
  constraints: PortfolioConstraint[];
  disableResidualForecasts: boolean;
  factorForecastId: string;
  factorForecastVersion: string;
  id: string;
  maxDrawdown: number;
  maxNumberOfAssets: number;
  maxVolatility: number;
  minReturn: number;
  minSharpe: number;
  name: string;
  optimizationTarget: OptimizationTargetEnum;
  portfolio: Portfolio;
  relaxationsNum: number;
  relaxationsPercent: number;
  unconstrainedAllocations: boolean;
}

export interface MultiOptimizationResponse {
  alternatePortfolios: OptimizedPortfolio[];
  averageInvestmentBuys: TradeSummary[];
  averageInvestmentSells: TradeSummary[];
  optimizedPortfolio: OptimizedPortfolio;
}

export interface MultiPart {
  bodyParts: BodyPart[];
  contentDisposition: ContentDisposition;
  entity: any;
  headers: { [key: string]: string[] };
  mediaType: MediaType;
  messageBodyWorkers: MessageBodyWorkers;
  parameterizedHeaders: { [key: string]: ParameterizedHeader[] };
  parent: MultiPart;
  providers: Providers;
}

export interface MultiPortfolioParseResult {
  portfolioParseResults: PortfolioParseResult[];
}

export interface MultiPortfolioPersistInput {
  portfolioPersistInputs: PortfolioPersistInput[];
}

export interface MultiPortfolioPersistResult {
  portfolioPersistResults: PortfolioPersistResult[];
}

export interface NamedEntity {
  id: any;
  name: string;
}

export interface NamedEntityInteger {
  id: number;
  name: string;
}

export interface NamedEntityLong {
  id: number;
  name: string;
}

export interface NamedEntityObject {
  id: any;
  name: string;
}

export interface NamedEntityString {
  id: string;
  name: string;
}

export interface NamedEntityUUID {
  id: string;
  name: string;
}

export interface NestedFactorInput {
  contribution: number;
  name: string;
}

export interface NestedFactorInputCollection {
  end: number;
  nestedInputs: NestedFactorInput[];
  start: number;
}

export interface NotableFactorPeriod {
  active: boolean;
  created: number;
  endDate: number;
  factorIds: number[];
  name: string;
  periodContext: string;
  periodId: string;
  sequence: number;
  startDate: number;
  updated: number;
}

export interface NotableFactorPeriodGroup {
  factorId: number;
  factorPeriods: NotableFactorPeriod[];
}

export interface NotablePeriod {
  end: number;
  extremumDate: number;
  extremumValue: number;
  id: number;
  name: string;
  start: number;
  type: TypeEnum;
}

export interface OptimizedPortfolio {
  portfolio: Portfolio;
  summary: PortfolioSummary;
}

export interface PageAnalysisSnapshot {
  pageNumber: number;
  results: AnalysisSnapshot[];
  resultsPerPage: number;
  totalResults: number;
}

export interface PageAnalysisViewSearchResult {
  pageNumber: number;
  results: AnalysisViewSearchResult[];
  resultsPerPage: number;
  totalResults: number;
}

export interface PageDataSetWithMembers {
  pageNumber: number;
  results: DataSetWithMembers[];
  resultsPerPage: number;
  totalResults: number;
}

export interface PageDocument {
  pageNumber: number;
  results: Document[];
  resultsPerPage: number;
  totalResults: number;
}

export interface PageFundMigrationStatus {
  pageNumber: number;
  results: FundMigrationStatus[];
  resultsPerPage: number;
  totalResults: number;
}

export interface PageLoginAuditEntry {
  pageNumber: number;
  results: LoginAuditEntry[];
  resultsPerPage: number;
  totalResults: number;
}

export interface PagePortfolio {
  pageNumber: number;
  results: Portfolio[];
  resultsPerPage: number;
  totalResults: number;
}

export interface PageSubscription {
  pageNumber: number;
  results: Subscription[];
  resultsPerPage: number;
  totalResults: number;
}

export interface PagedSearchDocumentSearch {
  pageNumber: number;
  query: DocumentSearch;
  resultsPerPage: number;
}

export interface PagedSearchFundMigrationStatusSearch {
  pageNumber: number;
  query: FundMigrationStatusSearch;
  resultsPerPage: number;
}

export interface PagedSearchString {
  pageNumber: number;
  query: string;
  resultsPerPage: number;
}

export interface PaginationParams {
  order: OrderEnum;
  page: number;
  pageSize: number;
  sortBy: string;
}

export interface PairLocalDateTimeLocalDateTime {
  key: number;
  left: number;
  right: number;
  value: number;
}

export interface ParameterizedHeader {
  parameters: { [key: string]: string };
  value: string;
}

export interface ParsedFileUploadResponse {
  completed: boolean;
  created: number;
  documentUuid: string;
  documentVersion: number;
  fileId: number;
  fileSource: string;
  mappingFileSize: number;
  metadataDifferences: string;
  name: string;
  ownerId: number;
  updated: number;
  uploadTime: number;
}

export interface ParsedPrivateFunds {
  fileId: string;
  funds: { [key: string]: PrivateFund };
  mappings: MappedPrivateFund[];
  performance: { [key: string]: PrivateFundPerformance[] };
  portfolio: PrivatePortfolioNode;
  transactions: { [key: string]: PrivateFundTransaction[] };
}

export interface PeerGroup {
  constituents: number;
  identifier: PeerGroupIdentifier;
  rangeEnd?: number;
  rangeStart?: number;
}

export interface PeerGroupAnalysisResponse {
  subjectsData: { [key: string]: { [key: string]: PeerGroupAnalysisSubjectMetricResponse[] } };
  universeData: { [key: string]: { [key: string]: PeerGroupAnalysisUniverseMetricResponse } };
}

export interface PeerGroupAnalysisSubjectMetricResponse {
  percentile?: number;
  rank?: number;
  value?: number;
}

export interface PeerGroupAnalysisUniverseMetricResponse {
  constituents: number;
  max?: number;
  min?: number;
  p25?: number;
  p5?: number;
  p50?: number;
  p75?: number;
  p95?: number;
}

export type PeerGroupIdentifier = Morningstar | SavedSearch;

export interface PeerGroupQuery {
  limit: number;
  name: string;
  source: PeerGroupSourceEnum;
}

export interface PercentageDrawdownAnalysis {
  series: number[][];
}

export interface PerformanceAttribution {
  contribution?: number;
  contributionValue?: number;
  name?: string;
  value?: number;
  weight?: number;
}

export interface PerformanceFilter {
  field: PerformanceFilterFieldEnum;
  maximum: number;
  minimum: number;
}

export interface PerformancePortfolioContributionResponse {
  periodAnnualized: boolean;
  portfolioContribution: PortfolioContributionPerformanceSummary;
}

export interface PerformanceSummary {
  informationRatio?: number;
  maxDrawdown?: number;
  maxUnderperformance?: number;
  periodExcessReturn?: number;
  periodReturn?: number;
  sharpe?: number;
  trackingError?: number;
  volatility?: number;
}

export interface PerformanceSummaryResponse {
  autocorrelation: number;
  averageDownMonth: number;
  averageUpMonth: number;
  averageWeight: number;
  battingAverage: number;
  betaToBenchmark: number;
  calmar: number;
  conditionalValueAtRisk95: number;
  correlation: number;
  downsideCaptureRatio: number;
  downsideCorrelation: number;
  downsideVolatility: number;
  endingWeight: number;
  excessTotalReturn: number;
  fixedPeriodsPerformance?: { [key: string]: PerformanceSummaryResponse };
  informationRatio: number;
  kurtosis: number;
  maxDrawdown: number;
  maxUnderperformance: number;
  periodAnnualized: boolean;
  periodExcessReturn: number;
  periodReturn: number;
  sharpe: number;
  skewness: number;
  sortino: number;
  startingWeight: number;
  totalReturn: number;
  trackingError: number;
  upsideCaptureRatio: number;
  upsideVolatility: number;
  valueAtRisk: number;
  valueAtRisk95: number;
  valueAtRisk975: number;
  valueAtRisk99: number;
  volatility: number;
  yearlyPerformance: { [key: string]: number };
  ytdReturn: number;
}

export interface Period {
  completed: boolean;
  end: number;
  start: number;
  trough: number;
  troughDrawdown: number;
  troughDrawdownError: number;
}

export interface PermissionAction {
  action: ActionEnum;
  description: string;
  name: string;
}

export interface PermissionContext {
  role: PermissionRole;
  teamContext: TreeContext;
}

export interface PermissionEntry {
  resourceEntityId: string;
  roleId: string;
  userEntityId: string;
}

export interface PermissionGroup {
  id: number;
  name: string;
}

export interface PermissionGroupEntity {
  id: number;
  name: string;
  type: PermissionGroupTypeEnum;
}

export interface PermissionRole {
  actions: PermissionAction[];
  description: string;
  id: string;
  name: string;
}

export interface PermissionTeam {
  permissionContexts: PermissionContext[];
  team: Team;
}

export interface PermissionUser {
  country: UserCountryEnum;
  hasElevatedPrivileges: boolean;
  name: string;
  orgId: number;
  orgPermissionGroup: number;
  permissionGroups: number[];
  superUser: boolean;
  systemUser: boolean;
  userId: number;
  userPermissionGroup: number;
  writePermissionGroups: number[];
}

export interface Portfolio {
  allocation?: number;
  cashBalance?: number;
  children: Portfolio[];
  closingAllocationsTs?: number[][];
  compare?: PortfolioCompare[];
  created?: number;
  customRebalanceMonths?: number;
  dataSource?: string;
  demo: boolean;
  draft: boolean;
  formattedPeriodStartEnd?: string;
  fund?: SimpleFund;
  grossLeverage?: number;
  grossPosition?: number;
  historical: boolean;
  id: number;
  leveraged?: boolean;
  longOnlyLeverage?: number;
  longPosition?: number;
  master: boolean;
  name: string;
  netAssets?: number;
  netLeverage?: number;
  optimizationId?: string;
  order?: number;
  owner?: AppUser;
  ownerContextId?: string;
  periodEnd?: number;
  periodStart?: number;
  portfolioStartDate?: number;
  rebalanceStrategy?: PortfolioRebalancingStrategyEnum;
  remoteId?: string;
  remoteType?: RemoteTypeEnum;
  sourceId?: string;
  updated?: number;
  updatedBy?: AppUser;
  version?: number;
}

export interface PortfolioBreakdownItem {
  allocation: number;
  fund?: SimpleFund;
  label: string;
  path: string[];
  weight: number;
}

export interface PortfolioBreakdownResponse {
  items: PortfolioBreakdownItem[];
}

export interface PortfolioCompare {
  benchmark: boolean;
  fundId?: string;
  name: string;
  portfolioId?: number;
  primary: boolean;
  type: BenchmarkTypeEnum;
}

export interface PortfolioConstraint {
  allEntities: boolean;
  condition: AllocationConstraintConditionEnum;
  constraintType: ConstraintTypeEnum;
  targets: ConstraintTarget[];
  value?: number;
  valueType: AllocationConstraintValueTypeEnum;
}

export interface PortfolioContributionFactorExposureNode {
  allocation: number;
  children: PortfolioContributionFactorExposureNode[];
  fundId: string;
  name: string;
  portfolioId: number;
  value: FactorExposureNode;
}

export interface PortfolioContributionPerformanceSummary {
  allocation: number;
  children: PortfolioContributionPerformanceSummary[];
  fundId: string;
  name: string;
  portfolioId: number;
  value: PerformanceSummary;
}

export interface PortfolioContributionPerformanceSummaryResponse {
  allocation: number;
  children: PortfolioContributionPerformanceSummaryResponse[];
  fundId: string;
  name: string;
  portfolioId: number;
  value: PerformanceSummaryResponse;
}

export interface PortfolioData {
  assets: PortfolioDataAsset[];
  baselinePortfolioId: number;
  baselinePortfolioVersion: number;
}

export interface PortfolioDataAsset {
  fundId: string;
  newAllocation: number;
  oldAllocation: number;
  optimizedAllocation: number;
  parentId: number;
}

export interface PortfolioHistoricalResidualReturnsResponse {
  portfolioContribution: PortfolioContributionPerformanceSummaryResponse;
}

export interface PortfolioId {
  type: 'portfolio';
  id: number;
}

export interface PortfolioParseResult {
  errors?: string[];
  mappedPortfolio?: Portfolio;
  parsedPortfolio: Portfolio;
}

export interface PortfolioPerformanceAttribution {
  funds: PerformanceAttribution[];
  strategies: PerformanceAttribution[];
}

export interface PortfolioPerformanceAttributions {
  analysisEnd: number;
  analysisStart: number;
  betaToBenchmark: PortfolioPerformanceAttribution;
  informationRatio?: PortfolioPerformanceAttribution;
  maxDrawdown: PortfolioPerformanceAttribution;
  maxUnderperformance: PortfolioPerformanceAttribution;
  periodAnnualized: boolean;
  periodExcessReturn: PortfolioPerformanceAttribution;
  periodReturn: PortfolioPerformanceAttribution;
  sharpe?: PortfolioPerformanceAttribution;
  trackingError: PortfolioPerformanceAttribution;
  volatility: PortfolioPerformanceAttribution;
}

export interface PortfolioPersistInput {
  portfolio: Portfolio;
  writeMode: PortfolioWriteModeEnum;
}

export interface PortfolioPersistResult {
  errors?: string[];
  portfolio: Portfolio;
  status: PortfolioPersistStatusEnum;
}

export interface PortfolioPolicy {
  constraints: PortfolioConstraint[];
  created?: number;
  factors?: { [key: string]: FactorExposure };
  id?: string;
  permissionGroupId?: number;
  portfolioId: number;
  updated?: number;
}

export interface PortfolioPolicyCheckRequest {
  baselinePortfolio: Portfolio;
  portfolio: Portfolio;
  portfolioPolicy: PortfolioPolicy;
}

export interface PortfolioQuery {
  active: boolean;
  maxUpdated: number;
  minUpdated: number;
  permissionGroupId: number;
}

export interface PortfolioRange {
  baseline: InvestmentRange;
  benchmark: InvestmentRange;
  factors: InvestmentRange[];
  investments: InvestmentRange[];
  name: string;
  secondary: InvestmentRange;
}

export interface PortfolioSummary {
  annualizedExcessReturn: number;
  annualizedSharpeRatio: number;
  annualizedTotalReturn: number;
  annualizedTrackingError: number;
  annualizedVolatility: number;
  created: number;
  efficientFrontier: RiskReturnPoint[];
  efficientFrontierPortfolioIndex: number;
  efficientFrontierPortfolios: EfficientFrontierPortfolio[];
  factorExposures: FactorExposure[];
  factorForecastId: string;
  factorForecastVersion: string;
  id: number;
  maxDrawdown: number;
  name: string;
  optimizationId: string;
  optimizationTargetId: number;
  originalPortfolioId: number;
  residualReturnContribution: number;
  residualRiskContribution: number;
  resultTargetId: number;
  selectedStrategyId: number;
  targetMaxVolatility: number;
  targetMinReturn: number;
  type: PortfolioSummaryTypeEnum;
}

export interface PrintSettings {
  blockFontOverrides?: BlockFontOverrides;
  coverPageDisclosure?: string;
  coverPageDisclosureFontSize?: number;
  disclaimer?: string;
  disclaimerFontSize?: number;
  disclosure?: string;
  disclosureFontSize?: number;
  legalFontOverrides: LegalFontOverrides;
  logoId?: string;
  organizationName?: string;
  sectionPageFontOverrides?: SectionPageFontOverrides;
  titlePageFontOverrides?: TitlePageFontOverrides;
}

export interface PrintingRequest {
  emulateMediaType: EmulateMediaTypeEnum;
  final: boolean;
  footer: string;
  header: string;
  name: string;
  ownerContextId: string;
  printOrientationType: PrintOrientationTypeEnum;
  uploadType: UploadTypeEnum;
  url: string;
  viewId: string;
}

export interface PrivateAnalysisError {
  fundInfo?: FundInfo;
  message: string;
  severity: SeverityEnum;
}

export interface PrivateAssetSummaryResponse {
  fundsWithCapitalCommitments: PrivateFundWithCapitalCommitment[];
}

export interface PrivateFund {
  active: boolean;
  assetClass: string;
  benchmarks?: Benchmark[];
  capitalCommitment: number;
  coreIndustries: string[];
  created: number;
  currency: string;
  dataSource: string;
  externalId: string;
  finalCloseDate: number;
  finalCloseSize: number;
  fundType: string;
  geographicFocus: string;
  geographicFocusName: string;
  id: string;
  industries: string[];
  manager: string;
  name: string;
  owner?: AppUser;
  ownerId: number;
  permissionGroupId: number;
  size: number;
  status: string;
  strategy: string;
  subStrategy: string;
  updated: number;
  updatedBy?: AppUser;
  updatedById: number;
  userUploaded: boolean;
  vintage: number;
}

export interface PrivateFundCapitalCall {
  fundId: string;
  name: string;
  projectedCapitalCall: number;
}

export interface PrivateFundId {
  type: 'private-fund-id';
  id: string;
}

export interface PrivateFundPerformance {
  asOfDate: number;
  calledPct: number;
  dpiPct: number;
  netIrrPct: number;
  netMultiple: number;
  quartile: number;
  rvpiPct: number;
}

export interface PrivateFundTransaction {
  contribution?: number;
  cumulativeContribution?: number;
  cumulativeDistribution?: number;
  distribution?: number;
  nav?: number;
  transactionDate: number;
}

export interface PrivateFundWithCapitalCommitment {
  capitalCommitment: number;
  fund: PrivateFund;
}

export interface PrivatePerformanceSummaryResponse {
  asOfDate: number;
  calledPercentage: number;
  capitalCommitment: number;
  children?: PrivatePerformanceSummaryResponse[];
  dpiPercentage: number;
  error: string;
  errorMessages: FundErrorMessages[];
  errors?: { [key: string]: PrivateAnalysisError[] };
  name: string;
  nav: number;
  netIRRPercentage: number;
  netMultiple: number;
  pmeCapitalDynamics: number;
  pmeDirectAlpha: number;
  pmeKaplanSchoar: number;
  pmeLongNickels: number;
  rvpiPercentage: number;
  subject: PrivateSubject;
  vintage: number;
}

export interface PrivatePerformanceTimeSeriesResponse {
  calledPercentage: number[][];
  capitalCommitment: number;
  dpiPercentage: number[][];
  error: string;
  errorMessages: FundErrorMessages[];
  errors?: { [key: string]: PrivateAnalysisError[] };
  name: string;
  nav: number[][];
  netIRRPercentage: number[][];
  netMultiple: number[][];
  pmeCapitalDynamics: number[][];
  pmeDirectAlpha: number[][];
  pmeKaplanSchoar: number[][];
  pmeLongNickels: number[][];
  projectionAsOfDate: number;
  rvpiPercentage: number[][];
  subject: PrivateSubject;
  vintage: number;
}

export interface PrivatePortfolio {
  type: 'private-portfolio';
  portfolio: PrivatePortfolioNode;
}

export interface PrivatePortfolioId {
  type: 'private-portfolio-id';
  id: string;
}

export interface PrivatePortfolioInfo {
  capitalCommitment: number;
  created: number;
  createdBy: AppUser;
  id: string;
  name: string;
  ownerContextId: string;
  updated: number;
  updatedBy: AppUser;
}

export interface PrivatePortfolioNode {
  active: boolean;
  benchmarks?: Benchmark[];
  capitalCommitment: number;
  children: PrivatePortfolioNode[];
  created: number;
  dataSource: string;
  fundId: string;
  id: string;
  name: string;
  owner?: AppUser;
  ownerContextId: string;
  ownerId: number;
  parentNodeId: string;
  permissionGroupId: number;
  rootNodeId: string;
  updated: number;
  updatedBy?: AppUser;
  updatedById: number;
}

export interface PrivatePortfolioNodeResponseWrapper {
  result?: PrivatePortfolioNode;
}

export type PrivateSubject = PrivatePortfolio | PrivatePortfolioId | PrivateFundId;

export interface PrivatesAnalysisRequest {
  analysisTypes: AnalysisTypeEnum[];
  asOfDate?: number;
  benchmarks?: (AnalysisSubject | undefined | null)[];
  endOnLatestAvailableTransaction: boolean;
  projectAfterLastCashFlow?: boolean;
  subjects: PrivateSubject[];
  trackingId: number;
}

export interface PrivatesAnalysisResponse {
  cashFlows: CashFlowResponse[];
  performanceSummary: PrivatePerformanceSummaryResponse[];
  performanceTimeSeries: PrivatePerformanceTimeSeriesResponse[];
}

export interface Providers {}

export interface ProxiedReturnsData {
  proxied: boolean;
  returns: number[][];
  unproxiedEnd: number;
  unproxiedFrequency: FrequencyEnum;
  unproxiedNumReturns: number;
  unproxiedStart: number;
}

export interface ProxyCategoryMapping {
  category: ProxyCategoryEnum;
  displayName: string;
  indexId: string;
  indexName: string;
}

export interface ProxyMeta {
  extrapolate: boolean;
  fundId: string;
  numLags: number;
  proxyId?: string;
  proxyType: ProxyTypeEnum;
}

export interface ProxyMetrics {
  correlation: number;
  cumulativeProxiedReturns: number[][];
  error?: ProxyErrorEnum;
  extrapolated: boolean;
  extrapolatedEnd?: number;
  extrapolatedStart?: number;
  investmentReturns: number[][];
  numLags?: number;
  percentMatch: number;
  proxiedEnd: number;
  proxiedStart: number;
  proxy: SearchFund;
  proxyCategory?: ProxyCategoryEnum;
  proxyReturns: number[][];
  proxyType?: ProxyTypeEnum;
  rawProxiedReturns: number[][];
  suggestedNumLags?: number;
  warning?: ProxyWarningEnum;
}

export interface ProxyOption {
  category: ProxyCategoryMapping;
  correlation: number;
  error?: ProxyErrorEnum;
  interpolatedCumulativeReturn: number[][];
  numLags?: number;
  proxiedCumulativeReturn: number[][];
  rawCumulativeReturn: number[][];
  suggestedNumLags?: number;
  valid: boolean;
  warning?: ProxyWarningEnum;
}

export interface PublishPrivatePortfolioRequest {
  destinationId: string;
  name: string;
  sourceId: string;
}

export interface PublishingSubscriptionQuery {
  accepted: boolean;
  end: number;
  ids: string[];
  page: number;
  pageSize: number;
  publisherAccountIds: number[];
  start: number;
  status: PublishingSubscriptionStatusEnum[];
  subscriberAccountIds: number[];
}

export interface RangeAnalysisResponse {
  earliestStart: number;
  end: number;
  factorRange: SubjectRangeAnalysis;
  finestFrequency: FrequencyEnum;
  frequency: FrequencyEnum;
  latestEnd: number;
  rangeAnalyses: SubjectRangeAnalysis[];
  start: number;
}

export interface RangeDebugResponse {
  constrainingPrimaryEnd: boolean;
  constrainingPrimaryFrequency: boolean;
  constrainingPrimaryStart: boolean;
  historicalPortfolioMetadata?: HistoricalPortfolioMetadata;
  insufficientRange: boolean;
  maxEndDate: number;
  maxFrequency: FrequencyEnum;
  maxStartDate: number;
  portfolioRange: PortfolioRange;
}

export interface RawPortfolio {}

export interface RecentFactorPerformance {
  forecastReturn: number;
  id: number;
  mtdReturn: number;
  performanceAlert?: FactorAlert;
  qtdReturn: number;
  sevenDayReturn: number;
  ytdCumulativeReturn: number[][];
}

export interface RecentPortfolioFactorHistory {
  factorPerformance: RecentFactorPerformance[];
}

export interface RecentPortfolioRange {
  masterPortfolio: Portfolio;
  recentEnd: number;
  recentStart: number;
}

export interface RecentUserActivity {
  recentOrganizationInvestments: SimpleFund[];
  recentUserInvestments: SimpleFund[];
}

export interface RegressionAnalysis {
  alpha: number;
  alphaStdError: number;
  alphaTValue: number;
  annualizedReturn: number;
  annualizedTotalRisk: number;
  annualizedVariance: number;
  annualizedVol: number;
  averageAnnualPortfolioReturn: number;
  averageFactorReturns: number[];
  averageResidualReturn: number;
  betas: number[];
  cashContribution: number;
  cumulativeResidualReturnContribution: number;
  cumulativeReturn: number;
  cumulativeReturnContribution: number[];
  factorInclusionMap: { [key: string]: number };
  factorReturn: number[];
  intercept: number;
  latestFactorReturns: number[];
  marginalRiskContribution: number[];
  residualMarginalRisk: number;
  residualReturnContribution: number;
  residualRisk: number;
  residualSharpe: number;
  residualVariance: number;
  residualVolatility: number;
  residuals: number[];
  returnContribution: number[];
  risk: number[];
  rsquared: number;
  sharpe: number[];
  stdErrors: number[];
  tvalues: number[];
}

export interface ReleaseNote {
  date: number;
  id: string;
  markdown: string;
  publishDate: number;
  status: ReleaseNoteStatusEnum;
}

export interface ReleaseNoteContent {
  markdown: string;
  publishDate: number;
  status: ReleaseNoteStatusEnum;
}

export interface RequiredAgreementQuery {
  active: boolean;
  keys: string[];
  limit: number;
  page: number;
}

export interface RequiredLegalDocument {
  active: boolean;
  created: number;
  key: string;
  recurranceType: RequiredRecurrenceTypeEnum;
}

export interface ResidualReturnsResponse {
  cashCumulativeContribution: number[][];
  factorCumulativeContribution: number[][];
  forecastedResidualPerformanceSummary: ForecastedPerformanceSummary;
  historicalResidualPerformanceSummary: PerformanceSummaryResponse;
  residualCumulativeContribution: number[][];
  totalCumulativeReturn: number[][];
}

export interface ResourceEntity {
  parentId: string;
  resourceId: string;
}

export interface ResponseBody {
  body: any;
}

export interface ResponseBodyBoolean {
  body: boolean;
}

export interface ResponseBodyDomainStatus {
  body: DomainStatusEnum;
}

export interface ResponseBodyString {
  body: string;
}

export interface ReturnCell {
  cellTypes: ReturnCellTypeEnum[];
  date: number;
  val: number;
}

export interface ReturnsFactorAnalysis {
  annualizedTotalRisk: number;
  factors: ReturnsFactorAnalysisBreakdown[];
  periodAnnualized: boolean;
  periodResidualReturn: number;
  periodTotalReturn: number;
  regressionEnd: number;
  regressionPeriods: number;
  regressionStart: number;
  regressionYears: number;
  regressionYearsOptions: { [key: string]: boolean };
  residualMarginalRisk: number;
  residualRisk: number;
  residualSharpe: number;
  residualSignificant: boolean;
  residualStdError: number;
  residualTStat: number;
  residualVariance: number;
  residualVolatility: number;
  riskFreeReturn: number;
  rsquared: number;
}

export interface ReturnsFactorAnalysisBreakdown {
  contribution: number;
  id: number;
  marginalContribution: number;
  name: string;
  periodAnnualized: boolean;
  significant: boolean;
  tstat: number;
}

export interface ReturnsGrid {
  returnCells: ReturnCell[];
  yearlyCumulativeReturn: ReturnCell[];
}

export interface ReturnsGridMapping {
  disabledFrequencies: FrequencyEnum[];
  returnsGridMap: { [key: string]: ReturnsGrid };
}

export interface ReturnsHistogram {
  bucketSize: number;
  frequency: FrequencyEnum;
  numReturns: number;
  returns: BucketEntry[];
}

export interface RiskReturnPoint {
  annualizedReturn: number;
  annualizedVolatility: number;
}

export interface RollingAnalysisResponse {
  annualized?: number;
  cumulative?: number;
  rolling: number[][];
}

export interface RollingCorrelationResponse {
  rolling: number[][];
  secondarySubjectName: NamedEntity;
  subjectName: NamedEntity;
}

export interface SavedSearch {
  type: 'saved-search-peer-group-id';
  categoryId: string;
  categoryName: string;
}

export interface Scenario {
  active?: boolean;
  created?: number;
  currency: CurrencyEnum;
  fundId: string;
  fundName: string;
  id?: string;
  maxShock: number;
  maxShockZScore: number;
  mean: number;
  minShock: number;
  minShockZScore: number;
  seriesType: ScenarioSeriesTypeEnum;
  shock: number;
  std: number;
  type: ScenarioTypeEnum;
  units: ShockUnitsEnum;
  updated?: number;
}

export interface ScenarioAnalysis {
  betas: number[];
  cashRateContribution: number;
  cashRateImpact: number;
  factorsPredictedContributionMap: { [key: string]: number };
  factorsPredictedErrorMap: { [key: string]: number };
  factorsPredictedImpactErrorMap: { [key: string]: number };
  factorsPredictedImpactMap: { [key: string]: number };
  factorsPredictedMap: { [key: string]: number };
  predicted: number;
  predictedError: number;
  status: StatusEnum;
}

export interface ScenarioAnalysisIndex {
  active: boolean;
  category: string;
  created: number;
  currency: CurrencyEnum;
  defaultScenario: boolean;
  defaultScenarioShock: number;
  fundId: string;
  fundName: string;
  id: string;
  mean: number;
  seriesType: ScenarioSeriesTypeEnum;
  shockRange: ScenarioShockRange;
  std: number;
  type: ScenarioTypeEnum;
  units: ShockUnitsEnum;
  updated: number;
}

export interface ScenarioAnalysisIndexConfiguration {
  active: boolean;
  category: string;
  created: number;
  currency: CurrencyEnum;
  defaultScenario: boolean;
  defaultScenarioShock: number;
  fundId: string;
  fundName: string;
  id: string;
  seriesType: ScenarioSeriesTypeEnum;
  type: ScenarioTypeEnum;
  units: ShockUnitsEnum;
  updated: number;
}

export interface ScenarioShockRange {
  maxShock: number;
  maxShockZScore: number;
  minShock: number;
  minShockZScore: number;
}

export interface ScheduledExport {
  active: boolean;
  analysisViewId: string;
  created: number;
  owner: AppUser;
  paused: boolean;
  scheduledExportId: string;
  scheduledFrequency: FrequencyEnum;
  updated: number;
}

export interface ScheduledLog {
  benchmarkName: string;
  created: number;
  documentId: string;
  emails: string[];
  endDate: number;
  exportFileName: string;
  exportType: ExportTypeEnum;
  exportsErrorType?: ExportsErrorTypeEnum;
  exportsStatus: ExportsStatusEnum;
  scheduledExportId: string;
  scheduledFrequency: FrequencyEnum;
  startDate: number;
  updated: number;
}

export interface SearchFilterField {
  key: string;
  name: string;
  type: FieldTypeEnum;
}

export interface SearchFund {
  accountNumber: string;
  active: boolean;
  analysisEnd: number;
  analysisStart: number;
  annualizedAlpha: number;
  annualizedAlphaTStat: number;
  annualizedExcessReturn: number;
  annualizedSharpeRatio: number;
  annualizedTotalReturn: number;
  annualizedVolatility: number;
  assetType: AssetTypeEnum;
  assetTypeName: string;
  benchmarkID: string;
  benchmarkName: string;
  created: number;
  currency: string;
  domicile: string;
  endRange: number;
  exchange: string;
  exchangeCountry: string;
  extrapolated: boolean;
  factorLensId: number;
  factorLensName: string;
  favorite: boolean;
  frequency: number;
  fundSourceType: FundSourceTypeEnum;
  groupedRiskContribution: FactorMetric[];
  id: string;
  interpolated: boolean;
  investmentBenchmarks: Benchmark[];
  investmentRegion: InvestmentRegionEnum;
  investmentRegionName: string;
  investmentSource: InvestmentSourceTypeEnum;
  investmentSourceName: string;
  legacyBenchmarkId: number;
  legacyId: number;
  live: boolean;
  maxDrawdown: Period;
  name: string;
  needsInterpolation: boolean;
  needsProxy: boolean;
  owner: AppUser;
  periodAlpha: number;
  periodAnnualized: boolean;
  periodReturn: number;
  proxyId: string;
  proxyName: string;
  riskContribution: FactorMetric[];
  startRange: number;
  symbol: string;
  trackingError: number;
  unproxiedEndRange: number;
  unproxiedFrequency: FrequencyEnum;
  unproxiedStartRange: number;
  updated: number;
  userUploaded: boolean;
  viewed: boolean;
}

export interface SearchQuery {
  assetTypes: AssetTypeEnum[];
  autocomplete: boolean;
  createdBy: CreatedByOptionEnum;
  currencyFilters: CurrencyEnum[];
  dataSources: string[];
  entityFilters: EntityFilterEnum[];
  excludeNonPrimaryShareClasses: boolean;
  excludedFundIds: string[];
  excludedPortfolioIds: number[];
  excludedPrivateFundIds: string[];
  excludedPrivatePortfolioIds: string[];
  fundIds: string[];
  fundSourceTypeIds: number[];
  inMasterOnly: boolean;
  includeTagsSearch: boolean;
  investmentSources: InvestmentSourceTypeEnum[];
  itemType: LibraryItemTypeEnum;
  metricFilters: MetricFilter[];
  morningstarCategories: string[];
  name: string;
  onlyTagName: boolean;
  onlyTaggedItems: boolean;
  order: OrderEnum;
  page: number;
  pageSize: number;
  performanceFilters: PerformanceFilter[];
  portfolioIds: number[];
  prioritizeMaster: boolean;
  privateAssetSearchMode: PrivateAssetSearchModeEnum;
  proxyable: boolean;
  quickFilters: LibraryQuickFilterEnum[];
  recentlyViewed: boolean;
  remoteTypes: RemoteTypeEnum[];
  requiresAttention: boolean;
  shareClasses: ShareClassEnum[];
  sortBy: string;
  tags: string[];
}

export interface SearchQueryParams {
  assetTypes: AssetTypeEnum[];
  autocomplete: boolean;
  createdBy: CreatedByOptionEnum;
  currencyFilters: CurrencyEnum[];
  dataSources: string[];
  entityFilters: EntityFilterEnum[];
  excludeNonPrimaryShareClasses: boolean;
  excludedFundIds: string[];
  excludedPortfolioIds: number[];
  excludedPrivateFundIds: string[];
  excludedPrivatePortfolioIds: string[];
  fundIds: string[];
  fundSourceTypeIds: number[];
  inMasterOnly: boolean;
  includeTagsSearch: boolean;
  investmentSources: InvestmentSourceTypeEnum[];
  itemType: LibraryItemTypeEnum;
  metricFilters: MetricFilter[];
  morningstarCategories: string[];
  name: string;
  onlyTagName: boolean;
  onlyTaggedItems: boolean;
  performanceFilters: PerformanceFilter[];
  portfolioIds: number[];
  prioritizeMaster: boolean;
  privateAssetSearchMode: PrivateAssetSearchModeEnum;
  proxyable: boolean;
  quickFilters: LibraryQuickFilterEnum[];
  recentlyViewed: boolean;
  remoteTypes: RemoteTypeEnum[];
  requiresAttention: boolean;
  shareClasses: ShareClassEnum[];
  tags: string[];
}

export interface SectionPageFontOverrides {
  nameFontSize: number;
  orgDisplayNameFontSize: number;
  subtitleFontSize: number;
  titleFontSize: number;
}

export interface SeriesCell {
  date: string;
  index: number;
  seriesId: string;
  value: string;
}

export interface SeriesColumn {
  dates: string[];
  formatter: number;
  header: string;
  id: string;
  origin: CellIndex;
  values: string[];
}

export interface SeriesErrorCell {
  date: string;
  errors: StreamErrorEnum[];
  index: number;
  seriesId: string;
  value: string;
}

export interface SeriesErrorRange {
  cells: SeriesErrorCell[];
  errors: StreamErrorEnum[];
}

export interface Session {
  active: boolean;
  end: number;
  organizationId: number;
  start: number;
  userId: number;
}

export interface SessionsSummary {
  averageSessionLength: Duration;
  dailyActiveUsers: number;
  monthlyActiveUsers: number;
  mostActiveUsers: AppUser[];
  numberOfActiveOrganizations: number;
  totalSessions: number;
  weeklyActiveUsers: number;
}

export interface SetAddeparReturnAttributeRequest {
  key: string;
}

export interface SetMigrationStatusRequest {
  all: boolean;
  datasource: string;
  migrationStatusType: MigrationStatusTypeEnum;
  status: boolean;
  uuids: string[];
}

export interface SetupPhoneRequest {
  phoneNumber: string;
}

export interface ShareMetadata {
  comparison: boolean;
  emails: string[];
  message: string;
  relativeLink: string;
  savedViewName: string;
  subjectName: string;
  templateName: string;
}

export interface SignupOptions {
  aumRanges: AUMRange[];
  companyTypes: CompanyTypeOption[];
  countries: Country[];
  localities: { [key: string]: Locality[] };
}

export interface SignupQuery {
  countryCodes: string[];
  limit: number;
  page: number;
}

export interface SignupRequest {
  activationCode: string;
  aumRangeId: string;
  companyType: string;
  countryCode: string;
  email: string;
  firstName: string;
  lastName: string;
  legalAgreements: string[];
  locality: string;
  organizationId: number;
  organizationLegalName: string;
  password: string;
}

export interface SignupRequestDetails {
  activationCode: string;
  aumRangeId: string;
  companyType: string;
  countryCode: string;
  created: number;
  email: string;
  firstName: string;
  ipAddressCountryCode: string;
  lastName: string;
  legalAgreements: string[];
  locality: string;
  organizationId: number;
  organizationLegalName: string;
  password: string;
  waitlisted: boolean;
}

export interface SignupResponse {
  existingAccount: boolean;
  jurisdiction: Country;
  mfaSetupRequired: boolean;
  organizationId: number;
  status: UserStatusEnum;
  userId: number;
}

export interface SignupUser {
  activationCode: string;
  actualUser: boolean;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  mfaCode: string;
  mfaType: MFATypeEnum;
  organizationId: number;
  password: string;
  totpVerificationCode: number;
  userSeatCapType: UserSeatCapTypeEnum;
}

export interface SimpleFactorPerformance {
  factorCumulativeReturns: { [key: string]: number[][] };
  frequency: FrequencyEnum;
  maxEnd: number;
  maxStart: number;
}

export interface SimpleFund {
  accountNumber: string;
  active: boolean;
  assetType: AssetTypeEnum;
  category: CategoryEnum;
  categoryAssetType?: CategoryAssetTypeOption;
  categoryGroup?: CategoryGroupOption;
  created: number;
  createdById: number;
  currency: CurrencyEnum;
  dataSource: string;
  domicile: string;
  endRange: number;
  exchange: string;
  exchangeCountry: string;
  fundSourceType: FundSourceTypeEnum;
  id: string;
  investmentObjective: string;
  investmentRegion: InvestmentRegionEnum;
  investmentSource: InvestmentSourceTypeEnum;
  isin?: string;
  live: boolean;
  morningstarCategory?: string;
  morningstarCategoryCode?: string;
  morningstarFundId?: string;
  name: string;
  netExpenseRatio: number;
  preferredFrequency: number;
  primaryBenchmark: string;
  primaryShareClass?: boolean;
  remoteType: RemoteTypeEnum;
  returnFrequency: FrequencyEnum;
  shareClass?: ShareClassEnum;
  startRange: number;
  symbol: string;
  updated: number;
  updatedByDataSource: string;
  updatedById: number;
  uploadName: string;
  userEditable: boolean;
  userUploaded: boolean;
}

export interface SnapshotMetadata {
  analysisViewActive: boolean;
  analysisViewId: string;
  analysisViewName: string;
  benchmarkName: string;
  endDate: number;
  exportSubjectName: string;
  exportTemplateName: string;
  exportType: ExportTypeEnum;
  name: string;
  startDate: number;
  subjectId: string;
  templateId: string;
}

export interface Sponsor {
  accountId: number;
  active: boolean;
  avatarId: string;
  colorScheme: string;
  contactEmail: string;
  displayName: string;
}

export interface SponsorInvitation {
  accountName: string;
  created: number;
  email: string;
  expirationDate: number;
  firstName: string;
  id: string;
  key: string;
  lastName: string;
  sponsorAccountId: number;
  sponsoredAccountId: number;
}

export interface SponsoredAccount {
  accountId: number;
  accountName: string;
  invitationEmail: string;
  invitationExpirationDate: number;
  invitationId: string;
  invitationKey: string;
  invited: number;
  status: AccountStatusEnum;
}

export interface SponsoredAccountsQuery {
  descending: boolean;
  page: number;
  pageSize: number;
  search: string;
  sort: SponsoredSortEnum;
}

export interface SponsoredAccountsResults {
  accounts: SponsoredAccount[];
  activeAccounts: number;
  invitedAccounts: number;
  total: number;
}

export interface StoredSearch {
  created: number;
  createdBy: AppUser;
  id: string;
  name: string;
}

export interface StoredSearchRequest {
  id: string;
  name: string;
  query: Partial<SearchQuery>;
}

export type SubjectId = FundId | PortfolioId | PrivateFundId | PrivatePortfolioId;

export interface SubjectRangeAnalysis {
  earliestStart: number;
  end: number;
  extrapolateEndDate: number;
  extrapolateStartDate: number;
  finestFrequency: FrequencyEnum;
  frequency: FrequencyEnum;
  historicalEnd?: number;
  historicalError?: HistoricalSubjectRangeAnalysisErrorEnum;
  historicalStart?: number;
  interpolationCategory: ProxyCategoryEnum;
  investmentId: string;
  latestEnd: number;
  name: string;
  numReturns: number;
  portfolioId: number;
  proxyCategory: ProxyCategoryEnum;
  proxyEndDate: number;
  proxyId: string;
  proxyName: string;
  proxyStartDate: number;
  rangeAnalyses: SubjectRangeAnalysis[];
  start: number;
}

export interface Subscription {
  accepted: boolean;
  email: string;
  endDate: number;
  id: string;
  publisherAccountId: number;
  publisherAccountName: string;
  startDate: number;
  status: PublishingSubscriptionStatusEnum;
  subscriberAccountId: number;
  subscriberAccountName: string;
  validEnd: number;
  validStart: number;
}

export interface SubscriptionQuery {
  accountId: number[];
  accountTier: AccountTierEnum[];
  billing: BillingTypeEnum[];
  billingSponsorAccountId: number[];
  end: number;
  limit: number;
  page: number;
  start: number;
  status: SubscriptionStatusEnum[];
  type: SubscriptionTypeEnum[];
}

export interface SuccessfulSyncResult {}

export interface SyncResultUserSyncAction {
  action: UserSyncActionEnum;
}

export interface SyncResultWorkspaceSyncAction {
  action: WorkspaceSyncActionEnum;
}

export interface SyncResultsUserSyncAction {
  deactivationResults: SyncResultUserSyncAction[];
  requestResults: SyncResultUserSyncAction[];
}

export interface SyncResultsWorkspaceSyncAction {
  deactivationResults: SyncResultWorkspaceSyncAction[];
  requestResults: SyncResultWorkspaceSyncAction[];
}

export interface SystemFile {
  active: boolean;
  created: number;
  fileType: string;
  id: number;
  language: UserLanguageEnum;
  name: string;
  public: boolean;
  tag: string;
}

export interface SystemGroup {
  active: boolean;
  elevatedGroup: boolean;
  elevatesToId: string;
  id: string;
  name: string;
}

export interface TSPrivateAccountRequest {
  approvedById: number;
  countryCode: string;
  created: number;
  email: string;
  firstName: string;
  lastName: string;
  status: TSAccountStatusEnum;
}

export interface TableSubscription {
  accountId: number;
  active: boolean;
  billing: BillingTypeEnum;
  billingSponsorAccountId: number;
  created: number;
  end: number;
  id: string;
  initiatedById: number;
  note: string;
  start: number;
  status: SubscriptionStatusEnum;
  tier: AccountTierEnum;
  type: SubscriptionTypeEnum;
  updated: number;
}

export interface TableUser {
  accountId: number;
  activationCode: string;
  activationExpirationDate: number;
  actualUser: boolean;
  avatarId: string;
  countryCode: string;
  created: number;
  displayName: string;
  email: string;
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  locality: string;
  permissionGroupId: number;
  pwdLastUpdated: number;
  status: UserStatusEnum;
  updated: number;
  userSeatCapType: UserSeatCapTypeEnum;
}

export interface TableWorkspace {
  account: Account;
  accountAdministrator: string;
  accountType: AccountTypeEnum;
  aum: AUMRange;
  avatarId: string;
  countryCode: string;
  created: number;
  currency: CurrencyEnum;
  description: string;
  id: number;
  language: UserLanguageEnum;
  legalName: string;
  locality: string;
  mfaRequired: boolean;
  name: string;
  organizationType: string;
  permissionGroupId: number;
  status: WorkspaceStatusEnum;
  wealthManager?: boolean;
  workspaceName: string;
}

export interface TaggedEntities {
  documentIds?: string[];
  empty?: boolean;
  fundIds?: string[];
  portfolioIds?: number[];
}

export interface TagsQuery {
  entities: TaggedEntities;
  tag: string;
}

export interface Team {
  accountId: number;
  adminTeam: boolean;
  name: string;
  teamId: string;
  users: number[];
}

export interface TemporalUnit {
  dateBased: boolean;
  durationEstimated: boolean;
  timeBased: boolean;
}

export interface TextGrid {
  active: boolean;
  content: string[][];
  id: string;
  name: string;
}

export interface TextGridFile {
  grids: TextGrid[];
  id: number;
  name: string;
}

export interface TimeFrame {
  end: number;
  start: number;
}

export interface TimeSeriesParsedFile {
  complete: boolean;
  corrections: SeriesCell[];
  documentUuid: string;
  documentVersion: number;
  errors: ColumnErrors[];
  file: TextGridFile;
  fileId: number;
  fileSource: string;
  mapping: FileMapping;
  series: SeriesColumn[];
  structure: UserGridSelection;
}

export interface TimeSeriesPersistResult {
  createdCount: number;
  errorCount: number;
  fileName: string;
  messages: Message[];
  newDataCountByFund: { [key: string]: number };
  responseObject: SimpleFund[];
  updatedCount: number;
}

export interface TimestampBound {
  lowerBound: number;
  timestamp: number;
  upperBound: number;
}

export interface TitlePageFontOverrides {
  additionalTextFontSize: number;
  dateFontSize: number;
  subtitleFontSize: number;
  titleFontSize: number;
}

export interface TradeSummary {
  action: TradeActionEnum;
  fullFundChanged: boolean;
  fund: SimpleFund;
  originalValue: number;
  value: number;
}

export interface TreeActionableContext {
  children: TreeActionableContext[];
  value: ActionableContext;
}

export interface TreeContext {
  children: TreeContext[];
  value: Context;
}

export interface TreeResourceEntity {
  children: TreeResourceEntity[];
  value: ResourceEntity;
}

export interface TreeString {
  children: TreeString[];
  value: string;
}

export interface Trial {
  end: number;
  initiatedBy: AppUser;
  start: number;
}

export interface TrialDetails {
  activeTrial: Trial;
  defaultLengthDays: number;
  status: TrialStatusEnum;
}

export interface UnmapContextToTeamRequest {
  contextId: string;
  teamId: string;
}

export interface UpdatePassword {
  oldPassword: string;
  password: string;
}

export interface UpdateTeamRequest {
  adminTeam: boolean;
  teamId: string;
  teamName: string;
}

export interface UpdateUsageRequest {
  accountId: number;
  feature: FeatureEnum;
  usageCount: number;
}

export interface UsageSummary {
  current: { [key: string]: UsageSummaryDetail };
  lastQuarter: { [key: string]: UsageSummaryDetail };
  lastYear: { [key: string]: UsageSummaryDetail };
  userCurrent: { [key: string]: UsageSummaryDetail };
  userLastQuarter: { [key: string]: UsageSummaryDetail };
  userLastYear: { [key: string]: UsageSummaryDetail };
}

export interface UsageSummaryDetail {
  additionsNegative: number;
  additionsPositive: number;
  total: number;
}

export interface UsageThreshold {
  count: number;
  limitPeriod: LimitPeriodEnum;
}

export interface UsageTrendSummary {
  summary: ActiveUsageForDate[];
}

export interface UseFeatureRequest {
  feature: FeatureEnum;
  orgId: number;
  usageCount: number;
  userId: number;
}

export interface UserCookiePolicyStatus {
  consentedCookieTypes: CookiePolicyTypeEnum[];
  setCookiePolicy: boolean;
}

export interface UserDatasets {
  permanentAccessEnds: { [key: string]: number };
  temporaryAccessEnds: { [key: string]: number };
}

export interface UserEntity {
  parentId: string;
  userId: string;
}

export interface UserFeature {
  limitReached: boolean;
  name: string;
  status: AvailabilityStatusEnum;
  usageCount: number;
  usageThreshold: UsageThreshold;
}

export interface UserGridSelection {
  dates: CellIndex;
  ignore: CellIndex[];
  multiDates: CellIndex[];
  names: CellIndex;
  values: CellIndex;
}

export interface UserMFAStatus {
  email: string;
  firstName: string;
  lastName: string;
  mfaEnabled: boolean;
  primaryMFAType: MFATypeEnum;
  userId: number;
  userStatus: UserStatusEnum;
}

export interface UserOneTimePassword {
  code: string;
  codeType: OTPCodeTypeEnum;
  expiration: number;
  issued: number;
  userId: number;
}

export interface UserOrganizationRoleUpdateRequest {
  orgId: number;
  userRoles: { [key: string]: UserOrgRoleEnum };
}

export interface UserProfileSettings {
  actualUser: boolean;
  availableContexts: TreeActionableContext[];
  bio: string;
  country: string;
  customTheme?: CustomTheme;
  displayName: string;
  expertise: string[];
  expertiseTags: any[];
  firstName: string;
  intercomHash: string;
  language: string;
  lastName: string;
  locale: string;
  mfaEnabled: boolean;
  mfaStatus: MFAStatus;
  notificationSettings: { [key: string]: boolean };
  organization: UserWorkspace;
  phoneNumber: string;
  position: string;
  publicProfile: boolean;
  role: any;
  sponsor?: Sponsor;
  state: string;
  status: UserStatusEnum;
  timezone: any;
  user: AppUser;
}

export interface UserQuery {
  countries: string[];
  createdEnd: number;
  createdStart: number;
  emails: string[];
  limit: number;
  name: string;
  page: number;
  statuses: UserStatusEnum[];
}

export interface UserSessionHistory {
  averageSessionLength: Duration;
  firstSession: number;
  history: Session[];
  lastSession: number;
  totalSessions: number;
}

export interface UserSessionQuery {
  endDate: number;
  includeInternal: boolean;
  orgIds: number[];
  startDate: number;
  userIds: number[];
}

export interface UserSettings {
  system: { [key: string]: any };
  user: { [key: string]: any };
}

export interface UserWorkspace {
  accountAdministrator: string;
  accountId: number;
  accountName: string;
  accountTier: AccountTierEnum;
  accountType: AccountTypeEnum;
  aum: AUMRange;
  avatarId: string;
  country: UserCountryEnum;
  created: number;
  currency: CurrencyEnum;
  description: string;
  id: number;
  issuesPublicEquity: boolean;
  jurisdiction: Country;
  language: UserLanguageEnum;
  locality: string;
  member: boolean;
  members: number;
  mfaRequired: boolean;
  name: string;
  open: boolean;
  organizationType: string;
  status: WorkspaceStatusEnum;
  trial: TrialDetails;
  type: WorkspaceTypeEnum;
  workspaceId: number;
  workspaceName: string;
}

export interface VenncastReturn {
  cumulativeReturn: number[][];
  estimateBounds: TimestampBound[];
  estimateError: number[][];
  estimateLowerBound: number[][];
  estimateUpperBound: number[][];
  estimated: boolean;
  estimatedCumulativeReturns: number[][];
  interpolated: boolean;
  lastReturnDate: number;
  residualRisk: number;
  status: CumulativeVenncastStatusEnum;
}

export interface VerificationCode {
  code: string;
}

export interface WhatsNewContent {
  link: string;
  publishedAt: number;
  sponsored: boolean;
  text: string;
  title: string;
  url: string;
}

export interface WorkspaceCreationRequest {
  aumRangeId: string;
  companyType: string;
  locality: string;
  workspaceName: string;
}

export interface WorkspaceInvitation {
  activationCode: string;
  created: number;
  createdUserId: number;
  email: string;
  expirationDate: number;
  invitedById: number;
  organizationId: number;
}

export interface WorkspaceMember {
  avatarId: string;
  created: number;
  email: string;
  invitationExpiration: number;
  invited: number;
  invitedById: number;
  mfaEnabled: boolean;
  name: string;
  roleInOrganization: UserOrgRoleEnum;
  status: UserStatusEnum;
  userId: number;
}

export interface WorkspaceMemberQuery {
  descending: boolean;
  name: string;
  page: number;
  pageSize: number;
  sortField: MemberSortEnum;
}

export interface WorkspaceMemberResults {
  activeUsers: number;
  invitedUsers: number;
  members: WorkspaceMember[];
  total: number;
}

export interface WorkspaceUser {
  organizationId: number;
  userId: number;
}

export interface YieldMetric {
  lastUpdated?: number;
  percentageYield?: number;
  updatedBy: AppUser;
}

/* If you see an Enum ending with an MD5 hash, please add this enum to the list of manual enums */
export type AccessFrequencyEnum = 'WEEKLY' | 'MONTHLY' | 'QUARTERLY' | 'YEARLY' | 'INACTIVE';
export type AccountStatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
export type AccountTierEnum = 'BASIC' | 'PRO' | 'ENTERPRISE';
export type AccountTypeEnum = 'DEMO' | 'TEST' | 'TWO_SIGMA_PERSONAL' | 'INSTITUTION' | 'EDUCATION';
export type ActionEnum =
  | 'VIEW_ANALYSIS_VIEW'
  | 'ACCESS_CONTEXT'
  | 'EDIT_ANALYSIS_VIEW'
  | 'CREATE_ANALYSIS_VIEW'
  | 'ANALYSIS_PAGE'
  | 'OPTIMIZATION'
  | 'STRESS_TESTING'
  | 'COMPARE'
  | 'VIEW_PORTFOLIO'
  | 'CREATE_PORTFOLIO'
  | 'MANAGE_PROXY'
  | 'INVITE_USERS'
  | 'MANAGE_USERS'
  | 'EDIT_FORECASTS'
  | 'MANAGE_ANALYSIS_TEMPLATES'
  | 'MANAGE_INTEGRATIONS'
  | 'CONFIGURE_BENCHMARKS'
  | 'MANAGE_TAGS'
  | 'STUDIO_CREATE_TEMPLATE'
  | 'STUDIO_EDIT_SUBJECTS'
  | 'STUDIO_EDIT_BENCHMARKS'
  | 'STUDIO_EDIT_DATES'
  | 'STUDIO_REORDER_BLOCKS'
  | 'STUDIO_DELETE_BLOCKS'
  | 'STUDIO_EDIT_TEXT'
  | 'STUDIO_EDIT_HEADERS'
  | 'STUDIO_EDIT_FORMAT'
  | 'STUDIO_EDIT_IMAGES'
  | 'STUDIO_EDIT_METRICS'
  | 'ANALYSIS_PAGE_CREATE_TEMPLATE'
  | 'ANALYSIS_PAGE_PRINT'
  | 'UPLOAD_RETURNS'
  | 'EDIT_PORTFOLIO'
  | 'VIEW_DOCUMENT'
  | 'MANAGE_MASTER_PORTFOLIO'
  | 'MANAGE_PRINT_CONFIGURATION'
  | 'PUBLISHING_ADMIN'
  | 'SPONSORSHIP_ADMIN'
  | 'RETURNS_INBOX'
  | 'MANAGE_DOCUMENTS'
  | 'STUDIO_INSERT_BLOCKS'
  | 'STUDIO_EDIT'
  | 'CREATE_STUDIO_VIEW'
  | 'WEALTH_ONBOARDING_FLOW'
  | 'EDIT_HYPERPARAMETERS'
  | 'EDIT_ESTIMATED_YIELD';
export type AddeparOptionTypeEnum = 'GROUPING' | 'FILTER' | 'RETURN';
export type AddeparSyncErrorTypeEnum =
  | 'AUTH'
  | 'INVALID_GROUPING_ATTR'
  | 'VENN_RETURN_COLUMN_MISSING'
  | 'TIMEOUT'
  | 'UNKNOWN';
export type AddeparViewFilterOperatorEnum = 'INCLUDE' | 'EXCLUDE' | 'RANGE' | 'RANK';
export type AddeparViewFilterRankOrderEnum = 'TOP' | 'BOTTOM';
export type AddeparViewFilterTypeEnum = 'DISCRETE' | 'NUMBER';
export type AggregationFieldEnum = 'START_RANGE' | 'END_RANGE' | 'CREATED' | 'FREQUENCY' | 'CURRENCY' | 'ASSET_TYPE';
export type AllocationConstraintConditionEnum = 'MIN' | 'MAX' | 'LOCKED' | 'MAX_TURNOVER';
export type AllocationConstraintValueTypeEnum = 'CURRENCY' | 'PERCENT' | 'CURRENT_VALUE';
export type AnalysisBlockTypeEnum =
  | 'PERFORMANCE_SUMMARY'
  | 'DRAWDOWN'
  | 'CUMULATIVE_RETURN'
  | 'RETURNS_DISTRIBUTION'
  | 'ROLLING_VOLATILITY'
  | 'ROLLING_SHARPE_RATIO'
  | 'FACTOR_CONTRIBUTION_TO_RISK'
  | 'FACTOR_CONTRIBUTION_TO_RISK_TREND'
  | 'FACTOR_CONTRIBUTION_TO_RETURN'
  | 'FACTOR_CONTRIBUTION_TO_RETURN_TREND'
  | 'FACTOR_CONTRIBUTION_TO_EXPOSURE'
  | 'FACTOR_CONTRIBUTION_TO_EXPOSURE_TREND'
  | 'HISTORICAL_DRAWDOWN_PERIODS'
  | 'SCENARIO'
  | 'RETURNS_GRID'
  | 'RISK_STATISTICS'
  | 'CORRELATION'
  | 'PERFORMANCE_ATTRIBUTION'
  | 'CUMULATIVE_RESIDUAL_RETURN'
  | 'PERCENTAGE_DRAWDOWN'
  | 'RESIDUAL_PERFORMANCE_SUMMARY'
  | 'ROLLING_RETURN'
  | 'ROLLING_BETA'
  | 'ROLLING_BENCHMARK_CORRELATION'
  | 'TEXT_BLOCK'
  | 'PAIRWISE_CORRELATION'
  | 'NOTABLE_HISTORICAL_PERIODS'
  | 'HISTORICAL_DRAWDOWN_AND_RALLY_SCANNER'
  | 'PORTFOLIO_BREAKDOWN';
export type AnalysisComparisonTypeEnum = 'PRIMARY' | 'BENCHMARK' | 'COMPARISON' | 'CATEGORY';
export type AnalysisPortfolioComparisonTypeEnum = 'NONE' | 'MASTER' | 'SAVED' | 'OPTIMIZED';
export type AnalysisSubjectTypeEnum = 'PORTFOLIO' | 'INVESTMENT' | 'RETURNS' | 'PRICES';
export type AnalysisTemplateTypeEnum = 'CUSTOM' | 'VENN' | 'BLOCK';
export type AnalysisTypeEnum =
  | 'RETURNS'
  | 'RETURNS_HISTOGRAM'
  | 'CUMULATIVE_RETURN'
  | 'ROLLING_VOLATILITY'
  | 'ROLLING_SHARPE'
  | 'ROLLING_RETURN'
  | 'ROLLING_BETA'
  | 'ROLLING_BENCHMARK_CORRELATION'
  | 'ROLLING_AUTOCORRELATION'
  | 'ROLLING_CORRELATION'
  | 'FACTOR_CONTRIBUTION_TO_RISK'
  | 'FACTOR_CONTRIBUTION_TO_RETURN'
  | 'FACTOR_EXPOSURES'
  | 'FACTOR_CONTRIBUTION_TO_RISK_FORECAST'
  | 'FACTOR_CONTRIBUTION_TO_RETURN_FORECAST'
  | 'FACTOR_EXPOSURES_FORECAST'
  | 'FACTOR_CONTRIBUTION_TO_RISK_TREND'
  | 'FACTOR_CONTRIBUTION_TO_RETURN_TREND'
  | 'FACTOR_EXPOSURES_TREND'
  | 'PROFORMA_DRAWDOWN'
  | 'FUND_RESIDUAL_CORRELATION'
  | 'FACTOR_DRAWDOWN'
  | 'FACTOR_DRAWDOWN_PERIOD'
  | 'NOTABLE_PERIODS'
  | 'FACTOR_ENVIRONMENT'
  | 'PERFORMANCE_SUMMARY_HISTORICAL'
  | 'PERFORMANCE_SUMMARY_FORECAST'
  | 'PERFORMANCE_SUMMARY_HISTORICAL_CONTRIBUTION'
  | 'PERFORMANCE_SUMMARY_FORECAST_CONTRIBUTION'
  | 'VENNCAST'
  | 'SCENARIO'
  | 'CORRELATION'
  | 'FUND_FACTOR_CONTRIBUTION'
  | 'FUND_FACTOR_TREND_CONTRIBUTION'
  | 'PAIRWISE_CORRELATION'
  | 'DOWNSIDE_CORRELATION'
  | 'PAIRWISE_DOWNSIDE_CORRELATION'
  | 'RANGE_DEBUG'
  | 'RANGE_ANALYSIS'
  | 'DETAILED_PROXY_METADATA'
  | 'RETURNS_GRID'
  | 'INVESTMENT_ATTRIBUTION'
  | 'RESIDUAL_RETURNS_ANALYSIS'
  | 'HISTORICAL_RESIDUAL_PORTFOLIO_RETURNS_ANALYSIS'
  | 'PERCENTAGE_DRAWDOWN_ANALYSIS'
  | 'PERFORMANCE_SUMMARY_HISTORICAL_PORTFOLIO_CONTRIBUTION'
  | 'PERFORMANCE_SUMMARY_FORECAST_PORTFOLIO_CONTRIBUTION'
  | 'GROWTH_SIMULATION'
  | 'GROWTH_SIMULATION_PUBLIC_PRIVATE'
  | 'PRIVATE_CASH_FLOW_PACING'
  | 'PRIVATE_PERFORMANCE_SUMMARY_TIME_SERIES'
  | 'PRIVATE_PERFORMANCE_SUMMARY'
  | 'PEER_GROUP_HISTORICAL_PERFORMANCE_ANALYSIS'
  | 'PEER_GROUP_FORECAST_PERFORMANCE_ANALYSIS'
  | 'PEER_GROUP_RESIDUAL_RETURNS_ANALYSIS'
  | 'PRIVATE_ASSET_SUMMARY'
  | 'PORTFOLIO_BREAKDOWN'
  | 'HISTORICAL_PORTFOLIO_ALLOCATIONS_TIMESERIES'
  | 'UNKNOWN';
export type AnalysisViewSortTypeEnum = 'NAME';
export type AnalysisViewTypeEnum =
  | 'ANALYSIS'
  | 'COMPARE'
  | 'CUSTOM'
  | 'ASSEMBLY'
  | 'ASSEMBLY_CHILD'
  | 'TEARSHEET'
  | 'REPORT'
  | 'TEARSHEET_TEMPLATE'
  | 'REPORT_TEMPLATE';
export type AssetClassEnum = 'PUBLIC' | 'PRIVATE';
export enum AssetTypeEnum {
  MANAGER = 'MANAGER',
  STOCK = 'STOCK',
  ETF = 'ETF',
  MUTUAL_FUND = 'MUTUAL_FUND',
  INDEX = 'INDEX',
  BENCHMARK = 'BENCHMARK',
  CURRENCY = 'CURRENCY',
  UPLOADED_INSTRUMENT = 'UPLOADED_INSTRUMENT',
  UCIT = 'UCIT',
  CUSTODIAN_ACCOUNT = 'CUSTODIAN_ACCOUNT',
  EXTERNAL_INTEGRATION_INVESTMENT = 'EXTERNAL_INTEGRATION_INVESTMENT',
  CATEGORY_GROUPING = 'CATEGORY_GROUPING',
  CRYPTO_REFERENCE_RATE = 'CRYPTO_REFERENCE_RATE',
}
export type AuditLogActionEnum =
  | 'API_CALLED'
  | 'PORTFOLIO_CREATED'
  | 'PORTFOLIO_MODIFIED'
  | 'PORTFOLIO_DELETED'
  | 'DEMO_PORTFOLIO_CREATED'
  | 'INVESTMENT_CREATED'
  | 'INVESTMENT_METADATA_MODIFIED'
  | 'INVESTMENT_DATA_UPDATED'
  | 'INVESTMENT_DELETED'
  | 'USER_ACCOUNT_CREATED'
  | 'USER_ACCOUNT_MODIFIED_TO_HAVE_2FA_ON'
  | 'USER_ACCOUNT_MODIFIED_TO_HAVE_2FA_OFF'
  | 'USER_ACCOUNT_MODIFIED_TO_DIFFERENT_2FA_METHOD'
  | 'USER_ACCOUNT_DELETED'
  | 'USER_ADDED_TO_ORGANIZATION'
  | 'USER_REMOVED_FROM_ORGANIZATION'
  | 'USER_OFAC_ENTRY_CREATED'
  | 'USER_OFAC_ENTRY_REMOVED'
  | 'MASTER_PORTFOLIO_CONTENT_MODIFIED'
  | 'MASTER_PORTFOLIO_UPDATED'
  | 'USER_ORGANIZATION_ROLE_UPDATED'
  | 'PRINT_SETTINGS_UPDATED'
  | 'CUSTOM_THEME_CREATED'
  | 'CUSTOM_THEME_UPDATED'
  | 'CUSTOM_THEME_DELETED'
  | 'CASHFLOW_SETTING_CREATED'
  | 'CASHFLOW_SETTING_UPDATED'
  | 'CASHFLOW_SETTING_DELETED'
  | 'CUSTOM_YIELD_UPDATED'
  | 'CUSTOM_YIELD_DELETED'
  | 'SPONSOR_CREATED'
  | 'SPONSOR_UPDATED'
  | 'SPONSOR_DELETED'
  | 'SPONSORED_CONTENT_UPDATED'
  | 'SPONSORED_CONTENT_DELETED'
  | 'ETF_CREATED_OR_UPDATED'
  | 'ETF_DELETED'
  | 'ETF_PATCH_PRICES'
  | 'ETF_PATCH_ADJ_PRICES'
  | 'ETF_PATCH_SPLITS'
  | 'ETF_PATCH_VOLUMES'
  | 'ETF_PATCH_DIVIDENDS'
  | 'MUTUAL_FUND_CREATED_OR_UPDATED'
  | 'MUTUAL_FUND_DELETED'
  | 'MUTUAL_FUND_PATCH_ADJ_RETURNS'
  | 'FUTURE_CREATED_OR_UPDATED'
  | 'FUTURE_DELETED'
  | 'FUTURE_PATCH_PRICE'
  | 'FUTURE_PATCH_ADJ_PRICE'
  | 'FUTURE_PATCH_VOLUME'
  | 'FUTURE_PATCH_DOLLAR_VOLUME'
  | 'FUTURE_PATCH_OPEN_INTEREST'
  | 'STOCK_CREATED_OR_UPDATED'
  | 'STOCK_DELETED'
  | 'STOCK_PATCH_PRICE'
  | 'STOCK_PATCH_ADJ_PRICE'
  | 'STOCK_PATCH_VOLUME'
  | 'STOCK_PATCH_DIVIDEND'
  | 'STOCK_PATCH_SPLITS'
  | 'INDEX_CREATED_OR_UPDATED'
  | 'INDEX_DELETED'
  | 'INDEX_PATCH_PRICE'
  | 'ADD_ASSET_TO_PEER_GROUP'
  | 'REMOVE_ASSET_FROM_PEER_GROUP'
  | 'CREATE_SIX_FUND'
  | 'LOAD_SIX_FUND'
  | 'CLEAR_ALL_SPARSE_RETURNS'
  | 'CLEAR_SPARSE_RETURNS'
  | 'FUTURE_SERIES_CREATED_OR_UPDATED'
  | 'FUTURE_SERIES_DELETED'
  | 'FUTURE_SERIES_PATCH_SERIES_PRICES'
  | 'FUTURE_SERIES_PATCH_DOLLAR_VOLUME'
  | 'HEDGE_FUND_CREATED_OR_UPDATED'
  | 'HEDGE_FUND_DELETED'
  | 'HEDGE_FUND_PATCH_ASSETS'
  | 'HEDGE_FUND_PATCH_RETURNS'
  | 'MANAGEMENT_FIRM_CREATED_OR_UPDATED'
  | 'MANAGEMENT_FIRM_DELETED'
  | 'ACCOUNT_CREATED'
  | 'ACCOUNT_UPDATED'
  | 'ORGANIZATION_CREATED'
  | 'ORGANIZATION_UPDATED'
  | 'ACCOUNT_SUBSCRIPTION_CREATED'
  | 'ACCOUNT_SUBSCRIPTION_UPDATED'
  | 'USER_ACCOUNT_UPDATED'
  | 'ORGANIZATION_SAML_UPDATED'
  | 'SUPPORTED_DOMAIN_CREATED'
  | 'SUPPORTED_DOMAIN_UPDATED'
  | 'COUNTRY_UPDATED'
  | 'COUNTRY_CREATED'
  | 'COUNTRY_DELETED'
  | 'LEGAL_DOCUMENT_CREATED'
  | 'LEGAL_DOCUMENT_UPDATED'
  | 'LEGAL_DOCUMENT_DELETED'
  | 'LEGAL_DOCUMENT_JURISDICTION_ADDED'
  | 'LEGAL_DOCUMENT_JURISDICTION_REMOVED'
  | 'FEATURE_CREATED'
  | 'FEATURE_MODIFIED'
  | 'FEATURE_DEACTIVATED'
  | 'CRITERION_MODIFIED'
  | 'CRITERION_DELETED'
  | 'INTERPOLATION_CATEGORY_FUND_SET'
  | 'INTERPOLATION_CATEGORY_FUND_DELETED'
  | 'ANALYSIS_SUGGESTION_BLOCK_UPDATED'
  | 'ANALYSIS_SUGGESTION_BLOCK_DELETED'
  | 'SCENARIO_DEFAULT_INDEX_SET'
  | 'SCENARIO_DEFAULT_INDEX_DELETED'
  | 'FACTOR_CMA_INDICES_UPDATED'
  | 'USER_ROLE_ADDED_TO_USER'
  | 'USER_ROLE_REMOVED_FROM_USER'
  | 'CUSTODIAN_ACCOUNT_TO_WORKSPACE_SET'
  | 'CUSTODIAN_ACCOUNT_TO_WORKSPACE_DELETED'
  | 'CUSTODIAN_INTEGRATION_STATUS_UPDATED'
  | 'CUSTODIAN_CONFIGURATION_UPDATED'
  | 'FACTOR_SERIES_ACCEPTED'
  | 'FACTOR_SERIES_UNACCEPTED'
  | 'FACTOR_SERIES_RECOMPUTED'
  | 'FACTOR_LENS_RECOMPUTED'
  | 'ORGANIZATION_SWITCHED'
  | 'SYSTEM_GROUP_CREATED'
  | 'SYSTEM_GROUP_UPDATED'
  | 'SYSTEM_GROUP_USER_ADDED'
  | 'SYSTEM_GROUP_USER_REMOVED'
  | 'USER_ELEVATED'
  | 'RELEASE_NOTE_CREATED'
  | 'RELEASE_NOTE_UPDATED'
  | 'RELEASE_NOTE_DELETED'
  | 'INGESTION_CACHE_CLEARED'
  | 'REMOVE_ASSET_DATA_OVERRIDE'
  | 'CREATE_ASSET_DATA_OVERRIDE'
  | 'DATASET_SUBSCRIPTION_CREATED'
  | 'DATASET_SUBSCRIPTION_DELETED'
  | 'USER_LOGIN'
  | 'USER_LOGOUT'
  | 'ACCOUNTS_SEARCH'
  | 'USERS_SEARCH'
  | 'WORKSPACES_SEARCH'
  | 'UPLOAD_SEARCH'
  | 'PORTFOLIO_SEARCH'
  | 'TEMPLATE_SEARCH'
  | 'VENN_TEMPLATE_CREATED'
  | 'VENN_TEMPLATE_DELETE'
  | 'VENN_TEMPLATE_UPDATE'
  | 'STOCK_SEARCH'
  | 'ETF_SEARCH'
  | 'INDEX_SEARCH'
  | 'MUTUAL_FUND_SEARCH'
  | 'ACCOUNT_DETAIL'
  | 'WORKSPACE_DETAIL'
  | 'USER_DETAIL'
  | 'DATASET_SEARCH'
  | 'DATASET_DETAIL'
  | 'SUBSCRIPTION_SEARCH'
  | 'WM_TEAM_CREATED'
  | 'WM_TEAM_UPDATED'
  | 'WM_TEAM_DELETED'
  | 'WM_CONTEXT_CREATED'
  | 'WM_CONTEXT_UPDATED'
  | 'WM_CONTEXT_DELETED'
  | 'WM_ROLE_CREATED'
  | 'WM_ROLE_UPDATED'
  | 'WM_ROLE_DELETED'
  | 'WM_ACTION_CREATED'
  | 'WM_ACTION_UPDATED'
  | 'WM_ACTION_DELETED';
export type AuditLogActionGroupEnum =
  | 'PORTFOLIO'
  | 'INVESTMENT'
  | 'USER_ACCOUNT'
  | 'MASTER_PORTFOLIO'
  | 'ORGANIZATION'
  | 'FEATURE'
  | 'ACCOUNT'
  | 'ACCOUNT_SUBSCRIPTION'
  | 'SIGNUP_DOMAIN'
  | 'COUNTRY'
  | 'LEGAL_DOCUMENT'
  | 'INTERPOLATION_CATEGORY'
  | 'ANALYSIS_SUGGESTION_BLOCK'
  | 'FACTOR_CMA_INDEX'
  | 'SCENARIO_INDEX'
  | 'USER_ROLE'
  | 'CUSTODIAN_INTEGRATION'
  | 'FACTOR_SERIES'
  | 'FACTOR_LENS'
  | 'USER_ORGANIZATION_ROLE'
  | 'SYSTEM_GROUP'
  | 'RELEASE_NOTES'
  | 'SPONSOR'
  | 'CUSTOMIZED_SETTING'
  | 'CACHE'
  | 'VENN_INVESTMENT'
  | 'USER'
  | 'TEMPLATE'
  | 'DATASET'
  | 'SUBSCRIPTION'
  | 'WM_TEAM'
  | 'WM_CONTEXT'
  | 'WM_USER'
  | 'WM_ROLE'
  | 'WM_ACTION'
  | 'PRINT_SETTINGS'
  | 'THEME'
  | 'CASHFLOW_SETTING'
  | 'CUSTOM_YIELD'
  | 'PUBLIC_API';
export type AuditLogTargetTypeEnum =
  | 'PORTFOLIO'
  | 'INVESTMENT'
  | 'USER_ACCOUNT'
  | 'ORGANIZATION'
  | 'FEATURE'
  | 'CRITERION'
  | 'ACCOUNT'
  | 'ACCOUNT_SUBSCRIPTION'
  | 'SUBSCRIPTION'
  | 'SIGNUP_DOMAIN'
  | 'COUNTRY'
  | 'LEGAL_DOCUMENT'
  | 'INTERPOLATION_CATEGORY'
  | 'ANALYSIS_SUGGESTION_BLOCK'
  | 'FACTOR_CMA_INDEX'
  | 'SCENARIO_INDEX'
  | 'USER_ROLE'
  | 'CUSTODIAN_INTEGRATION'
  | 'FACTOR_SERIES'
  | 'FACTOR_LENS'
  | 'SYSTEM_GROUP'
  | 'RELEASE_NOTES'
  | 'SPONSOR'
  | 'CACHE'
  | 'ASSET_DATA_OVERRIDE'
  | 'ETF'
  | 'MUTUAL_FUND'
  | 'FUTURE'
  | 'FUTURE_SERIES'
  | 'HEDGE_FUND'
  | 'MANAGEMENT_FIRM'
  | 'INDEX'
  | 'PEER_GROUP'
  | 'SIX'
  | 'SPARSE_RETURNS'
  | 'STOCK'
  | 'OFAC_ENTRY'
  | 'USER'
  | 'TEMPLATE'
  | 'DATASET'
  | 'WM_TEAM'
  | 'WM_CONTEXT'
  | 'WM_USER'
  | 'WM_ROLE'
  | 'WM_ACTION'
  | 'PRINT_SETTINGS'
  | 'CUSTOM_THEME'
  | 'PUBLIC_API'
  | 'CASHFLOW_SETTING'
  | 'CUSTOM_METADATA';
export type AuditSourceTypeEnum = 'BACKEND' | 'VENN_APPLICATION' | 'ADMIN_PORTAL' | 'EXCEL_ADD_ON' | 'ALPHA_SITE';
export type AvailabilityStatusEnum = 'UNAVAILABLE' | 'AVAILABLE';
export type BenchmarkTypeEnum = 'PORTFOLIO' | 'INVESTMENT' | 'COMPOSITE';
export type BillingTypeEnum = 'SPONSOR' | 'VENN' | 'CUSTOMER' | 'NT_CHANNEL';
export type CRUDTypeEnum = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';
export type CashFactorEnum = 'AUD' | 'CAD' | 'CHF' | 'EUR' | 'GBP' | 'JPY' | 'NOK' | 'NZD' | 'SEK' | 'USD';
export type CashFlowOverrideTypeEnum = 'CLASSIFICATION' | 'PARAMETER' | 'UNKNOWN';
export type CategoryAssetTypeEnum = 'ETF' | 'HEDGE_FUND' | 'MUTUAL_FUND' | 'OTHER';
export type CategoryEnum =
  | 'OTHER'
  | 'ALLOCATION_15_TO_30_EQUITY'
  | 'ALLOCATION_30_TO_50_EQUITY'
  | 'ALLOCATION_50_TO_70_EQUITY'
  | 'ALLOCATION_70_TO_85_EQUITY'
  | 'ALLOCATION_85_EQUITY'
  | 'BANK_LOAN'
  | 'BEAR_MARKET'
  | 'CHINA_REGION'
  | 'COMMODITIES_BROAD_BASKET'
  | 'COMMODITIES_PRECIOUS_METALS'
  | 'COMMUNICATIONS'
  | 'CONSUMER_CYCLICAL'
  | 'CONSUMER_DEFENSIVE'
  | 'CONVERTIBLES'
  | 'CORPORATE_BOND'
  | 'DIVERSIFIED_EMERGING_MKTS'
  | 'DIVERSIFIED_PACIFIC_ASIA'
  | 'EMERGING_MARKETS_BOND'
  | 'EMERGING_MARKETS_LOCAL_CURRENCY_BOND'
  | 'ENERGY_LIMITED_PARTNERSHIP'
  | 'EQUITY_ENERGY'
  | 'EQUITY_PRECIOUS_METALS'
  | 'EUROPE_STOCK'
  | 'FINANCIAL'
  | 'FOREIGN_LARGE_BLEND'
  | 'FOREIGN_LARGE_GROWTH'
  | 'FOREIGN_LARGE_VALUE'
  | 'FOREIGN_SMALL_MID_BLEND'
  | 'FOREIGN_SMALL_MID_GROWTH'
  | 'FOREIGN_SMALL_MID_VALUE'
  | 'GLOBAL_REAL_ESTATE'
  | 'HEALTH'
  | 'HIGH_YIELD_BOND'
  | 'HIGH_YIELD_MUNI'
  | 'INDIA_EQUITY'
  | 'INDUSTRIALS'
  | 'INFLATION_PROTECTED_BOND'
  | 'INFRASTRUCTURE'
  | 'INTERMEDIATE_GOVERNMENT'
  | 'INTERMEDIATE_TERM_BOND'
  | 'JAPAN_STOCK'
  | 'LARGE_BLEND'
  | 'LARGE_GROWTH'
  | 'LARGE_VALUE'
  | 'LATIN_AMERICA_STOCK'
  | 'LONG_GOVERNMENT'
  | 'LONG_SHORT_CREDIT'
  | 'LONG_SHORT_EQUITY'
  | 'LONG_TERM_BOND'
  | 'MANAGED_FUTURES'
  | 'MARKET_NEUTRAL'
  | 'MID_CAP_BLEND'
  | 'MID_CAP_GROWTH'
  | 'MID_CAP_VALUE'
  | 'MISCELLANEOUS_REGION'
  | 'MULTIALTERNATIVE'
  | 'MULTICURRENCY'
  | 'MULTISECTOR_BOND'
  | 'MUNI_CALIFORNIA_INTERMEDIATE'
  | 'MUNI_CALIFORNIA_LONG'
  | 'MUNI_MASSACHUSETTS'
  | 'MUNI_MINNESOTA'
  | 'MUNI_NATIONAL_INTERM'
  | 'MUNI_NATIONAL_LONG'
  | 'MUNI_NATIONAL_SHORT'
  | 'MUNI_NEW_JERSEY'
  | 'MUNI_NEW_YORK_INTERMEDIATE'
  | 'MUNI_NEW_YORK_LONG'
  | 'MUNI_OHIO'
  | 'MUNI_PENNSYLVANIA'
  | 'MUNI_SINGLE_STATE_INTERM'
  | 'MUNI_SINGLE_STATE_LONG'
  | 'MUNI_SINGLE_STATE_SHORT'
  | 'NATURAL_RESOURCES'
  | 'NONTRADITIONAL_BOND'
  | 'OPTIONS_BASED'
  | 'PACIFIC_ASIA_EX_JAPAN_STK'
  | 'PREFERRED_STOCK'
  | 'REAL_ESTATE'
  | 'SHORT_GOVERNMENT'
  | 'SHORT_TERM_BOND'
  | 'SMALL_BLEND'
  | 'SMALL_GROWTH'
  | 'SMALL_VALUE'
  | 'TACTICAL_ALLOCATION'
  | 'TARGET_DATE_2000_2010'
  | 'TARGET_DATE_2015'
  | 'TARGET_DATE_2020'
  | 'TARGET_DATE_2025'
  | 'TARGET_DATE_2030'
  | 'TARGET_DATE_2035'
  | 'TARGET_DATE_2040'
  | 'TARGET_DATE_2045'
  | 'TARGET_DATE_2050'
  | 'TARGET_DATE_2055'
  | 'TARGET_DATE_2060'
  | 'TARGET_DATE_RETIREMENT'
  | 'TECHNOLOGY'
  | 'TRADING_INVERSE_COMMODITIES'
  | 'TRADING_INVERSE_DEBT'
  | 'TRADING_INVERSE_EQUITY'
  | 'TRADING_LEVERAGED_DEBT'
  | 'TRADING_LEVERAGED_EQUITY'
  | 'TRADING_MISCELLANEOUS'
  | 'ULTRASHORT_BOND'
  | 'UTILITIES'
  | 'VOLATILITY'
  | 'WORLD_ALLOCATION'
  | 'WORLD_BOND'
  | 'WORLD_LARGE_STOCK'
  | 'WORLD_SMALL_MID_STOCK'
  | 'NOT_SUPPORTED_CATEGORY';
export type CategoryGroupEnum =
  | 'MF_BLEND_MUNI'
  | 'MF_CAP_PROTECTED'
  | 'MF_COMMODITY'
  | 'ETF_COMMODITY'
  | 'MF_COMMUNICATION'
  | 'ETF_COMMUNICATION'
  | 'MF_CONS_DISC'
  | 'ETF_CONS_DISC'
  | 'MF_CONS_STAPLE'
  | 'ETF_CONS_STAPLE'
  | 'HF_CONV_ARB'
  | 'MF_CONVERTIBLES'
  | 'ETF_CONVERTIBLES'
  | 'MF_CURRENCY'
  | 'ETF_CURRENCY'
  | 'HF_SHORT_BIAS'
  | 'MF_DIVERSIFIED_ARB'
  | 'MF_DIVERSIFIED_EM'
  | 'ETF_DIVERSIFIED_EM'
  | 'MF_DIVERSIFIED_AM'
  | 'ETF_DIVERSIFIED_AM'
  | 'HF_EMERGING_MARKETS'
  | 'MF_ENERGE'
  | 'ETF_ENERGE'
  | 'HF_EQ_MARKET_NEUTRAL'
  | 'MF_EUR_HY'
  | 'MF_EUR_IG'
  | 'MF_EUR_STOCK'
  | 'ETF_EUR_STOCK'
  | 'MF_EVENT_DRIVEN'
  | 'HF_EVENT_DRIVEN'
  | 'MF_FINANCIALS'
  | 'ETF_FINANCIALS'
  | 'HF_FI_ARB'
  | 'MF_FOR_LARGE_BLEND'
  | 'ETF_FOR_LARGE_BLEND'
  | 'MF_FOR_LARGE_GROWTH'
  | 'ETF_FOR_LARGE_GROWTH'
  | 'MF_FOR_LARGE_VALUE'
  | 'ETF_FOR_LARGE_VALUE'
  | 'MF_FOR_MID_BLEND'
  | 'ETF_FOR_MID_BLEND'
  | 'MF_FOR_MID_GROWTH'
  | 'ETF_FOR_MID_GROWTH'
  | 'MF_FOR_MID_VALUE'
  | 'ETF_FOR_MID_VALUE'
  | 'MF_FOF'
  | 'HF_FOF'
  | 'MF_GLO_AGGRESSIVE_ALLOC'
  | 'MF_GLO_BOND_HY'
  | 'MF_GLO_BOND_IG'
  | 'ETF_GLO_BOND_IG'
  | 'MF_GLO_CAUTIOUS_ALLOC'
  | 'MF_GLO_FLEX_ALLOC'
  | 'ETF_GLO_FLEX_ALLOC'
  | 'MF_GLO_MACRO'
  | 'HF_GLO_MACRO'
  | 'MF_GLO_MOD_ALLOC'
  | 'MF_HEALTHCARE'
  | 'ETF_HEALTHCARE'
  | 'MF_HY_MUNI'
  | 'ETF_HY_MUNI'
  | 'MF_INDUSTRIALS'
  | 'ETF_INDUSTRIALS'
  | 'MF_IT'
  | 'ETF_IT'
  | 'MF_INT_MUNI'
  | 'ETF_INT_MUNI'
  | 'MF_LARGE_BLEND'
  | 'ETF_LARGE_BLEND'
  | 'MF_LARGE_GROWTH'
  | 'ETF_LARGE_GROWTH'
  | 'MF_LARGE_VALUE'
  | 'ETF_LARGE_VALUE'
  | 'MF_LT_MUNI'
  | 'ETF_LT_MUNI'
  | 'MF_LS_DEBT'
  | 'ETF_LS_DEBT'
  | 'MF_LS_EQUITY'
  | 'ETF_LS_EQUITY'
  | 'HF_LS_EQUITY_HEDGE'
  | 'MF_MAN_FUTURES'
  | 'HF_MAN_FUTURES'
  | 'ETF_MAN_FUTURES'
  | 'MF_MARKET_NEUTRAL'
  | 'ETF_MARKET_NEUTRAL'
  | 'MF_MATERIALS'
  | 'ETF_MATERIALS'
  | 'MF_MID_BLEND'
  | 'ETF_MID_BLEND'
  | 'MF_MID_GROWTH'
  | 'ETF_MID_GROWTH'
  | 'MF_MID_VALUE'
  | 'ETF_MID_VALUE'
  | 'MF_MISC_REGION'
  | 'ETF_MISC_REGION'
  | 'MF_MONEY_MARKET'
  | 'HF_MULTI_STRAT'
  | 'MF_'
  | 'ETF_'
  | 'HF_OPTIONS_STRAT'
  | 'MF_OTHER'
  | 'HF_OTHER'
  | 'ETF_OTHER'
  | 'MF_OTHER_BONDS'
  | 'ETF_OTHER_BONDS'
  | 'MF_PROPERTY'
  | 'MF_REAL_ESTATE'
  | 'ETF_REAL_ESTATE'
  | 'MF_PRIVATE_EQUITY'
  | 'MF_SHORT_MUNI'
  | 'ETF_SHORT_MUNI'
  | 'MF_SHORT_BLEND'
  | 'ETF_SHORT_BLEND'
  | 'MF_SHORT_GROWTH'
  | 'ETF_SHORT_GROWTH'
  | 'MF_SHORT_VALUE'
  | 'ETF_SHORT_VALUE'
  | 'MF_TARGET_DATE_ALLOC'
  | 'MF_TRADING_TOOLS'
  | 'ETF_TRADING_TOOLS'
  | 'MF_US_FLEX_CAP_EQUITY'
  | 'MF_US_AGRESSIVE_ALLOC'
  | 'ETF_US_AGRESSIVE_ALLOC'
  | 'MF_US_BANK_LOAN'
  | 'ETF_US_BANK_LOAN'
  | 'MF_US_CAUTIOUS_ALLOC'
  | 'ETF_US_CAUTIOUS_ALLOC'
  | 'MF_US_FLEX_ALLOC'
  | 'MF_US_HY'
  | 'ETF_US_HY'
  | 'MF_US_IG'
  | 'ETF_US_IG'
  | 'MF_US_MOD_ALLOC'
  | 'ETF_US_MOD_ALLOC'
  | 'MF_UTILITIES'
  | 'ETF_UTILITIES'
  | 'MF_VOLATILITY'
  | 'ETF_VOLATILITY'
  | 'MF_WORLD_STOCK';
export type ColumnSeriesErrorEnum = 'PERCENT_DECIMAL_MISMATCH';
export enum ComputableMetricEnum {
  CUMULATIVE_RETURN = 'CUMULATIVE_RETURN',
  ANNUALIZED_RETURN = 'ANNUALIZED_RETURN',
  PERIOD_RETURN = 'PERIOD_RETURN',
  ANNUALIZED_VOLATILITY = 'ANNUALIZED_VOLATILITY',
  SHARPE_RATIO = 'SHARPE_RATIO',
  MAX_DRAWDOWN = 'MAX_DRAWDOWN',
  CALENDAR_YEAR_2024_RETURN = 'CALENDAR_YEAR_2024_RETURN',
  CALENDAR_YEAR_2023_RETURN = 'CALENDAR_YEAR_2023_RETURN',
  CALENDAR_YEAR_2022_RETURN = 'CALENDAR_YEAR_2022_RETURN',
  CALENDAR_YEAR_2021_RETURN = 'CALENDAR_YEAR_2021_RETURN',
  CALENDAR_YEAR_2020_RETURN = 'CALENDAR_YEAR_2020_RETURN',
  CALENDAR_YEAR_2019_RETURN = 'CALENDAR_YEAR_2019_RETURN',
  CALENDAR_YEAR_2018_RETURN = 'CALENDAR_YEAR_2018_RETURN',
  CALENDAR_YEAR_2017_RETURN = 'CALENDAR_YEAR_2017_RETURN',
  CALENDAR_YEAR_2016_RETURN = 'CALENDAR_YEAR_2016_RETURN',
  CALENDAR_YEAR_2015_RETURN = 'CALENDAR_YEAR_2015_RETURN',
  SORTINO = 'SORTINO',
  CALMAR = 'CALMAR',
  AVERAGE_UP_MONTH = 'AVERAGE_UP_MONTH',
  AVERAGE_DOWN_MONTH = 'AVERAGE_DOWN_MONTH',
  BATTING_AVERAGE = 'BATTING_AVERAGE',
  CONDITIONAL_VALUE_AT_RISK_C_95 = 'CONDITIONAL_VALUE_AT_RISK_C_95',
  VALUE_AT_RISK_C_95 = 'VALUE_AT_RISK_C_95',
  VALUE_AT_RISK_C_97_5 = 'VALUE_AT_RISK_C_97_5',
  VALUE_AT_RISK_C_99 = 'VALUE_AT_RISK_C_99',
  SKEWNESS = 'SKEWNESS',
  KURTOSIS = 'KURTOSIS',
  BETA_TO_BENCHMARK = 'BETA_TO_BENCHMARK',
  BENCHMARK_CORRELATION = 'BENCHMARK_CORRELATION',
  DOWNSIDE_CORRELATION = 'DOWNSIDE_CORRELATION',
  UPSIDE_CAPTURE_RATIO = 'UPSIDE_CAPTURE_RATIO',
  DOWNSIDE_CAPTURE_RATIO = 'DOWNSIDE_CAPTURE_RATIO',
  CUMULATIVE_EXCESS_RETURN = 'CUMULATIVE_EXCESS_RETURN',
  ANNUALIZED_EXCESS_RETURN = 'ANNUALIZED_EXCESS_RETURN',
  PERIOD_EXCESS_RETURN = 'PERIOD_EXCESS_RETURN',
  INFORMATION_RATIO = 'INFORMATION_RATIO',
  TRACKING_ERROR = 'TRACKING_ERROR',
  MAX_UNDERPERFORMANCE = 'MAX_UNDERPERFORMANCE',
  CALENDAR_YEAR_2024_EXCESS_RETURN = 'CALENDAR_YEAR_2024_EXCESS_RETURN',
  CALENDAR_YEAR_2023_EXCESS_RETURN = 'CALENDAR_YEAR_2023_EXCESS_RETURN',
  CALENDAR_YEAR_2022_EXCESS_RETURN = 'CALENDAR_YEAR_2022_EXCESS_RETURN',
  CALENDAR_YEAR_2021_EXCESS_RETURN = 'CALENDAR_YEAR_2021_EXCESS_RETURN',
  CALENDAR_YEAR_2020_EXCESS_RETURN = 'CALENDAR_YEAR_2020_EXCESS_RETURN',
  CALENDAR_YEAR_2019_EXCESS_RETURN = 'CALENDAR_YEAR_2019_EXCESS_RETURN',
  CALENDAR_YEAR_2018_EXCESS_RETURN = 'CALENDAR_YEAR_2018_EXCESS_RETURN',
  CALENDAR_YEAR_2017_EXCESS_RETURN = 'CALENDAR_YEAR_2017_EXCESS_RETURN',
  CALENDAR_YEAR_2016_EXCESS_RETURN = 'CALENDAR_YEAR_2016_EXCESS_RETURN',
  CALENDAR_YEAR_2015_EXCESS_RETURN = 'CALENDAR_YEAR_2015_EXCESS_RETURN',
  RELATIVE_SORTINO = 'RELATIVE_SORTINO',
  RELATIVE_CALMAR = 'RELATIVE_CALMAR',
  RELATIVE_AVERAGE_UP_MONTH = 'RELATIVE_AVERAGE_UP_MONTH',
  RELATIVE_AVERAGE_DOWN_MONTH = 'RELATIVE_AVERAGE_DOWN_MONTH',
  RELATIVE_BATTING_AVERAGE = 'RELATIVE_BATTING_AVERAGE',
  RELATIVE_CONDITIONAL_VALUE_AT_RISK_C_95 = 'RELATIVE_CONDITIONAL_VALUE_AT_RISK_C_95',
  RELATIVE_VALUE_AT_RISK_C_95 = 'RELATIVE_VALUE_AT_RISK_C_95',
  RELATIVE_VALUE_AT_RISK_C_97_5 = 'RELATIVE_VALUE_AT_RISK_C_97_5',
  RELATIVE_VALUE_AT_RISK_C_99 = 'RELATIVE_VALUE_AT_RISK_C_99',
  RELATIVE_SKEWNESS = 'RELATIVE_SKEWNESS',
  RELATIVE_KURTOSIS = 'RELATIVE_KURTOSIS',
  FACTOR_EXPOSURE_EQUITY = 'FACTOR_EXPOSURE_EQUITY',
  FACTOR_EXPOSURE_INTEREST_RATES = 'FACTOR_EXPOSURE_INTEREST_RATES',
  FACTOR_EXPOSURE_CREDIT = 'FACTOR_EXPOSURE_CREDIT',
  FACTOR_EXPOSURE_COMMODITIES = 'FACTOR_EXPOSURE_COMMODITIES',
  FACTOR_EXPOSURE_FX = 'FACTOR_EXPOSURE_FX',
  FACTOR_EXPOSURE_LOCAL_INFLATION = 'FACTOR_EXPOSURE_LOCAL_INFLATION',
  FACTOR_EXPOSURE_EMERGING_MARKETS = 'FACTOR_EXPOSURE_EMERGING_MARKETS',
  FACTOR_EXPOSURE_LOCAL_EQUITY = 'FACTOR_EXPOSURE_LOCAL_EQUITY',
  FACTOR_EXPOSURE_EQUITY_SHORT_VOLATILITY = 'FACTOR_EXPOSURE_EQUITY_SHORT_VOLATILITY',
  FACTOR_EXPOSURE_FX_CARRY = 'FACTOR_EXPOSURE_FX_CARRY',
  FACTOR_EXPOSURE_FI_CARRY = 'FACTOR_EXPOSURE_FI_CARRY',
  FACTOR_EXPOSURE_TREND_FOLLOWING = 'FACTOR_EXPOSURE_TREND_FOLLOWING',
  FACTOR_EXPOSURE_LOW_RISK = 'FACTOR_EXPOSURE_LOW_RISK',
  FACTOR_EXPOSURE_MOMENTUM = 'FACTOR_EXPOSURE_MOMENTUM',
  FACTOR_EXPOSURE_QUALITY = 'FACTOR_EXPOSURE_QUALITY',
  FACTOR_EXPOSURE_VALUE = 'FACTOR_EXPOSURE_VALUE',
  FACTOR_EXPOSURE_SMALL_CAP = 'FACTOR_EXPOSURE_SMALL_CAP',
  FACTOR_EXPOSURE_RESIDUAL_CROWDING = 'FACTOR_EXPOSURE_RESIDUAL_CROWDING',
  FACTOR_CONTRIBUTION_TO_RISK_EQUITY = 'FACTOR_CONTRIBUTION_TO_RISK_EQUITY',
  FACTOR_CONTRIBUTION_TO_RISK_INTEREST_RATES = 'FACTOR_CONTRIBUTION_TO_RISK_INTEREST_RATES',
  FACTOR_CONTRIBUTION_TO_RISK_CREDIT = 'FACTOR_CONTRIBUTION_TO_RISK_CREDIT',
  FACTOR_CONTRIBUTION_TO_RISK_COMMODITIES = 'FACTOR_CONTRIBUTION_TO_RISK_COMMODITIES',
  FACTOR_CONTRIBUTION_TO_RISK_FX = 'FACTOR_CONTRIBUTION_TO_RISK_FX',
  FACTOR_CONTRIBUTION_TO_RISK_LOCAL_INFLATION = 'FACTOR_CONTRIBUTION_TO_RISK_LOCAL_INFLATION',
  FACTOR_CONTRIBUTION_TO_RISK_EMERGING_MARKETS = 'FACTOR_CONTRIBUTION_TO_RISK_EMERGING_MARKETS',
  FACTOR_CONTRIBUTION_TO_RISK_LOCAL_EQUITY = 'FACTOR_CONTRIBUTION_TO_RISK_LOCAL_EQUITY',
  FACTOR_CONTRIBUTION_TO_RISK_EQUITY_SHORT_VOLATILITY = 'FACTOR_CONTRIBUTION_TO_RISK_EQUITY_SHORT_VOLATILITY',
  FACTOR_CONTRIBUTION_TO_RISK_FX_CARRY = 'FACTOR_CONTRIBUTION_TO_RISK_FX_CARRY',
  FACTOR_CONTRIBUTION_TO_RISK_FI_CARRY = 'FACTOR_CONTRIBUTION_TO_RISK_FI_CARRY',
  FACTOR_CONTRIBUTION_TO_RISK_TREND_FOLLOWING = 'FACTOR_CONTRIBUTION_TO_RISK_TREND_FOLLOWING',
  FACTOR_CONTRIBUTION_TO_RISK_LOW_RISK = 'FACTOR_CONTRIBUTION_TO_RISK_LOW_RISK',
  FACTOR_CONTRIBUTION_TO_RISK_MOMENTUM = 'FACTOR_CONTRIBUTION_TO_RISK_MOMENTUM',
  FACTOR_CONTRIBUTION_TO_RISK_QUALITY = 'FACTOR_CONTRIBUTION_TO_RISK_QUALITY',
  FACTOR_CONTRIBUTION_TO_RISK_VALUE = 'FACTOR_CONTRIBUTION_TO_RISK_VALUE',
  FACTOR_CONTRIBUTION_TO_RISK_SMALL_CAP = 'FACTOR_CONTRIBUTION_TO_RISK_SMALL_CAP',
  FACTOR_CONTRIBUTION_TO_RISK_RESIDUAL_CROWDING = 'FACTOR_CONTRIBUTION_TO_RISK_RESIDUAL_CROWDING',
  FACTOR_CONTRIBUTION_TO_RETURN_EQUITY = 'FACTOR_CONTRIBUTION_TO_RETURN_EQUITY',
  FACTOR_CONTRIBUTION_TO_RETURN_INTEREST_RATES = 'FACTOR_CONTRIBUTION_TO_RETURN_INTEREST_RATES',
  FACTOR_CONTRIBUTION_TO_RETURN_CREDIT = 'FACTOR_CONTRIBUTION_TO_RETURN_CREDIT',
  FACTOR_CONTRIBUTION_TO_RETURN_COMMODITIES = 'FACTOR_CONTRIBUTION_TO_RETURN_COMMODITIES',
  FACTOR_CONTRIBUTION_TO_RETURN_FX = 'FACTOR_CONTRIBUTION_TO_RETURN_FX',
  FACTOR_CONTRIBUTION_TO_RETURN_LOCAL_INFLATION = 'FACTOR_CONTRIBUTION_TO_RETURN_LOCAL_INFLATION',
  FACTOR_CONTRIBUTION_TO_RETURN_EMERGING_MARKETS = 'FACTOR_CONTRIBUTION_TO_RETURN_EMERGING_MARKETS',
  FACTOR_CONTRIBUTION_TO_RETURN_LOCAL_EQUITY = 'FACTOR_CONTRIBUTION_TO_RETURN_LOCAL_EQUITY',
  FACTOR_CONTRIBUTION_TO_RETURN_EQUITY_SHORT_VOLATILITY = 'FACTOR_CONTRIBUTION_TO_RETURN_EQUITY_SHORT_VOLATILITY',
  FACTOR_CONTRIBUTION_TO_RETURN_FX_CARRY = 'FACTOR_CONTRIBUTION_TO_RETURN_FX_CARRY',
  FACTOR_CONTRIBUTION_TO_RETURN_FI_CARRY = 'FACTOR_CONTRIBUTION_TO_RETURN_FI_CARRY',
  FACTOR_CONTRIBUTION_TO_RETURN_TREND_FOLLOWING = 'FACTOR_CONTRIBUTION_TO_RETURN_TREND_FOLLOWING',
  FACTOR_CONTRIBUTION_TO_RETURN_LOW_RISK = 'FACTOR_CONTRIBUTION_TO_RETURN_LOW_RISK',
  FACTOR_CONTRIBUTION_TO_RETURN_MOMENTUM = 'FACTOR_CONTRIBUTION_TO_RETURN_MOMENTUM',
  FACTOR_CONTRIBUTION_TO_RETURN_QUALITY = 'FACTOR_CONTRIBUTION_TO_RETURN_QUALITY',
  FACTOR_CONTRIBUTION_TO_RETURN_VALUE = 'FACTOR_CONTRIBUTION_TO_RETURN_VALUE',
  FACTOR_CONTRIBUTION_TO_RETURN_SMALL_CAP = 'FACTOR_CONTRIBUTION_TO_RETURN_SMALL_CAP',
  FACTOR_CONTRIBUTION_TO_RETURN_RESIDUAL_CROWDING = 'FACTOR_CONTRIBUTION_TO_RETURN_RESIDUAL_CROWDING',
  FACTOR_CONTRIBUTION_TO_RISK_RESIDUAL = 'FACTOR_CONTRIBUTION_TO_RISK_RESIDUAL',
  FACTOR_CONTRIBUTION_TO_RETURN_RESIDUAL = 'FACTOR_CONTRIBUTION_TO_RETURN_RESIDUAL',
  UNKNOWN = 'UNKNOWN',
}
export type ConfigVersionEnum = 'V1' | 'V2';
export type ConstraintTypeEnum = 'ALLOCATION' | 'FACTOR';
export type CookiePolicyTypeEnum = 'ADVERTISING' | 'FUNCTIONAL';
export type CreatedByOptionEnum = 'SELF' | 'ANY_USER';
export type CriterionOperatorEnum = 'IS_NOT_IN' | 'IS_IN';
export type CriterionPropertyNameEnum =
  | 'COUNTRY'
  | 'ORG_ID'
  | 'ORG_TIER'
  | 'TRIAL_STATUS'
  | 'USER_ID'
  | 'USER_ORG_ROLE'
  | 'SYSTEM_GROUP'
  | 'ACCOUNT_ID'
  | 'ACCOUNT_TYPE'
  | 'SPONSOR_ACCOUNT_ID'
  | 'IS_SPONSOR';
export type CumulativeVenncastStatusEnum =
  | 'PRIMARY_SUBJECT_ERROR'
  | 'INSUFFICIENT_RETURNS'
  | 'UP_TO_DATE'
  | 'INVALID_END_DATE'
  | 'SUCCESSFUL'
  | 'RELATIVE'
  | 'SECONDARY_SUBJECT'
  | 'QUARTERLY';
export enum CurrencyEnum {
  AUD = 'AUD',
  CAD = 'CAD',
  CHF = 'CHF',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  JPY = 'JPY',
  NOK = 'NOK',
  NZD = 'NZD',
  SEK = 'SEK',
  USD = 'USD',
}
export type CustomBenchmarkTypeEnum = 'COMMON' | 'INDIVIDUAL' | 'NONE';
export type DataSetAccessTypeEnum = 'PERMANENT' | 'TEMPORARY';
export type DataSetItemTypeEnum = 'FUND';
export type DateRangeResolutionEnum = 'GLOBAL' | 'INPUT' | 'BLOCK';
export type DocumentSortTypeEnum = 'UPDATED_TIME' | 'NAME' | 'SAVED_BY';
export type DomainStatusEnum = 'SUPPORTED' | 'RESTRICTED';
export type EmailSubscriptionProviderEnum = 'SENDGRID' | 'HUBSPOT';
export type EmulateMediaTypeEnum = 'SCREEN' | 'PRINT';
export type EntityFilterEnum = 'PORTFOLIO' | 'TAG' | 'FUND';
export type ExportTypeEnum = 'XLSX' | 'PNG' | 'ALL';
export type ExportsErrorTypeEnum =
  | 'FAILED_EXPORT_EXCEL'
  | 'FAILED_SENT_EMAILS'
  | 'FAILED_BACKUP'
  | 'NOT_FOUND_VIEW'
  | 'INACTIVE_USER'
  | 'NO_ACCESS'
  | 'UNKNOWN';
export type ExportsStatusEnum = 'EMAIL_SENT' | 'NOT_SENT' | 'ERROR';
export type FactorAlertLevelEnum = 'MODERATE' | 'LARGE';
export type FactorPerformanceTypeEnum = 'FACTOR' | 'FACTOR_INPUT' | 'FUND' | 'PORTFOLIO';
export type FeatureEnum =
  | 'UNKNOWN'
  | 'ORG_USERS'
  | 'SPONSORED_ACCOUNTS'
  | 'FULL_ACCESS_SEAT_CAP'
  | 'ADVISOR_SEAT_CAP'
  | 'API_USAGE'
  | 'ANALYTICS_API'
  | 'FACTOR_API'
  | 'FACTOR_API_FORECASTS'
  | 'INTEGRATION_API'
  | 'OPTIMIZATION'
  | 'WORKSPACE_ADMIN_ACTIONS'
  | 'SPONSOR'
  | 'BYPASS_MFA_REQUIREMENT'
  | 'LULA'
  | 'CANADA_RESTRICTION'
  | 'PRIVATE_ANALYTICS'
  | 'CONTEXT_SWITCHING'
  | 'ADDEPAR_SELF_SERVICE'
  | 'E2E_BATCH_CLEANUP'
  | 'E2E_AUTO_SIGNUP'
  | 'USE_ENV_FUTURES'
  | 'LIMITED_OPT_ALT_PORTFOLIOS_FF'
  | 'NEW_PERMISSIONS_FF'
  | 'VIEW_RAW_RETURNS_FF'
  | 'MFA_UPDATES_FF'
  | 'PEER_GROUP_ANALYTICS_FF'
  | 'CUSTOM_PEER_GROUPS_FF'
  | 'BATCH_RETURNS_QUERYING_REST_FF'
  | 'PGA_LIMIT_CONSTITUENTS_TO_30_FF'
  | 'PEER_GROUPS_SEARCH_V2_FF'
  | 'PRIVATES_STRENGTHEN_FF'
  | 'DOCUMENT_JURISDICTION_FF'
  | 'AXIOMA_MARKET_CAP_FF'
  | 'REBRAND_ENABLED_FF'
  | 'DUMMY_COMPUTE_FF'
  | 'MACRO_SCENARIO_FF'
  | 'TS_SAMPLING_FF'
  | 'FORCE_SUBSCRIBER_AGREEMENT_FF'
  | 'HISTORICAL_PORTFOLIOS_FF'
  | 'PAUSE_INGESTION_UPDATES_FF'
  | 'DISABLE_HUBSPOT_BLOGPOST_FF'
  | 'ADMIN_ACCOUNT_MANAGE'
  | 'ADMIN_ACCOUNT_META_MANAGE'
  | 'ADMIN_SUBSCRIPTION_MANAGE'
  | 'ADMIN_WORKSPACE_SWITCH'
  | 'ADMIN_SIGNUP_DOMAIN_MANAGE'
  | 'ADMIN_LEGAL_DOCUMENTS_MANAGE'
  | 'ADMIN_ROLES_MANAGE'
  | 'ADMIN_FUND_RESTRICTION_MANAGE'
  | 'ADMIN_CUSTODIAN_MANAGE'
  | 'ADMIN_COUNTRY_MANAGE'
  | 'ADMIN_SETTINGS_MANAGE'
  | 'ADMIN_SENDGRID_MANAGE'
  | 'ADMIN_SCENARIOS_MANAGE'
  | 'ADMIN_FUNDS_MANAGE'
  | 'ADMIN_FEATURE_AVAILABILITY_MANAGE'
  | 'ADMIN_FACTORS_MANAGE'
  | 'ADMIN_FACTOR_ALERTS_MANAGE'
  | 'ADMIN_FORECASTS_MANAGE'
  | 'ADMIN_INTERPOLATION_MANAGE'
  | 'ADMIN_SUGGESTION_BLOCKS_MANAGE'
  | 'ADMIN_DATA_WRITER'
  | 'ADMIN_DATA_READER'
  | 'ADMIN_SYSTEM_GROUP_MANAGE'
  | 'ADMIN_VIEW_CLIENT_DATA'
  | 'ADMIN_UPDATE_CLIENT_DATA'
  | 'ADMIN_PUBLISHER_MANAGE'
  | 'ADMIN_OFAC_MANAGE'
  | 'ADMIN_STUDIO_TEMPLATE_MANAGER'
  | 'ADMIN_CLEAN_WORKSPACE'
  | 'COLOR_SCHEME_PICKER'
  | 'YIELD_INCOME_FF'
  | 'TRIM_INCOMPLETE_PERIODS_IN_CALENDAR_RETURNS_FF'
  | 'HOLDINGS_METADATA_API_V2_FF'
  | 'PORTFOLIO_BULK_PROXY_FF';
export type FieldTypeEnum = 'NUMBER' | 'PERCENTAGE';
export type FileTypeEnum = 'PDF' | 'XLSX' | 'DOCX' | 'ZIP' | 'TXT' | 'PRINT' | 'PNG';
export type ForecastContextEnum = 'HISTORICAL_FORECASTS';
export type FrequencyEnum = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'QUARTERLY' | 'YEARLY' | 'UNKNOWN';
export type FundSourceTypeEnum =
  | 'FUND'
  | 'BENCHMARK'
  | 'INDEX'
  | 'STRATEGY_RETURN'
  | 'COMPOSITE_BENCHMARK'
  | 'CUSTODIAN_ACCOUNT'
  | 'EXTERNAL_INTEGRATION'
  | 'EXTERNAL_VENN'
  | 'EXTERNAL_BYOL';
export type GdprRegionEnum = 'EEA' | 'NON_EEA';
export type HistoricalSubjectRangeAnalysisErrorEnum =
  | 'EARLIEST_ALLOCATION_DATE_PREDATES_SUBJECT_START_DATE'
  | 'ALLOCATION_DATE_OUTSIDE_SUBJECT_RANGE'
  | 'INVESTMENT_RETURNS_NOT_AVAILABLE'
  | 'LATEST_ALLOCATION_DATE_EXCEEDS_SUBJECT_RETURNS_END_DATE'
  | 'INVESTMENT_ALLOCATIONS_NOT_AVAILABLE';
export type HoldingsBreakdownTypeEnum =
  | 'ASSET'
  | 'REGION'
  | 'SECTOR'
  | 'PRIVATE_STRATEGY'
  | 'PRIVATE_REGION'
  | 'PRIVATE_INDUSTRY';
export type InfoGraphicTypeEnum =
  | 'GRID'
  | 'PIVOTED_GRID'
  | 'LINE'
  | 'BAR'
  | 'DIVERGENT_BAR'
  | 'DISTRIBUTE_BAR'
  | 'FACTOR_TREND'
  | 'DRAWDOWN'
  | 'CALENDAR_GRID'
  | 'CORRELATION'
  | 'GROWTH_SIMULATION'
  | 'BOX_CHART'
  | 'DEFAULT'
  | 'SCATTER'
  | 'HEATMAP'
  | 'BOX'
  | 'IMAGE'
  | 'PIE'
  | 'GRID_TREE'
  | 'MAP'
  | 'PRIVATE_CASH_FLOW'
  | 'PEER_GROUP'
  | 'AREA';
export type IntegrationProviderTypeEnum =
  | 'CUSTODIAN'
  | 'FUND_ADMINISTRATOR'
  | 'INVESTMENT_CONSULTANTS'
  | 'SOFTWARE'
  | 'CUSTOM';
export type IntegrationStatusEnum = 'ACTIVE' | 'PENDING_DELETION' | 'DELETED' | 'REQUESTED' | 'REQUEST_CANCELLED';
export type IntegrationTypeEnum = 'ADDEPAR' | 'NORTHERN_TRUST' | 'BNY';
export type InvestmentRegionEnum = 'US' | 'PAN_AMERICA' | 'EMEA' | 'ASIA_PACIFIC' | 'JAPAN' | 'NOT_SPECIFIED';
export type InvestmentSourceTypeEnum =
  | 'VENN'
  | 'UPLOAD'
  | 'CUSTODIAN'
  | 'EXTERNAL_INTEGRATION'
  | 'EXTERNAL_BYOL'
  | 'CUSTOM';
export type InvestmentSubtypeEnum = 'PORTFOLIO_PRO_FORMA' | 'PORTFOLIO_HISTORICAL';
export type InvestmentTypeEnum = 'FUND' | 'PORTFOLIO' | 'PRIVATE_FUND' | 'PRIVATE_PORTFOLIO';
export type InviteValidationStatusEnum =
  | 'OUTSIDE_DOMAIN'
  | 'INVALID'
  | 'EXISTING_USER'
  | 'VALID'
  | 'VALID_FOR_WORKSPACE';
export type ItemTypeEnum = 'venn_fund' | 'venn_scenario' | 'FUND' | 'PORTFOLIO';
export type LegalDocumentKeyEnum =
  | 'ADV'
  | 'ADV_2A'
  | 'ADV_2B'
  | 'LIMITED_USE_LICENSE_AGREEMENT'
  | 'OLD_USER_AGREEMENT'
  | 'USER_AGREEMENT'
  | 'SUBSCRIBER_AGREEMENT'
  | 'SUBSCRIBER_DEFINITIONS'
  | 'PRIVACY_POLICY'
  | 'COOKIE_POLICY'
  | 'THIRD_PARTY_NOTICES'
  | 'WILSHIRE_AGREEMENT'
  | 'CRS'
  | 'SERVICE_TIERS'
  | 'REBRAND_ADV'
  | 'REBRAND_ADV_2A'
  | 'REBRAND_ADV_2B'
  | 'REBRAND_LIMITED_USE_LICENSE_AGREEMENT'
  | 'REBRAND_USER_AGREEMENT'
  | 'REBRAND_SUBSCRIBER_AGREEMENT'
  | 'REBRAND_SUBSCRIBER_DEFINITIONS'
  | 'REBRAND_PRIVACY_POLICY'
  | 'REBRAND_COOKIE_POLICY'
  | 'REBRAND_THIRD_PARTY_NOTICES'
  | 'REBRAND_CRS'
  | 'REBRAND_SERVICE_TIERS';
export type LibraryItemTypeEnum =
  | 'all'
  | 'portfolio'
  | 'portfolioIntegration'
  | 'proForma'
  | 'compositeBenchmark'
  | 'investment'
  | 'upload'
  | 'investmentIntegration'
  | 'masterPortfolioHolding'
  | 'vennProvided'
  | 'category'
  | 'none'
  | 'tags';
export type LibraryQuickFilterEnum =
  | 'recentlyAnalyzed'
  | 'uploadedByAnyone'
  | 'uploadedByMe'
  | 'integrations'
  | 'requiresAttention';
export type LimitPeriodEnum = 'LIFETIME' | 'YTD' | 'MTD';
export type MFATypeEnum = 'DISABLED' | 'SMS' | 'TOTP' | 'BACKUP_CODE' | 'MANUAL' | 'EMAIL';
export type MatchTypeEnum = 'FULL' | 'PREFIX' | 'SUFFIX';
export type MemberSortEnum = 'STATUS' | 'EMAIL' | 'NAME';
export type MessageTypeEnum = 'INFO' | 'WARN' | 'ERROR';
export type MigrationStatusTypeEnum =
  | 'METADATA_VALIDATION'
  | 'METADATA_MIGRATED'
  | 'TIMESERIES_VALIDATION'
  | 'TIMESERIES_MIGRATED';
export type NodeTypeEnum = 'PORTFOLIO' | 'FUND';
export type OTPCodeTypeEnum = 'BACKUP_CODE' | 'TEXT_CODE';
export type OfacEntityTypeEnum = 'USER' | 'ORGANIZATION';
export type OptimizationTargetEnum = 'MAXIMIZE_RETURN' | 'MAXIMIZE_SHARPE' | 'MINIMIZE_VOLATILITY';
export type OrderEnum = 'asc' | 'desc';
export type PeerGroupSourceEnum = 'MORNINGSTAR' | 'SAVED_SEARCH' | 'ALL';
export type PerformanceFilterFieldEnum = 'ANNUALIZED_VOLATILITY' | 'ANNUALIZED_SHARPE' | 'ANNUALIZED_RETURN';
export type PermissionGroupTypeEnum = 'ORGANIZATION' | 'USER' | 'GLOBAL_PERMISSION_GROUP' | 'SUPER_USER';
export type PortfolioPersistStatusEnum = 'CREATED' | 'UPDATED' | 'SKIPPED' | 'FAILED';
export type PortfolioRebalancingStrategyEnum =
  | 'CONTINUOUS'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'SEMIANNUAL'
  | 'ANNUAL'
  | 'CUSTOM_REBALANCE'
  | 'NO_REBALANCE';
export type PortfolioSummaryTypeEnum =
  | 'OPTIMIZATION'
  | 'OPTIMIZATION_EFFICIENT_FRONTIER_PORTFOLIO'
  | 'PORTFOLIO'
  | 'PORTFOLIO_EFFICIENT_FRONTIER_PORTFOLIO';
export type PortfolioTypeEnum = 'firm' | 'group' | 'entity';
export type PortfolioWriteModeEnum = 'OVERWRITE';
export type PrintColumnTypeEnum = 'FIXED' | 'DYNAMIC';
export type PrintOrientationTypeEnum = 'LANDSCAPE' | 'PORTRAIT';
export type PrintRowTypeEnum = 'FIXED' | 'DYNAMIC' | 'ROLLOVER';
export type PrivateAssetSearchModeEnum = 'PRIVATES_ONLY' | 'PUBLIC_ONLY' | 'ALL';
export type PrivateFundAssetClassEnum =
  | 'PRIVATE_EQUITY'
  | 'FUND_OF_FUNDS'
  | 'REAL_ASSETS'
  | 'REAL_ESTATE'
  | 'PRIVATE_DEBT'
  | 'EU_PRIVATE_DEBT'
  | 'VENTURE_CAPITAL'
  | 'MULTI'
  | 'NATURAL_RESOURCES'
  | 'INFRASTRUCTURE'
  | 'HEDGE_FUNDS';
export type ProxyCategoryEnum =
  | 'VENTURE_CAPITAL'
  | 'REAL_ESTATE'
  | 'BUYOUT'
  | 'NATURAL_RESOURCES'
  | 'INFRASTRUCTURE'
  | 'PRIVATE_CREDIT';
export type ProxyErrorEnum =
  | 'INVALID_FREQUENCY'
  | 'BELOW_THRESHOLD'
  | 'NO_OVERLAP'
  | 'INVESTMENT_SERIES_TOO_SHORT'
  | 'PROXY_SERIES_TOO_SHORT'
  | 'NON_RETURNS_SERIES'
  | 'UNABLE_TO_DESMOOTH_NOT_ENOUGH_OVERLAP'
  | 'UNABLE_TO_DESMOOTH_INVALID_N_LAGS'
  | 'UNABLE_TO_EXTRAPOLATE_SERIES_TOO_SHORT'
  | 'UNABLE_TO_EXTRAPOLATE_NOT_ENOUGH_OVERLAP'
  | 'UNABLE_TO_EXTRAPOLATE_PROXY_NOT_EXTENDING'
  | 'UNABLE_TO_EXTRAPOLATE'
  | 'UNABLE_TO_PROXY'
  | 'UNABLE_TO_INTERPOLATE_NOT_ENOUGH_OVERLAP';
export type ProxyTypeEnum =
  | 'BACKFILL'
  | 'INTERPOLATE'
  | 'SUBSTITUTE'
  | 'DESMOOTH'
  | 'DESMOOTH_INTERPOLATE'
  | 'EXTRAPOLATE';
export type ProxyWarningEnum = 'BELOW_THRESHOLD';
export type PublishingSubscriptionStatusEnum =
  | 'INACTIVE'
  | 'ACCEPTED'
  | 'IGNORED'
  | 'SUBSCRIBER_TERMINATED'
  | 'PUBLISHER_TERMINATED';
export type RegressionPeriodEnum = 'YEAR_3' | 'YEAR_5' | 'YEAR_7' | 'YEAR_10' | 'FULL';
export type ReleaseNoteStatusEnum = 'PENDING' | 'PUBLISHED' | 'PENDING_EMAIL' | 'EMAILED';
export type RemoteTypeEnum = 'LOCAL' | 'DATA' | 'CUSTODIAN' | 'EXTERNAL' | 'API';
export type RequiredRecurrenceTypeEnum = 'USER_RECURRING' | 'USER_ONCE' | 'ORGANIZATION_ONCE';
export type ReturnCellTypeEnum = 'PROXY' | 'ORIGINAL' | 'ANALYSIS' | 'TOTAL';
export type SavedSearchSortByEnum = 'created' | 'name';
export type ScenarioSeriesTypeEnum = 'RETURN' | 'PRICE' | 'YOY_DELTA' | 'YOY_PERCENTAGE_CHANGE';
export type ScenarioTypeEnum = 'MACRO' | 'MARKET';
export type ScopeEnum = 'INVESTMENT' | 'PORTFOLIO';
export type ScopeTypeEnum = 'ALL' | 'FUND' | 'INDEX' | 'USER_UPLOADED' | 'STRATEGY_RETURN' | 'PORTFOLIO';
export type SeverityEnum = 'ERROR' | 'WARNING';
export type ShareClassEnum =
  | 'A'
  | 'ADV'
  | 'B'
  | 'C'
  | 'CLEAN'
  | 'COMM_BASED'
  | 'DIY'
  | 'FEE_BASED'
  | 'I'
  | 'INST_CAD'
  | 'INV'
  | 'M'
  | 'N'
  | 'NO_LOAD'
  | 'R'
  | 'S'
  | 'T';
export type ShockUnitsEnum = 'PERCENTAGE' | 'ABS_PERCENTAGE' | 'BPS' | 'PRICE' | 'POINTS';
export type SourceTypeEnum = 'API' | 'APP' | 'ADMIN';
export type SponsoredSortEnum = 'STATUS' | 'NAME';
export type StatusEnum = 'SUCCESS' | 'FAILURE';
export type StreamErrorEnum = 'DUPLICATE_DATE' | 'MISSING_VALUE' | 'VALUE_ANOMALY' | 'MISSING_DATE' | 'BAD_VALUE';
export type StudioDocumentTypeEnum = 'TEARSHEET' | 'REPORT';
export type SubscriptionStatusEnum = 'TERMINATED' | 'ACTIVE';
export type SubscriptionTypeEnum = 'TRIAL' | 'SUBSCRIBER';
export type TSAccountStatusEnum = 'REQUESTED' | 'REJECTED' | 'CREATED';
export type TableLayoutTypeEnum = 'STANDARD' | 'PIVOT';
export type TimePeriodEnum =
  | 'YTD'
  | 'YEAR_1'
  | 'YEAR_3'
  | 'YEAR_5'
  | 'YEAR_7'
  | 'YEAR_10'
  | 'YEAR_15'
  | 'YEAR_20'
  | 'FULL'
  | 'FULL_NO_FACTOR_CONSTRAINT';
export type TokenTypeEnum =
  | 'INTERNAL_SYSTEM_CREDENTIAL'
  | 'LOGIN_CREDENTIAL'
  | 'REGULAR_RESET_PASSWORD'
  | 'WELCOME_RESET_PASSWORD'
  | 'WELCOME_SIGNIN'
  | 'DEFAULT';
export type TradeActionEnum = 'BUY' | 'SELL' | 'HOLD';
export type TrialStatusEnum = 'AVAILABLE' | 'ACTIVE' | 'COMPLETED' | 'NOT_AVAILABLE';
export type TypeEnum = 'DRAWDOWN' | 'RALLY' | 'PREDEFINED' | 'CUSTOM';
export type UpdateTypeEnum = 'TIMESERIES' | 'METADATA';
export type UploadTypeEnum = 'FILE' | 'COPY_PASTE' | 'SAMPLE_FILE' | 'EXPORT_EXCEL';
export type UsageTrendIntervalLengthEnum = 'DAILY' | 'MONTHLY';
export type UserCountryEnum =
  | 'AF'
  | 'AX'
  | 'AL'
  | 'DZ'
  | 'AS'
  | 'AD'
  | 'AO'
  | 'AI'
  | 'AQ'
  | 'AG'
  | 'AR'
  | 'AM'
  | 'AW'
  | 'AU'
  | 'AT'
  | 'AZ'
  | 'BS'
  | 'BH'
  | 'BD'
  | 'BB'
  | 'BY'
  | 'BE'
  | 'BZ'
  | 'BJ'
  | 'BM'
  | 'BT'
  | 'BO'
  | 'BA'
  | 'BW'
  | 'BV'
  | 'BR'
  | 'VG'
  | 'IO'
  | 'BN'
  | 'BG'
  | 'BF'
  | 'BI'
  | 'KH'
  | 'CM'
  | 'CA'
  | 'CV'
  | 'KY'
  | 'CF'
  | 'TD'
  | 'CL'
  | 'CN'
  | 'HK'
  | 'MO'
  | 'CX'
  | 'CC'
  | 'CO'
  | 'KM'
  | 'CG'
  | 'CD'
  | 'CK'
  | 'CR'
  | 'CI'
  | 'HR'
  | 'CU'
  | 'CY'
  | 'CZ'
  | 'DK'
  | 'DJ'
  | 'DM'
  | 'DO'
  | 'EC'
  | 'EG'
  | 'SV'
  | 'GQ'
  | 'ER'
  | 'EE'
  | 'ET'
  | 'FK'
  | 'FO'
  | 'FJ'
  | 'FI'
  | 'FR'
  | 'GF'
  | 'PF'
  | 'TF'
  | 'GA'
  | 'GM'
  | 'GE'
  | 'DE'
  | 'GH'
  | 'GI'
  | 'GR'
  | 'GL'
  | 'GD'
  | 'GP'
  | 'GU'
  | 'GT'
  | 'GG'
  | 'GN'
  | 'GW'
  | 'GY'
  | 'HT'
  | 'HM'
  | 'VA'
  | 'HN'
  | 'HU'
  | 'IS'
  | 'IN'
  | 'ID'
  | 'IR'
  | 'IQ'
  | 'IE'
  | 'IM'
  | 'IL'
  | 'IT'
  | 'JM'
  | 'JP'
  | 'JE'
  | 'JO'
  | 'KZ'
  | 'KE'
  | 'KI'
  | 'KP'
  | 'KR'
  | 'KW'
  | 'KG'
  | 'LA'
  | 'LV'
  | 'LB'
  | 'LS'
  | 'LR'
  | 'LY'
  | 'LI'
  | 'LT'
  | 'LU'
  | 'MK'
  | 'MG'
  | 'MW'
  | 'MY'
  | 'MV'
  | 'ML'
  | 'MT'
  | 'MH'
  | 'MQ'
  | 'MR'
  | 'MU'
  | 'YT'
  | 'MX'
  | 'FM'
  | 'MD'
  | 'MC'
  | 'MN'
  | 'ME'
  | 'MS'
  | 'MA'
  | 'MZ'
  | 'MM'
  | 'NA'
  | 'NR'
  | 'NP'
  | 'NL'
  | 'AN'
  | 'NC'
  | 'NZ'
  | 'NI'
  | 'NE'
  | 'NG'
  | 'NU'
  | 'NF'
  | 'MP'
  | 'NO'
  | 'OM'
  | 'PK'
  | 'PW'
  | 'PS'
  | 'PA'
  | 'PG'
  | 'PY'
  | 'PE'
  | 'PH'
  | 'PN'
  | 'PL'
  | 'PT'
  | 'PR'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RU'
  | 'RW'
  | 'BL'
  | 'SH'
  | 'KN'
  | 'LC'
  | 'MF'
  | 'PM'
  | 'VC'
  | 'WS'
  | 'SM'
  | 'ST'
  | 'SA'
  | 'SN'
  | 'RS'
  | 'SC'
  | 'SL'
  | 'SG'
  | 'SK'
  | 'SI'
  | 'SB'
  | 'SO'
  | 'ZA'
  | 'GS'
  | 'SS'
  | 'ES'
  | 'LK'
  | 'SD'
  | 'SR'
  | 'SJ'
  | 'SZ'
  | 'SE'
  | 'CH'
  | 'SY'
  | 'TW'
  | 'TJ'
  | 'TZ'
  | 'TH'
  | 'TL'
  | 'TG'
  | 'TK'
  | 'TO'
  | 'TT'
  | 'TN'
  | 'TR'
  | 'TM'
  | 'TC'
  | 'TV'
  | 'UG'
  | 'UA'
  | 'AE'
  | 'UK'
  | 'US'
  | 'UM'
  | 'UY'
  | 'UZ'
  | 'VU'
  | 'VE'
  | 'VN'
  | 'VI'
  | 'WF'
  | 'EH'
  | 'YE'
  | 'ZM'
  | 'ZW';
export type UserLanguageEnum = 'EN' | 'JA';
export type UserOrgRoleEnum = 'ORG_ADMIN' | 'USER';
export type UserRoleEnum =
  | 'SUPERUSER'
  | 'ADMIN'
  | 'USER'
  | 'ORGANIZATION_ADMIN'
  | 'USER_ADMIN'
  | 'DATA_READER'
  | 'SYSTEM_ADMIN';
export type UserSeatCapTypeEnum = 'ADVISOR' | 'FULL_ACCESS';
export type UserStatusEnum = 'ACTIVE' | 'INACTIVE' | 'PENDING_ACTIVATION' | 'MFA_LOCKED' | 'RESTRICTED';
export type UserSyncActionEnum =
  | 'CREATE_NO_WELCOME_EMAIL'
  | 'CREATE_WELCOME_EMAIL'
  | 'UPDATE'
  | 'REMOVE_FROM_WORKSPACE'
  | 'DEACTIVATE'
  | 'UNCHANGED'
  | 'VALIDATE_WORKSPACE'
  | 'VALIDATE_USER'
  | 'VALIDATE_EMAIL';
export type VenncastPeriodEnum = 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR' | 'MTD' | 'QTD' | 'YTD' | 'MAX_VENNCAST';
export type WorkspaceStatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
export type WorkspaceSyncActionEnum = 'CREATE' | 'UPDATE' | 'DEACTIVATE' | 'UNCHANGED' | 'VALIDATE';
export type WorkspaceTypeEnum =
  | 'INTERNAL'
  | 'FUND'
  | 'CONSULTANT'
  | 'MANAGER'
  | 'ENDOWMENT'
  | 'FOUNDATION'
  | 'PUBLIC_PENSION'
  | 'CORPORATE_PENSION'
  | 'SWF';
export type WriteTypeEnum = 'APPEND' | 'UPSERT' | 'OVERWRITE';
