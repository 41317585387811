import { atom, selector, selectorFamily } from 'recoil';
import type { BlockId } from './types';
import { blockSettings } from './configuration/blockSettings';
import { studioBlockCustomWidth } from './grid';
import type { VirtuosoHandle } from 'react-virtuoso';
import { hasFeatureSelector, type CustomizableBlockSetting } from 'venn-utils';
import { resetOnStudioReset } from '../effects/signalEffects';

export const studioLeftPanelOpen = atom<boolean>({
  key: 'studioLeftPanelOpen',
  default: true,
});

const studioLeftBlockConfigPanelExpandedAtom = atom<boolean>({
  key: 'studioLeftBlockConfigPanelExpandedAtom',
  default: true,
});

/**
 * @deprecated always returns true if ap_in_rl_ff is enabled.
 *
 * TODO: delete when ap_in_rl_ff is deleted.
 */
export const studioLeftBlockConfigPanelExpandedState = selector<boolean>({
  key: 'studioLeftBlockConfigPanelExpandedState',
  get: ({ get }) => {
    const hasApInRl = get(hasFeatureSelector('ap_in_rl_ff'));
    return hasApInRl || get(studioLeftBlockConfigPanelExpandedAtom);
  },
  set: ({ set, get }, value) => {
    const hasApInRl = get(hasFeatureSelector('ap_in_rl_ff'));
    if (!hasApInRl) {
      set(studioLeftBlockConfigPanelExpandedAtom, value);
    }
  },
});

export const selectedBlockState = atom<{ refId: BlockId; scrollToBlock: boolean } | undefined>({
  key: 'selectedBlockState',
  default: undefined,
  effects: [resetOnStudioReset],
});

export const selectedBlockIdState = selector<BlockId | undefined>({
  key: 'selectedBlockIdState',
  get: ({ get }) => get(selectedBlockState)?.refId,
});

export const isSelectedBlockState = selectorFamily<boolean, BlockId>({
  key: 'isSelectedBlock',
  get:
    (blockId) =>
    ({ get }) =>
      get(selectedBlockState)?.refId === blockId,
});

export const selectedBlockSettings = selector<CustomizableBlockSetting | undefined>({
  key: 'selectedBlockSettings',
  get: ({ get }) => {
    const selected = get(selectedBlockIdState);
    return selected ? get(blockSettings(selected)) : undefined;
  },
});

export const selectedBlockRelativeWidth = selector<number | undefined>({
  key: 'selectedBlockRelativeWidth',
  get: ({ get }) => {
    const selected = get(selectedBlockIdState);
    return selected ? get(studioBlockCustomWidth(selected)) : undefined;
  },
  set: ({ get, set }, value) => {
    const selected = get(selectedBlockIdState);
    selected && value && set(studioBlockCustomWidth(selected), value);
  },
});

export const studioImageUploading = atom<boolean>({
  key: 'studioImageUploading',
  default: false,
});

export const virtualScrollRef = atom<{ current: VirtuosoHandle | null } | undefined>({
  key: 'virtualScrollRef',
  default: undefined,
});

/** Index of the selected report page. */
export const selectedReportPage = atom<number>({
  key: 'selectedReportPage',
  default: 0,
});
