import React from 'react';
import { SmallSwitch } from '@/components/ui/small-switch';

export const NameHeaderPrimitive = {
  Root: ({ children }: React.PropsWithChildren) => (
    <div className="ag-cell-label-container !overflow-visible" role="presentation">
      <div className="ag-header-cell-label flex [&&]:items-end h-full !overflow-visible">
        <span data-ref="eText" className="ag-header-cell-text flex items-end justify-between w-full">
          <div>Name</div>
          {children}
        </span>
      </div>
    </div>
  ),
  Toggle: ({ toggled, onToggle }: { toggled: boolean; onToggle: () => void }) => (
    <div className="flex flex-row items-end">
      <span className="mr-2">Show liquidated investments:</span>
      <SmallSwitch data-testid="qa-toggle-show-allocations-manage-data" checked={toggled} onCheckedChange={onToggle} />
    </div>
  ),
};
