import React from 'react';
import type { Portfolio } from 'venn-api';
import { useCommonTreeGridProps } from '../../../../../studio-blocks/logic/columnUtils';
import DataGrid from '../../../../../data-grid/DataGrid';
import styled from 'styled-components';
import { ColorUtils, GetColor } from 'venn-ui-kit';
import type { ColDef, GetRowIdFunc, RowClassRules } from 'ag-grid-community';
import type { RowData } from '../../../types';

type RightSideBarContentProps = {
  rowData: RowData<Portfolio>[];
  columnDefs: ColDef<RowData<Portfolio>>[];
  autoGroupColumnDefs: ColDef<RowData<Portfolio>>;
  rowClassRules: RowClassRules<RowData<Portfolio>>;
  getRowId?: GetRowIdFunc<RowData<Portfolio>>;
};

export const RightSideBarContent = ({
  rowData,
  columnDefs,
  autoGroupColumnDefs,
  rowClassRules,
  getRowId,
}: RightSideBarContentProps) => {
  const commonGridProps = useCommonTreeGridProps<RowData<Portfolio>>();

  return (
    <PortfolioAllocatorDataGridWrapper>
      <DataGrid<RowData<Portfolio>>
        {...commonGridProps}
        rowData={rowData}
        columnDefs={columnDefs}
        domLayout="normal"
        autoGroupColumnDef={autoGroupColumnDefs}
        rowClassRules={rowClassRules}
        getRowId={getRowId}
        suppressCellFocus
        suppressRowTransform
        suppressScrollOnNewData
        suppressRowHoverHighlight
      />
    </PortfolioAllocatorDataGridWrapper>
  );
};

const PortfolioAllocatorDataGridWrapper = styled.div`
  // ag-grid does not appear unless the parent has a height set
  // perhaps this is a problem with our DataGrid wrapper
  height: 100%;

  // styling for portfolio review

  .ag-row-odd,
  .ag-row-even {
    background-color: ${GetColor.GreyScale.Grey20};
  }

  .ag-header-container {
    background-color: ${GetColor.White};
  }

  .error-row {
    background-color: ${ColorUtils.opacifyFrom(GetColor.Error, 0.1)};
  }
  .warning-row {
    background-color: ${GetColor.DataLibraryColor.Warning};
  }

  .ag-cell {
    &[col-id='mapped'] {
      display: flex;
      flex-direction: row;
      justify-content: left;
    }
  }

  .ag-cell-wrapper.ag-row-group {
    align-items: center;
  }

  .ag-group-expanded,
  .ag-group-contracted {
    margin-right: 4px;
  }

  .ag-header-cell {
    &[col-id='allocation'] {
      padding-right: 14px;
      text-align: right;
    }
  }
`;
