import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import moment from 'moment';
import { compact, isEmpty } from 'lodash';
import type { DataPoint } from 'venn-utils';
import { Dates } from 'venn-utils';
import { ColorUtils } from 'venn-ui-kit';
import type { Options, SeriesLineOptions } from 'highcharts';
import { VennHighchart } from '../../highchart/Highchart';

interface InterpolationSnapshotChartProps {
  primarySeries?: DataPoint[];
  overlaySeries?: DataPoint[];
  extrapolateSeries?: DataPoint[];
  height?: number;
  invert?: boolean;
  subjectLineColor?: string;
  markerRadius?: number;
}

const MAX_SERIES_POINTS = 100;
const BANDS_OPACITY = 0.2;

const InterpolationSnapshot: React.FC<React.PropsWithChildren<InterpolationSnapshotChartProps>> = ({
  primarySeries,
  overlaySeries,
  extrapolateSeries,
  invert,
  height,
  subjectLineColor,
  markerRadius,
}) => {
  const { Schemes } = useContext(ThemeContext);

  const primaryColor = invert ? Schemes.Proxy.invertedSubjectLine : Schemes.Proxy.subjectLine;
  const overlayColor = subjectLineColor || Schemes.Proxy.proxyLine;
  const extrapolateColor = Schemes.Proxy.extrapolation;

  const hasPrimaryReturn = !isEmpty(primarySeries);
  const hasOverlayReturn = !isEmpty(overlaySeries);
  const hasExtrapolatedReturn = !isEmpty(extrapolateSeries);
  const extrapolationStart = hasExtrapolatedReturn ? extrapolateSeries?.[0].x : undefined;
  const maxDate =
    Math.max(
      (hasPrimaryReturn && primarySeries?.[primarySeries.length - 1].x) || 0,
      (hasOverlayReturn && overlaySeries?.[overlaySeries.length - 1].x) || 0,
      (hasExtrapolatedReturn && extrapolateSeries?.[extrapolateSeries.length - 1].x) || 0,
    ) || moment().valueOf();
  const minStartDate = moment(maxDate).subtract(5, 'years').valueOf();

  const adjustedPrimaryReturns = primarySeries
    ? reformat(reduceSeriesPoints(primarySeries, MAX_SERIES_POINTS).filter((r) => r.x > minStartDate))
    : undefined;
  const adjustedOverlayReturns = overlaySeries
    ? reformat(reduceSeriesPoints(overlaySeries, MAX_SERIES_POINTS).filter((r) => r.x > minStartDate))
    : undefined;
  const adjustedExtrapolatedReturns = extrapolateSeries
    ? reformat(reduceSeriesPoints(extrapolateSeries, MAX_SERIES_POINTS).filter((r) => r.x > minStartDate))
    : undefined;

  const series: SeriesLineOptions[] = compact([
    hasOverlayReturn && {
      name: 'Interpolated Return',
      type: 'line' as const,
      lineWidth: 1,
      color: overlayColor,
      states: {
        inactive: {
          opacity: 1,
        },
        hover: { enabled: false },
      },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      data: adjustedOverlayReturns,
    },
    hasPrimaryReturn && {
      name: 'Original Return Values',
      type: 'line' as const,
      lineWidth: 0,
      color: primaryColor,
      states: {
        inactive: {
          opacity: 1,
        },
        hover: { enabled: false },
      },
      marker: {
        enabled: true,
        lineColor: primaryColor,
        radius: markerRadius ?? (height && height > 26 ? 2 : 1),
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      data: adjustedPrimaryReturns,
    },
    hasExtrapolatedReturn && {
      name: 'Extrapolation',
      type: 'line' as const,
      lineWidth: 1,
      color: extrapolateColor,
      states: {
        inactive: {
          opacity: 1,
        },
        hover: { enabled: false },
      },
      marker: {
        enabled: false,
      },
      data: adjustedExtrapolatedReturns,
    },
  ]);

  // rectangles on the plot highlighting the ranges
  const bands = (
    invert
      ? [
          {
            color: ColorUtils.hex2rgba(overlayColor, BANDS_OPACITY),
            from: minStartDate,
            to: extrapolationStart ?? maxDate,
          },
          {
            color: ColorUtils.hex2rgba(extrapolateColor, BANDS_OPACITY),
            from: extrapolationStart ?? undefined,
            to: maxDate,
          },
        ]
      : []
  ).filter((band) => band.color && band.from && band.to);

  const config: Options = {
    ...defaultConfig,
    xAxis: {
      labels: {
        enabled: true,
        style: {
          color: primaryColor,
        },
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          return Dates.parse(this.value).format("'YY");
        },
      },
      lineWidth: 0,
      tickLength: 0,
      tickPositions: compact([minStartDate, extrapolationStart ?? undefined, maxDate]),
      crosshair: {
        width: 0,
      },
      plotBands: bands,
    },
    series,
  };

  if (height) {
    config.chart = {
      margin: [3, 4, 20, 1],
      height,
      spacing: [0, 0, 0, 0],
      backgroundColor: 'transparent',
    };
  }

  return (
    <Wrapper>
      <VennHighchart options={config} />
    </Wrapper>
  );
};

export default React.memo(InterpolationSnapshot);

const Wrapper = styled.div`
  padding: 0;
`;

const defaultConfig = {
  title: undefined,
  chart: {
    margin: [3, 4, 1, 1],
    height: 25,
    spacing: [0, 0, 0, 0],
    backgroundColor: 'transparent',
  },
  scrollbar: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  rangeSelector: {
    enabled: false,
  },
  navigator: {
    enabled: false,
  },
  exporting: {
    enabled: true,
    fallbackToExportServer: true,
  },
  tooltip: {
    enabled: false,
  },
  yAxis: {
    visible: false,
    maxPadding: 0,
  },
  series: [],
};

const reduceSeriesPoints = (series: DataPoint[], maxPoints: number): DataPoint[] => {
  if (series.length <= maxPoints) {
    return series;
  }
  const pick = Math.floor(series.length / maxPoints);
  return series.filter((_val, index) => !(index % pick));
};

const reformat = (series: DataPoint[]): [number, number][] => {
  return series.map((point) => [point.x, point.y]);
};
