import styled, { css } from 'styled-components';
import { GetColor, Headline2, Shimmer } from 'venn-ui-kit';

export const MANAGE_DATA_HEADER_HORIZONTAL_PADDING_PX = 60;

export const ManageDataTitle = styled(Headline2)<{ refreshedStyling?: boolean }>`
  ${({ refreshedStyling }) =>
    refreshedStyling
      ? css`
          display: inline;
          font-size: 20px;
          font-weight: bold;
          margin-right: 20px;
        `
      : css`
          display: flex;
          font-weight: bold;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        `}
`;

export const PageContentContainer = styled.div<{ refreshedStyling?: boolean }>`
  min-height: 200px;
  margin-top: ${(props) => (props.refreshedStyling ? '24px' : '40px')};
`;

export const SummaryBaseWrapper = styled.div<{ refreshedStyling?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  padding: ${(props) =>
    props.refreshedStyling
      ? `8px ${MANAGE_DATA_HEADER_HORIZONTAL_PADDING_PX}px`
      : `10px ${MANAGE_DATA_HEADER_HORIZONTAL_PADDING_PX}px`};
  max-width: 100vw;
`;

export const SummaryWrapper = styled(SummaryBaseWrapper)<{ refreshedStyling?: boolean }>`
  background-color: ${(props) => (props.refreshedStyling ? GetColor.GreyScale.Grey10 : GetColor.PaleGrey)};
  padding: ${(props) =>
    props.refreshedStyling
      ? `12px ${MANAGE_DATA_HEADER_HORIZONTAL_PADDING_PX}px`
      : `10px ${MANAGE_DATA_HEADER_HORIZONTAL_PADDING_PX}px`};
`;

export const FlexGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const SummaryItem = styled.span`
  &:not(:last-child) {
    padding-right: 40px;
  }
  font-size: 12px;
  label {
    display: block;
  }
`;

export const Summary = styled.div`
  display: flex;
  padding: 4px 0;
  height: 34px;
`;

export const SummaryPlaceholder = styled(Summary)`
  ${Shimmer}
  width: 30%;
`;

export const PlaceholderWrapper = styled.div`
  margin: 10px 60px 0;
`;

export const StyledMetaDataFields = styled.div`
  max-width: 600px;
  font-size: 14px;
  margin: 0 auto;
`;
