import React from 'react';

import { cn } from '@/lib/utils';

import { Icon } from 'venn-ui-kit';
import { Button } from '@/components/ui/button';

interface ClosePanelButtonProps {
  onClose?: () => void;
  className?: string;
}

export const ClosePanelButton = ({ onClose, className }: ClosePanelButtonProps) => {
  return (
    <Button
      onClick={onClose}
      variant="outline"
      size="icon"
      className={cn('border-venn-grey-30 rounded-[6px] h-[18px] w-[20px]', className)}
      data-testid="panel-close-button"
    >
      <Icon type="times" className="text-venn-dark-blue text-[12px]" />
    </Button>
  );
};
