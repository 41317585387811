import type { DetailedProxyMetadata, ProxyTypeEnum } from 'venn-api';
import { assertExhaustive } from '../type';
import { getFrequencyType, MOMENT_DURATIONS } from '../frequency';
import Dates from '../dates';
import { capitalize } from 'lodash';

export const getFormattedProxyType = (proxyType: ProxyTypeEnum): string => {
  switch (proxyType) {
    case 'DESMOOTH_INTERPOLATE':
      return 'Desmoothing with Interpolation';
    default:
      return capitalize(proxyType);
  }
};

export const getProxyTypeDescription = (proxyType: ProxyTypeEnum): string => {
  switch (proxyType) {
    case 'BACKFILL':
      return 'Extend past history';
    case 'INTERPOLATE':
      return 'Increase return frequency to daily';
    case 'SUBSTITUTE':
      return 'Replace with different investment';
    case 'DESMOOTH':
      return 'Reduce private asset performance lag';
    case 'DESMOOTH_INTERPOLATE':
      return 'Reduce private asset performance lag and increase return frequency to daily';
    case 'EXTRAPOLATE':
      return 'Extend available history forward';
    default:
      throw assertExhaustive(proxyType, 'Unexpected proxy type');
  }
};

export const getProxyTypeNoun = (proxyType: ProxyTypeEnum): string => {
  switch (proxyType) {
    case 'BACKFILL':
      return 'Backfill';
    case 'INTERPOLATE':
      return 'Interpolation';
    case 'SUBSTITUTE':
      return 'Substitution';
    case 'DESMOOTH':
      return 'Desmoothing';
    case 'DESMOOTH_INTERPOLATE':
      return 'Desmoothing with Interpolation';
    case 'EXTRAPOLATE':
      return 'Extrapolation';
    default:
      throw assertExhaustive(proxyType, 'Unexpected proxy type');
  }
};

export const getProxyStartDate = (proxy: DetailedProxyMetadata): number => {
  switch (proxy.proxyType) {
    case 'BACKFILL':
    case 'INTERPOLATE':
    case 'SUBSTITUTE':
    case 'DESMOOTH':
    case 'DESMOOTH_INTERPOLATE':
      return proxy.proxiedStartDate;
    case 'EXTRAPOLATE':
      return Dates.parse(proxy.unproxiedEndDate)
        .add(1, MOMENT_DURATIONS[getFrequencyType(proxy.proxiedFrequency)])
        .valueOf();
    default:
      throw assertExhaustive(proxy.proxyType, 'Unexpected proxy type');
  }
};

export const getProxyEndDate = (proxy: DetailedProxyMetadata): number => {
  if (proxy.extrapolate && proxy.proxyType !== 'EXTRAPOLATE' && proxy.proxyType !== 'BACKFILL') {
    // The extrapolation portion of the time series starts at unproxiedEndDate and would be unaffected by other proxies.
    return proxy.unproxiedEndDate;
  }

  switch (proxy.proxyType) {
    case 'BACKFILL':
      if (proxy.proxiedStartDate < proxy.unproxiedStartDate) {
        return Dates.parse(proxy.unproxiedStartDate)
          .subtract(1, MOMENT_DURATIONS[getFrequencyType(proxy.proxiedFrequency)])
          .valueOf();
      }
      return proxy.unproxiedStartDate;
    case 'INTERPOLATE':
    case 'SUBSTITUTE':
    case 'DESMOOTH':
    case 'DESMOOTH_INTERPOLATE':
    case 'EXTRAPOLATE':
      return proxy.proxiedEndDate;
    default:
      throw assertExhaustive(proxy.proxyType, 'Unexpected proxy type');
  }
};
