import React, { useCallback } from 'react';
import { selector, useRecoilValue, useSetRecoilState } from 'recoil';
import { firstPortfolioInView, isAllocatorOpenState, isReportState } from 'venn-state';
import { Icon } from 'venn-ui-kit';
import { withSuspense } from 'venn-utils';
import { Constants } from '../Layout';
import { EmptyStudioAllocatorWindow, StudioAllocatorSubject } from './components';

const hasPortfolioInViewState = selector<boolean>({
  key: 'hasPortfolioInView',
  get: ({ get }) => !!get(firstPortfolioInView),
});

export const StudioEmptyAllocationPanel = withSuspense(
  null,
  React.memo(function EmptyAllocationPanel() {
    const setIsAllocatorOpen = useSetRecoilState(isAllocatorOpenState);

    const onClosePanel = useCallback(() => {
      setIsAllocatorOpen(false);
    }, [setIsAllocatorOpen]);

    const allocatorWidth = 1 + Constants.ALLOCATION_PANEL_MAIN_WIDTH;
    const isReport = useRecoilValue(isReportState);
    const hasPortfolioInView = useRecoilValue(hasPortfolioInViewState);

    return (
      <EmptyStudioAllocatorWindow hasApInRl>
        <StudioAllocatorSubject width={allocatorWidth} onClose={onClosePanel} hideCompareLink />

        <div
          data-testid="studio-empty-allocator-wrapper"
          className="[grid-area:allocator-panel] overflow-x-hidden bg-venn-white  bg-slate-100 "
        >
          <div className="px-2 py-4 flex flex-col items-center gap-4">
            <Icon type="empty-set" prefix="fat" className="text-slate-200 text-8xl" />
            <div className="text-sm">
              {hasPortfolioInView
                ? 'Select a portfolio above to edit allocations.'
                : `Add a portfolio to this ${isReport ? 'report' : 'Studio analysis'} to edit allocations.`}
            </div>
          </div>
        </div>
      </EmptyStudioAllocatorWindow>
    );
  }),
);
