import React, { type MouseEventHandler } from 'react';
import type { ManageDataLinkProps } from 'venn-components';
import { BulkEmptyState, EmptyState, ManageDataLink } from 'venn-components';
import { MANAGE_DATA_SECTION, useHasFF, type AnalysisSubject } from 'venn-utils';
import styled from 'styled-components';
import type { Message } from 'venn-api';
import { SupportedErrorCodes } from 'venn-api';

import { Button } from 'venn-ui-kit';

interface RollingErrorMessageProps extends ManageDataLinkProps {
  subject: AnalysisSubject;
  analysisType: string;
  analysisError?: Message;
}

export const RollingErrorMessage = ({ analysisType, subject, analysisError }: RollingErrorMessageProps) => {
  const hasBulkProxy = useHasFF('portfolio_bulk_proxy_ff');

  if (analysisError?.code === SupportedErrorCodes.AnalysisRelativeMissingBenchmark) {
    return (
      <StyledEmptyState
        header={`${analysisType} requires a benchmark`}
        message={<div>Please select a benchmark.</div>}
      />
    );
  }

  const customRenderer = (onClick: MouseEventHandler) => (
    <div>
      <p>
        Go to{' '}
        <button type="button" onClick={onClick} className="text-venn-dark-blue hover:text-venn-dark-blue/90 font-bold">
          Manage Data
        </button>{' '}
        to apply proxies.
      </p>
      <Button className="print:hidden" onClick={onClick} dominant refreshedStyling>
        Manage Data
      </Button>
    </div>
  );

  return hasBulkProxy ? (
    <BulkEmptyState
      header="Unable to run analysis"
      message={
        <div>
          <p className="mt-0">One or more of the items you are analyzing does not have sufficient returns.</p>
          {/* TODO(houssein): Refactor ManageDataLink to a custom navigation hook */}
          <ManageDataLink
            subject={subject}
            defaultEditData
            customRenderer={customRenderer}
            forceSection={MANAGE_DATA_SECTION.ANALYSIS_RANGE}
            errorState
          />
        </div>
      }
    />
  ) : (
    <StyledEmptyState
      header="There are insufficient returns available to analyze"
      message={
        <>
          <div>
            The {subject?.type} does not have sufficient history to calculate Rolling {analysisType} for the Rolling
            Period selected.
          </div>
          <ManagedDataLinkDiv>
            <ManageDataLink subject={subject} defaultEditData />
          </ManagedDataLinkDiv>
        </>
      }
    />
  );
};

const StyledEmptyState = styled(EmptyState)`
  margin: 20px;
`;

const ManagedDataLinkDiv = styled.div`
  margin-top: 40px;
  text-align: center;
  @media print {
    display: none;
  }
`;
