import React from 'react';
import ErrorInvalidAnalysisPeriod from '../shared-errors/ErrorInvalidAnalysisPeriod';
import { processErrorMessage } from '../PortfolioRangesUtils';
import type { FailedAnalysisError } from '../types';
import { SupportedErrorCodes } from 'venn-api';
import ComparisonErrorNoReturns from './ComparisonErrorNoReturns';
import type { ComparisonSubject } from 'venn-utils';

interface ComparisonErrorProps {
  error: FailedAnalysisError;
  onResetAnalysisPeriod?: () => void;
  /**
   * List of subjects used in the Comparison Page
   */
  comparisonSubjects?: ComparisonSubject[];
  /**
   * Callback to remove items from the comparison page by specifying array index in {@link comparisonSubjects}
   */
  removeComparisonSubject?: (idx: number) => void;
}

const ComparisonError = ({
  error,
  onResetAnalysisPeriod,
  comparisonSubjects,
  removeComparisonSubject,
}: ComparisonErrorProps) => {
  if (error.code === SupportedErrorCodes.InvalidAnalysisPeriod) {
    return (
      <ErrorInvalidAnalysisPeriod
        defaultMessage={processErrorMessage(error.message)}
        onResetAnalysisPeriod={onResetAnalysisPeriod}
      />
    );
  }
  if (error.code === SupportedErrorCodes.NoFundReturns && comparisonSubjects) {
    return (
      <ComparisonErrorNoReturns
        comparisonSubjects={comparisonSubjects}
        removeComparisonSubject={removeComparisonSubject}
      />
    );
  }

  return error.message;
};

export default ComparisonError;
