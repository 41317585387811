import { useContext, useEffect, useMemo, useState } from 'react';
import { MultiPortfolioReviewContext } from '../../common/MultiPortfolioReviewContext';
import { identity, isEmpty, isNil, sortBy, uniq } from 'lodash';
import { AnalysisSubject } from 'venn-utils';
import { useRangeAnalysisQuery } from '../../../../../../hooks/useRangeAnalysisQuery';
import { getAllRanges, hasDuplicatedHistoricalInvestments, serializePath } from '../../../review/helpers';
import type { Portfolio } from 'venn-api';
import type {
  HistoricalPortfolioErrorState,
  MultiHistoricalPortfolioContextValue,
} from '../MultiHistoricalPortfolioContext';

type Result =
  | {
      kind: 'success';
      context: MultiHistoricalPortfolioContextValue;
    }
  | {
      kind: 'loading';
    };
export const useMultiHistoricalReviewRightPane = (): Result => {
  const {
    data: { parsedResults, selectedIndex },
  } = useContext(MultiPortfolioReviewContext);
  const openedPortfolio = parsedResults[selectedIndex].parsedPortfolio;
  const excludedInvestments = parsedResults[selectedIndex].excludedInvestments;
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const allDates = sortBy(
    uniq(getAllTimestamps(openedPortfolio)).map((ts) => new Date(ts)),
    identity,
  );
  const errors = useMemo(
    () => getAllErrors(openedPortfolio, excludedInvestments),
    [excludedInvestments, openedPortfolio],
  );
  const cachedSubject = useMemo(
    () => new AnalysisSubject(cleanPortfolio(openedPortfolio), 'portfolio'),
    [openedPortfolio],
  );

  const { rangeAnalysis } = useRangeAnalysisQuery(cachedSubject);
  const ranges = useMemo(
    () => (rangeAnalysis && rangeAnalysis.rangeAnalyses.length > 0 ? getAllRanges(rangeAnalysis.rangeAnalyses[0]) : {}),
    [rangeAnalysis],
  );
  useEffect(() => {
    if (allDates.length > 0) {
      // select the first date by default
      setSelectedDate(allDates[0]);
    }
    // reset selected date only when switching portfolios
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);
  if (isNil(selectedDate) && allDates.length > 0) {
    return { kind: 'loading' };
  }
  return {
    kind: 'success',
    context: {
      selectedDate: selectedDate!,
      selectDate: (date: Date) => setSelectedDate(date),
      allDates,
      errors,
      rangeAnalysis: ranges,
    },
  };
};

const getAllTimestamps = (portfolio: Portfolio): number[] => {
  if (isEmpty(portfolio.children)) {
    return portfolio.closingAllocationsTs?.map((ts) => ts[0]) ?? [];
  }
  const currentTimestamps = portfolio.closingAllocationsTs?.map((ts) => ts[0]) ?? [];
  const rest = portfolio.children.flatMap(getAllTimestamps);
  return currentTimestamps.concat(rest);
};

const getAllErrors = (portfolio: Portfolio, excludedInvestments: Set<string>): HistoricalPortfolioErrorState => {
  const populateErrors = (
    parent: Portfolio | undefined,
    portfolio: Portfolio,
    path: number[],
    mutableErrors: HistoricalPortfolioErrorState,
  ) => {
    if (isEmpty(portfolio.children)) {
      // if this is a fund
      if (portfolio.fund) {
        // handle unmapped funds
        if (isNil(portfolio.fund.id) && !excludedInvestments.has(serializePath(path).value)) {
          const closingAllocationsTs = portfolio.closingAllocationsTs ?? [];
          for (const [ts, value] of closingAllocationsTs) {
            if (value === 0) {
              continue;
            }
            if (mutableErrors[ts]) {
              mutableErrors[ts].push({ portfolioId: portfolio.id });
            } else {
              mutableErrors[ts] = [{ portfolioId: portfolio.id }];
            }
          }
        }

        // check for duplicate funds
        if (parent && portfolio.fund.id) {
          const closingAllocationsTs = portfolio.closingAllocationsTs ?? [];
          for (const ts of closingAllocationsTs) {
            if (!hasDuplicatedHistoricalInvestments(parent, portfolio, path, ts[0], excludedInvestments)) {
              continue;
            }

            if (mutableErrors[ts[0]]) {
              mutableErrors[ts[0]].push({ portfolioId: portfolio.id });
            } else {
              mutableErrors[ts[0]] = [{ portfolioId: portfolio.id }];
            }
          }
        }
      }
      return;
    }
    for (const [index, child] of portfolio.children.entries()) {
      populateErrors(portfolio, child, [...path, index], mutableErrors);
    }
  };
  const errors: HistoricalPortfolioErrorState = {};
  populateErrors(undefined, portfolio, [0], errors);
  return errors;
};

const cleanPortfolio = (portfolio: Portfolio): Portfolio => ({
  ...portfolio,
  children: portfolio.children.filter((c) => !c?.fund || !!c?.fund?.id).map(cleanPortfolio),
});
