import React from 'react';
import type { SearchResultWithUIState } from './types';
import { StyledHint } from './StyledComponents';
import styled from 'styled-components';
import { NumericSpan, PercentageSpan } from 'venn-components';
import { iconsRender, renderExtrapolation, renderProxyType, renderTimespan } from './Shared';
import { GetColor, Pill } from 'venn-ui-kit';
import { getAnnRet, getAnnVol, getSharpe } from './utils';

const renderAlpha = (item: SearchResultWithUIState) => {
  const { annualizedAlphaTStat, periodAlpha, periodAnnualized } = item;
  if (periodAlpha && annualizedAlphaTStat) {
    return (
      <>
        <p>Residual Return</p>
        <p>
          / T-Stat:
          <span className="tooltip-value">
            <PercentageSpan value={periodAlpha} />
            &nbsp;/&nbsp;
            <NumericSpan precision={2}>{annualizedAlphaTStat}</NumericSpan>
          </span>
        </p>
      </>
    );
  }

  if (!periodAlpha && annualizedAlphaTStat) {
    return (
      <p>
        T-Stat:&nbsp;
        <NumericSpan className="tooltip-value" precision={2}>
          {annualizedAlphaTStat}
        </NumericSpan>
      </p>
    );
  }

  if (!annualizedAlphaTStat && periodAlpha) {
    return (
      <p>
        {periodAnnualized ? 'Annualized' : 'Cumulative'} Residual Return:&nbsp;
        <PercentageSpan className="tooltip-value" value={periodAlpha} />
      </p>
    );
  }

  return null;
};

const renderTotalReturn = (item: SearchResultWithUIState) => {
  const periodReturn = getAnnRet(item);
  if (periodReturn === 0 || periodReturn) {
    return (
      <p>
        Annualized Return*:&nbsp;
        <PercentageSpan className="tooltip-value" value={periodReturn} />
      </p>
    );
  }
  return null;
};

const renderVolatility = (item: SearchResultWithUIState) => {
  const vol = getAnnVol(item);
  if (vol === 0 || vol) {
    return (
      <p>
        Volatility*:&nbsp;
        <PercentageSpan className="tooltip-value" value={vol} />
      </p>
    );
  }
  return null;
};

const renderSharpeRatio = (item: SearchResultWithUIState) => {
  const sharpe = getSharpe(item);
  if (sharpe === 0 || sharpe) {
    return (
      <p>
        Sharpe*:&nbsp;
        <NumericSpan className="tooltip-value" precision={2}>
          {sharpe}
        </NumericSpan>
      </p>
    );
  }
  return null;
};

const renderText = (item: SearchResultWithUIState) => {
  if (!item.fundId && !item.portfolioId) {
    return '';
  }
  if (item.investmentSource === 'CUSTOM') {
    return 'Composite Benchmark';
  }
  if (item.portfolioId) {
    return 'Portfolio';
  }
  return 'Investment';
};

interface ItemOverviewProps {
  item: SearchResultWithUIState;
}

const ItemOverview = ({ item }: ItemOverviewProps) => {
  /* Even though an item has a proxy, there are cases wherein the proxy wasn't used to calculate
   * the metrics displayed in the library, so we don't show the proxy info here.
   * Examples: system funds and publisher funds since they are shared across workspaces but their
   * proxies are workspace-specific.
   */
  const renderProxyInfo = !!(item.proxyStartRange && item.proxyEndRange);
  return (
    <div>
      {item.symbol && <StyledTooltipSymbol>{item.symbol}</StyledTooltipSymbol>}
      <h1>
        <span className="qa-library-item-name">{item.name}</span>
        <StyledType>
          {iconsRender(item)} {renderText(item)}
        </StyledType>
      </h1>

      {item.portfolioId && renderTimespan('Availability', item.startRange, item.endRange, item.frequency)}
      {item.fundId &&
        renderTimespan('Availability', item.unproxiedStartRange, item.unproxiedEndRange, item.unproxiedFrequency)}
      {renderProxyInfo && renderProxyType(item.proxyType)}
      {renderProxyInfo && item.proxyType !== 'EXTRAPOLATE' && renderExtrapolation(item.extrapolated ?? false)}
      {renderProxyInfo && renderTimespan('Proxy Range', item.proxyStartRange, item.proxyEndRange, item.frequency, true)}
      {renderTotalReturn(item)}
      {renderVolatility(item)}
      {renderSharpeRatio(item)}
      {renderAlpha(item)}

      <StyledHint>Click to perform analysis.</StyledHint>
    </div>
  );
};

export default ItemOverview;

const StyledTooltipSymbol = styled(Pill)`
  color: ${GetColor.Black};
  background: ${GetColor.White};
  display: inline-block;
  margin-bottom: 4px;
`;

const StyledType = styled.div`
  font-size: 10px;
  font-weight: bold;
`;
