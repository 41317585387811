/**
 * @fileoverview contains the minimal set of logic that is conditional on {@link CustomBlockTypeEnum | blockType}.
 *
 * This file could fit better outside venn-utils because they aren't really utils so much as a consolidation of
 * business logic. I did try to put this in venn-components and a couple other places but it tended to create circular reference problems.
 */

import { concat, isNil } from 'lodash';
import type { InfoGraphicTypeEnum } from 'venn-api';
import type { CustomBlockTypeEnum, CustomizableMetric } from 'venn-utils';

export const TEXT_AND_FORMAT_BLOCKS: CustomBlockTypeEnum[] = ['MARKDOWN', 'IMAGE', 'PAGE_BREAK'];

export const HOLDINGS_DATA_BLOCKS: CustomBlockTypeEnum[] = ['ASSET_EXPOSURE', 'SECTOR_EXPOSURE', 'GEOGRAPHY_EXPOSURE'];

export const PRIVATES_CASH_FLOW_BLOCKS: CustomBlockTypeEnum[] = ['PRIVATE_CASH_FLOW'];

export const PRIVATES_PERFORMANCE_BLOCKS: CustomBlockTypeEnum[] = [
  'PRIVATE_PERFORMANCE_TIME_SERIES',
  'PRIVATE_PERFORMANCE_SUMMARY',
];

export const PRIVATES_ASSET_GROWTH_BLOCKS: CustomBlockTypeEnum[] = [
  'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES',
  'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN',
];

export const PRIVATES_METADATA_BLOCKS: CustomBlockTypeEnum[] = ['PRIVATE_CAPITAL_SUMMARY'];
export const PRIVATES_BLOCKS: CustomBlockTypeEnum[] = [
  ...PRIVATES_CASH_FLOW_BLOCKS,
  ...PRIVATES_METADATA_BLOCKS,
  ...PRIVATES_PERFORMANCE_BLOCKS,
];

export const HOLDINGS_TREE_BLOCKS: CustomBlockTypeEnum[] = ['SECTOR_EXPOSURE', 'GEOGRAPHY_EXPOSURE'];

export const PORTFOLIO_BLOCKS: CustomBlockTypeEnum[] = ['PORTFOLIO_COMPARISON'];

export const isHoldingsBlock = (customBlockType: CustomBlockTypeEnum) => {
  return HOLDINGS_DATA_BLOCKS.includes(customBlockType);
};
const AXIS_INFOGRAPHICS = [
  'BAR',
  'BOX',
  'BOX_CHART',
  'DISTRIBUTE_BAR',
  'DRAWDOWN',
  'FACTOR_TREND',
  'FACTOR_TREND',
  'GROWTH_SIMULATION',
  'HEATMAP',
  'LINE',
  'PEER_GROUP',
  'PRIVATE_CASH_FLOW',
  'SCATTER',
] as const;
type AxisInfographic = (typeof AXIS_INFOGRAPHICS)[number];
// TODO(VENN-26038): Migrate sector exposure not to use bar chart type
export const supportsAxisFontSize = (
  infographicType: InfoGraphicTypeEnum,
  blockType: CustomBlockTypeEnum,
): infographicType is AxisInfographic =>
  blockType !== 'SECTOR_EXPOSURE' && AXIS_INFOGRAPHICS.includes(infographicType as AxisInfographic);

const LEGEND_INFOGRAPHICS = [
  'BAR',
  'BOX',
  'BOX_CHART',
  'CORRELATION',
  'DISTRIBUTE_BAR',
  'DIVERGENT_BAR',
  'DRAWDOWN',
  'FACTOR_TREND',
  'FACTOR_TREND',
  'GROWTH_SIMULATION',
  'HEATMAP',
  'LINE',
  'MAP',
  'PEER_GROUP',
  'PIE',
  'PRIVATE_CASH_FLOW',
  'SCATTER',
] as const;
type LegendInfographic = (typeof LEGEND_INFOGRAPHICS)[number];
// TODO(VENN-26038): Migrate sector exposure not to use bar chart type
export const supportsLegendFontSize = (infographicType: InfoGraphicTypeEnum): infographicType is LegendInfographic =>
  LEGEND_INFOGRAPHICS.includes(infographicType as LegendInfographic);

export const supportsCompactMode = (infographicType: InfoGraphicTypeEnum | undefined, blockType: CustomBlockTypeEnum) =>
  infographicType?.includes('GRID') || (blockType === 'SECTOR_EXPOSURE' && infographicType?.includes('BAR'));

const TABLE_DATA_INFOGRAPHICS = [
  'CORRELATION',
  'DIVERGENT_BAR',
  'GRID',
  'PIVOTED_GRID',
  'CALENDAR_GRID',
  'GRID_TREE',
  'BOX_CHART',
  'PEER_GROUP',
] as const;
type TableDataInfographic = (typeof TABLE_DATA_INFOGRAPHICS)[number];
// TODO(VENN-26038): Migrate sector exposure not to use bar chart type
export const supportsTableDataFontSize = (
  infographicType: InfoGraphicTypeEnum,
  blockType: CustomBlockTypeEnum,
): infographicType is TableDataInfographic =>
  blockType === 'SECTOR_EXPOSURE' || TABLE_DATA_INFOGRAPHICS.includes(infographicType as TableDataInfographic);

const TABLE_SPACING_INFOGRAPHICS = [
  'GRID',
  'PIVOTED_GRID',
  'CALENDAR_GRID',
  'GRID_TREE',
  'BOX_CHART',
  'PEER_GROUP',
] as const;
type TableSpacingInfographic = (typeof TABLE_SPACING_INFOGRAPHICS)[number];
// TODO(VENN-26038): Migrate sector exposure not to use bar chart type
export const supportsTableSpacing = (
  infographicType: InfoGraphicTypeEnum,
  blockType: CustomBlockTypeEnum,
): infographicType is TableSpacingInfographic =>
  blockType === 'SECTOR_EXPOSURE' || TABLE_SPACING_INFOGRAPHICS.includes(infographicType as TableSpacingInfographic);
export const isPrivatesBlock = (customBlockType: CustomBlockTypeEnum) => PRIVATES_BLOCKS.includes(customBlockType);
export const isProjectionBasedPrivatesBlock = (customBlockType: CustomBlockTypeEnum) => {
  return PRIVATES_CASH_FLOW_BLOCKS.includes(customBlockType) || PRIVATES_METADATA_BLOCKS.includes(customBlockType);
};
export const isPrivatesPerformanceBlock = (customBlockType: CustomBlockTypeEnum) => {
  return PRIVATES_PERFORMANCE_BLOCKS.includes(customBlockType);
};
export const isPublicPrivateAssetGrowthBlock = (customBlockType: CustomBlockTypeEnum) => {
  return PRIVATES_ASSET_GROWTH_BLOCKS.includes(customBlockType);
};

export const isTreeBlock = (customBlockType: CustomBlockTypeEnum) => {
  return HOLDINGS_TREE_BLOCKS.includes(customBlockType);
};

export const isTextOrFormatBlock = (customBlockType: CustomBlockTypeEnum) => {
  return TEXT_AND_FORMAT_BLOCKS.includes(customBlockType);
};

export const isAnalysisBlock = (customBlockType: CustomBlockTypeEnum) => {
  return (
    !isTextOrFormatBlock(customBlockType) &&
    !isHoldingsBlock(customBlockType) &&
    !isPrivatesBlock(customBlockType) &&
    !isPortfolioBlock(customBlockType)
  );
};

export const isPortfolioBlock = (type: CustomBlockTypeEnum) => PORTFOLIO_BLOCKS.includes(type);

export const requiresAnalysisRequest = (customBlockType: CustomBlockTypeEnum) => {
  return isAnalysisBlock(customBlockType) || isPrivatesBlock(customBlockType);
};

export type DATE_BEHAVIOR_ENUM = 'SERVER_SENT' | 'DATE_RANGE' | 'END_DATE_RANGE' | 'NONE';

export const requiresInfographic = (customBlockType: CustomBlockTypeEnum) => {
  return !isTextOrFormatBlock(customBlockType);
};

const DISABLED_RELATIVE_TO_BENCHMARK_BLOCKS = concat(
  [
    'GROWTH_SIMULATION',
    'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES',
    'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN',
    'PORTFOLIO_BREAKDOWN',
  ] as CustomBlockTypeEnum[],
  HOLDINGS_DATA_BLOCKS,
  PRIVATES_PERFORMANCE_BLOCKS,
);
export const hasDisabledRelativeToBenchmark = (customBlockType: CustomBlockTypeEnum | undefined) => {
  return customBlockType && DISABLED_RELATIVE_TO_BENCHMARK_BLOCKS.includes(customBlockType);
};

export const shouldForceHideBenchmark = (
  customBlockType: CustomBlockTypeEnum | undefined,
  metricDefinitionMap: Record<string, CustomizableMetric>,
  selectedMetrics: string[],
) => {
  if (isNil(customBlockType) || !PRIVATES_PERFORMANCE_BLOCKS.includes(customBlockType)) {
    return false;
  }

  // If none of the selected metrics need benchmark (e.g. only called % and RVPI % are selected)
  // then we will hide benchmark even if it's selected in block settings because it has no effect on these metrics
  return selectedMetrics
    .map((metric) => metricDefinitionMap[metric])
    .every((metricDefinition) => metricDefinition.needsBenchmark !== true);
};

const FULLY_DISABLED_BENCHMARK_BLOCKS: CustomBlockTypeEnum[] = [
  'PORTFOLIO_BREAKDOWN',
  ...PRIVATES_CASH_FLOW_BLOCKS,
  ...PRIVATES_METADATA_BLOCKS,
  ...PORTFOLIO_BLOCKS,
  'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN',
  'HISTORICAL_ALLOCATIONS_TIMESERIES',
];
const DISABLED_BENCHMARK_BLOCK_GRAPHIC_COMBOS = new Map<CustomBlockTypeEnum, InfoGraphicTypeEnum>([
  ['ASSET_EXPOSURE', 'PIE'],
  ['SECTOR_EXPOSURE', 'BAR'],
  ['GEOGRAPHY_EXPOSURE', 'MAP'],
]);

export const hasDisabledBenchmark = (
  customBlockType: CustomBlockTypeEnum | undefined,
  infoGraphicType: InfoGraphicTypeEnum | undefined,
) => {
  return (
    (customBlockType && FULLY_DISABLED_BENCHMARK_BLOCKS.includes(customBlockType)) ||
    disabledBenchmarkForBlockGraphicCombos(customBlockType, infoGraphicType)
  );
};

function disabledBenchmarkForBlockGraphicCombos(
  customBlockType: CustomBlockTypeEnum | undefined,
  infoGraphicType: InfoGraphicTypeEnum | undefined,
) {
  if (customBlockType && infoGraphicType) {
    return DISABLED_BENCHMARK_BLOCK_GRAPHIC_COMBOS.get(customBlockType) === infoGraphicType;
  }
  return false;
}

const DISABLED_INDIVIDUAL_BENCHMARK_BLOCKS = concat(
  ['CORRELATION', 'DISTRIBUTION', 'PEER_GROUPS'] as CustomBlockTypeEnum[],
  HOLDINGS_DATA_BLOCKS,
  PRIVATES_CASH_FLOW_BLOCKS,
  PRIVATES_METADATA_BLOCKS,
  ['PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES'],
);
export const hasDisabledIndividualBenchmark = (customBlockType: CustomBlockTypeEnum | undefined) => {
  return customBlockType && DISABLED_INDIVIDUAL_BENCHMARK_BLOCKS.includes(customBlockType);
};

const DISABLED_DATE_RANGE_BLOCKS = concat(
  ['GROWTH_SIMULATION', 'SCENARIO', 'PORTFOLIO_BREAKDOWN'],
  HOLDINGS_DATA_BLOCKS,
  PRIVATES_METADATA_BLOCKS,
  PORTFOLIO_BLOCKS,
);

export const hasDisabledDateRange = (customBlockType: CustomBlockTypeEnum) =>
  customBlockType && DISABLED_DATE_RANGE_BLOCKS.includes(customBlockType);

export const getDateBehavior = (customBlockType: CustomBlockTypeEnum | undefined) => {
  if (!customBlockType) {
    return 'DATE_RANGE';
  }

  if (HOLDINGS_DATA_BLOCKS.includes(customBlockType)) {
    return 'SERVER_SENT';
  }

  if (hasDisabledDateRange(customBlockType)) {
    return 'NONE';
  }

  if (
    PRIVATES_CASH_FLOW_BLOCKS.includes(customBlockType) ||
    PRIVATES_PERFORMANCE_BLOCKS.includes(customBlockType) ||
    PRIVATES_ASSET_GROWTH_BLOCKS.includes(customBlockType)
  ) {
    return 'END_DATE_RANGE';
  }

  return 'DATE_RANGE';
};

const BLOCKS_NOT_SHOWING_INVESTMENTS: CustomBlockTypeEnum[] = [
  'PORTFOLIO_CONTRIBUTION',
  'PORTFOLIO_FACTOR_CONTRIBUTION',
];

export const blockShowsInvestments = (blockType: CustomBlockTypeEnum) => {
  return !BLOCKS_NOT_SHOWING_INVESTMENTS.includes(blockType);
};

export const getBlockTileLabel = (blockType: CustomBlockTypeEnum) => {
  if (isPublicPrivateAssetGrowthBlock(blockType)) {
    return 'RETURNS + CASHFLOWS';
  }

  return undefined;
};
