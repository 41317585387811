import React, { useState, useContext, useCallback } from 'react';
import { constSelector, useRecoilValue } from 'recoil';
import { ConfirmationModal, StudioContext } from 'venn-components';
import {
  redistributableState,
  isReportState,
  useRecoilValueWithDefault,
  analysisViewCombinedIdState,
} from 'venn-state';
import { getAppTitle, getTextThemeProvider, Icon } from 'venn-ui-kit';
import { useHasFF, useModal } from 'venn-utils';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

const CONTACT_US_ITEM_CLASSNAME = 'contact-us-dropdown-item';

const contactUsLink = (
  <a
    className={CONTACT_US_ITEM_CLASSNAME}
    target="_blank"
    href={`mailto:${getTextThemeProvider().salesEmail}?subject=Report Lab Inquiry`}
    rel="noreferrer"
  >
    contact us
  </a>
);

export const RedesignedPdfExport = () => {
  const { onPdfExport, isFetchingNewViewRef, isCheckingDuplicateReportName } = useContext(StudioContext);
  const isReport = useRecoilValue(isReportState);

  const [isExportingPdf, setIsExportingPdf] = useState(false);
  const [showFinalConfirmationModal, openFinalConfirmationModal, closeFinalConfirmationModal] = useModal();

  const onPdfExportInternal = useCallback(async () => {
    setIsExportingPdf(true);
    await onPdfExport(true);
    setIsExportingPdf(false);
  }, [onPdfExport]);

  const hasReportLab = useHasFF('studio_report_editor');
  const hasDisabledExport = useHasFF('disable_exports_ff');
  const hasVennBrandedWatermark = useHasFF('venn_branded_watermark_ff');

  const disableFinalExport = useIsFinalExportDisabled();

  if (isReport) {
    return (
      <>
        <DropdownMenu>
          <Tooltip>
            <TooltipTrigger asChild>
              <DropdownMenuTrigger asChild>
                <button
                  type="button"
                  className="flex gap-2 items-center justify-center text-white h-[64px] w-[64px] hover:bg-venn-grey-80 aria-expanded:bg-white aria-expanded:text-venn-dark-blue hover:text-white"
                  disabled={isFetchingNewViewRef.current || isExportingPdf || isCheckingDuplicateReportName}
                  aria-label="PDF export options"
                >
                  <Icon type="file-pdf" className="text-2xl" />
                  <Icon type="caret-down" prefix="fas" />
                </button>
              </DropdownMenuTrigger>
            </TooltipTrigger>
            <TooltipContent side="bottom">PDF Export</TooltipContent>
          </Tooltip>
          <DropdownMenuContent className="w-56 text-white bg-venn-grey-90">
            <DropdownMenuItem
              className="focus:bg-venn-grey-80 focus:text-white text-lg"
              onSelect={() => onPdfExport(true)}
            >
              Draft
            </DropdownMenuItem>
            <DropdownMenuItem
              className="focus:bg-venn-grey-80 focus:text-white text-lg"
              onSelect={() => onPdfExport(false)}
              disabled={disableFinalExport}
            >
              Final
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="focus:bg-venn-grey-80 focus:text-white text-lg"
              onSelect={openFinalConfirmationModal}
            >
              What's the difference
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        {showFinalConfirmationModal && (
          <ConfirmationModal
            header="PDF Export"
            subhead="What’s the difference between a Draft and Final?"
            text={
              <div>
                <div style={{ marginBottom: 40 }}>
                  {`DRAFT - PDF is intended for internal purposes and is not retained in ${getAppTitle()}’s Document Archive.
                 An “Internal Use Only” watermark will appear on all documents.`}
                </div>
                <div>
                  {`FINAL - PDF is intended for external distribution. Watermarks will be removed.
                All Final documents will be stored in ${getAppTitle()}’s Document Archive upon export. `}
                </div>
              </div>
            }
            onProceed={() => closeFinalConfirmationModal()}
            proceedLabel="Close"
          />
        )}
      </>
    );
  }

  const showStudioExportOptions = !hasReportLab && (hasDisabledExport || hasVennBrandedWatermark);
  if (showStudioExportOptions) {
    return (
      <DropdownMenu>
        <Tooltip>
          <TooltipTrigger asChild>
            <DropdownMenuTrigger asChild>
              <button
                type="button"
                className="flex gap-2 items-center justify-center text-white h-[64px] w-[64px] hover:bg-venn-grey-80 aria-expanded:bg-white aria-expanded:text-venn-dark-blue hover:text-white"
                disabled={isFetchingNewViewRef.current || isExportingPdf || isCheckingDuplicateReportName}
                aria-label="PDF export options"
              >
                <Icon type="file-pdf" className="text-2xl" />
                <Icon type="caret-down" prefix="fas" />
              </button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          <TooltipContent side="bottom">PDF Export</TooltipContent>
        </Tooltip>
        <DropdownMenuContent className="w-[200px] text-white bg-venn-grey-90">
          <DropdownMenuItem className="focus:bg-venn-grey-80 focus:text-white text-lg" onSelect={onPdfExportInternal}>
            PDF Export (internal use only)
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuLabel className="text-lg">
            <Icon type="file-chart-column" className="pr-1" />
            Please {contactUsLink} for enhanced reporting and exporting options.
          </DropdownMenuLabel>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          type="button"
          className="flex items-center justify-center text-white h-[64px] w-[64px] hover:bg-venn-grey-80 hover:text-white"
          disabled={isFetchingNewViewRef.current || isExportingPdf || isCheckingDuplicateReportName}
          onClick={onPdfExportInternal}
          aria-label="Export to PDF"
        >
          <Icon type="file-pdf" className="text-2xl" />
        </button>
      </TooltipTrigger>
      <TooltipContent side="bottom">PDF Export</TooltipContent>
    </Tooltip>
  );
};

function useIsFinalExportDisabled() {
  const analysisViewId = useRecoilValue(analysisViewCombinedIdState);

  const hasReportLabExportWatermarkFF = useHasFF('report_lab_export_watermark_ff');
  const isRedistributableLoadable = useRecoilValueWithDefault(
    analysisViewId ? redistributableState(analysisViewId) : constSelector(true),
    false,
  );

  return !isRedistributableLoadable && hasReportLabExportWatermarkFF;
}
