import {
  type ComputedDateRange,
  type CustomBlockTypeEnum,
  type CustomizableMetric,
  PRO_FORMA_HISTORICAL_AS_OF_DATE_PARAMETERIZED_BLOCKS,
  type SelectableCustomNotablePeriod,
} from 'venn-utils';
import {
  assertNotNil,
  getRequestSubjectFromAnalysisSubject,
  hasDisabledDateRange,
  requiresAnalysisRequest,
} from 'venn-utils';
import type {
  AnalysisTypeEnum,
  CustomBenchmarkTypeEnum,
  InfoGraphicTypeEnum,
  PeerGroupIdentifier,
  Scenario,
} from 'venn-api';
import { compact, isNil, uniq } from 'lodash';
import { getBenchmarkSubject, type StudioAnalysisRequest, type StudioRequestSubject } from 'venn-state';

export const getBlockRequest = (
  apiRequestSubjects: StudioRequestSubject[],
  metrics: CustomizableMetric[],
  commonBenchmark: StudioRequestSubject | undefined,
  benchmarkType: CustomBenchmarkTypeEnum,
  relative: boolean,
  computedDateRange: ComputedDateRange | undefined,
  customBlockType: CustomBlockTypeEnum,
  blockGraphicType: InfoGraphicTypeEnum | undefined,
  rollingYears?: number,
  forecastWindow?: number,
  confidenceLevels?: number[],
  allSelectedNotablePeriods?: number[],
  customNotablePeriods?: SelectableCustomNotablePeriod[],
  scenarios?: Scenario[],
  groupRootLevelInvestments?: boolean,
  selectedPeerGroupId?: PeerGroupIdentifier | undefined,
  startingNav?: number,
  historicalPortfolioAsOfDates?: (number | undefined)[],
): StudioAnalysisRequest[] => {
  if (!requiresAnalysisRequest(customBlockType)) {
    return [];
  }

  const blockAnalysesTypes = uniq(
    compact(
      metrics.map((m) =>
        m.analysisType !== 'CUSTOM_ENTRY' && m.analysisType !== 'CUSTOM_METADATA' ? m.analysisType : undefined,
      ),
    ),
  );
  if (!hasDisabledDateRange(customBlockType)) {
    assertNotNil(
      computedDateRange,
      'Analysis cannot be performed with a nil block date range. Check the date range before trying to run analysis.',
    );
  }
  const frequency = computedDateRange?.frequency ?? 'DAILY';
  const dateRange = computedDateRange?.range ?? { from: undefined, to: undefined };

  const hasCumulativeReturn = blockAnalysesTypes.some((type) => type === 'CUMULATIVE_RETURN');
  const analysesTypes: AnalysisTypeEnum[] = hasCumulativeReturn
    ? [...blockAnalysesTypes, 'VENNCAST']
    : blockAnalysesTypes;
  const customTimeFrames = customNotablePeriods?.map((period) => ({
    start: period.start,
    end: period.end,
  }));
  const selectedNotablePeriods = allSelectedNotablePeriods?.filter((id) => id > 0);

  const partialRequest: Omit<
    StudioAnalysisRequest,
    'subject' | 'benchmark' | 'benchmarkType' | 'relative' | 'isBenchmark'
  > = {
    dateRange,
    analysesTypes,
    frequency,
    rollingYears,
    selectedNotablePeriods,
    customTimeFrames,
    includeAllPredefinedPeriods: isNil(selectedNotablePeriods),
    forecastWindow,
    forecastConfidenceLevels: customBlockType === 'GROWTH_SIMULATION' ? confidenceLevels : undefined,
    groupRootLevelInvestments,
    peerGroupIdentifier: selectedPeerGroupId,
    returnsBasedSubjectStartingNav: startingNav,
  };

  const hasExplicitAsOfDateSetting = PRO_FORMA_HISTORICAL_AS_OF_DATE_PARAMETERIZED_BLOCKS.includes(customBlockType);

  const requests: StudioAnalysisRequest[] = apiRequestSubjects.map((subject, index) => {
    const benchmark =
      benchmarkType === 'INDIVIDUAL'
        ? subject.individualBenchmark &&
          getRequestSubjectFromAnalysisSubject(getBenchmarkSubject(subject.individualBenchmark))
        : benchmarkType === 'COMMON'
          ? commonBenchmark
          : undefined;
    return {
      ...partialRequest,
      subject,
      benchmark,
      benchmarkType: benchmark ? benchmarkType : 'NONE',
      relative,
      scenarios,
      historicalPortfolioAsOfDate: hasExplicitAsOfDateSetting ? historicalPortfolioAsOfDates?.[index] : undefined,
    };
  });

  if (benchmarkType === 'COMMON' && !relative && commonBenchmark) {
    requests.push({
      ...partialRequest,
      subject: commonBenchmark,
      frequency,
      rollingYears,
      benchmarkType: 'NONE',
      benchmark: undefined,
      relative: false,
      isBenchmark: true,
      scenarios,
    });
  }

  return requests;
};
