import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { ColorUtils, GetColor, Icon, EllipsisAutoPositionTooltipSpan, TooltipBodyDirection, Label } from 'venn-ui-kit';
import StrategyInput from './strategy/StrategyInput';
import { Constants } from './Layout';
import { toClassName } from 'venn-utils';

export const GhostFundNameClass = 'ghost-fund-name';
export const OutOfSubtreeNameClass = 'out-of-subtree-item-name';

export interface AllocationItemNameProps {
  originalName?: string;
  name: string;
  width: number;
  isGhost: boolean;
  isEditing: boolean;
  isStrategy: boolean;
  hasAddFund: boolean;
  isSelected: boolean;
  isCollapsed: boolean;
  isOutsideOfSelectedSubtree: boolean;
  investmentTimeRange?: string;
  isMissingReturnsMessage?: JSX.Element;
  onSelectStrategyClick?: () => void;
  onAddFundClick: (() => void) | undefined;
  onTriangleClick: (() => void) | undefined;
  onEditNameClick: ((value: string) => void) | undefined;
  investmentSymbol?: string;
  hideComparisonColumn?: boolean;
}

class AllocationItemName extends PureComponent<AllocationItemNameProps> {
  renderName = () => {
    const {
      originalName,
      name,
      width,
      isOutsideOfSelectedSubtree,
      isSelected,
      investmentTimeRange,
      isMissingReturnsMessage,
      isStrategy,
      isGhost,
      investmentSymbol,
    } = this.props;
    const hasChanged = !!originalName && name !== originalName;
    const fixedWidthElementsWidth = this.getFixedWidthElementsWidth();
    // If it's an investment, we will always show tooltip for adding investment time range
    const alwaysShowTooltip = !isStrategy && !isGhost;
    const contentHeader = investmentTimeRange ? `${name} ${investmentTimeRange}` : name;
    return (
      <LeftTextAlign>
        <EllipsisAutoPositionTooltipSpan
          usePortal
          content={
            <TooltipContent>
              <div>
                {investmentSymbol ? <StyledSymbol>{investmentSymbol}</StyledSymbol> : null}
                <StyledLabel inverted>{contentHeader}</StyledLabel>
              </div>
              {isMissingReturnsMessage}
            </TooltipContent>
          }
          bodyDirection={TooltipBodyDirection.Right}
          maxWidth={width - fixedWidthElementsWidth}
          alwaysShowTooltip={alwaysShowTooltip}
          hideArrow
          tooltipMaxWidth={172}
        >
          <Name hasChanged={hasChanged} isOutsideOfSelectedSubtree={isOutsideOfSelectedSubtree} isSelected={isSelected}>
            {name}
          </Name>
        </EllipsisAutoPositionTooltipSpan>
      </LeftTextAlign>
    );
  };

  getFixedWidthElementsWidth = () => {
    const { isStrategy, hasAddFund, hideComparisonColumn } = this.props;
    return (
      (isStrategy ? Constants.TRIANGLE_WIDTH : 0) +
      Constants.NAME_MARGIN +
      (hasAddFund ? Constants.ADD_FUND_PLUS_WIDTH : 0) +
      Constants.ALLOCATION_WIDTH +
      Constants.ACTIONS_WIDTH +
      (hideComparisonColumn ? 0 : Constants.COMPARE_WIDTH)
    );
  };

  render() {
    const {
      name,
      width,
      isGhost,
      isStrategy,
      isEditing,
      hasAddFund,
      isSelected,
      isCollapsed,
      isOutsideOfSelectedSubtree,
      onSelectStrategyClick,
      onAddFundClick,
      onTriangleClick,
      onEditNameClick,
    } = this.props;
    if (isEditing && onEditNameClick) {
      return <StrategyInput name={name} onChange={onEditNameClick} />;
    }
    const fixedWidthElementsWidth = this.getFixedWidthElementsWidth();
    const fixedElementsForSelectedBackgroundWidth = isStrategy
      ? fixedWidthElementsWidth - Constants.NAME_MARGIN - Constants.TRIANGLE_WIDTH - Constants.ADD_FUND_PLUS_WIDTH
      : fixedWidthElementsWidth - Constants.NAME_MARGIN;

    const isSelectingDisabled = onSelectStrategyClick === undefined;
    const testqaClass = toClassName(name);
    return (
      <ItemName
        tabIndex={0}
        isGhost={isGhost}
        isStrategy={isStrategy}
        isOutsideOfSelectedSubtree={isOutsideOfSelectedSubtree}
        className={
          isGhost && !isStrategy && !isOutsideOfSelectedSubtree
            ? GhostFundNameClass
            : !isGhost && isOutsideOfSelectedSubtree
              ? OutOfSubtreeNameClass
              : testqaClass
        }
        disabled={isGhost || isSelected || isSelectingDisabled}
        aria-label={isSelectingDisabled ? undefined : `Select ${name}`}
        onClick={onSelectStrategyClick}
      >
        {isSelected && (
          <div style={{ position: 'relative' }}>
            <SelectedBackground width={width - fixedElementsForSelectedBackgroundWidth} />
          </div>
        )}

        {isStrategy && (
          <Triangle
            isGhost={isGhost}
            isOutsideOfSelectedSubtree={isOutsideOfSelectedSubtree}
            aria-label={`${isCollapsed ? 'Show' : 'Hide'} children of strategy ${name}`}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              onTriangleClick?.();
            }}
          >
            <Icon type={isCollapsed ? 'caret-right' : 'caret-down'} />
          </Triangle>
        )}
        {this.renderName()}
        {hasAddFund && (
          <AddFund
            isOutsideOfSelectedSubtree={isOutsideOfSelectedSubtree}
            aria-label="Add investment"
            onClick={
              !isOutsideOfSelectedSubtree
                ? (e: React.MouseEvent) => {
                    e.stopPropagation();
                    onAddFundClick?.();
                  }
                : undefined
            }
          >
            <Icon type="plus" />
          </AddFund>
        )}
      </ItemName>
    );
  }
}
export default AllocationItemName;

const Triangle = styled.button<{ isGhost: boolean; isOutsideOfSelectedSubtree: boolean }>`
  position: relative;
  font-size: 16px;
  left: -4.5px;
  width: ${Constants.TRIANGLE_WIDTH}px;
  text-align: center;

  &&&:hover {
    cursor: pointer;
  }

  ${({ isGhost, isOutsideOfSelectedSubtree }) => css`
    color: ${isGhost || isOutsideOfSelectedSubtree ? GetColor.Grey : GetColor.Black};
  `}
`;

const Selectable = styled.span<{ disabled: boolean }>`
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`;

const ItemName = styled(Selectable)<{
  isGhost: boolean;
  isStrategy: boolean;
  isOutsideOfSelectedSubtree: boolean;
}>`
  display: flex;
  align-items: center;
  height: 30px;
  ${({ isGhost, isOutsideOfSelectedSubtree }) =>
    isGhost || isOutsideOfSelectedSubtree
      ? css`
          color: ${GetColor.Grey};
        `
      : css`
          color: ${GetColor.Black};
        `}
  ${({ isStrategy }) =>
    isStrategy
      ? css`
          font-weight: bold;
          font-size: 12px;
        `
      : css`
          font-size: 14px;
        `}
  flex-grow: 1;
`;

const Name = styled.span<{ hasChanged: boolean; isOutsideOfSelectedSubtree: boolean; isSelected: boolean }>`
  line-height: normal;
  color: ${({ hasChanged, isOutsideOfSelectedSubtree, isSelected }) =>
    hasChanged && !isOutsideOfSelectedSubtree && !isSelected
      ? GetColor.HighlightDark
      : hasChanged && isOutsideOfSelectedSubtree && !isSelected
        ? GetColor.HighlightLight
        : undefined};
`;

const SelectedBackground = styled.div<{ width: number }>`
  height: 30px;
  background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute;
  border: 1px solid ${GetColor.Primary.Dark};
  border-left: 0;
  left: -${Constants.NAME_MARGIN}px;
  padding-left: ${Constants.NAME_MARGIN}px;
  top: -15px;

  ${({ width }) => `width: ${width}px;`}
`;

const AddFund = styled.button<{ isOutsideOfSelectedSubtree: boolean }>`
  width: ${Constants.ADD_FUND_PLUS_WIDTH}px;
  min-width: ${Constants.ADD_FUND_PLUS_WIDTH}px;
  position: relative;
  top: -1px;
  color: ${({ isOutsideOfSelectedSubtree }) => (isOutsideOfSelectedSubtree ? GetColor.Grey : GetColor.HintGrey)};
  padding-left: 3px;
  padding-right: 5px;
  &&&:hover {
    ${({ isOutsideOfSelectedSubtree }) => (isOutsideOfSelectedSubtree ? 'cursor: default;' : 'cursor: pointer')}
  }
`;

const LeftTextAlign = styled.div`
  text-align: left;
`;

const TooltipContent = styled.div`
  > div:last-child {
    :not(:only-child) {
      margin-top: 5px;
    }
  }
`;

const StyledSymbol = styled.span`
  border-radius: 2px;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  margin-top: -1px;
  color: ${GetColor.White};
  display: inline;
  background: ${GetColor.DarkGrey};
  padding: 1px 5px;
  margin-right: 6px;
`;

const StyledLabel = styled(Label)`
  line-height: 16px;
`;
