import React from 'react';
import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Icon } from 'venn-ui-kit';
import type { BulkManageRow } from '../types';
import { bulkManageSortFunction } from './bulkManageSortFunction';
import { DisabledCellClass } from './column-styles';
import { FundUtils } from 'venn-utils';
import { isBulkManageFundRow } from '../utils';

type DataSourceValue = string | undefined;
const IntegrationDataSourceRenderer = ({ value }: ICellRendererParams<BulkManageRow, DataSourceValue>) => {
  if (!value) {
    return null;
  }

  return (
    <>
      {value} <Icon type="exchange" prefix="fas" />
    </>
  );
};

const DATA_SOURCE_COL_WIDTH = 130;

export const dataSourceColDef: ColDef<BulkManageRow, DataSourceValue> = {
  cellRendererSelector: ({ data }) =>
    isBulkManageFundRow(data) && FundUtils.isUserIntegration(data?.investment?.investmentSource)
      ? { component: IntegrationDataSourceRenderer }
      : undefined,
  field: 'dataSource',
  headerName: 'Data Source',
  width: DATA_SOURCE_COL_WIDTH,
  comparator: bulkManageSortFunction(),
  cellClassRules: {
    [DisabledCellClass]: ({ data }) => isBulkManageFundRow(data) && !data?.investment?.live,
  },
};
