import noop from 'lodash/noop';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import type { ActionEnum, AnalysisTypeEnum, Fund, Message } from 'venn-api';
import { HIDE_FORECASTS_IN_EXPORTS_KEY, analysis, SupportedErrorCodes } from 'venn-api';
import type { DownloadMetaData, TrackAnalysisProps } from 'venn-components';
import {
  DownloadableContentBlock,
  ForecastsMenuLauncher,
  PrintContainerDimensions,
  TrackSuccessfulAnalysis,
  UserContext,
} from 'venn-components';
import { forceZeroResidualForecastChangedAtom } from 'venn-state';
import type { AnalysisConfig, AnalysisSubject } from 'venn-utils';
import { convert, getAnalysisRequest, logExceptionIntoSentry, logMessageToSentry, useApi } from 'venn-utils';
import { tearsheetTabs } from '../../configs';
import type { AnalysesResults, PerformanceAnalysisContribution } from '../../types';
import toData, { convertContributionResponse } from './logic';
import { LongTermForecastTooltip, LongTermForecastTooltipForHistoricals } from './LongTermForecastTooltip';
import PerformanceSummaryTable from './PerformanceSummaryTable';
import { Blocks, TableContainer } from './Table';

export interface PerformanceSummaryContentProps {
  analysisConfig: AnalysisConfig;
  analysisLoading: boolean;
  noChartTrackingProps: TrackAnalysisProps;
  downloadMetaData?: DownloadMetaData;
  subtitle?: string;
  onForecastUpdate?: () => void;
  onFundUpdated?: (fund: Fund) => void;
  onDateRangeError?: (range: [string, string]) => void;
}

const performanceSummaryList: AnalysisTypeEnum[] = ['PERFORMANCE_SUMMARY_FORECAST', 'PERFORMANCE_SUMMARY_HISTORICAL'];

const performanceContributions: AnalysisTypeEnum[] = [
  'PERFORMANCE_SUMMARY_FORECAST_CONTRIBUTION',
  'PERFORMANCE_SUMMARY_HISTORICAL_CONTRIBUTION',
];

const parseDateError = (message: Message | undefined, onDateRangeError: (range: [string, string]) => void) => {
  if (message?.code === SupportedErrorCodes.InvalidAnalysisPeriod) {
    const [start, end] = message.text
      .slice(64 - 7)
      .split(' and ')
      .map((x) => x.replace(/\./g, ''));
    onDateRangeError([start, end]);
  }
};

const PerformanceSummaryContent = ({
  analysisConfig,
  noChartTrackingProps,
  downloadMetaData,
  subtitle,
  onForecastUpdate = noop,
  onFundUpdated = noop,
  onDateRangeError = noop,
}: PerformanceSummaryContentProps) => {
  const mounted = useRef(false);
  const { hasPermission } = useContext(UserContext);
  const { relative, subject, category, selectedPeriod, selectedTimeFrame } = analysisConfig;
  const categoryActive = category === 'ON';
  const getSummaryAnalysis = useApi(analysis);
  const getContributionAnalysis = useApi(analysis);
  const [contributions, setContributions] = useState<PerformanceAnalysisContribution | undefined>();
  const [analyses, setAnalyses] = useState<AnalysesResults | undefined>();
  const [loading, setLoading] = useState(false);
  const { metrics, columns, excelData } = useMemo(
    () =>
      subject
        ? toData(
            analyses,
            subject.type,
            relative,
            categoryActive,
            contributions,
            subject.secondaryPortfolio,
            subject.secondaryLabel,
            onFundUpdated,
            analyses?.results?.performance,
          )
        : {
            metrics: undefined,
            columns: undefined,
            excelData: undefined,
          },
    [analyses, subject, contributions, relative, categoryActive, onFundUpdated],
  );
  // `trackingIdRef` is used to determine when NOT to refresh analysis.
  const trackingIdRef = useRef<number>(-1);
  const forceZeroResidualForecastChanged = useRecoilValue(forceZeroResidualForecastChangedAtom);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  const portfolio = subject?.portfolio;
  const fund = subject?.fund;
  useEffect(() => {
    const fetchSummaryAnalysis = async () => {
      if (!subject) {
        logMessageToSentry('Failed to fetch summary analysis for missing subject');
        return;
      }
      setLoading(true);

      const configs = getAnalysisRequest(
        performanceSummaryList,
        subject,
        selectedTimeFrame,
        relative,
        categoryActive,
        selectedPeriod,
        analysisConfig.trackingId,
      );
      try {
        const { content } = await getSummaryAnalysis(configs);
        if (content.subjectErrors?.length) {
          const error = content.subjectErrors[0];
          parseDateError(error, onDateRangeError);
        }
        const analysesResults: AnalysesResults = convert(configs, content.analyses, subject, relative);
        if (mounted.current) {
          setAnalyses(analysesResults);
          setLoading(false);
        }
      } catch (e) {
        await e;
        if (e.name === 'AbortError') {
          return;
        }

        logExceptionIntoSentry(e);
      }
      setLoading(false);
    };

    const fetchContributionsAnalysis = async () => {
      const configs = getAnalysisRequest(
        performanceContributions,
        subject,
        selectedTimeFrame,
        relative,
        categoryActive,
        selectedPeriod,
        analysisConfig.trackingId,
      );
      try {
        const { content } = await getContributionAnalysis(configs);
        if (content.subjectErrors?.length) {
          const error = content.subjectErrors[0];
          parseDateError(error, onDateRangeError);
        }
        if (mounted.current) {
          const contributionsRes = convertContributionResponse(configs ?? {}, content.analyses);
          setContributions(contributionsRes);
        }
      } catch (e) {
        await e;
        if (e.name === 'AbortError') {
          return;
        }

        // Suppress error
        logExceptionIntoSentry(e);
      }
    };
    // Only re-fetch analysis results if analysisConfig.trackingId has changed or if "Force Zero Residual Forecast" has been toggled
    if (trackingIdRef.current !== analysisConfig.trackingId || forceZeroResidualForecastChanged) {
      fetchSummaryAnalysis();
      fetchContributionsAnalysis();
    }
    return () => {
      trackingIdRef.current = -1;
    };
  }, [
    selectedTimeFrame,
    subject,
    portfolio,
    fund,
    relative,
    categoryActive,
    analysisConfig.trackingId,
    selectedPeriod,
    getSummaryAnalysis,
    getContributionAnalysis,
    onDateRangeError,
    forceZeroResidualForecastChanged,
  ]); // portfolio and fund is for benchmark update

  useEffect(() => {
    trackingIdRef.current = analysisConfig.trackingId;
  }, [analysisConfig.trackingId]);

  if (!subject || !columns || !metrics) {
    logMessageToSentry('Failed to render performance summary content while missing data');
    return null;
  }

  return (
    <PrintContainerDimensions>
      {({ print }) => {
        return (
          <TrackSuccessfulAnalysis {...noChartTrackingProps}>
            <Blocks id={tearsheetTabs.PERFORMANCE_SUMMARY} print={print}>
              <DownloadableContentBlock
                className="qa-performance-summary-header"
                header="Performance Summary"
                subHeader={
                  subtitle ||
                  (!print && (
                    <PerformanceSummaryHeader
                      onForecastUpdate={onForecastUpdate}
                      hasPermission={hasPermission}
                      subject={subject}
                      benchmark={analysisConfig.benchmark}
                    />
                  ))
                }
                downloadable={{
                  png: true,
                  excel: excelData,
                  options: {
                    fileName: `${subject.name} - Performance Summary`,
                    width: 800,
                    metaData: downloadMetaData,
                  },
                  tracking: {
                    subjectType: subject.type,
                    subjectId: subject.id,
                    description: 'PERFORMANCE_SUMMARY_HISTORICAL',
                    relativeToBenchmark: relative,
                    userUploaded: subject.userUploaded,
                  },
                }}
              >
                <TableContainer>
                  <PerformanceSummaryTable
                    metrics={metrics}
                    columns={columns}
                    subject={subject}
                    relative={relative}
                    loading={loading}
                    print={print}
                    isAnalyzingStrategy={!!subject.strategyId && subject.strategyId !== subject.id}
                    isCategoryPredicted={subject.isCategoryPredicted}
                  />
                </TableContainer>
              </DownloadableContentBlock>
            </Blocks>
          </TrackSuccessfulAnalysis>
        );
      }}
    </PrintContainerDimensions>
  );
};

const PerformanceSummaryHeader = ({
  onForecastUpdate,
  hasPermission,
  subject,
  benchmark,
}: {
  onForecastUpdate: () => void;
  hasPermission: (perm: ActionEnum) => boolean;
  subject: AnalysisSubject;
  benchmark: AnalysisSubject | undefined;
}) => {
  const { settings } = useContext(UserContext);
  const hideForecasts = !!settings?.user?.[HIDE_FORECASTS_IN_EXPORTS_KEY];
  const isHistorical = !!subject.portfolio?.historical || !!benchmark?.isHistoricalPortfolio;

  return (
    <Row>
      Key metrics driven by historical data
      {!hideForecasts && (
        <>
          {' '}
          and your&nbsp;
          <ForecastsMenuLauncher
            ctaPurpose="Edit forecasts from Performance Summary Header"
            onDefaultForecastUpdated={onForecastUpdate}
            onResidualForecastUpdated={onForecastUpdate}
            isReadOnly={!hasPermission('EDIT_FORECASTS')}
          />
          .&nbsp;
          {isHistorical ? <LongTermForecastTooltipForHistoricals /> : <LongTermForecastTooltip />}
        </>
      )}
    </Row>
  );
};

export default PerformanceSummaryContent;

const Row = styled.div`
  display: flex;
`;
