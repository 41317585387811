import React from 'react';
import { MANAGE_DATA_HEADER_HORIZONTAL_PADDING_PX } from 'venn-components';
import { Icon, HISTORICAL_PORTFOLIOS_HELP_HREF } from 'venn-ui-kit';

export const ManageDataHeaderHistoricalErrorBanner = () => (
  <div
    className="flex gap-1 py-3 bg-venn-error text-white"
    style={{
      paddingLeft: `${MANAGE_DATA_HEADER_HORIZONTAL_PADDING_PX}px`,
      paddingRight: `${MANAGE_DATA_HEADER_HORIZONTAL_PADDING_PX}px`,
    }}
  >
    <Icon type="triangle-exclamation" className="text-white" iconLabel="Banner historical error icon" />
    Portfolio cannot be analyzed. Please resolve errors by uploading missing data or adding a proxy.
    {/* todo: follow up on VENN-28619 to update href link */}
    <a
      className="text-white font-bold"
      href={HISTORICAL_PORTFOLIOS_HELP_HREF}
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn more
    </a>
  </div>
);
