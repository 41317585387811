import { selectorFamily } from 'recoil';
import { analysis, type RangeDebugResponse } from 'venn-api';
import { requestSubjects } from '../configuration/subjects';

import { type Subject, toSubjectOnly } from '../types';
import { convertStudioSubjectToApiSubject } from '../utils';

/**
 * Wrapper around rangeDebugQueryImpl.
 * It ensures that fees are not included in the request
 * as they don't affect range debug
 */
export const rangeDebugQuery = selectorFamily<RangeDebugResponse | null, Subject>({
  key: 'rangeDebugQuery',
  get:
    (subject) =>
    ({ get }) => {
      return get(rangeDebugQueryImpl(toSubjectOnly(subject)));
    },
});

const rangeDebugQueryImpl = selectorFamily<RangeDebugResponse | null, Subject>({
  key: 'rangeDebugQueryImpl',
  get:
    (subject) =>
    async ({ get }) => {
      const requestSubject = get(requestSubjects([subject]))[0];

      if (requestSubject.private) {
        return null;
      }

      const apiSubject = convertStudioSubjectToApiSubject(requestSubject, 'PRIMARY', true);

      const { content } = await analysis({
        analyses: [
          {
            analysisType: 'RANGE_DEBUG',
            relative: false,
            scenarios: [],
            includeAllPredefinedPeriods: false,
          },
        ],
        subjects: [apiSubject],
      });
      return content.analyses?.[0].rangeDebug?.[0] ?? null;
    },
});
