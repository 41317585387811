import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useBlockId } from 'venn-components';
import { blockFonts } from 'venn-state';
import { StudioHighchart } from '../../../highchart/Highchart';
import { getAreaChartOptions } from './line-chart/utils';
import type { AreaChartProps } from './types';

const AreaChart = ({
  theme,
  series,
  yAxisUnitFormat,
  frequency,
  inPrintMode,
  height,
  stackingEnabled,
}: AreaChartProps) => {
  const blockId = useBlockId();

  const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
  const axisFont = useRecoilValue(blockFonts.blockChartAxis(blockId));

  const options = useMemo(() => {
    return getAreaChartOptions({
      series,
      theme,
      yAxisUnitFormat,
      frequency,
      inPrintMode,
      height,
      stackingEnabled,
      legendFontSize: legendFont.fontSizePt,
      axisFontSize: axisFont.fontSizePt,
    });
  }, [
    axisFont.fontSizePt,
    frequency,
    height,
    inPrintMode,
    legendFont.fontSizePt,
    series,
    stackingEnabled,
    theme,
    yAxisUnitFormat,
  ]);

  return <StudioHighchart options={options} blockId={blockId} />;
};

export default AreaChart;
