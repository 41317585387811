import React, { useMemo } from 'react';
import styled from 'styled-components';
import type { ProxyTypeEnum } from 'venn-api';
import { DropMenu, TooltipPosition } from 'venn-ui-kit';
import { getFormattedProxyType, getProxyTypeDescription } from 'venn-utils';
import type { ReturnsRangeInfo } from './utils';
import { useProxyTypeValidation } from './useProxyTypeValidation';
import { useProxyTypes } from './proxy-types';

import type { FundToBulkProxy, FundToProxy } from '../types';

interface ProxyTypeOptionsProps {
  selectedProxyType: ProxyTypeEnum | undefined;
  onSelectProxyType: (value: ProxyTypeEnum) => void;
  investments: (FundToProxy | FundToBulkProxy)[];
  /** Unproxied returns info of the investment */
  rawInvestmentRanges?: (ReturnsRangeInfo | null)[];
  /** Unproxied returns info of the selected proxy, if any */
  selectedProxyRange: ReturnsRangeInfo | undefined | null;
  /** Whether to show invalid proxy types of the given investments */
  showInvalidTypes?: boolean;
}

const ProxyTypeOptions = ({
  selectedProxyType,
  onSelectProxyType,
  investments,
  rawInvestmentRanges,
  selectedProxyRange,
  showInvalidTypes = false,
}: ProxyTypeOptionsProps) => {
  const proxyTypeValidationResults = useProxyTypeValidation(investments, rawInvestmentRanges ?? [], selectedProxyRange);

  const proxyTypes = useProxyTypes();

  const items = useMemo(() => {
    const isValid = (proxyType: ProxyTypeEnum) => !proxyTypeValidationResults[proxyType].hasOnlyErrors;
    const getFirstMessage = (proxyType: ProxyTypeEnum) =>
      proxyTypeValidationResults[proxyType].investmentInfo.find((ii) => !!ii.disabledMessage)?.disabledMessage;

    return proxyTypes
      .filter((type) => (showInvalidTypes ? true : isValid(type)))
      .map((proxyType) => {
        const error = getFirstMessage(proxyType);
        const disabled = !isValid(proxyType);
        return {
          label: getFormattedProxyType(proxyType),
          description: disabled ? error : getProxyTypeDescription(proxyType),
          value: proxyType,
          tooltipContent: disabled ? undefined : error,
          disabled,
          tooltipPosition: TooltipPosition.Right,
          className: `qa-proxy-option-${proxyType.toLowerCase()}`,
        };
      });
  }, [proxyTypeValidationResults, proxyTypes, showInvalidTypes]);

  return (
    <Container>
      <ProxyContainer data-testid="qa-proxy-dropmenu">
        <ProxyTypeContainer>Proxy Type</ProxyTypeContainer>
        <DropMenu
          width={showInvalidTypes ? 350 : 306}
          height={400}
          items={items}
          selected={selectedProxyType}
          onChange={(item) => onSelectProxyType(item.value)}
          minimumItemsToTrigger={1}
          placeholder="Proxy type"
          newStyle
        />
      </ProxyContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

const ProxyContainer = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  .simple-menu-item {
    font-size: 16px;
  }
  .simple-menu-item-description {
    font-size: 12px;
    line-height: 16px;
  }
`;

const ProxyTypeContainer = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

export default ProxyTypeOptions;
