import {
  type BulkManageAxisRow,
  type BulkManageRow,
  type BulkManageFactorRow,
  type BulkManageFundRow,
  type HistoricalAllocation,
  type BulkManageInvestmentRow,
  type OldBulkManageRow,
  type BulkManagePortfolioRow,
  type BulkManageStrategyRow,
  type ProFormaAllocation,
  type BulkManageProFormaTotalRow,
  type BulkManageHistoricalTotalRow,
  type HistoricalError,
  type HistoricalWarning,
  HistoricalErrorValues,
} from './types';
import type { HistoricalSubjectRangeAnalysisErrorEnum } from 'venn-api';

export const isBulkManageInvestmentRow = (row?: BulkManageRow): row is BulkManageInvestmentRow => {
  return row?.rowType === 'investment';
};

export const isBulkManageFundRow = (row?: BulkManageRow): row is BulkManageFundRow => {
  return isBulkManageInvestmentRow(row) && row.investmentType === 'fund';
};

export const isBulkManagePortfolioRow = (row?: BulkManageRow): row is BulkManagePortfolioRow => {
  return isBulkManageInvestmentRow(row) && row.investmentType === 'portfolio';
};

export const isBulkManageStrategyRow = (row?: BulkManageRow): row is BulkManageStrategyRow => {
  return isBulkManageInvestmentRow(row) && row.investmentType === 'strategy';
};

export const isBulkManageFactorRow = (row?: BulkManageRow): row is BulkManageFactorRow => {
  return row?.rowType === 'factor';
};

export const isBulkManageAxisRow = (row?: BulkManageRow): row is BulkManageAxisRow => {
  return row?.rowType === 'axis';
};

export const isBulkManageProFormaTotalRow = (row?: BulkManageRow): row is BulkManageProFormaTotalRow => {
  return row?.rowType === 'total' && row?.allocationType === 'proforma';
};
export const isBulkManageHistoricalTotalRow = (row?: BulkManageRow): row is BulkManageHistoricalTotalRow => {
  return row?.rowType === 'total' && row?.allocationType === 'historical';
};
export const isBulkManageTotalRow = (
  row?: BulkManageRow,
): row is BulkManageProFormaTotalRow | BulkManageHistoricalTotalRow => {
  return row?.rowType === 'total';
};

export const isSecondaryInvestment = (row?: BulkManageRow): boolean => {
  return (isBulkManageFundRow(row) || isBulkManagePortfolioRow(row) || isBulkManageTotalRow(row)) && !!row.secondary;
};

export const isHistoricalBulkManageInvestmentRow = (
  row?: BulkManageRow,
): row is BulkManageInvestmentRow & HistoricalAllocation => {
  return isBulkManageInvestmentRow(row) && row.allocationType === 'historical';
};

export const isProFormaBulkManageInvestmentRow = (
  row?: BulkManageRow,
): row is BulkManageInvestmentRow & ProFormaAllocation => {
  return isBulkManageInvestmentRow(row) && row.allocationType === 'proforma';
};

export const isBulkManageRow = (row?: BulkManageRow | OldBulkManageRow): row is BulkManageRow => {
  return !!row && 'rowType' in row;
};

export const rowHasHistoricalError = (
  row?: BulkManageRow,
): row is BulkManageInvestmentRow & HistoricalAllocation & { historicalError: HistoricalError } => {
  return !!row && isHistoricalBulkManageInvestmentRow(row) && row?.historicalError?._type === 'error';
};

export const rowHasHistoricalWarning = (
  row?: BulkManageRow,
): row is BulkManageInvestmentRow & HistoricalAllocation & { historicalError: HistoricalWarning } => {
  return !!row && isHistoricalBulkManageInvestmentRow(row) && row?.historicalError?._type === 'warning';
};

export const isHistoricalError = (
  value?: HistoricalSubjectRangeAnalysisErrorEnum,
): value is HistoricalError['value'] => {
  return !!value && (HistoricalErrorValues as readonly string[]).includes(value);
};
