// TODO(houssein): Remove this file entirely once bulk proxy feature flag is enabled by default and only DataGrid is used in the Bulk Management Table

import React from 'react';
import styled, { css } from 'styled-components';
import type { OldBulkManageRow, CreateUpdateMetadata, OldBulkManageUpdateFn } from './types';
import { BulkManageAction } from './types';
import type { Theme } from 'venn-ui-kit';
import {
  BodyHighlight,
  CellLoader,
  EllipsisTooltipSpan,
  getAppTitle,
  GetColor,
  Icon,
  Tooltip,
  TooltipPosition,
} from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import {
  assertNotNil,
  capitalizeFirstLetter,
  flattenNodeWithStrategyPath,
  FS,
  FundUtils,
  getAnalysisLabels,
  getFormattedFrequency,
  itemHasNoReturns,
  Numbers,
} from 'venn-utils';
import {
  type AnalysisRequest,
  type ComputedInvestmentResidual,
  type Fund,
  type Portfolio,
  type RangeAnalysisResponse,
  type SubjectRangeAnalysis,
} from 'venn-api';
import InvestmentActions from '../investment-actions/InvestmentActions';
import { compact, isNil } from 'lodash';
import type { BasicTableColumn } from '../basictable/BasicTable';
import { SORTDIR } from '../basictable/BasicTable';
import { formatDate, formatDateByFrequency } from '../manage-data/utils';
import ForecastModalLauncherSpanButton from '../modals/forecasts/ForecastModalLauncherSpanButton';
import type { InvestmentOverrideType } from 'venn-state';
import AnalysisPeriodLegend from './AnalysisPeriodLegend';
import { atStartOfDay, deprecatedGetDataColor, getRangeLogic, getRowRangeLogic } from './analysisPeriodUtils';
import ProxiedInvestmentTooltipContent from './ProxiedInvestmentTooltipContent';

const DATE_COLUMN_MIN_WIDTH = 95; // px. Width that prevents dates from breaking onto multiple lines
const PROXY_COLUMN_MIN_WIDTH = 155; // px.  Width that prevents proxies from breaking onto multiple lines
const FORECAST_RESIDUAL_COLUMN_MIN_WIDTH = 65; // px. Width that prevents residuals from breaking onto multiple lines
const FREQUENCY_COLUMN_MIN_WIDTH = 75; // px. Width that allows the sort icon to be displayed on the header

const getCreateUpdateMetadata = (subject?: Fund | Portfolio): CreateUpdateMetadata => ({
  created: subject?.created,
  updated: subject?.updated,
  owner: subject?.owner,
  updatedBy: subject?.updatedBy,
});

const mapPortfolioToRow = (
  name: string,
  portfolio?: Portfolio,
  portfolioRange?: SubjectRangeAnalysis,
  rangeLoading?: boolean,
): OldBulkManageRow => ({
  ...getCreateUpdateMetadata(portfolio),
  name,
  startDate: portfolioRange?.start,
  endDate: portfolioRange?.end,
  historicalStartDate: portfolioRange?.historicalStart,
  historicalEndDate: portfolioRange?.historicalEnd,
  frequency: portfolioRange?.frequency,
  rangeLoading,
  secondary: true,
});

const mapFundToRow = (
  name: string,
  fund?: Fund,
  investmentRange?: SubjectRangeAnalysis,
  rangeLoading?: boolean,
  secondary = true,
  allocation?: number,
  residualForecast?: ComputedInvestmentResidual,
  residualForecastsLoading?: boolean,
): OldBulkManageRow => ({
  ...getCreateUpdateMetadata(fund),
  name,
  investmentId: fund?.id,
  allocation,
  investment: fund,
  dataSource: fund?.dataSource ?? (fund?.userUploaded ? 'Upload' : `${getAppTitle()}`),
  isIntegration: FundUtils.isUserIntegration(fund?.investmentSource),
  isLive: fund?.live,
  proxyType: fund?.proxyType,
  startDate: investmentRange?.start,
  endDate: investmentRange?.end,
  historicalStartDate: investmentRange?.historicalStart,
  historicalEndDate: investmentRange?.historicalEnd,
  proxyStartDate: investmentRange?.proxyStartDate ?? undefined,
  proxyEndDate: investmentRange?.proxyEndDate ?? undefined,
  extrapolateStartDate: investmentRange?.extrapolateStartDate ?? undefined,
  extrapolateEndDate: investmentRange?.extrapolateEndDate ?? undefined,
  frequency: investmentRange?.frequency,
  rangeLoading,
  secondary,
  investmentForecast: residualForecast,
  investmentForecastLoading: residualForecastsLoading,
});

export const getBulkManagementData = (
  subject: AnalysisSubject,
  funds?: Fund[],
  rangeAnalysis?: RangeAnalysisResponse,
  rangeAnalysisRequest?: Partial<AnalysisRequest>,
  secondaryPortfolio?: Portfolio,
  benchmarkPortfolio?: Portfolio,
  residualForecasts?: { [key: string]: ComputedInvestmentResidual },
): OldBulkManageRow[] => {
  const result: OldBulkManageRow[] = [];
  if ((!funds || !funds.length) && !subject.fund) {
    return result;
  }
  const primaryIndex = rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'PRIMARY');
  const primaryRangeAnalysis = !isNil(primaryIndex) ? rangeAnalysis?.rangeAnalyses[primaryIndex] : undefined;
  const rangeAnalyses = primaryRangeAnalysis?.rangeAnalyses ?? [];
  const rangeAnalysesByInvestmentId = new Map(rangeAnalyses.map((a) => [a.investmentId, a]));

  const fundAllocationMap = new Map(
    flattenNodeWithStrategyPath(subject.portfolio)
      .filter((n) => Boolean(n.fund))
      .map((n) => [n.fund?.id, n]),
  );
  const rangeLoading = isNil(rangeAnalysis);
  const residualForecastsLoading = isNil(residualForecasts);

  if (subject.fund) {
    result.push(
      mapFundToRow(
        subject.fund.name,
        subject.fund,
        primaryRangeAnalysis,
        rangeLoading,
        false,
        undefined,
        residualForecasts?.[subject.id],
        residualForecastsLoading,
      ),
    );
  }

  funds?.forEach((fund) => {
    const fundInfo = fundAllocationMap.get(fund.id);
    if (!fundInfo) return;

    result.push(
      mapFundToRow(
        fund.name,
        fund,
        rangeAnalysesByInvestmentId.get(fund.id),
        rangeLoading,
        false,
        fundInfo.allocation,
        residualForecasts?.[fund.id],
        residualForecastsLoading,
      ),
    );
  });

  const labels = getAnalysisLabels(subject.type, subject.secondaryLabel, subject.secondaryPortfolio?.updated);

  if (subject.hasBenchmark && labels.benchmark) {
    const benchmarkIndex = rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'BENCHMARK');
    const benchmarkRange = !isNil(benchmarkIndex) ? rangeAnalysis?.rangeAnalyses[benchmarkIndex] : undefined;
    const benchmarkFund = funds?.find((fund) => fund.id === subject.activeBenchmarkId);

    const name = benchmarkRange?.name ? `${labels.benchmark}: ${benchmarkRange.name}` : labels.benchmark;
    const row: OldBulkManageRow = benchmarkFund
      ? mapFundToRow(name, benchmarkFund, benchmarkRange, rangeLoading)
      : mapPortfolioToRow(name, benchmarkPortfolio, benchmarkRange, rangeLoading);
    row.isBenchmark = true;
    result.push(row);
  }

  if (!FS.has('test_hide_category_ff') && subject.categoryGroup && labels.category) {
    const categoryIndex = rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'CATEGORY');
    const categoryRange = !isNil(categoryIndex) ? rangeAnalysis?.rangeAnalyses[categoryIndex] : undefined;
    const categoryFund = funds?.find((fund) => fund.id === subject.categoryGroup?.categoryId);

    const name = categoryRange?.name ? `${labels.category}: ${categoryRange.name}` : labels.category;

    result.push(mapFundToRow(name, categoryFund, categoryRange, rangeLoading));
  }

  if (subject.hasSecondarySubject && labels.comparison) {
    const secondaryIndex = rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'COMPARISON');
    const secondaryRange = !isNil(secondaryIndex) ? rangeAnalysis?.rangeAnalyses[secondaryIndex] : undefined;

    result.push(mapPortfolioToRow(labels.comparison, secondaryPortfolio, secondaryRange, rangeLoading));
  }

  if (rangeAnalysis?.factorRange) {
    const factorRange = rangeAnalysis.factorRange;
    const row: OldBulkManageRow = {
      name: 'Factors',
      startDate: factorRange.start,
      endDate: factorRange.end,
      historicalStartDate: undefined,
      historicalEndDate: undefined,
      frequency: factorRange.frequency,
      secondary: true,
      rangeLoading,
      dataSource: `${getAppTitle()}`,
    };
    result.push(row);
  }

  return result;
};

export const getBulkManagementColumns = (
  rangeAnalysis: RangeAnalysisResponse | undefined,
  onRowUpdate: OldBulkManageUpdateFn,
  theme: Theme,
  canEditForecasts: boolean,
  canEditProxies: boolean,
  tableContainerWidth?: number,
  sortKey?: string,
  sortDir?: SORTDIR,
  rangeAnalysisRequest?: Partial<AnalysisRequest>,
): BasicTableColumn<OldBulkManageRow>[] => {
  const primaryIndex = rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'PRIMARY');
  const primaryRangeAnalysis = !isNil(primaryIndex) ? rangeAnalysis?.rangeAnalyses[primaryIndex] : null;

  const largeScreen: boolean = !isNil(tableContainerWidth) && tableContainerWidth > 1200;
  const columns = [
    getNameColumn(tableContainerWidth, largeScreen),
    getDataSource(),
    largeScreen && getDateCreated(),
    largeScreen && getCreatedBy(),
    getDateUpdated(),
    largeScreen && getUpdatedBy(),
    getRangeColumn(theme, rangeAnalysis, primaryRangeAnalysis),
    getStartColumn(),
    getEndColumn(),
    getFrequencyColumn(),
    getReturnForecastColumn(canEditForecasts, onRowUpdate),
    getResidualForecastColumn(canEditForecasts, onRowUpdate),
    getProxyInterpolationColumn(canEditProxies, onRowUpdate),
  ];

  return compact(columns).map((column) => ({
    ...column,
    accessor: column.label,
    sortingIsExternal: true,
    sorted: sortKey === column.label ? sortDir : undefined,
  }));
};

export const getSortTableFunc = (accessor?: string) => {
  switch (accessor) {
    case 'Name':
      return bulkManageSortFunction((r) => r.name);
    case 'Data Source':
      return bulkManageSortFunction((r) => r.dataSource);
    case 'Date Created':
      return bulkManageSortFunction((r) => r.created);
    case 'Date Updated':
      return bulkManageSortFunction((r) => r.updated);
    case 'Created By':
      return bulkManageSortFunction((r) => r.owner?.displayName);
    case 'Analysis Period':
      return bulkManageSortFunction((r) => (r.endDate ?? 0) - (r.startDate ?? 0)); // Sort by range length
    case 'Start Date':
      return bulkManageSortFunction((r) => r.startDate);
    case 'End Date':
      return bulkManageSortFunction((r) => r.endDate);
    case 'Frequency':
      return bulkManageSortFunction((r) => r.frequency);
    case 'Forecast Residual':
      return bulkManageSortFunction(
        (r) => r.investmentForecast?.computedResidual ?? r.investmentForecast?.defaultResidual,
        (r) => isNil(r.investmentForecast?.overriddenResidual) && isNil(r.investmentForecast?.defaultResidual),
      );
    case 'Forecast Return':
      return bulkManageSortFunction(
        (r) => r.investmentForecast?.computedReturn ?? r.investmentForecast?.defaultReturn,
        (r) => isNil(r.investmentForecast?.overriddenReturn) && isNil(r.investmentForecast?.defaultReturn),
      );
    default:
      return undefined;
  }
};

const getNameColumn = (tableContainerWidth = 0, largeScreen: boolean): BasicTableColumn<OldBulkManageRow> => {
  const widthForOtherColumns = largeScreen ? 1100 : 885;
  const maxWidth = Math.min(250, Math.max(120, tableContainerWidth - widthForOtherColumns));
  return {
    cellRenderer: (data) => (
      <NameCell italic={Boolean(data.secondary)}>
        <EllipsisTooltipSpan maxWidth={maxWidth}>{data.name}</EllipsisTooltipSpan>
      </NameCell>
    ),
    label: 'Name',
    sortable: true,
    headerStyle: {
      minWidth: '120px',
      maxWidth: `${maxWidth}px`,
    },
  };
};

const getDataSource = (): BasicTableColumn<OldBulkManageRow> => {
  return {
    cellRenderer: (data) => (
      <PaddedCell disabled={data.isLive === false}>
        {data.dataSource} {data.isIntegration && <Icon type="exchange" prefix="fas" />}
      </PaddedCell>
    ),
    label: 'Data Source',
    sortable: true,
    arrowStyle: {
      right: '-3px',
    },
  };
};

const getDateCreated = (): BasicTableColumn<OldBulkManageRow> => {
  return {
    cellRenderer: (data) => <PaddedCell>{formatDate(data.created)}</PaddedCell>,
    label: 'Date Created',
    sortable: true,
    headerStyle: { minWidth: `${DATE_COLUMN_MIN_WIDTH}px` },
  };
};

const getDateUpdated = (): BasicTableColumn<OldBulkManageRow> => {
  return {
    cellRenderer: (data) => <PaddedCell>{formatDate(data.updated)}</PaddedCell>,
    label: 'Date Updated',
    sortable: true,
    headerStyle: { minWidth: `${DATE_COLUMN_MIN_WIDTH}px` },
  };
};

const getCreatedBy = (): BasicTableColumn<OldBulkManageRow> => {
  return {
    cellRenderer: (data) => <PaddedCell>{data.owner?.displayName}</PaddedCell>,
    label: 'Created By',
    sortable: true,
  };
};

const getUpdatedBy = (): BasicTableColumn<OldBulkManageRow> => {
  return {
    cellRenderer: (data) => <PaddedCell>{data.updatedBy?.displayName}</PaddedCell>,
    label: 'Updated By',
    sortable: true,
  };
};

const getFrequencyColumn = (): BasicTableColumn<OldBulkManageRow> => {
  const label = 'Frequency';
  return {
    label,
    cellRenderer: (data: OldBulkManageRow) => {
      if (data.rangeLoading) {
        return (
          <PaddedCell>
            <CellLoader />
          </PaddedCell>
        );
      }
      if (itemHasNoReturns(data.startDate, data.endDate)) {
        return getNoReturnsTooltippedCell(data, 'N/A');
      }
      return <PaddedCell>{capitalizeFirstLetter(getFormattedFrequency(data.frequency!))}</PaddedCell>;
    },
    sortable: true,
    headerStyle: {
      minWidth: `${FREQUENCY_COLUMN_MIN_WIDTH}px`,
      maxWidth: `${FREQUENCY_COLUMN_MIN_WIDTH}px`,
    },
  };
};

const getForecastColumn = (
  canEditForecasts: boolean,
  onUpdate: OldBulkManageUpdateFn,
  getData: (c: ComputedInvestmentResidual) => {
    computedDataField: number | undefined;
    defaultDataField: number | undefined;
    isDataOverridden: boolean;
  },
  label: string,
  overrideType: InvestmentOverrideType,
): BasicTableColumn<OldBulkManageRow> => {
  return {
    label,
    cellRenderer: (data: OldBulkManageRow) => {
      const { investmentForecastLoading, investmentForecast } = data;
      if (investmentForecastLoading) {
        return (
          <PaddedCell>
            <CellLoader />
          </PaddedCell>
        );
      }
      if (!investmentForecast) {
        return <ForecastInvestmentCell />;
      }
      const { computedDataField, defaultDataField, isDataOverridden } = getData(investmentForecast);
      const computedData = isDataOverridden ? (
        <StyledBodyHighlight>{Numbers.safeFormatPercentage(computedDataField, 1)}</StyledBodyHighlight>
      ) : (
        Numbers.safeFormatPercentage(computedDataField, 1)
      );
      return (
        <ForecastInvestmentCell>
          <>
            {!isNil(computedDataField) ? (
              computedData
            ) : (
              <StyledResidualSpan useRightMargin={!defaultDataField}>
                {Numbers.safeFormatPercentage(defaultDataField, 1)}
              </StyledResidualSpan>
            )}
            <ForecastModalLauncherSpanButton
              ctaPurpose="Edit forecasts from Manage Data page row"
              onResidualForecastUpdated={(targetId) =>
                onUpdate(BulkManageAction.INVESTMENT_FORECAST_MODIFIED, data, targetId)
              }
              onOpenResidualForecastTarget={investmentForecast}
              isReadOnly={!canEditForecasts}
              overrideType={overrideType}
            />
          </>
        </ForecastInvestmentCell>
      );
    },
    sortable: true,
    headerStyle: {
      minWidth: `${FORECAST_RESIDUAL_COLUMN_MIN_WIDTH}px`,
      maxWidth: `${FORECAST_RESIDUAL_COLUMN_MIN_WIDTH}px`,
    },
    arrowStyle: {
      right: '-3px',
    },
  };
};

const getResidualForecastColumn = (
  canEditForecasts: boolean,
  onUpdate: OldBulkManageUpdateFn,
): BasicTableColumn<OldBulkManageRow> => {
  return getForecastColumn(
    canEditForecasts,
    onUpdate,
    (c) => ({
      computedDataField: c.computedResidual,
      defaultDataField: c.defaultResidual,
      isDataOverridden: !isNil(c.overriddenResidual),
    }),
    'Forecast Residual',
    'residual',
  );
};

const getReturnForecastColumn = (
  canEditForecasts: boolean,
  onUpdate: OldBulkManageUpdateFn,
): BasicTableColumn<OldBulkManageRow> => {
  return getForecastColumn(
    canEditForecasts,
    onUpdate,
    (c) => ({
      computedDataField: c.computedReturn,
      defaultDataField: c.defaultReturn,
      isDataOverridden: !isNil(c.overriddenReturn),
    }),
    'Forecast Return',
    'return',
  );
};

const getProxyInterpolationColumn = (
  canEditProxies: boolean,
  onUpdate: OldBulkManageUpdateFn,
): BasicTableColumn<OldBulkManageRow> => {
  return {
    cellRenderer: (data, index, count) => {
      if (!data.investment) {
        return null;
      }
      // A little hacky but we want to flip the pickers up if they are too close to the bottom of the page
      const dropdownDirection = count > 10 && index > count / 2 ? 'up' : 'down';
      return (
        <PaddedCell>
          <InvestmentActions
            fund={data.investment}
            context="bulk-management"
            onFundDataUpdated={() => onUpdate(BulkManageAction.FUND_MODIFIED, data, data.investmentId)}
            dropdownVerticalDirection={dropdownDirection}
            isReadOnly={!canEditProxies}
          />
        </PaddedCell>
      );
    },
    label: 'Proxy',
    headerStyle: { minWidth: `${PROXY_COLUMN_MIN_WIDTH}px` },
  };
};

const getStartColumn = (): BasicTableColumn<OldBulkManageRow> => {
  return {
    cellRenderer: (data) => {
      const date = data.historicalStartDate ?? data.startDate;
      return <PaddedCell>{date ? formatDate(date) : ''}</PaddedCell>;
    },
    label: 'Start Date',
    sortable: true,
    headerStyle: { minWidth: `${DATE_COLUMN_MIN_WIDTH}px` },
  };
};

const getEndColumn = (): BasicTableColumn<OldBulkManageRow> => {
  return {
    cellRenderer: (data) => {
      const date = data.historicalEndDate ?? data.endDate;
      return <PaddedCell>{date ? formatDate(date) : ''}</PaddedCell>;
    },
    label: 'End Date',
    sortable: true,
    headerStyle: { minWidth: `${DATE_COLUMN_MIN_WIDTH}px` },
  };
};

const getRangeColumn = (
  theme: Theme,
  rangeAnalysis?: RangeAnalysisResponse,
  primaryRangeAnalysis?: SubjectRangeAnalysis | null,
): BasicTableColumn<OldBulkManageRow> => {
  if (!rangeAnalysis) {
    return {
      label: 'Analysis Period',
      cellRenderer: () => null,
    };
  }
  const { earliestStart, latestEnd, fullRange, overlap } = getRangeLogic(false, rangeAnalysis, primaryRangeAnalysis);
  return {
    label: 'Analysis Period',
    headerRenderer: () => <AnalysisPeriodLegend />,
    headerStyle: {
      minWidth: '230px',
    },
    sortable: true,
    footerRenderer: () => {
      return (
        <RangeFooter>
          <div>{formatDateByFrequency(earliestStart, rangeAnalysis.frequency)}</div>
          <div>{formatDateByFrequency((earliestStart + latestEnd) / 2, rangeAnalysis.frequency)}</div>
          <div>{formatDateByFrequency(latestEnd, rangeAnalysis.frequency)}</div>
        </RangeFooter>
      );
    },
    cellRenderer: (data) => {
      if (data.rangeLoading) {
        return (
          <PaddedCell>
            <CellLoader />
          </PaddedCell>
        );
      }
      if (itemHasNoReturns(data.startDate, data.endDate)) {
        return getNoReturnsTooltippedCell(data, <TooltipTrigger />);
      }
      // if item has returns, startDate, endDate and frequency are valid
      const frequency = assertNotNil(data.frequency);
      const startDate = assertNotNil(atStartOfDay(data.startDate));
      const endDate = assertNotNil(data.endDate);
      const zeroAllocation = data.allocation === 0;

      const { investmentColor, proxyColor } = deprecatedGetDataColor({
        theme,
        isGreyedOut: zeroAllocation,
        secondaryData: data?.secondary ?? false,
      });

      const { proxy, investment, extrapolation } = getRowRangeLogic({
        startDate,
        endDate,
        fullRange,
        earliestStart,
        latestEnd,
        proxyStartDate: atStartOfDay(data.proxyStartDate),
        proxyEndDate: data.proxyEndDate,
        extrapolateEndDate: data.extrapolateEndDate,
        extrapolateStartDate: atStartOfDay(data.extrapolateStartDate),
      });
      const dashedRangeBar = frequency === 'QUARTERLY' || frequency === 'YEARLY';
      return (
        <RangeCell>
          {overlap.percentageWidth > 0 ? (
            <OverlapBar widthPercentage={overlap.percentageWidth} leftMarginPercentage={overlap.percentageStart} />
          ) : null}
          <RangeBar
            widthPercentage={investment.percentageWidth}
            leftMarginPercentage={investment.percentageStart}
            dashed={dashedRangeBar}
            color={investmentColor}
          >
            <StyledTooltip
              position={TooltipPosition.Top}
              maxWidth={400}
              content={<ProxiedInvestmentTooltipContent data={data} />}
            >
              {proxy.percentageWidth > 0 && (
                <RangeBar
                  widthPercentage={proxy.percentageWidth}
                  leftMarginPercentage={proxy.percentageStart}
                  color={proxyColor}
                  dashed={dashedRangeBar}
                />
              )}
              {extrapolation.percentageWidth > 0 && (
                <RangeBar
                  widthPercentage={extrapolation.percentageWidth}
                  leftMarginPercentage={extrapolation.percentageStart}
                  color={theme.Colors.DEPRECATED_DataBarColor.LightPaleBlue}
                  dashed={false}
                />
              )}
            </StyledTooltip>
          </RangeBar>
        </RangeCell>
      );
    },
  };
};

/** Simple cell with tooltip indicating content is empty due to no returns */
const getNoReturnsTooltippedCell = (data: OldBulkManageRow, cellContent: React.ReactNode) => {
  return (
    <PaddedCell>
      <StyledTooltip maxWidth={240} content={<div>This item does not have a return stream.</div>}>
        {cellContent}
      </StyledTooltip>
    </PaddedCell>
  );
};

const PaddedCell = styled.div<{ disabled?: boolean }>`
  padding: 6px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${GetColor.HintGrey};
    `}
  & > span {
    display: flex;
  }
`;

const NameCell = styled.div<{ italic: boolean }>`
  ${(props) => (props.italic ? 'font-style: italic;' : '')}
  padding: 6px;
  padding-left: 20px;
  & > span {
    display: flex;
  }
`;

const StyledTooltip = styled(Tooltip)`
  width: 100%;
  height: 100%;
  display: block;
`;

/* Internal cell padding to trigger tooltips to show on no text */
const TooltipTrigger = styled.div`
  height: 16px;
  width: 100%;
`;

const RangeCell = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
`;

const RangeBar = styled.div<{
  widthPercentage: number;
  leftMarginPercentage: number;
  dashed?: boolean;
  color: string;
}>`
  ${({ dashed, widthPercentage, leftMarginPercentage, color }) => `
    background: ${
      dashed ? `repeating-linear-gradient(to right, ${color} 0, ${color} 8px, transparent 8px,transparent 16px)` : color
    };
    height: 8px;
    width: ${widthPercentage * 100}%;
    margin-left: ${leftMarginPercentage * 100 ?? 0}%;
    padding: 0;
    top: calc(50% - 4px);
    position: absolute;
    `}
`;

const RangeFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OverlapBar = styled.div<{
  widthPercentage: number;
  leftMarginPercentage: number;
}>`
  background-color: ${GetColor.PaleTeal};
  height: 100%;
  width: ${({ widthPercentage }) => widthPercentage * 100}%;
  margin-left: ${({ leftMarginPercentage }) => leftMarginPercentage * 100 ?? 0}%;
  padding: 0;
  top: 0;
  opacity: 0.5;
  border-left: 1px solid ${GetColor.LightGrey};
  border-right: 1px solid ${GetColor.LightGrey};
  position: absolute;
`;

const StyledBodyHighlight = styled(BodyHighlight)`
  font-weight: bold;
`;

const ForecastInvestmentCell = styled(PaddedCell)`
  display: flex;
  justify-content: flex-end;

  > span {
    margin-left: auto;
  }
`;

const StyledResidualSpan = styled.span<{ useRightMargin?: boolean }>`
  ${({ useRightMargin }) =>
    useRightMargin &&
    css`
      margin-right: 5px;
    `}
`;

/* Convenience method to sort rows by a property while ensuring that excluded or secondary rows are always last. */
const bulkManageSortFunction =
  (getter: (r: OldBulkManageRow) => number | string | undefined, exclude?: (r: OldBulkManageRow) => boolean) =>
  (rows: OldBulkManageRow[], dir: SORTDIR) => {
    /* Always sort secondary rows after all non-excluded rows */
    const isSecondary = (r: OldBulkManageRow) => r.secondary ?? false;

    return rows.sort(
      (a, b) =>
        compareExclude(a, b, exclude) ?? compareExclude(a, b, isSecondary) ?? compare(getter(a), getter(b), dir),
    );
  };

/* Ensures that rows marked as excluded are always after non-excluded rows. */
const compareExclude = (a: OldBulkManageRow, b: OldBulkManageRow, exclude?: (r: OldBulkManageRow) => boolean) => {
  if (exclude?.(a) && exclude?.(b)) {
    return 0;
  }
  if (exclude?.(a)) {
    return 1;
  }
  if (exclude?.(b)) {
    return -1;
  }
  return undefined;
};

const compare = <T extends (number | undefined) | (string | undefined)>(a: T, b: T, dir: SORTDIR) => {
  if (a === b) {
    return 0;
  }
  return ((a ?? 0) > (b ?? 0) ? 1 : -1) * (dir === SORTDIR.DESC ? -1 : 1);
};
