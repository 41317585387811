import { useRecoilCallback, useRecoilValue } from 'recoil';
import type { AnalysisSubject } from 'venn-utils';
import { flattenNode, mapPortfolioIdsToNewPortfolioIds } from 'venn-utils';
import type { Portfolio } from 'venn-api';
import { subjectInputGroups, subjectInputGroupSubjects } from 'venn-state';
import { isNil, mapKeys, omitBy, pickBy } from 'lodash';
import { TreeItemUpdateType } from '../AllocationUtils';
import { useCallback } from 'react';

/**
 * Returns a callback to sync the feesMapping of all subjects in report lab with allocation panel updates
 */
export const useSyncFees = () => {
  const inputGroups = useRecoilValue(subjectInputGroups);

  /**
   * Updates feesMapping's for save and save as events.
   * In this case, currentPortfolio and newPortfolio will always have the same structure, but newPortfolio will contain
   * the new portfolio ids after saving.
   */
  const onSavePortfolio = useRecoilCallback(
    ({ set }) =>
      (currentPortfolio: Portfolio, newPortfolio: Portfolio) => {
        const idMap = mapPortfolioIdsToNewPortfolioIds(currentPortfolio, newPortfolio);

        inputGroups.forEach((inputGroup) => {
          set(subjectInputGroupSubjects(inputGroup), (current) =>
            current.map((subject) => {
              if (subject.portfolioId !== currentPortfolio.id) {
                return subject;
              }
              const newFeesMapping = omitBy(
                mapKeys(subject.feesMapping, (_value, key) => {
                  return idMap.get(Number(key));
                }),
                (_value, key) => isNil(key),
              );
              return { ...subject, feesMapping: newFeesMapping };
            }),
          );
        });
      },
    [inputGroups],
  );

  /**
   * Updates feesMapping's for events that could lead to items being removed (fund or strategy removal, or resets).
   * In this case, we must remove any portfolio ids from feesMapping that are no longer present
   */
  const onRemoveItems = useRecoilCallback(
    ({ set }) =>
      (portfolio: Portfolio) => {
        const portfolioNodes = flattenNode(portfolio);
        const portfolioIds = new Set(portfolioNodes.map(({ id }) => id.toString()));

        inputGroups.forEach((inputGroup) => {
          set(subjectInputGroupSubjects(inputGroup), (current) =>
            current.map((subject) => {
              if (subject.portfolioId !== portfolio.id) {
                return subject;
              }
              const newFeesMapping = pickBy(subject.feesMapping, (_value, key) => portfolioIds.has(key));
              return { ...subject, feesMapping: newFeesMapping };
            }),
          );
        });
      },
    [inputGroups],
  );

  const syncFees = useCallback(
    (currentSubject: AnalysisSubject | undefined, newPortfolio: Portfolio, updateType: TreeItemUpdateType) => {
      const currentPortfolio = currentSubject?.portfolio;

      if (!currentPortfolio) return;

      switch (updateType) {
        case TreeItemUpdateType.SAVE:
          onSavePortfolio(currentPortfolio, newPortfolio);
          return;
        case TreeItemUpdateType.RESET:
        case TreeItemUpdateType.DELETE_PORTFOLIO:
        case TreeItemUpdateType.DELETE_PORTFOLIO_CHILD:
          onRemoveItems(newPortfolio);
      }
    },
    [onSavePortfolio, onRemoveItems],
  );

  return { syncFees };
};
