import React from 'react';

import { DESMOOTHING_HELP_HREF } from 'venn-ui-kit';
import type { ProxyTypeEnum, DetailedProxyMetadata } from 'venn-api';

import LagInput from './LagInput';
import { typeSupportsDesmooth } from './utils';
import type { FundToBulkProxy, FundToProxy } from '../types';

export function DesmoothingLag({
  investments,
  selectedProxyType,
  numLags,
  suggestedNumLags,
  onLagUpdate,
  proxyData,
}: {
  investments: (FundToProxy | FundToBulkProxy)[];
  selectedProxyType?: ProxyTypeEnum;
  numLags?: number;
  suggestedNumLags?: number;
  onLagUpdate: (numLags: number) => void;
  proxyData?: DetailedProxyMetadata;
}) {
  const singleProxy = investments.length === 1;
  const customNumLags = numLags || suggestedNumLags;

  if (!selectedProxyType || !typeSupportsDesmooth(selectedProxyType)) {
    return null;
  }

  if (singleProxy) {
    return (
      <LagInput
        frequency={proxyData?.unproxiedFrequency ?? investments[0].returnFrequency}
        onLagUpdate={onLagUpdate}
        suggestedNumLags={suggestedNumLags}
        numLags={customNumLags ?? 0}
      />
    );
  }

  return (
    <div className="flex flex-shrink flex-col gap-2 text-[14px]">
      <span className="font-bold">Desmoothing Lag</span>
      <span className="text-venn-grey-80 text-balance leading-normal">
        Venn will automatically calculate a lag for each investment once a proxy is chosen. View applied lags on the
        Portfolio Management page. To apply custom lags, proxy one investment at a time.{' '}
        <a
          className="text-venn-dark-blue font-bold"
          href={DESMOOTHING_HELP_HREF}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </span>
    </div>
  );
}
