import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import UniversalUploaderContext from '../../../contexts/universal-uploader';
import { DataUploaderMode } from '../types';
import FileLoading from './FileLoading';
import NavsSample from './images/paste/navSample.png';
import ReturnSample from './images/paste/returnSample.png';

export interface PasteInputProps {
  mode: DataUploaderMode;
  isUploading?: boolean;

  onPaste(data: Blob): unknown;
}

const getPlaceholderLabel = (mode: DataUploaderMode) => {
  switch (mode) {
    case DataUploaderMode.Returns:
    case DataUploaderMode.NewNavs:
      return 'Paste data in any format here...';
    case DataUploaderMode.Portfolios:
      return 'Paste data in Venn-provided format';
    default:
      // Other modes don't support pasting
      return '';
  }
};

const getPlaceholderContent = (mode: DataUploaderMode, refreshedStyling: boolean) => {
  switch (mode) {
    case DataUploaderMode.Returns:
      return refreshedStyling ? (
        <SampleTable
          styling={{
            borderSpacing: 40,
            fontSize: 14,
          }}
          columns={[
            {
              name: 'Date',
              align: 'left-align',
            },
            {
              name: 'Manager 1',
              align: 'right-align',
            },
            {
              name: 'Manager 2',
              align: 'right-align',
            },
            {
              name: 'Manager 3',
              align: 'right-align',
            },
          ]}
          rows={[
            ['1/31/2019', '-0.60%', '1.10%', '-2.30%'],
            ['2/28/2019', '0.70%', '0.50%', '1.60%'],
            ['3/31/2019', '2.40%', '1.20%', '0.80%'],
          ]}
        />
      ) : (
        <StyledImage src={ReturnSample} alt="paste data format" />
      );
    case DataUploaderMode.NewNavs:
      // NAVs is not migrated to universal uploader and doesn't have refreshed styling
      return <StyledImage src={NavsSample} alt="paste data format" />;
    case DataUploaderMode.Portfolios:
      return (
        <SampleTable
          styling={{
            borderSpacing: 20,
            fontSize: 12,
          }}
          columns={[
            {
              name: 'Portfolio Name',
              align: 'left-align',
            },
            {
              name: 'Strategy',
              align: 'left-align',
            },
            {
              name: 'Sub-Strategy',
              align: 'left-align',
            },
            {
              name: 'ISIN',
              align: 'left-align',
            },
            {
              name: 'Ticker',
              align: 'left-align',
            },
            {
              name: 'Investment Name',
              align: 'left-align',
            },
            {
              name: 'Allocations ($)',
              align: 'right-align',
            },
          ]}
          rows={[
            ['Portfolio 1', 'Equity', 'US Equity', '', '', 'Investment 1', '$31.25'],
            ['Portfolio 1', 'Equity', 'US Equity', '', 'TICKER2', '', '$15.02'],
            ['Portfolio 1', 'Equity', 'Global Equity', 'US1234567890', '', '', '$14.21'],
            ['Portfolio 2', 'Fixed Income', '', '', '', 'Investment 4', '$98.23'],
            ['Portfolio 2', 'Real Estate', '', '', '', 'Investment 5', '$21.00'],
          ]}
        />
      );
    default:
      return '';
  }
};

type SampleTableColumn = Readonly<{ name: string; align: 'left-align' | 'right-align' }>;
type SampleTableRow = string[];
type SampleTableStyling = Readonly<{ borderSpacing: number; fontSize: number }>;
type SampleTableProps = Readonly<{ columns: SampleTableColumn[]; rows: SampleTableRow[]; styling: SampleTableStyling }>;

const SampleTable = ({ columns, rows, styling }: SampleTableProps) => {
  return (
    <SampleTableWrapper styling={styling}>
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th className={column.align}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr>
              {row.map((cell, index) => (
                <td className={columns[index].align}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </SampleTableWrapper>
  );
};

export const PasteInput = ({ isUploading, onPaste, mode }: PasteInputProps) => {
  const [inputNode, setInputNode] = useState<HTMLTextAreaElement | null>(null);
  const { refreshedStyling } = useContext(UniversalUploaderContext);
  const setRef = useCallback(
    // @ts-expect-error: fixme
    (node) => {
      setInputNode(node);
    },
    [],
  );

  useEffect(() => {
    setText('');
    if (inputNode) {
      if (isUploading) {
        inputNode.blur();
      } else {
        inputNode.focus();
      }
    }
  }, [isUploading, inputNode]);

  const [text, setText] = useState('');
  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text/plain');
    const data = new Blob([pastedText], { type: 'text/plain' });
    onPaste(data);
  };

  return (
    <StyledPasteInput data-testid="paste-input">
      {isUploading && (
        <LoadingContainer>
          <FileLoading label="Pasted data" />
        </LoadingContainer>
      )}
      {!text && !isUploading && (
        <Hint>
          <div>{getPlaceholderLabel(mode)}</div>
          {getPlaceholderContent(mode, refreshedStyling)}
        </Hint>
      )}
      <StyledTextArea
        refreshedStyling={refreshedStyling}
        onPaste={(e) => handlePaste(e)}
        value={text}
        onChange={(e) => setText(e.target.value)}
        ref={setRef}
      />
    </StyledPasteInput>
  );
};

export default PasteInput;

const StyledPasteInput = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const fontStyles = css`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
`;

const StyledTextArea = styled.textarea<{ refreshedStyling: boolean }>`
  ${fontStyles};
  width: 100%;
  height: 100%;
  border: 1px solid ${GetColor.MidGrey2};

  :focus {
    border: 1px solid ${(props) => (props.refreshedStyling ? GetColor.DarkBlue : GetColor.Primary.Dark)};
  }

  color: ${GetColor.Black};
  resize: none;
`;

// Make sure that the hint text aligns with the textarea cursor,
// and that the hint is not selectable
const Hint = styled.div`
  ${fontStyles};
  position: absolute;
  padding: 6px 11px; // default padding of the textarea + 1 (for the border)
  color: ${GetColor.HintGrey};
  pointer-events: none;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const StyledImage = styled.img`
  width: 60%;
  display: block;
`;

const LoadingContainer = styled.div`
  ${fontStyles};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const SampleTableWrapper = styled.div<{ styling: SampleTableStyling }>`
  font-size: ${(props) => props.styling.fontSize}px;
  font-family: 'Roboto Mono', monospace;

  table {
    border-collapse: separate;
    border-spacing: ${(props) => props.styling.borderSpacing}px 0;
    margin-left: -${(props) => props.styling.borderSpacing}px;
  }

  th.right-align,
  td.right-align {
    text-align: right;
  }

  th.left-align,
  td.left-align {
    text-align: left;
  }
`;
