import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {
  analyticsService,
  getCreatedByTrackingField,
  LibraryFilterSectionKey,
  LibraryItemType,
  scrollToElementByQuerySelector,
  useApi,
} from 'venn-utils';
import styled from 'styled-components';
import { globalSearch, LIBRARY_NEW_USER_KEY } from 'venn-api';
import type { SORTDIR } from 'venn-components';
import { TagsContext, UserContext, useTags } from 'venn-components';
import type { AdvancedFilterValue, SearchResultWithUIState, SearchState } from './components';
import {
  BulkActionsIcons,
  getId,
  getItemType,
  getSortDirection,
  PAGE_SIZE,
  search,
  searchStateZero,
  toTrackingFormat,
  useLibraryStateURLSynchronizer,
  AdvancedFilterType,
} from './components';

import LibraryLayout, { LIBRARY_CONTENT_SELECTOR } from './LibraryLayout';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import type { FilterLocation } from './components/filters/search';
import type { SearchParams } from 'venn-state';
import { DEFAULT_PARAMS_V2, libraryFiltersStateSelector } from 'venn-state';
import { LibraryTableAnnotation } from './LibraryTableAnnotation';
import { useReturnsLibraryState } from './components/useLibraryState';
import { getFilterPills } from './components/logic/filterPills';
import { useRecoilValue } from 'recoil';
import { useLoadSavedFilters } from './components/filters/components/ManageSavedFilters';
import type * as H from 'history';

const SIDE_MENU_KEYS = Object.values(LibraryFilterSectionKey);
const canResetFilters = (librarySearchParams: SearchParams) => {
  return !isEqual(pick(librarySearchParams, SIDE_MENU_KEYS), pick(DEFAULT_PARAMS_V2, SIDE_MENU_KEYS));
};

const ReturnsDataLibrary = ({ history }: RouteComponentProps) => {
  const { settings, updateSettings } = useContext(UserContext);
  const isStateZero = useRef<boolean>(settings?.user[LIBRARY_NEW_USER_KEY] ?? false);
  const [searchState, setSearchState] = useState<SearchState>({
    results: [],
    totalCount: 0,
    loading: true,
  });

  const tagsContext = useTags();
  const searchApi = useApi(globalSearch);
  const pageLoaded = useRef(false);
  const isFirstLoad = useRef(true);
  const latestFilterLocation = useRef<FilterLocation>('library');
  useLibraryStateURLSynchronizer(
    history as H.History<{ shouldListenerIgnore?: boolean | undefined }>,
    pageLoaded,
    isStateZero,
  );
  const { librarySearchParams, toggleParamItem, updateLibrarySearchParam, resetLibrarySearchParams } =
    useReturnsLibraryState();
  const libraryFiltersState = useRecoilValue(libraryFiltersStateSelector);
  const loadSavedFilters = useLoadSavedFilters();

  /** Clears user selection */
  const clearSelectedItems = useCallback(() => {
    updateLibrarySearchParam({
      key: 'selectedIds',
      value: [],
    });
  }, [updateLibrarySearchParam]);

  const onPageChange = useCallback(
    (index: number) => {
      updateLibrarySearchParam({
        key: 'page',
        value: index,
      });
      clearSelectedItems();
    },
    [updateLibrarySearchParam, clearSelectedItems],
  );

  const onSort = useCallback(
    (key: string, dir: SORTDIR) => {
      latestFilterLocation.current = 'sort';
      updateLibrarySearchParam({
        key: 'sortBy',
        order: getSortDirection(dir),
        column: key,
      });
    },
    [updateLibrarySearchParam],
  );

  const fetchSearch = useCallback(
    (hideLoading?: boolean) =>
      search(
        librarySearchParams,
        setSearchState,
        searchApi,
        isFirstLoad.current,
        latestFilterLocation.current,
        hideLoading,
        onPageChange,
      ),
    [librarySearchParams, searchApi, onPageChange, isFirstLoad],
  );

  useEffect(() => {
    if (!pageLoaded.current) {
      return;
    }
    if (isStateZero.current) {
      searchStateZero(librarySearchParams, setSearchState, searchApi);
      updateSettings({ [LIBRARY_NEW_USER_KEY]: false });
    } else {
      fetchSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [librarySearchParams, fetchSearch, searchApi, pageLoaded.current, isStateZero.current]);

  useEffect(() => {
    if (librarySearchParams.savedSearchId) {
      loadSavedFilters(librarySearchParams.savedSearchId);
    }
  }, [librarySearchParams.savedSearchId, loadSavedFilters]);

  /** Called after the user modified the text search field */
  const onSearchTextChange = useCallback(
    (searchQuery: string) => {
      isStateZero.current = false;
      latestFilterLocation.current = 'library';
      updateLibrarySearchParam({
        key: 'filter',
        filterName: 'name',
        value: searchQuery,
      });
    },
    [updateLibrarySearchParam],
  );

  /** Called when a new filter is applied */
  const handleApplyFilters = useCallback(
    (filter: string, value: string | string[], location: FilterLocation) => {
      isStateZero.current = false;
      latestFilterLocation.current = location;
      updateLibrarySearchParam({
        key: 'filter',
        filterName: filter,
        value,
      });
    },
    [updateLibrarySearchParam],
  );

  /** Applied when an advanced filter is set */
  const onAdvancedFilter = useCallback(
    (filter: AdvancedFilterType, updated: AdvancedFilterValue, location: FilterLocation) => {
      latestFilterLocation.current = location;
      updateLibrarySearchParam({
        key: 'filter',
        filterName: filter,
        value: updated as string[],
      });
    },
    [updateLibrarySearchParam],
  );

  const { loading, results, totalCount } = searchState;

  useLayoutEffect(() => {
    // don't scroll if this just the initial page load
    if (!isFirstLoad.current && !loading) {
      // In addition to the autoscroll that happens right the filters are changed,
      // scroll again once the results are loaded to account for changes in the library table height.
      // This also helps solve the bugginess of autoscroll when zoomed in on the page.
      scrollToElementByQuerySelector(LIBRARY_CONTENT_SELECTOR, 0, null, 'auto');
    }
    // make sure the query params have been loaded before removing the first load state
    if (pageLoaded.current) {
      isFirstLoad.current = false;
    }
  }, [loading]);

  const { page = 0, name, sortBy, order = 'desc', tags, filters, itemType = LibraryItemType.ALL } = librarySearchParams;
  const selectedFilters = [filters ?? [], itemType, tags ?? []] as [string[], LibraryItemType, string[]];

  const sharedTrackingProps = useMemo(
    () => ({
      totalResults: searchState.totalCount,
      visibleResults: searchState.results.length,
      queryDriven: !!librarySearchParams.name,
      quickFilters: librarySearchParams.filters,
      tagFilters: librarySearchParams.tags,
      typeFilters: [librarySearchParams.itemType],
      currencyFilters: librarySearchParams.currency,
      assetTypeFilters: librarySearchParams.assetTypes,
      metricFilters: toTrackingFormat(librarySearchParams.metrics),
      dataSourceFilters: librarySearchParams.dataSource,
    }),
    [searchState.totalCount, searchState.results.length, librarySearchParams],
  );

  const trackSelectionMade = useCallback(
    (item: SearchResultWithUIState) => {
      const rank = searchState.results.findIndex((i) => i.fundId === item.fundId && i.portfolioId === item.portfolioId);
      analyticsService.searchSelectionMade({
        activeSort: sortBy ? `${sortBy} ${order}` : undefined,
        location: 'library',
        objectId: getId(item) ?? undefined,
        objectType: getItemType(item),
        query: name ?? '',
        createdBy: getCreatedByTrackingField(item),
        rank,
        ...sharedTrackingProps,
      });
    },
    [sortBy, order, name, searchState, sharedTrackingProps],
  );

  const selectedItems = results.filter((item) => item.selected);
  const bulkActionsComponent = (
    <BulkActionsIcons
      selectedItems={selectedItems}
      clearSelectedItems={clearSelectedItems}
      updateData={setSearchState}
      trackingProps={sharedTrackingProps}
    />
  );

  const pills = useMemo(() => {
    return getFilterPills({
      filtersState: libraryFiltersState,
      toggleParamItem: (key: string, item: string) => {
        latestFilterLocation.current = 'filterPill';
        toggleParamItem(key, item);
        scrollToElementByQuerySelector(LIBRARY_CONTENT_SELECTOR, 0, null, 'auto');
      },
      updateSearchParam: (key, value) => {
        latestFilterLocation.current = 'filterPill';
        updateLibrarySearchParam({
          key: 'filter',
          filterName: key,
          value,
        });
        scrollToElementByQuerySelector(LIBRARY_CONTENT_SELECTOR, 0, null, 'auto');
      },
      removeAdvancedQuery: (index) => {
        onAdvancedFilter(
          AdvancedFilterType.METRICS,
          librarySearchParams.metrics.filter((_, i) => i !== index),
          'filterPill',
        );
        scrollToElementByQuerySelector(LIBRARY_CONTENT_SELECTOR, 0, null, 'auto');
      },
    });
  }, [libraryFiltersState, toggleParamItem, updateLibrarySearchParam, onAdvancedFilter, librarySearchParams.metrics]);

  const tableAnnotation = (
    <LibraryTableAnnotation
      pills={pills}
      bulkActionsComponent={bulkActionsComponent}
      userSelectedRowsCount={selectedItems.length}
      displayedRowsCount={results.length}
      totalRowsCount={totalCount}
      clearAllFilters={resetLibrarySearchParams}
      displayedPage={page}
      pageSize={PAGE_SIZE}
    />
  );

  return (
    <TagsContext.Provider value={tagsContext}>
      <PageContent>
        <LibraryLayout
          pageLoaded={pageLoaded}
          onSearchTextChange={onSearchTextChange}
          onAdvancedFilter={onAdvancedFilter}
          handleApplyFilters={handleApplyFilters}
          onSort={onSort}
          tableAnnotation={tableAnnotation}
          onPageChange={onPageChange}
          fetchSearch={fetchSearch}
          loading={loading}
          order={order}
          page={page}
          results={results}
          selectedFilters={selectedFilters}
          setSearchState={setSearchState}
          size={PAGE_SIZE}
          totalCount={totalCount}
          sortBy={sortBy}
          name={name}
          resetLibrarySearchParams={resetLibrarySearchParams}
          canResetFilters={canResetFilters(librarySearchParams)}
          trackSelectionMade={trackSelectionMade}
        />
      </PageContent>
    </TagsContext.Provider>
  );
};

export default withRouter(ReturnsDataLibrary);

const PageContent = styled.div`
  margin: 0;
`;
