import { isNil } from 'lodash';
import { analyticsService } from 'venn-utils';
import type { SearchMenuBar } from 'venn-components';
import type React from 'react';

export const useMappedPortfolioSearchMenuBar = ({
  // @ts-expect-error: fixme
  closeSearch,
  // @ts-expect-error: fixme
  allFundIdsInPortfolio,
  // @ts-expect-error: fixme
  onApplyMapping,
  // @ts-expect-error: fixme
  fundName,
  // @ts-expect-error: fixme
  node,
}): React.ComponentProps<typeof SearchMenuBar> => {
  return {
    refreshedStyling: true,
    autofocus: true,
    defaultMenuIsOpen: true,
    privateAssetSearchMode: 'PUBLIC_ONLY',
    investmentsOnly: true,
    isOptionDisabled: (option) => {
      const fundId = option?.value?.fund?.id;
      if (!fundId) {
        // if it does not have a fund ID, it is a tag; we should allow expanding them
        return false;
      }
      return allFundIdsInPortfolio.includes(fundId);
    },
    customPlaceholder: `Search for a fund to replace ${!isNil(fundName) ? `'${fundName}'` : 'your investment'}`,
    optionDisabledTooltipContent: () => 'Fund already in portfolio.',
    getOptionValue: (item) => item.value?.id?.toString() ?? '',
    onBlur: closeSearch,
    onSelected: (selectedItem) => {
      if (!selectedItem?.value?.fund) {
        return;
      }
      onApplyMapping((node?.data?.path ?? []).slice(1), selectedItem.value.fund);
      closeSearch();
      analyticsService.navigationTriggered({
        itemType: 'search menu',
        userIntent: 'map investment',
        destinationPageTitle: 'Portfolio Multi Uploader',
      });

      analyticsService.multiPortfolioUploaderInvestmentRemapped({
        portfolioId: node?.data?.root?.id,
        portfolioName: node?.data?.root?.name,
        newInvestmentId: selectedItem.value.fund.id,
        newInvestmentName: selectedItem.value.fund.name,
        oldInvestmentId: node?.data?.node?.fund?.id,
        oldInvestmentName: node?.data?.node?.name,
      });
    },
    location: 'portfolio-multi-uploader',
  };
};
