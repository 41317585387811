import { toEndOfPeriod, toEndOfFirstFullPeriod } from 'venn-utils';
import type { FrequencyEnum } from 'venn-api';

export const DAY_SIZE = 213;
export const MONTH_SIZE = 189;
export const YEAR_SIZE = 141;
/** Available factors start date */
const NEW_FACTOR_LENS_START_DATE = '1998/08/07';

/** The maximum range possible: from the start of factor data to today. */
export const getFactorMaxRange = (frequency: FrequencyEnum) => ({
  from: toEndOfFirstFullPeriod(NEW_FACTOR_LENS_START_DATE, frequency),
  to: toEndOfPeriod(new Date().valueOf(), 'day'),
});
