import React, { useContext } from 'react';
import type { FrequencyEnum } from 'venn-api';
import { EmptyStateContent, FloatRight, Frequency, ScreenOnly } from '../components/styled';
import {
  mapRangeToConstrainingInvestments,
  getRangeStartAndEnd,
  isPeriodSet,
  getPeriodFormatterForInsufficientReturns,
} from '../PortfolioRangesUtils';
import type { AnalysisSubject, TimeFrame } from 'venn-utils';
import { fundLengthInMonths, getFormattedPeriod, MANAGE_DATA_SECTION, useHasFF } from 'venn-utils';
import type { RangeDebugGroup } from '../../../hooks/useRangesDebug';
import ErrorInvalidAnalysisPeriod from '../shared-errors/ErrorInvalidAnalysisPeriod';
import { Button, Icon, Link as LinkStyle } from 'venn-ui-kit';
import RangeItemsList from '../components/RangeItemsList';
import { ManageDataLink, ManagePortfolioDataTrigger } from '../../../manage-data';
import FactorLensesContext from '../../../contexts/factor-lenses-context';

interface RangeMetadata {
  availableStart: number;
  availableEnd: number;
  frequency: FrequencyEnum;
  requiredMonths: number;
  analysisMonths: number;
  requiredLabel: string;
}

interface ErrorInsufficientReturnsProps {
  defaultMessage: string | null;
  rangeMetadata: RangeMetadata;
  subject: AnalysisSubject;
  regressionName: string;
  onResetAnalysisPeriod?: () => void;
  actualTimeFrame: TimeFrame;
  rangeDebugGroup?: RangeDebugGroup;
  isFactorTrend?: boolean;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  subject,
  rangeDebugGroup,
  onResetAnalysisPeriod,
  defaultMessage,
  rangeMetadata,
  regressionName,
  actualTimeFrame,
  isFactorTrend,
}: ErrorInsufficientReturnsProps) => {
  const hasBulkProxy = useHasFF('portfolio_bulk_proxy_ff');

  const { primaryFactorLens } = useContext(FactorLensesContext);
  const { availableStart, availableEnd, frequency, requiredMonths, requiredLabel } = rangeMetadata;
  const noOverlap = isPeriodSet(availableStart, availableEnd) && availableStart > availableEnd;

  const portfolioRange = rangeDebugGroup?.primary?.response?.portfolioRange;

  if (!portfolioRange) {
    return <EmptyStateContent>{defaultMessage}</EmptyStateContent>;
  }

  const actualStart = actualTimeFrame.startTime ?? availableStart;
  const actualEnd = actualTimeFrame.endTime ?? availableEnd;

  const formatDateRange = getPeriodFormatterForInsufficientReturns(frequency, availableStart, availableEnd, noOverlap);
  const availableRange = formatDateRange(availableStart, availableEnd);

  if (
    isPeriodSet(availableStart, availableEnd, isFactorTrend) &&
    ((!isFactorTrend && actualStart !== availableStart) ||
      (actualEnd !== availableEnd && actualEnd !== primaryFactorLens?.earliestEndDate))
  ) {
    return (
      <ErrorInvalidAnalysisPeriod
        buttonTitle="Reset Date To Factor Lens History"
        defaultMessage="Subject has insufficient returns or the selected analysis period is not supported by this block. Adjust the analysis period to run analysis."
        onResetAnalysisPeriod={onResetAnalysisPeriod}
      />
    );
  }

  const investments = mapRangeToConstrainingInvestments(portfolioRange);

  const itemsWithInsufficientReturns = investments.filter(
    ({ range }) => fundLengthInMonths(frequency, range.start, range.end) < requiredMonths,
  );
  const benchmarkInsufficient = itemsWithInsufficientReturns.find(({ type }) => type === 'Benchmark');
  const comparisonInsufficient = itemsWithInsufficientReturns.find(({ type }) => type === 'Comparison');
  const investmentsInsufficient = itemsWithInsufficientReturns.filter(({ type }) => !type);

  const [maxStart, minEnd] = getRangeStartAndEnd(portfolioRange);
  const constrainingInvestments = investments.filter(
    ({ type, range: { start, end } }) =>
      type === undefined &&
      (noOverlap ? start > minEnd || end < maxStart : start === availableStart || end === availableEnd),
  );
  const benchmarkConstraining =
    portfolioRange.benchmark &&
    (noOverlap
      ? portfolioRange.benchmark.start > minEnd || portfolioRange.benchmark.end < maxStart
      : portfolioRange.benchmark.start === availableStart || portfolioRange.benchmark.end === availableEnd);
  const secondaryConstraining =
    portfolioRange.secondary &&
    (noOverlap
      ? portfolioRange.secondary.start > minEnd || portfolioRange.secondary.end < maxStart
      : portfolioRange.secondary.start <= availableStart || portfolioRange.secondary.end >= availableEnd);

  // @ts-expect-error: fixme
  const customRenderer = (onClick) => (
    <div>
      <p>
        {subject.type === 'portfolio' ? (
          <span>
            <strong>Remove</strong> investments with insufficient returns from your portfolio or go to
          </span>
        ) : (
          <span>Go to</span>
        )}{' '}
        <button type="button" onClick={onClick} className="text-venn-dark-blue hover:text-venn-dark-blue/90 font-bold">
          Manage Data
        </button>{' '}
        to apply proxies.
      </p>
      <Button className="print:hidden" onClick={onClick} dominant refreshedStyling>
        Manage Data
      </Button>
    </div>
  );

  if (hasBulkProxy) {
    return (
      <div>
        <div className="text-[16px] text-balance">
          <p className="mt-0">
            {!noOverlap && (
              <>
                <strong>{regressionName}</strong> requires at least <strong>{requiredLabel}</strong> of return history
                for <span className="font-bold lowercase">{frequency}</span> analysis.{' '}
              </>
            )}
            {noOverlap ? (
              <>
                Currently, the return streams of the items you're analyzing have <strong>no common overlap</strong>.
              </>
            ) : (
              <>
                Currently, the return streams of the items you're analyzing overlap over{' '}
                <strong>{getFormattedPeriod(frequency, availableStart, availableEnd)}</strong> (
                <strong>{availableRange}</strong>).
              </>
            )}
          </p>
          {/* TODO(houssein): Refactor ManageDataLink to a custom navigation hook */}
          <ManageDataLink
            subject={subject}
            defaultEditData
            customRenderer={customRenderer}
            forceSection={MANAGE_DATA_SECTION.ANALYSIS_RANGE}
            errorState
          />
        </div>
      </div>
    );
  }

  return (
    <EmptyStateContent style={{ maxWidth: '100%' }}>
      {!noOverlap && (
        <>
          <strong>{regressionName}</strong> requires at least{' '}
          <strong>
            <i>{requiredLabel}</i>
          </strong>{' '}
          of return history for <Frequency>{frequency}</Frequency> analysis.{' '}
        </>
      )}
      {noOverlap ? (
        <>
          Currently, the return streams of the items you're analyzing have <strong>no common overlap</strong>.
        </>
      ) : (
        <>
          Currently, the return streams of the items you're analyzing overlap over{' '}
          <strong>
            <i>{getFormattedPeriod(frequency, availableStart, availableEnd)}</i>
          </strong>{' '}
          (<strong>{availableRange}</strong>).
        </>
      )}
      <ScreenOnly>
        <div style={{ marginTop: 20 }}>
          {itemsWithInsufficientReturns.length > 0 ? (
            <>
              The following items have insufficient returns for a <Frequency>{frequency}</Frequency> analysis:
              <RangeItemsList
                subject={subject}
                portfolioRange={portfolioRange}
                includeBenchmark={!!benchmarkInsufficient}
                includeSecondary={!!comparisonInsufficient}
                infoType="returns"
                investments={investmentsInsufficient}
                availableStart={availableStart}
                availableEnd={availableEnd}
              />
            </>
          ) : (
            <>
              The following items are constraining your analysis period:
              <RangeItemsList
                subject={subject}
                portfolioRange={portfolioRange}
                includeBenchmark={benchmarkConstraining}
                includeSecondary={secondaryConstraining}
                infoType="constraining"
                investments={constrainingInvestments}
                availableStart={availableStart}
                availableEnd={availableEnd}
              />
            </>
          )}
        </div>
        <ManagePortfolioDataTrigger hidden={subject.type !== 'portfolio'}>
          {(openManageDataPage) => (
            <FloatRight>
              <LinkStyle>
                <button type="button" onClick={openManageDataPage}>
                  <Icon type="question-circle" /> How is my available analysis period calculated?
                </button>
              </LinkStyle>
            </FloatRight>
          )}
        </ManagePortfolioDataTrigger>
      </ScreenOnly>
    </EmptyStateContent>
  );
};
