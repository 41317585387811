import styled, { css } from 'styled-components';
import { GetColor, ColorUtils } from 'venn-ui-kit';

const ALLOCATION_PANEL_TOTAL_WIDTH = 410;
const COMPARE_WIDTH = 105;
export const Constants = {
  NAME_MARGIN: 16,
  COMPARE_WIDTH,
  ALLOCATION_WIDTH: 80,
  ACTIONS_WIDTH: 20,
  TRIANGLE_WIDTH: 10,
  ADD_FUND_PLUS_WIDTH: 19,
  HISTORY_BUTTON_WIDTH: 40,
  ALLOCATION_PANEL_TOTAL_WIDTH,
  ALLOCATION_PANEL_MAIN_WIDTH: ALLOCATION_PANEL_TOTAL_WIDTH - COMPARE_WIDTH,
} as const;

export const Grid = styled.div<{ hideComparisonColumn?: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: ${({ hideComparisonColumn }) => (hideComparisonColumn ? '32px' : '62px')} 1fr auto;
  grid-template-areas:
    'header'
    'content'
    'footer';
  height: 100%;
  @media print {
    display: none;
  }
`;

export const Header = styled.div`
  grid-area: header;
`;

export const Content = styled.div<{ color: string; hideComparisonColumn?: boolean }>`
  grid-area: content;
  overflow-y: auto;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-color: ${GetColor.GreyScale.Grey10};

  ${({ hideComparisonColumn, color }) =>
    !hideComparisonColumn &&
    css`
      background-image: linear-gradient(${GetColor.GreyScale.Grey10}, ${GetColor.GreyScale.Grey10}),
        linear-gradient(${ColorUtils.hex2rgba(color, 0.05)}, ${ColorUtils.hex2rgba(color, 0.05)});
      background-size:
        calc(100% - ${Constants.COMPARE_WIDTH}px) 100%,
        100%;

      &::-webkit-scrollbar-track {
        background-color: ${ColorUtils.hex2rgbaFrom(GetColor.DEPRECATED_DataLineColor.DarkBlue, 0.005)};
      }
    `}
`;

export const Footer = styled.div`
  grid-area: footer;
  display: flex;
`;
