import type { ColDef } from 'ag-grid-community';
import { CellLoader } from 'venn-ui-kit';
import { capitalizeFirstLetter, getFormattedFrequency, itemHasNoReturns, lowestFrequencyOf } from 'venn-utils';
import type { FrequencyEnum } from 'venn-api';
import type { BulkManageRow } from '../types';
import { bulkManageSortFunction } from './bulkManageSortFunction';
import { notApplicableTooltipCell } from './tooltip-cells';
import { isBulkManageAxisRow, isBulkManageFundRow, isBulkManageStrategyRow, isBulkManageTotalRow } from '../utils';

type FrequencyValue = FrequencyEnum | undefined;
const FREQUENCY_COL_WIDTH = 80;
export const frequencyColDef: ColDef<BulkManageRow, FrequencyValue> = {
  valueFormatter: ({ value, data }) =>
    value && !isBulkManageStrategyRow(data) ? capitalizeFirstLetter(getFormattedFrequency(value ?? 'UNKNOWN')) : '',
  cellRendererSelector: ({ data, value: frequency }) => {
    if (isBulkManageAxisRow(data) || isBulkManageTotalRow(data)) {
      return undefined;
    }
    if (isBulkManageFundRow(data) && data.rangeLoading) {
      return { component: CellLoader };
    }
    if (!frequency && data && itemHasNoReturns(data.startDate, data.endDate)) {
      return { component: NoReturnsTooltipCell };
    }
    return undefined;
  },
  headerName: 'Frequency',
  field: 'frequency',
  sortable: true,
  width: FREQUENCY_COL_WIDTH,
  comparator: bulkManageSortFunction(),
  aggFunc: ({ values }) => lowestFrequencyOf(values),
};

const NoReturnsTooltipCell = () => notApplicableTooltipCell;
