import React, { useCallback, useEffect, useState } from 'react';
import type { PortfolioCompare } from 'venn-api';
import { createCompositeBenchmark, getCompositeBenchmarkByFundId, getFund, updateCompositeBenchmark } from 'venn-api';
import { logExceptionIntoSentry, Numbers, useApi } from 'venn-utils';
import { Modal, ModalDescription, ModalSize, ModalSubhead } from '../../modal';
import type { CreateCompositeBenchmarkTabStateUpdater } from './CompositeBenchmarkEditor';
import CompositeBenchmarkEditor from './CompositeBenchmarkEditor';
import type { BenchmarkShape, CreateCompositeBenchmarkTabState } from './types';
import { INITIAL_BENCHMARK_TAB_STATE, RequestStatus, ScalingSign } from './types';
import type { SearchMenuItem } from '../../search-menu';
import { fundToSearchMenuItem } from '../../search-menu';
import { isNil } from 'lodash';
import { ConditionalOverlay } from '../../conditional-overlay';
import { Loading, portalMenuIgnoreActivityClassName } from 'venn-ui-kit';
import BenchmarkModalLayout from './modal/BenchmarkModalLayout';
import ModalFooter from './modal/ModalFooter';
import ModalHeader from '../../modal/ModalHeader';
import styled from 'styled-components';
import every from 'lodash/every';
import { getAnnualizedOffset } from './utils/offset';
import { getNodeComparison, NodeType } from './utils/comparison';
import classNames from 'classnames';
import { useSendSignal } from 'venn-state';

export interface BenchmarksModalProps {
  onSubmit: (benchmark: PortfolioCompare) => Promise<void>;
  onClose(): void;
  resultsFilter(item: SearchMenuItem): boolean;
  /**
   * Optional arg provided if editing a composite benchmark
   */
  benchmarkToEdit?: PortfolioCompare;
}

type BenchmarksModalContentProps = BenchmarksModalProps;

const qaClass = 'qa-benchmarks-modal';

export const CompositeBenchmarkModal = ({
  onSubmit,
  onClose,
  benchmarkToEdit,
  resultsFilter,
}: BenchmarksModalContentProps) => {
  const [composite, setComposite] = useState<CreateCompositeBenchmarkTabState>(INITIAL_BENCHMARK_TAB_STATE);
  const { name, benchmarks, error, status, id, fundId } = composite;
  const getBenchmark = useApi(getCompositeBenchmarkByFundId);
  const onBenchmarkEdited = useSendSignal({ type: 'SubjectUpdate', subject: { fundId: benchmarkToEdit?.fundId } });

  useEffect(() => {
    const fillBenchmarkDetails = async (benchmark: PortfolioCompare) => {
      setComposite((cur) => ({
        ...cur,
        status: RequestStatus.Pending,
      }));
      try {
        const benchmarkInfo = (await getBenchmark(benchmark.fundId!)).content;
        const getFunds = benchmarkInfo.children.map(async (child) => {
          const response = await getFund(child.fundId);
          return {
            fund: response.content,
            benchmark: child,
          };
        });
        const fundsAndBenchmarks = await Promise.all(getFunds);
        const benchmarks = fundsAndBenchmarks.map(
          (info): BenchmarkShape => ({
            scalingSign: info.benchmark.annualizedOffset < 0 ? ScalingSign.Minus : ScalingSign.Plus,
            scalingValue: isNil(info.benchmark.annualizedOffset)
              ? ''
              : (info.benchmark.annualizedOffset * 100).toFixed(1),
            weight: Numbers.safeFormatPercentage(info.benchmark.weight, 1, false),
            item: fundToSearchMenuItem(info.fund),
          }),
        );
        setComposite({
          name: benchmark.name,
          status: RequestStatus.Success,
          benchmarks,
          id: benchmarkInfo.id,
          fundId: benchmarkInfo.fundId,
        });
      } catch (e) {
        if (e.name === 'AbortError') {
          return;
        }
        logExceptionIntoSentry(e);
        setComposite((cur) => ({
          ...cur,
          status: RequestStatus.Failure,
          error: 'Failed to load the benchmark',
        }));
      }
    };

    if (benchmarkToEdit) {
      fillBenchmarkDetails(benchmarkToEdit);
    }
  }, [benchmarkToEdit, getBenchmark, onClose]);

  const onCompositeBenchmarksUpdate = useCallback((updater: CreateCompositeBenchmarkTabStateUpdater) => {
    setComposite((prev) => updater(prev));
  }, []);

  const setStatus = useCallback(
    (status: RequestStatus, error?: string) =>
      onCompositeBenchmarksUpdate((prevState) => ({
        ...prevState,
        status,
        error,
      })),
    [onCompositeBenchmarksUpdate],
  );

  const handleSubmit = useCallback(
    async (benchmarks: BenchmarkShape[], name?: string) => {
      setStatus(RequestStatus.Pending);

      try {
        let compositeBenchmark;
        const update = {
          name,
          children: benchmarks.map((benchmark) => ({
            weight: Number(benchmark.weight) / 100,
            // Tech debt: fundId could be undefined but server expects defined fundId
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            fundId: benchmark?.item?.value?.fund?.id!,
            annualizedOffset: getAnnualizedOffset(benchmark) / 100,
          })),
        };
        if (id && fundId) {
          compositeBenchmark = {
            ...update,
            id,
            fundId,
          };
          await updateCompositeBenchmark(id, compositeBenchmark);
        } else {
          compositeBenchmark = (await createCompositeBenchmark(update)).content;
        }

        await onSubmit(
          // @ts-expect-error: fixme
          getNodeComparison(compositeBenchmark.fundId, compositeBenchmark.name, NodeType.Fund) as PortfolioCompare,
        );
        benchmarkToEdit && onBenchmarkEdited();
        onClose();
      } catch (error) {
        setStatus(RequestStatus.Failure, error.content.message);
      }
    },
    [setStatus, id, fundId, onSubmit, onClose, benchmarkToEdit, onBenchmarkEdited],
  );

  const ableToSubmit = !!name && benchmarks.length > 0 && every(benchmarks, 'item');
  const editing = id && fundId;
  const header = (
    <>
      <ModalHeader>{editing ? 'Edit' : 'Create'} Composite Benchmark</ModalHeader>
      <ModalSubhead>Select a group of investments</ModalSubhead>
      <ModalDescription>Weights must sum to 100%</ModalDescription>
    </>
  );

  return (
    <Modal onClose={onClose} size={ModalSize.Large} className={classNames(portalMenuIgnoreActivityClassName, qaClass)}>
      <Container>
        <ConditionalOverlay
          condition={status === RequestStatus.Pending}
          className="conditional-overlay"
          contentClassName="conditional-overlay-content"
          overlay={<Loading title="" />}
        >
          <BenchmarkModalLayout
            header={header}
            footer={
              <ModalFooter
                onClose={onClose}
                canSubmit={ableToSubmit}
                onSubmit={() => handleSubmit(benchmarks, name)}
                error={error}
              />
            }
          >
            <CompositeBenchmarkEditor
              onUpdate={onCompositeBenchmarksUpdate}
              resultsFilter={resultsFilter}
              composite={composite}
            />
          </BenchmarkModalLayout>
        </ConditionalOverlay>
      </Container>
    </Modal>
  );
};

export default CompositeBenchmarkModal;

const Container = styled.div`
  height: 100%;
  .conditional-overlay {
    height: 100%;
    .conditional-overlay-content {
      height: 100%;
    }
  }
`;
