import React, { useCallback, useMemo, useState } from 'react';
import { BulkManageAction, type BulkManageRow } from '../types';
import { isBulkManageFundRow, rowHasHistoricalError, rowHasHistoricalWarning } from '../utils';
import type { AnalysisSubject } from 'venn-utils';
import { invalidate, logExhaustive, useMemoWithArgs } from 'venn-utils';
import type { ColDef, IRowNode } from 'ag-grid-community';
import { compact, some } from 'lodash';
import {
  dataSourceColDef,
  endDateColDef,
  frequencyColDef,
  getProxyColDef,
  rangeColDef,
  startDateColDef,
} from '../columns';
import { autoGroupColDef } from '../columns/autoGroupColDef';
import { NameCellRenderer } from '../columns/NameCellRenderer';
import { NameHeaderPrimitive } from '../columns/NameHeaderPrimitive';

type Props = {
  subject: AnalysisSubject;
  onFundUpdated?: (fundId: string) => void;
  canEditProxies: boolean;
  setOpenedSecondaryId: (id: string) => void;
  setSelectedFundIds: (ids: string[]) => void;
  onPickerOpen: () => void;
  rowData: BulkManageRow[];
  showLiquidatedInvestmentsToggle: boolean;
  showLiquidatedInvestments: boolean;
  setShowLiquidatedInvestments: (show: boolean) => void;
};
type PortfolioManagementColumns = {
  columnDefs: ColDef<BulkManageRow>[];
  autoGroupColumnDef: ColDef<BulkManageRow>;
};
export const usePortfolioManagementColumns = ({
  subject,
  onFundUpdated,
  setSelectedFundIds,
  setOpenedSecondaryId,
  canEditProxies,
  onPickerOpen,
  rowData,
  showLiquidatedInvestmentsToggle,
  showLiquidatedInvestments,
  setShowLiquidatedInvestments,
}: Props): PortfolioManagementColumns => {
  const [rowWithOpenTooltip, setRowWithOpenTooltip] = useState<BulkManageRow['rowId'] | undefined>(undefined);
  const handleRowUpdate = useCallback(
    async (action: BulkManageAction, row: BulkManageRow) => {
      switch (action) {
        case BulkManageAction.FUND_MODIFIED:
          const investmentId = isBulkManageFundRow(row) && row.investment.id;
          const subjectInvalidation = invalidate('investment', subject.id);
          const investmentInvalidation =
            investmentId && investmentId !== subject.id ? invalidate('investment', investmentId) : Promise.resolve();
          investmentId && onFundUpdated?.(investmentId);
          await Promise.allSettled([subjectInvalidation, investmentInvalidation]);
          break;
        case BulkManageAction.INVESTMENT_FORECAST_MODIFIED:
          break;
        default:
          logExhaustive(action);
      }
    },
    [subject.id, onFundUpdated],
  );

  const selectRow = useCallback(
    (row: IRowNode<BulkManageRow>) => {
      const bulkManageRow = row.data;
      const fundId = bulkManageRow && isBulkManageFundRow(bulkManageRow) && bulkManageRow.investment.id;
      if (!fundId) return;
      if (row.isRowPinned()) {
        setOpenedSecondaryId(fundId);
        onPickerOpen();
      } else {
        row.setSelected(true, true);
        setSelectedFundIds([fundId]);
        onPickerOpen();
      }
    },
    [onPickerOpen, setOpenedSecondaryId, setSelectedFundIds],
  );

  const subjectHasHistoricalError = useMemo(() => some(rowData, rowHasHistoricalError), [rowData]);

  const proxyColDef = useMemoWithArgs(getProxyColDef, [canEditProxies, handleRowUpdate, selectRow]);
  const autoGroupColumnDef = useMemo(
    () =>
      autoGroupColDef({
        customHeaderComponent: {
          headerComponent: () => (
            <NameHeaderPrimitive.Root>
              {showLiquidatedInvestmentsToggle && (
                <NameHeaderPrimitive.Toggle
                  toggled={showLiquidatedInvestments}
                  onToggle={() => setShowLiquidatedInvestments(!showLiquidatedInvestments)}
                />
              )}
            </NameHeaderPrimitive.Root>
          ),
        },
        customInnerCellComponent: {
          innerRenderer: NameCellRenderer,
          innerRendererParams: {
            setRowWithOpenTooltip,
          },
        },
        showGroupHeader: false,
        showCheckbox: true,
      }),
    [showLiquidatedInvestments, setShowLiquidatedInvestments, showLiquidatedInvestmentsToggle, setRowWithOpenTooltip],
  );

  const shouldShowHypotheticalReturnExtension = useCallback(
    (rowData: BulkManageRow) => rowHasHistoricalWarning(rowData) && rowData.rowId === rowWithOpenTooltip,
    [rowWithOpenTooltip],
  );

  return {
    columnDefs: compact([
      rangeColDef({ subjectHasHistoricalError, shouldShowHypotheticalReturnExtension }),
      startDateColDef(!!subject?.portfolio?.historical),
      endDateColDef(!!subject?.portfolio?.historical),
      frequencyColDef,
      dataSourceColDef,
      proxyColDef,
    ]),
    autoGroupColumnDef,
  };
};
