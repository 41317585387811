import type { ReactNode } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from '../../../style/color';
import { Body1 } from '../../typography';
import type { SimpleMenuItemProps } from './SimpleMenuItem';
import { toClassName } from 'venn-utils';
import type { DropMenuItem } from '../types';
import { ColorUtils } from '../../../style/colorUtils';

const DEFAULT_ITEM_HEIGHT = 26;
export const DEFAULT_PADDING_LEFT = 40;

export interface CategorizedMenuItemProps<T = string> extends SimpleMenuItemProps<T> {
  disabled?: boolean;
  onClick: () => void;
  renderItem?: (item: DropMenuItem<T>, isSelected?: boolean) => ReactNode;
  refreshedStyling?: boolean;
}

class CategorizedMenuItem<T> extends React.PureComponent<CategorizedMenuItemProps<T>> {
  itemRef = React.createRef<HTMLDivElement>();

  componentDidUpdate(prevProps: CategorizedMenuItemProps<T>) {
    // When user is using arrows to highlight & select a menu item, scroll items into view
    // when they become highlighted but aren't fully visible inside the scroll view.
    // This doesn't cover the case when the menu itself is not fully visible.
    if (this.itemRef.current && !prevProps.highlighted && this.props.highlighted) {
      const isNotFullyVisible =
        this.itemRef.current.offsetTop + this.itemRef.current.offsetHeight >
        (this.itemRef.current.offsetParent?.clientHeight ?? 0);
      if (isNotFullyVisible) {
        this.itemRef.current.scrollIntoView(false);
      }
    }
  }

  render() {
    const {
      item,
      onClick,
      selected,
      highlighted,
      disabled,
      renderItem = defaultRenderItem,
      refreshedStyling,
    } = this.props;
    const qaClass = item.label
      ? `qa-categorized-dropmenu-item ${toClassName(item.label)}`
      : 'qa-categorized-dropmenu-item';
    return (
      <Item
        role="option"
        aria-selected={selected}
        ref={this.itemRef}
        onClick={onClick}
        level={item.level}
        selected={selected}
        highlighted={highlighted}
        disabled={!!disabled}
        className={qaClass}
        height={refreshedStyling ? 'auto' : (item.style?.height ?? DEFAULT_ITEM_HEIGHT)}
        indent={
          typeof item.style?.paddingLeft === 'number' ? item.style?.paddingLeft : (undefined ?? DEFAULT_PADDING_LEFT)
        }
        refreshedStyling={refreshedStyling}
      >
        {renderItem(item, selected)}
      </Item>
    );
  }
}

const defaultRenderItem: <T>(item: DropMenuItem<T>) => ReactNode = ({ style, label }) => (
  <Body1 style={style}>{label}</Body1>
);

interface ItemProps {
  level?: number;
  selected: boolean;
  highlighted: boolean;
  disabled: boolean;
  height: string | number;
  indent: number;
  refreshedStyling?: boolean;
}

const Item = styled.div<ItemProps>`
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
  padding-left: ${({ level, indent }) => indent + (level || 0) * 10}px;
  padding-right: ${({ indent }) => indent}px;
  display: flex;
  align-items: center;
  ${({ disabled, refreshedStyling }) =>
    disabled
      ? css`
          pointer-events: none;
          background-color: ${GetColor.White};
          color: ${GetColor.HintGrey};
        `
      : css`
          cursor: pointer;
          padding-top: ${({ refreshedStyling }) => (refreshedStyling ? '4px' : '0')};
          padding-bottom: ${({ refreshedStyling }) => (refreshedStyling ? '4px' : '0')};
          background-color: ${({ selected, highlighted }: ItemProps) =>
            selected
              ? refreshedStyling
                ? GetColor.GreyScale.Grey20
                : ColorUtils.hex2rgbaFrom(GetColor.Primary.Dark, 0.1)
              : highlighted
                ? GetColor.WhiteGrey
                : GetColor.White};
          &:hover {
            background-color: ${({ selected }) =>
              refreshedStyling
                ? GetColor.GreyScale.Grey20
                : selected
                  ? ColorUtils.hex2rgbaFrom(GetColor.Primary.Dark, 0.1)
                  : GetColor.PaleGrey};
          }
        `};

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default CategorizedMenuItem;
