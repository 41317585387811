import type { ColDef, ColGroupDef, ValueGetterFunc } from 'ag-grid-community';
import type { BulkManageRow } from '../types';
import { AllocationHeaderGroup } from '../columns/AllocationHeaderGroup';
import {
  AllocationCellClass,
  AllocationHeaderCellClass,
  AllocationHeaderGroupCellClass,
  BoldRowGroupCellClass,
} from '../columns';
import { isNil } from 'lodash';
import {
  isBulkManageHistoricalTotalRow,
  isBulkManageProFormaTotalRow,
  isBulkManageTotalRow,
  isHistoricalBulkManageInvestmentRow,
  isProFormaBulkManageInvestmentRow,
} from '../utils';
import { getHistoricalPortfolioDateTotalAllocations, getProFormaPortfolioTotalAllocations } from '../columns/utils';
import type { Portfolio } from 'venn-api';
import { autoGroupColDef } from '../columns/autoGroupColDef';
import { NameHeaderPrimitive } from '../columns/NameHeaderPrimitive';

type Props = {
  portfolio: Portfolio | undefined;
  selectedAllocationDate: string;
  allocationDates: number[];
  selectedDate: string | undefined;
  onSetSetSelectedDate: (date: string | undefined) => void;
};
type AllocationColumns = {
  columnDefs: ColGroupDef<BulkManageRow>[];
  autoGroupColumnDef: ColDef<BulkManageRow>;
};
export const useAllocationColumns = ({
  portfolio,
  allocationDates,
  selectedDate,
  selectedAllocationDate,
  onSetSetSelectedDate,
}: Props): AllocationColumns => {
  const historical = !!portfolio?.historical;
  const portfolioDateTotalAllocations = getHistoricalPortfolioDateTotalAllocations(portfolio, allocationDates);
  const proFormaTotalAllocations = getProFormaPortfolioTotalAllocations(portfolio);
  const allocationValueGetter: ValueGetterFunc<BulkManageRow, number> = ({ data }) => {
    if (isProFormaBulkManageInvestmentRow(data) || isBulkManageProFormaTotalRow(data)) {
      return data.allocation ?? 0;
    }
    if (isHistoricalBulkManageInvestmentRow(data) || isBulkManageHistoricalTotalRow(data)) {
      return data.allocation[Number(selectedDate ?? allocationDates.at(-1))] ?? 0;
    }
    return 0;
  };
  const defaultAllocationCol: ColDef<BulkManageRow> = {
    flex: 1,
    minWidth: 100,
    maxWidth: 100,
    headerClass: AllocationHeaderCellClass,
    cellClass: AllocationCellClass,
    valueGetter: allocationValueGetter,
    aggFunc: 'sum',
    cellClassRules: {
      [BoldRowGroupCellClass]: ({ node, data }) => !!node?.group || isBulkManageTotalRow(data),
    },
  };
  const autoGroupColumnDef = autoGroupColDef({
    customHeaderComponent: {
      headerComponent: NameHeaderPrimitive.Root,
    },
    showGroupHeader: false,
    showCheckbox: false,
  });
  return {
    columnDefs: [
      {
        headerName: 'Group Header',
        headerGroupComponent: AllocationHeaderGroup,
        headerGroupComponentParams: {
          historical,
          dates: allocationDates,
          selectedDate: selectedAllocationDate,
          setSelectedDate: onSetSetSelectedDate,
        },
        headerClass: AllocationHeaderGroupCellClass,
        children: [
          {
            ...defaultAllocationCol,
            headerName: 'Value',
            valueFormatter: ({ value, node }) =>
              (!node?.group || (node?.group && !node?.expanded)) && !isNil(value) ? `$${value.toFixed(2)}` : '',
          },
          {
            ...defaultAllocationCol,
            headerName: 'Weight',
            valueFormatter: ({ value, node }) => {
              const total = historical
                ? portfolioDateTotalAllocations[selectedAllocationDate]
                : proFormaTotalAllocations;
              return (!node?.group || (node?.group && !node?.expanded)) && !isNil(value) && !isNil(total)
                ? `${((100 * value) / total).toFixed(1)}%`
                : '';
            },
          },
        ],
      },
    ],
    autoGroupColumnDef,
  };
};
