import styled, { css } from 'styled-components';
import Icon from '../icon/Icon';
import { GetColor } from '../../style';

export const HOVER_PARENT_CLASSNAME = 'hover_parent';

export interface StudioIconProps {
  /** Use nav default style */
  isNav?: boolean;
  /** Use nav selected style */
  navSelected?: boolean;
  size?: number;
  /** Adjust vertical position */
  top?: number;
  /** Update color when item (or parent) is hovered */
  hoverHighlight?: boolean;
  /** Fill the icon with solid color */
  color?: string;
}

export interface PrivateIconProps {
  /** Fill the icon with solid color */
  color?: string;
  className?: string;
}

export type PortfolioWithLetterIconProps = PrivateIconProps & {
  letter: string;
  dataTestId: string;
  large?: boolean;
};

interface PathProps {
  fillColor: string;
  hoverColor?: string;
}

export const Path = styled.path<PathProps>`
  fill: ${({ fillColor }) => fillColor};
  ${({ hoverColor }) =>
    hoverColor &&
    css`
      svg:hover &,
      .${HOVER_PARENT_CLASSNAME}:hover & {
        fill: ${hoverColor};
      }
    `}
`;

export const Rect = styled.rect<PathProps>`
  fill: ${({ fillColor }) => fillColor};
  ${({ hoverColor }) =>
    hoverColor &&
    css`
      svg:hover &,
      .${HOVER_PARENT_CLASSNAME}:hover & {
        fill: ${hoverColor};
      }
    `}
`;

export const SVG = styled.svg<{ top?: number }>`
  position: relative;
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
    `}
`;

export const LetterIcon = styled(Icon)`
  // Workaround to make the font increase font weight, as the designs call for bolder text than is possible
  // with font awesome
  -webkit-text-stroke: 1px ${GetColor.White};
  color: ${GetColor.White};
`;

export const FAStackWrapper = styled.span`
  // Half the font size, as stacked FA icons are double size by default
  font-size: 0.5em;
  // Overrides fa-stack 2.5em width, so that the width of the icon is exactly the width of the underlying icon
  width: 2em;
`;
