import React, { memo, useState } from 'react';
import type { ICellRendererParams, IRowNode } from 'ag-grid-community';
import { Icon } from 'venn-ui-kit';
import { AssetTypeEnum, type DetailedProxyMetadata } from 'venn-api';
import { assertNotNil, getFrequencyType, useEventCallback, useModal } from 'venn-utils';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipPortal } from '@/components/ui/tooltip';
import ProxyInfo from '../../modals/proxy-cell/ProxyInfo';
import { BulkManageAction, type BulkManageRow, type BulkManageUpdateFn, type BulkManageFundRow } from '../types';
import { DeleteProxiesConfirmationModal } from '../DeleteProxiesConfirmationModal';
import type { FundToBulkProxy } from '../../modals/pickers/types';

export type ProxyCellRendererParams = {
  canEditProxies: boolean;
  onUpdate: BulkManageUpdateFn;
  openBulkProxyModal: (node: IRowNode<BulkManageRow>) => void;
};

export function ProxyCellRenderer({
  node,
  canEditProxies,
  onUpdate,
  openBulkProxyModal,
}: ICellRendererParams<BulkManageFundRow> & ProxyCellRendererParams) {
  // useEventCallback ensures that we never rerender the expensive proxy info section due to the functions or their dependencies changing.
  const launchBulkProxyModal = useEventCallback(() => openBulkProxyModal(node));
  const onRemove = useEventCallback(async () => {
    await onUpdate(BulkManageAction.FUND_MODIFIED, assertNotNil(node.data, 'Node data is required to remove proxy'));
  });

  if (node?.group || !node.data?.investment) return null;

  const isCompositeBenchmark = node.data.investment.assetType === AssetTypeEnum.BENCHMARK;
  if (isCompositeBenchmark) {
    return <ProxyUnavailableMessage />;
  }

  const nodeData = node.data;
  const fundProxyInfo = nodeData.fundProxyInfo;
  if (fundProxyInfo?.proxyId) {
    return (
      <FundProxyInfoSection
        investment={assertNotNil(nodeData.investment)}
        fundProxyInfo={fundProxyInfo}
        canEditProxies={canEditProxies}
        onRemove={onRemove}
        launchBulkProxyModal={launchBulkProxyModal}
      />
    );
  }

  if (!canEditProxies) {
    return null;
  }

  if (node.data.secondary) {
    if (!node.data.isBenchmark) {
      return null;
    }
    return (
      <div className="flex gap-2 pr-2 items-center">
        <span className="text-venn-grey-80 grow shrink text-ellipsis overflow-clip whitespace-nowrap min-w-0">
          Set proxy on benchmark
        </span>
        <Button onClick={launchBulkProxyModal} variant="ghost" size="icon" className="size-max hover:bg-transparent">
          <Icon className="text-venn-highlight-dark hover:text-venn-highlight-light" type="circle-plus" prefix="far" />
        </Button>
      </div>
    );
  }

  return checkBoxToSetProxyMessage;
}

/** Component is memoized for performance; pass in only referentially stable data to prevent rerenders. */
const FundProxyInfoSection = memo(function _FundProxyInfoSection({
  investment,
  fundProxyInfo,
  canEditProxies,
  onRemove,
  launchBulkProxyModal,
}: {
  investment: FundToBulkProxy;
  fundProxyInfo: DetailedProxyMetadata;
  canEditProxies: boolean;
  onRemove: () => Promise<void>;
  launchBulkProxyModal: () => void;
}) {
  const investmentId = investment.id;
  const investmentLastUpdated = investment.updated;
  const investmentFrequency = getFrequencyType(investment.returnFrequency);

  const [isDeleteConfirmationOpen, openDeleteConfirmation, closeDeleteConfirmation] = useModal();
  const [isProxiesUpdating, setIsProxiesUpdating] = useState(false);

  const modal = isDeleteConfirmationOpen && (
    <DeleteProxiesConfirmationModal
      fundsWithProxy={[investment]}
      proxyDataByFund={{
        [investmentId]: fundProxyInfo,
      }}
      onCancel={closeDeleteConfirmation}
      onStartingUpdate={() => setIsProxiesUpdating(true)}
      onFinishedUpdate={() => {
        closeDeleteConfirmation();
        onRemove().finally(() => {
          setIsProxiesUpdating(false);
        });
      }}
    />
  );

  return (
    <div className="flex gap-2 pr-2">
      {modal}
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex grow shrink min-w-0 overflow-clip items-center gap-1">
            <Icon type="arrows-left-right-to-line" className="text-xs text-venn-highlight-dark" />
            <div className="overflow-hidden whitespace-nowrap text-ellipsis text-venn-black">
              {fundProxyInfo.proxyName}
            </div>
          </div>
        </TooltipTrigger>

        <TooltipPortal>
          <TooltipContent>
            <ProxyInfo
              key={investmentId}
              investmentLastUpdated={investmentLastUpdated}
              investmentId={investmentId}
              proxy={fundProxyInfo}
              investmentFrequency={investmentFrequency}
            />
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>

      {canEditProxies && (
        <div className="flex gap-2 items-center">
          <Button
            onClick={launchBulkProxyModal}
            variant="ghost"
            size="icon"
            className="size-max text-venn-highlight-dark hover:text-venn-highlight-light hover:bg-transparent"
            disabled={isProxiesUpdating}
          >
            <Icon type="edit" />
          </Button>
          <Button
            onClick={openDeleteConfirmation}
            variant="ghost"
            size="icon"
            className="size-max text-venn-grey-50 hover:text-venn-error hover:bg-transparent"
            disabled={isProxiesUpdating}
          >
            <Icon type="circle-x" />
          </Button>
        </div>
      )}
    </div>
  );
});

const ProxyUnavailableMessage = memo(function _ProxyUnavailableTooltip() {
  return (
    <Tooltip>
      <TooltipTrigger className="flex items-center gap-1 h-full">
        <div className="text-venn-grey-80">Proxying Unavailable</div>{' '}
        <Icon type="circle-info" className="text-venn-grey-50" />
      </TooltipTrigger>

      <TooltipPortal>
        <TooltipContent>Proxying is unavailable for composite benchmarks.</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
});

const checkBoxToSetProxyMessage = <div className="text-venn-grey-50">Check box to set a proxy</div>;
