import type { ReactNode } from 'react';
import React, { useState, useLayoutEffect, useMemo, useEffect, useRef, useCallback } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { STUDIO_REPORT_LAB_AIS_FAQ_HREF, GetColor, Icon, Tooltip } from 'venn-ui-kit';
import styled from 'styled-components';
import ExpandCollapseButton from './ExpandCollapseButton';
import { useHasFF, useRedrawCharts } from 'venn-utils';
import { isReportState, studioLeftPanelOpen } from 'venn-state';

interface SidePanelItem {
  key: string;
  text: string;
  labelComponent?: ReactNode;
  Component: ReactNode;
}

interface SidePanel {
  defaultKey?: string;
  items?: SidePanelItem[];
  customHeaderComponent?: ReactNode;
  contentMinHeight?: string;
}

const ACTIVE_DEFAULT_POSITION = { left: 0, width: 10 };

const SidePanel = ({ defaultKey, items, customHeaderComponent, contentMinHeight = 'auto' }: SidePanel) => {
  const hasApInRl = useHasFF('ap_in_rl_ff');

  const [isOpen, setIsOpen] = useRecoilState(studioLeftPanelOpen);
  const isReport = useRecoilValue(isReportState);
  const defaultActiveKey = defaultKey ?? items?.[0]?.key ?? '';
  const defaultActiveComponent =
    items?.find((item) => item.key === defaultKey)?.Component ?? items?.[0]?.Component ?? null;
  const [activeKey, setActiveKey] = useState<string>(defaultActiveKey);
  const [activePosition, setActivePosition] = useState<{ left: number; width: number }>(ACTIVE_DEFAULT_POSITION);
  const selectedItems = useMemo(() => items?.filter((item) => item.key === activeKey), [activeKey, items]);

  const headerElementRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (activeKey) {
      const tabContainer = headerElementRef.current;
      const tabNode = document.getElementById(activeKey);
      if (tabNode && tabContainer) {
        const { left, width } = tabNode.getBoundingClientRect();
        const { left: containerLeft } = tabContainer.getBoundingClientRect();
        setActivePosition({ left: left - containerLeft, width });
      }
    }
    // The activePosition is ultimately to show a highlight under items, but items can change, so items must also be a dep even though it isn't explicitly used
  }, [activeKey, items]);

  useRedrawCharts(isOpen);

  // TODO(will, collin): IMV2 remove this useEffect that is slightly unnessicary
  useEffect(() => {
    setActiveKey(defaultActiveKey);
  }, [defaultActiveKey]);

  useEffect(() => {
    if (!selectedItems?.length) {
      setActiveKey(defaultActiveKey);
    }
  }, [defaultActiveKey, selectedItems?.length]);

  const onToggle = useCallback(() => {
    setIsOpen((currentState) => !currentState);
  }, [setIsOpen]);

  if (!isOpen) {
    return (
      <SidePanelWrapper style={{ paddingTop: '10px' }} hasApInRl={hasApInRl}>
        <ExpandCollapseButton isOpen={isOpen} isLeftOrientation onToggle={onToggle} />
      </SidePanelWrapper>
    );
  }

  return (
    <SidePanelWrapper hasApInRl={hasApInRl}>
      <SidePanelHeader ref={headerElementRef}>
        {customHeaderComponent && (
          <>
            <ExpandCollapseButton isOpen={isOpen} isLeftOrientation onToggle={onToggle} />
            {customHeaderComponent}
          </>
        )}

        {items?.length && items?.length > 1 && (
          <HeaderTabsWrapper>
            <HeaderTabs>
              <div>
                {items.map((item) => (
                  <CustomButton
                    onClick={() => setActiveKey(item.key)}
                    active={activeKey === item.key}
                    id={item.key}
                    key={item.key}
                  >
                    {item.labelComponent ? item.labelComponent : item.text}
                  </CustomButton>
                ))}
              </div>
              {!customHeaderComponent && (
                <ButtonContainer>
                  <Tooltip
                    content={`Click to learn how to use Input Settings in ${isReport ? 'Report Lab' : 'Studio'}.`}
                  >
                    <a target="_blank" rel="noopener noreferrer" href={STUDIO_REPORT_LAB_AIS_FAQ_HREF}>
                      <Icon type="circle-question" />
                    </a>
                  </Tooltip>
                  <ExpandCollapseButton isOpen={isOpen} isLeftOrientation onToggle={onToggle} />
                </ButtonContainer>
              )}
            </HeaderTabs>
            <ActiveBar {...activePosition} />
          </HeaderTabsWrapper>
        )}
      </SidePanelHeader>
      <Content>
        <div style={{ minHeight: contentMinHeight }}>
          {selectedItems?.length ? (
            selectedItems?.map((item) => <React.Fragment key={item.key}>{item.Component}</React.Fragment>)
          ) : (
            <React.Fragment key={defaultActiveKey}>{defaultActiveComponent}</React.Fragment>
          )}
        </div>
      </Content>
    </SidePanelWrapper>
  );
};

export default SidePanel;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 6px;
  a {
    color: unset;
  }
`;

const HeaderTabsWrapper = styled.div`
  position: relative;
  min-height: 40px;
  padding: 10px;
`;

const HeaderTabs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

const CustomButton = styled.button<{ active: boolean }>`
  color: ${(props) => (props.active ? GetColor.Black : GetColor.DarkGrey)};
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  display: inline-flex;
  padding: 4px 2px;
  margin: 0 8px;

  &:hover {
    color: ${GetColor.MidGrey2};
  }

  &:focus {
    color: ${GetColor.Black};
  }
`;

export const ActiveBar = styled.div<{ left: number; width: number }>`
  left: ${(props) => props.left ?? 0}px;
  background-color: ${GetColor.Primary.Main};
  position: absolute;
  bottom: 0;
  height: 6px;
  width: ${(props) => props.width ?? 20}px;
  transition: left 0.5s;
  transition-timing-function: cubic-bezier(0.03, 0.92, 0.84, 1.08);
`;

export const SidePanelWrapper = styled.div<{
  hasApInRl: boolean;
}>`
  height: 100%;
  border: 1px solid ${(props) => (props.hasApInRl ? GetColor.GreyScale.Grey30(props) : GetColor.Grey(props))};
  background: ${GetColor.White};
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'content';
  @media print {
    display: none;
  }
`;

export const SidePanelHeader = styled.div`
  grid-area: header;
  border-bottom: 1px solid ${GetColor.GreyScale.Grey10};
`;
