import React, { useCallback } from 'react';
import type { BaseDropMenuProps, MenuContainerProps } from '../types';
import type { CategorizedCheckboxMenuProps } from '../menus/CategorizedCheckboxMenu';
import CategorizedCheckboxMenu from '../menus/CategorizedCheckboxMenu';
import SkeletalDropMenu from './SkeletalDropMenu';
import SimpleTrigger from '../triggers/SimpleTrigger';
import { GetColor } from '../../../style/color';
import styled from 'styled-components';

interface CategorizedCheckboxDropMenuProps<T>
  extends CategorizedCheckboxMenuProps<T>,
    BaseDropMenuProps<T>,
    MenuContainerProps {
  getTriggerItemNameProp?: (count: number) => string;
}

export const CategorizedCheckboxDropMenu = <T,>({
  categories,
  width,
  height,
  onChange,
  disabled = false,
}: CategorizedCheckboxDropMenuProps<T>) => {
  const allItems = categories.flatMap((c) => c.items);
  const numSelectedItems = allItems.filter((i) => i.checked).length;
  const allItemsSelected = numSelectedItems === allItems.length;
  const selectionText = allItemsSelected ? 'All Selected' : `${numSelectedItems} Selected`;

  const triggerComponent: React.ComponentProps<typeof SkeletalDropMenu>['triggerComponent'] = useCallback(
    (expanded, toggleMenu) => (
      <StyledTrigger
        width={width}
        expanded={expanded}
        onClick={toggleMenu}
        selection={selectionText}
        allItemsSelected={allItemsSelected}
        disabled={disabled}
      />
    ),
    [allItemsSelected, selectionText, width, disabled],
  );

  const menuComponent: React.ComponentProps<typeof SkeletalDropMenu>['menuComponent'] = useCallback(
    (onClose) => (
      <CategorizedCheckboxMenu<T>
        width={width}
        height={height}
        categories={categories}
        ignoreHierarchySelect
        onChange={onChange}
        onCollapse={onClose}
      />
    ),
    [categories, width, height, onChange],
  );

  return (
    <SkeletalDropMenu
      disabled={disabled}
      menuPosition="right"
      triggerComponent={triggerComponent}
      menuComponent={menuComponent}
    />
  );
};

const StyledTrigger = styled(SimpleTrigger)<{ disabled: boolean; allItemsSelected: boolean }>`
  .qa-dropmenu-trigger {
    background-color: ${(props) =>
      props.disabled ? GetColor.Grey : props.allItemsSelected ? GetColor.White : GetColor.Primary.Main};
  }
  .qa-dropmenu-input-content {
    font-weight: normal;
    font-size: 14px;
  }
`;
