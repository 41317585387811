import React, { useCallback, useMemo, useState } from 'react';
import type { ItemId, PortfolioCompare } from 'venn-api';
import { getFund } from 'venn-api';
import {
  CellLoader,
  Icon,
  Label,
  Notifications,
  NotificationType,
  Tooltip,
  TooltipLink,
  TooltipBodyDirection,
  ZIndex,
  COMMON_BENCHMARKS_HELP_HREF,
} from 'venn-ui-kit';
import styled, { css } from 'styled-components';
import { AnalysisSubject } from 'venn-utils';
import SearchMenuBar from '../../search-menu/SearchMenuBar';
import CreateCompositeFooter from '../../search-menu/components/CreateCompositeFooter';
import type { SearchMenuItem, SearchMenuItemCategory } from '../../search-menu';
import { SearchMenuColumn } from '../../search-menu';
import CompositeBenchmarkModal from '../composite-benchmark-modal/CompositeBenchmarkModal';

interface BenchmarkProps {
  benchmarks?: ItemId[];
  disabled?: boolean;
  disabledTooltipMessage?: string;
  onSelectBenchmark: (benchmark: AnalysisSubject) => void;
  onClearBenchmark: () => void;
  resultsFilter?: (item: SearchMenuItem) => boolean;
  resultDisabledMessage?: (item: SearchMenuItem) => string;
  investmentsOnly?: boolean;
  value?: AnalysisSubject;
  loading?: boolean;
  smallScreen?: boolean;
  usePortal?: boolean;
  tooltipPosition?: { top: number; left: number };
}

const MAX_BENCHMARKS = 10;
const SEARCH_WIDTH = 850;
const SEARCH_HEIGHT = 370;
const PLACEHOLDER_CLASS = 'benchmark-placeholder';
const SEARCH_COLUMNS: SearchMenuColumn[] = [
  SearchMenuColumn.CCY,
  SearchMenuColumn.IDENTIFIER,
  SearchMenuColumn.TYPE,
  SearchMenuColumn.AVAILABLE_DATES,
  SearchMenuColumn.LAST_UPDATED,
];

const SMALL_SEARCH_COLUMNS: SearchMenuColumn[] = [
  SearchMenuColumn.CCY,
  SearchMenuColumn.IDENTIFIER,
  SearchMenuColumn.AVAILABLE_DATES,
];

enum BenchmarkModalMode {
  Edit,
  Create,
}

const BENCHMARK_TOOLTIP_TEXT =
  'If you are not sure which benchmark to use or can’t find the index you are looking for, Venn has created a list of commonly used benchmarks.';

const BenchmarkDropMenu = ({
  benchmarks,
  disabled,
  disabledTooltipMessage,
  onSelectBenchmark,
  onClearBenchmark,
  resultsFilter = () => false,
  resultDisabledMessage,
  investmentsOnly,
  value,
  loading,
  smallScreen,
  usePortal,
  tooltipPosition = {
    top: -130,
    left: -120,
  },
}: BenchmarkProps) => {
  const [benchmarkModalMode, setBenchmarkModalMode] = useState<BenchmarkModalMode | undefined>(undefined);

  const onSelected = useCallback(
    (selected: SearchMenuItem) => {
      if (selected.value) {
        onSelectBenchmark(selected.value);
      }
    },
    [onSelectBenchmark],
  );

  const getSearchOptionValue = useCallback(
    (option: SearchMenuItem) => (option.value?.id ? option.value?.id.toString() : ''),
    [],
  );

  const onEditBenchmark = useCallback(() => setBenchmarkModalMode(BenchmarkModalMode.Edit), []);

  const onCreateBenchmark = useCallback(() => setBenchmarkModalMode(BenchmarkModalMode.Create), []);

  const onBenchmarkModalClose = useCallback(() => setBenchmarkModalMode(undefined), []);

  const onBenchmarkModalSubmit = useCallback(
    async (benchmark: PortfolioCompare) => {
      const fund = await getFund(benchmark.fundId!);
      const subject = new AnalysisSubject(fund.content, 'investment');
      onSelectBenchmark(subject);
      if (benchmarkModalMode === BenchmarkModalMode.Edit) {
        Notifications.notify('Benchmark updated successfully', NotificationType.SUCCESS);
      } else {
        Notifications.notify('Benchmark created successfully', NotificationType.SUCCESS);
      }
    },
    [benchmarkModalMode, onSelectBenchmark],
  );

  const benchmarkToEdit = useMemo(
    () =>
      benchmarkModalMode === BenchmarkModalMode.Edit
        ? ({
            benchmark: true,
            fundId: value?.fund?.id,
            portfolioId: value?.portfolio?.id,
            name: value?.name,
            primary: true,
            type: value?.portfolio ? 'PORTFOLIO' : value?.fund?.assetType === 'BENCHMARK' ? 'COMPOSITE' : 'INVESTMENT',
          } as PortfolioCompare)
        : undefined,
    [benchmarkModalMode, value?.fund?.assetType, value?.fund?.id, value?.name, value?.portfolio],
  );

  const menu = useMemo(() => {
    const searchValue = value
      ? {
          value,
          label: value.name,
          category: 'recent' as SearchMenuItemCategory,
        }
      : null;

    const searchMenuBar = (
      <StyledSearchMenuBar
        data-testid="benchmark-menu-search-bar"
        investmentsOnly={investmentsOnly}
        onSelected={onSelected}
        location="benchmarkSelection"
        placeholders={benchmarks ? { ids: benchmarks.slice(0, MAX_BENCHMARKS), type: 'saved' } : undefined}
        value={searchValue}
        getOptionValue={getSearchOptionValue}
        disabled={disabled}
        optionDisabledTooltipContent={(option: SearchMenuItem) => resultDisabledMessage?.(option) ?? ''}
        isOptionDisabled={(option: SearchMenuItem) => resultsFilter(option)}
        footer={CreateCompositeFooter(onCreateBenchmark)}
        autofocus={false}
        columns={smallScreen ? SMALL_SEARCH_COLUMNS : SEARCH_COLUMNS}
        customPlaceholder={<div className={PLACEHOLDER_CLASS} />}
        onClear={onClearBenchmark}
        clearQueryOnBlur
        smallScreen={smallScreen}
        menuStyles={
          smallScreen
            ? {
                minWidth: '600px',
                right: 0,
                position: 'absolute',
              }
            : undefined
        }
        usePortal={usePortal}
        privateAssetSearchMode="PUBLIC_ONLY"
        showQuickFilters={!smallScreen}
      />
    );

    return (
      <>
        <StyledLabel>
          Benchmark:
          <TooltipLink
            text={BENCHMARK_TOOLTIP_TEXT}
            href={COMMON_BENCHMARKS_HELP_HREF}
            positions={tooltipPosition}
            top
          />
        </StyledLabel>
        {loading ? (
          <LoadingWrapper>
            <CellLoader />
          </LoadingWrapper>
        ) : (
          <SearchContainer data-testid="benchmark-dropmenu-search-container">
            {disabledTooltipMessage ? (
              <Tooltip block content={disabledTooltipMessage}>
                {searchMenuBar}
              </Tooltip>
            ) : (
              searchMenuBar
            )}
          </SearchContainer>
        )}
      </>
    );
  }, [
    value,
    benchmarks,
    investmentsOnly,
    onSelected,
    getSearchOptionValue,
    disabled,
    onCreateBenchmark,
    smallScreen,
    onClearBenchmark,
    usePortal,
    loading,
    disabledTooltipMessage,
    resultDisabledMessage,
    resultsFilter,
    tooltipPosition,
  ]);

  return (
    <>
      <Wrapper>
        <MenuWrapper>{menu}</MenuWrapper>
        {value?.isCompositeBenchmark && !loading && (
          <EditButton disabled={disabled} onClick={onEditBenchmark} className="qa-edit">
            <Tooltip content="Edit Composite Benchmark" bodyDirection={TooltipBodyDirection.Left}>
              <EditIcon type="edit" />
            </Tooltip>
          </EditButton>
        )}
      </Wrapper>
      {benchmarkModalMode !== undefined && (
        <CompositeBenchmarkModal
          onClose={onBenchmarkModalClose}
          onSubmit={onBenchmarkModalSubmit}
          resultsFilter={resultsFilter}
          benchmarkToEdit={benchmarkToEdit}
        />
      )}
    </>
  );
};

export default BenchmarkDropMenu;

const LoadingWrapper = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
`;

const SearchContainer = styled.div`
  height: 35px;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 5px;
`;

const EditButton = styled.button`
  margin-top: 13px;
  margin-left: 10px;
`;

const EditIcon = styled(Icon)`
  font-size: 20px;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const getOpenPlaceholder = (investmentsOnly?: boolean) =>
  `Search for any investment, ${!investmentsOnly ? 'portfolio, ' : ''}benchmark or category`;

const StyledSearchMenuBar = styled(SearchMenuBar)<{ investmentsOnly?: boolean; smallScreen?: boolean }>`
  .select__control {
    transition: width 0.5s ease-in-out;
    width: 100%;
    .${PLACEHOLDER_CLASS}:after {
      content: 'Search for or create a benchmark';
    }
  }
  ${({ smallScreen }) =>
    smallScreen &&
    css`
      .select__control--menu-is-open:after {
        content: '';
        height: ${SEARCH_HEIGHT}px;
        width: 100%;
      }
    `}
  .select__control--menu-is-open {
    ${({ smallScreen, investmentsOnly }) =>
      !smallScreen &&
      css`
        width: ${SEARCH_WIDTH}px;
        .${PLACEHOLDER_CLASS}:after {
          content: '${getOpenPlaceholder(investmentsOnly)}';
        }
      `}
  }

  ${(props) =>
    !props.smallScreen &&
    css`
      .select__menu {
        width: ${SEARCH_WIDTH}px;
      }
    `}

  z-index: ${ZIndex.Front};
`;
