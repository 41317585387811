import React from 'react';
import { useTheme } from 'styled-components';

export function BulkProxyLegend() {
  const { Colors } = useTheme(); // TODO(houssein): Switch to using our new static color/theme once we fully migrate away from styled-components
  const items = [
    { color: Colors.HighlightDark, name: 'Proxy' },
    { color: Colors.Black, name: 'Investment' },
  ];

  return (
    <div className="flex flex-row items-end gap-6 leading-none">
      {items.map((item) => (
        <div key={item.name} className="flex items-center gap-2 text-[12px]">
          <div className="h-[2px] w-[20px]" style={{ backgroundColor: item.color }} />
          <span>{item.name}</span>
        </div>
      ))}
      <span className="text-[14px] font-bold">5-year Return</span>
    </div>
  );
}
