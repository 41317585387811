import { useRecoilValue, waitForAll } from 'recoil';
import {
  type AnalysesResult,
  analysisIdState,
  blockBenchmarkConfig,
  blockConfidenceLevels,
  blockCustomizableMetrics,
  blockDateRange,
  blockFactors,
  blockForecastWindow,
  type BlockId,
  blockInfoGraphicType,
  blockLimitedRequestSubjects,
  blockRollingYears,
  blockScenarios,
  blockSelectedPeerGroupId,
  blockSettings,
  blockStartingNAV,
  portfolioStrategyFilterType,
  type StudioAnalysis,
  type StudioAnalysisRequest,
  subjectAsOfDate,
  viewCustomNotablePeriods,
  viewSelectedNotablePeriods,
} from 'venn-state';
import {
  createStudioAnalysisKey,
  getRequestSubjectFromAnalysisSubject,
  getSubjectFromRequestSubject,
  logExceptionIntoSentry,
  type QueriesResults,
  useHasFF,
  useMemoWithArgs,
  useQueries,
  useQuery,
  type UseQueryOptions,
} from 'venn-utils';
import type { AnalysesFetchingFunction } from '../types';
import { getAnalysesFetchingFunction, getCustomGridProps } from './customBlockUtils';
import { getBlockRequest } from './getBlockRequest';

const ONE_HOUR = 1000 * 60 * 60;

/** A date range must be set for the block before this hook is used, or an error will be thrown. */
export const useRequests = (blockId: BlockId) => {
  const settings = useRecoilValue(blockSettings(blockId));
  const apiRequestSubjects = useRecoilValue(blockLimitedRequestSubjects(blockId)).filter(
    (s) => settings.supportsPrivateInvestments || !s.private,
  );
  const dateRange = useRecoilValue(blockDateRange(blockId));
  const {
    type: benchmarkType,
    relative: benchmarkRelative,
    subject: benchmarkSubject,
  } = useRecoilValue(blockBenchmarkConfig(blockId));
  const benchmarkApiSubject = benchmarkSubject && getRequestSubjectFromAnalysisSubject(benchmarkSubject);
  const rollingYears = useRecoilValue(blockRollingYears(blockId));
  const forecastWindow = useRecoilValue(blockForecastWindow(blockId));
  const confidenceLevels = useRecoilValue(blockConfidenceLevels(blockId));
  const selectedNotablePeriods = useRecoilValue(viewSelectedNotablePeriods(blockId));
  const customNotablePeriods = useRecoilValue(viewCustomNotablePeriods(blockId));
  const scenarios = useRecoilValue(blockScenarios(blockId));
  const allMetrics = useRecoilValue(blockCustomizableMetrics(blockId));
  const blockGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const portfolioStrategyFilter = useRecoilValue(portfolioStrategyFilterType(blockId));
  const groupRootLevelInvestments =
    portfolioStrategyFilter === 'ALL_STRATEGIES' || portfolioStrategyFilter === 'TOP_LEVEL_STRATEGIES';
  const selectedPeerGroupId = useRecoilValue(blockSelectedPeerGroupId(blockId));
  const startingNAV = useRecoilValue(blockStartingNAV(blockId));

  const historicalAsOfDates = useRecoilValue(
    waitForAll(apiRequestSubjects.map((subject) => subjectAsOfDate([blockId, getSubjectFromRequestSubject(subject)]))),
  );

  return useMemoWithArgs(getBlockRequest, [
    apiRequestSubjects,
    allMetrics,
    benchmarkApiSubject,
    benchmarkType,
    benchmarkRelative,
    dateRange,
    settings.customBlockType,
    blockGraphicType,
    rollingYears,
    forecastWindow,
    confidenceLevels,
    selectedNotablePeriods,
    customNotablePeriods,
    scenarios,
    groupRootLevelInvestments,
    selectedPeerGroupId,
    startingNAV,
    historicalAsOfDates,
  ]);
};

/** A date range must be set for the block before this hook is used, or an error will be thrown. */
export const useResponseParser = (blockId: BlockId) => {
  const apiRequestSubjects = useRecoilValue(blockLimitedRequestSubjects(blockId));
  const factors = useRecoilValue(blockFactors(blockId));
  const settings = useRecoilValue(blockSettings(blockId));
  const scenarios = useRecoilValue(blockScenarios(blockId));
  const allMetrics = useRecoilValue(blockCustomizableMetrics(blockId));

  const responseParser = useMemoWithArgs(getCustomGridProps, [
    allMetrics,
    apiRequestSubjects,
    factors,
    settings.customBlockType,
    scenarios,
  ]);

  return responseParser;
};

export const useAnalysis = (blockId: BlockId) => {
  const settings = useRecoilValue(blockSettings(blockId));

  const requests = useRequests(blockId);
  const analysesFetchingFunction = getAnalysesFetchingFunction(settings.customBlockType);

  const hasParallelQueriesFF = useHasFF('studio_use_parallel_queries_ff');
  const hasParallelQuery = hasParallelQueriesFF || window.location.href.includes('studio_use_parallel_queries_ff');
  const queryAnalyses = hasParallelQuery ? useDataMultipleQueries : useDataSingleQuery;
  const analyses = queryAnalyses(analysesFetchingFunction, requests);
  return {
    analyses,
    requests,
  };
};

const useDataSingleQuery = (analysesFetchingFunction: AnalysesFetchingFunction, requests: StudioAnalysisRequest[]) => {
  const analysisId = useRecoilValue(analysisIdState);

  const { data: analyses = [] } = useQuery<AnalysesResult>(
    createStudioAnalysisKey(analysisId, requests),
    () => {
      return analysesFetchingFunction(requests);
    },
    {
      onError: (error) => {
        return logExceptionIntoSentry(error as Error);
      },
      suspense: true,
      staleTime: ONE_HOUR,
    },
  );
  return analyses;
};

/**
 * We are taking a request that has multiple subjects and a fixed set of analysis types for *all* of them.
 * There is no opportunity currently for different requests per subject.
 * For example: `Block {subjects: [a,b,c], analysisTypes: {t1,t2,t3,t4}}` will generate a single request
 *  applied to all inputs.  The transformation in this method is as follows:
 *
 * *Initial*
 * | request | analysesTypes |
 * | ------ | ------ |
 * | [a,b,c] | [t1,t2,t3,t4] |
 *
 * *Transformed*
 * | request | analysesTypes |
 * | ------ | ------ |
 * | [a] | [t1,t2,t3,t4] |
 * | [b] | [t1,t2,t3,t4] |
 * | [c] | [t1,t2,t3,t4] |
 *
 * *Batched*
 * | request | analysesTypes |
 * | ------ | ------ |
 * | [a,b,c] | [t1,t2,t3,t4] |
 *
 * TODO: The final missing piece below relates to errors.  We have individual request errors and top level.
 * The top level is now split up into 3 requests any of which can produce an error.  The individual
 * errors are retained.  So there needs to be a way of collecting all the top level errors or merging
 * them if there are more than one or more than one unique error.
 */
const useDataMultipleQueries = (
  analysesFetchingFunction: AnalysesFetchingFunction,
  requests: StudioAnalysisRequest[],
): AnalysesResult => {
  const analysisId = useRecoilValue(analysisIdState);

  // split requests by analysis type and subject
  const flatRequests = requests.flatMap((r) =>
    r.analysesTypes.map((a) => ({
      ...r,
      analysesTypes: [a],
    })),
  );

  const queries = flatRequests.map(
    (request): UseQueryOptions => ({
      queryKey: createStudioAnalysisKey(analysisId, [request]),
      queryFn: () => {
        return analysesFetchingFunction([request]);
      },
      onError: (error) => {
        return logExceptionIntoSentry(error as Error);
      },
      suspense: true,
      staleTime: ONE_HOUR,
    }),
  );
  const result: QueriesResults<StudioAnalysis[]> = useQueries<StudioAnalysis[]>({ queries });

  if (!requests.length) {
    return [];
  }

  // batch results by subject once more
  const analysesFull = result?.map((r): StudioAnalysis | undefined => r?.data?.[0]?.[0]);
  const batchSize = analysesFull.length / requests.length;
  const analyses: AnalysesResult = [];
  for (let i = 0; i < analysesFull.length; i += batchSize) {
    analyses.push(analysesFull.slice(i, i + batchSize));
  }

  return analyses;
};
