import React from 'react';
import type { IHeaderParams } from 'ag-grid-community';
import { Icon } from 'venn-ui-kit';

type RangeHeaderComponentProps = IHeaderParams & {
  hasHistoricalError: boolean;
};

export const RangeHeaderComponent = ({ hasHistoricalError }: RangeHeaderComponentProps) =>
  hasHistoricalError ? (
    <div className="flex gap-1">
      <Icon type="triangle-exclamation" className="text-venn-error" iconLabel="Range header historical error icon" />
      No Available Analysis Period
    </div>
  ) : (
    'Available Analysis Period'
  );
