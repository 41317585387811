import React from 'react';
import styled from 'styled-components';
import { GetColor, ZIndex } from 'venn-ui-kit';

export const MAIN_CONTENT_ID = 'main-content';

const SkipToContent = () => (
  <LinkButton href={`#${MAIN_CONTENT_ID}`} onClick={(e) => e.currentTarget.blur()}>
    Skip to content
  </LinkButton>
);

const LinkButton = styled.a`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  :focus {
    color: ${GetColor.Primary.Dark};
    text-decoration: underline;
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    width: auto;
    background-color: ${GetColor.White};
    font-size: 18px;
    padding: 0 24px;
    position: absolute;
    left: 3px;
    line-height: 64px;
    top: 3px;
    z-index: ${ZIndex.ModalCover};
  }
`;

export default SkipToContent;
