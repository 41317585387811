import type { FC } from 'react';
import React, { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import type { ExternalChartProps } from './types';
import type { FrequencyEnum, VenncastReturn } from 'venn-api';
import type { AnalysisGroup } from 'venn-utils';
import { analyticsService, getCumulativeVenncastExportData } from 'venn-utils';
import VennChart from '../analysis-charts/internal/VennChart';
import {
  getCumulativeReturnConfig,
  getVenncastConfig,
  getVenncastLegendItems,
  getVenncastDisableMessage,
} from './internal/utils';
import { AnalysisViewContext, AnalysisConfigContext } from '../../contexts';
import { ThemeContext } from 'styled-components';
import { useAppPrintMode } from '../../print';

interface CumulativeReturnChartProps extends ExternalChartProps {
  analysisGroup: AnalysisGroup<number[][]>;
  frequency?: FrequencyEnum;
  venncast?: AnalysisGroup<VenncastReturn>;
  dataExportable?: boolean;
}

const CumulativeReturnChart: FC<React.PropsWithChildren<CumulativeReturnChartProps>> = ({
  frequency,
  subject,
  relative,
  noChartErrorComponent,
  noChartTrackingProps,
  analysisGroup,
  venncast,
  downloadMetaData,
  dataExportable,
}) => {
  const { venncast: venncastParam, onUpdateAnalysisViewParam } = useContext(AnalysisViewContext);
  const { setVenncastOn } = useContext(AnalysisConfigContext);
  const [showVenncast, toggleVenncast] = useState(venncast && venncastParam);
  const theme = useContext(ThemeContext);
  const hasMainSerie = !!venncast?.subject?.cumulativeReturn?.[0];
  const mainSerieName = subject?.name || '';
  const header = relative ? 'Cumulative Excess Return' : 'Cumulative Return';
  const legends = getVenncastLegendItems(subject, relative, theme, venncast);
  const isFund = subject?.type === 'investment';
  const isInterpolated = !!venncast?.subject?.interpolated;
  const venncastDisableMessage = getVenncastDisableMessage(
    frequency || 'UNKNOWN',
    isFund,
    isInterpolated,
    venncast,
    !!subject.portfolio?.historical,
  );

  const onToggleVenncast = useCallback(() => {
    toggleVenncast((prev) => {
      onUpdateAnalysisViewParam({ venncast: !prev });
      analyticsService.chartModified({
        objectType: noChartTrackingProps?.objectType,
        chart: header,
        venncast: !prev ? 'true' : 'false',
        objectName: subject?.name,
        dateRange: noChartTrackingProps?.dateRange,
      });
      return !prev;
    });
  }, [subject, noChartTrackingProps, onUpdateAnalysisViewParam, header]);

  const exportData = useMemo(() => {
    if (!dataExportable) {
      return undefined;
    }
    return getCumulativeVenncastExportData(subject.name, venncast?.subject, showVenncast);
  }, [dataExportable, subject.name, venncast, showVenncast]);

  useEffect(() => {
    setVenncastOn?.(!!venncast?.subject?.estimated && !!showVenncast);
  }, [venncast?.subject?.estimated, setVenncastOn, showVenncast]);

  const { inPrintMode } = useAppPrintMode();
  return (
    <VennChart
      config={getCumulativeReturnConfig(analysisGroup, subject, frequency, inPrintMode, theme)}
      venncastConfig={getVenncastConfig(subject, frequency, inPrintMode, theme, venncast)}
      fileName={`${header} - ${mainSerieName}`}
      disableDownload={!hasMainSerie}
      header={header}
      venncastLegend={legends}
      hasMainSerie={hasMainSerie}
      enabledVenncast
      venncastDisableMessage={venncastDisableMessage}
      venncastOn={showVenncast}
      onToggleVenncast={onToggleVenncast}
      noChartErrorComponent={noChartErrorComponent}
      noChartTrackingProps={noChartTrackingProps}
      exportPNGEnabled
      exportXLSXEnabled={!!exportData}
      downloadMetaData={downloadMetaData}
      exportPercentage
      exportData={exportData}
      dataExportEvent={{
        description: 'CUMULATIVE_RETURN',
        userUploaded: !!subject?.fund?.userUploaded,
        relativeToBenchmark: relative,
        subjectType: subject.type,
        subjectId: subject.id,
      }}
    />
  );
};

export default CumulativeReturnChart;
