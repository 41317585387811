import React from 'react';
import styled from 'styled-components';
import { StudioShimmerBlock, useAppPrintMode, useBlockId } from 'venn-components';
import { Body1, GetColor, Headline3 } from 'venn-ui-kit';
import { useRecoilValue } from 'recoil';
import { blockDisplayHeader, blockFonts, customizedBlock, getCustomFontSize, isReportState } from 'venn-state';

export const HeaderFoundations = {
  Header: ({ children }: { children: React.ReactNode }) => {
    return (
      <React.Suspense fallback={<StudioShimmerBlock />}>
        <BlockHeaderWrapper>{children}</BlockHeaderWrapper>
      </React.Suspense>
    );
  },
  HeaderTopWrapper: ({ children }: { children: React.ReactNode }) => {
    const { inPrintModeOrReportLab } = useAppPrintMode();
    return <TopMetaData inPrintMode={inPrintModeOrReportLab}>{children}</TopMetaData>;
  },
  TitleWrapper: ({ children }: { children: React.ReactNode }) => {
    return <HeaderArea>{children}</HeaderArea>;
  },
  Title: () => {
    const blockId = useBlockId();
    const titleFont = useRecoilValue(blockFonts.blockTitle(blockId));
    const isReport = useRecoilValue(isReportState);
    const header = useRecoilValue(blockDisplayHeader(blockId));
    return (
      <Headline
        style={{ fontSize: getCustomFontSize(titleFont) }}
        data-testid="qa-block-header"
        isReport={Boolean(isReport)}
      >
        {header}
      </Headline>
    );
  },
  Description: () => {
    const blockId = useBlockId();
    const subTitleFont = useRecoilValue(blockFonts.blockSubtitle(blockId));
    const block = useRecoilValue(customizedBlock(blockId));
    return block && <Body1 style={{ fontSize: getCustomFontSize(subTitleFont) }}>{block.subHeader}</Body1>;
  },
};

const BlockHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderArea = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  word-break: break-word;
  padding-bottom: 5px;

  ${Body1} {
    font-size: 1rem;
  }
`;

const TopMetaData = styled.div<{ inPrintMode: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ inPrintMode }) => `${inPrintMode ? '0.7rem 0.7rem 0.3rem 0.7rem' : '13px 15px 5px 15px'}`};

  label {
    font-size: 14px;
    padding-right: 5px;
  }

  border-bottom: 1px solid ${GetColor.Grey};
`;

const Headline = styled(Headline3)<{ isReport: boolean }>`
  margin: 0 10px 0 0;
  vertical-align: middle;
  font-size: ${({ isReport }) => (isReport ? '1.225rem' : '21px')};
  ${({ isReport }) => (isReport ? '' : '@media print { font-size: 16pt; }')}
`;
