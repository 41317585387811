import { isNil } from 'lodash';
import type {
  DetailedProxyMetadata,
  FactorLensWithReturns,
  Fund,
  HistoricalSubjectRangeAnalysisErrorEnum,
  Portfolio,
  SimpleFund,
  SubjectRangeAnalysis,
} from 'venn-api';
import { getAppTitle } from 'venn-ui-kit';
import {
  type AnalysisSubject,
  assertExhaustive,
  flattenNode,
  flattenNodeWithStrategyPath,
  getAnalysisLabels,
  IS_PROD,
  logExceptionIntoSentry,
  lowestFrequencyOf,
  type WithStrategies,
} from 'venn-utils';
import {
  type Allocation,
  type BulkManageFactorRow,
  type BulkManageFundRow,
  type BulkManagePortfolioRow,
  type BulkManageRow,
  type BulkManageStrategyRow,
  type CommonRangeData,
  type CreateUpdateMetadata,
  type HistoricalAllocation,
  type Timestamp,
} from './types';
import type { FundToBulkProxy } from '../modals/pickers/types';
import { isProFormaBulkManageInvestmentRow } from './utils';
import { getHistoricalPortfolioDateTotalAllocations, getProFormaPortfolioTotalAllocations } from './columns/utils';

const getHistoricalError = (
  error?: HistoricalSubjectRangeAnalysisErrorEnum,
): HistoricalAllocation['historicalError'] => {
  if (isNil(error)) {
    return undefined;
  }
  switch (error) {
    case 'EARLIEST_ALLOCATION_DATE_PREDATES_SUBJECT_START_DATE':
    case 'ALLOCATION_DATE_OUTSIDE_SUBJECT_RANGE':
    case 'INVESTMENT_RETURNS_NOT_AVAILABLE':
    case 'INVESTMENT_ALLOCATIONS_NOT_AVAILABLE':
      return {
        _type: 'error' as const,
        value: error,
      };
    case 'LATEST_ALLOCATION_DATE_EXCEEDS_SUBJECT_RETURNS_END_DATE':
      return {
        _type: 'warning' as const,
        value: error,
      };
    default:
      return assertExhaustive(error);
  }
};

const getPinnedRowData = (
  subject: AnalysisSubject,
  proxyDataByFund: Record<string, DetailedProxyMetadata | undefined>,
  rangeDataByFund: Record<string, SubjectRangeAnalysis[]>,
  benchmark: Portfolio | Fund | null | undefined,
  commonRangeData: CommonRangeData,
  primaryFactorLens: FactorLensWithReturns | undefined,
  getNextFundIndex: (id: string) => number,
) => {
  const pinnedRowData: BulkManageRow[] = [];

  pinnedRowData.push({
    rowType: 'axis',
    commonRangeData,
    fullPath: ['axis'],
    name: '',
    rowId: 'axis',
    axis: true,
  });

  if (benchmark) {
    const labels = getAnalysisLabels(subject.type, subject.secondaryLabel, subject.secondaryPortfolio?.updated);
    const name = benchmark.name ? `${labels.benchmark}: ${benchmark.name}` : labels.benchmark;
    const fundCount = getNextFundIndex(`${benchmark.id}`);
    const row = {
      ...('children' in benchmark
        ? mapPortfolioToRow(commonRangeData, name, benchmark, rangeDataByFund[benchmark.id]?.[fundCount])
        : mapFundToRow(
            commonRangeData,
            name,
            [],
            benchmark,
            proxyDataByFund[benchmark.id],
            rangeDataByFund[benchmark.id]?.[fundCount],
            true,
          )),
      secondary: true,
      isBenchmark: true,
    } satisfies BulkManageFundRow | BulkManagePortfolioRow;
    pinnedRowData.push(row);
  }

  if (primaryFactorLens) {
    const rowId = `Factors${primaryFactorLens.latestStartDate}${primaryFactorLens.earliestEndDate}`;
    const row: BulkManageFactorRow = {
      rowType: 'factor' as const,
      commonRangeData,
      name: 'Factors',
      rowId,
      fullPath: [rowId],
      startDate: primaryFactorLens.latestStartDate,
      endDate: primaryFactorLens.earliestEndDate,
      frequency: primaryFactorLens.maximumFrequency,
      secondary: true,
      dataSource: getAppTitle(),
    };
    pinnedRowData.push(row);
  }

  return pinnedRowData;
};

export const getBulkManagementData = (
  subject: AnalysisSubject,
  funds: SimpleFund[],
  proxyDataByFund: Record<string, DetailedProxyMetadata | undefined>,
  rangeDataByFund: Record<string, SubjectRangeAnalysis[]>,
  benchmark: Portfolio | Fund | null | undefined,
  commonRangeData: CommonRangeData,
  primaryFactorLens: FactorLensWithReturns | undefined,
  allocationDates: number[],
  showTotalRow: boolean,
) => {
  const rowData: BulkManageRow[] = [];
  const fundIdToFund = new Map(funds?.map((f) => [f.id, f]));
  const flattenedPortfolioWithStrategyPaths = flattenNodeWithStrategyPath(subject.portfolio);
  const getNextFundIndex = (
    (fundToIndexMap: Map<string, number>) =>
    (id: string): number => {
      if (id in fundToIndexMap) {
        fundToIndexMap[id] += 1;
      } else {
        fundToIndexMap[id] = 0;
      }
      return fundToIndexMap[id];
    }
  )(new Map<string, number>());

  if (subject.fund) {
    rowData.push(
      mapFundToRow(
        commonRangeData,
        subject.fund.name,
        [],
        subject.fund,
        proxyDataByFund[subject.fund.id],
        rangeDataByFund[subject.fund.id]?.[getNextFundIndex(subject.fund.id)],
        false,
      ),
    );
  }

  const portfolioFundEntryMap = new Map<string, BulkManageRow>();
  for (const portfolioEntry of flattenedPortfolioWithStrategyPaths) {
    if (!portfolioEntry.fund) {
      // If the node doesn't have a fund, it is either the root or a strategy.
      if (portfolioEntry.id !== subject.portfolio?.id && portfolioEntry.children.length) {
        rowData.push(mapStrategyToRow(commonRangeData, portfolioEntry));
      }
      continue;
    }

    const fund = fundIdToFund.get(portfolioEntry.fund.id);
    if (!fund) {
      // hasn't loaded yet, so skip it for now
      continue;
    }

    const newEntry = mapFundToRow(
      commonRangeData,
      fund.name,
      portfolioEntry.strategyIdPath,
      fund,
      proxyDataByFund[fund.id],
      rangeDataByFund[fund.id]?.[getNextFundIndex(portfolioEntry.fund.id)],
      false,
      portfolioEntry,
    );

    const existingEntry = portfolioFundEntryMap.get(newEntry.rowId);
    if (existingEntry && isProFormaBulkManageInvestmentRow(existingEntry)) {
      // If we already have an entry with the the same exact rowId, we should just merge the allocations.
      // This is a rare edge case that can happen if somehow someone adds the same investment twice to a strategy.
      if (
        !isNil(existingEntry.allocation) ||
        (isProFormaBulkManageInvestmentRow(newEntry) && !isNil(newEntry.allocation))
      ) {
        existingEntry.allocation = (existingEntry.allocation ?? 0) + (portfolioEntry.allocation ?? 0);
      }
      continue;
    }
    portfolioFundEntryMap.set(newEntry.rowId, newEntry);
    rowData.push(newEntry);
  }

  let pinnedRowData = getPinnedRowData(
    subject,
    proxyDataByFund,
    rangeDataByFund,
    benchmark,
    commonRangeData,
    primaryFactorLens,
    getNextFundIndex,
  );

  if (showTotalRow) {
    if (subject?.portfolio?.historical) {
      const portfolioDateTotalAllocations = getHistoricalPortfolioDateTotalAllocations(
        subject.portfolio,
        allocationDates,
      );
      pinnedRowData = [
        {
          rowType: 'total' as const,
          allocation: portfolioDateTotalAllocations,
          allocationType: 'historical',
          name: 'Total',
          fullPath: ['total-ce15479b-b395-4066-b6cb-236b1cbbc238'],
          rowId: 'total-ce15479b-b395-4066-b6cb-236b1cbbc238',
          secondary: true,
          historicalStartDate: undefined,
          historicalEndDate: undefined,
          historicalError: undefined,
        },
      ];
    } else {
      const proFormaTotalAllocations = getProFormaPortfolioTotalAllocations(subject.portfolio);
      pinnedRowData = [
        {
          rowType: 'total' as const,
          allocation: proFormaTotalAllocations,
          allocationType: 'proforma',
          name: 'Total',
          fullPath: ['total-ce15479b-b395-4066-b6cb-236b1cbbc238'],
          rowId: 'total-ce15479b-b395-4066-b6cb-236b1cbbc238',
          secondary: true,
        },
      ];
    }
  }

  try {
    // verify that all results have unique rowIds and paths
    const rowIds = new Set<string>();
    const paths = new Set<string>();
    for (const row of rowData) {
      if (rowIds.has(row.rowId)) {
        throw new Error(`Duplicate rowId: ${row.rowId} in ${subject.name}`);
      }
      rowIds.add(row.rowId);

      const path = row.fullPath.join('>');
      if (paths.has(path)) {
        throw new Error(`Duplicate path: ${path} in ${subject.name}}`);
      }
      paths.add(path);
    }
  } catch (e) {
    if (IS_PROD) {
      logExceptionIntoSentry(e);
    } else {
      throw e;
    }
  }

  return { rowData, pinnedRowData };
};

const getCreateUpdateMetadata = (subject?: CreateUpdateMetadata): CreateUpdateMetadata => ({
  created: subject?.created,
  updated: subject?.updated,
  owner: subject?.owner,
  updatedBy: subject?.updatedBy,
});

const allocationTsToRecord = (allocationTs: Portfolio['closingAllocationsTs']): Record<Timestamp, Allocation> => {
  const record: Record<Timestamp, Allocation> = {};
  if (!allocationTs) {
    return record;
  }
  for (const [timestamp, allocation] of allocationTs) {
    record[timestamp] = allocation;
  }
  return record;
};

const mapStrategyToRow = (
  commonRangeData: CommonRangeData,
  strategy: WithStrategies<Portfolio>,
): BulkManageStrategyRow => {
  const strategyId = String(strategy.id);
  const fullPath = strategy.strategyIdPath.map(String);
  fullPath.push(String(strategy.id));
  const commonFields = {
    rowType: 'investment' as const,
    investmentType: 'strategy' as const,
    name: strategy.name,
    rowId: strategyId,
    fullPath,
    startDate: strategy.periodStart,
    endDate: strategy.periodEnd,
    frequency: strategy.lowestFrequency,
    commonRangeData,
  };
  if (strategy.historical) {
    return {
      ...commonFields,
      allocationType: 'historical' as const,
      allocation: allocationTsToRecord(strategy.closingAllocationsTs),
      historicalStartDate: undefined,
      historicalEndDate: undefined,
      historicalError: undefined,
    };
  }
  return {
    ...commonFields,
    allocationType: 'proforma' as const,
    allocation: strategy.allocation,
  };
};

// exported to support testing only
export const mapPortfolioToRow = (
  commonRangeData: CommonRangeData,
  name: string,
  portfolio: Portfolio,
  rangeAnalysis: SubjectRangeAnalysis | undefined,
): BulkManagePortfolioRow => {
  const rowId = `bm${String(portfolio.id)}`;
  const commonFields = {
    rowType: 'investment' as const,
    investmentType: 'portfolio' as const,
    ...getCreateUpdateMetadata(portfolio),
    name,
    rowId,
    fullPath: [rowId],
    rangeLoading: false as const,
    commonRangeData,
    isBenchmark: true as const,
    secondary: true as const,
    // TODO(willw): can we remove frequency, start and end from this row?
    frequency: lowestFrequencyOf(flattenNode(portfolio).map((node) => node.fund?.returnFrequency)),
    startDate: portfolio.periodStart,
    endDate: portfolio.periodEnd,
  };
  if (portfolio.historical) {
    return {
      ...commonFields,
      allocationType: 'historical' as const,
      allocation: allocationTsToRecord(portfolio.closingAllocationsTs),
      startDate: rangeAnalysis?.start,
      endDate: rangeAnalysis?.end,
      historicalStartDate: rangeAnalysis?.historicalStart,
      historicalEndDate: rangeAnalysis?.historicalEnd,
      historicalError: getHistoricalError(rangeAnalysis?.historicalError),
    };
  }
  return {
    ...commonFields,
    allocationType: 'proforma' as const,
    allocation: portfolio.allocation,
  };
};

const mapFundToRow = (
  commonRangeData: CommonRangeData,
  name: string,
  strategyPath: number[],
  fund: FundToBulkProxy,
  fundProxyInfo: DetailedProxyMetadata | undefined,
  rangeAnalysis: SubjectRangeAnalysis | undefined,
  secondary: boolean,
  fundNode?: Portfolio,
): BulkManageFundRow => {
  const strategyStrPath = strategyPath.map(String);
  strategyStrPath.push(fund.id + (secondary ? 'bm' : ''));
  const commonFields = {
    rowType: 'investment' as const,
    investmentType: 'fund' as const,
    ...getCreateUpdateMetadata(fund),
    name,
    rowId: strategyStrPath.join('>'),
    fullPath: strategyStrPath,
    fundProxyInfo,
    investment: fund,
    dataSource: fund.dataSource ?? (fund.userUploaded ? 'Upload' : getAppTitle()),
    startDate: rangeAnalysis?.start,
    endDate: rangeAnalysis?.end,
    frequency: rangeAnalysis?.frequency,
    proxyStartDate: rangeAnalysis?.proxyStartDate,
    proxyEndDate: rangeAnalysis?.proxyEndDate,
    extrapolateStartDate: rangeAnalysis?.extrapolateStartDate,
    extrapolateEndDate: rangeAnalysis?.extrapolateEndDate,
    rangeLoading: isNil(rangeAnalysis),
    secondary,
    commonRangeData,
    isBenchmark: false,
  };

  if (fundNode?.historical) {
    return {
      ...commonFields,
      allocationType: 'historical',
      allocation: allocationTsToRecord(fundNode?.closingAllocationsTs),
      historicalStartDate: rangeAnalysis?.historicalStart,
      historicalEndDate: rangeAnalysis?.historicalEnd,
      historicalError: getHistoricalError(rangeAnalysis?.historicalError),
      // hacking rowId to workaround ag-grid bug where the table doesn't refresh
      // despite the row's `historicalError` field changing
      rowId: `${strategyStrPath.join('>')}-${rangeAnalysis?.historicalError}`,
    };
  }

  return {
    ...commonFields,
    allocationType: 'proforma',
    allocation: fundNode?.allocation,
  };
};
