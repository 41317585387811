import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockLimitedRequestSubjects, blockSettings, blockSubjects, requestSubjects } from 'venn-state';
import { isHistoricalSubject, isPublicPrivateAssetGrowthBlock, useDebugValue } from 'venn-utils';
import { StyledErrorState } from '../StyledErrorState';
import type { ErrorWrapper } from './errorWrapperTypes';

export const SubjectsErrorWrapper: ErrorWrapper = ({ blockId, children }) => {
  const allSubjects = useRecoilValue(blockSubjects(blockId));
  const allRequestSubjects = useRecoilValue(requestSubjects(allSubjects));
  const settings = useRecoilValue(blockSettings(blockId));
  const supportedSubjects = useRecoilValue(blockLimitedRequestSubjects(blockId));
  const forceNoSubjectsError = useDebugValue('debugBlockErrorState') === 'NoSubjects';
  if (forceNoSubjectsError || !supportedSubjects.length) {
    if (forceNoSubjectsError || !allSubjects.length) {
      return (
        <StyledErrorState
          selectedRefId={blockId}
          header="No subjects selected"
          message="Please configure the subjects."
        />
      );
    }
    const allSubjectsArePublic = allSubjects.every((subject) => subject.fundId || subject.portfolioId);
    const allSubjectsArePrivate = allSubjects.every((subject) => subject.privateFundId || subject.privatePortfolioId);
    const allSubjectsAreFunds = allSubjects.every((subject) => subject.fundId || subject.privateFundId);
    const allSubjectsAreHistoricalPortfolios = allRequestSubjects.every(isHistoricalSubject);

    if (!settings.supportsPrivateInvestments && allSubjectsArePrivate) {
      return (
        <StyledErrorState
          header="This block only supports investment or portfolio returns as subjects, and cannot be used for private asset data"
          selectedRefId={blockId}
        />
      );
    }

    if (!settings.supportsPublicInvestments && allSubjectsArePublic) {
      return (
        <StyledErrorState
          header="This block only supports private asset data, and cannot be used for investment or portfolio returns data"
          selectedRefId={blockId}
        />
      );
    }

    if (settings.supportsPortfoliosOnly && allSubjectsAreFunds) {
      return (
        <StyledErrorState
          header="This block only supports portfolios, and cannot be used for individual investments"
          selectedRefId={blockId}
        />
      );
    }

    if (!settings.supportsHistoricalPortfolios && allSubjectsAreHistoricalPortfolios) {
      return <StyledErrorState header="This block does not support historical portfolios" selectedRefId={blockId} />;
    }
  }
  if (isPublicPrivateAssetGrowthBlock(settings.customBlockType) && supportedSubjects.length < 2) {
    return (
      <StyledErrorState
        selectedRefId={blockId}
        header="Your Subject Group must include one Returns-Based subject and one Private Asset subject."
        message="Please configure the subjects."
      />
    );
  }

  return children;
};
