import React, { type MutableRefObject, type PropsWithChildren, useEffect } from 'react';
import { RecoilRoot, type RecoilState, useRecoilCallback } from 'recoil';
import type { RecoilWrapperState } from './testRecoilState';

/**
 * Default fallback for the recoil wrapper suspense boundary.
 * Used to reduce confusion if suspense is accidentally triggered, as opposed to just throwing a promise.
 */
const DefaultFallback = () => {
  return <div>This is the default fallback for the base recoil wrapper suspense boundary</div>;
};

/**
 * Wrapper component providing RecoilRoot and enabling custom initialization of Recoil state.
 *
 * @param children - The child components to be wrapped.
 * @param states - An array of Recoil state and value tuples to be initialized.
 * @param customSuspenseFallback - Overrides the default suspense fallback that this component provides.
 * @param recoilRef - Allows you access to set/reset/refresh recoil state during a test.
 */
export const RecoilTestWrapper = ({
  children,
  states,
  customSuspenseFallback,
  recoilRef,
}: PropsWithChildren<{
  states?: RecoilWrapperState[];
  customSuspenseFallback?: React.ReactNode;
  recoilRef?: MutableRefObject<RecoilSetterRef | undefined>;
}>) => {
  return (
    <RecoilRoot
      initializeState={(snapshot) => {
        states?.forEach((cb) =>
          cb(([atom, value]) => {
            try {
              snapshot.set(atom, value);
            } catch (error) {
              // eslint-disable-next-line no-console
              console.warn(`setting ${atom.key} to ${value} caused error to be thrown.`);
              throw error;
            }
          }),
        );
      }}
    >
      <SuspenseNotifier />
      <React.Suspense fallback={customSuspenseFallback || <DefaultFallback />}>
        {children}
        {recoilRef && <RecoilSetter recoilRef={recoilRef} />}
      </React.Suspense>
    </RecoilRoot>
  );
};

export interface RecoilSetterRef {
  set: <T>(state: RecoilState<T>, value: T) => void;
  reset: <T>(state: RecoilState<T>) => void;
  refresh: <T>(state: RecoilState<T>) => void;
}

const RecoilSetter = ({ recoilRef }: { recoilRef: MutableRefObject<RecoilSetterRef | undefined> }) => {
  const extractCallback = useRecoilCallback(
    ({ set, reset, refresh }) =>
      () => {
        recoilRef.current = {
          set,
          reset,
          refresh,
        };
      },
    [recoilRef],
  );
  useEffect(extractCallback, [extractCallback]);
  return null;
};
const SuspenseNotifier = () => {
  const getPendingNodes = useRecoilCallback(({ snapshot }) => () => {
    const nodes = [...snapshot.getNodes_UNSTABLE()];
    return nodes
      .filter((node) => {
        const nodeInfo = snapshot.getInfo_UNSTABLE(node);
        const isLoading = nodeInfo.loadable?.state === 'loading';
        const hasValue = nodeInfo.loadable?.state === 'hasValue';
        const isActive = nodeInfo.isActive;

        return isLoading || (isActive && !hasValue);
      })
      .map((node) => `${node.key}`);
  });

  useEffect(
    () => () => {
      const nodes = getPendingNodes();
      if (nodes.length) {
        // eslint-disable-next-line no-console
        console.log(
          '='.repeat(80),
          '\n',
          'Potentially pending/suspended Recoil atoms/selectors:',
          nodes,
          '\n',
          '='.repeat(80),
        );
      }
    },
    [getPendingNodes],
  );
  return null;
};
