import React, { type CSSProperties, Fragment, useContext, useState } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { kebabCase } from 'lodash';

import type { SelectTypes, VennColors } from 'venn-ui-kit';
import {
  CheckboxWrapper,
  ColorUtils,
  Ellipsis,
  EllipsisTooltipSpan,
  GetColor,
  Icon,
  ItemIcon,
  ItemType,
  Tooltip,
  ZIndex,
} from 'venn-ui-kit';
import { CELL_SPACING, getItemType, isUploaded, ItemLabel } from './shared';
import { ARTICLE_COLUMNS, ColumnRenderers, renderItemsTooltip, VIEW_COLUMNS } from './Columns';
import type { CustomMultiSelectProps, SearchMenuItem } from '../types';
import { itemEqualityCheck } from '../utils';

const isIncluded = (array: SearchMenuItem[], item: SearchMenuItem) =>
  array.some((arrayItem: SearchMenuItem) => itemEqualityCheck(arrayItem, item)) || false;

const checkFirst = (options: SearchMenuItem[], item: SearchMenuItem) => {
  const taggedIndex = options.findIndex((option) => itemEqualityCheck(option, item));
  const isFirst = !options[taggedIndex - 1]?.isTagged && options[taggedIndex + 1]?.isTagged;

  return isFirst;
};

const checkLast = (options: SearchMenuItem[], item: SearchMenuItem) => {
  const taggedIndex = options.findIndex((option) => itemEqualityCheck(option, item));
  const isLast = options[taggedIndex - 1]?.isTagged && !options[taggedIndex + 1]?.isTagged;

  return isLast;
};

const Option = <IsMulti extends boolean = false>(props: SelectTypes.OptionProps<SearchMenuItem, IsMulti>) => {
  const { options, innerProps, innerRef, getStyles, isDisabled, isSelected, isMulti } = props;
  const selectProps = props.selectProps as unknown as CustomMultiSelectProps;
  const { columns, optionDisabledTooltipContent, excludedItems, smallScreen } = selectProps;
  const { Colors } = useContext(ThemeContext);

  // We use state rather than use ref here, so that the component rerenders when the ref is defined
  const [labelCell, setLabelCell] = useState<HTMLTableCellElement | null>();
  const labelWidth = labelCell ? labelCell.offsetWidth - CELL_SPACING : undefined;
  const item = props.data; // as SearchMenuItem;

  const itemType = getItemType(item);
  const isTag = itemType === ItemType.Tag;
  const isTagOpen = isTag ? isIncluded(selectProps.openedTags, item) : undefined;
  const isFirstTaggedItemInDropdown = item.isTagged ? checkFirst([...(options as SearchMenuItem[])], item) : undefined;
  const isLastTaggedItemInDropdown = item.isTagged ? checkLast([...(options as SearchMenuItem[])], item) : undefined;

  const overrideIsSelected = isMulti
    ? isTag
      ? isIncluded(selectProps.selectedTagDropdowns, item)
      : isIncluded(selectProps.selections, item)
    : isSelected;

  const handleTagOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    overrideIsSelected ? selectProps.onBulkTagUnselect(item) : selectProps.onBulkTagSelect(item);
  };

  const isTaggedItemExcluded = item.isTagged
    ? excludedItems?.some(
        (excludedItem) =>
          excludedItem.id === item.searchResult?.fundId || excludedItem.id === String(item.searchResult?.portfolioId),
      )
    : undefined;

  const isOptionDisabled = isTaggedItemExcluded || isDisabled;

  const style = {
    ...getStyles('option', props),
    display: 'table-row',
    color: isOptionDisabled ? Colors.MidGrey1 : Colors.HintGrey,
  } as CSSProperties;

  const displayColumns =
    item.category === 'view' ? VIEW_COLUMNS : item.category === 'article' ? ARTICLE_COLUMNS : columns;
  return (
    <>
      <ItemRow
        style={style}
        {...innerProps}
        ref={innerRef}
        className={`qa-menu-item-renderer qa-${kebabCase(item.label)} ${labelWidth} ${
          isOptionDisabled ? 'qa-menu-item-disabled' : ''
        }`}
        key={item.label}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          if (isOptionDisabled) {
            return;
          }

          if (!isMulti && isTag) {
            isTagOpen ? selectProps.onTagsClose(item) : selectProps.onTagsDropdown(item);
            return;
          }

          isTag ? handleTagOnClick(e) : innerProps.onClick?.(e);
        }}
        isMulti={isMulti}
        isTagged={item.isTagged}
        Colors={Colors}
        isOptionDisabled={Boolean(isOptionDisabled)}
        isTag={isTag}
        isFocused={props.isFocused}
      >
        {isTag ? (
          <ArrowCell
            data-testid="qa-tag-dropdown"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              isTagOpen ? selectProps.onTagsClose(item) : selectProps.onTagsDropdown(item);
            }}
          >
            <ArrowIcon type={isTagOpen ? 'caret-down' : 'caret-right'} isMulti={isMulti} />
          </ArrowCell>
        ) : (
          <td>
            {item.isTagged ? (
              <TagBorderWrapper
                isFirstTaggedItemInDropdown={isFirstTaggedItemInDropdown}
                isLastTaggedItemInDropdown={isLastTaggedItemInDropdown}
              >
                <div />
              </TagBorderWrapper>
            ) : (
              <div />
            )}
          </td>
        )}

        {isMulti ? (
          <CellWrapper>
            {isOptionDisabled ? (
              <StyledIcon type="ban" />
            ) : (
              <CheckboxWrapper
                key={`${item.searchResult?.portfolioId || item.searchResult?.fundId}-${overrideIsSelected}`}
                checked={overrideIsSelected}
                onClick={(e) => e.preventDefault()}
                onKeyUp={(e) => e.preventDefault()}
                onChange={(e) => e.preventDefault()}
                disabled={isOptionDisabled}
              />
            )}
          </CellWrapper>
        ) : null}
        <td className={smallScreen ? 'no-padding' : ''}>
          {isOptionDisabled && (
            <TooltipWrapper>
              <Tooltip
                flex
                content={
                  isTaggedItemExcluded && item.isTagged
                    ? 'Item is already selected'
                    : optionDisabledTooltipContent
                      ? optionDisabledTooltipContent(item)
                      : 'This benchmark has no overlap with your selection'
                }
              >
                <TooltipArea />
              </Tooltip>
            </TooltipWrapper>
          )}
          {smallScreen ? '' : <ItemLabel isDisabled={Boolean(isOptionDisabled)} item={item} />}
        </td>
        <td>
          <ItemIcon
            isDisabled={isOptionDisabled}
            item={itemType}
            dataSource={item.value?.superItem?.dataSource || item.value?.portfolio?.dataSource}
            isUploaded={isUploaded(item)}
            investmentSource={item.value?.fund?.investmentSource}
          />
        </td>
        <ExpandCell
          uploaded={isUploaded(item)}
          isOptionDisabled={Boolean(isOptionDisabled)}
          isSelected={overrideIsSelected}
          ref={(ref) => setLabelCell(ref)}
        >
          <NameOverflow>
            {smallScreen ? (
              <StyledTooltip content={renderItemsTooltip(item)} usePortal>
                <Ellipsis singleLine>{item.label}</Ellipsis>
              </StyledTooltip>
            ) : (
              <EllipsisTooltipSpan maxWidth={labelWidth}>{item.label}</EllipsisTooltipSpan>
            )}
          </NameOverflow>
        </ExpandCell>
        {displayColumns.map((col) => (
          <Fragment key={col}>{ColumnRenderers[col].cellRenderer(item)}</Fragment>
        ))}
      </ItemRow>
      {isTagOpen ? (
        <TagInfo>
          <td colSpan={5 + columns.length}>Contents of a tag are search and filter agnostic.</td>
        </TagInfo>
      ) : null}
    </>
  );
};

export default Option;

const ItemRow = styled.tr<{
  isOptionDisabled: boolean;
  isMulti: boolean;
  isTagged?: boolean;
  isTag: boolean;
  isFocused: boolean;
  Colors: VennColors;
}>`
  min-height: 32px !important;
  position: relative;
  ${(props) =>
    props.isOptionDisabled &&
    css`
      cursor: ${props.isTag ? 'pointer' : 'not-allowed'} !important;
    `}

  ${(props) =>
    props.isMulti &&
    css`
      padding: 0 25px !important;
    `}

  ${(props) =>
    props.isTagged &&
    css`
      background-color: ${props.isOptionDisabled
        ? GetColor.PaleGrey
        : ColorUtils.hex2rgba(props.Colors.Primary.Dark, 0.05)} !important;
      &:hover {
        background-color: ${props.isOptionDisabled ? GetColor.PaleGrey : props.Colors.HoverGrey} !important;
      }
    `}

    ${(props) =>
    props.isTagged &&
    props.isFocused &&
    css`
      background-color: ${props.isOptionDisabled ? GetColor.PaleGrey : props.Colors.HoverGrey} !important;
    `}
`;

const TagBorderWrapper = styled.div<{
  isFirstTaggedItemInDropdown?: boolean;
  isLastTaggedItemInDropdown?: boolean;
}>`
  height: 30px;
  width: 9px;
  & div {
    border-right: 1px dashed ${GetColor.MidGrey2};
    height: 100%;
  }
  ${(props) =>
    props.isFirstTaggedItemInDropdown &&
    css`
      padding-top: 8px;
    `}
  ${(props) =>
    props.isLastTaggedItemInDropdown &&
    css`
      padding-bottom: 8px;
    `}
`;

const TooltipArea = styled.div`
  width: 100%;
  height: 26px;
`;

const TooltipWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 26px;
  transform: translateY(-5px);
  z-index: ${ZIndex.Front};
`;

const StyledTooltip = styled(Tooltip)`
  width: 100%;
`;

const NameOverflow = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
`;

const ExpandCell = styled.td<{ uploaded: boolean; isOptionDisabled: boolean; isSelected: boolean }>`
  ${(props) =>
    !props.isOptionDisabled &&
    css`
      color: ${GetColor.Black};
    `}
  ${(props) =>
    props.uploaded &&
    css`
      font-weight: bold;
      color: ${props.isOptionDisabled ? GetColor.HintGrey : GetColor.Primary.Dark};
    `}
  width: 100%;
  position: relative;
  :before {
    content: '&nbsp;';
    visibility: hidden;
  }
`;

const ArrowCell = styled.td`
  position: relative;
  padding-left: 5px;
`;

const ArrowIcon = styled(Icon)<{ isMulti: boolean }>`
  top: ${({ isMulti }) => (isMulti ? 9 : 7)}px;
  position: absolute;
  font-size: 14px;
`;

const TagInfo = styled.tr`
  background-color: ${GetColor.WhiteGrey};
  color: ${GetColor.HintGrey};

  & td {
    font-size: 10px;
    padding: 6px 0 6px 18px;
  }
`;

const CellWrapper = styled.td`
  text-align: center;
`;

const StyledIcon = styled(Icon)`
  font-size: 16px;
  color: ${GetColor.Grey};
`;
