import React from 'react';
import { createAutoproxyKey, useQuery, withSuspense } from 'venn-utils';
import { autoproxyFund, type AutoproxyResponse } from 'venn-api';
import type { FundToBulkProxy } from '../../types';
import AutoproxyOptions from './AutoproxyOptions';
import type { SelectedProxy } from '../utils';
import { ShimmerBlock } from 'venn-ui-kit';

interface AutoproxySectionProps {
  investment: FundToBulkProxy;
  shouldExtrapolate?: boolean;
  setSelectedProxy: (proxy: SelectedProxy) => void;
}

const ShimmerFallback = () => {
  return (
    <div className="mt-3 flex flex-col gap-1">
      {Array.from({ length: 3 }, (_, index) => (
        <ShimmerBlock height={26} key={`shimmer-${index}`} className="w-full mb-0" />
      ))}
    </div>
  );
};

const AutoproxySectionInternal = ({ investment, shouldExtrapolate, setSelectedProxy }: AutoproxySectionProps) => {
  const autoProxyQuery = useQuery<AutoproxyResponse>(
    createAutoproxyKey(investment.id, !!shouldExtrapolate),
    async ({ signal }) => autoproxyFund(investment.id, shouldExtrapolate, signal).then((result) => result.content),
    {
      suspense: true,
    },
  );

  return <AutoproxyOptions setSelectedProxy={setSelectedProxy} autoproxyResponse={autoProxyQuery.data} />;
};

export const AutoProxySection = withSuspense(<ShimmerFallback />, AutoproxySectionInternal);
